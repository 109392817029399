import LoadingOverlay from "components/LoadingOverlay"
import ProfileContext from "features/Profile/context"
import { ArrowLeft } from "iconoir-react"
import { useContext, useEffect, useMemo, useState } from "react"

import CheckList, { CheckListType } from "./CheckList"
import Form from "./Form"
import TitleBar from "./TitleBar"

const CoverLetterManager = ({
  coverLetterId,
  handleOpenPersonalDetailsSheet,
  onBack,
  updateCoverLetterId,
}: {
  coverLetterId?: number
  handleOpenPersonalDetailsSheet: () => void
  onBack: () => void
  updateCoverLetterId: (id: number) => void
}) => {
  const {
    profile: {
      country,
      gender,
      nationality,
      phoneNumber,
      dateOfBirth,
      careerAspiration,
      resume,
    },
    languages,
    academics: { qualifications },
  } = useContext(ProfileContext)

  const [showCheckList, setShowCheckList] = useState<boolean | null>(null)

  const checkList: CheckListType[] = useMemo(
    () => [
      {
        name: "Date of Birth",
        isCompleted: Boolean(dateOfBirth),
      },
      {
        name: "Phone Number",
        isCompleted: Boolean(phoneNumber),
      },
      {
        name: "Gender",
        isCompleted: Boolean(gender),
      },
      {
        name: "Nationality",
        isCompleted: Boolean(nationality),
      },
      {
        name: "Current Location",
        isCompleted: Boolean(country),
      },
      {
        name: "About",
        key: "about",
        isCompleted: Boolean(careerAspiration),
      },
      {
        name: "Qualifications",
        key: "qualifications",
        isCompleted: Boolean(qualifications.data.length),
      },
      {
        name: "Language",
        key: "language",
        isCompleted: Boolean(languages.data.length),
      },
      {
        name: "Resume",
        key: "resumeUpload",
        isCompleted: Boolean(resume),
      },
    ],
    [
      careerAspiration,
      country,
      dateOfBirth,
      gender,
      languages.data.length,
      nationality,
      phoneNumber,
      qualifications.data.length,
      resume,
    ]
  )

  useEffect(() => {
    const checkListProgress = () => {
      const filterCompleted = checkList.filter(
        item => item.isCompleted === true
      )
      return (100 * filterCompleted.length) / checkList.length
    }
    if (checkListProgress() === 100) {
      setShowCheckList(false)
    } else {
      setShowCheckList(true)
    }
  }, [checkList])

  if (!coverLetterId && showCheckList === null) {
    return <LoadingOverlay />
  }

  if (!coverLetterId && showCheckList) {
    return (
      <div>
        <TitleBar
          onClick={onBack}
          startAdornment={<ArrowLeft />}
          label="Back"
        />

        <CheckList
          checkList={checkList}
          handleOpenPersonalDetailsSheet={handleOpenPersonalDetailsSheet}
        />
      </div>
    )
  }

  return (
    <div>
      <Form
        coverLetterId={coverLetterId}
        onBack={onBack}
        updateCoverLetterId={updateCoverLetterId}
      />
    </div>
  )
}

export default CoverLetterManager
