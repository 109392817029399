import { toast, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  theme,
  Tooltip,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  ActivityUserResponse,
  MultipleChoiceActivity as MultipleChoiceActivity,
} from "api/resources/learningItems/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import { NavArrowLeft, NavArrowRight } from "iconoir-react"
import { useEffect, useState } from "react"

import ActivityContainer from "../ActivityContainer"
import ActivityResultBanner from "../ActivityResultBanner"
import ActivityItem from "./ActivityItem"

const calculatePercentage = (options: ActivityUserResponse[]) => {
  const total = options.length
  if (options.length === 0) {
    return 1
  }

  const answered = options.reduce((acc, curr) => {
    if (curr.markedAnswer !== null && curr.markedAnswer.length > 0) {
      return acc + 1
    }

    return acc
  }, 0)

  const percentage = (answered / total) * 100
  return Math.max(percentage, 1)
}

const buildResponses = (
  data: MultipleChoiceActivity["activity"]["activityItems"],
  responses: MultipleChoiceActivity["attemptItemResponses"]
): ActivityUserResponse[] => {
  return data.map(item => {
    const response = responses.find(r => r.activityItem === item.id)
    return {
      id: item.id,
      label: item.text,
      value: item.text,
      options: item.options,
      markedAnswer: response?.response || null,
      validation:
        response?.isCorrect == null
          ? null
          : response.isCorrect
          ? "correct"
          : "incorrect",
    }
  })
}

const getInitialQuestionIndex = (responses: ActivityUserResponse[]) => {
  return Math.max(
    responses.findIndex(
      item => item.markedAnswer === null || item.markedAnswer.length === 0
    ),
    0
  )
}
const scrollTo = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "center",
    })
  }
}
const MultipleChoice = ({
  data,
  reset,
  onBack,
}: {
  onBack: () => void
  reset: () => Promise<void>
  data: MultipleChoiceActivity
}) => {
  const [responses, setResponses] = useState<ActivityUserResponse[]>(
    buildResponses(data.activity.activityItems, data.attemptItemResponses)
  )

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    getInitialQuestionIndex(responses)
  )

  const [mode, setMode] = useState<"failed" | "success" | "attempt">("attempt")

  const markAnswer = (activityItemId: number, response: number[]) => {
    api.learningItems.activity.markAnswer({
      data: {
        activityItemId,
        response,
      },
      urlParams: {
        attemptId: data.id,
      },
    })
  }

  const submitActivity = useMutation({
    mutationFn: () =>
      api.learningItems.activity.submit({
        urlParams: {
          attemptId: data.id,
        },
      }),
    onSuccess: raw => {
      const data = raw as MultipleChoiceActivity
      setResponses(
        buildResponses(data.activity.activityItems, data.attemptItemResponses)
      )

      if (
        data.attemptItemResponses.every(answer => answer.isCorrect === true)
      ) {
        setMode("success")
      }

      if (data.attemptItemResponses.some(answer => answer.isCorrect !== true)) {
        setMode("failed")
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        if (err.errors.message) toast.error(err.errors.message)
      }
    },
  })

  const currentQuestion = responses[currentQuestionIndex]

  const percentage = calculatePercentage(responses)

  const handleReset = async () => {
    await reset()
    setMode("attempt")
  }

  const getProgressStatus = (item: ActivityUserResponse, index: number) => {
    if (item.validation === "correct") {
      return "success"
    }
    if (item.validation === "incorrect") {
      return "error"
    }
    if (currentQuestionIndex === index) {
      return "current"
    }
    if (item.markedAnswer !== null && item.markedAnswer.length > 0) {
      return "answered"
    }
    return "unanswered"
  }

  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    scrollTo(`progress-button-${currentQuestion.id}`)
  }, [currentQuestion.id])

  return (
    <ActivityContainer
      onBack={onBack}
      title={data.activity.title}
      endSlot={
        mode === "attempt" && !isSmDown ? (
          <Button
            disabled={percentage < 100}
            onClick={() => submitActivity.mutate()}
            loading={submitActivity.isLoading}
          >
            Submit
          </Button>
        ) : undefined
      }
    >
      <div className="flex space-x-2 overflow-x-auto pb-1">
        {responses.map((item, index) => {
          const id = `progress-button-${item.id}`
          const status = getProgressStatus(item, index)
          return (
            <button
              onClick={() => {
                setCurrentQuestionIndex(index)
              }}
              id={id}
              key={item.id}
              className={clsx(
                "flex grow cursor-pointer flex-col items-center",
                {
                  "text-primary-500": status === "current",
                  "text-interactive-400": status === "answered",
                  "text-onSurface-800": status === "unanswered",
                  "text-success-500": status === "success",
                  "text-critical-500": status === "error",
                }
              )}
            >
              <div
                className={clsx(
                  "mb-1.5 h-1 w-full min-w-[98px] rounded sm:h-2",
                  {
                    "bg-primary-500": status === "current",
                    "bg-interactive-400": status === "answered",
                    "bg-surface-200": status === "unanswered",
                    "bg-success-500": status === "success",
                    "bg-critical-500": status === "error",
                  }
                )}
              />

              <Typography variant="title4" className="text-center">
                {index + 1}
              </Typography>
            </button>
          )
        })}
      </div>

      {mode === "success" && (
        <ActivityResultBanner mode="success" action={async () => onBack()} />
      )}
      {mode === "failed" && (
        <ActivityResultBanner mode="failed" action={handleReset} />
      )}

      <div className="relative mt-4">
        {mode === "attempt" ? (
          <ActivityItem
            data={currentQuestion}
            className="justify-center"
            onSelect={selectedOption => {
              const markedAnswerIndex = currentQuestion.options.findIndex(
                x => x === selectedOption
              )

              if (markedAnswerIndex >= 0) {
                markAnswer(currentQuestion.id, [markedAnswerIndex])

                setResponses(prevState =>
                  prevState.map(x =>
                    x.id === currentQuestion.id
                      ? {
                          ...x,
                          markedAnswer: [markedAnswerIndex],
                        }
                      : x
                  )
                )
              }
            }}
          />
        ) : (
          <div className="flex flex-col divide-y">
            {responses.map(item => (
              <ActivityItem
                key={item.id}
                data={item}
                className="py-2"
                showIcon
              />
            ))}
          </div>
        )}

        {mode === "attempt" && (
          <>
            <Tooltip
              disabled={currentQuestionIndex !== 0}
              title="This is the first question"
            >
              <button
                disabled={currentQuestionIndex === 0}
                onClick={() => {
                  if (currentQuestionIndex > 0) {
                    setCurrentQuestionIndex(currentQuestionIndex - 1)
                  }
                }}
                className={clsx(
                  "absolute left-4 top-1/4 hidden -translate-y-1/4 rounded-full bg-onSurface-800 p-2 text-surface-500 md:block",
                  {
                    "!bg-onSurface-300": currentQuestionIndex === 0,
                  }
                )}
              >
                <NavArrowLeft />
              </button>
            </Tooltip>

            <Tooltip
              disabled={currentQuestionIndex !== responses.length - 1}
              title="This is the last question"
            >
              <button
                disabled={currentQuestionIndex === responses.length - 1}
                onClick={() => {
                  if (currentQuestionIndex < responses.length - 1) {
                    setCurrentQuestionIndex(currentQuestionIndex + 1)
                  }
                }}
                className={clsx(
                  "absolute right-4 top-1/4 hidden -translate-y-1/4 rounded-full bg-onSurface-800 p-2 text-surface-500 md:block",
                  {
                    "!bg-onSurface-300":
                      currentQuestionIndex === responses.length - 1,
                  }
                )}
              >
                <NavArrowRight />
              </button>
            </Tooltip>
          </>
        )}
      </div>

      {isSmDown && mode === "attempt" && (
        <div className="mt-6 flex justify-center gap-1">
          <Button
            fullWidth
            startAdornment={<NavArrowLeft />}
            variant="outlined"
            disabled={currentQuestionIndex === 0}
            onClick={() => {
              if (currentQuestionIndex > 0) {
                setCurrentQuestionIndex(currentQuestionIndex - 1)
              }
            }}
            className={clsx({
              "!bg-onSurface-300": currentQuestionIndex === 0,
            })}
          >
            Previous
          </Button>
          {currentQuestionIndex === responses.length - 1 ? (
            <Button
              fullWidth
              disabled={percentage < 100}
              onClick={() => submitActivity.mutate()}
              loading={submitActivity.isLoading}
            >
              Submit Activity
            </Button>
          ) : (
            <Button
              endAdornment={<NavArrowRight />}
              fullWidth
              variant="outlined"
              disabled={currentQuestionIndex === responses.length - 1}
              onClick={() => {
                if (currentQuestionIndex < responses.length - 1) {
                  setCurrentQuestionIndex(currentQuestionIndex + 1)
                }
              }}
              className={clsx({
                "!bg-onSurface-300":
                  currentQuestionIndex === responses.length - 1,
              })}
            >
              Next
            </Button>
          )}
        </div>
      )}
    </ActivityContainer>
  )
}

export default MultipleChoice
