import { HandoutGeneratorType } from "api/resources/aiTools/types"
import { useContext } from "react"

import { AIToolsContext } from "../../context"
import InputDisplay from "../../helpers/InputDisplay"
import HandoutContent from "./HandoutContent"

const HandoutOutput = (props: HandoutGeneratorType) => {
  const { overviewData } = useContext(AIToolsContext)
  return (
    <div className="mb-4 space-y-4">
      {overviewData && <InputDisplay data={overviewData} />}
      <HandoutContent {...props} defaultOpen />
    </div>
  )
}

export default HandoutOutput
