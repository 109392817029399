export const getDeletionImplications = ({
  skillVisibilityWarning = false,
  jobsAccessWarning = false,
}) => {
  let message: string | null = null

  const skillWarningMessage =
    "'Skills' section will be hidden from your public profile. Ensure your profile includes at least one academic or work experience."
  const jobsAccessMessage =
    "You will lose your access to Jobs Platform. To get back your access you'll need to have at least one qualification in your profile."

  if (skillVisibilityWarning) {
    message = skillWarningMessage
  }

  if (jobsAccessWarning) {
    message = jobsAccessMessage
  }

  if (jobsAccessWarning && skillVisibilityWarning) {
    message = `By doing so:\n\n- ${skillWarningMessage}\n\n- ${jobsAccessMessage}`
  }

  return message
}
