import { Typography } from "@suraasa/placebo-ui"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Tag,
} from "@suraasa/placebo-ui-legacy"
import capitalize from "lodash/capitalize"
import startCase from "lodash/startCase"
import React from "react"
import { pluralize } from "utils/helpers"

import { WarningType } from "../../Proctoring"

type Props = {
  open: boolean
  handleClose: () => void
  parentSelector?: () => void
  onSubmit: () => void
  unattemptedQuestionsCount: number
  unreadQuestionsCount: number
  markedForReviewQuestionCount: number
  warnings?: WarningType
  totalWarnings?: number
}

const SubmitConfirmationDialog = ({
  open,
  warnings,
  parentSelector,
  unattemptedQuestionsCount,
  unreadQuestionsCount,
  markedForReviewQuestionCount,
  onSubmit,
  totalWarnings,
  handleClose,
}: Props) => {
  const warningDisplay = Object.entries(warnings || {}).map(e => ({
    name: capitalize(startCase(e[0])),
    value: e[1],
  }))

  return (
    <Dialog
      open={open}
      parentSelector={parentSelector}
      onRequestClose={handleClose}
    >
      <DialogTitle>
        <Typography variant="subtitle2">Submit Assessment?</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className="mb-1.25">
          Are you sure you want to submit the assessment? This action cannot be
          reversed.
          {(unattemptedQuestionsCount > 0 ||
            unreadQuestionsCount > 0 ||
            markedForReviewQuestionCount > 0) &&
            "\n\nYou still have:"}
        </Typography>
        {markedForReviewQuestionCount > 0 && (
          <Tag
            color="warning"
            label={`${pluralize(
              "Question",
              markedForReviewQuestionCount
            )} Marked for Review`}
            size="md"
            className="mb-1.25"
          />
        )}
        {unattemptedQuestionsCount > 0 && (
          <Tag
            color="warning"
            label={`${pluralize(
              "Question",
              unattemptedQuestionsCount
            )} Unanswered`}
            size="md"
            className="mb-1.25"
          />
        )}
        {unreadQuestionsCount > 0 && (
          <Tag
            color="onSurface"
            label={`${pluralize("Question", unreadQuestionsCount)} Unread`}
            size="md"
            className="mb-1.25"
          />
        )}

        {Boolean(totalWarnings) && (
          <div className="mt-2 border-t-2 pt-1">
            <Typography variant="largeBody" className="mb-0.5">
              Total Violations: {totalWarnings}
            </Typography>
            {warningDisplay.map(e =>
              e.value > 0 ? (
                <Typography
                  key={e.name}
                  variant="smallBody"
                  className="text-onSurface-500"
                >
                  {e.name}: {e.value}
                </Typography>
              ) : null
            )}
          </div>
        )}
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Submit",
            onClick: onSubmit,
          },
        }}
      />
    </Dialog>
  )
}

export default SubmitConfirmationDialog
