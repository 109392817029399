import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import clsx from "clsx"
import Paper from "features/AItools/components/Paper"
import { useContext } from "react"

import { AIToolsContext } from "../../../context"
import MarkdownRenderer from "../../MarkdownRenderer"
import RegenerateOutput from "../../RegenerateOutput"

const UnitPlanTopicOutput = ({
  refetchPromptData,
}: {
  refetchPromptData: () => void
}) => {
  const { currentResponseId } = useContext(AIToolsContext)

  const unitPlanResponse = useQuery({
    queryKey: queries.aiTools.unitPlanResponse(currentResponseId).queryKey,
    queryFn: () =>
      api.aiTools.retrieveUnitPlanResponse({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: currentResponseId!,
        },
      }),
    enabled: Boolean(currentResponseId),
  })

  return (
    <Paper
      className="mb-3 !p-0"
      endSlot={
        <RegenerateOutput
          isPositiveResponse={unitPlanResponse.data?.isPositiveResponse}
          copyText={unitPlanResponse.data?.output}
          refetchOverviewData={refetchPromptData}
        />
      }
    >
      <div className={clsx("overflow-x-auto px-2")}>
        <MarkdownRenderer>{unitPlanResponse.data?.output}</MarkdownRenderer>
      </div>
    </Paper>
  )
}

export default UnitPlanTopicOutput
