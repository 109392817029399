import { AxiosRequestConfig, AxiosResponse } from "axios"

import { APIError } from "./utils"

/**
 * Data type for `204 No Content` response
 */
export type NoContentType = null

/**
 * Contains the original response returned by axios.
 * Is useful when the consumer would like to access
 * other properties of the request/response which is very rare
 */
type OriginalResponse = {
  __data?: AxiosResponse
}

/**
 * Success response format
 * for codes in the 2xx range
 */
export type APIResponse<T> = T & OriginalResponse

/**
 * @deprecated
 */
export type APIResponseOld<T> =
  | ({
      data: T
      isSuccessful: true
    } & OriginalResponse)
  | ({ isSuccessful?: false } & OriginalResponse & APIError)

/**
 * Success response format with pagination
 * for codes in the 2xx range
 */
export type PaginatedAPIResponse<T> = {
  data: T
  total: number
  nextPage: null | number
  previousPage: null | number
} & OriginalResponse

/**
 * `POST` API Arguments
 */
export type Config = Pick<
  AxiosRequestConfig,
  | "data"
  | "headers"
  | "params"
  | "cancelToken"
  | "onUploadProgress"
  | "onDownloadProgress"
  | "responseType"
>

type ParamType = string | number
/**
 * `PUT/PATCH/DELETE` API arguments with any custom URL params
 * Example:
 * `/profile/${id}/`
 *             ^^ This `id` is extracted from `urlParams`
 */
export type ConfigWithURLParams<Keys extends string = string> = Config & {
  urlParams: {
    [key in Keys]: ParamType
  }
}

type UrlFunction = (...args: ParamType[]) => string
type UrlKey = Record<string, UrlFunction>

export function validateUrls<T extends UrlKey | Record<string, UrlKey>>(
  urls: T
): T {
  return urls
}
