import { toast, Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  ActivityUserResponse,
  TextMatchingActivity,
} from "api/resources/learningItems/types"
import { APIError } from "api/utils"
import SlidingSheet from "components/SlidingSheet"
import { useState } from "react"
import { splitStringWithPattern } from "utils/helpers"

import ActivityButton, { ActivityButtonState } from "../ActivityButton"
import ActivityContainer from "../ActivityContainer"
import ActivityResultBanner from "../ActivityResultBanner"
import Options from "./Options"

const getButtonState = (option: ActivityUserResponse): ActivityButtonState => {
  if (option.validation === "incorrect") {
    return "error"
  }
  if (option.validation === "correct") {
    return "success"
  }
  if (option.markedAnswer !== null) {
    return "selected"
  }

  return "default"
}

const calculatePercentage = (options: ActivityUserResponse[]) => {
  const total = options.length
  if (options.length === 0) {
    return 1
  }

  const answerCount = options.filter(
    item => item.markedAnswer && item.markedAnswer.length !== 0
  ).length

  const percentage = (answerCount / total) * 100
  return percentage > 0 ? percentage : 1
}

const buildResponses = (
  data: TextMatchingActivity["activity"]["activityItems"],
  responses: TextMatchingActivity["attemptItemResponses"]
): ActivityUserResponse[] => {
  return data.map(item => {
    const response = responses.find(r => r.activityItem === item.id)
    return {
      id: item.id,
      label: item.text,
      value: item.text,
      options: item.options,
      markedAnswer: response?.response || null,
      validation:
        response?.isCorrect == null
          ? null
          : response.isCorrect
          ? "correct"
          : "incorrect",
    }
  })
}

const getMarkedAnswer = (
  option: ActivityUserResponse | null
): string | null => {
  if (!option) return null

  return option.markedAnswer && option.markedAnswer.length > 0
    ? option.options[option.markedAnswer[0]]
    : null
}

const TextMatching = ({
  data,
  reset,
  onBack,
}: {
  onBack: () => void
  data: TextMatchingActivity
  reset: () => void
}) => {
  const [responses, setResponses] = useState<ActivityUserResponse[]>(
    buildResponses(data.activity.activityItems, data.attemptItemResponses)
  )
  const [selectedActivityItem, setSelectedActivityItem] =
    useState<ActivityUserResponse | null>(null)

  const [mode, setMode] = useState<"failed" | "success" | "attempt">("attempt")

  const paraWords = data.activity.questionText?.split(" ") || []

  const markAnswer = (activityItemId: number, response: number[]) => {
    api.learningItems.activity.markAnswer({
      data: {
        activityItemId,
        response,
      },
      urlParams: {
        attemptId: data.id,
      },
    })
  }

  const submitActivity = useMutation({
    mutationFn: () =>
      api.learningItems.activity.submit({
        urlParams: {
          attemptId: data.id,
        },
      }),
    onSuccess: raw => {
      const data = raw as TextMatchingActivity
      setResponses(
        buildResponses(data.activity.activityItems, data.attemptItemResponses)
      )

      if (
        data.attemptItemResponses.every(answer => answer.isCorrect === true)
      ) {
        setMode("success")
      }

      if (data.attemptItemResponses.some(answer => answer.isCorrect !== true)) {
        setMode("failed")
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        if (err.errors.message) toast.error(err.errors.message)
      }
    },
  })

  const handleReset = async () => {
    await reset()
    setMode("attempt")
  }

  const percentage = calculatePercentage(responses)

  return (
    <ActivityContainer
      onBack={onBack}
      title={data.activity.title}
      progress={mode === "attempt" ? percentage : 100}
      endSlot={
        mode === "attempt" ? (
          <Button
            disabled={percentage < 100}
            onClick={() => submitActivity.mutate()}
            loading={submitActivity.isLoading}
          >
            Submit
          </Button>
        ) : undefined
      }
    >
      <Typography className="mb-3 mt-1" variant="largeBody">
        Click the highlighted words and select the correct option
      </Typography>
      {mode === "success" && (
        <ActivityResultBanner mode="success" action={async () => onBack()} />
      )}
      {mode === "failed" && (
        <ActivityResultBanner mode="failed" action={handleReset} />
      )}
      <div className="flex flex-wrap items-center gap-2">
        {paraWords.map((word, index) => {
          const enclosedWord = (word.match(/%%(.*?)%%/g) ?? [])[0]

          if (enclosedWord) {
            const punctuation = splitStringWithPattern(word)[1]

            const btnText = enclosedWord.replace(/%%/g, "")

            const activityItem = responses.find(obj => obj.value === btnText)

            if (!activityItem)
              throw new Error("option <-> text mapping seems wrong")

            const markedAnswer =
              activityItem?.markedAnswer && activityItem.markedAnswer.length > 0
                ? activityItem?.options[activityItem.markedAnswer[0]]
                : null

            return (
              <ActivityButton
                onClick={() => {
                  setSelectedActivityItem(activityItem)
                }}
                disabled={
                  mode !== "attempt" || activityItem.validation === "correct"
                }
                key={index}
                endAdornment={punctuation}
                state={getButtonState(activityItem)}
              >
                {btnText}{" "}
                {markedAnswer && (
                  <span className="text-xs font-normal italic leading-[130%]">
                    ({markedAnswer.toLowerCase()})
                  </span>
                )}
              </ActivityButton>
            )
          }
          return word + " "
        })}
      </div>
      <SlidingSheet
        open={Boolean(selectedActivityItem)}
        onClose={() => setSelectedActivityItem(null)}
      >
        <Options
          onSelect={selectedOption => {
            if (selectedActivityItem) {
              const markedAnswerIndex = selectedActivityItem.options.findIndex(
                x => x === selectedOption
              )

              if (markedAnswerIndex >= 0) {
                markAnswer(selectedActivityItem.id, [markedAnswerIndex])
                setResponses(prevState =>
                  prevState.map(prev =>
                    prev.value === selectedActivityItem.value
                      ? {
                          ...prev,
                          markedAnswer: [markedAnswerIndex],
                        }
                      : prev
                  )
                )
              }
            }
          }}
          openFor={selectedActivityItem?.value || null}
          options={selectedActivityItem?.options || []}
          selectedOption={getMarkedAnswer(selectedActivityItem)}
          onClose={() => {
            setSelectedActivityItem(null)
          }}
        />
      </SlidingSheet>
    </ActivityContainer>
  )
}

export default TextMatching
