import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"

import styles from "./DomainReports.module.css"

const NotYetReady = () => {
  return (
    <div
      className={clsx(
        "rounded-xl px-2 py-3 sm:px-4",
        styles.notYetReadyContainer
      )}
    >
      <div className="relative z-1">
        <Typography
          variant="title2"
          className={clsx("text-center", styles.notYetReadyTitle)}
        >
          Your personalised reports are arriving here soon!
        </Typography>
      </div>

      <div className="absolute left-0 top-0 size-full bg-gradient-to-t from-onSurface-100 from-30% to-white/100 to-100%" />
    </div>
  )
}

export default NotYetReady
