import { DialogBody, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Dialog,
  DialogFooter,
  DialogTitle,
} from "@suraasa/placebo-ui-legacy"

const NoLessonPlannerDialog = ({
  open,
  onClose,
  onCreateFromScratch,
  onCreateLessonPlan,
  description,
}: {
  open: boolean
  onClose: () => void
  onCreateFromScratch?: () => void
  onCreateLessonPlan?: () => void
  description?: string
}) => {
  return (
    <Dialog open={open} onRequestClose={onClose}>
      <DialogTitle>No Lesson Plans Found</DialogTitle>
      <DialogBody>
        <Typography>
          {description ||
            "Looks like you haven’t generated any Lesson Plans. You can either generate a Lesson Plan to create an assessment from or create an assessment from scratch."}
        </Typography>
      </DialogBody>
      <DialogFooter>
        <div className="flex items-center justify-end">
          {onCreateFromScratch && (
            <Button
              className="me-1"
              size="sm"
              variant="outlined"
              color="black"
              onClick={onCreateFromScratch}
            >
              Generate from Scratch
            </Button>
          )}
          {onCreateLessonPlan && (
            <Button size="sm" onClick={onCreateLessonPlan}>
              Generate Lesson Plan
            </Button>
          )}
        </div>
      </DialogFooter>
    </Dialog>
  )
}

export default NoLessonPlannerDialog
