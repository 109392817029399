import { Avatar, Typography } from "@suraasa/placebo-ui"
import { Radio, theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { CoverStyle } from "api/resources/profile/types"
import ProfileContext from "features/Profile/context"
import { getCoverImageURL } from "features/Profile/utils"
import { useContext } from "react"
import { buildUserName } from "utils/helpers"

const Card = ({
  title,
  type,
  onClick,
  coverStyleType,
}: {
  title: string
  type: CoverStyle
  onClick: () => void
  coverStyleType: CoverStyle
}) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const { profile } = useContext(ProfileContext)

  const fullName = buildUserName(profile.user)

  return (
    <button
      onClick={onClick}
      className="relative h-[169.84px] w-full overflow-hidden rounded-lg border border-surface-200 bg-white pl-2 pt-2 md:max-w-[300px]"
    >
      <div className="relative size-full overflow-hidden rounded-tl-[5.25px] border-[0.5px] border-solid border-slate-200 bg-surface-50 shadow-[1px_1px_5px_0px_#0000001A]">
        <div className="flex h-1.75 w-full items-center space-x-0.25 bg-white px-0.75 py-0.5">
          <div className="size-0.75 rounded-full bg-[#ED6A5F]" />
          <div className="size-0.75 rounded-full bg-[#F5BF4F]" />
          <div className="size-0.75 rounded-full bg-[#61C554]" />
        </div>
        <div
          style={{
            backgroundImage: `url(${getCoverImageURL(type, isXsDown)})`,
          }}
          className="h-4 w-full bg-cover"
        />
        <div className="relative h-7 bg-white">
          <Avatar
            className="absolute -top-2 left-3 size-4.5 shadow-[0px_1.4px_3.5px_0px_#0000000D]"
            src={profile.picture}
            name={fullName}
          />

          <div className="absolute left-3 top-2.75">
            <div className="mb-0.5 h-0.75 w-7 rounded-full bg-onSurface-700" />
            <div className="mb-px h-0.5 w-8 rounded-full bg-onSurface-400" />
            <div className="h-0.5 w-8 rounded-full bg-onSurface-400" />
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 flex w-full items-center justify-between bg-surface-100 px-1 py-0.5">
        <Typography variant="strongSmallBody" className="capitalize">
          {title}
        </Typography>
        <Radio checked={coverStyleType === type} />
      </div>
    </button>
  )
}

export default Card
