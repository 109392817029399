// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import data from "./tool_data.json"

const generateMarkdown = (item: any) => {
  try {
    switch (item.tool_type) {
      case "lesson_plan":
        return lessonPlannerMarkdownFormat({
          output: item.response_data_output,
          title: item.title,
        })
      case "assignment":
        return assignmentMarkdownFormat({
          output: item.response_data_output,
          overviewData: item.prompt_data,
        })
      case "handout":
        return handoutMarkdownFormat({
          output: item.response_data_output,
          title: item.title,
        })
      case "narration":
        return narrationMarkdownFormat({
          output: item.response_data_output,
          title: item.title,
        })
      case "quiz":
        return quizMarkdownFormat({
          output: item.response_data_output,
          title: item.title,
        })
      case "rubrics":
        return rubricMarkdownFormat({
          output: item.response_data_output,
          title: item.title,
        })
      case "subjective_assessment":
        return subjectiveAssessmentMarkdownFormat({
          output: item.response_data_output,
          overviewData: item.prompt_data,
          title: item.title,
        })
      default:
        return ""
    }
  } catch (error) {
    return ""
  }
}

function pluralize(
  word: string,
  count: number,
  {
    endsWithVowel,
    skipCount,
    plural,
  }: {
    endsWithVowel?: boolean
    skipCount?: boolean
    plural?: string
  } = {
    endsWithVowel: false,
    skipCount: false,
    plural: "",
  }
) {
  let str = `${count} `

  if (skipCount) {
    str = ""
  }

  if (plural) {
    return `${count !== 1 ? `${str}${plural}` : `${str}${word}`}`
  }

  return `${
    count !== 1 ? `${str}${word}${endsWithVowel ? "es" : "s"}` : `${str}${word}`
  }`
}

const capitalizeFirstLetter = (str?: string) => {
  if (typeof str !== "string" || !str) return ""
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const formatBullet = (
  text: string,
  options?: { bold?: boolean; capitalise?: boolean }
) => {
  options = {
    bold: options?.bold === undefined ? false : options.bold,
    capitalise: options?.capitalise === undefined ? true : options.capitalise,
  }

  if (!text) return ""

  if (text.startsWith("- ")) {
    text = text.replace("- ", "")
  }

  if (options.capitalise) {
    text = capitalizeFirstLetter(text)
  }

  if (options.bold) return `- **${capitalizeFirstLetter(text)}**`
  return `- ${capitalizeFirstLetter(text)}`
}

export const lessonPlannerMarkdownFormat = ({
  output,
  title,
}: {
  output: any
  title: any
}) => {
  const markdownFormatSection = (title: string, data: any): string => {
    if (!data) return ""

    if (Array.isArray(data) && data.length === 0) return ""

    if (Array.isArray(data)) {
      const formattedData = data
        .filter(Boolean)
        .map(item => {
          if (typeof item === "string") {
            if (item.startsWith("- ")) {
              item = item.replace("- ", "")
            }
            return `- ${capitalizeFirstLetter(item)}`
          }
          if (item.description && typeof item.description === "string") {
            const { title, description } = item
            return `\n#### ${capitalizeFirstLetter(
              title
            )}\n${capitalizeFirstLetter(description)}`
          } else {
            const { title, description } = item
            return `\n#### ${capitalizeFirstLetter(title)}\n${description
              .filter(Boolean)
              .map((desc: string) => {
                if (desc.startsWith("- ")) {
                  desc = desc.replace("- ", "")
                }
                return `- ${capitalizeFirstLetter(desc)}`
              })
              .join("\n")}`
          }
        })
        .join("\n")

      return `### ${title}\n${formattedData}`
    } else {
      return `### ${title}\n${data}`
    }
  }

  if (Array.isArray(output)) {
    return output.map(item =>
      lessonPlannerMarkdownFormat({ output: item, title })
    )
  }

  if (output.content) {
    output = {
      ...output,
      ...output.content,
      content: undefined,
    }
  }

  const learningObjectives = markdownFormatSection(
    "Learning Objectives",
    output.learning_objectives
  )

  const materialNeeded = markdownFormatSection(
    "Materials Needed",
    output.materials
  )
  const lessonOutline = markdownFormatSection(
    "Lesson Outline",
    output.lesson_outline
  )
  const assessment = markdownFormatSection("Assessment", output.assessment)
  const rubrics = markdownFormatSection("Rubrics", output.rubric_for_evaluation)
  const applications = markdownFormatSection(
    "Applications",
    output.applications
  )
  const teachingStrategies = markdownFormatSection(
    "Teaching Strategies",
    output.teaching_strategies
  )
  const successMetrics = markdownFormatSection(
    "Success Metrics",
    output.success_metrics
  )
  const followUp = markdownFormatSection("Follow Up", output.follow_up)
  const nextSteps = markdownFormatSection("Next Steps", output.next_steps)

  return [
    title ? `## ${capitalizeFirstLetter(title)}` : "",
    learningObjectives,
    materialNeeded,
    lessonOutline,
    assessment,
    rubrics,
    applications,
    teachingStrategies,
    successMetrics,
    followUp,
    nextSteps,
  ]
    .filter(Boolean)
    .join("\n\n")
}

export const handoutMarkdownFormat = ({
  output,
  title,
}: {
  output: any
  title: any
}) => {
  function markdownFormatSection(title: string, data: any[]): string {
    if (!data) return ""

    if (Array.isArray(data) && data.length === 0) return ""

    const formattedData = data
      .filter(Boolean)
      .map((item: any) => {
        if (typeof item === "string") {
          return formatBullet(item)
        }

        if (item.title && item.content && Array.isArray(item.content))
          return `\n#### ${capitalizeFirstLetter(item.title)}\n\n${item.content
            .filter(Boolean)
            .map((i: any) => {
              if (i.title) {
                return `${formatBullet(i.title + ":", {
                  bold: true,
                })}\n  ${capitalizeFirstLetter(i.text)}`
              }

              return formatBullet(i.text)
            })
            .join("\n")}`

        if (item.title && item.content && typeof item.content === "string")
          return `\n#### ${capitalizeFirstLetter(item.title)}\n\n${
            item.content
          }`
      })
      .join("\n")

    return title ? `### ${title}\n${formattedData}` : formattedData
  }
  return [
    title ? `## ${capitalizeFirstLetter(title)}` : "",
    markdownFormatSection("", output.sections),
    markdownFormatSection("Additional Notes", output.additional_note),
  ]
    .filter(Boolean)
    .join("\n\n")
}

export const rubricMarkdownFormat = ({
  output,
  title,
}: {
  output: any
  title?: string
}) => {
  const markdownFormatSection = (data: any) => {
    if (!data) return ""

    if (Array.isArray(data) && data.length === 0) return ""

    const formattedData = data.criteria
      .filter(Boolean)
      .map(item => {
        return `\n#### ${capitalizeFirstLetter(item.criterion)}\n\n${item.points
          .filter(Boolean)
          .sort((a, b) => a.point - b.point)
          .map(i => {
            return `- **${pluralize(
              "Point",
              i.point
            )}:**\n  ${capitalizeFirstLetter(i.comments)}`
          })
          .join("\n\n")}`
      })
      .join("\n")

    return formattedData
  }

  return [
    title ? `## ${capitalizeFirstLetter(title)}` : undefined,
    markdownFormatSection(output),
  ]
    .filter(Boolean)
    .join("\n\n")
}

export const quizMarkdownFormat = ({
  output,
  title,
}: {
  output: any
  title?: string
}) => {
  const getCorrectAnswer = (question: any, correct_answers: any) => {
    return (
      correct_answers?.find(
        item => item.question_number === question.question_number
      )?.correct_answer ??
      question.correct_answer ??
      ""
    )
  }

  function markdownFormatSection(data: any): string {
    if (!data) return ""

    const formattedData = data.questions
      .filter(Boolean)
      .map((item, index) => {
        const correctAnswer = getCorrectAnswer(item, data.correct_answers)
        return `#### Question ${index + 1}\n\n${capitalizeFirstLetter(
          item.question
        )}\n\n${item.options
          ?.map((option, index) => {
            return `- ${String.fromCharCode(index + 97)}) ${option}`
          })
          .join("\n")}\n\n**Correct Answer:** ${correctAnswer}`
      })
      .join("\n\n")

    return formattedData
  }
  return [
    title ? `## ${capitalizeFirstLetter(title)}` : "",
    markdownFormatSection(output),
  ]
    .filter(Boolean)
    .join("\n\n")
}

const formatDuration = (duration: string) => {
  if (!duration.includes(":")) {
    return duration
  }
  const [h, m, _] = duration.split(":").map(x => parseInt(x))

  if (h !== 0) {
    if (m !== 0) {
      return `${pluralize("hour", h)} ${pluralize("minute", m)}`
    }

    return pluralize("hour", h)
  }

  if (m !== 0) {
    return pluralize("minute", m)
  }
}

export const narrationMarkdownFormat = ({
  output,
  title,
}: {
  output: any
  title?: string
}) => {
  return [
    title ? `## ${capitalizeFirstLetter(title)}` : "",
    output.content
      .map(item => {
        const title = item.duration
          ? `${capitalizeFirstLetter(item.title)} (${formatDuration(
              item.duration
            )})`
          : capitalizeFirstLetter(item.title)
        return `\n#### ${capitalizeFirstLetter(title)}\n${item.narration
          .filter(Boolean)
          .map((desc: string) => formatBullet(desc))
          .join("\n")}`
      })
      .join("\n"),
  ]
    .filter(Boolean)
    .join("\n\n")
}

export const subjectiveAssessmentMarkdownFormat = ({
  output,
  title,
  overviewData,
}: {
  output: any
  title?: string
  overviewData?: any
}) => {
  const markdownFormatTotalQuestionsAndMarks = (data?: any) => {
    if (!data) return ""

    return [
      data.total_questions
        ? `**Total Questions:** ${data.total_questions}`
        : "",
      data.total_marks ? `**Total Marks:** ${data.total_marks}` : "",
    ]
      .filter(Boolean)
      .join("\n\n")
  }

  const markdownFormatHowToUseRubrics = (title: any, data: any) => {
    if (!data) return ""

    const formatData = data
      .filter(Boolean)
      .map(item => {
        if (item.title && item.description) {
          return `- **${capitalizeFirstLetter(
            item.title
          )}**\n  ${capitalizeFirstLetter(item.description)}\n`
        }
      })
      .join("\n")

    return `### ${title}\n${formatData}`
  }

  const markdownFormatQuestions = (data: any) => {
    if (Array.isArray(data) && data.length === 0) return ""

    return data
      .filter(Boolean)
      .map(item => {
        const markQuestion = `**${capitalizeFirstLetter(
          pluralize("Mark", item.marks)
        )} ${capitalizeFirstLetter(
          pluralize("Question", item.questions.length, { skipCount: true })
        )}**`
        const questions = item.questions
          .filter(Boolean)
          .map((item, index) => {
            return `### Question ${index + 1}:\n\n${capitalizeFirstLetter(
              item.question_text
            )}\n\n${item.rubric.points
              .filter(Boolean)
              .map(
                item =>
                  `- **${capitalizeFirstLetter(
                    pluralize("Mark", item.point)
                  )}**: ${capitalizeFirstLetter(item.comments)}`
              )
              .join("\n")}`
          })
          .join("\n\n")
        return [markQuestion, questions].filter(Boolean).join("\n")
      })
      .join("\n\n")
  }
  return [
    title ? `## ${capitalizeFirstLetter(title)}` : ``,
    markdownFormatTotalQuestionsAndMarks(overviewData),
    markdownFormatHowToUseRubrics(
      "How to use rubrics",
      output.how_to_use_rubrics
    ),
    markdownFormatQuestions(output.assessments),
  ]
    .filter(Boolean)
    .join("\n\n")
}

export const assignmentMarkdownFormat = ({
  output,
  overviewData,
}: {
  output: any
  overviewData?: any
}) => {
  const markdownFormatString = (title: any, data: any) => {
    if (!data) return ""

    return `### ${title}\n${data}`
  }

  const markdownFormatDescription = (title: any, data: any) => {
    if (Array.isArray(data) && data.length === 0) return ""
    const formatData = data
      .filter(Boolean)
      .map(
        item =>
          `#### ${capitalizeFirstLetter(item.title)}\n${capitalizeFirstLetter(
            item.description
          )}`
      )
      .join("\n\n")

    return `### ${title}\n${formatData}`
  }

  const markdownFormatRubric = (title: any, data: any) => {
    if (!data) return ""
    if (Array.isArray(data) && data.length === 0) return ""

    const formattedData = data
      .filter(Boolean)
      .map(item => {
        return `#### ${capitalizeFirstLetter(item.criterion)}\n\n${item.scale
          .filter(Boolean)
          .sort((a, b) => a.point - b.point)
          .map(i => {
            return `- **${pluralize(
              "Point",
              i.point
            )}:**\n ${capitalizeFirstLetter(i.rubric)}\n`
          })
          .join("")}`
      })
      .join("\n")

    return `### ${title}\n${formattedData}`
  }

  const markdownFormatAdditionalNotes = (title: any, data: any) => {
    if (!data) return ""

    const formatData = data
      .filter(Boolean)
      .map(item => `- ${capitalizeFirstLetter(item)}`)
      .join("\n")

    return `### ${title}\n${formatData}`
  }

  return [
    overviewData?.title
      ? `## ${capitalizeFirstLetter(overviewData?.title)}`
      : "",
    markdownFormatString("Objective", output.objective),
    markdownFormatDescription(
      "Description",
      output.assignment_description.sections
    ),
    markdownFormatString("Format", output.assignment_description.format),
    markdownFormatRubric(
      `Rubrics (Scale of ${overviewData?.rubric_scale})`,
      output.rubric
    ),
    markdownFormatAdditionalNotes(
      "Additional Instructions",
      output.additional_instructions
    ),
  ]
    .filter(Boolean)
    .join("\n\n")
}
