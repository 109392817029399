import { Typography } from "@suraasa/placebo-ui"
import { Container } from "@suraasa/placebo-ui-legacy"
import { useOlympiadPrices } from "features/ITO/utils/hooks/useOlympiadPrices"
import React from "react"

import CashPrizeCard from "./CashPrizeCard"
import ImpactAwardCard from "./ImpactAwardCard"
import ITUCard from "./ITUcard"

type Props = {
  isTop100: boolean
  isTop800: boolean
  isTop1Percentile: boolean
}
const YourRewards = ({ isTop100, isTop1Percentile, isTop800 }: Props) => {
  const { getPrice } = useOlympiadPrices({ edition: "2023" })

  const top100Prize = getPrice("cashPrizeBronze")

  if (isTop100 || isTop1Percentile || isTop800)
    return (
      <Container className="pb-6 sm:pb-5 md:pb-9">
        <Typography variant="title3" className="mb-3">
          Exclusive Rewards for You
        </Typography>
        <div className="grid gap-3">
          {isTop100 && <CashPrizeCard prize={top100Prize} />}
          {(isTop1Percentile || isTop100) && <ImpactAwardCard />}
          {(isTop800 || isTop100) && <ITUCard />}
        </div>
      </Container>
    )

  return null
}

export default YourRewards
