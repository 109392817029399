import { Button, Tag, TagColors, Tooltip } from "@suraasa/placebo-ui-legacy"
import { OrderStatus, PaymentPlanStatus } from "api/resources/orders/types"
import { OrderItemType } from "api/resources/orders/types"
import { RefundStatus } from "api/resources/payment/types"
import { Link } from "react-router-dom"

export const getStatusTag = (
  status: OrderStatus,
  refundStatus: RefundStatus | null
): JSX.Element => {
  switch (refundStatus) {
    case RefundStatus.refundInitiated:
      return (
        <div>
          <Tooltip title="Amount will be refunded into your account within 10-15 business days.">
            <div>
              <Tag color="primary" label="Refund Initiated" />
            </div>
          </Tooltip>
        </div>
      )

    case RefundStatus.refunded:
      return <Tag label="Refunded" color="success" />
  }

  switch (status) {
    case OrderStatus.SUCCESSFUL:
      return <Tag label="Successful" color="success" />

    case OrderStatus.IN_PROGRESS:
      return <Tag label="In Progress" color="primary" />

    case OrderStatus.NOT_INITIATED:
      return <Tag label="Not Initiated" color="warning" />

    case OrderStatus.CANCELLED:
      return <Tag label="Cancelled" color="critical" />

    case OrderStatus.FAILED:
      return <Tag label="Payment Failed" color="critical" />
  }
}

export const getPaymentPlanTag = (
  status: PaymentPlanStatus
): { label: string; color: TagColors } => {
  switch (status) {
    case PaymentPlanStatus.PAID:
      return { label: "Paid", color: "success" }
    case PaymentPlanStatus.UNPAID:
      return { label: "Unpaid", color: "critical" }
    case PaymentPlanStatus.DUE:
      return { label: "Due", color: "onSurface" }
  }
}
export const getOrderItemAction = ({
  orderStatus,
  itemType,
  itemSlug,
  itemsAllocated,
}: {
  orderStatus: OrderStatus
  itemType: OrderItemType
  itemSlug: string | null
  itemsAllocated: boolean
}) => {
  if (orderStatus !== OrderStatus.SUCCESSFUL) {
    return null
  }

  const actionableItemTypes = [
    OrderItemType.QUALIFICATION,
    OrderItemType.CERTIFICATION,
    OrderItemType.COURSE,
  ]

  if (!actionableItemTypes.includes(itemType)) return

  if (itemsAllocated) {
    const itemTypeNames = {
      [OrderItemType.QUALIFICATION]: "Qualification",
      [OrderItemType.CERTIFICATION]: "Certification",
      [OrderItemType.COURSE]: "Course",
    }

    const itemTypeName = itemTypeNames[itemType as keyof typeof itemTypeNames]

    return (
      <Button
        component={Link}
        to={`/${itemType}/${itemSlug}/overview/`}
        variant="text"
      >
        Start {itemTypeName}
      </Button>
    )
  }

  return (
    <Tooltip
      maxWidth="200px"
      title="It will be allocated to you within 30 minutes"
    >
      <div>
        <Tag color="warning" label="Allocating Product" />
      </div>
    </Tooltip>
  )
}
