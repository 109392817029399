import { Typography } from "@suraasa/placebo-ui"
import { Divider } from "@suraasa/placebo-ui-legacy"
import { UserAcademicStatus } from "api/resources/profile/types"
import ProfileContext from "features/Profile/context"
import { ProfileQuickAccessTabs } from "features/Profile/utils"
import { Fragment, useContext } from "react"

import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import ItemRow from "./ItemRow"

const EducationWithSuraasa = () => {
  const {
    isPublic,
    profile: { user },
    academics: { qualifications, certifications },
  } = useContext(ProfileContext)
  const suraasaQualifications = qualifications.data.filter(
    item => item.isVerified
  )
  const suraasaCertifications = certifications.data.filter(
    item => item.isVerified
  )

  const total = suraasaQualifications.length + suraasaCertifications.length

  return (
    <>
      <Section id={ProfileQuickAccessTabs.educationWithSuraasa}>
        <SectionHeading
          heading={
            <SectionTitle title={ProfileQuickAccessTabs.educationWithSuraasa} />
          }
          xPadding={2}
        />
        <SectionContent xPadding={2}>
          {total === 0 && !isPublic && (
            <Typography variant="smallBody" className="text-onSurface-400">
              You have not enrolled in any programme with Suraasa yet.
            </Typography>
          )}
          {total === 0 && isPublic && (
            <Typography variant="smallBody" className="text-onSurface-400">
              {user.firstName} has not enrolled in any programme with Suraasa.
            </Typography>
          )}
          <div className="flex flex-col gap-1.5">
            {suraasaQualifications.map((item, index) => (
              <Fragment key={item.id}>
                <ItemRow
                  isInProgress={item.status === UserAcademicStatus.IN_PROGRESS}
                  title={item.name}
                  startDate={item.startDate}
                  endDate={item.endDate}
                  type="Qualification"
                />
                {index < suraasaQualifications.length - 1 && (
                  <Divider color="onSurface.200" weight="light" />
                )}
              </Fragment>
            ))}
            {suraasaCertifications.map((item, index) => (
              <Fragment key={item.id}>
                <ItemRow
                  title={item.name}
                  startDate={item.completionDate}
                  type="Certification"
                />
                {index < suraasaCertifications.length - 1 && (
                  <Divider color="onSurface.200" weight="light" />
                )}
              </Fragment>
            ))}
          </div>
        </SectionContent>
      </Section>
    </>
  )
}

export default EducationWithSuraasa
