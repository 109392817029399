import { ButtonComponentProps } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import React, { PropsWithChildren } from "react"

import styles from "./ActivityButton.module.css"

export type ActivityButtonState =
  | "default"
  | "active"
  | "selected"
  | "error"
  | "success"
  | "blank"

const ActivityButton = ({
  children,
  className,
  state,
  variant = "filled",
  endAdornment = "",
  ...props
}: PropsWithChildren<{ state: ActivityButtonState }> &
  ButtonComponentProps<any>) => {
  return (
    <button
      {...props}
      className={clsx("relative !px-1 !py-0.75", className, styles.button, {
        [styles.default]: state === "default",
        [styles.active]: state === "active",
        [styles.selected]: state === "selected",
        "text-white": variant === "filled",
        "text-surface-800": variant !== "filled",
        "bg-interactive-500": state === "selected" && variant === "filled",
        [styles.success]: state === "success",
        "bg-success-500": state === "success" && variant === "filled",
        [styles.error]: state === "error",
        "bg-critical-500": state === "error" && variant === "filled",
        "pointer-events-none": props.disabled,
        [styles.blank]: state === "blank",
      })}
    >
      {children}
      {endAdornment && (
        <span className="absolute -right-1.5 z-auto text-black">
          {endAdornment}
        </span>
      )}
    </button>
  )
}

export default ActivityButton
