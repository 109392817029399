import axios from "api/axios"
import { APIResponse } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Batch } from "./types"
import { urls } from "./urls"

export default {
  listBatches: async (): Promise<APIResponse<Batch[]>> => {
    try {
      const res = await axios.get(urls.listBatches())
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
