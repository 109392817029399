import { Typography } from "@suraasa/placebo-ui"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import { NavArrowRight } from "iconoir-react"

import { makeId } from "./utils/helper"

const Topics = ({ topics }: { topics: string[] }) => {
  return (
    <>
      <SwitzerTypography
        variant="title2"
        style={{
          fontFamily: "Switzer Bold",
        }}
      >
        Topics
      </SwitzerTypography>

      <div className="mt-3 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
        {topics.map(item => (
          <button
            className="flex items-center justify-between rounded-full border border-onSurface-300 px-3 py-2.5 hover:bg-onSurface-100"
            key={item}
            onClick={() => {
              document
                .getElementById(makeId(item))
                ?.scrollIntoView({ behavior: "smooth", block: "start" })
            }}
          >
            <Typography
              variant="strong"
              className="text-start text-onSurface-700"
            >
              {item}
            </Typography>
            <NavArrowRight className="text-onSurface-700" />
          </button>
        ))}
      </div>
    </>
  )
}

export default Topics
