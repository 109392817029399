import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import Resume from "assets/UploadResumeOnboarding/resume.webp"
import { differenceInCalendarWeeks } from "date-fns"
import DialogBox from "features/Onboarding/DialogBox"
import { context } from "global/Context/context"
import { XmarkCircleSolid } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import routes from "routes"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { useEnrollments } from "utils/hooks/useEnrollments"
import { UserPreferenceManager } from "utils/userPreferenceManager"

function isTwoWeeksOld(date: string): boolean {
  if (date === "null") return true

  const differenceWeeks = differenceInCalendarWeeks(new Date(), new Date(date))
  return differenceWeeks >= 2
}

const UploadResumeNudgeDialog = () => {
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)

  const {
    profile: { resume },
  } = useContext(context)
  const { isPGCTLUser } = useEnrollments()

  useEffect(() => {
    if (resume) return
    if (!isPGCTLUser) return
    if (open) return

    const lastSeenAt = UserPreferenceManager.get(
      BROWSER_STORAGE_KEYS.uploadResumeNudgeDismissedTime
    )

    // User is seeing the popup for the first time
    if (!lastSeenAt) {
      setOpen(true)
      return
    }

    // User last saw the popup more than 2 weeks ago
    if (isTwoWeeksOld(lastSeenAt)) {
      setOpen(true)
    }
  }, [isPGCTLUser, resume, open])

  const onClose = () => {
    setOpen(false)
    UserPreferenceManager.set(
      BROWSER_STORAGE_KEYS.uploadResumeNudgeDismissedTime,
      new Date().toISOString()
    )
  }

  // Only want to show popup on specific pages
  const allowedRoutes = [routes.home, routes.learning, routes.profile.self]
  if (!allowedRoutes.includes(pathname)) return null

  return (
    <DialogBox open={open}>
      <div className="flex h-full flex-col">
        <div className="relative max-h-[275px] overflow-hidden bg-onSurface-100 px-2 pt-4">
          <img src={Resume} alt="resume" className="mx-auto sm:max-w-[505px]" />
          <button
            onClick={() => {
              setOpen(false)
              onClose()
            }}
            className="absolute right-3 top-2"
          >
            <XmarkCircleSolid className="text-onSurface-600" />
          </button>
        </div>
        <div className="flex h-full flex-col bg-onSurface-800 p-2 text-white sm:p-4">
          <Typography variant="preTitle" className="mb-2">
            Mandatory Action Required
          </Typography>

          <Typography variant="title2" className="mb-2">
            Improve Your Chances of Getting Hired.{" "}
            <br className="hidden sm:block" />
            Upload Your Resume on Your Suraasa Profile.
          </Typography>

          <Typography variant="largeBody" className="mb-3">
            Learners who upload their resumes get interview calls faster than
            those who don’t.
          </Typography>

          {/* Spacer */}
          <div className="grow" />

          <Button
            component={Link}
            onClick={onClose}
            to={routes.profile.self.concat("?action=uploadResume")}
            fullWidth
          >
            Upload Resume
          </Button>
        </div>
      </div>
    </DialogBox>
  )
}

export default UploadResumeNudgeDialog
