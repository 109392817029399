type Props = {
  unitPlanResponses?: { id: number; responseIds: number[] }[] | undefined
  unitDivisionResponses?: number[] | undefined
}

export const getOutputMode = ({
  unitPlanResponses,
  unitDivisionResponses,
}: Props) => {
  if (!unitPlanResponses || !unitDivisionResponses) {
    throw new Error(
      "unitPlanResponses and unitDivisionResponses should not be undefined"
    )
  }

  if (unitDivisionResponses.length === 0 && unitPlanResponses.length > 0) {
    return "unitPlanTopic"
  }
  if (unitDivisionResponses.length > 0 && unitPlanResponses.length === 0) {
    return "unitPlanIntermediate"
  }
  if (unitDivisionResponses.length > 0 && unitPlanResponses.length > 0) {
    return "unitPlanDivision"
  }

  return ""
}
