import { Typography } from "@suraasa/placebo-ui"
import { Currency } from "api/resources/global/types"
import clsx from "clsx"
import CardWrapper from "features/ITO/Dashboard/ReferAndEarn/CardWrapper"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  totalEarnings: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    padding: "24px",
  },
})

type Props = {
  earning?: number
  currency?: Currency
}

const EarningsCard = ({ earning, currency }: Props) => {
  const classes = useStyles()
  return (
    <CardWrapper
      content={className => (
        <div
          className={clsx(
            classes.totalEarnings,
            "bg-gradient-to-tl from-black  via-black  to-[#F9CD5E] backdrop-blur-xl",
            className
          )}
        >
          <Typography variant="preTitle" style={{ color: "#fff" }}>
            Total Earnings
          </Typography>
          <div className="mt-auto flex items-end justify-between">
            <Typography
              variant="title1"
              className="text-onSurface-800"
              style={{ fontSize: "40px", marginBottom: 4, color: "#fff" }}
            >
              {currency ? currency.symbol : "₹"}
              {earning ?? "-"}
            </Typography>
          </div>
        </div>
      )}
    />
  )
}

export default EarningsCard
