import { Typography } from "@suraasa/placebo-ui"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  IconButton,
  Tag,
} from "@suraasa/placebo-ui-legacy"
import ProfileContext from "features/Profile/context"
import { Edit, Plus } from "iconoir-react"
import { useContext, useState } from "react"
import { pluralize } from "utils/helpers"

import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import SkillsDialog from "./SkillsDialog"

const AlertMessage = ({
  open,
  handleDialogClose,
}: {
  open: boolean
  handleDialogClose: () => void
}) => (
  <Dialog open={open} onRequestClose={() => handleDialogClose()}>
    <DialogContent>
      <Typography variant="body">
        Please add at least one academic or work experience detail before adding
        skills.
      </Typography>
    </DialogContent>
    <DialogFooter
      actions={{
        primary: {
          label: "Close",
          onClick: handleDialogClose,
          variant: "outlined",
        },
        secondary: null,
      }}
    />
  </Dialog>
)

const Skills = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [openAlertDialog, setOpenAlertDialog] = useState(false)

  const {
    isPublic,
    skills: { data },
    workExperiences,
    academics: { qualifications, certifications },
  } = useContext(ProfileContext)

  const hasRequiredDetails = [
    workExperiences.data.length,
    qualifications.data.length,
    certifications.data.length,
  ].some(length => length > 0)

  const toggleSkillsDialog = () => {
    if (!hasRequiredDetails && !openDialog) {
      setOpenAlertDialog(true)
      setOpenDialog(false)
      return
    }
    openAlertDialog && setOpenAlertDialog(false)
    setOpenDialog(!openDialog)
  }

  return (
    <>
      <AlertMessage
        handleDialogClose={() => setOpenAlertDialog(false)}
        open={openAlertDialog}
      />
      <SkillsDialog handleDialogClose={toggleSkillsDialog} open={openDialog} />
      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              actionIcon={
                isPublic ? undefined : (
                  <IconButton onClick={toggleSkillsDialog}>
                    {hasRequiredDetails && data.length > 0 ? (
                      <Edit />
                    ) : (
                      <Plus />
                    )}
                  </IconButton>
                )
              }
              itemCount={hasRequiredDetails ? data.length : 0}
              title={pluralize(
                "Additional Skill",
                hasRequiredDetails ? data.length : 0,
                {
                  skipCount: true,
                }
              )}
            />
          }
        />
        <SectionContent className={"flex flex-wrap gap-1.5"} xPadding={2}>
          {hasRequiredDetails && data.length > 0 ? (
            data.map((item, i) => (
              <Tag key={i} label={item.skillName} size="sm" />
            ))
          ) : (
            <Typography className="text-onSurface-400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any skills. Click on + icon to add one."}
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}

export default Skills
