import { SheetBody, SheetClose, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  IconButton,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import Laptop from "assets/UploadVideo/laptop.png"
import { Xmark } from "iconoir-react"
import React from "react"

type Props = {
  onContinue: () => void
}

const Introduction = ({ onContinue }: Props) => {
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  return (
    <>
      <SheetClose className="ml-auto pr-1 pt-1">
        <IconButton color="black">
          <Xmark />
        </IconButton>
      </SheetClose>

      <SheetBody className="flex grow flex-col">
        <img
          src={Laptop}
          alt="laptop"
          width="100%"
          className="mb-4 max-h-[40vh] rounded-t-xl bg-[#F4F4F4] object-contain"
        />
        <div className="mx-auto mb-4 px-2 sm:px-0">
          <Typography
            variant={isSmDown ? "title3" : "title2"}
            className="mb-1 text-center"
          >
            Video Portfolio
          </Typography>
          <Typography
            variant={isSmDown ? "body" : "largeBody"}
            className="mx-auto mb-1 max-w-lg text-center"
          >
            Video Portfolio is the quickest way to showcase your skills and
            experience to employers across the world. Teachers with video
            portfolio are hired much faster.
          </Typography>
        </div>

        <div className="grow sm:grow-0"></div>
        <Button
          className="mx-auto block"
          style={{
            display: "block",
          }}
          fullWidth={isXs}
          onClick={onContinue}
        >
          Continue
        </Button>
      </SheetBody>
    </>
  )
}

export default Introduction
