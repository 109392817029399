import { Typography } from "@suraasa/placebo-ui"
import { Button, Tag, theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import {
  AccessStatus,
  LearningItem,
  UserPlannerItemStatus,
} from "api/resources/learningItems/types"
import clsx from "clsx"
import Card from "components/Card"
import TruncatedText from "components/TruncatedText"
import CheckRestrictions, {
  getRestriction,
} from "features/AccessManagement/CheckRestrictions"
import {
  ChatLines,
  JournalPage,
  Lock,
  PageFlip,
  Play,
  SoccerBall,
} from "iconoir-react"
import { Link, useParams } from "react-router-dom"
import routes from "routes"
import { pluralize } from "utils/helpers"

const getIcon = (
  contentType: string,
  accessStatus: AccessStatus,
  status?: UserPlannerItemStatus
) => {
  const colors = {
    success: "text-success-500 bg-success-100",
    interactive: "text-interactive-900 bg-interactive-100",
    onSurface: "text-onSurface-900 bg-onSurface-200",
  }

  const iconStyle = clsx("me-1.5 rounded-md p-1", {
    [colors.success]:
      status === "completed" &&
      accessStatus !== AccessStatus.COMPLETELY_RESTRICTED,
    [colors.interactive]:
      (status === "in_progress" || status === "incomplete") &&
      accessStatus !== AccessStatus.COMPLETELY_RESTRICTED,
    [colors.onSurface]:
      status === "locked" ||
      status === "unlocked" ||
      accessStatus === AccessStatus.COMPLETELY_RESTRICTED,
  })

  if (contentType === "video") {
    return (
      <div className={iconStyle}>
        <Play />
      </div>
    )
  }
  if (contentType === "article") {
    return (
      <div className={iconStyle}>
        <JournalPage />
      </div>
    )
  }
  if (contentType === "discussion") {
    return (
      <div className={iconStyle}>
        <ChatLines />
      </div>
    )
  }
  if (contentType === "assessment") {
    return (
      <div className={iconStyle}>
        <PageFlip />
      </div>
    )
  }
  if (contentType === "activity") {
    return (
      <div className={iconStyle}>
        <SoccerBall />
      </div>
    )
  }
  return null
}

const Title = ({
  title,
  description,
  hasSavedItems = false,
  contentType,
}: {
  title: string
  description?: string
  hasSavedItems?: boolean
  contentType: string
}) => {
  return (
    <div className="flex flex-col align-middle">
      <div className="flex">
        <TruncatedText
          maxLength={50}
          hideActionButton
          variant="strongSmallBody"
        >
          {title}
        </TruncatedText>
        {hasSavedItems && <Tag label="Saved" size="md" className="ms-1" />}
      </div>
      <div className="flex items-center text-onSurface-500">
        <Typography variant="smallBody" className="capitalize">
          {contentType}
        </Typography>
        {description && (
          <>
            <div className="mx-1 size-0.5 rounded-full bg-onSurface-500" />
            <Typography variant="smallBody" className="capitalize">
              {description}
            </Typography>
          </>
        )}
      </div>
    </div>
  )
}

const MilestoneItem = ({
  item,
  plannerId,
}: {
  item: LearningItem
  plannerId: number
}) => {
  const { slug } = useParams()
  const smUp = useMediaQuery(theme.breakpoints.up("sm"))

  const getAction = () => {
    if (!smUp || !slug) return null

    if (item.status === "locked") return <Lock width={20} height={20} />

    const text =
      item.status === "unlocked"
        ? "Start"
        : item.status === "in_progress"
        ? "Continue"
        : "View"

    return (
      <CheckRestrictions
        accessStatus={item.accessStatus}
        render={({ isRestricted }) => (
          <Button
            variant="text"
            component={Link}
            disabled={isRestricted}
            to={`${routes.learningModule.replace(
              ":slug",
              slug
            )}?plannerId=${plannerId}&itemId=${item.id}`}
          >
            {text}
          </Button>
        )}
      />
    )
  }

  const DynamicComponent =
    getRestriction(item) || item.status === "locked" ? "div" : Link

  return (
    <DynamicComponent
      to={`${routes.learningModule.replace(
        ":slug",
        slug as string
      )}?plannerId=${plannerId}&itemId=${item.id}`}
    >
      <Card
        border={0}
        padding={0}
        infoSlot={
          <div className="flex grow items-center">
            {getIcon(item.learningContentType, item.accessStatus, item.status)}

            <div className="flex">
              {item.learningContentType === "article" && (
                <Title
                  title={item.title}
                  hasSavedItems={item.bookmarkArticle.length > 0}
                  contentType={item.learningContentType}
                  description={
                    item.optimumTime
                      ? pluralize("min", Math.ceil(item.optimumTime / 60))
                      : undefined
                  }
                />
              )}

              {item.learningContentType === "video" && (
                <Title
                  title={item.title}
                  hasSavedItems={item.bookmarkVideo.length > 0}
                  contentType={item.learningContentType}
                  description={
                    item.duration
                      ? pluralize("min", Math.ceil(item.duration / 60))
                      : undefined
                  }
                />
              )}

              {item.learningContentType === "assessment" && (
                <Title
                  title={item.title}
                  description={pluralize(
                    "Question",
                    item.totalNumberOfQuestions
                  )}
                  contentType={item.learningContentType}
                />
              )}

              {item.learningContentType === "discussion" && (
                <Title
                  title={item.milestone.title}
                  contentType={item.learningContentType}
                />
              )}

              {item.learningContentType === "activity" && (
                <Title
                  title={item.title}
                  contentType={item.learningContentType}
                  description={
                    import.meta.env.MODE === "development"
                      ? item.activityType.replaceAll("_", " ")
                      : undefined
                  }
                />
              )}
            </div>
          </div>
        }
        actionSlot={getAction}
      />
    </DynamicComponent>
  )
}

export default MilestoneItem
