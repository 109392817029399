import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getPhoenixURL = getServiceURL(SERVICE_PREFIX.phoenix)

export const urls = validateUrls({
  getAdmissionLetter: slug =>
    getPhoenixURL(`/v1/credentials/admission-letters/${slug}/`),
})

export default urls
