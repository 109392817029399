import { Typography } from "@suraasa/placebo-ui"
import { Button, theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import CourseBg from "assets/Fallback/course-bg-1.png"
import clsx from "clsx"
import { Calendar } from "iconoir-react"
import { getPlatformURL } from "utils/helpers"

import styles from "./TalkToMentorBanner.module.css"

const TalkToMentorBanner = () => {
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div
      className={clsx("mb-2 overflow-hidden rounded-2xl bg-onSurface-100", {
        "rounded-none": isSmDown,
      })}
    >
      <div
        className={clsx(
          "overflow-hidden rounded-2xl bg-gradient-to-b  from-[rgba(14,20,49,1)] to-[rgba(28,41,98,1)] shadow-[0_8px_8px_0_rgba(14,20,49,0.15)]",
          {
            "rounded-none": isSmDown,
          }
        )}
      >
        <div className="flex flex-wrap items-center justify-between px-4 pt-3">
          <div className="mb-3 text-white sm:mb-0">
            <Typography variant="title3" className="mb-0.5">
              Check out our courses!
            </Typography>
            <Typography variant="smallBody">
              Select from our catalog of courses created just for you.
            </Typography>
          </div>
          <Button
            size="sm"
            className={styles.customButton}
            component="a"
            href={getPlatformURL("suraasa", "/store")}
          >
            View Courses
          </Button>
        </div>
        <img
          src={CourseBg}
          alt="course-bg"
          width="100%"
          className="h-[202px] object-cover"
        />
      </div>
      <div className="flex flex-wrap items-center justify-between p-4">
        <Typography variant="title2" className="mb-3 sm:mb-0">
          Confused which course to enrol in? <br />{" "}
          <span className={styles.gradientText}>Talk to our Mentors.</span>
        </Typography>
        <Button
          startAdornment={<Calendar />}
          fullWidth={isSmDown}
          component="a"
          target="_blank"
          href={getPlatformURL("suraasa", "/talk-to-a-mentor")}
        >
          Talk to a Mentor
        </Button>
      </div>
    </div>
  )
}

export default TalkToMentorBanner
