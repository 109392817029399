import { RubricType, ToolType } from "api/resources/aiTools/types"

export const getToolName = (type: ToolType): string => {
  switch (type) {
    case ToolType.lessonPlanPro:
    case ToolType.lessonPlan:
      return "Lesson Plan"
    case ToolType.quizPro:
    case ToolType.quiz:
      return "Quiz"
    case ToolType.rubricsPro:
    case ToolType.rubrics:
      return "Rubrics"
    case ToolType.subjectiveAssessmentPro:
    case ToolType.subjectiveAssessment:
      return "Subjective Assessment"
    case ToolType.narration:
      return "Narration"
    case ToolType.handout:
      return "Handout"
    case ToolType.assignmentPro:
    case ToolType.assignment:
      return "Assignment"
    case ToolType.coverLetter:
      return "Cover Letter"
    case ToolType.activityPlanner:
      return "Activity Plan"
    case ToolType.videoSummary:
      return "YouTube Video Summary"
    case ToolType.unitPlanner:
      return "Unit Plan"
  }
}

export const getToolNamePlural = (type: ToolType): string => {
  switch (type) {
    case ToolType.lessonPlanPro:
    case ToolType.lessonPlan:
      return "Lesson Plans"
    case ToolType.quizPro:
    case ToolType.quiz:
      return "Quizzes"
    case ToolType.rubricsPro:
    case ToolType.rubrics:
      return "Rubrics"
    case ToolType.subjectiveAssessmentPro:
    case ToolType.subjectiveAssessment:
      return "Subjective Assessments"
    case ToolType.narration:
      return "Narrations"
    case ToolType.handout:
      return "Handouts"
    case ToolType.assignmentPro:
    case ToolType.assignment:
      return "Assignments"
    case ToolType.coverLetter:
      return "Cover Letters"
    case ToolType.activityPlanner:
      return "Activity Plans"
    case ToolType.videoSummary:
      return "YouTube Video Summaries"
    case ToolType.unitPlanner:
      return "Unit Plans"
  }
}

export const getDurationDisplay = (duration: string): string => {
  if (duration === "01:00:00") duration = "00:60:00"
  return DURATION_MINUTES.find(item => item.value === duration)?.label || "-"
}

export const getBloomTaxonomyDisplay = (taxonomies: string[]): string => {
  if (!taxonomies || !taxonomies.length) return ""
  return (
    taxonomies
      .map(
        taxonomy =>
          BLOOMS_TAXONOMY_OPTIONS.find(item => item.value === taxonomy)
            ?.label || ""
      )
      .join(", ") || "-"
  )
}

export const DURATION_MINUTES = [
  {
    label: "30 minutes",
    value: "00:30:00",
  },
  {
    label: "45 minutes",
    value: "00:45:00",
  },
  { label: "60 minutes", value: "00:60:00" },
  { label: "75 minutes", value: "00:75:00" },
  { label: "90 minutes", value: "00:90:00" },
  // { label: "105 minutes", value: "00:105:00" },
  // { label: "120 minutes", value: "00:120:00" },
]

export const BLOOMS_TAXONOMY_OPTIONS = [
  {
    label: "Remember",
    value: "remembering",
  },
  {
    label: "Understand",
    value: "understanding",
  },
  {
    label: "Apply",
    value: "application",
  },
  {
    label: "Analyse",
    value: "analysis",
  },
  {
    label: "Evaluate",
    value: "evaluation",
  },
  {
    label: "Create",
    value: "creativity",
  },
]

export const pointScaleOptions = [
  { label: "3", value: 3 },
  { label: "5", value: 5 },
]
export const proPointScaleOptions = [
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
]
export const criteriaLevelOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
]

export const rubricsTypeOptions: { label: string; value: RubricType }[] = [
  { label: "Analytic", value: RubricType.analytic },
  { label: "Holistic", value: RubricType.holistic },
  { label: "Single Point", value: RubricType.singlePoint },
]
