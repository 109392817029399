import { Sheet, SheetBody, SheetContent } from "@suraasa/placebo-ui"
import { theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useState } from "react"

import CoverLetterManager from "./CoverLetterManager"
import HistoryRecords from "./HistoryRecords"

const CoverLetter = ({
  open,
  onClose: onSheetClose,
  handleOpenPersonalDetailsSheet,
}: {
  open: boolean
  onClose: () => void
  handleOpenPersonalDetailsSheet: () => void
}) => {
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const [openForm, setOpenForm] = useState(false)
  const [coverLetterId, setCoverLetterId] = useState<number | undefined>(
    undefined
  )

  const createCoverLetter = () => {
    setOpenForm(true)
  }
  const updateCoverLetterId = (id: number) => {
    setCoverLetterId(id)
  }

  const onClose = () => {
    setOpenForm(false)
    onSheetClose()
  }
  const onBack = () => {
    if (coverLetterId) {
      setCoverLetterId(undefined)
    }
    setOpenForm(false)
  }

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent
        side={isSmDown ? "bottom" : "right"}
        className="!rounded-none !p-0"
        height={95}
        closeWhenInteractOutside={!openForm && !coverLetterId}
      >
        <SheetBody className="p-0">
          {!openForm && !coverLetterId && (
            <HistoryRecords
              onCreateCoverLetter={createCoverLetter}
              onSelectCoverLetter={updateCoverLetterId}
              onClose={onClose}
            />
          )}
          {(openForm || coverLetterId) && (
            <CoverLetterManager
              updateCoverLetterId={updateCoverLetterId}
              onBack={onBack}
              coverLetterId={coverLetterId}
              handleOpenPersonalDetailsSheet={handleOpenPersonalDetailsSheet}
            />
          )}
        </SheetBody>
      </SheetContent>
    </Sheet>
  )
}

export default CoverLetter
