import { Sheet, SheetContent } from "@suraasa/placebo-ui"
import React, { useContext, useEffect, useState } from "react"

import ProfileContext from "../context"
import { Steps } from "./config"
import Introduction from "./Introduction"
import Upload from "./Upload"
import View from "./View"

type Props = {
  handleClose: () => void
  openSheet: boolean
  videoURL?: string | null
  onDelete: () => void
  onUpload: (url: string) => void
  setOpenSheet: React.Dispatch<React.SetStateAction<boolean>>
}

const VideoPortfolio = ({
  handleClose,
  openSheet,
  videoURL,
  onDelete,
  onUpload,
  setOpenSheet,
}: Props) => {
  const [step, setStep] = useState<Steps>(Steps.INTRO)
  const { isPublic } = useContext(ProfileContext)
  const [localURL, setLocalURL] = useState<string | null>(null)

  useEffect(() => {
    if (videoURL) {
      setStep(Steps.VIEW)
    } else {
      setStep(Steps.INTRO)
    }
  }, [videoURL])

  return (
    <>
      <Sheet open={openSheet} onOpenChange={setOpenSheet}>
        <SheetContent side="bottom" height={90}>
          {step === Steps.INTRO && !isPublic && (
            <Introduction
              onContinue={() => {
                setStep(Steps.UPLOAD)
              }}
            />
          )}
          {step === Steps.UPLOAD && !isPublic && (
            <Upload
              onUpload={(localURL: string, serverURL: string) => {
                setLocalURL(localURL)
                onUpload(serverURL)
              }}
            />
          )}
          {step === Steps.VIEW && (
            <View
              videoURL={localURL ?? videoURL}
              handleUpdate={() => setStep(Steps.UPLOAD)}
              onDelete={() => {
                onDelete()
                handleClose()
              }}
            />
          )}
        </SheetContent>
      </Sheet>
    </>
  )
}

export default VideoPortfolio
