import { Typography } from "@suraasa/placebo-ui"
import { Theme } from "@suraasa/placebo-ui-legacy"
import { useNetworkState } from "@uidotdev/usehooks"
import clsx from "clsx"
import { WarningTriangle } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles((theme: Theme) => ({
  overlay: {
    position: "fixed",
    textAlign: "center",
    zIndex: theme.zIndex.dialog + 2,
    height: "100%",
    width: "100%",
    background: "rgba(0, 0, 0, 0.30)",
    left: 0,
    top: 0,
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
    },
  },
  warning: {
    display: "flex",
    alignItems: "center",
    background: theme.colors.critical[500],
    color: "white",
  },
  icon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}))

const InternetConnectionBanner = () => {
  const classes = useStyles()

  const network = useNetworkState()

  const isOffline = !network.online
  // Less than 0.1 means less than 100kbps
  const isSuperSlow = network.downlink !== null ? network.downlink < 0.1 : false

  if (isOffline || isSuperSlow)
    return (
      <div className={classes.overlay}>
        <div className={clsx("p-2", classes.warning)}>
          <WarningTriangle className={classes.icon} />
          <Typography variant="body">
            Your device is offline! Please check your internet connection.
          </Typography>
        </div>
      </div>
    )

  return null
}

export default InternetConnectionBanner
