import { Container, theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import React, { PropsWithChildren } from "react"
import { useAnchorMargin } from "utils/hooks/useAnchorMargin"

export const CUSTOM_BREAKPOINT =
  "@media (min-width:960px) and (max-width:2160px)"

export const DRAWER_WIDTH = 420
export const PADDING = 30

export const CONTAINER_ANCHOR_REF = "home-anchor-ref"

export const CustomContainer = (
  props: PropsWithChildren & {
    className?: string
    id?: string
    paddingRight?: number
    paddingLeft?: number
    removeMarginRight?: boolean
  }
) => {
  const { marginLeft } = useAnchorMargin(`#${CONTAINER_ANCHOR_REF}`)

  return (
    <>
      <Container>
        <div id={CONTAINER_ANCHOR_REF} />
      </Container>
      <div
        id={props.id}
        className={props.className}
        style={{
          marginLeft: props.paddingLeft
            ? !props.removeMarginRight
              ? DRAWER_WIDTH
              : undefined
            : marginLeft,
          marginRight: props.paddingRight
            ? !props.removeMarginRight
              ? DRAWER_WIDTH
              : undefined
            : marginLeft,
          paddingRight: props.paddingRight,
          paddingLeft: props.paddingLeft,
        }}
      >
        {props.children}
      </div>
    </>
  )
}

const DynamicContainer = ({
  paddingDirection = "right",
  ...props
}: PropsWithChildren & {
  className?: string
  paddingDirection?: "left" | "right"
}) => {
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isCustomBreakpoint = useMediaQuery(CUSTOM_BREAKPOINT)

  const CurrentContainer =
    !isCustomBreakpoint || isSmDown ? Container : CustomContainer

  return (
    <CurrentContainer
      className={props.className}
      paddingLeft={
        !isCustomBreakpoint || isSmDown
          ? undefined
          : paddingDirection === "left"
          ? PADDING
          : undefined
      }
      paddingRight={
        !isCustomBreakpoint || isSmDown
          ? undefined
          : paddingDirection === "right"
          ? PADDING
          : undefined
      }
    >
      {props.children}
    </CurrentContainer>
  )
}

export default DynamicContainer
