import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Divider,
  LinearProgress,
  TextField,
} from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import clsx from "clsx"
import { add } from "date-fns"
import { useEffect, useState } from "react"
import { useTimer } from "react-timer-hook"
import { handleErrors } from "utils/helpers"

import styles from "./Step2.module.css"

const AUTO_DISMISS_TIME = 3 // in seconds

type Props = {
  onClose: () => void
  itemId: number | string
}

const buttonList = Array.from({ length: 5 }, (_, i) => i + 1)

const Step2 = ({ onClose, itemId }: Props) => {
  const [rating, setRating] = useState<null | number>(null)

  const [comment, setComment] = useState("")

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: () =>
      api.feedback.create({
        data: {
          rating: rating,
          comment: {
            description: comment,
          },
          itemType: 4, // "class"
          itemId,
        },
      }),
    onSuccess: () => {
      setRating(null)
      setComment("")
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const timer = useTimer({
    expiryTimestamp: add(new Date(), { seconds: AUTO_DISMISS_TIME }),
    autoStart: false,
    onExpire: () => {
      setTimeout(() => {
        onClose()
      }, 1000)
    },
  })

  useEffect(() => {
    if (isSuccess) {
      timer.restart(add(new Date(), { seconds: AUTO_DISMISS_TIME }), true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const onSubmit = () => {
    mutate()
  }

  return (
    <div
      className={clsx("overflow-hidden", styles.container, {
        [styles.isSuccess]: isSuccess,
      })}
    >
      <div
        className={clsx(
          "mb-2 flex items-center justify-between px-2 pt-2 sm:px-4 sm:pt-4"
        )}
      >
        <Typography variant="title3" className="text-onSurface-800">
          Feedback
        </Typography>
        {/* <IconButton
          onClick={() => {
            sessionStorage.setItem("hasDismissedRateCoursePopup", "true")
            onClose()
          }}
        >
          <Xmark />
        </IconButton> */}
      </div>
      {isSuccess ? (
        <div>
          <Typography className="mb-4 px-2 text-onSurface-800 sm:px-4">
            Thank you for your feedback.
          </Typography>

          <LinearProgress value={(timer.seconds / AUTO_DISMISS_TIME) * 100} />
        </div>
      ) : (
        <div className=" px-2 pb-2 sm:px-4 sm:pb-4">
          <Typography className="mb-1.5 text-onSurface-800">
            Please rate the trainer&apos;s overall performance in this session.
          </Typography>
          <div className="mb-1 flex gap-1.5">
            {buttonList.map((item, index) => (
              <button
                onClick={() => {
                  setRating(item)
                }}
                className={clsx(styles.rateButton, {
                  [styles.selectedRateButton]: rating === item,
                })}
                key={index}
              >
                <Typography variant="strong">{item}</Typography>
              </button>
            ))}
          </div>
          <div className="flex items-center justify-between">
            <Typography variant="smallBody" className="text-onSurface-500">
              Weak
            </Typography>
            <Typography variant="smallBody" className="text-onSurface-500">
              Outstanding
            </Typography>
          </div>
          <div
            className={clsx(styles.bottomContainer, {
              [styles.bottomContainerActive]: rating,
            })}
          >
            <div className="overflow-hidden">
              <Divider className="my-3" />

              <TextField
                label="Tell us more about your experience (optional)"
                className="mb-4"
                multiLine
                value={comment}
                rows={5}
                fullWidth
                onChange={(event: { target: { value: string } }) => {
                  setComment(event.target.value)
                }}
              />
              <Button onClick={onSubmit} loading={isLoading} fullWidth>
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Step2
