import { Typography } from "@suraasa/placebo-ui"
import { Clock } from "iconoir-react"
import React from "react"

const IconBox = ({
  Icon = Clock,
  heading,
  subheading,
}: {
  Icon: typeof Clock
  heading: string
  subheading: string | number
}) => {
  return (
    <div className="flex items-center gap-2">
      <span className="rounded-lg border border-onSurface-200 bg-white p-1.25">
        <Icon />
      </span>
      <div>
        <Typography variant="strongSmallBody" className="block">
          {heading}
        </Typography>
        <Typography variant="smallBody" className="block">
          {subheading}
        </Typography>
      </div>
    </div>
  )
}

export default IconBox
