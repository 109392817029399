import { Typography } from "@suraasa/placebo-ui"
import { Dialog } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { PaymentStatus } from "api/resources/payment/types"
import { useEffect } from "react"
import { CountdownCircleTimer } from "react-countdown-circle-timer"
import { useNavigate } from "react-router-dom"
import routes from "routes"

type Props = {
  paymentPlanId: string
  itemId?: number
}

const Polling = ({ paymentPlanId, itemId }: Props) => {
  const navigate = useNavigate()

  const goToOrders = () => {
    const searchParams = new URLSearchParams(window.location.search)

    try {
      if (itemId) searchParams.append("item_id", `${itemId}`)
    } catch (e) {
      console.error("> Error while generating redirect url to orders page", e)
    }

    navigate({
      pathname: routes.orders,
      search: searchParams.toString(),
    })
  }

  const { data } = useQuery({
    enabled: Boolean(paymentPlanId),
    queryFn: () =>
      api.payment.getPaymentStatus({ urlParams: { paymentPlanId } }),
    refetchInterval: 3000,
  })

  const cancelPayment = () => {
    if (data?.status === PaymentStatus.inProgress) {
      api.payment.cancelPayment({ urlParams: { paymentPlanId } })
      goToOrders()
    }
  }

  useEffect(() => {
    switch (data?.status) {
      case PaymentStatus.cancelled:
        goToOrders()
        break
      case PaymentStatus.completed:
        goToOrders()
        break

      case PaymentStatus.inProgress:
        break

      /**
       * Safe to ignore
       */
      case PaymentStatus.notInitiated:
      case PaymentStatus.overdue:
        break

      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Dialog open classes={{ overlay: "!bg-white" }}>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-col items-center justify-center gap-2">
          <CountdownCircleTimer
            isPlaying
            duration={3 * 60}
            colors={["#3852C5", "#3852C5"]}
            colorsTime={[0, 1]}
            strokeWidth={10}
            onComplete={() => {
              cancelPayment()
            }}
          >
            {({ remainingTime }) => {
              const minutes = Math.floor(remainingTime / 60)
              const seconds = (remainingTime % 60).toString().padStart(2, "0")
              return (
                <div className="flex flex-col items-center justify-center">
                  <Typography variant="largeBody">
                    {minutes}:{seconds}
                  </Typography>
                </div>
              )
            }}
          </CountdownCircleTimer>
          <Typography variant="largeBody" className="text-center">
            Please wait while we are processing your payment.
          </Typography>

          <Typography
            variant="smallBody"
            className="text-center text-onSurface-500"
          >
            You will be automatically redirected when timer ends.
          </Typography>
        </div>
      </div>
    </Dialog>
  )
}

export default Polling
