import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { ToolType } from "api/resources/aiTools/types"
import CreateFromExisting from "assets/AITools/CreateFromExisting"
import CreateFromScratch from "assets/AITools/CreateFromScratch"
import clsx from "clsx"

const CreateFromScratchOrSelect = ({
  type = ToolType.subjectiveAssessment,
  onCreateNew,
  onCreateExisting,
}: {
  onCreateNew: () => void
  onCreateExisting: () => void
  type?: ToolType
}) => {
  const getSvgColor = () => {
    if (type === ToolType.subjectiveAssessment) {
      return "#9A7BBA"
    }
  }
  return (
    <div className="flex flex-wrap items-stretch justify-center gap-3">
      <div className="flex max-w-[327px] shrink-0 flex-col items-center rounded-lg border border-solid border-decorative-two-50 bg-white  px-2 py-3 text-center shadow-[0px_16px_24.600000381469727px_0px_#382C430D]">
        <CreateFromScratch currentColor={getSvgColor()} />
        <Typography variant="title3" className="mb-8 mt-3">
          Create Assessment from Scratch
        </Typography>
        <div className="grow" />
        <Button
          onClick={onCreateNew}
          fullWidth
          size="lg"
          className={clsx({
            "!bg-decorative-two-200": type === ToolType.subjectiveAssessment,
          })}
        >
          Create from Scratch
        </Button>
      </div>
      <div className="flex max-w-[327px] shrink-0 flex-col items-center rounded-lg border border-solid border-decorative-two-50 bg-white  px-2 py-3 text-center shadow-[0px_16px_24.600000381469727px_0px_#382C430D]">
        <CreateFromExisting currentColor={getSvgColor()} />
        <Typography variant="title3" className="mb-8 mt-3">
          Create Assessment from Existing Lesson Plan
        </Typography>
        <div className="grow" />
        <Button
          className={clsx({
            "!bg-decorative-two-200": type === ToolType.subjectiveAssessment,
          })}
          onClick={onCreateExisting}
          fullWidth
          size="lg"
        >
          Create from Existing Lesson Plan
        </Button>
      </div>
    </div>
  )
}

export default CreateFromScratchOrSelect
