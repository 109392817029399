import { toast } from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import { useContext, useEffect } from "react"

import { AIToolsContext } from "../../../context"
import AccordionOutput from "../AccordionOutput"

const Output = ({
  unitIndex,
  termIndex,
  sequence,
  refetchPromptData,
  onSuccessAPi,
}: {
  unitIndex: number
  termIndex: number
  sequence: number
  refetchPromptData: () => void
  onSuccessAPi: () => void
}) => {
  const { promptDataId, currentResponseId, overviewData } =
    useContext(AIToolsContext)

  const generateUnitPlan = useMutation({
    mutationFn: () =>
      api.aiTools.generateUnitPlan({
        data: {
          promptDataId: promptDataId,
          unitPlanType: "academic_year",
          unitPlanIndex: unitIndex,
          termIndex,
          unitDivisionId: currentResponseId,
        },
      }),
    onSuccess: () => {
      onSuccessAPi()
    },
    onError: (err: any) => {
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  useEffect(() => {
    generateUnitPlan.mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AccordionOutput
      responseIds={
        generateUnitPlan.data
          ? overviewData?.unitPlanResponses?.find(item =>
              item.responseIds.includes(generateUnitPlan.data.id)
            )?.responseIds || [generateUnitPlan.data.id]
          : []
      }
      refetchPromptData={refetchPromptData}
      sequence={sequence}
      unitPlanGenerateResponse={generateUnitPlan.data}
      isLoading={generateUnitPlan.isLoading}
      isError={generateUnitPlan.isError}
      generateUnitPlan
      retryFunc={() => generateUnitPlan.mutate()}
    />
  )
}

export default Output
