import { NarrationGeneratorType } from "api/resources/aiTools/types"
import { useContext } from "react"

import { AIToolsContext } from "../../context"
import InputDisplay from "../../helpers/InputDisplay"
import NarrationContent from "./NarrationContent"

const NarrationOutput = (props: NarrationGeneratorType) => {
  const { overviewData } = useContext(AIToolsContext)

  return (
    <div className="mb-4 space-y-4">
      {overviewData && <InputDisplay data={overviewData} />}

      <NarrationContent {...props} defaultOpen />
    </div>
  )
}

export default NarrationOutput
