import SuraasaLogo from "assets/suraasaLogo.jpg"
import React from "react"
import { Link } from "react-router-dom"
import routes from "routes"

const ErrorPage404 = () => {
  return (
    <>
      <div className="flex h-screen flex-col items-center justify-center p-2">
        <img
          src={SuraasaLogo}
          alt="logo"
          width={70}
          height={70}
          className="mb-2"
        />

        <p>Page not found.</p>
        <Link
          className="text-highlight-500 hover:underline"
          to={routes.home}
          replace
        >
          Click here to go back to Suraasa Home Page.
        </Link>
      </div>
    </>
  )
}

export default ErrorPage404
