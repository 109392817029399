import { toast, Typography } from "@suraasa/placebo-ui"
import { Button, Container, LinearProgress } from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { TIASubmission } from "api/resources/tia/types"
import { APIError } from "api/utils"
import axios from "axios"
import clsx from "clsx"
import { ArrowUpCircleSolid } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { formatBytes, handleErrors } from "utils/helpers"

import styles from "./ContestVideoUpload.module.css"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore axios type
const CancelToken = axios.CancelToken
let cancelAxiosRequest: () => void

const ALLOWED_TYPES = [".mp4", ".mov", ".avi", ".heif", ".hevc"]
const ALLOWED_SIZE_IN_BYTES = 1024 * 1024 * 1024 // 1 GB

const ContestVideoUpload = ({
  videoUploaded,
  submissionVideo,
}: {
  videoUploaded: () => void
  submissionVideo: TIASubmission | null
}) => {
  const [files, setFiles] = useState<HTMLInputElement["files"] | null>()
  const [videoLink, setVideoLink] = useState<string>("")
  const [uploadPercentage, setUploadPercentage] = useState(10)

  useEffect(() => {
    if (videoLink) videoUploaded()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoLink])
  const [errors, setErrors] = useState<string[]>([])

  const { mutate: postVideo, isLoading: isUploading } = useMutation({
    onSuccess: data => {
      setVideoLink(data.video)
      toast.success("Your video has been submitted")
    },

    mutationFn: async (file: FormData) => {
      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent: ProgressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          if (percent <= 100 && percent > 0) {
            setUploadPercentage(percent)
          }
        },
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelAxiosRequest = c
        }),
      }
      return api.tia.createSubmission({
        data: file,
        headers: options,
      })
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const uploadVideo = useCallback(
    (files: FileList) => {
      if (!files) return

      setUploadPercentage(1)
      const data = new FormData()
      data.append("video", files[0])
      const reader = new FileReader()
      const file = files[0]

      reader.onloadend = (event: ProgressEvent<FileReader>) => {
        let dataUrl
        const ele = document.querySelector("#preview") as HTMLImageElement
        if (event && event.target) dataUrl = event.target.result as string
        if (ele) ele.src = dataUrl ?? ""
      }

      reader.readAsDataURL(file)
      postVideo(data)
    },
    [postVideo]
  )

  const validateFiles = useCallback(
    (files: HTMLInputElement["files"] | null) => {
      if (files) {
        if (files.length === 0 || isUploading) return

        setErrors([])

        const fileExt = files[0].name.split(".").pop()?.toLowerCase()
        const fileSize = files[0].size

        if (!fileExt) return

        if (!ALLOWED_TYPES.includes(`.${fileExt}`)) {
          setErrors(e => [
            ...e,
            `Please upload a valid video format. Accepted formats are ${ALLOWED_TYPES.join(
              ", "
            )}`,
          ])
          return
        }

        if (fileSize > ALLOWED_SIZE_IN_BYTES) {
          setErrors(e => [...e, "Please upload a file which is less than 1 GB"])
          return
        }

        setFiles(files)
        uploadVideo(files)
      }
    },
    [isUploading, uploadVideo]
  )

  useEffect(() => {
    const form = document.querySelector("form")
    const box = document.querySelector("form .box__input")

    if (!form) return
    if (!box) return

    const preventDefault = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
    }

    const dragOver = () => {
      form.classList.add("isDragging")
    }

    const dragLeave = () => {
      form.classList.remove("isDragging")
    }

    const drop = (e: DragEvent) => {
      if (e.dataTransfer) {
        validateFiles(e.dataTransfer.files)
      }
    }

    const allDragEvents = [
      "drag",
      "dragstart",
      "dragend",
      "dragover",
      "dragenter",
      "dragleave",
      "drop",
    ]

    allDragEvents.forEach(event => {
      form.addEventListener(event, preventDefault, false)
    })

    const dragOverEvents = ["dragover", "dragenter"]

    dragOverEvents.forEach(event => {
      box.addEventListener(event, dragOver, false)
    })

    const dragLeaveEvents = ["dragleave", "dragend", "drop"]

    dragLeaveEvents.forEach(event => {
      box.addEventListener(event, dragLeave, false)
    })

    form.addEventListener("drop", drop, false)

    return () => {
      allDragEvents.forEach(event => {
        form.removeEventListener(event, preventDefault)
      })
      dragOverEvents.forEach(event => {
        box.removeEventListener(event, dragOver)
      })
      dragLeaveEvents.forEach(event => {
        box.removeEventListener(event, dragLeave)
      })
      form.removeEventListener("drop", drop)
    }
  }, [validateFiles])

  const clearInput = () => {
    setFiles(null)

    const form = document.querySelector("form")
    if (form) form.reset()
  }

  return (
    <div
      style={{
        background:
          videoLink || submissionVideo
            ? "#ECF6F4"
            : "linear-gradient(235.38deg,#39AFFD 4.23%,#477FFF 95.84%),#F8FAFC",
      }}
      className="p-1 py-4 md:px-6"
    >
      <Container>
        {!videoLink && !submissionVideo ? (
          <>
            <div
              style={{ color: "white" }}
              className="mb-3 mt-1 flex flex-col items-center justify-between md:mt-0 md:flex-row md:gap-3"
            >
              <Typography
                style={{ fontFamily: "Space Grotesk Bold" }}
                className="!text-2xl !font-bold"
              >
                Ready with your success story?
              </Typography>
              <Typography>
                Make sure your video file size does not exceed 1GB
              </Typography>
            </div>

            {/* When the file is being uploaded */}
            {files ? (
              <div className="grid grid-flow-row items-center">
                <div>
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video
                    src=""
                    id="preview"
                    className="max-h-[400px] w-full overflow-hidden rounded-lg border border-solid border-white bg-black object-cover"
                  />
                </div>
                <div className="mt-2">
                  <div className="flex items-center justify-between">
                    <Typography
                      variant="subtitle2"
                      className="text-surface-500"
                    >
                      Uploading your video
                    </Typography>
                    <Typography className="text-surface-500">
                      {uploadPercentage}%
                    </Typography>
                  </div>
                  <LinearProgress
                    value={uploadPercentage}
                    size="lg"
                    className={clsx(styles.linearProgress, "my-1")}
                  />
                  <div className="flex items-center justify-between">
                    <Typography className="text-surface-500">
                      {formatBytes((files[0].size * uploadPercentage) / 100)} of{" "}
                      {formatBytes(files[0].size)}
                    </Typography>
                    <Button
                      variant="link"
                      color="white"
                      onClick={() => {
                        setUploadPercentage(0)
                        clearInput()

                        cancelAxiosRequest()
                      }}
                      className="!text-common-white-500"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <form className={clsx(styles.box)}>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <div
                    // eslint-disable-next-line tailwindcss/no-custom-classname
                    className="box__input flex flex-col items-center px-3 py-2 sm:p-10"
                    onClick={() =>
                      document.getElementById("file-input")?.click()
                    }
                  >
                    <input
                      className="hidden"
                      type="file"
                      id="file-input"
                      accept={ALLOWED_TYPES.join()}
                      onChange={e => validateFiles(e.target.files)}
                      disabled={isUploading}
                    />
                    <div className="flex flex-col items-center justify-center">
                      <ArrowUpCircleSolid className="mb-2" />
                      <Typography className="text-center">
                        Drag and Drop files to upload
                      </Typography>
                      <Typography className="my-0.5">or</Typography>
                      <Button variant="link">Browse</Button>
                    </div>
                    {errors.map((e, i) => (
                      <div className={clsx("mt-2 text-critical-500")} key={i}>
                        <Typography className="text-center">{e}</Typography>
                      </div>
                    ))}
                  </div>
                </form>
                <Typography className="mx-auto mt-2 w-full text-center text-surface-500 sm:w-[50%]">
                  By uploading your video, you give Suraasa the consent to use
                  your video for the purpose of this competition and beyond.
                </Typography>
              </div>
            )}
          </>
        ) : (
          <div className="rounded-lg">
            {/* File already Uploaded */}
            <Typography
              style={{ fontFamily: "Space Grotesk Bold" }}
              className="mb-3 !text-2xl !font-bold"
            >
              Your Submission
            </Typography>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              width="100%"
              className="max-h-[420px] rounded-lg"
              controls
              playsInline
            >
              <source
                src={submissionVideo?.video ?? videoLink}
                type="video/mp4"
              />
            </video>
          </div>
        )}
      </Container>
    </div>
  )
}

export default ContestVideoUpload
