import { Typography, TypographyProps } from "@suraasa/placebo-ui"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      gap: 0,
    },
  },

  icon: {
    flexShrink: 0,
  },

  title: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1 / 2),
    [theme.breakpoints.down("sm")]: {
      flexWrap: "nowrap",
    },
  },
}))

interface Props {
  itemCount?: number
  title?: string
  actionIcon?: React.ReactElement
  typographyProps?: TypographyProps
}

const SectionTitle = ({
  actionIcon,
  itemCount,
  title,
  typographyProps,
}: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {itemCount !== 0 && itemCount !== undefined && (
          <Typography className="text-primary-500" variant="strong">
            {itemCount}
          </Typography>
        )}
        {title && (
          <Typography variant="strong" {...typographyProps}>
            {title}
          </Typography>
        )}
      </div>

      <div className={classes.icon}>{actionIcon}</div>
    </div>
  )
}

export default SectionTitle
