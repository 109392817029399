import { Typography } from "@suraasa/placebo-ui"
import { Button, theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import React from "react"

const Header = ({
  showButton,
  onClick,
}: {
  showButton: boolean
  onClick: () => void
}) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  return (
    <div className="flex flex-col items-center justify-between pb-3 pt-6 sm:flex-row">
      <Typography
        style={{ fontFamily: "Space Grotesk Bold" }}
        className="!text-[40px] !leading-10 !tracking-[-1px]"
      >
        Teacher Impact Awards
      </Typography>
      {/* {!submission && !isVideoSubmitted && ( */}
      {showButton && (
        <Button
          size="md"
          //   onClick={handleUploadClick}
          onClick={onClick}
          fullWidth={isXsDown}
          className="mt-1 sm:mt-0"
        >
          Upload My Submission
        </Button>
      )}
    </div>
  )
}

export default Header
