import React from "react"

const AISparkle = (props: React.SVGProps<any>) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="currentColor"
      {...props}
    >
      <path
        d="M0 7.54314L-0.00370747 6.79315L-0.00371468 8.29313L0 7.54314ZM7.43779 14.9806L6.6878 14.9843L8.18778 14.9843L7.43779 14.9806ZM7.43796 0.105469L8.18796 0.101764L6.68797 0.101765L7.43796 0.105469ZM14.8757 7.54314L14.8795 8.29313L14.8795 6.79315L14.8757 7.54314ZM11.3407 13.1554L11.337 12.4055L11.337 13.9054L11.3407 13.1554ZM15.6703 17.4848L14.9203 17.4886H16.4203L15.6703 17.4848ZM15.6704 8.82594L16.4204 8.82223L14.9204 8.82223L15.6704 8.82594ZM20 13.1554L20.0037 13.9054L20.0037 12.4055L20 13.1554ZM-0.00371468 8.29313C3.68373 8.31139 6.66953 11.2971 6.6878 14.9843L8.18778 14.9769C8.16544 10.467 4.51372 6.81549 0.00371468 6.79315L-0.00371468 8.29313ZM6.68797 0.101765C6.66976 3.78904 3.6838 6.77492 -0.00370747 6.79315L0.00370747 8.29313C4.51378 8.27083 8.16568 4.61915 8.18796 0.109173L6.68797 0.101765ZM14.8795 6.79315C11.192 6.77492 8.20617 3.78906 8.18796 0.101764L6.68797 0.109173C6.71025 4.61912 10.3619 8.27083 14.872 8.29313L14.8795 6.79315ZM8.18778 14.9843C8.20605 11.2972 11.192 8.3114 14.8795 8.29313L14.872 6.79315C10.3621 6.81549 6.71014 10.467 6.6878 14.9769L8.18778 14.9843ZM11.337 13.9054C13.3116 13.9152 14.9106 15.5141 14.9203 17.4886L16.4203 17.4811C16.4065 14.684 14.1416 12.4193 11.3445 12.4055L11.337 13.9054ZM14.9204 8.82223C14.9107 10.7967 13.3117 12.3957 11.337 12.4055L11.3445 13.9054C14.1417 13.8916 16.4066 11.6268 16.4204 8.82964L14.9204 8.82223ZM20.0037 12.4055C18.0291 12.3957 16.4302 10.7967 16.4204 8.82223L14.9204 8.82964C14.9343 11.6268 17.1991 13.8916 19.9963 13.9054L20.0037 12.4055ZM16.4203 17.4886C16.4301 15.5141 18.0291 13.9152 20.0037 13.9054L19.9963 12.4055C17.1991 12.4193 14.9342 14.684 14.9203 17.4811L16.4203 17.4886Z"
        fill="currentColor"
        strokeWidth="1.49998"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const AISparkleFilled = (props: React.SVGProps<any>) => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 8.55907C4.71668 8.58243 8.5358 12.4014 8.55917 17.1179C8.58253 12.4014 12.4019 8.58244 17.1185 8.55907C12.4018 8.53575 8.58267 4.71659 8.55937 0C8.53607 4.71659 4.71675 8.53576 0 8.55907Z" />
    <path d="M13.0508 15.0179C15.7964 15.0315 18.0195 17.2546 18.0331 20.0001C18.0467 17.2546 20.27 15.0315 23.0156 15.0179C20.2699 15.0044 18.0468 12.7812 18.0332 10.0356C18.0197 12.7812 15.7964 15.0044 13.0508 15.0179Z" />
  </svg>
)

export default AISparkle
