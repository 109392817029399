import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@suraasa/placebo-ui"

type Props = {
  open: boolean
  handleClose: () => void
}
const JobApplicationKit = ({ open, handleClose }: Props) => {
  return (
    <Sheet open={open} onOpenChange={handleClose}>
      <SheetContent height={98} closeWhenInteractOutside>
        <SheetHeader>
          <SheetTitle>Job Application Kit</SheetTitle>
        </SheetHeader>
        <SheetBody className="flex grow flex-col p-0">
          <iframe
            width="100%"
            height="100%"
            title="Job Application Kit"
            src="https://assets.suraasa.com/lms/profile-assets/job-application-kit.html"
          />
        </SheetBody>
      </SheetContent>
    </Sheet>
  )
}

export default JobApplicationKit
