import {
  Button,
  CircularProgress,
  Dialog,
  DialogBody,
  DialogContent,
  Typography,
} from "@suraasa/placebo-ui"
import { IconButton } from "@suraasa/placebo-ui-legacy"
import ConnectGoogleAccountImage from "assets/AITools/export-to-google.svg"
import GoogleIcon from "assets/icons/google-icon.svg"
import AsyncBuilder from "components/AsyncBuilder"
import { Xmark } from "iconoir-react"

type Props = {
  onOpenChange: (x: boolean) => void
  open: boolean
  onConnectClick: () => Promise<any>
}

const ConnectGoogleAccount = ({
  open,
  onOpenChange,
  onConnectClick,
}: Props) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent closeWhenInteractOutside>
        <DialogBody className="relative h-[400px] rounded-xl bg-[#F5F5F5] !p-0">
          <div className="absolute right-2 top-2">
            <IconButton onClick={() => onOpenChange(false)} autoFocus={false}>
              <Xmark className="text-onSurface-800" />
            </IconButton>
          </div>
          <div className="flex h-full flex-col">
            <div className="flex w-full flex-1 items-center justify-center">
              <img
                src={ConnectGoogleAccountImage}
                alt="connect google account"
              />
            </div>
            <div className="flex h-[215px] flex-col items-center justify-center gap-1 bg-surface">
              <div className="flex flex-col items-center gap-1">
                <Typography className="text-2xl font-bold text-onSurface-900 md:text-4xl">
                  <span className="flex items-center">
                    Connect with Google{" "}
                    <img src={GoogleIcon} alt="GoogleIcon" />
                  </span>
                </Typography>
                <Typography
                  variant="largeBody"
                  className="px-4 text-center text-onSurface-500"
                >
                  Sign in using Google Account to save your lesson plan as
                  Google Doc
                </Typography>
              </div>
              <div>
                <AsyncBuilder
                  render={({ loading, onClick }) => (
                    <Button
                      onClick={onClick}
                      className="mt-2 rounded-full bg-secondary-900"
                    >
                      Sign in using Google
                      {loading && (
                        <CircularProgress className="ml-1 text-surface" />
                      )}
                    </Button>
                  )}
                  handler={onConnectClick}
                />
              </div>
            </div>
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default ConnectGoogleAccount
