import { Typography } from "@suraasa/placebo-ui"
import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
} from "@suraasa/placebo-ui-legacy"
import { NavArrowDown } from "iconoir-react"
import React from "react"

const FAQs = ({
  endSlot,
  data,
  startSlot,
}: {
  startSlot?: React.ReactNode
  endSlot?: React.ReactNode
  data: { title: string; description: string }[]
}) => {
  return (
    <div>
      {startSlot}
      <div>
        <div className="pb-3">
          <Typography variant="title2">Frequently Asked Questions</Typography>
        </div>
        <div className="flex flex-col gap-2">
          {data.map((item, index) => (
            <Accordion
              className="overflow-hidden rounded-lg bg-surface-100 pt-1"
              key={index}
            >
              <AccordionHeader
                className="!pb-2"
                icon={<NavArrowDown color="black" />}
              >
                <Typography className="text-start" variant="strong">
                  {item.title}
                </Typography>
              </AccordionHeader>

              <AccordionDetails className="bg-surface-50 !py-2">
                <div>
                  {typeof item.description === "string" ? (
                    <Typography
                      dangerouslySetInnerHTML={{ __html: item.description }}
                      variant="body"
                    />
                  ) : (
                    item.description
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      {endSlot}
    </div>
  )
}

export default FAQs
