import clsx from "clsx"
import { PropsWithChildren } from "react"

const CollapseWrapper = ({
  children,
  open,
  direction = "vertical",

  className,
}: PropsWithChildren<{
  open: boolean
  direction?: "vertical" | "horizontal"
  className?: string
}>) => {
  return (
    <div
      className={clsx("grid w-full grow transition-all", {
        ...(direction === "vertical"
          ? {
              "grid-rows-[1fr]": open,
              "grid-rows-[0fr]": !open,
            }
          : {
              "grid-cols-[1fr]": open,
              "grid-cols-[0fr]": !open,
            }),
      })}
    >
      <div
        className={clsx("overflow-hidden", {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          [className]: open,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default CollapseWrapper
