import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { LearningItem } from "api/resources/learningItems/types"
import clsx from "clsx"
import Fallback from "components/Fallback"
import SlidingSheet from "components/SlidingSheet"
import MilestoneList from "features/LearningModule/MilestoneList"
import { Xmark } from "iconoir-react"
import { CSSTransition } from "react-transition-group"

interface Props {
  isEmpty?: boolean
  items?: LearningItem[]
  drawerProps: {
    open: boolean
    toggle: () => void
  }
  currentItem: LearningItem | undefined
}

const CurriculumSidebar = ({
  isEmpty = false,
  items,
  drawerProps,
  currentItem,
}: Props) => {
  return (
    <>
      <SlidingSheet
        unmountOnExit
        from="end"
        open={drawerProps.open}
        onClose={drawerProps.toggle}
        className="max-w-[400px]"
      >
        <div className="border border-onSurface-100">
          <div className="sticky top-0 z-10 bg-white px-2 pb-1 pt-1.5">
            <Button
              startAdornment={<Xmark />}
              onClick={() => drawerProps.toggle()}
              variant="text"
              color="black"
              className="-ml-1"
            >
              Hide
            </Button>
            <Typography variant="title3" className="mt-1.5">
              Curriculum
            </Typography>
          </div>
          {isEmpty && <Fallback hasError className="mt-6" />}
          {items && (
            <MilestoneList
              items={items}
              onItemSelect={drawerProps.toggle}
              currentItem={currentItem}
            />
          )}
        </div>
      </SlidingSheet>
      <CSSTransition
        in={!drawerProps.open}
        timeout={300}
        appear
        mountOnEnter
        classNames={{
          exitActive: "translate-x-8",
          exitDone: "translate-x-8",
          enterActive: "translate-x-0",
          enterDone: "translate-x-0",
        }}
      >
        <Button
          color="black"
          onClick={() => drawerProps.toggle()}
          className={clsx(
            "fixed -right-8 top-20 z-20 !hidden -rotate-90 duration-300 ease-linear sm:!inline-block",
            {
              "delay-300": !drawerProps.open,
            }
          )}
        >
          Show Curriculum
        </Button>
      </CSSTransition>
    </>
  )
}

export default CurriculumSidebar
