import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { Profile, SkillEvidence } from "api/resources/profile/types"
import Monitor from "assets/Fallback/monitor.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import { SkillProfileControllerContext } from "features/SkillProfile"
import { useContext } from "react"

type Props = {
  profile: Profile
  skillEvidences: SkillEvidence[]
  isLoading: boolean
  isError: boolean
}

const SkillOverview = ({ skillEvidences, isLoading, isError }: Props) => {
  const controller = useContext(SkillProfileControllerContext)

  return (
    <div>
      {(isLoading || isError) && (
        <Fallback isLoading={isLoading} hasError={isError} className="mt-4" />
      )}

      {skillEvidences && (
        <div className="mt-3 overflow-hidden border border-surface-200 bg-white sm:rounded-2xl">
          <div className="border-b border-surface-200 px-2.5 py-1.5">
            <Typography variant="title3">Skills</Typography>
          </div>
          {skillEvidences.length > 0 ? (
            skillEvidences.map((item, index) => (
              <div
                key={item.slug}
                className={clsx(
                  "mx-2 flex flex-wrap items-center justify-between gap-1 py-2",
                  {
                    "border-b border-surface-200":
                      index !== skillEvidences.length - 1,
                  }
                )}
              >
                <Typography className="text-onSurface-800" variant="title4">
                  {item.name}
                </Typography>
                <Button
                  onClick={() => {
                    controller.update({ courseId: item.slug })
                  }}
                  variant="text"
                >
                  View Evidences
                </Button>
              </div>
            ))
          ) : (
            <Fallback
              className="my-4"
              data={{ image: Monitor, title: "No Skill Evidences Available!" }}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default SkillOverview
