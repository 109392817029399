import {
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Select,
  toast,
} from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { Language } from "api/resources/global/types"
import {
  LanguageProficiency,
  LanguageResponse,
} from "api/resources/profile/types"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { useResource } from "utils/hooks/useResource"

import { proficiencyOptions } from "./utils"

type Props = {
  onAdd: (data: LanguageResponse) => void
  onBack?: () => void
  onRequestClose: () => void
  open: boolean
}

const AddDialog = ({ onRequestClose, onBack, onAdd }: Props) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setError,
  } = useForm<{ languageId: Language; proficiency: LanguageProficiency }>()
  const [loading, setLoading] = useState(false)

  const { languages } = useResource(["languages"]) as { languages: Language[] }

  useEffect(() => {
    reset()
  }, [reset])

  const onSubmit = handleSubmit(async data => {
    setLoading(true)

    const res = await api.profile.languages.create({
      data: {
        ...data,
        languageId: data.languageId.uuid,
      },
    })
    if (res.isSuccessful) {
      onAdd(res.data)
      setLoading(false)
      toast.success("Added successfully.")
      reset()
      if (onBack) onBack()
    } else {
      handleErrors(res, { setter: setError })
      setLoading(false)
    }
  })

  return (
    <div>
      <DialogHeader>
        <DialogTitle onBack={onBack}>Add New Language</DialogTitle>
      </DialogHeader>

      <DialogBody className="px-3 pb-5 pt-2.5">
        <form onSubmit={onSubmit} className="flex flex-col gap-3">
          <div>
            <Controller
              control={control}
              name="languageId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  mountOnBody
                  errors={errors.languageId?.message}
                  label="Language"
                  getOptionValue={(x: { uuid: any }) => x.uuid}
                  getOptionLabel={(x: { name: any }) => x.name}
                  options={languages}
                  value={value}
                  placeholder="Select Language"
                  onBlur={onBlur}
                  onChange={onChange}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="proficiency"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  mountOnBody
                  errors={errors.proficiency?.message}
                  label="Proficiency"
                  options={proficiencyOptions}
                  value={
                    value
                      ? proficiencyOptions.find(
                          proficiency => proficiency.value === value
                        )
                      : null
                  }
                  placeholder="Select Language Proficiency"
                  onBlur={onBlur}
                  onChange={(val: any) => onChange(val?.value)}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>
        </form>
      </DialogBody>
      <DialogFooter>
        <Button
          size="sm"
          onClick={onRequestClose}
          variant="outlined"
          color="black"
        >
          Close
        </Button>
        <Button size="sm" onClick={onSubmit} loading={loading}>
          Save
        </Button>
      </DialogFooter>
    </div>
  )
}

export default AddDialog
