import { toast, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
} from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import clsx from "clsx"
import { ArrowSeparateVertical, Plus } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { BROWSER_STORAGE_KEYS, UpdateProfileEnum } from "utils/constants"
import { handleErrors } from "utils/helpers"

import ProfileContext from "../../context"
import DraggableItem from "../ReOrderingFeatureOnboarding/DraggableItem"
import useDraggable from "../ReOrderingFeatureOnboarding/DraggableItem/useDraggable"
import Instructions from "../ReOrderingFeatureOnboarding/Instructions"
import AddDialog from "./AddDialog"
import RemoveDialog from "./RemoveDialog"

const { reorderOnboarding: updateProfileDialog } = BROWSER_STORAGE_KEYS

const SubjectsDialog = ({
  open,
  onRequestClose,
  customAction,
}: Pick<DialogProps, "open" | "onRequestClose"> &
  Pick<DialogTitleProps, "onBack"> & { customAction?: JSX.Element }) => {
  const [changePriority, setChangePriority] = useState(false)
  const {
    subjects: { data, add, remove, set },
  } = useContext(ProfileContext)

  const hasNoItems = data.length === 0

  const subjectList = useDraggable({ data })

  const [openAddSubjectDialog, setOpenAddSubjectDialog] = useState(false)
  const [subjectToDelete, setSubjectToDelete] = useState<number | null>(null)

  const { mutate: handleRemove, isLoading: loading } = useMutation({
    mutationFn: () =>
      api.profile.subjects.delete({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { id: subjectToDelete! },
      }),
    onSuccess: () => {
      if (subjectToDelete) {
        remove(subjectToDelete)
        toast.success("Removed successfully.")
        setSubjectToDelete(null)
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const { mutate: handleUpdateSequence, isLoading: loadingSequence } =
    useMutation({
      mutationFn: (data: { id: number; sequence: number }[]) =>
        api.profile.subjects.updateSequence({
          data: { subjectInterests: data },
        }),
      onSuccess: () => {
        toast.success("Successfully Updated")
        const newData = subjectList.processData(false)
        if (newData && set) {
          set(newData)
        }
      },
      onError: err => {
        if (err instanceof APIError) {
          handleErrors(err)
        }
      },
    })

  useEffect(() => {
    if (open) setOpenAddSubjectDialog(hasNoItems)
  }, [open, hasNoItems])

  function selectedDeleteSubject() {
    return data.find(item => item.id === subjectToDelete)
  }

  const changePriorityFnc = () => {
    setChangePriority(true)

    const updateStatus: string[] = JSON.parse(
      localStorage.getItem(updateProfileDialog) ?? "[]"
    )

    if (!updateStatus.includes(UpdateProfileEnum.SUBJECTS)) {
      updateStatus.push(UpdateProfileEnum.SUBJECTS)
    }

    localStorage.setItem(updateProfileDialog, JSON.stringify(updateStatus))
  }
  return (
    <Dialog
      open={open}
      width={518}
      onAfterClose={() => {
        setOpenAddSubjectDialog(false)
        setChangePriority(false)
        subjectList.reset()
      }}
      onRequestClose={onRequestClose}
    >
      {openAddSubjectDialog || hasNoItems ? (
        <AddDialog
          open={openAddSubjectDialog}
          onAdd={add}
          onBack={hasNoItems ? undefined : () => setOpenAddSubjectDialog(false)}
          onRequestClose={onRequestClose}
        />
      ) : (
        <>
          <RemoveDialog
            onClose={() => setSubjectToDelete(null)}
            open={Boolean(subjectToDelete)}
            loading={loading}
            onRemove={handleRemove}
            subjectName={selectedDeleteSubject()?.subject.name}
            listCount={data.length}
          />
          <DialogTitle>Subjects</DialogTitle>
          <DialogContent className="flex flex-col !p-0">
            <div className="relative flex items-center justify-between border-b border-onSurface-200 px-1.5 py-2">
              <Button
                startAdornment={<Plus />}
                variant="text"
                onClick={() => setOpenAddSubjectDialog(true)}
              >
                Add New Subject
              </Button>

              <IconButton
                onClick={changePriorityFnc}
                variant={changePriority ? "filled" : "plain"}
                size="xs"
                className="!grid sm:!hidden [&>svg]:size-2.5"
              >
                <ArrowSeparateVertical />
              </IconButton>

              <Button
                startAdornment={<ArrowSeparateVertical />}
                variant="text"
                className="!hidden sm:!grid"
                onClick={changePriorityFnc}
              >
                Change Priority
              </Button>
            </div>
            {changePriority && (
              <Instructions
                type="subjects"
                title="Change Subject Priority"
                description="Drag your main preferred subject to the top to make it your primary subject interest. Similarly, your second location will be marked as your secondary subject interest."
                disclaimer="We match your profile with the right schools based on your primary and secondary subject interests."
              />
            )}
            <div>
              {subjectList.data.length > 0 &&
                subjectList.data.map((item, index) => (
                  <DraggableItem
                    showTags
                    onDelete={() => {
                      setSubjectToDelete(item.id)
                    }}
                    className={clsx(
                      "[&>div]:mx-1.5 [&>div]:py-1.5 sm:[&>div]:mx-3",
                      {
                        "[&>div]:border-b [&>div]:border-onSurface-200":
                          subjectList.data.length - 1 !== index,
                      }
                    )}
                    isDraggable={changePriority}
                    key={item.id}
                    index={index}
                    onDragStart={subjectList.onDragStart}
                    onDrop={subjectList.onDrop}
                    onDragOver={subjectList.onDragOver}
                  >
                    <Typography variant="strong" className="mr-2">
                      {item.subject.name}
                    </Typography>
                  </DraggableItem>
                ))}
            </div>
          </DialogContent>
          <DialogFooter>
            {changePriority && (
              <div className="flex justify-end">
                <Button
                  variant="outlined"
                  color="critical"
                  size="sm"
                  onClick={() => {
                    subjectList.reset()
                    setChangePriority(false)
                  }}
                  className="mr-1"
                >
                  Discard
                </Button>
                <Button
                  loading={loadingSequence}
                  onClick={() => {
                    const newData = subjectList.processData(true)
                    handleUpdateSequence(newData)
                    setChangePriority(false)
                  }}
                  size="sm"
                >
                  Save
                </Button>
              </div>
            )}
            {!changePriority && !customAction && (
              <Button
                onClick={onRequestClose}
                className="ml-auto !block"
                size="sm"
                variant="outlined"
                color="secondary"
              >
                Close
              </Button>
            )}
            {!changePriority && customAction}
          </DialogFooter>
        </>
      )}
    </Dialog>
  )
}

export default SubjectsDialog
