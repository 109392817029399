import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getPolluxURL = getServiceURL(SERVICE_PREFIX.pollux)
const getLibraURL = getServiceURL(SERVICE_PREFIX.libra)

export const urls = validateUrls({
  orders: {
    base: () => getPolluxURL("/v1/orders"),
    detail: id => getPolluxURL(`/v1/orders/${id}/`),
    invoice: transactionId => getLibraURL(`/v1/invoices/${transactionId}/`),
    create: () => getPolluxURL("/v1/cartless-orders"),
  },
})
