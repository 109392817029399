import { Typography } from "@suraasa/placebo-ui"
import { Radio } from "@suraasa/placebo-ui-legacy"
import { Answer as AnswerType } from "api/resources/assessments/types"
import clsx from "clsx"
import React from "react"

import styles from "../MultipleCorrect/MultipleCorrect.module.css"

type Props = {
  question: AnswerType
}

function SingleCorrect({ question }: Props) {
  return (
    <>
      <div>
        <Typography variant="body" className="mb-2 mt-1">
          {question.question}
        </Typography>
      </div>
      <div className="mt-2">
        <div>
          {Object.entries(question.options).map(([optionId, optionText]) => {
            const isCorrect = question.response
              ? question.isCorrect &&
                question.response[0] === parseInt(optionId)
              : false
            const isMarked = question.response
              ? parseInt(optionId) === question.response[0]
              : false

            return (
              <div key={optionId}>
                <Radio
                  readOnly
                  className={clsx(
                    "mb-1 w-full cursor-text rounded-[5px] p-0.75",
                    {
                      [styles.correctAnswer]: isCorrect,
                      [styles.markedAnswer]: !isCorrect && isMarked,
                    }
                  )}
                  label={optionText}
                  color={isCorrect ? "success" : "critical"}
                  checked={isCorrect || isMarked}
                />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default SingleCorrect
