import {
  Button,
  Container,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"

const ReattemptBanner = ({
  onClick,
  forceReattempt,
}: {
  onClick: () => void
  forceReattempt?: boolean
}) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <div
      className={clsx({
        "bg-highlight-50": !forceReattempt,
        "bg-critical-50": forceReattempt,
      })}
    >
      <Container className="py-1">
        <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
          <Typography
            color={forceReattempt ? "critical.900" : "highlight.900"}
            variant="strongSmallBody"
          >
            {forceReattempt
              ? "You did not meet the passing criteria, please request for a reattempt"
              : "Do you want to improve your score?"}
          </Typography>
          <Button
            fullWidth={isXsDown}
            onClick={onClick}
            color={forceReattempt ? "critical" : "primary"}
            variant="text"
          >
            Request a Reattempt
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default ReattemptBanner
