import { Button, cn, Typography } from "@suraasa/placebo-ui"
import { AISparkleFilled } from "assets/icons/system/AISparkle"
import { useSuraasaProContext } from "features/AItools/PRO/context/SuraasaProContext"

const ClaimProTrial = ({ className = "" }) => {
  const { isActive, showGetProSheet } = useSuraasaProContext()

  const onClaimClick = () => {
    showGetProSheet()
  }

  if (isActive) {
    return null
  }

  return (
    <div className={cn("relative overflow-hidden rounded-2xl", className)}>
      <div
        className={cn(
          "flex gap-1 flex-col items-center justify-between relative z-2 p-2 sm:flex-row bg-gradient-to-r from-[#309FBA] to-[#4383CC]"
        )}
      >
        <div className="flex gap-1 text-surface sm:flex-row">
          <AISparkleFilled fill="white" width={44} height={40} />
          <div className="flex flex-col gap-0.75">
            <Typography variant="title4" className="text-inherit">
              Pro AI Teaching Tools by Suraasa
            </Typography>
            <Typography
              variant="smallBody"
              className="max-w-[420px] text-inherit xl:max-w-full"
            >
              Save tons of time and effort by finishing hours of work in
              minutes—with AI teaching tools, all in one place.
            </Typography>
          </div>
        </div>
        <Button
          className="mt-2 w-full max-w-[240px] !bg-surface p-2 sm:mt-0"
          variant="filled"
          size="icon"
          onClick={onClaimClick}
        >
          <Typography
            variant={"button"}
            className="inline-block bg-gradient-to-r from-[#309FBA] to-[#4383CC] bg-clip-text text-transparent"
          >
            Claim free early access
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export default ClaimProTrial
