import {
  Dialog,
  DialogBody,
  DialogContent,
  Sheet,
  SheetBody,
  SheetContent,
  Typography,
} from "@suraasa/placebo-ui"
import {
  CircularProgress,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { RetrieveCoverLetter, ToolType } from "api/resources/aiTools/types"
import clsx from "clsx"
import { Xmark } from "iconoir-react"

import { ExportOutputButton } from "../GenerationOutput/Export/ContentActions"
import { getFileName } from "./utils/helper"

const ViewCoverLetterDialog = ({
  open,
  data,
  onClose,
}: {
  open: boolean
  data?: RetrieveCoverLetter
  onClose: () => void
}) => {
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const fileName = getFileName({
    fullName: data?.fullName,
    schoolName: data?.schoolName,
  })

  const Content = data ? (
    <>
      <div className="p-2 pb-8 sm:scale-[0.85] sm:px-0 sm:pb-4 sm:pt-0">
        <div className="mb-3 grid grid-cols-2 grid-rows-1 items-start">
          <Typography
            className={clsx("text-sm sm:!text-2xl md:text-2xl")}
            variant="title3"
          >
            {data.fullName}
          </Typography>
          <div className="text-end">
            {(data.state || data.country) && (
              <Typography
                className={clsx({ "!text-sm text-onSurface-500": isXsDown })}
              >
                {[data.state, data.country].filter(Boolean).join(", ")}
              </Typography>
            )}
            {data.email && (
              <Typography
                className={clsx({ "!text-sm text-onSurface-500": isXsDown })}
              >
                {data.email}
              </Typography>
            )}
            {data.phoneNumber && (
              <Typography
                className={clsx({ "!text-sm text-onSurface-500": isXsDown })}
              >
                {data.phoneNumber}
              </Typography>
            )}
          </div>
        </div>
        <div className="mb-3 h-0.5 w-full bg-primary-600" />
        <Typography className={clsx({ "!text-sm": isXsDown })}>
          {data.output}
        </Typography>
      </div>
      <div className="absolute inset-x-0 bottom-1 mx-auto flex w-fit items-center space-x-0.5 rounded-lg bg-onSurface-800 p-1">
        {isMdDown && (
          <button
            onClick={onClose}
            className="flex items-center justify-center gap-1 px-1.5 py-0.75"
          >
            <Xmark className="size-2.5 text-white" />

            <Typography variant="button" className="block !text-white">
              Close
            </Typography>
          </button>
        )}
        {data.id && (
          <ExportOutputButton
            currentResponseId={data.id}
            toolType={ToolType.coverLetter}
            title={fileName}
          >
            <button className="flex items-center justify-center gap-1 px-1.5 py-0.75">
              <Typography variant="button" className="block !text-white">
                Download
              </Typography>
            </button>
          </ExportOutputButton>
        )}
      </div>
    </>
  ) : (
    <div className="flex items-center justify-center p-4">
      <CircularProgress />
    </div>
  )

  if (isSmDown) {
    return (
      <Sheet open={open} onOpenChange={onClose}>
        <SheetContent
          side={isSmDown ? "bottom" : "right"}
          className="bg-white !p-0"
          height={95}
        >
          <SheetBody className="p-0">{Content}</SheetBody>
        </SheetContent>
      </Sheet>
    )
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        overlayClassName="lg:hidden"
        className="!max-h-full max-w-[min(95%,48rem)] shadow-2xl lg:left-[37.5%]"
      >
        <DialogBody className="px-4">{Content}</DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default ViewCoverLetterDialog
