import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { Button, Select, TextField } from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  DifficultyLevel,
  ListPromptHistory,
  RetrievePrompt,
  SubjectiveAssessmentProFormType,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import ErrorMessage from "components/ErrorMessage"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { Edit } from "iconoir-react"
import { useContext, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../../context"
import SelectLessonPlanSheet from "../../../helpers/SelectLessonPlanSheet"
import AssessmentStructure, {
  AssessmentStructureRow,
} from "../../AssessmentStructure"

type Form = SubjectiveAssessmentProFormType

const LEVEL_OF_DIFFICULTY: { value: DifficultyLevel; label: string }[] = [
  { value: "easy", label: "Easy" },
  { value: "medium", label: "Medium" },
  { value: "difficult", label: "Difficult" },
  { value: "expert", label: "Expert" },
]

const toolType = ToolType.subjectiveAssessmentPro

const ProForm = ({
  onGeneration,
  openLessonPlanSelector,
}: {
  onGeneration: (data: APIResponse<RetrievePrompt<typeof toolType>>) => void
  openLessonPlanSelector?: boolean
}) => {
  const [searchParams] = useSearchParams()
  const mode = searchParams.get("mode") // "new" | "template"
  const isTemplateMode = mode === "template"

  const { setPromptDataId } = useContext(AIToolsContext)
  const [structure, setStructure] = useState<AssessmentStructureRow[] | null>(
    null
  )
  const [difficultyLevel, setDifficultyLevel] = useState<{
    value: DifficultyLevel
    label: string
  } | null>(null)

  const [showErrors, setShowErrors] = useState<{
    selectedLessonPlan?: string
    difficultyLevel?: string
    structure?: string
  } | null>(null)

  const [openSidebar, setOpenSidebar] = useState(false)

  const [selectedLessonPlan, setSelectedLessonPlan] =
    useState<ListPromptHistory | null>(null)

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Form>()

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: any) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
        model: "pro",
      })
      return api.aiTools.generateSubjectiveAssessmentPro({ data })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
        model: "pro",
      })
      onGeneration(res)

      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        model: "pro",
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) > 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const onSubmit = handleSubmit(async data => {
    if (isTemplateMode) {
      let hasError = false

      if (!selectedLessonPlan) {
        setShowErrors(prev => ({
          ...prev,
          selectedLessonPlan: "Lesson Plan is required",
        }))
        hasError = true
      }

      if (!difficultyLevel) {
        setShowErrors(prev => ({
          ...prev,
          difficultyLevel: "Difficulty level is required",
        }))
        hasError = true
      }

      if (!structure || structure.length === 0) {
        setShowErrors(prev => ({
          ...prev,
          structure: "Assessment structure is required",
        }))
        hasError = true
      }

      if (hasError) return
      setShowErrors(null)

      mutate({
        lessonPlan: selectedLessonPlan!.promptDataId,
        questionDetails: structure,
        difficultyLevel: difficultyLevel!.value,
      })
      return
    }
    mutate({
      grade: data.grade,
      subject: data.subject,
      curriculum: data.curriculum,
      topic: data.topic,
      country: data.country,
      assessmentObjectives: data.assessmentObjectives,
      difficultyLevel: data.difficultyLevel,
      questionDetails: structure,
      ...(data.book ? { book: data.book } : {}),
    })
  })

  return (
    <Paper>
      <form onSubmit={onSubmit} className="space-y-2">
        {!isTemplateMode ? (
          <div className="flex flex-col gap-2">
            <div className=" grid grid-cols-1 gap-2 sm:grid-cols-2">
              <TextField
                inputLabelProps={{ required: true }}
                label="Class/Grade"
                fullWidth
                placeholder="Ex: 12th Grade"
                error={Boolean(errors.grade)}
                helperText={errors.grade?.message}
                {...register("grade", {
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                {...register("country", {
                  required: { value: true, message: "Required" },
                })}
                inputLabelProps={{ required: true }}
                error={Boolean(errors.country)}
                label="Country"
                fullWidth
                placeholder="Ex: India"
                helperText={
                  errors.country?.message ||
                  "We use this to tailor the lesson plan to your region"
                }
              />
              <TextField
                inputLabelProps={{ required: true }}
                label="Subject"
                fullWidth
                placeholder="Ex: Business Studies"
                error={Boolean(errors.subject)}
                helperText={errors.subject?.message}
                {...register("subject", {
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                {...register("curriculum", {
                  required: { value: true, message: "Required" },
                })}
                inputLabelProps={{ required: true }}
                error={Boolean(errors.curriculum)}
                label="Curriculum"
                fullWidth
                placeholder="Ex: CBSE"
                helperText={errors.curriculum?.message}
              />
              <TextField
                {...register("book")}
                error={Boolean(errors.book)}
                label="Book Name (Optional)"
                fullWidth
                placeholder="Ex: NCERT"
                helperText={errors.book?.message}
              />
              <Controller
                control={control}
                name="difficultyLevel"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    inputLabelProps={{ required: true }}
                    label="Level of Difficulty"
                    error={Boolean(errors.difficultyLevel)}
                    helperText={errors.difficultyLevel?.message}
                    options={LEVEL_OF_DIFFICULTY}
                    placeholder="Ex: Difficult"
                    value={LEVEL_OF_DIFFICULTY.find(c => c.value === value)}
                    fullWidth
                    mountOnBody
                    onBlur={onBlur}
                    onChange={val => onChange(val?.value)}
                  />
                )}
                rules={{ required: { value: true, message: "Required" } }}
              />
            </div>

            {/* @ts-expect-error: placebo rows prop error */}
            <TextField
              multiLine
              inputLabelProps={{ required: true }}
              fullWidth
              rows={3}
              label="topic"
              placeholder="Ex: Modern Business Practices."
              error={Boolean(errors.topic)}
              helperText={errors.topic?.message}
              {...register("topic", {
                required: { value: true, message: "Required" },
              })}
            />

            {/* @ts-expect-error: placebo rows prop error */}
            <TextField
              multiLine
              inputLabelProps={{ required: true }}
              fullWidth
              rows={3}
              label="Objective"
              placeholder={`Ex: Evaluate students' understanding of modern business practices by analyzing current trends, technologies, and strategies in business management and their impact on organizational success.`}
              error={Boolean(errors.assessmentObjectives)}
              helperText={errors.assessmentObjectives?.message}
              {...register("assessmentObjectives", {
                required: { value: true, message: "Required" },
              })}
            />
          </div>
        ) : (
          <div>
            <div className="flex items-center justify-between">
              <div>
                <Typography variant="strong">Lesson Plan</Typography>
                {selectedLessonPlan && (
                  <Typography variant="body">
                    {selectedLessonPlan.title}
                  </Typography>
                )}
              </div>
              <Button
                variant="text"
                onClick={() => {
                  setOpenSidebar(true)
                }}
                nudge="right"
                startAdornment={selectedLessonPlan ? <Edit /> : undefined}
              >
                {selectedLessonPlan ? "Edit" : "Select Lesson Plan"}
              </Button>
            </div>
            {showErrors?.selectedLessonPlan && (
              <ErrorMessage title={showErrors?.selectedLessonPlan} />
            )}
            <SelectLessonPlanSheet
              open={openSidebar}
              onOpenChange={() => setOpenSidebar(false)}
              onSelect={subjectiveAssessment => {
                setSelectedLessonPlan(subjectiveAssessment)
                setOpenSidebar(false)
              }}
            />
          </div>
        )}
        <AssessmentStructure
          setStructure={setStructure}
          structure={structure}
          openLessonPlanSelector={openLessonPlanSelector}
        />
        {showErrors?.structure && (
          <ErrorMessage title={showErrors?.structure} />
        )}

        {selectedLessonPlan && (
          <>
            <div className="flex flex-wrap items-center justify-between gap-2">
              <Typography variant="strong">Level of Difficulty</Typography>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="text">
                    {difficultyLevel?.label || "Select"}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {LEVEL_OF_DIFFICULTY.map(item => (
                    <DropdownMenuItem
                      onClick={() => setDifficultyLevel(item)}
                      key={item.value}
                    >
                      {item.label}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            {showErrors?.difficultyLevel && (
              <ErrorMessage title={showErrors?.difficultyLevel} />
            )}
          </>
        )}

        <Button
          variant="filled"
          fullWidth
          type="submit"
          loading={isLoading}
          // disabled={
          //   !totalQuestions ||
          //   totalQuestions > 20 ||
          //   totalQuestions === 0 ||
          //   (Boolean(selectedLessonPlan) && !difficultyLevel)
          // }
        >
          Generate {getToolName(toolType)}
        </Button>
      </form>
      <AiToolsLoading type={toolType} open={isLoading} />
    </Paper>
  )
}

export default ProForm
