import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getSolisURL = getServiceURL(SERVICE_PREFIX.solis)
const getPolarisURL = getServiceURL(SERVICE_PREFIX.polaris)

export const urls = validateUrls({
  email: {
    detail: id => getSolisURL(`/v1/users/emails/${id}/`),
    list: () => getSolisURL(`/v1/users/emails/`),
    create: () => getSolisURL(`/v1/users/emails/unverified/`),
    verify: () => getSolisURL(`/v1/users/emails/unverified/verify/`),
  },
  password: {
    changePassword: () => getSolisURL("/v1/auth/change-password/"),
  },
  config: {
    preferences: () => getPolarisURL(`/v1/users/settings/`),
  },
  phoneNumber: {
    add: () => getSolisURL(`/v1/users/phone-numbers/unverified/`),
  },
  otp: {
    verify: () => getSolisURL(`/v1/users/otp/verify/`),
    send: () => getSolisURL(`/v1/users/otp/`),
  },
  socialAuth: {
    list: () => getSolisURL(`/v1/auth/social/accounts/`),
    link: () => getSolisURL(`/v1/auth/social/accounts/link/`),
    unlink: id => getSolisURL(`/v1/auth/social/accounts/${id}/`),
  },
})
