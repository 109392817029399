import { Typography } from "@suraasa/placebo-ui"
import { IconButton, Tag } from "@suraasa/placebo-ui-legacy"
import { Edit, Plus } from "iconoir-react"
import React, { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { pluralize } from "utils/helpers"

import ProfileContext from "../../context"
import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import InterestsDialog from "./InterestsDialog"

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5),
  },
}))

const Interests = () => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const toggle = () => setOpenDialog(!openDialog)

  const {
    interests: { data },
    isPublic,
  } = useContext(ProfileContext)

  return (
    <>
      <InterestsDialog handleClose={toggle} open={openDialog} />
      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              actionIcon={
                isPublic ? undefined : (
                  <IconButton onClick={toggle}>
                    {data.length > 0 ? <Edit /> : <Plus />}
                  </IconButton>
                )
              }
              itemCount={data.length}
              title={`${pluralize("Interest", data.length, {
                skipCount: true,
              })} & ${pluralize("Hobby", data.length, {
                plural: "Hobbies",
                skipCount: true,
              })}`}
            />
          }
        />

        <SectionContent className={classes.root} xPadding={2}>
          {data.length > 0 ? (
            data.map((item, i) => (
              <Tag key={i} label={item.interest} size="sm" />
            ))
          ) : (
            <Typography className="text-onSurface-400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any interests. Click on + icon to add one."}
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}

export default Interests
