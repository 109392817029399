import React from "react"

const CreateFromExisting = ({
  currentColor = "#9A7BBA",
}: {
  currentColor?: string
}) => {
  return (
    <svg
      width="135"
      height="108"
      viewBox="0 0 135 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.499967"
        width="65.9513"
        height="86.3632"
        rx="3.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 76.7728 31.0112)"
        fill="white"
        stroke={currentColor}
      />
      <rect
        y="0.499967"
        width="65.9513"
        height="86.3632"
        rx="3.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 76.7728 24.8589)"
        fill="white"
        stroke={currentColor}
      />
      <rect
        y="0.499967"
        width="65.9513"
        height="86.3632"
        rx="3.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 76.7728 18.7065)"
        fill="white"
        stroke={currentColor}
      />
      <rect
        y="0.499967"
        width="65.9513"
        height="86.3632"
        rx="3.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 76.7728 12.5544)"
        fill="white"
        stroke={currentColor}
      />
      <rect
        y="0.499967"
        width="65.9513"
        height="86.3632"
        rx="3.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 76.7728 6.40234)"
        fill="white"
        stroke={currentColor}
      />
      <rect
        y="0.499967"
        width="65.9513"
        height="86.3632"
        rx="3.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 76.7728 0.25)"
        fill="white"
        stroke={currentColor}
      />
      <g clipPath="url(#clip0_12904_27977)">
        <path
          d="M55.1786 44.4916C52.468 42.9268 51.0047 41.0447 50.7886 38.8454C50.5725 36.646 51.6627 34.6841 54.059 32.9596L56.4749 34.3544C54.7071 35.6924 53.9266 37.1921 54.1332 38.8535C54.3398 40.515 55.474 41.9408 57.5356 43.1309C59.8337 44.4576 62.5835 45.1212 65.7852 45.1217C68.9868 45.1221 71.7366 44.4585 74.0347 43.1309C76.3328 41.8042 77.4819 40.217 77.4819 38.3691C77.4819 36.5213 76.3328 34.9336 74.0347 33.606C72.6794 32.8236 71.0983 32.2736 69.2912 31.9561C67.4842 31.6386 65.628 31.5649 63.7228 31.735L66.9637 33.606L64.6066 34.9667L57.5356 30.8846L64.6066 26.8024L66.9637 28.1631L64.1942 29.762C66.453 29.6146 68.6482 29.7592 70.7797 30.196C72.9112 30.6328 74.7819 31.3159 76.3918 32.2453C77.8649 33.0957 78.9648 34.0541 79.6916 35.1204C80.4183 36.1868 80.7821 37.2695 80.7829 38.3685C80.7821 39.4684 80.4187 40.5513 79.6928 41.6171C78.9668 42.683 77.8665 43.6412 76.3918 44.4916C74.9186 45.3421 73.2589 45.9773 71.4125 46.3973C69.5662 46.8173 67.6904 47.0271 65.7852 47.0266C63.8799 47.0262 62.0041 46.8164 60.1578 46.3973C58.3114 45.9782 56.6517 45.343 55.1786 44.4916ZM64.1352 43.1309V37.9602L70.4992 34.2863L72.8562 35.647L67.4351 38.7767L67.4351 43.1309H64.1352Z"
          fill={currentColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_12904_27977">
          <rect
            width="32.6592"
            height="32.6592"
            fill="white"
            transform="matrix(0.866044 0.499967 -0.866044 0.499967 65.7852 22.04)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CreateFromExisting
