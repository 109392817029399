import { Button, Typography } from "@suraasa/placebo-ui"
import AISparkle from "assets/AITools/ai-sparkle.webp"
import BackButton from "components/BackButton"
import React, { useContext } from "react"

import { AIToolsContext } from "../Generator/context"

type Props = {
  slot?: React.ReactNode
  title?: string
  subtitle?: string
  backButtonLabel?: string
  isPro?: boolean
}

const Heading = ({
  slot,
  title = "",
  subtitle = "",
  backButtonLabel = "AI Tools",
  isPro,
}: Props) => {
  const { setOpenHistorySideBar } = useContext(AIToolsContext)
  return (
    <>
      <div className="mt-1 flex items-center  justify-between">
        <BackButton className="!-ml-2" nudge="left" label={backButtonLabel} />
        <Button
          variant="text"
          className="lg:!hidden"
          onClick={() => {
            setOpenHistorySideBar(true)
          }}
        >
          View History
        </Button>
      </div>
      <div className="mb-2 mt-1 flex flex-col items-start justify-between gap-2 md:flex-row">
        <div>
          <Typography
            variant="title2"
            className={isPro ? "flex items-center gap-2" : undefined}
          >
            {isPro ? <span>{title}</span> : title}
            {isPro && (
              <div className="flex shrink-0 items-center justify-between gap-0.5 rounded-md bg-secondary-900 px-1 py-0.5 text-white">
                <img src={AISparkle} alt="" width="20" />
                <Typography variant="title4">Pro</Typography>
              </div>
            )}
          </Typography>
          <Typography variant="largeBody" className="text-onSurface-700">
            {subtitle}
          </Typography>
        </div>
        {slot}
      </div>
    </>
  )
}
export default Heading
