import { MasterclassCertificate } from "api/resources/profile/types"
import NoCertificates from "assets/Fallback/no-certificates.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import ShareSheet from "components/ShareSheet"
import React, { useState } from "react"

import MasterclassCard from "../Cards/MasterclassCard"
import styles from "./Masterclasses.module.css"

type Props = { masterclassesList: MasterclassCertificate[] }

const Masterclasses = (props: Props) => {
  const [certificateUrl, setCertificateUrl] = useState<string>("")

  if (props.masterclassesList.length === 0) {
    return (
      <Fallback
        data={{
          image: NoCertificates,
          title: "Looks like you don’t have any certificates yet!",
          description: "Attend masterclasses to earn certificates.",
        }}
        className="fixed left-1/2 top-1/2 w-full translate-x-[-50%] translate-y-[-50%] px-2 text-center sm:w-fit sm:px-0"
        hasError={false}
      />
    )
  }
  return (
    <div
      className={clsx(
        "mb-4 grid gap-1 sm:gap-x-3 sm:gap-y-2 md:gap-x-2",
        styles.masterClassGridContainer
      )}
    >
      {props.masterclassesList.map((item, index) => (
        <MasterclassCard
          setCertificateUrl={url => setCertificateUrl(url)}
          key={index}
          data={item}
        />
      ))}
      <ShareSheet
        data={{
          url: certificateUrl,
          text: `Checkout my certificate on Suraasa: ${certificateUrl}`,
        }}
        open={!!certificateUrl}
        handleClose={() => {
          setCertificateUrl("")
        }}
      />
    </div>
  )
}

export default Masterclasses
