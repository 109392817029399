import AiLogo from "assets/AITools/TogglePro/ai-logo.webp"
import AiLogoWhite from "assets/AITools/TogglePro/ai-logo-white.webp"
import clsx from "clsx"
import { useSuraasaProContext } from "features/AItools/PRO/context/SuraasaProContext"

const TogglePro = ({
  className,
  isPro,
  onChange,
}: {
  className?: string
  isPro: boolean
  onChange: (state: boolean) => void
}) => {
  const { isActive, showGetProSheet } = useSuraasaProContext()

  const handleProChange = (activate: boolean) => {
    if (activate) {
      if (isActive) {
        onChange(true)
      } else {
        showGetProSheet({
          claimCallback: async () => {
            onChange(true)
          },
        })
      }
    }

    if (!activate) {
      onChange(false)
    }
  }

  return (
    <div
      className={clsx(
        "mx-auto flex max-w-[380px] items-center overflow-hidden rounded-full border border-solid border-onSurface-200 bg-white p-0.25 shadow-[1px_1px_5px_0px_#0000001A]",
        className
      )}
    >
      <button
        onClick={() => {
          handleProChange(false)
        }}
        className={clsx(
          "w-full rounded-full py-[6.5px] text-sm font-semibold leading-[16.94px] tracking-[-0.02em] transition-colors",
          {
            "shadow-[0px_4px_4px_0px_#00000040] bg-black text-white": !isPro,
          }
        )}
      >
        Basic Mode
      </button>
      <button
        onClick={() => {
          handleProChange(true)
        }}
        className={clsx(
          "flex w-full items-center justify-center gap-0.25 rounded-full py-[6.5px] text-sm font-semibold leading-[16.94px] tracking-[-0.02em]",
          {
            "shadow-[0px_4px_4px_0px_#00000040] text-white bg-[radial-gradient(circle_at_0%_10%,rgb(253,144,6)_0px,transparent_100%),radial-gradient(circle_at_20%_100%,rgb(99,80,233)_20px,transparent_100%),radial-gradient(circle_at_20%_40%,rgb(149,85,176)_0px,transparent_55%),radial-gradient(circle_at_70%_100%,rgb(12,206,164)_0px,transparent_100%),radial-gradient(circle_at_80%_100%,rgb(12,206,164)_0px,transparent_100%)]":
              isPro,
          }
        )}
      >
        Pro Mode{" "}
        <img
          src={isPro ? AiLogoWhite : AiLogo}
          alt={isPro ? "ai-logo-white" : "ai-logo"}
          className="h-2 w-2.25 shrink-0"
        />
      </button>
    </div>
  )
}

export default TogglePro
// radial-gradient(circle at 90% 100%, rgba(125, 184, 22, 1) 0, transparent 55%)
