import {
  Award,
  Certification,
  Interest,
  LanguageResponse,
  Profile,
  Project,
  Publication,
  Qualification,
  Skill,
  SkillEvidenceOverview,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "api/resources/profile/types"
import React from "react"
import { UseArray } from "utils/hooks/useArray"

import { DEFAULT_PROFILE_COVER } from "./utils"

type ProfileItem<T> = {
  data: UseArray<T>["array"]
  add: UseArray<T>["unshift"]
  remove: UseArray<T>["removeByKey"]
  update: UseArray<T>["updateByKey"]
  set?: UseArray<T>["set"]
}

type Context = {
  isPublic: boolean
  profile: Profile
  updateProfile: React.Dispatch<React.SetStateAction<Partial<Profile>>>
  languages: ProfileItem<LanguageResponse>
  workExperiences: ProfileItem<WorkExperienceType>
  subjects: ProfileItem<SubjectResponse>
  achievements: {
    awards: ProfileItem<Award>
    projects: ProfileItem<Project>
    publications: ProfileItem<Publication>
    testScores: ProfileItem<TestScore>
  }
  academics: {
    certifications: ProfileItem<Certification>
    qualifications: ProfileItem<Qualification>
  }
  workLocationInterest: ProfileItem<WorkLocationInterest>
  interests: ProfileItem<Interest>
  skillEvidences: SkillEvidenceOverview
  skills: ProfileItem<Skill>
}

const defaultProfileItem = {
  data: [],
  add: () => {},
  remove: () => {},
  update: () => {},
}

const ProfileContext = React.createContext<Context>({
  isPublic: true,
  updateProfile: () => {},
  profile: {
    id: "",
    totalWorkingExperience: 0,
    coverStyle: DEFAULT_PROFILE_COVER,
    careerAspiration: null,
    countriesWorkedIn: [],
    country: null,
    currentWorkingExperience: null,
    dateOfBirth: null,
    gender: null,
    isVerified: false,
    lookingForJobs: false,
    nationality: null,
    phoneNumber: null,
    picture: null,
    state: null,
    user: {
      uuid: "",
      email: "",
      firstName: "",
      lastName: null,
      middleName: "",
      username: "",
    },
  },

  languages: defaultProfileItem,
  workExperiences: defaultProfileItem,
  subjects: defaultProfileItem,
  workLocationInterest: defaultProfileItem,
  achievements: {
    awards: defaultProfileItem,
    projects: defaultProfileItem,
    publications: defaultProfileItem,
    testScores: defaultProfileItem,
  },
  academics: {
    certifications: defaultProfileItem,
    qualifications: defaultProfileItem,
  },
  interests: defaultProfileItem,
  skillEvidences: {
    advancedSkillCount: 0,
    beginnerSkillCount: 0,
    intermediateSkillCount: 0,
    professional: 0,
  },
  skills: defaultProfileItem,
})

export default ProfileContext
