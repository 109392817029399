import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Divider,
  IconButton,
  TextField,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { SETTINGS } from "api/resources/settings/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import SlidingSheet from "components/SlidingSheet"
import { Edit, Search, Xmark } from "iconoir-react"
import { ChangeEvent, useState } from "react"
import { useTheme } from "react-jss"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import timezones from "utils/data/timezones.json"
import { handleErrors } from "utils/helpers"
import { useResource } from "utils/hooks/useResource"
import { ValueOf } from "utils/types"

import { useSettings } from ".."
import ContactDetails from "../ContactDetails"
import MobileHeadBar from "../MobileHeadbar"
import SocialAccounts from "../SocialAccounts"
import ChangePasswordDialog from "./ChangePasswordDialog"

const ActionButton = ({
  action,
  btnLabel,
}: {
  action: () => void
  btnLabel: string
}) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  return isXs ? (
    <IconButton onClick={action}>
      <Edit />
    </IconButton>
  ) : (
    <Button variant="text" onClick={action}>
      {btnLabel}
    </Button>
  )
}

const AccountDetails = () => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const { userPreferences, updateUserPreference } = useSettings()
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [openChangeLanguage, setOpenChangeLanguage] = useState(false)
  const [openChangeTimezone, setOpenChangeTimezone] = useState(false)
  const [searchText, setSearchText] = useState("")

  const { languages } = useResource(["languages"])

  const languagePreference = userPreferences?.find(
    e => e.setting.slug === SETTINGS.preferredLanguage
  )

  const languageChoices = languages.filter(
    language => language.localeCode === "ar" || language.localeCode === "en"
  )

  const timezoneSelected = userPreferences.find(
    e => e.setting.slug === SETTINGS.timezone
  )

  const searchTimezone = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const timezoneList = timezones.filter(timezone =>
    timezone.toLowerCase().includes(searchText.toLowerCase())
  )

  const updateSettings = async (
    slug: ValueOf<typeof SETTINGS>,
    value: string
  ) => {
    if (slug === SETTINGS.preferredLanguage) {
      if (value === languagePreference?.value) return
    }
    if (slug === SETTINGS.timezone) {
      if (value === timezoneSelected?.value) return
    }

    try {
      await api.settings.updateUserPreferences({
        data: {
          settings: [{ slug, value }],
        },
      })
      updateUserPreference(slug, value)
    } catch (err) {
      if (err instanceof APIError) handleErrors(err)
    }
  }

  return (
    <div>
      <MobileHeadBar title="Account Details" />

      <ContactDetails />

      {/* Social Accounts */}
      <SocialAccounts />

      <Divider weight="light" className="mb-3 mt-4" />

      {/* Account Password Section */}
      <Typography variant="strong">Account Password</Typography>
      <div className="mt-2 flex items-center justify-between">
        <Typography variant="strong">**********</Typography>
        <ActionButton
          action={() => setOpenChangePassword(true)}
          btnLabel="Change Password"
        />
      </div>

      <Divider weight="light" className="mb-3 mt-4" />

      {/* Platform Language Section */}
      <Typography variant="strong">Platform Language</Typography>
      <div className="mt-2 flex items-center justify-between">
        <Typography variant="smallBody">
          Currently set to{" "}
          <b>
            {languagePreference?.value
              ? languageChoices.find(
                  item => item.uuid === languagePreference?.value
                )?.name
              : "-"}
          </b>
        </Typography>
        <ActionButton
          action={() => setOpenChangeLanguage(true)}
          btnLabel="Change Platform Language"
        />
      </div>

      <Divider weight="light" className="mb-3 mt-4" />

      {/* Timezone Section */}
      <Typography variant="strong">Timezone</Typography>
      <div className="mb-10 mt-2 flex items-center justify-between">
        <Typography variant="smallBody">
          Currently set to <b>{timezoneSelected?.value}</b>
        </Typography>
        <ActionButton
          action={() => setOpenChangeTimezone(true)}
          btnLabel="Change Timezone"
        />
      </div>

      {/* Change Password Dialog */}
      <ChangePasswordDialog
        close={() => setOpenChangePassword(false)}
        open={openChangePassword}
      />

      {/* Sliding Sheet Language Selection */}
      <SlidingSheet
        open={openChangeLanguage}
        from={isXs ? "bottom" : "end"}
        onClose={() => setOpenChangeLanguage(false)}
        isDismissible={true}
      >
        <div className={isXs ? "w-full" : "w-[350px]"}>
          <div className="p-2">
            <Button
              nudge="left"
              onClick={() => setOpenChangeLanguage(false)}
              variant="text"
              startAdornment={<Xmark />}
              color="black"
            >
              Close
            </Button>
            <Typography variant="title3" className="mt-1.5">
              Select Platform Language
            </Typography>
          </div>
          <Divider weight="light" color="onSurface.200" />
          <div className="flex flex-col p-2">
            {languageChoices.map(language => (
              <button
                key={language.uuid}
                className={clsx("mb-1 rounded-2xl p-2 text-start", {
                  ["bg-primary-50"]:
                    languagePreference?.value === language.uuid,
                })}
                onClick={async () => {
                  if (language.uuid === languagePreference?.value) return
                  await updateSettings(
                    SETTINGS.preferredLanguage,
                    language.uuid
                  )
                  localStorage.setItem(
                    BROWSER_STORAGE_KEYS.language,
                    language.localeCode
                  )
                  window.location.reload()
                  setOpenChangeLanguage(false)
                }}
              >
                <div className="self-start">
                  <Typography variant="strong">
                    {language.nativeName}
                  </Typography>
                  <Typography
                    variant="smallBody"
                    className="text-onSurface-500"
                  >
                    {language.name}
                  </Typography>
                </div>
              </button>
            ))}
          </div>
        </div>
      </SlidingSheet>

      {/* Timezone Selection Sheet */}
      <SlidingSheet
        open={openChangeTimezone}
        from={isXs ? "bottom" : "end"}
        onClose={() => setOpenChangeTimezone(false)}
        isDismissible={true}
        className="overflow-hidden"
      >
        <div className={isXs ? "w-full" : "w-[350px]"}>
          <div className="top-0 z-10 border-b-2 border-onSurface-200 bg-white p-2 sm:sticky">
            <Button
              nudge="left"
              onClick={() => setOpenChangeTimezone(false)}
              variant="text"
              startAdornment={<Xmark />}
              color="black"
            >
              Close
            </Button>
            <Typography variant="title3" className="mt-1.5">
              Select Timezone
            </Typography>
            <TextField
              onChange={searchTimezone}
              inputMode="search"
              startAdornment={<Search />}
              className="mt-1"
            />
          </div>
          {/* <Divider weight="light" color="onSurface.200" /> */}
          <div className="flex h-[420px] flex-col overflow-auto p-2 sm:h-full">
            {timezoneList.length === 0 ? (
              <Typography variant="strong">No timezones available</Typography>
            ) : (
              <>
                {timezoneList.map((timezone, id) => (
                  <button
                    key={id}
                    className={clsx("rounded-lg p-1.5 text-start", {
                      ["bg-primary-50"]: timezoneSelected?.value === timezone,
                    })}
                    onClick={() => {
                      updateSettings(SETTINGS.timezone, timezone)
                      setOpenChangeTimezone(false)
                    }}
                  >
                    <div className="self-start">
                      <Typography variant="body">{timezone}</Typography>
                    </div>
                  </button>
                ))}
              </>
            )}
          </div>
        </div>
      </SlidingSheet>
    </div>
  )
}

export default AccountDetails
