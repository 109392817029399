import { Typography } from "@suraasa/placebo-ui"
import React from "react"
import useCurrentCountry from "utils/hooks/useCurrentCountry"

const getTIACountryPrizes = (isoCode: string | undefined) => {
  switch (isoCode) {
    case "IN":
      return {
        currency: "INR",
        winner: "3,00,000",
        firstRunnerUp: "2,00,000",
        secondRunnerUp: "1,00,000",
        top10: "50,000",
      }
    case "AE":
      return {
        currency: "AED",
        winner: "15,000",
        firstRunnerUp: "10,000",
        secondRunnerUp: "5,000",
        top10: "2500",
      }
    default:
      return {
        currency: "USD",
        winner: "4,090",
        firstRunnerUp: "2,725",
        secondRunnerUp: "1,360",
        top10: "680",
      }
  }
}

const CashPrize = () => {
  const country = useCurrentCountry()

  const prize = getTIACountryPrizes(country?.isoCode)
  return (
    <div>
      <Typography className="text-common-black-700">
        Your story - told through a video, will go through an evaluation process
        by a jury of education leaders to identify top impact creators who would
        be eligible to win cash prizes as follows:
      </Typography>
      <div className="mb-2 mt-3 flex flex-col rounded-lg border-[1.5px] border-solid border-[#FBEBA5] md:flex-row">
        <div className="flex flex-col-reverse border-b-[1.5px] border-solid border-b-[#FBEBA5] sm:border-b-0 md:flex-row">
          {/* First Runner Up */}
          <div className="flex flex-col items-center justify-center p-3 sm:px-4 sm:py-8 md:col-span-1">
            <Typography
              className="!text-2xl"
              style={{ fontFamily: "Space Grotesk Bold" }}
            >
              {prize.currency} {prize.firstRunnerUp}
            </Typography>
            <Typography
              className="pt-1 text-center !text-base"
              variant="strongSmallBody"
            >
              First runner-up will receive a cash prize of {prize.currency}{" "}
              {prize.firstRunnerUp}.
            </Typography>
          </div>

          {/* Winner */}
          <div
            className="flex flex-col items-center justify-center p-2 sm:px-7 sm:py-6 md:col-span-1"
            style={{ backgroundColor: "#FBEBA5" }}
          >
            <Typography
              className="!text-2xl"
              style={{ fontFamily: "Space Grotesk Bold" }}
            >
              {prize.currency} {prize.winner}
            </Typography>
            <Typography
              className="pt-1 text-center !text-base"
              variant="strongSmallBody"
            >
              Winner of Teacher Impact Awards 2024 will receive a cash prize of{" "}
              {prize.currency} {prize.winner}.
            </Typography>
          </div>
        </div>

        {/* Second Runner Up*/}
        <div className="flex flex-col items-center justify-center p-3 sm:px-4 sm:py-8 md:col-span-1">
          <Typography
            className="!text-2xl"
            style={{ fontFamily: "Space Grotesk Bold" }}
          >
            {prize.currency} {prize.secondRunnerUp}
          </Typography>
          <Typography
            className="pt-1 text-center !text-base"
            variant="strongSmallBody"
          >
            Second runner-up will receive a cash prize of {prize.currency}{" "}
            {prize.secondRunnerUp}.
          </Typography>
        </div>
      </div>
      <Typography variant="largeBody" className="text-center">
        Top 10 impact creators will also win crash prizes of {prize.currency}{" "}
        {prize.top10} each.
      </Typography>
    </div>
  )
}

export default CashPrize
