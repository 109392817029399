import { context } from "global/Context/context"
import { useContext } from "react"
import { learningItemSlugs } from "utils/constants"

export const useEnrollments = () => {
  const { enrollments } = useContext(context)

  const isPGCTLUser = enrollments
    ? enrollments.length > 0
      ? enrollments.some(item => item.slug === learningItemSlugs.pgctl)
      : false
    : undefined

  return { enrollments, isPGCTLUser }
}
