import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { ArrowRight } from "iconoir-react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(() => ({
  container: {
    filter: "drop-shadow(0px 8px 25px rgba(0, 0, 0, 0.15))",
    background:
      'url("/assets/ito/2023/AfterResultPhase/YourRewards/dot-pattern.svg")',
  },
}))

const LINK_TO_CHECK_ELIGIBILITY = ""

const ITUCard = () => {
  const classes = useStyles()
  return (
    <div className="overflow-hidden rounded-2xl bg-[#720123] !text-white">
      <div className={clsx(classes.container, " relative p-4")}>
        <Typography className="mb-0.5" variant="preTitle">
          Scholarship
        </Typography>
        <Typography variant="title2">
          Master&apos;s in Education from ITU
        </Typography>

        <Typography className="mt-3 max-w-[523px]">
          You have received a scholarship of $1200 USD for your admission into
          the Master&apos;s in Education (M.Ed) program with International
          Teachers&apos; University.
        </Typography>
        {LINK_TO_CHECK_ELIGIBILITY ? (
          <Button
            endAdornment={<ArrowRight />}
            rounded
            className="mt-3"
            color="white"
            style={{ color: "#9E002F" }}
          >
            Check Program Eligibility
          </Button>
        ) : (
          <Typography className="mt-3 text-onSurface-200" variant="smallBody">
            You will receive an official letter from the university soon with
            the next steps.
          </Typography>
        )}
        <img
          className="absolute -bottom-4 -right-4 h-4/5 opacity-20 md:right-3 md:top-1/2 md:-translate-y-1/2 md:opacity-100"
          src="/assets/ito/2023/AfterResultPhase/YourRewards/ITU.webp"
          alt="ITU"
        />
      </div>
    </div>
  )
}

export default ITUCard
