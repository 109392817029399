import { Typography } from "@suraasa/placebo-ui"
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@suraasa/placebo-ui"
import { Button, Checkbox } from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import axios from "axios"
import ProfileContext from "features/Profile/context"
import { context } from "global/Context/context"
import { useContext, useState } from "react"
import { toast } from "react-toastify"

import DragNDropPDF from "./DragNDropPDF"

const CancelToken = axios.CancelToken
let cancelAxiosRequest: () => void

const AddResume = ({
  open,
  onClose,
}: {
  open?: boolean
  onClose: () => void
}) => {
  // const { updateResumeFillerStage } = useContext(context)
  const { updateProfile } = useContext(ProfileContext)
  const [resume, setResume] = useState<File | null>(null)
  const [shouldGenerateData, setShouldGenerateData] = useState<boolean>(false)

  const [uploadPercentage, setUploadPercentage] = useState<number>()

  const handleFileUpload = (files: File) => {
    setResume(files)
  }
  const resumeFiller = useMutation({
    mutationFn: () => api.aiTools.resumeFiller.create(),
    onSuccess: () => {
      // updateResumeFillerStage("fetch-filler")
      onClose()
    },
  })

  const { mutate, isLoading } = useMutation({
    mutationFn: async (file: File) => {
      const uploadFile = new FormData()
      uploadFile.append("resume", file)

      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent: ProgressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          if (percent <= 100 && percent > 0) {
            setUploadPercentage(percent)
          }
        },
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelAxiosRequest = c
        }),
      }

      return api.profile.update({
        data: uploadFile,
        ...options,
      })
    },
    onSuccess: res => {
      if (res.isSuccessful) {
        updateProfile({ resume: res.data.resume })
        clearInput()
        if (shouldGenerateData) {
          resumeFiller.mutate()
        } else {
          onClose()
        }
      } else {
        cancelAxiosRequest()
        setUploadPercentage(undefined)
        toast.error("Something went wrong while uploading your resume")
      }
    },
  })

  const clearInput = () => {
    const inputEl = document.getElementById(
      "resume-pdf-input"
    ) as HTMLInputElement
    if (inputEl) {
      inputEl.value = ""
    }

    setUploadPercentage(undefined)
    setResume(null)
  }

  const toggleShouldGenerateData = () => {
    setShouldGenerateData(!shouldGenerateData)
  }
  return (
    <Dialog open={open}>
      <DialogContent className="max-w-[408px]">
        <DialogHeader hideClose className="!border-0 pb-0">
          <DialogTitle>
            <Typography variant="title3">Upload Resume</Typography>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="px-2 pb-5">
          <div className="flex flex-col">
            <DragNDropPDF
              inputId="resume-pdf-input"
              allowedExtensions={[".pdf"]}
              instructions={["Only .pdf files. 10MB max file size."]}
              maxSize={10}
              uploadProgress={uploadPercentage}
              onChange={files => {
                if (files?.length) {
                  handleFileUpload(files[0] as File)
                }
                if (!files) {
                  clearInput()
                }
              }}
            />
            {/* <div className="mt-1.75">
              <Checkbox
                onClick={toggleShouldGenerateData}
                label="Use the information from my resume to update my profile"
              />
            </div> */}
          </div>
        </DialogBody>
        <DialogFooter className="!border-0">
          <Button
            size="sm"
            color="black"
            onClick={() => {
              clearInput()
              if (uploadPercentage !== undefined) {
                cancelAxiosRequest()
                setUploadPercentage(undefined)
              } else {
                onClose()
              }
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (resume) {
                mutate(resume)
              }
            }}
            loading={isLoading || resumeFiller.isLoading}
            disabled={!resume}
            size="sm"
          >
            Upload Resume
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddResume
