/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography } from "@suraasa/placebo-ui"
import ChatIcon from "assets/icons/ChatIcon.svg"

import { AlertState } from "."

type Props = {
  setOpen: (x: boolean) => void
  alert: AlertState
}

const Launcher = ({ alert, setOpen }: Props) => {
  const onChatIconClick = () => {
    setOpen(true)
  }

  return (
    <div className="flex size-8 cursor-pointer items-center justify-center rounded-l-xl bg-secondary-900">
      <div className="relative h-full">
        {alert.show && (
          <>
            {/* {alert.message && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className="absolute -top-2 right-3.5 z-40 flex h-auto max-h-[55px] w-screen max-w-[225px] items-start justify-center rounded-full bg-primary-500 px-2 py-1"
                onClick={onMessageClick}
              >
                <Typography
                  style={
                    {
                      // fontWeight: 400,
                      // fontSize: 12,
                      // lineHeight: "15.6px",
                    }
                  }
                  color="common.white.200"
                  variant="body"
                >
                  {alert.message}
                </Typography>
              </div>
            )} */}

            {alert.text && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                onClick={onChatIconClick}
                className="absolute -top-1.25 right-0.25 z-40 flex size-3 items-center justify-center  rounded-full bg-critical-500 p-1"
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 12,
                    // lineHeight: "15.6px",
                  }}
                  color="common.white.200"
                >
                  {alert.text}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
      <img
        src={ChatIcon}
        alt="chatbot"
        className="h-[26px] w-[29px]"
        onClick={onChatIconClick}
      />
    </div>
  )
}

export default Launcher
