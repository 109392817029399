import SuraasaLogo from "assets/SuraasaLogo.svg"
import clsx from "clsx"
import React from "react"

import styles from "./Navbar.module.css"

export type NavbarProps = {
  className?: string
  gutterBottom?: boolean
  slotEnd?: React.ReactNode
  navBarTitle?: string
}

const Navbar = ({ gutterBottom = true, className, slotEnd }: NavbarProps) => {
  return (
    <div
      className={clsx("w-full", {
        ["mb-4 md:mb-8"]: gutterBottom,
      })}
    >
      <nav
        className={clsx(
          "relative h-[62px] border-b border-b-onSurface-200 bg-onSurface-50",
          className,
          styles.root,
          "flex items-center py-1"
        )}
      >
        <div
          className={clsx(
            "flex w-full flex-wrap items-center justify-between p-2 sm:px-5 md:flex-nowrap",
            styles.container
          )}
        >
          <div className="flex grow items-center justify-start md:block md:grow-0">
            <img alt="" src={SuraasaLogo} />
          </div>
          {slotEnd}
        </div>
      </nav>
    </div>
  )
}

export default Navbar
