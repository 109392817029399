import "@suraasa/placebo-ui-legacy"

import { toast } from "@suraasa/placebo-ui"
import api from "api"
// import { GA } from "shared/utils/googleAnalytics"
import { Status } from "features/ITO/Assessments/Proctoring/hooks/useCamDetection"
import { useProctoring } from "features/ITO/Assessments/Proctoring/hooks/useProctoring"
import React, { useEffect, useState } from "react"
import { isDesktop } from "react-device-detect"
import { useNavigate } from "react-router"
import Webcam from "react-webcam"
import { browserIsSupportedForITO } from "utils/constants"

import { triggerFullscreen } from "../helpers"
import ProctoringAlerts from "./ProctoringAlerts"

type Options = {
  totalWarnings: number
  testIsAccessible: boolean
  videoRef: React.RefObject<Webcam>
  proctoringAlerts: JSX.Element
  webCamStatus: Status | null
  warnings: WarningType
}
type Props = {
  setWebCamStatus: (status: Status | null) => void
  onTotalWarningsChange: (warningsIncurred: number) => void
  attemptId: string
  disabled?: boolean
  assessmentId: number
  maxWarningCount: number | null
  isITO?: boolean
  render: ({
    proctoringAlerts,
    totalWarnings,
    testIsAccessible,
    videoRef,
    warnings,
  }: Options) => JSX.Element
}

export type WarningType = {
  cameraCloseCount: number
  tabSwitchCount: number
  noFaceDetectedCount: number
  unfairMeansDetectedCount: number
}

const Proctoring = ({
  attemptId,
  assessmentId,
  setWebCamStatus,
  onTotalWarningsChange,
  render,
  maxWarningCount,
  disabled = false,
  isITO = false,
}: Props) => {
  if (import.meta.env.VITE_ITO_TEST_MODE === "true") {
    disabled = true
  }

  const [warningsFetched, setWarningsFetched] = useState(false)
  const [latestWarning, setLatestWarning] = useState<keyof WarningType>()
  const [warnings, setWarnings] = useState<WarningType>({
    cameraCloseCount: 0,
    tabSwitchCount: 0,
    noFaceDetectedCount: 0,
    unfairMeansDetectedCount: 0,
  })

  const {
    webcam: { webCamStatus, videoRef, violationType, clearViolations },
    devTools: { devToolsOpen },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fullScreen: { fullScreenStatus },
    tabUnfocus: { tabUnfocusStatus },
  } = useProctoring({ disabled })

  useEffect(() => {
    setWebCamStatus(webCamStatus)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webCamStatus])

  // useEffect(() => {
  //   if (fullScreenStatus) {
  //     document.body.style.overflowY = "auto"
  //   }
  // }, [fullScreenStatus])

  // const tour = useContext(ShepherdTourContext)

  const navigate = useNavigate()

  const totalWarnings =
    Object.values(warnings).reduce((number, total) => total + number, 0) || 0

  useEffect(() => {
    onTotalWarningsChange(totalWarnings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalWarnings])

  /**
   * Retrieve Warning Counts
   */
  useEffect(() => {
    const getWarnings = async () => {
      try {
        const res = await api.ito.assessments.getWarningCount({
          urlParams: {
            attemptId,
          },
        })

        if ("autoSubmitted" in res) {
          toast.error("Assessment already submitted.")
          navigate("/dashboard/ito/")
          return
        }
        if ("warningData" in res)
          setWarnings({
            unfairMeansDetectedCount:
              res.warningData?.unfairMeansDetectedCount || 0,
            noFaceDetectedCount: res.warningData?.noFaceDetectedCount || 0,
            tabSwitchCount: res.warningData?.tabSwitchCount || 0,
            cameraCloseCount: res.warningData?.cameraCloseCount || 0,
          })
      } catch (err) {
        // console.error(err)
      }

      setWarningsFetched(true)
    }
    getWarnings()
  }, [])

  /**
   * Send warning count to backend whenever warning increases
   */
  useEffect(() => {
    const updateWarning = async () => {
      try {
        const res = await api.ito.assessments.updateWarningCount({
          urlParams: { attemptId },
          data: {
            trigger: latestWarning,
            totalWarningCount: totalWarnings,
            warningData: warnings,
          },
        })
        if (res && "attempt" in res) {
          toast.error("Assessment already submitted.")
          navigate("/dashboard/ito/")
          return
        }
      } catch (err) {
        // if (err instanceof APIError) {
        //   handleErrors(err)
        // }
      }
    }

    /**
     * We don't call this API until the GET API resolves. Else we'd be sending 0,0,0 to backend which isn't ideal.
     */
    if (warningsFetched && !disabled) {
      updateWarning()
    }
  }, [warnings, disabled])

  //   const trackOnGA = (type: "unfocus" | "devtools" | "camera") => {
  //     GA.trackEvent("ito_assessment_violation_triggered", {
  //       type,
  //     })
  //   }

  const [showSettingsOverlay, toggleSettingsOverlay] = useState(false)
  const [winSwitchDialog, toggleWinSwitchDialog] = useState(false)
  const [showDevToolsDialog, toggleDevToolsDialog] = useState(false)
  const [showFullscreenOverlay, toggleFullscreenOverlay] = useState(false)
  const [showNoFaceDialog, toggleNoFaceDialog] = useState(false)
  const [showUnfairMeansDialog, toggleUnfairMeansDialog] = useState(false)

  const testIsAccessible =
    disabled ||
    (browserIsSupportedForITO === "supported" &&
      !tabUnfocusStatus &&
      webCamStatus === "on" &&
      !showSettingsOverlay &&
      !winSwitchDialog &&
      !showUnfairMeansDialog &&
      !showNoFaceDialog &&
      !showFullscreenOverlay)

  // useEffect(() => {
  //   if (!tour) return

  //   const hasCompletedTour = localStorage.getItem(
  //     BROWSER_STORAGE_KEYS.suraasaMockOlympiadTourCompleted
  //   )

  //   if (testIsAccessible && hasCompletedTour === null) {
  //     if (tour.isActive()) {
  //       tour.show()
  //     } else {
  //       tour.start()
  //     }
  //   } else {
  //     tour.hide()
  //   }
  // }, [testIsAccessible])

  /**
   * Camera Access:
   * When user denies camera permissions or turns off camera,
   * increase camera warning by 1 and make settings overlay visible
   */
  useEffect(() => {
    if (webCamStatus === "blocked") {
      toggleSettingsOverlay(true)
      //   trackOnGA("camera")
      console.log("> Warning Count Triggered: ")
      setWarnings(prev => ({
        ...prev,
        cameraCloseCount: prev.cameraCloseCount + 1,
      }))
      setLatestWarning("cameraCloseCount")
    } else toggleSettingsOverlay(false)
  }, [webCamStatus])

  useEffect(() => {
    if (webCamStatus !== null && tabUnfocusStatus) {
      //   trackOnGA("unfocus")

      console.log(`> Warning Count Triggered: Tab Unfocus`)
      setWarnings(prev => ({
        ...prev,
        tabSwitchCount: prev.tabSwitchCount + 1,
      }))
      setLatestWarning("tabSwitchCount")
      toggleWinSwitchDialog(true)
    }
  }, [webCamStatus, tabUnfocusStatus])

  useEffect(() => {
    const captureImageOnBackend = () => {
      try {
        if (import.meta.env.VITE_ITO_VIOLATION_STORAGE_API_BASE_URL == null) {
          return
        }
        if (!videoRef.current) {
          return
        }

        const base64Image = videoRef.current.getScreenshot()

        if (base64Image)
          fetch(base64Image)
            .then(res => res.blob())
            .then(blob => {
              const data = new FormData()
              data.append("assessment_id", assessmentId.toString())
              data.append("attempt_id", attemptId)
              if (violationType) data.append("violation_type", violationType)
              data.append(
                "violation_image",
                new File([blob as Blob], "image.jpg")
              )
              api.ito.assessments.uploadViolation({
                data,
              })
            })
      } catch (e) {
        console.error(e)
      }
    }

    if (violationType === "noFace") {
      console.log(`> Warning Count Triggered: ${violationType}`)
      setWarnings(prev => ({
        ...prev,
        noFaceDetectedCount: prev.noFaceDetectedCount + 1,
      }))
      captureImageOnBackend()
      toggleNoFaceDialog(true)
      toggleUnfairMeansDialog(false)
      setLatestWarning("noFaceDetectedCount")
    }
    if (violationType === "unfairMeans") {
      console.log(`> Warning Count Triggered: ${violationType}`)
      setWarnings(prev => ({
        ...prev,
        unfairMeansDetectedCount: prev.unfairMeansDetectedCount + 1,
      }))
      captureImageOnBackend()
      toggleUnfairMeansDialog(true)
      toggleNoFaceDialog(false)
      setLatestWarning("unfairMeansDetectedCount")
    }

    if (violationType === null) {
      toggleNoFaceDialog(false)
      toggleUnfairMeansDialog(false)
      setLatestWarning(undefined)
    }
  }, [violationType, videoRef])

  const proctoringAlerts = (
    <ProctoringAlerts
      isOlympiad={isITO}
      maxWarningCount={maxWarningCount}
      disabled={disabled}
      showDevToolsOverlay={Boolean(devToolsOpen)}
      showNoFaceDialog={showNoFaceDialog}
      showUnfairMeansDialog={showUnfairMeansDialog}
      showFullscreenOverlay={showFullscreenOverlay}
      showDevToolsDialog={showDevToolsDialog}
      showSettingsOverlay={showSettingsOverlay}
      showTabUnfocusDialog={winSwitchDialog}
      onClose={(
        type:
          | "fullscreen"
          | "tabUnfocus"
          | "devtools"
          | "noFace"
          | "unfairMeans"
      ) => {
        if (type === "fullscreen") {
          if (isDesktop) triggerFullscreen()
          toggleFullscreenOverlay(false)
        }
        if (type === "tabUnfocus") {
          if (isDesktop) triggerFullscreen()
          toggleWinSwitchDialog(false)
        }
        if (type === "devtools") {
          toggleDevToolsDialog(false)
        }
        if (type === "noFace") {
          clearViolations()
          toggleNoFaceDialog(false)
        }
        if (type === "unfairMeans") {
          clearViolations()
          toggleUnfairMeansDialog(false)
        }
      }}
      totalWarnings={totalWarnings}
    />
  )

  return (
    <>
      {render({
        proctoringAlerts,
        totalWarnings,
        testIsAccessible,
        videoRef,
        webCamStatus,
        warnings,
      })}
    </>
  )
}

export default Proctoring
