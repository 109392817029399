import { Country, State } from "../global/types"
import {
  Award,
  Certification,
  Gender,
  Interest,
  LanguageResponse,
  Project,
  Publication,
  Qualification,
  Skill,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "../profile/types"

export enum ToolType {
  lessonPlan = "lesson_plan",
  lessonPlanPro = "lesson_plan_pro",
  rubrics = "rubrics",
  rubricsPro = "rubrics_pro",
  quiz = "quiz",
  quizPro = "quiz_pro",
  handout = "handout",
  narration = "narration",
  subjectiveAssessment = "subjective_assessment",
  subjectiveAssessmentPro = "subjective_assessment_pro",
  assignment = "assignment",
  assignmentPro = "assignment_pro",
  activityPlanner = "activity_planner",
  videoSummary = "video_summary",
  unitPlanner = "unit_plan",
  coverLetter = "cover_letter",
}

export type LessonPlanForm = {
  bloomTaxonomyLevel: string[]
  country: string
  curriculum: string
  subject: string
  grade: string
  topic: string
  duration: string
}

export type LessonPlanProForm = {
  bloomTaxonomyLevel: string[]
  country: string
  curriculum: string
  subject: string
  numberOfClasses?: string
  grade: string
  book?: string
  topic?: string
  duration: string
}

export type LessonPlanResponse = {
  id: number
  isPro: false
  isPositiveResponse: boolean | null
  promptDataId: number
  title: string
  output: string
}

export type LessonPlanProResponse = {
  id: number
  title: string
  isPro: true
  subject: string
  country: string
  curriculum: string
  grade: string
  duration: string
  bloomTaxonomyLevel: string[]
  numberOfClasses?: number | null
  book?: string | null
  topic?: string | null
  output: LessonPlanResponse["output"][]
}

// export type LessonPlanResponseGenerate = {
//   id: number
//   title: string
//   subject: string
//   country: string
//   curriculum: string
//   grade: string
//   topic: string
//   duration: string
//   bloom_taxonomy_level: BloomTaxonomyLevels[]
// }

export type RubricsForm = {
  objective: string
  grade: string
  assignmentDescription: string
  pointOfScale: number
  curriculum: string
  additionalCustomisation?: string | null
}

export type RubricsProForm = {
  objective: string
  assignmentDescription: string
  grade: string
  pointOfScale?: number | null
  curriculum: string
  rubricType: RubricType
  criteriaLevel?: number | null
}

export type RubricsResponse = {
  id: number
  title: string
  objective: string
  grade: string
  assignmentDescription: string
  pointOfScale: number
  curriculum: string
  additionalCustomisation?: string | null
  isPositiveResponse?: boolean | null
  output: string
}

export type DifficultyLevel = "easy" | "medium" | "difficult" | "expert"

export enum RubricType {
  holistic = "holistic",
  analytic = "analytic",
  singlePoint = "single_point",
}

export type RubricsProResponse = {
  id: number
  objective: string
  grade: string
  pointOfScale: number
  curriculum: string
  rubricType: RubricType
  title: string
  output: string
}

export type QuizForm = {
  numberOfQuestions: number
  curriculum: string
  grade: string
  description: string
  objective?: string
}

export type QuizProForm = {
  grade: string
  curriculum: string
  numberOfIndividualQuestions: number
  difficultyLevel: DifficultyLevel
  topic: string
  country: string
  subject: string
  numberOfComprehensions?: number
  numberOfComprehensionQuestions?: number
}

export type QuizResponse = {
  id: number
  title: string
  numberOfQuestions: number
  curriculum: string
  grade: string
  description: string
  isPositiveResponse?: boolean | null
  objective?: string
  output: string
}

export type QuizProResponse = {
  id: number
  numberOfQuestions: number
  curriculum: string
  grade: string
  topic: string
  book: string
  difficultyLevel: DifficultyLevel
  country: string
  subject: string
  numberOfComprehensions: number
  numberOfComprehensionQuestions: number
  title: string
  isPro: boolean
  output: string
}

export type HandoutForm = {
  bloomTaxonomyLevel: string[]
  country: string
  curriculum: string
  subject: string
  grade: string
  topic: string
  duration: string
  lessonPlanObjectives: string
  title: string
}

export type HandoutResponse = {
  id: number
  isPositiveResponse: boolean | null
  promptDataId: number
  output: string
}
export type HandoutGeneratorType = HandoutForm & HandoutResponse

// These types are same
export type NarrationForm = HandoutForm
export type NarrationResponse = {
  id: number
  isPositiveResponse: boolean | null
  promptDataId: number
  output: string
}
export type NarrationGeneratorType = NarrationForm & NarrationResponse

export type SubjectiveAssessmentFormType = {
  curriculum: string
  subject: string
  grade: string
  country: string
  topic: string
  duration: string
  assessmentObjective: string
}

export type SubjectiveAssessmentProFormType = {
  grade: number
  subject: string
  curriculum: string
  topic: string
  country: string
  assessmentObjectives: string
  book?: string | null
  difficultyLevel: DifficultyLevel
  questionDetails: {
    marks: number
    numberOfQuestions: number
  }[]
}
export type SubjectiveAssessmentResponse = {
  id: number
  title: string
  totalQuestions: number
  totalMarks: number
  assessmentTopic: string
  grade: string
  subject: string
  curriculum: string
  isPositiveResponse?: boolean | null
  output: string
}

export type SubjectiveAssessmentProResponse = {
  id: number
  subject: string
  curriculum: string
  country: string
  grade: string
  topic: string
  book: string | null
  questionDetails: {
    marks: number
    number_of_questions: number
  }[]
  difficultyLevel: DifficultyLevel
  isPro: boolean
  title: string
  output: string
}

export type AssignmentForm = {
  grade: string
  rubricScale: number
  subject: string
  curriculum: string
  assignmentObjectives: string
}

export type AssignmentProForm = {
  grade: string
  rubricScale: number
  subject: string
  curriculum: string
  assignmentObjectives: string
  topic: string
  book?: string | null
}

export type ActivityPlannerForm = {
  subject: string
  curriculum: string
  grade: string
  topic: string
  duration: string
}

export type ActivityPlannerResponse = {
  id: number
  subject: string
  grade: string
  duration: string
  topic: string
  curriculum: string
  output: string
  title: string
  isPositiveResponse?: boolean | null
}

export type AssignmentOutput = string

export type AssignmentResponse = {
  id: number
  isPositiveResponse?: boolean | null
  promptDataId?: number
  output: AssignmentOutput
}

export type AssignmentProResponse = {
  id: number
  title: string
  subject: string
  curriculum: string
  grade: string
  rubricScale: number
  assignmentObjectives: string
  book: string
  topic: string
  isPro: boolean
  output: AssignmentOutput
}

export type AssignmentGeneratorType = {
  id: number
  title: string
  rubricScale: number
  curriculum: string
  grade: string
  subject: string
  output: AssignmentOutput
}
export type VideoSummaryForm = {
  url: string
  language: string
  startTime: string
  endTime: string
}
export type VideoSummaryResponse = {
  id: number
  url: string
  language: string
  startTime: string
  endTime: string
  title: string
  output: string
  isPositiveResponse: boolean
}
export type VideoDurationType = {
  url: string
  startTime?: string
  duration: string
}

export enum UnitPlanTypeEnum {
  academicYear = "academic_year",
  topic = "topic",
}

export type UnitPlanForm = {
  subject: string
  book: string | null
  topic: string | null
  grade: string
  country: string
  curriculum: string
  classDuration: string
  unitPlanType: UnitPlanTypeEnum
  semesterDuration: string | null
  numberOfDays: string | null
}

export type UnitPlanGenerateResponse = {
  id: number
  grade: string
  classDuration: string
  subject: string
  curriculum: string
  book: string | null
  unitPlanType: UnitPlanTypeEnum
  numberOfDays: number | null
  semesterDuration: string
  country: string
  topic: string | null
  title: string
  output: string
  duration: string
}

export type UnitPlanPromptResponse = {
  title: string
  unitDivisionResponses: number[]
  grade: string
  classDuration: string
  subject: string
  curriculum: string
  unitPlanType: UnitPlanTypeEnum
  country: string
  promptDataId: number
  book?: string
  semesterDuration?: string
  topic?: string
  numberOfDays?: number
  unitPlanResponses: {
    id: number
    response_id: number[]
  }[]
}

export type UnitPlanTerms = {
  title: string
  description: string
  terms: {
    term: string
    units: {
      unitNumber: number
      unitTitle: string
      numberOfWeeks: string
      details: string
    }[]
  }[]
}
export type UnitPlanDivisionResponse = {
  id: number
  output: UnitPlanTerms
  isPositiveResponse: boolean | null
  promptData: number
}
export type UnitPlanResponse = {
  id: number
  output: string
  isPositiveResponse: boolean | null
  promptData: number
}

export type RetrievePrompt<T = ToolType> = {
  id: number
} & (T extends ToolType.quiz
  ? QuizResponse
  : T extends ToolType.quizPro
  ? QuizProResponse
  : T extends ToolType.rubrics
  ? RubricsResponse
  : T extends ToolType.rubricsPro
  ? RubricsProResponse
  : T extends ToolType.lessonPlanPro
  ? LessonPlanProResponse
  : T extends ToolType.lessonPlan
  ? LessonPlanResponse
  : T extends ToolType.handout
  ? HandoutGeneratorType
  : T extends ToolType.narration
  ? NarrationGeneratorType
  : T extends ToolType.activityPlanner
  ? ActivityPlannerResponse
  : T extends ToolType.subjectiveAssessment
  ? SubjectiveAssessmentResponse
  : T extends ToolType.subjectiveAssessmentPro
  ? SubjectiveAssessmentProResponse
  : T extends ToolType.assignment
  ? AssignmentGeneratorType
  : T extends ToolType.assignmentPro
  ? AssignmentProResponse
  : T extends ToolType.videoSummary
  ? VideoSummaryResponse
  : T extends ToolType.unitPlanner
  ? UnitPlanPromptResponse
  : never)

export type ListPromptHistory = {
  dateCreated: string
  title: string
  promptDataId: number
  generatedWithLessonPlan?: boolean
  toolType: ToolType
}

type BloomTaxonomyLevels =
  | "remembering"
  | "understanding"
  | "application"
  | "analysis"
  | "evaluation"
  | "creativity"

export type AIToolPrompts = {
  id: number
  title: string
  country?: string
  curriculum?: string
  subject?: string
  numberOfClasses?: number | null
  numberOfIndividualQuestions?: number | null
  book?: string | null
  criteriaLevel?: string | null
  topic?: string | null
  grade?: string
  duration?: string
  objective?: string
  pointOfScale?: number
  rubricScale?: number
  taxonomy?: string[]
  bloomTaxonomyLevel?: BloomTaxonomyLevels[]
  numberOfQuestions?: number
  responseIds: number[]
  totalMarks?: number
  totalQuestions?: number
  numberOfComprehensions?: number
  numberOfComprehensionQuestions?: number
  difficultyLevel?: DifficultyLevel
  startTime?: string
  endTime?: string
  language?: string
  rubricType?: RubricType
  unitPlanResponses?: {
    id: number
    responseIds: number[]
  }[]
  unitDivisionResponses: number[]
  classDuration: string
  unitPlanType?: UnitPlanTypeEnum
  promptDataId: number
  semesterDuration?: string
  numberOfDays?: number
}

export type CoverLetterHistory = {
  id: number
  schoolName: string
  position: number
  subject: number
  dateCreated: string
}

export type RetrieveCoverLetter = {
  dateCreated?: string | null
  position: string | null
  subject: string | null
  schoolName: string | null
  recipientName?: string | null
  id?: number
  fullName: string | null
  email: string | null
  phoneNumber: string | null
  country: string | null
  state: string | null
  output: string | null
  isPositiveResponse: boolean | null
}

export interface ResumeFillerType {
  firstName?: {
    firstName: string
  }
  lastName?: {
    lastName: string
  }
  gender?: {
    gender: Gender
  }
  dateOfBirth?: {
    dateOfBirth: string
  }
  phoneNumber?: {
    phoneNumber: { code: string; number: string }
  }
  lookingForJobs?: {
    lookingForJobs: boolean
  }
  nationality?: { nationality: Country }
  country?: Country
  careerAspiration?: {
    careerAspiration: string
  }
  state?: State
  qualifications?: Qualification[]
  certification?: Certification[]
  workExperience?: WorkExperienceType[]
  skills?: Skill[]
  language?: LanguageResponse[]
  subjects?: SubjectResponse[]
  interest?: Interest[]
  awards?: Award[]
  project?: Project[]
  publication?: Publication[]
  testScores?: TestScore[]
  workLocationInterests?: WorkLocationInterest[]
}
export type UpdateField = { updatedField?: string[] }
