import { Typography } from "@suraasa/placebo-ui"
import { IconButton } from "@suraasa/placebo-ui-legacy"
import { Edit, Plus } from "iconoir-react"
import { useContext, useState } from "react"
import { pluralize } from "utils/helpers"

import ProfileContext from "../../context"
import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import WorkLocationDialog from "./WorkLocationDialog"

const WorkLocationInterests = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const toggle = () => setOpenDialog(!openDialog)

  const {
    workLocationInterest: { data },
    isPublic,
  } = useContext(ProfileContext)

  const [primary, secondary, ...others] = data

  return (
    <>
      <WorkLocationDialog
        open={openDialog}
        onBack={toggle}
        onRequestClose={toggle}
      />
      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              actionIcon={
                isPublic ? undefined : (
                  <IconButton onClick={toggle}>
                    {data.length > 0 ? <Edit /> : <Plus />}
                  </IconButton>
                )
              }
              itemCount={data.length}
              title={pluralize("Work Location Interest", data.length, {
                skipCount: true,
              })}
            />
          }
        />
        <SectionContent className="flex flex-col flex-wrap gap-2" xPadding={2}>
          {data.length > 0 ? (
            <div className="flex flex-col gap-1.75">
              {primary && (
                <div>
                  <Typography
                    variant="smallBody"
                    className="mb-0.5 !text-onSurface-500"
                  >
                    Primary Work Location Interest
                  </Typography>
                  <Item
                    isoCode={primary.country.isoCode}
                    state={primary.state.name}
                    country={primary.country.name}
                  />
                </div>
              )}
              {secondary && (
                <div>
                  <Typography
                    variant="smallBody"
                    className="mb-0.5 !text-onSurface-500"
                  >
                    Secondary Work Location Interest
                  </Typography>
                  <Item
                    isoCode={secondary.country.isoCode}
                    state={secondary.state.name}
                    country={secondary.country.name}
                  />
                </div>
              )}
              {others.length > 0 && (
                <div>
                  <Typography
                    variant="smallBody"
                    className="mb-0.5 !text-onSurface-500"
                  >
                    {pluralize(
                      "Other Work Location Interest",
                      data.slice(2).length,
                      {
                        skipCount: true,
                      }
                    )}
                  </Typography>
                  <div className="flex flex-wrap gap-1">
                    {others.map(x => (
                      <Item
                        key={x.id}
                        isoCode={x.country.isoCode}
                        state={x.state.name}
                        country={x.country.name}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Typography className="text-onSurface-400" variant="smallBody">
              {isPublic
                ? "Looks like there's nothing here."
                : "You haven’t added any work location interests. Click on + icon to add one."}
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}

const Item = ({
  isoCode,
  state,
  country,
}: {
  isoCode: string
  state: string
  country: string
}) => {
  return (
    <div className="flex items-center">
      <img
        alt=""
        className="mr-1.25 h-2.5 w-3.5"
        src={`/assets/flags/${isoCode}.svg`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src = "/assets/flags/placeholder.png"
        }}
      />
      <div>
        <Typography className="inline" variant="strong">
          {state},{" "}
        </Typography>
        <Typography className="inline" variant="body">
          {country}
        </Typography>
      </div>
    </div>
  )
}

export default WorkLocationInterests
