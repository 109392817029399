import { CircularProgress, toast } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import { getToolName } from "features/AItools/helper"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import Heading from "../../Heading"
import TogglePro from "../../TogglePro"
import { AIToolsContext } from "../context"
import AssignmentOutput from "../GenerationOutput/AssignmentOutput"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import BasicForm from "./BasicForm"
import ProForm from "./ProForm"

const AssignmentGenerator = ({
  id,
  onGeneration,
}: {
  id?: string
  onGeneration: (data: ListPromptHistory) => void
}) => {
  const navigate = useNavigate()

  const [enableProMode, setEnableProMode] = useState(false)
  const { currentResponseId, overviewData, setPromptDataId, promptDataId } =
    useContext(AIToolsContext)

  const content = useQuery({
    enabled: currentResponseId !== undefined,
    queryKey: queries.aiTools.retrieveHistory(
      ToolType.assignment,
      currentResponseId
    ).queryKey,
    queryFn: async () => {
      const res = await api.aiTools.retrieveContent<ToolType.assignment>({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: currentResponseId!,
          type: ToolType.assignment,
        },
      })
      return res
    },
  })

  useEffect(() => {
    if (content.error instanceof APIError) {
      toast.error(content.error.message || "Something went wrong")
    }
  }, [content.error])

  if (id && content.isLoading) {
    return (
      <div className="flex flex-col items-center justify-center p-5">
        <CircularProgress />
      </div>
    )
  }

  if (content.data && promptDataId) {
    return (
      <>
        <ContentActions
          onBack={() => {
            navigate(routes.aiTools.assignment, { replace: true })
            setPromptDataId(null)
          }}
          currentResponseId={currentResponseId}
          toolType={ToolType.assignment}
          title={overviewData?.title}
        />

        <AssignmentOutput {...content.data} />
      </>
    )
  }

  return (
    <>
      <Heading
        title={`${getToolName(ToolType.assignment)} Generator`}
        subtitle="A tool for creating assignments based on your preferences and specifications."
      />
      <TogglePro
        isPro={enableProMode}
        onChange={state => setEnableProMode(state)}
        className="mb-2"
      />
      {enableProMode ? (
        <ProForm
          onGeneration={res => {
            onGeneration({
              dateCreated: new Date().toString(),
              promptDataId: res.id,
              title: res.title,

              toolType: ToolType.assignmentPro,
            })
          }}
        />
      ) : (
        <BasicForm
          onGeneration={res => {
            onGeneration({
              dateCreated: new Date().toString(),
              promptDataId: res.id,
              title: res.title,
              toolType: ToolType.assignment,
            })
          }}
        />
      )}
    </>
  )
}

export default AssignmentGenerator
