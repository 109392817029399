import { Typography } from "@suraasa/placebo-ui"
import { Button, Tooltip } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import queries from "api/resources/masterclasses/queries"
import clsx from "clsx"
import { Calendar, Clock } from "iconoir-react"
import { useEffect } from "react"
import { createUseStyles } from "react-jss"
import { useTimer } from "react-timer-hook"
import { formatDate } from "utils/helpers"

type Props = {
  className?: string
}
const useStyles = createUseStyles(() => ({
  description: {
    "& ul": {
      paddingInlineStart: "14px !important",
      listStyle: "outside",
      "& span": {
        backgroundColor: "transparent !important",
      },
    },
  },
}))

const MasterclassCard = ({ className }: Props) => {
  const classes = useStyles()
  const { data, refetch } = useQuery({
    queryKey: queries.upcomingMasterclass().queryKey,
    queryFn: () => api.masterclasses.overview(),
  })

  const trackMasterclass = () => {
    if (!data || !data.upcomingMasterclass) return

    api.masterclasses.trackItoMasterclass({
      urlParams: { videoMeetingId: data.upcomingMasterclass.videoMeetingId },
      data: {
        joinTime: new Date().toISOString(),
      },
    })
  }

  if (!data || data?.upcomingMasterclass === null) {
    return null
  }
  const { upcomingMasterclass } = data

  return (
    <div className={className}>
      <div className="overflow-hidden rounded-xl bg-ito-iris-100 p-0 md:p-3">
        <div className="flex flex-col-reverse  justify-between md:flex-row">
          <div className="mr-0 px-3 pt-2 md:mr-3 md:p-0">
            <Typography variant="title2" className="mb-2 text-onSurface-800">
              {upcomingMasterclass.title}
            </Typography>
            <Typography className="mb-2 max-w-[560px] text-onSurface-800">
              <span
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html: upcomingMasterclass.description,
                }}
              />
            </Typography>
          </div>
          <img
            src={upcomingMasterclass.thumbnail}
            className="max-h-[164px] w-full shrink-0 object-cover shadow-md md:w-auto md:min-w-[271px] md:rounded-lg"
            alt="masterclass-img"
          />
        </div>

        <Timer
          onExpire={() => {
            refetch()
          }}
          onClick={() => trackMasterclass()}
          startTime={upcomingMasterclass.startTime}
          className="px-3 pb-3 md:p-0"
          url={upcomingMasterclass.joiningUrl}
        />
      </div>
    </div>
  )
}

const Item = ({
  className,
  title,
  description,
  icon,
}: {
  title: string
  className?: string
  description: string
  icon: JSX.Element
}) => {
  return (
    <div className={clsx("flex flex-row items-center", className)}>
      <div className="mr-1 rounded-lg border border-solid border-ito-iris-100 bg-white px-[9px] py-1.25">
        {icon}
      </div>
      <div>
        <Typography variant="strongSmallBody" className="text-onSurface-600">
          {title}
        </Typography>
        <Typography variant="smallBody" className="!text-ito-midnight">
          {description}
        </Typography>
      </div>
    </div>
  )
}

const earlyEntryInMinutes = 10

const Timer = ({
  className,
  url,
  onExpire,
  onClick,
  startTime,
}: {
  startTime: string
  url: string | null
  className?: string
  onExpire: () => void
  onClick: () => void
}) => {
  const { hours, days, minutes, seconds, totalSeconds } = useTimer({
    expiryTimestamp: new Date(startTime),
  })

  let result = ""

  result += hours > 0 ? `${hours}h : ` : ""
  result += minutes > 0 ? `${minutes}m : ` : ""
  result += seconds >= 0 ? `${seconds}s` : ""

  const allowEntry = totalSeconds <= earlyEntryInMinutes * 60

  useEffect(() => {
    if (url === null && allowEntry) {
      onExpire()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowEntry, url])

  return (
    <div className={className}>
      {(days === 0 && hours >= 12) || days > 0 ? (
        <div className="flex flex-wrap items-center gap-2">
          <Item
            icon={<Calendar />}
            title="Date"
            description={formatDate(startTime, "dd MMMM yyyy")}
          />
          <Item
            icon={<Clock />}
            title="Time"
            description={formatDate(startTime, "h:m aaa")}
          />
        </div>
      ) : (
        <div className="flex items-center">
          <Tooltip
            disabled={allowEntry}
            maxWidth="263px"
            title={`Join Masterclass button will be enabled ${earlyEntryInMinutes} minutes before the masterclass.`}
          >
            <span>
              <Button
                component="a"
                target="_blank"
                href={url || ""}
                className="mr-2"
                rounded
                disabled={!allowEntry || url === null}
                onClick={onClick}
              >
                Join Masterclass
              </Button>
            </span>
          </Tooltip>

          {!allowEntry && (
            <Typography
              variant="smallBody"
              className="block shrink-0 text-onSurface-500"
            >
              Starts in {result}
            </Typography>
          )}
        </div>
      )}
    </div>
  )
}

export default MasterclassCard
