import { Typography } from "@suraasa/placebo-ui"
import { InfiniteData, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { PaginatedAPIResponse } from "api/types"
import { getToolName } from "features/AItools/helper"
import { useState } from "react"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

import NarrationContent from "../NarrationContent"

const TemplateNarrationsList = ({
  isFetching,
  fetchNextPage,
  hasNextPage,
  narrations: data,
}: {
  isFetching: boolean
  fetchNextPage: () => void
  hasNextPage?: boolean
  narrations?: InfiniteData<PaginatedAPIResponse<ListPromptHistory[]>>
}) => {
  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage,
    actionFunc: fetchNextPage,
  })

  const allData = data?.pages.flatMap(page => page.data)

  const firstData = allData && allData.length > 0 ? allData[0] : null

  const hasOnlyOneHandout = allData && allData.length === 1

  return (
    <>
      <FetchNarrationContent
        id={firstData?.promptDataId}
        defaultOpen
        sequence={hasOnlyOneHandout ? undefined : 0}
      />
      <div className="mb-4 space-y-4">
        <div>
          {allData &&
            allData.filter(x => x.generatedWithLessonPlan !== true).length >
              0 && (
              <Typography
                variant="smallBody"
                className="mb-2 text-onSurface-500"
              >
                The Narrations below were generated separately by linking this
                Lesson Plan in the <b>Narration Generator.</b>
              </Typography>
            )}

          {allData &&
            allData
              .filter(x => x.generatedWithLessonPlan !== true)
              .map((prompt, index) => {
                const isLastItem = index === allData.length - 1
                return (
                  <div key={prompt.promptDataId} ref={isLastItem ? ref : null}>
                    <FetchNarrationContent
                      defaultOpen
                      sequence={hasOnlyOneHandout ? undefined : index + 1}
                      id={prompt.promptDataId}
                    />
                  </div>
                )
              })}
        </div>
      </div>
    </>
  )
}

const FetchNarrationContent = ({
  id,
  defaultOpen,
  sequence,
}: {
  id?: number
  defaultOpen?: boolean
  sequence: number | undefined
}) => {
  const [currentResponseId, setCurrentResponseId] = useState<number | null>(
    null
  )
  const { data: overviewData, refetch } = useQuery({
    queryKey: queries.aiTools.promptData(ToolType.narration, id).queryKey,
    queryFn: () =>
      api.aiTools.getPromptData({
        urlParams: {
          type: ToolType.narration,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: id!,
        },
      }),
    enabled: Boolean(id),
    onSuccess: res => {
      setCurrentResponseId(res.responseIds[0])
    },
  })
  const { data } = useQuery({
    enabled: Boolean(currentResponseId),
    queryKey: queries.aiTools.retrieveHistory(
      ToolType.narration,
      currentResponseId
    ).queryKey,
    queryFn: async () => {
      const res = await api.aiTools.retrieveContent<ToolType.narration>({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { type: ToolType.narration, id: currentResponseId! },
      })
      return res
    },
  })

  const title =
    sequence !== undefined
      ? `${getToolName(ToolType.narration)} ${sequence + 1}: ${
          overviewData?.title
        }`
      : overviewData?.title

  return (
    <div>
      {overviewData && data && (
        <NarrationContent
          showDownloadButton
          promptDataId={data.promptDataId}
          isPositiveResponse={data.isPositiveResponse}
          defaultOpen={defaultOpen}
          id={overviewData.id}
          output={data.output}
          title={title}
          onResponseIdChange={number => {
            setCurrentResponseId(number)
          }}
          responseIds={overviewData.responseIds}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          currentResponseId={currentResponseId!}
          type={ToolType.narration}
          refetchOverviewData={() => {
            refetch()
          }}
        />
      )}
    </div>
  )
}

export default TemplateNarrationsList
