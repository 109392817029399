import clsx from "clsx"
import React, { PropsWithChildren } from "react"

const LearningModuleContainer = ({
  children,
  disabled,
  removePadding,
}: PropsWithChildren<{ disabled?: boolean; removePadding?: boolean }>) => {
  if (disabled) return <>{children}</>
  return (
    // Updating classNames here will not update assessment item container. It has its own. So keep that in mind if you change any classes here.
    <div
      className={clsx(
        "relative mb-4 h-full rounded-2xl border-0 border-onSurface-200 bg-white sm:border",
        {
          "p-0 sm:p-3": !removePadding,
        }
      )}
    >
      {children}
    </div>
  )
}

export default LearningModuleContainer
