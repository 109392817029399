import React from "react"

type Props = {
  list: {
    title: string
    backgroundColor: string
    borderColor?: string
  }[]
}

const Legend = ({ list }: Props) => {
  return (
    <div className="flex flex-wrap">
      {list.map(item => (
        <div
          key={item.title}
          className="mb-0.5 mr-2.5 grid grid-flow-col items-center justify-start gap-0.75"
        >
          <div
            style={{
              height: 16,
              width: 16,
              borderRadius: 4,
              backgroundColor: item.backgroundColor,
              border: `1.5px solid ${
                item.borderColor ? item.borderColor : item.backgroundColor
              }`,
            }}
          />
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  )
}

export default Legend
