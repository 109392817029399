import { RubricsResponse } from "api/resources/aiTools/types"
import clsx from "clsx"
import { useContext } from "react"

import Paper from "../../../Paper"
import { AIToolsContext } from "../../context"
import InputDisplay from "../../helpers/InputDisplay"
import MarkdownRenderer from "../MarkdownRenderer"
import RegenerateOutput from "../RegenerateOutput"
import styles from "./Rubrics.module.css"

const RubricOutput = ({ output, ...props }: RubricsResponse) => {
  const { overviewData } = useContext(AIToolsContext)

  output = output.replace("### Rubrics:", "")
  output = output.replace("### Rubric:", "")
  output = output.replace("**Rubrics:**", "")
  output = output.replace("**Rubric:**", "")

  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && <InputDisplay data={overviewData} />}
      <Paper
        className="mb-3 !p-0"
        endSlot={
          <RegenerateOutput
            copyText={output}
            isPositiveResponse={props.isPositiveResponse}
          />
        }
      >
        <div
          className={clsx("overflow-x-auto px-2 pt-2", styles.tableContainer)}
        >
          <MarkdownRenderer>{output}</MarkdownRenderer>
        </div>
      </Paper>
    </div>
  )
}

export default RubricOutput
