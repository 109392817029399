import { useState } from "react"

export type UseArray<T> = {
  array: T[]
  set: React.Dispatch<React.SetStateAction<T[]>>
  push: (element: T) => void
  unshift: (element: T) => void
  filter: (callback: (item: T) => void) => void
  update: (index: number, newElement: T) => void
  updateByKey: (id: string | number, newValue: T, key?: string) => void
  remove: (index: number) => void
  removeByKey: (id: string | number, key?: string) => void
  clear: () => void
}

export default function useArray<T = Array<any>>(
  defaultValue: T[]
): UseArray<T> {
  const [array, setArray] = useState(defaultValue)

  function push(element: T) {
    setArray(a => [...a, element])
  }
  function unshift(element: T) {
    setArray(a => [element, ...a])
  }

  function filter(callback: (item: T) => void) {
    setArray(a => a.filter(callback))
  }

  function update(index: number, newElement: T) {
    setArray(a => [
      ...a.slice(0, index),
      newElement,
      ...a.slice(index + 1, a.length),
    ])
  }

  function updateByKey(id: string | number, newValue: T, key = "id") {
    setArray(arr => arr.map(v => (v[key] === id ? { ...v, ...newValue } : v)))
  }

  function removeByKey(id: string | number, key = "id") {
    setArray(arr => arr.filter(v => v && v[key] !== id))
  }

  function remove(index: number) {
    setArray(a => [...a.slice(0, index), ...a.slice(index + 1, a.length)])
  }

  function clear() {
    setArray([])
  }

  return {
    array,
    set: setArray,
    push,
    unshift,
    filter,
    update,
    updateByKey,
    remove,
    removeByKey,
    clear,
  } as const
}
