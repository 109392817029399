import { Typography } from "@suraasa/placebo-ui"
import { Button, Divider, IconButton, Theme } from "@suraasa/placebo-ui-legacy"
import {
  AssessmentQuestion,
  QuestionStatus,
} from "api/resources/assessments/types"
import clsx from "clsx"
import { getQuestionStatus } from "features/Assessments/helpers"
import { Xmark } from "iconoir-react"
import React from "react"
import { createUseStyles, useTheme } from "react-jss"

import Legend from "./Legend"

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    height: "100%",
    position: "relative",
    zIndex: 3,
    [theme.breakpoints.up("lg")]: {
      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
      backgroundColor: theme.colors.common.white[500],
      borderRadius: 8,
      padding: theme.spacing(2, 0),
    },
  },
  btnWrapper: {
    height: 48,
    width: 48,
    padding: 0,
    backgroundColor: theme.colors.secondary[50],
    border: `2px solid ${theme.colors.secondary[500]}`,
    borderRadius: 8,
    color: theme.colors.common.black[700],
    "&:hover": {
      backgroundColor: theme.colors.secondary[200],
    },
    "&:active": {
      backgroundColor: theme.colors.secondary[200],
    },
  },
  markedForReview: {
    color: theme.colors.common.white[500],
    border: `2px solid ${theme.colors.warning[500]}`,
    backgroundColor: theme.colors.warning[500],
    "&:hover": {
      backgroundColor: theme.colors.warning[600],
    },
    "&:active": {
      backgroundColor: theme.colors.warning[400],
    },
  },

  attempted: {
    color: theme.colors.common.white[500],
    border: `2px solid ${theme.colors.success[500]}`,
    backgroundColor: theme.colors.success[500],
    "&:hover": {
      backgroundColor: theme.colors.success[600],
    },
    "&:active": {
      backgroundColor: theme.colors.success[400],
    },
  },
  activeBtnOutline: {
    outline: `2px solid ${theme.colors.interactive[400]}`,
    outlineOffset: "1px",
  },
  attemptedActive: {
    "&:hover": {
      backgroundColor: theme.colors.success[700],
    },
  },
  markedForReviewActive: {
    "&:hover": {
      backgroundColor: theme.colors.warning[700],
    },
  },

  cancel: {
    cursor: "pointer",
    color: theme.colors.common.black[200],
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  none: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}))

const QuestionPanel = ({
  onClose,
  onQuestionChange,
  currentQuestion,
  questions,
}: {
  onClose?: () => void
  onQuestionChange: (questionNumber: number) => void
  currentQuestion: AssessmentQuestion
  questions: AssessmentQuestion[]
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()

  const getActiveBtnColor = (
    question: AssessmentQuestion,
    status: QuestionStatus
  ) => {
    if (currentQuestion.questionNumber !== question.questionNumber) return
    switch (status) {
      case QuestionStatus.ANSWERED:
        return `${classes.attemptedActive} ${classes.activeBtnOutline}`
      case QuestionStatus.MARKED_FOR_REVIEW:
        return `${classes.markedForReviewActive} ${classes.activeBtnOutline}`
      default:
        return ""
    }
  }

  return (
    <div className={clsx(classes.container)}>
      <div className="grid grid-flow-col items-center justify-between px-2 pt-2 lg:pl-2 lg:pt-0">
        <Legend
          list={[
            {
              title: "Not Answered",
              backgroundColor: theme.colors.secondary[50],
              borderColor: theme.colors.onSurface[500],
            },
            {
              title: "Answered",
              backgroundColor: theme.colors.success[500],
            },
            {
              title: "Marked for review",
              backgroundColor: theme.colors.warning[500],
            },
          ]}
        />
        <IconButton className={classes.cancel}>
          <Xmark onClick={onClose} />
        </IconButton>
      </div>
      <Divider weight="light" className="my-2" />

      <Typography
        variant="preTitle"
        className={clsx("px-2 text-onSurface-600 lg:pl-2", classes.none)}
      >
        Questions
      </Typography>
      <div className="my-2 grid grid-cols-5 gap-y-2 px-2 py-0.5 sm:grid-cols-10 lg:grid-cols-4 lg:px-2">
        {questions.map(ques => {
          const status = getQuestionStatus(ques)
          const activeClass = getActiveBtnColor(ques, status)

          return (
            <Button
              onClick={() => onQuestionChange(ques.questionNumber)}
              key={ques.id}
              size="sm"
              className={clsx(
                classes.btnWrapper,
                activeClass,
                "flex justify-center p-1",
                {
                  [classes.markedForReview]:
                    status === QuestionStatus.MARKED_FOR_REVIEW,
                  [classes.attempted]: status === QuestionStatus.ANSWERED,
                }
              )}
            >
              <Typography variant="strong">{ques.questionNumber}</Typography>
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default QuestionPanel
