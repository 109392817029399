import clsx from "clsx"
import React from "react"
import { PropsWithClassName } from "utils/types"

const Paper = ({
  children,
  className,
  endSlot,
}: PropsWithClassName<
  React.PropsWithChildren & { endSlot?: React.ReactNode }
>) => {
  return (
    <div
      className={clsx(
        "w-full overflow-hidden rounded-2xl border border-onSurface-200 bg-white shadow-sm"
      )}
    >
      <div className={clsx("p-2", className)}>{children}</div>
      {endSlot && endSlot}
    </div>
  )
}

export default Paper
