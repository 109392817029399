import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getKeplerURL = getServiceURL(SERVICE_PREFIX.kepler)

export const urls = validateUrls({
  create: () => getKeplerURL("/v1/feedback/"),
  retrieve: (itemType, itemId) =>
    getKeplerURL(`/v1/feedback/${itemType}/${itemId}/`),
})
