import autosize from "autosize"
import { MapsArrow } from "iconoir-react"
import { useEffect } from "react"
import { useForm } from "react-hook-form"

const StopCircle = (props: React.SVGProps<any>) => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 16 16"
      height="200px"
      width="200px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
      <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5z"></path>
    </svg>
  )
}
type Props = {
  onSend: (x: string) => void
  disabled: boolean
}

type FormType = {
  message: string
  disable: boolean
}
const Input = ({ onSend, disabled }: Props) => {
  const { register, handleSubmit, resetField } = useForm<FormType>({
    defaultValues: {
      message: "",
    },
  })

  const onSubmit = handleSubmit(async r => {
    if (r.message.length === 0 || disabled) {
      return
    }
    await onSend(r.message)
    resetField("message")
    const textarea = document.getElementById(
      "textarea-chatbox"
    ) as HTMLTextAreaElement

    setTimeout(() => {
      textarea.style.height = "inherit"
      textarea.value = ""
    }, 50)
  })
  const handleKeydown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      onSubmit()
    }
  }

  useEffect(() => {
    const textarea = document.getElementById("textarea-chatbox")

    autosize(textarea)

    return () => {}
  }, [])

  return (
    <div className="flex size-full items-end gap-1 rounded-3xl border hover:ring-1 hover:ring-primary-100">
      <textarea
        className="size-full resize-none overflow-auto !overflow-x-hidden rounded-3xl border-none py-1 pl-2 pr-1 outline-none"
        placeholder="Type something..."
        rows={1}
        style={{
          maxHeight: "100px",
        }}
        id="textarea-chatbox"
        onKeyDown={handleKeydown}
        {...register("message")}
      />
      <div className="mb-0.75 mr-0.75 flex h-full items-end rounded-full text-blue-500 ">
        {disabled ? (
          <StopCircle
            className="size-3.5 rotate-90 rounded-full p-0.25 pl-0.5"
            onClick={() => {}}
          />
        ) : (
          <MapsArrow
            className="size-3.5 rotate-90 cursor-pointer rounded-full p-0.25 pl-0.5 hover:bg-gray-100 "
            onClick={() => onSubmit()}
          />
        )}
      </div>
    </div>
  )
}

export default Input
