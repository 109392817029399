import { toast, Typography } from "@suraasa/placebo-ui"
import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import queries from "api/resources/learningItems/queries"
import { Resource } from "api/resources/learningItems/types"
import MysteryBox from "assets/Fallback/mystery-box.svg"
import ComputerPlay from "assets/Resources/computerPlay.svg"
import Fallback from "components/Fallback"
import TruncatedText from "components/TruncatedText"
import { format } from "date-fns/esm"
import CheckRestrictions, {
  getRestriction,
} from "features/AccessManagement/CheckRestrictions"
import { Lock } from "iconoir-react"
import groupBy from "lodash/groupBy"
import { useTheme } from "react-jss"
import { useParams } from "react-router-dom"
import { downloadFile, getAuthInfo, getPlatformURL } from "utils/helpers"

import ItemList from "../../../components/ItemList"
import ItemRow from "../../../components/ItemRow"
import { useCourseDetails } from "../Course"
import { useQualificationDetails } from "../Qualification"

type Props = {
  course?: boolean
  qualification?: boolean
}

const Resources = (props: Props) => {
  const { course } = useCourseDetails()
  const { qualification } = useQualificationDetails()

  const { learningItemType } = useParams() as { learningItemType: string }

  const itemType = props.course ? "course" : learningItemType
  const itemId = itemType === "course" ? course.id : qualification.id

  const {
    data: additionalResources,
    isLoading,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: queries.listResources(itemType, itemId).queryKey,
    queryFn: () =>
      api.learningItems.listResources({ urlParams: { itemType, itemId } }),
  })

  const download = async (resource: Resource) => {
    if (resource.url.includes("https://proxy.lirn.net/IntlTeachersUniv")) {
      const newTab = window.open()
      if (newTab) {
        const response = await fetch(
          getPlatformURL("suraasa", "/api/lirn-access"),
          // "http://localhost:300/api/lirn-access",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              username: getAuthInfo()?.user.username,
            }),
          }
        )

        const data = await response.json()
        const url = data?.url

        if (url) {
          newTab.location.href = url
        } else {
          toast.error("Something went wrong", {
            description: "Please contact care@suraasa.com",
          })
        }
      }
    } else {
      downloadFile(resource.url, resource.name)
    }
  }

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const qualificationResources =
    additionalResources?.filter(x => x.learningItemType === "qualification") ||
    []
  const certificationResources =
    additionalResources?.filter(x => x.learningItemType === "certification") ||
    []
  const courseResources =
    additionalResources?.filter(x => x.learningItemType === "course") || []

  const buildRow = (item: Resource) => {
    return (
      <ItemRow
        onClick={(() => {
          const isRestricted = getRestriction(item)
          if (isRestricted) return undefined
          return () => download(item)
        })()}
        key={`${item.learningItemType}-${item.name}`}
        infoSlot={
          <div className="flex items-center">
            <div className="me-2 size-fit shrink-0 rounded-md bg-onSurface-200 p-1">
              <img alt="icon" src={ComputerPlay} width={20} height={19} />
            </div>
            <div>
              <TruncatedText
                maxLength={60}
                color="onSurface.900"
                variant="strongSmallBody"
                hideActionButton
              >
                {item.name}
              </TruncatedText>
              <Typography className="text-onSurface-500" variant="smallBody">
                Uploaded on {format(new Date(item.dateCreated), "do MMM, y")}
              </Typography>
            </div>
          </div>
        }
        actionSlot={() => {
          return (
            <CheckRestrictions
              accessStatus={item.accessStatus}
              render={({ isRestricted }) => {
                if (isXs) return null
                if (isRestricted) return <Lock />
                return <Button variant="text">Download</Button>
              }}
            />
          )
        }}
      />
    )
  }

  return (
    <div>
      <Typography variant="title3" className="mb-2">
        Resources
      </Typography>

      {(isError || isLoading) && (
        <Fallback
          data={{
            image: MysteryBox,
            title: "Something went wrong!",
            description: "Please contact care@suraasa.com",
          }}
          className="my-9 sm:my-20 md:my-18"
          hasError={isError}
          isLoading={isLoading}
        />
      )}
      {isSuccess && (
        <>
          {additionalResources.length === 0 && (
            <Fallback
              data={{
                image: MysteryBox,
                title: "No resources available",
              }}
              className="my-6 sm:my-20 md:my-8"
              hasError={false}
            />
          )}

          {qualificationResources.length > 0 &&
            Object.entries(
              groupBy(qualificationResources, "learningItemName")
            ).map(([courseName, resources]) => (
              <>
                <Typography variant="title4" className="mb-2">
                  {courseName}
                </Typography>
                <ItemList className="mb-4">
                  {resources.map(item => buildRow(item))}
                </ItemList>
              </>
            ))}
          {certificationResources.length > 0 &&
            Object.entries(
              groupBy(certificationResources, "learningItemName")
            ).map(([courseName, resources]) => (
              <>
                <Typography variant="title4" className="mb-2">
                  {courseName}
                </Typography>
                <ItemList className="mb-4">
                  {resources.map(item => buildRow(item))}
                </ItemList>
              </>
            ))}
          {courseResources.length > 0 &&
            Object.entries(groupBy(courseResources, "learningItemName")).map(
              ([courseName, resources]) => (
                <>
                  <Typography variant="title4" className="mb-2">
                    {courseName}
                  </Typography>
                  <ItemList className="mb-4">
                    {resources.map(item => buildRow(item))}
                  </ItemList>
                </>
              )
            )}
        </>
      )}
    </div>
  )
}

export default Resources
