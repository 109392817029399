import { Typography } from "@suraasa/placebo-ui"
import format from "date-fns/format"
import isSameDay from "date-fns/isSameDay"
import isToday from "date-fns/isToday"
import isYesterday from "date-fns/isYesterday"
import React, { useEffect, useRef } from "react"
import { pluralize } from "utils/helpers"

import MessageRow, { Author, Message } from "./MessageRow"

const NewMessageDivider = ({ count }: { count: number }) => {
  return (
    <div className="flex items-center gap-1">
      <div className="grow rounded-full border-t-[0.5px] border-onSurface-400" />
      <div className="shrink">
        <Typography className="text-onSurface-400">
          {pluralize("new message", count)}
        </Typography>
      </div>
      <div className="grow rounded-full border-t-[0.5px] border-onSurface-400" />
    </div>
  )
}

const DateContainer = ({
  date,
  showToday,
  showYesterday,
  showDate,
}: {
  showDate: boolean
  date?: number | Date
  showToday: boolean
  showYesterday: boolean
}) => {
  return (
    <Typography
      variant="smallBody"
      className="my-1 text-center text-onSurface-400"
    >
      {showToday && "TODAY"}
      {showYesterday && "YESTERDAY"}
      {showDate && date && format(new Date(date), "dd MMMM yyyy")}
    </Typography>
  )
}

const ConversationHeader = ({ name }: { name?: string }) => {
  if (!name) {
    return null
  }
  return (
    <div className="">
      <Typography
        variant="smallBody"
        className="my-1 text-center text-onSurface-400"
      >
        You&apos;re now in conversation with {name} <br /> from our team
      </Typography>
    </div>
  )
}

const checkShowDay = (a: Message, b?: Message) => {
  const showToday =
    isToday(new Date(a.createdTime)) && b && !isToday(new Date(b.createdTime))

  const showYesterday =
    isYesterday(new Date(a.createdTime)) &&
    b &&
    !isYesterday(new Date(b.createdTime))

  const showDate =
    !isToday(new Date(a.createdTime)) && !isYesterday(new Date(a.createdTime))
      ? b
        ? !isSameDay(new Date(a.createdTime), new Date(b.createdTime))
        : true
      : false
  const timeVisible =
    Boolean(showToday) || Boolean(showYesterday) || Boolean(showDate)

  return {
    showToday: !!showToday,
    showYesterday: !!showYesterday,
    showDate,
    timeVisible,
  }
}

const MessageContainer = ({
  messages,
  agentName,
}: {
  messages: Message[]
  agentName?: string
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore instant is valid
    messagesEndRef.current?.scrollIntoView({ behavior: "instant" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  return (
    <div className="grow overflow-auto">
      <ConversationHeader name={agentName} />
      {messages.length === 0 ? (
        <DateContainer
          showToday={true}
          showYesterday={false}
          showDate={false}
          date={new Date()}
        />
      ) : (
        (() => {
          const message = messages[0]
          const showToday = isToday(new Date(message.createdTime))
          const showYesterday = isYesterday(new Date(message.createdTime))
          if (!showToday && !showYesterday) {
            return null
          }
          return (
            <DateContainer
              showToday={showToday}
              showYesterday={showYesterday}
              showDate={!showToday && !showYesterday}
              date={messages[0].createdTime}
            />
          )
        })()
      )}

      {messages.map((m, i, arr) => {
        const prevAuthorSame = arr[i - 1]
          ? arr[i - 1].author === m.author
          : false

        const nextAuthorSame = arr[i + 1]
          ? arr[i + 1].author === m.author
          : false

        const dateConfig = checkShowDay(m, arr[i - 1])

        const nextDateConfig = arr[i] ? checkShowDay(arr[i], arr[i + 1]) : false

        const isSelf = m.author === Author.USER

        return (
          <>
            {[
              dateConfig.showToday,
              dateConfig.showDate,
              dateConfig.showYesterday,
            ].some(e => e) && (
              <DateContainer
                showToday={dateConfig.showToday}
                showYesterday={dateConfig.showYesterday}
                showDate={dateConfig.showDate}
                date={m.createdTime}
              />
            )}
            <MessageRow
              message={m}
              key={i}
              isSelf={isSelf}
              prevAuthorSame={prevAuthorSame}
              nextAuthorSame={nextAuthorSame}
              timeVisible={dateConfig.timeVisible}
              isNextDayDifferent={nextDateConfig && nextDateConfig.timeVisible}
            />
          </>
        )
      })}
      {/* <NewMessageDivider count={2} /> */}
      <div ref={messagesEndRef} />
    </div>
  )
}

export default MessageContainer
