import axios from "api/axios"
import { APIResponse, Config, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Certification, Evidence, Qualification } from "../profile/types"
import {
  ApplicationFormOverview,
  Profiles,
  QuestionnaireProduct,
} from "./types"
import { urls } from "./urls"

export default {
  overview: async (): Promise<APIResponse<ApplicationFormOverview[]>> => {
    try {
      const res = await axios.get(urls.applicationOverview())
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  pgctl: {
    getProfiles: async ({
      params,
      headers,
    }: Config): Promise<APIResponse<Profiles>> => {
      try {
        const res = await axios.get(urls.profiles(), { headers, params })
        return formatSuccessResponse(res)
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
  questionnaireProduct: {
    get: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<QuestionnaireProduct[]>> => {
      try {
        const res = await axios.get(urls.getProduct(urlParams.slug))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  profile: {
    createQualificationEvidence: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<Qualification>> => {
      try {
        const res = await axios.post(urls.academicEvidence(), data, {
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    createCertificationEvidence: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<Certification>> => {
      try {
        const res = await axios.post(urls.academicEvidence(), data, {
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    deleteEvidence: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<Evidence>> => {
      try {
        const res = await axios.delete(urls.academicEvidence(), {
          data,
          headers,
        })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
