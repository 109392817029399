import {
  Button,
  CircularProgress,
  cn,
  Sheet,
  SheetBody,
  SheetContent,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import BackgroundImage from "assets/backgroundColor.webp"
// import { AISparkleFilled } from "assets/icons/system/AISparkle"
import AsyncBuilder from "components/AsyncBuilder"
import { getToolName } from "features/AItools/helper"
import { Check, Xmark } from "iconoir-react"
import Lottie from "react-lottie"
import { handleErrors, sleep } from "utils/helpers"

import ProLottie from "../assets/suraasa-pro-tools-lottie.json"
import { FREE_CLAIM_PRODUCT_SLUG } from "../constants"
import { useSuraasaProContext } from "../context/SuraasaProContext"

const toolsText = [
  "Save up to 4 hours daily and focus on what you love - teaching",
  "No more endless searching, get curriculum-aligned resources at your fingertips",
  "⁠Reduce your workload and let AI handle the rest",
  "Get what you need in seconds, no long prompts or multiple edits required",
  "Enjoy  10+ teacher-friendly tools in one place, for all things teaching",
]
// const toolsText = [
//   "Create detailed unit plans & plan student activities in seconds.",
//   "Save time with quick video summaries on the go.",
//   "Get outputs customized to your curriculum and books.",
//   "Craft personalized cover letters for job applications.",
//   "Generate faster & more efficient lesson plans, quizzes, and assignments with Pro.",
// ]

type Props = {
  open: boolean
  handleClose: () => void
  tool?: ToolType
  claimCallback?: () => void
}

const GetProSheet = ({ tool, open, handleClose, claimCallback }: Props) => {
  const { refreshState } = useSuraasaProContext()

  const onGetAccessClick = async () => {
    try {
      await api.orders.createOrder({
        data: {
          product_slug: FREE_CLAIM_PRODUCT_SLUG,
        },
      })

      toast.success("Your Pro Subscription is activated")

      await sleep(2000)

      claimCallback?.()
      await refreshState()

      handleClose()
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err)
      }

      console.error(err)
    }
  }

  return (
    <div className="absolute">
      <Sheet open={open} onOpenChange={handleClose}>
        <SheetBody>
          <SheetContent height={94} className="max-w-screen-md rounded-t-3xl">
            <div className="relative h-full max-w-screen-md overflow-y-auto rounded-t-3xl bg-[#0f172a]">
              <div className="absolute size-full overflow-hidden ">
                <div
                  className={cn("size-full bg-top z-1 bg-cover animate-spin")}
                  style={{
                    animationDuration: "10s",
                    scale: "1.6",
                  }}
                >
                  <div
                    className={cn("size-full bg-top animate-spin")}
                    style={{
                      backgroundImage: `url(${BackgroundImage})`,
                      animationDuration: "10s",
                      scale: "1.6",
                    }}
                  ></div>
                </div>
              </div>
              <div
                className="absolute -bottom-2 z-2 size-full"
                style={{
                  background:
                    "linear-gradient(0deg, rgba(15,23,42,1) 65%, rgba(81,86,100,0.8) 80%, rgba(249,249,249,0) 100%)",
                }}
              ></div>
              <div className="relative z-3 mt-2 size-full">
                <div className="absolute right-3">
                  <Xmark
                    className="cursor-pointer text-surface"
                    onClick={() => handleClose()}
                  />
                </div>
                <div className="flex flex-col items-center px-2 text-surface">
                  <Typography
                    variant="title2"
                    className=" text-center text-inherit"
                  >
                    <div className="flex items-center gap-0.5">
                      Pro AI Teaching Tools{" "}
                      {/* <AISparkleFilled fill="white" width={36} height={32} /> */}
                    </div>
                  </Typography>
                  <Typography
                    variant={"largeBody"}
                    className="mt-1.5 max-w-[580px] text-center leading-6 text-inherit"
                  >
                    {tool
                      ? `Looks like you have stumbled upon a Suraasa Pro tool. ${getToolName(
                          tool
                        )} requires Suraasa Pro Subscription.`
                      : "Making your teaching journey a lot more easier & rewarding."}
                  </Typography>
                  <AsyncBuilder
                    handler={onGetAccessClick}
                    render={({ loading, onClick }) => (
                      <Button
                        className="mt-2.5 rounded-full px-4"
                        onClick={onClick}
                      >
                        <span>
                          <Typography variant={"button"}>
                            Claim Your Early Access
                          </Typography>
                          <Typography
                            variant={"smallBody"}
                            className="mt-0.5 text-primary-200"
                          >
                            1 tap to start, no payment details required
                          </Typography>
                        </span>
                        {loading && (
                          <CircularProgress className="ml-1 text-surface" />
                        )}
                      </Button>
                    )}
                  />
                </div>
                <div className="relative mt-4  pl-2 sm:h-[280px] sm:pl-0">
                  <div
                    className="overflow-hidden rounded-tl-2xl border-l border-t border-[#5C616C] bg-gradient-to-b from-[#ffffff1A] sm:ml-16"
                    style={{
                      boxShadow: "2.47px 2.47px 2.47px 0px #0000001A inset",
                    }}
                  >
                    <div className="ml-2 flex h-[250px] w-[350px] flex-col pt-2 text-surface sm:mx-4 sm:h-[275px] sm:w-[700px] md:w-[370px]">
                      <Lottie
                        speed={1}
                        isClickToPauseDisabled
                        style={{
                          userSelect: "none",
                          cursor: "default",
                          height: "100%",
                          width: "100%",
                        }}
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: ProLottie,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex max-w-[680px] flex-wrap gap-2 px-2 sm:ml-16 sm:mt-2 sm:gap-1 sm:px-0">
                  {toolsText.map((text, i) => (
                    <div
                      key={i}
                      className="flex items-start gap-0.5 px-1.5 py-1.25 pr-2"
                    >
                      <div>
                        <Check className="size-3 text-decorative-one-100" />
                      </div>
                      <Typography
                        variant={"largeBody"}
                        className="text-surface"
                      >
                        {text}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </SheetContent>
        </SheetBody>
      </Sheet>
    </div>
  )
}

export default GetProSheet
