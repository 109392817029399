import { CircularProgress } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { Play } from "iconoir-react"
import React, { useEffect, useRef } from "react"

const VideoPlayer = ({
  className,
  thumbnail,
  src,
  disabled,
}: {
  className?: string
  thumbnail?: string
  src?: string | null
  disabled?: boolean
}) => {
  const [showPlayButton, setShowPlayButton] = React.useState(true)
  const video = useRef<HTMLVideoElement | null>(null)

  const handleOnClick = () => {
    if (video) {
      if (video.current?.paused) {
        video.current.play()
        setShowPlayButton(false)
      }
    }
  }
  useEffect(() => {
    if (video) {
      setShowPlayButton(true)
      video.current?.pause()
      if (video.current?.currentTime) video.current.currentTime = 0
    }
  }, [video])

  return (
    <div className={clsx(className, { relative: !disabled })}>
      {showPlayButton && !disabled && (
        <div className="absolute top-0 z-10 size-full rounded-xl bg-black opacity-60" />
      )}
      <div
        className="absolute left-1/2 top-1/2 z-[11]"
        style={{
          transform: "translate(-50%, -50%)",
        }}
      >
        {!src && (
          <div>
            <CircularProgress color="white" size="lg" />
          </div>
        )}
        {src && showPlayButton && !disabled && (
          <button id="play-button" onClick={handleOnClick}>
            <Play className="size-[63px] fill-white text-white sm:size-[126px]" />
          </button>
        )}
      </div>
      {src && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          ref={video}
          className="size-full rounded-xl"
          controls={!showPlayButton && !disabled}
          controlsList="nodownload"
          id="video"
          poster={thumbnail}
          src={src}
        />
      )}
    </div>
  )
}

export default VideoPlayer
