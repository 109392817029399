import { Tooltip } from "@suraasa/placebo-ui-legacy"
import {
  AccessStatus,
  RestrictionType,
} from "api/resources/learningItems/types"
import { PauseSolid, Timer, XmarkCircle } from "iconoir-react"
import React from "react"

type Options = {
  isRestricted: boolean
  accessStatus: AccessStatus
}

type Props = {
  render: (options: Options) => JSX.Element | null
  accessStatus: AccessStatus
  showTooltip?: boolean
}

export const getRestriction = (
  props: Omit<Props, "showTooltip" | "render">
) => {
  return props.accessStatus === AccessStatus.COMPLETELY_RESTRICTED
}

export const getRestrictionIcon = (type: RestrictionType) => {
  switch (type) {
    case RestrictionType.PAUSED: {
      return <PauseSolid />
    }
    case RestrictionType.EXPIRED: {
      return <Timer />
    }
    case RestrictionType.REVOKED: {
      return <XmarkCircle strokeWidth={2} />
    }
  }
}

const CheckRestrictions = ({ showTooltip = true, ...props }: Props) => {
  const isRestricted = getRestriction(props)

  if (isRestricted && showTooltip) {
    return (
      <Tooltip title="Access Restricted">
        <div>
          {props.render({
            isRestricted,
            accessStatus: props.accessStatus,
          })}
        </div>
      </Tooltip>
    )
  }

  return (
    <>
      {props.render({
        isRestricted,
        accessStatus: props.accessStatus,
      })}
    </>
  )
}

export default CheckRestrictions
