// import { Typography } from "@suraasa/placebo-ui-legacy"
import { Typography } from "@suraasa/placebo-ui"
import { Enrollment } from "api/resources/learningItems/types"
import clsx from "clsx"
import { context } from "global/Context/context"
import React, { useContext } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import routes from "routes"
import { PropsWithClassName } from "utils/types"

import BlackBookIcon from "./assets/blackBookIcon.svg"
import WhiteBookIcon from "./assets/bookIcon.svg"

type Crumb = {
  id?: string
  path: string
  displayName: string
}

export type BreadcrumbProps = PropsWithClassName<{
  skipLastItem?: boolean
  color?: "light" | "dark"
}>

const buildCrumbs = ({
  parent,
  item,
  skipLastItem,
}: {
  parent: Enrollment | undefined
  item: Enrollment | undefined
  skipLastItem: boolean
}): Crumb[] => {
  const crumbs: Crumb[] = [
    { id: "learning", path: routes.learning, displayName: "My Learning" },
  ]

  if (!item) return crumbs

  if (parent) {
    crumbs.push({
      path: `/${parent.type}/${parent.slug}`,
      displayName: `${
        skipLastItem
          ? parent.name
          : // Truncate the item to save space
            `${parent.name.slice(0, 15)}...`
      }`,
    })
    crumbs.push({
      path: `/${parent.type}/${parent.slug}/${item.type}/${item.slug}`,
      displayName: `${item.name}`,
    })
  } else {
    crumbs.push({
      path: `/${item.type}/${item.slug}`,
      displayName: `${item.name}`,
    })
  }

  if (skipLastItem) return crumbs.slice(0, -1)

  return crumbs
}

const buildLibraryCrumb = (
  crumb: Crumb,
  total: number,
  color: BreadcrumbProps["color"]
) => {
  const icon = color === "dark" ? BlackBookIcon : WhiteBookIcon
  if (total <= 1) {
    return (
      <div className="flex items-center">
        <img src={icon} className="me-1" alt="learning" />
        <Typography variant="smallBody">{crumb.displayName}</Typography>
      </div>
    )
  }

  // Mini variant
  return <img src={icon} alt="learning" />
}

const Breadcrumbs = ({
  className,
  color = "dark",
  skipLastItem = true,
}: BreadcrumbProps) => {
  const params = useParams()

  const { state } = useLocation()

  const slug = state?.slug || params.slug
  const parentSlug = state?.parentSlug || params.parentSlug

  const { enrollments } = useContext(context)
  if (!enrollments) return null

  const enrollment = enrollments.find(enrollment => enrollment.slug === slug)
  const parentEnrollment = enrollments.find(
    enrollment => enrollment.slug === parentSlug && enrollment.type !== "course"
  )

  const breadCrumbs = buildCrumbs({
    item: enrollment,
    parent: parentEnrollment,
    skipLastItem: skipLastItem,
  })

  return (
    <div
      className={clsx("mb-1 flex items-center", className, {
        "text-common-white-500": color === "light",
        "text-common-black-500": color === "dark",
      })}
    >
      {breadCrumbs.map((crumb, index) => {
        return (
          <div key={index} className="flex items-center">
            <Link to={crumb.path} className="hover:underline">
              {crumb.id === "learning" ? (
                buildLibraryCrumb(crumb, breadCrumbs.length, color)
              ) : (
                <Typography variant="smallBody">{crumb.displayName}</Typography>
              )}
            </Link>

            {/* Dividers between crumbs */}
            <Typography className="mx-1" variant="smallBody">
              /
            </Typography>
          </div>
        )
      })}
    </div>
  )
}

export default Breadcrumbs
