import { Typography } from "@suraasa/placebo-ui"
import { Container } from "@suraasa/placebo-ui-legacy"
import React, { ReactNode } from "react"

import styles from "./TopHeadingBar.module.css"

const TopHeadingBar = ({
  heading,
  subheading,
  endSlot,
  children,
}: {
  heading: string | ReactNode
  subheading?: string
  endSlot?: ReactNode
  children?: ReactNode
}) => {
  return (
    <div className={styles.root}>
      <Container>
        <div className="flex flex-wrap items-center justify-between gap-1 py-2">
          <div>
            <Typography variant="title2">{heading}</Typography>
            {subheading && (
              <Typography
                variant="largeBody"
                className="mt-0.5 text-onSurface-500"
              >
                {subheading}
              </Typography>
            )}
          </div>

          {endSlot && endSlot}
        </div>
        {children}
      </Container>
    </div>
  )
}

export default TopHeadingBar
