import { Typography } from "@suraasa/placebo-ui"
import { Tag } from "@suraasa/placebo-ui-legacy"
import { ReviewRubric, Rubric } from "api/resources/assignments/types"
import clsx from "clsx"
import React from "react"
import { pluralize } from "utils/helpers"

type Comment = Rubric["allComments"][number]

const sort = (a: Comment, b: Comment) => (a.scale > b.scale ? 1 : -1)

const CriteriaWiseScore = (rubric: Rubric | ReviewRubric) => {
  const isFailed = "marked" in rubric && rubric.marked.scale === 1
  return (
    <div className="cursor-default">
      <div className="mb-2 mt-3 flex items-center justify-between">
        <Typography variant="strong" className="first-letter-capitalize">
          {rubric.criteria}
        </Typography>
        {isFailed && <Tag label="Failed" color="critical" />}
      </div>

      <div className="grid min-w-[180px] gap-1 sm:grid-cols-3">
        {rubric.allComments.sort(sort).map((point, index) => {
          return (
            <div
              key={index}
              className={clsx(
                "rounded-lg border border-onSurface-200 bg-white p-2 text-onSurface-800 shadow-[0px_6px_15px_rgba(0,0,0,0.05)]",
                {
                  "bg-[linear-gradient(235.38deg,#39AFFD_4.23%,#477FFF_95.84%)] text-white":
                    "marked" in rubric &&
                    point.uuid === rubric.marked.uuid &&
                    point.scale !== 1,
                  "bg-[linear-gradient(235.38deg,#E18A8A_4.23%,#D86565_95.84%)] text-white":
                    "marked" in rubric &&
                    point.uuid === rubric.marked.uuid &&
                    point.scale === 1,
                }
              )}
            >
              <Typography variant="title4" className="mb-1.25">
                {pluralize("Point", point.scale)}
              </Typography>
              <Typography className="first-letter-capitalize">
                {point.comments}
              </Typography>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CriteriaWiseScore
