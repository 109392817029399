import { toast, Typography } from "@suraasa/placebo-ui"
import { Button, Tag, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Attempt, AttemptStatus } from "api/resources/assessments/types"
import HandHoldingPen from "assets/Fallback/hand-holding-pen.svg"
import Monitor from "assets/Fallback/monitor.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import SlidingSheet from "components/SlidingSheet"
import TruncatedText from "components/TruncatedText"
import { format } from "date-fns"
import CheckRestrictions, {
  getRestriction,
} from "features/AccessManagement/CheckRestrictions"
import {
  reloadOnAssessmentClose,
  startAssessment,
} from "features/Assessments/helpers"
import ReattemptDialog from "features/AssignmentResult/ReattemptDialog"
import AttemptCard from "features/LearningItems/Qualification/Assessments/AttemptCard"
import { getTagStyle } from "features/LearningItems/Qualification/Assessments/helpers"
import { Book, Calendar, Lock, Xmark } from "iconoir-react"
import { useState } from "react"
import { useTheme } from "react-jss"

import { useCourseDetails } from ".."

const CourseAssessments = () => {
  const [openReattemptDialog, setOpenReattemptDialog] = useState<string | null>(
    null
  )
  const [selectedAssessmentId, setSelectedAssessmentId] = useState<
    null | string
  >(null)
  const [openAttemptsSheet, setOpenAttemptsSheet] = useState(false)
  const [attemptsData, setAttemptsData] = useState<Attempt[]>([])

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const { course } = useCourseDetails()

  const {
    data: schedules,
    isLoading: isAssessmentsLoading,
    isError: isAssessmentsError,
  } = useQuery({
    enabled: Boolean(course.id),
    queryFn: () =>
      api.assessments.listAssessmentsSchedules({
        urlParams: {
          itemId: course.id,
          itemType: "course",
        },
      }),
    queryKey: queries.assessments.assessmentList(course.id).queryKey,
  })

  const listAttempts = useMutation({
    mutationFn: (assessmentId: string) =>
      api.assessments.listAttempts({
        urlParams: { assessmentId },
      }),
    onSuccess: data => {
      handleAction(data)
    },
    onError: () => {
      toast.error("Unable to view assessment.")
      setSelectedAssessmentId(null)
    },
  })

  /**
   * if user has already passed or failed the test then it will show the result
   * else the user will be navigated to the instructions page of assessment
   */
  const handleAction = (attemptsData: Attempt[]) => {
    setAttemptsData(attemptsData)
    /**
     * if attempt is Not started & attempt is not missed or attempt is in progress
     * then only we start assessment otherwise we open the sidebar
     */
    if (attemptsData.length === 1) {
      if (
        (attemptsData[0].status === AttemptStatus.NOT_STARTED &&
          attemptsData[0].assessmentSchedule?.endTime &&
          new Date(attemptsData[0].assessmentSchedule?.endTime) > new Date()) ||
        attemptsData[0].status === AttemptStatus.IN_PROGRESS
      ) {
        startAssessment({
          assessmentId: attemptsData[0].assessment.uuid,
          onClose: reloadOnAssessmentClose,
        })
        return
      }
    }
    setOpenAttemptsSheet(true)
  }

  if (isAssessmentsLoading || isAssessmentsError) {
    return (
      <div className="pb-3">
        <Typography variant="title3" className="mb-2">
          Assessments
        </Typography>
        <Fallback
          data={{
            title: "Something went wrong",
            description: "Please contact care@suraasa.com",
            image: Monitor,
          }}
          className="pt-4"
          hasError={isAssessmentsError}
          isLoading={isAssessmentsLoading}
        />
      </div>
    )
  }

  const currentAssessment = schedules.find(
    schedule => schedule.assessment.uuid === selectedAssessmentId
  )

  return (
    <>
      <ReattemptDialog
        open={Boolean(openReattemptDialog)}
        title={openReattemptDialog || ""}
        type="assessment"
        onClose={() => {
          setOpenReattemptDialog(null)
        }}
      />
      <div className="pb-3">
        <Typography variant="title3" className="my-2">
          Assessments
        </Typography>

        {schedules.length > 0 ? (
          <ItemList>
            {schedules.map(schedule => {
              const tagStyle = getTagStyle({
                status: schedule.attempt?.status,
                startTime: schedule.startTime,
                endTime: schedule.endTime,
                result: schedule.attempt?.result,
              })
              const assessmentId = schedule.assessment.uuid

              return (
                <ItemRow
                  key={schedule.id}
                  onClick={(() => {
                    const isRestricted = getRestriction(schedule)
                    if (isRestricted || isXs) return undefined

                    return () => {
                      setSelectedAssessmentId(assessmentId)
                      listAttempts.mutate(assessmentId)
                    }
                  })()}
                  infoSlot={
                    <div>
                      <div className="mb-1 flex items-center">
                        <Typography variant="strong" className="me-1">
                          {schedule.assessment.title}
                        </Typography>
                        <Tag
                          size="sm"
                          className="shrink-0"
                          label={tagStyle.label}
                          color={tagStyle.color}
                        />
                      </div>
                      <span className="flex flex-col items-start text-secondary-600">
                        {schedule && schedule.startTime && schedule.endTime && (
                          <span className="me-2 mt-0.5 flex flex-nowrap items-center sm:mt-0">
                            <Calendar className="me-1" fontSize={15} />
                            <Typography
                              variant="strongSmallBody"
                              className="text-clip"
                            >
                              {format(
                                new Date(schedule.startTime),
                                "dd LLL yyyy, p"
                              )}{" "}
                              -{" "}
                              {format(
                                new Date(schedule.endTime),
                                "dd LLL yyyy, p"
                              )}
                            </Typography>
                          </span>
                        )}
                        <span className="me-2 mt-0.5 flex flex-nowrap items-center sm:mt-0">
                          <Book className="me-1" fontSize={15} />
                          <TruncatedText
                            maxLength={50}
                            hideActionButton
                            variant="strongSmallBody"
                            className="text-clip"
                          >
                            {schedule.learningItem.name}
                          </TruncatedText>
                        </span>
                      </span>
                    </div>
                  }
                  actionSlot={
                    <div className="flex items-center gap-1">
                      {(tagStyle.label === "Missed" ||
                        tagStyle.label === "Failed") && (
                        <Button
                          variant="text"
                          onClick={(e: { stopPropagation: () => void }) => {
                            e.stopPropagation()
                            setOpenReattemptDialog(schedule.assessment.title)
                          }}
                        >
                          Request Reattempt
                        </Button>
                      )}
                      <CheckRestrictions
                        accessStatus={schedule.accessStatus}
                        render={({ isRestricted }) => {
                          if (isRestricted) return <Lock />
                          return (
                            <span className={clsx({ "self-end": isXs })}>
                              <Button
                                variant="text"
                                label="View"
                                loading={
                                  listAttempts.isLoading &&
                                  selectedAssessmentId === assessmentId
                                }
                                onClick={() => {
                                  setSelectedAssessmentId(assessmentId)
                                  listAttempts.mutate(assessmentId)
                                }}
                              />
                            </span>
                          )
                        }}
                      />
                    </div>
                  }
                  className="py-2"
                />
              )
            })}
          </ItemList>
        ) : (
          <Fallback
            data={{
              title: "No assessments available",
              description:
                "Make progress in your courses to unlock assessments!",
              image: HandHoldingPen,
            }}
            className="my-9 sm:my-20 md:my-18"
          />
        )}

        <SlidingSheet
          from="end"
          // isDismissible={false}
          open={openAttemptsSheet}
          onClose={() => {
            setOpenAttemptsSheet(false)
            setSelectedAssessmentId(null)
          }}
        >
          <div className="p-3">
            <Button
              variant="text"
              color="black"
              startAdornment={<Xmark />}
              onClick={() => {
                setOpenAttemptsSheet(false)
                setSelectedAssessmentId(null)
              }}
              className="mb-3"
            >
              Close
            </Button>
            {currentAssessment && (
              <Typography variant="title3" className="mb-2">
                {currentAssessment.assessment.title}
              </Typography>
            )}
            <div className="flex flex-col">
              {attemptsData.length > 0 ? (
                attemptsData.map((attempt, index) => (
                  <AttemptCard
                    key={index}
                    attempt={attempt}
                    attemptNumber={attemptsData.length - index}
                  />
                ))
              ) : (
                <Typography
                  variant="body"
                  className="mt-2 rounded-lg border border-critical-500 bg-critical-50 p-1"
                >
                  No attempts available.
                  <br />
                  Please contact care@suraasa.com.
                </Typography>
              )}
            </div>
          </div>
        </SlidingSheet>
      </div>
    </>
  )
}

export default CourseAssessments
