import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import AsyncBuilder from "components/AsyncBuilder"
import React, { useState } from "react"

type Props = {
  children: React.ReactNode
  /**
   * Must be an element that accepts `ref` and `onClick` props.
   */
  trigger: React.ReactNode
  title: string
  actionLabel?: string
  onRemove: () => Promise<void>
}

const RemoveDialogShad = ({
  children,
  title,
  actionLabel = "Remove",
  onRemove,
  trigger,
}: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent
        closeWhenInteractOutside
        className="max-w-[min(95%,24rem)]"
      >
        <DialogBody className="p-2.5">
          <DialogTitle className="mb-1">
            <Typography variant="title3" className="leading-tight">
              {title}
            </Typography>
          </DialogTitle>

          <DialogDescription>
            <>{children}</>
          </DialogDescription>

          <div className="mt-2.5 flex justify-end gap-1">
            <DialogClose>
              <Button variant="outlined" color="black" size="sm">
                Cancel
              </Button>
            </DialogClose>
            <AsyncBuilder
              handler={async () => {
                await onRemove()
                setOpen(false)
              }}
              render={props => (
                <Button color="critical" size="sm" {...props}>
                  {actionLabel}
                </Button>
              )}
            />
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default RemoveDialogShad
