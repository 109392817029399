import { CoverStyle } from "api/resources/profile/types"
import { useSearchParams } from "react-router-dom"
import { importantSearchParams, Platforms } from "utils/constants"

export const DEFAULT_PROFILE_COVER = CoverStyle.DUSK

export const Settings = {
  preferredLanguage: "preferred-language",
  pushNotifications: "push-notifications",
  emailNotifications: "email-notifications",
  eventBasedEmails: "event-based-emails",
  promotionalEmails: "promotional-emails",
} as const

export enum ProfileQuickAccessTabs {
  about = "About",
  resume = "Resume",
  educationWithSuraasa = "Education with Suraasa",
  skillPortfolio = "Skill Portfolio",
  otherDetails = "Other Details",
}

export const getCoverImageURL = (style?: CoverStyle, isXs = false) => {
  if (!style) {
    return undefined
  }

  let baseUrl = "https://assets.suraasa.com/lms/profile-assets/cover-images/"

  if (isXs) {
    baseUrl += "mobile/"
  }

  return `${baseUrl}${style}.webp`
}

/**
 * This hook caters to two features.
 * 1. A learner is 'previewing' how their profile will look to a school. That is controlled by `preview` param.
 * Learner has the ability to exit out of this preview mode and go back to viewing their profile normally.
 *
 * 2. A School / Centre Admin (or any other platform that might get this access in the future) is viewing learner's profile.
 * This is controlled by the `importantSearchParams.platformOverride` param.
 * This mode cannot be exited. The user will just have to close the tab.
 */
export const usePreviewMode = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const previewMode = searchParams.get("preview") === "true"

  /**
   * If this is true, then user can't exit the preview mode
   */
  const isViewingAsOtherPlatform = platformsWhichCanViewLearnerProfile.includes(
    searchParams.get(
      importantSearchParams.platformOverride
    ) as PlatformsWhichCanViewLearnerProfile
  )

  const setPreviewMode = (enable: boolean) => {
    if (enable) {
      searchParams.set("preview", "true")
    } else {
      searchParams.delete("preview")
    }

    setSearchParams(searchParams, { replace: true })
  }

  return { previewMode, setPreviewMode, isViewingAsOtherPlatform }
}

const platformsWhichCanViewLearnerProfile: PlatformsWhichCanViewLearnerProfile[] =
  [Platforms.school, Platforms.centerAdmin]

export type PlatformsWhichCanViewLearnerProfile =
  | Platforms.school
  | Platforms.centerAdmin
