import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("global", {
  languageList: () => ({
    queryKey: ["languages"],
  }),
  subjectList: () => ({
    queryKey: ["subjects"],
  }),
  countryList: () => ({
    queryKey: ["countries"],
  }),
  currencyList: () => ({
    queryKey: ["currencies"],
  }),
  curriculumList: () => ({
    queryKey: ["curriculum"],
  }),
})
