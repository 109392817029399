import { Sheet, SheetBody, SheetContent, Typography } from "@suraasa/placebo-ui"
import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import LoadingOverlay from "components/LoadingOverlay"
import { Page, Xmark } from "iconoir-react"
import React, { useState } from "react"
import { useTheme } from "react-jss"
import { formatDate } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

const SelectLessonPlanSheet = ({
  open = false,
  onOpenChange = () => {},
  onSelect,
}: React.PropsWithChildren<{
  open?: boolean
  onOpenChange?: (open: boolean) => void
  id?: string
  onSelect: (item: ListPromptHistory) => void
}>) => {
  const theme = useTheme<Theme>()

  const [selected, setSelected] = useState<ListPromptHistory | null>(null)

  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const { isFetching, isSuccess, hasNextPage, fetchNextPage, data, isLoading } =
    useInfiniteQuery({
      queryKey: queries.aiTools.listHistory(ToolType.lessonPlan).queryKey,
      queryFn: x =>
        api.aiTools.listHistory({
          type: ToolType.lessonPlan,
          params: { page: x.pageParam || 1, is_pro: false },
        }),
      onError: err => {
        if (err instanceof APIError) {
          console.log(err.statusCode)
          console.log(err.is500)
          console.log(err.__errors)
        }
      },

      getNextPageParam: lastPage => {
        return lastPage.nextPage ?? undefined
      },
      enabled: open,
    })

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage,
    actionFunc: fetchNextPage,
  })

  const allData = data?.pages.flatMap(page => page.data)

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side={isSmDown ? "bottom" : "right"}
        className="bg-white p-0"
        height={95}
      >
        <SheetBody className="relative h-full rounded-3xl px-2 py-1.5">
          <Button
            nudge="left"
            variant="text"
            startAdornment={<Xmark />}
            onClick={() => onOpenChange(false)}
          >
            Close
          </Button>
          <Typography variant="title3" className="my-3">
            Select a Lesson Plan
          </Typography>

          {isLoading && (
            <div className="flex flex-col items-center justify-center p-5">
              <LoadingOverlay />
            </div>
          )}

          {isSuccess && allData && allData.length > 0 && (
            <div className="flex grow flex-col gap-2">
              {allData.map((item, i) => {
                const isLastItem = i === allData.length - 1

                return (
                  <div key={item.promptDataId} ref={isLastItem ? ref : null}>
                    <Item
                      isSelected={selected?.promptDataId === item.promptDataId}
                      item={item}
                      onClick={() => setSelected(item)}
                    />
                  </div>
                )
              })}
            </div>
          )}

          <Button
            className="sticky bottom-1 mt-3"
            variant="filled"
            fullWidth
            disabled={!selected}
            onClick={() => {
              selected && onSelect(selected)
              onOpenChange(false)
            }}
          >
            Done
          </Button>
        </SheetBody>
      </SheetContent>
    </Sheet>
  )
}

export default SelectLessonPlanSheet

const Item = ({
  item,
  isSelected,
  onClick,
}: {
  onClick: VoidFunction
  item: ListPromptHistory
  isSelected?: boolean
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "duration flex w-full items-center rounded-xl border border-solid border-surface-100 px-2 py-2.25 text-start transition-colors",
        {
          "bg-onSurface-900 text-white": isSelected,
        }
      )}
    >
      <div
        className={clsx(
          "mr-1.5 flex size-6 shrink-0 items-center justify-center rounded-xl border-[0.75px] border-solid border-decorative-one-100 bg-decorative-one-50"
        )}
      >
        <Page className="size-3 text-decorative-one-100" />
      </div>
      <div>
        <Typography
          variant="title4"
          className={clsx("mb-0.5", {
            "text-white": isSelected,
          })}
        >
          {item.title}
        </Typography>
        <Typography
          variant="smallBody"
          className={clsx({
            "text-onSurface-300": isSelected,
          })}
        >
          {formatDate(item.dateCreated)}
        </Typography>
      </div>
    </button>
  )
}
