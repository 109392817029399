import { Typography } from "@suraasa/placebo-ui"
import React from "react"

const Introduction = () => (
  <div className="mt-4">
    <Typography className="text-common-black-700">
      We are elated to announce one of the most awaited events of the year, the
      Teacher Impact Awards 2024!
      <br />
      <br />
      Furthering Suraasa&apos;s vision of empowering and celebrating teachers,
      the Teacher Impact Awards recognizes the impeccable impact that teachers
      like you create in and outside the classroom every day.
      <br />
      <br />
      Share your story of hard work and resilience that helped you grow, helped
      you get a wave of job offers, helped you get that promotion, helped you
      guide that struggling student and put a smile on their parents&apos;
      faces.
    </Typography>
    <br />
  </div>
)

export default Introduction
