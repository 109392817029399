import { theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import SlidingSheet from "components/SlidingSheet"
import { useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { getAuthInfo } from "utils/helpers"

import styles from "./ClassFeedbackCard.module.css"
import Step1 from "./Step1"
import Step2 from "./Step2"

const duration = 300

type Props = {
  itemId: number | string
  itemName: string
}

const ClassFeedbackCard = ({ itemName, itemId }: Props) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const [show, setShow] = useState(false)

  const [cardAnimation, setCardAnimation] = useState(false)
  const [step, setStep] = useState<"step1" | "step2" | null>("step1")

  const toggleCardAnimation = (enabled: boolean) => {
    setCardAnimation(enabled)
    setStep(null)
  }

  const onClose = () => {
    setShow(false)
  }

  const onDismiss = () => {
    onClose()
  }

  useEffect(() => {
    const auth = getAuthInfo()
    if (auth?.shadow) return

    if (sessionStorage.getItem("preventFeedbackPopups") === "true") {
      return
    }

    setShow(true)
  }, [itemId])

  if (!show) return null

  if (sessionStorage.getItem("preventFeedbackPopups") === "true") return null

  if (isXsDown) {
    return (
      <SlidingSheet
        className={clsx(styles.slidingSheetContainer, {
          [styles.step1]: step === "step1",
          [styles.step2]: step === "step2",
        })}
        open
        isDismissible={false}
        onClose={onClose}
      >
        <>
          {step === "step1" && (
            <Step1
              title={itemName}
              onClickRateCourse={() => setStep("step2")}
              onClickNotNow={onDismiss}
            />
          )}
          {step === "step2" && <Step2 onClose={onClose} itemId={itemId} />}
        </>
      </SlidingSheet>
    )
  }
  return (
    <div className="fixed bottom-8 right-1 z-20 md:bottom-2 md:right-2">
      <div className="relative">
        <CSSTransition
          in={cardAnimation}
          timeout={duration}
          classNames={{
            enterActive: styles.opacityEnter,
            enterDone: styles.opacityEnterActive,
            exitDone: styles.opacityExit,
            exitActive: styles.opacityExitActive,
          }}
          onEntered={() => setStep("step2")}
          onExited={() => setStep("step1")}
        >
          <div
            className={clsx("absolute bottom-0 right-0", styles.verticalCard, {
              [styles.horizontalCard]: cardAnimation,
            })}
          />
        </CSSTransition>

        {step === "step1" && (
          <Step1
            title={itemName}
            onClickRateCourse={() => toggleCardAnimation(true)}
            onClickNotNow={onClose}
          />
        )}
        {step === "step2" && <Step2 onClose={onClose} itemId={itemId} />}
      </div>
    </div>
  )
}

export default ClassFeedbackCard
