import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getSiriusURL = getServiceURL(SERVICE_PREFIX.sirius)

export const urls = validateUrls({
  applicationOverview: () =>
    getSiriusURL("/v1/applications/overview/applicant/"),

  profiles: () => getSiriusURL("/v1/profiles/"),
  getProduct: slug => getSiriusURL(`/v1/questionnaires/products/${slug}/`),
  academicEvidence: () => getSiriusURL("/v1/profiles/certificates/"),
})
