export const ryanSchoolsList = [
  {
    name: "Ryan Central Office",
    board: "",
  },
  {
    name: "Ryan International School CBSE Kandivli",
    board: "CBSE",
  },
  {
    name: "Cambridge School Kandivali",
    board: "ICSE",
  },
  {
    name: "Ryan International School Nallasopara",
    board: "CBSE",
  },
  {
    name: "Ryan International School Goregaon",
    board: "ICSE",
  },
  {
    name: "Ryan International School Malad (ICSE)",
    board: "ICSE",
  },
  {
    name: "Ryan International School Malad (CBSE)",
    board: "CBSE",
  },
  {
    name: "Ryan Christan School Borivali",
    board: "ICSE",
  },
  {
    name: "Ryan International School Ambernath",
    board: "CBSE",
  },
  {
    name: "Ryan International School Sanpada",
    board: "CBSE",
  },
  {
    name: "St Joseph's High School New Panvel",
    board: "CBSE",
  },
  {
    name: "Ryan Christan School Vashi",
    board: "ICSE",
  },
  {
    name: "Ryan International School Nerul",
    board: "ICSE",
  },
  {
    name: "Ryan International School Chembur",
    board: "ICSE",
  },
  {
    name: "Ryan International School Kharghar",
    board: "ICSE",
  },
  {
    name: "RSM Andheri",
    board: "ICSE",
  },
  {
    name: "RSM Kamothe",
    board: "",
  },
  {
    name: "RSM Ulwe",
    board: "ICSE",
  },
  {
    name: "Ryan International School Bavdhan Pune",
    board: "ICSE",
  },
  {
    name: "Ryan International School Kundalahalli",
    board: "ICSE",
  },
  {
    name: "Ryan International School Yelahanka - ICSE",
    board: "ICSE",
  },
  {
    name: "Ryan International School Yelahanka - CBSE",
    board: "CBSE",
  },
  {
    name: "Ryan International School Bannerghatta - ICSE",
    board: "ICSE",
  },
  {
    name: "Ryan International School Bannergatta - CBSE",
    board: "CBSE",
  },
  {
    name: "Ryan International School Cochin",
    board: "CBSE",
  },
  {
    name: "Ryan International School Sriperumbudur",
    board: "CBSE",
  },
  {
    name: "Ryan International School Mangalore",
    board: "ICSE",
  },
  {
    name: "Ryan International School Dumas Surat - ICSE",
    board: "ICSE",
  },
  {
    name: "Ryan International School Dumas - CBSE",
    board: "CBSE",
  },
  {
    name: "Ryan International School Bardoli",
    board: "ICSE",
  },
  {
    name: "Ryan International School Adajan",
    board: "CBSE",
  },
  {
    name: "St Xaviers High School Vapi",
    board: "ICSE",
  },
  {
    name: "Ryan International School Masma",
    board: "CBSE",
  },
  {
    name: "Ryan International School Ojhar",
    board: "CBSE",
  },
  {
    name: "Ryan International School Nasik",
    board: "ICSE",
  },
  {
    name: "Ryan International School Aurangabad",
    board: "ICSE",
  },
  {
    name: "Ryan International School CBSE Jalna",
    board: "CBSE",
  },
  {
    name: "St Xaviers High School Midc Nagpur",
    board: "CBSE",
  },
  {
    name: "St Xaviers High School Hiwri Nagar",
    board: "CBSE",
  },
  {
    name: "St Xaviers High School Gondia",
    board: "CBSE",
  },
  {
    name: "St Xaviers High School Raipur",
    board: "ICSE",
  },
  {
    name: "St Xaviers High School Durg",
    board: "ICSE",
  },
  {
    name: "St Xaviers High School Dhamtari",
    board: "ICSE",
  },
  {
    name: "Ryan International School Raipur",
    board: "CBSE",
  },
  {
    name: "St Xaviers High School Shantinagar Jabalpur",
    board: "ICSE",
  },
  {
    name: "St Xaviers High School Gupteshwar Jabalpur",
    board: "ICSE",
  },
  {
    name: "Ryan International School Shantinagar",
    board: "CBSE",
  },
  {
    name: "Ryan International School Indore",
    board: "CBSE",
  },
  {
    name: "Ryan International School Bhopal",
    board: "CBSE",
  },
  {
    name: "Ryan International School Raebareli",
    board: "ICSE",
  },
  {
    name: "St. Xaviers Kindergarden School - SSC Ajni",
    board: "SSC",
  },
  {
    name: "Ryan International School Sohna Road",
    board: "CBSE",
  },
  {
    name: "Ryan International School Vasant Kunj",
    board: "CBSE",
  },
  {
    name: "Ryan International School Mayur Vihar",
    board: "CBSE",
  },
  {
    name: "Ryan International School Rohini Sector-25",
    board: "CBSE",
  },
  {
    name: "Ryan International School Rohini Sec-11 G2",
    board: "CBSE",
  },
  {
    name: "Ryan International School Rohini H3 Sector-11",
    board: "CBSE",
  },
  {
    name: "Ryan International School Gurgaon Sector-40",
    board: "CBSE",
  },
  {
    name: "Ryan International School Faridabad",
    board: "CBSE",
  },
  {
    name: "Ryan International School Noida",
    board: "CBSE",
  },
  {
    name: "Ryan International School Noida Extension",
    board: "CBSE",
  },
  {
    name: "Ryan International School Ghaziabad",
    board: "CBSE",
  },
  {
    name: "Ryan International School Greater Noida",
    board: "CBSE",
  },
  {
    name: "Ryan International School Gurgaon Sector-31",
    board: "CBSE",
  },
  {
    name: "Ryan International School Shahjahanpur",
    board: "CBSE",
  },
  {
    name: "Ryan International School Bolpur Birbhum",
    board: "CBSE",
  },
  {
    name: "RSM Mayur Vihar",
    board: "CBSE",
  },
  {
    name: "RSM Rohini Sec 16",
    board: "CBSE",
  },
  {
    name: "RSM Sec 40 Gurugram",
    board: "CBSE",
  },
  {
    name: "RSM Preet Vihar",
    board: "CBSE",
  },
  {
    name: "Ryan International School Chandigarh",
    board: "CBSE",
  },
  {
    name: "Ryan International School Patiala",
    board: "CBSE",
  },
  {
    name: "Ryan International School Amritsar",
    board: "CBSE",
  },
  {
    name: "Ryan International School Ludhiana",
    board: "CBSE",
  },
  {
    name: "Ryan International School Dugri",
    board: "CBSE",
  },
  {
    name: "Ryan International School Mohali",
    board: "CBSE",
  },
  {
    name: "Ryan International School Jalandhar",
    board: "CBSE",
  },
  {
    name: "Ryan International School Nakodar Jalandhar",
    board: "CBSE",
  },
  {
    name: "Ryan International School Jaipur Padmavati",
    board: "CBSE",
  },
  {
    name: "Ryan International School Jaipur V T Road Mansarovar",
    board: "CBSE",
  },
  {
    name: "Ryan International School Udaipur",
    board: "CBSE",
  },
  {
    name: "Ryan International School Ajmer",
    board: "CBSE",
  },
  {
    name: "Ryan International School Jagatpura",
    board: "CBSE",
  },
  {
    name: "Ryan International School Bikaner",
    board: "CBSE",
  },
  {
    name: "Ryan International School Sharjah",
    board: "CBSE",
  },
  {
    name: "Ryan International School Masdar",
    board: "CBSE",
  },
]

export const ryanGroupIdentifier = "ryan-group"
