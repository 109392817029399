import { useCallback, useEffect, useState } from "react"
import { useStopwatch } from "react-timer-hook"

import RecordPronunciation from "../RecordPronunciation"
import TextPreview from "../TextPreview"

type Props = {
  text: string
  preRecordedFile: string | null
  additionalDescription?: string | null
  type: "sentence" | "words"
  onRecordSuccess: (blobUrl: { mpeg: string; wav: string }) => void
  hideWaveForm?: boolean
}

const RECORDING_START_DELAY = 3 // in seconds
const PER_WORD_RECORD_DURATION = 2 // in seconds

export type AudioStateType = "play" | "pause" | "ended" | null

const PronunciationComponent = ({
  onRecordSuccess,
  additionalDescription,
  text,
  preRecordedFile,
  type,
  hideWaveForm,
}: Props) => {
  const { seconds, start, pause, reset, isRunning } = useStopwatch({
    autoStart: false,
  })

  useEffect(() => {
    return () => {
      reset()
      pause()
    }
  }, [text])

  useEffect(() => {
    const totalWords = text.split(" ").length
    const totalDuration = totalWords * PER_WORD_RECORD_DURATION

    if (seconds === totalDuration && isRunning) {
      reset()
      pause()
    }
  }, [type, text, pause, isRunning, seconds, reset])

  const currentWordIndex = (() => {
    if (seconds < PER_WORD_RECORD_DURATION) return 0

    return Math.floor(seconds / PER_WORD_RECORD_DURATION)
  })()

  const onStart = useCallback(() => {
    if (isRunning) return
    start()
    setAudioState("pause")
    setDisableListenButton(true)
  }, [isRunning, start])

  const onStop = useCallback(
    (blobUrl: { mpeg: string; wav: string }) => {
      onRecordSuccess(blobUrl)
      reset()
      pause()
      onRecordSuccess(blobUrl)
      setDisableListenButton(false)
    },
    [onRecordSuccess]
  )

  const [audioState, setAudioState] = useState<AudioStateType>("ended")

  const [disableListenButton, setDisableListenButton] = useState(false)

  const enableGuidedRecording = type === "words"

  return (
    <>
      <TextPreview
        additionalDescription={additionalDescription}
        type={type}
        highlightIndex={
          isRunning && enableGuidedRecording ? [currentWordIndex] : []
        }
        preRecordedFile={preRecordedFile}
        text={text}
        variant="primary"
        audioState={audioState}
        disableListenButton={disableListenButton}
        setAudioState={setAudioState}
      />
      <div className="mt-2">
        <RecordPronunciation
          perWordRecordDuration={PER_WORD_RECORD_DURATION}
          startRecordingDelay={RECORDING_START_DELAY}
          text={text}
          onRecordingStart={onStart}
          onRecordingStop={onStop}
          enableTimer={enableGuidedRecording}
          hideWaveForm={hideWaveForm}
        />
      </div>
    </>
  )
}

export default PronunciationComponent
