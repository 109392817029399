import { Typography } from "@suraasa/placebo-ui"
import { Button, IconButton, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { CoreAreaSlug, ITOReport } from "api/resources/ito/reports/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import { Download } from "iconoir-react"
import React from "react"
import { downloadFile, handleErrors, saveBlobAsFile } from "utils/helpers"
import { trackingService } from "utils/tracking"

import styles from "./DomainReports.module.css"
import NotYetReady from "./NotYetReady"

const getAssets = (string?: string) => {
  return string
    ? {
        web: `/assets/ito/2023/AfterResultPhase/OverallReport/web/${string}.webp`,
        mobile: `/assets/ito/2023/AfterResultPhase/OverallReport/mobile/${string}.webp`,
        smallMobile: `/assets/ito/2023/AfterResultPhase/OverallReport/smallMobile/${string}.png`,
      }
    : undefined
}

const handleDownload = (
  e: { stopPropagation: () => void },
  pdf?: string | null
) => {
  e.stopPropagation()
  if (pdf) downloadFile(pdf, "Report.pdf")
}
const openCertificate = (pdf: string | null, domain: string | undefined) => {
  trackingService.trackEvent(
    "element_clicked",
    {
      meta: {
        element: "report_tile",
        domain: domain,
      },
      text: "",
      intent: "download_domain_report",
      page_url: window.location.href,
    },
    ["WebEngage"]
  )
  if (pdf) window.open(pdf, "_blank")
}

const WebCard = ({ data }: { data: ITOReport }) => {
  return (
    <button
      className={clsx(
        "relative max-h-[261px] overflow-hidden rounded-xl shadow-[0px_8px_25px_0px_rgba(0,0,0,0.15)]",
        styles.container
      )}
      onClick={() => {
        openCertificate(data.pdf, data.coreArea?.slug)
      }}
    >
      <img
        src={getAssets(data.coreArea?.slug)?.web}
        alt={`domain-${data.id}`}
        className="size-full"
      />

      <Button
        fullWidth
        className={clsx(
          "absolute bottom-0 left-0 w-full bg-onSurface-500",
          styles.downloadButton
        )}
        onClick={(e: { stopPropagation: () => void }) => {
          handleDownload(e, data.pdf)
        }}
      >
        Download
      </Button>
    </button>
  )
}

const MobileCard = ({ data }: { data: ITOReport }) => {
  const themeMapping = {
    [CoreAreaSlug.CURRICULUM_PEDAGOGY_TECH_REPORT]: "!text-ito-peach-600",
    [CoreAreaSlug.STUDENT_ENGAGEMENT_REPORT]: "!text-ito-mint-600",
    [CoreAreaSlug.LEARNING_ENVIRONMENT_REPORT]: "!text-ito-iris-600",
    [CoreAreaSlug.LEARNING_ASSESSMENT_DATA_REPORT]: "!text-ito-lavender-600",
    [CoreAreaSlug.ETHICS_VALUES_REPORT]: "!text-ito-bubblegum-600",
    [CoreAreaSlug.TEACHER_GROWTH_AND_PROFESSIONAL_DEVELOPMENT]:
      "!text-ito-salmon-600",
  }
  return (
    <button
      className=" relative max-h-[261px] rounded-xl"
      onClick={() => {
        openCertificate(data.pdf, data.coreArea?.slug)
      }}
    >
      <img
        src={getAssets(data.coreArea?.slug)?.mobile}
        alt={`domain-${data.id}`}
        className="size-full"
      />

      <IconButton
        onClick={(e: { stopPropagation: () => void }) => {
          handleDownload(e, data.pdf)
        }}
        size="sm"
        className={clsx(
          "absolute right-1 top-1",
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          themeMapping[data.coreArea!.slug]
        )}
      >
        <Download />
      </IconButton>
    </button>
  )
}
const SmallMobileCard = ({ data }: { data: ITOReport }) => {
  const themeMapping = {
    [CoreAreaSlug.CURRICULUM_PEDAGOGY_TECH_REPORT]:
      "bg-ito-peach-100 border-[#F7DCC2]",
    [CoreAreaSlug.STUDENT_ENGAGEMENT_REPORT]:
      "bg-ito-mint-100 border-[#BAF1CA]",
    [CoreAreaSlug.LEARNING_ENVIRONMENT_REPORT]:
      "bg-ito-iris-100 border-[#AFC9FA]",
    [CoreAreaSlug.LEARNING_ASSESSMENT_DATA_REPORT]:
      "bg-ito-lavender-100 border-[#D2C2FB]",
    [CoreAreaSlug.ETHICS_VALUES_REPORT]:
      "bg-ito-bubblegum-100 border-[#F7CDEF]",
    [CoreAreaSlug.TEACHER_GROWTH_AND_PROFESSIONAL_DEVELOPMENT]:
      "bg-ito-salmon-100 border-[#F5BFBD]",
  }
  return (
    <button
      onClick={() => {
        openCertificate(data.pdf, data.coreArea?.slug)
      }}
      className="flex w-full items-center justify-between rounded border border-surface-100 bg-surface-50 px-2 py-1"
    >
      <div className="flex items-center">
        <div
          className={clsx(
            "mr-0.75 flex size-[60px] shrink-0 items-center justify-center rounded-lg border-2 border-solid",
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            themeMapping[data.coreArea!.slug]
          )}
        >
          <img
            src={getAssets(data.coreArea?.slug)?.smallMobile}
            className="h-[40.959px] w-[38.175px] shrink-0"
            alt=""
          />
        </div>
        <Typography className="text-start" variant="strong">
          {data.coreArea?.name}
        </Typography>
      </div>
      <IconButton
        onClick={(e: { stopPropagation: () => void }) => {
          handleDownload(e, data.pdf)
        }}
      >
        <Download width={20} height={20} className="text-interactive-500" />
      </IconButton>
    </button>
  )
}

const DomainReports = ({ data }: { data: ITOReport[] }) => {
  const isAvailable = data.length === 0
  const isCustomDown = useMediaQuery(
    "@media only screen and (max-width: 320px)"
  )

  const { mutate: downloadReports, isLoading } = useMutation({
    mutationFn: () =>
      api.profile.credentials.download({
        urlParams: {
          itemType: "olympiad_report",
          itemSlug: "international-teacher-olympiad-2023",
        },
      }),
    onSuccess: res => {
      if (res.__data) {
        saveBlobAsFile({
          data: res.__data?.data,
          type: "application/zip",
          name: `Reports.zip`,
        })
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const downloadAllReports = () => {
    trackingService.trackEvent(
      "element_clicked",
      {
        text: "Download All Plans",
        intent: "download_all_reports",
        page_url: window.location.href,
      },
      ["WebEngage"]
    )
    downloadReports()
  }

  if (isAvailable) {
    return <NotYetReady />
  }

  return (
    <div>
      <div className="mb-5">
        <div className="mb-1 flex items-start justify-between">
          <Typography variant="title2" className="mr-1">
            Your Personalised Development Plans
          </Typography>
          {!isAvailable && (
            <Button
              size="sm"
              onClick={downloadAllReports}
              rounded
              startAdornment={<Download />}
              loading={isLoading}
              className="!hidden sm:!inline-block"
            >
              Download All Plans
            </Button>
          )}
        </div>
        <Typography className="max-w-[717px] text-onSurface-500">
          Your monthly growth plans contain guidance and resources to help you
          achieve excellence in each pedagogy area. While you have access to all
          of your plans now, we will be focusing on one pedagogy area each month
          over the next 6 months. November will be focused on Curriculum,
          Pedagogy, & Technology.
        </Typography>
      </div>

      {isAvailable ? (
        <Typography variant="title2" className="text-center">
          Your personalised reports are arriving here soon!
        </Typography>
      ) : (
        <>
          <div className="hidden grid-cols-[repeat(3,minmax(0,185px))] justify-center gap-3 sm:grid">
            {data
              .sort((a, b) =>
                new Date(a.dateVisibleFrom) > new Date(b.dateVisibleFrom)
                  ? 1
                  : -1
              )
              .map((item, index) => (
                <WebCard data={item} key={index} />
              ))}
          </div>

          {!isCustomDown && (
            <div className="grid grid-cols-[repeat(3,minmax(0,185px))] justify-center gap-1 sm:hidden">
              {data
                .sort((a, b) =>
                  new Date(a.dateVisibleFrom) > new Date(b.dateVisibleFrom)
                    ? 1
                    : -1
                )
                .map((item, index) => (
                  <MobileCard data={item} key={index} />
                ))}
            </div>
          )}

          {isCustomDown && (
            <div className="flex flex-col space-y-2 sm:hidden">
              {data
                .sort((a, b) =>
                  new Date(a.dateVisibleFrom) > new Date(b.dateVisibleFrom)
                    ? 1
                    : -1
                )
                .map((item, index) => (
                  <SmallMobileCard data={item} key={index} />
                ))}
            </div>
          )}
        </>
      )}

      {!isAvailable && (
        <Button
          rounded
          startAdornment={<Download />}
          className="mt-6 !inline-block sm:!hidden"
          fullWidth
          onClick={downloadAllReports}
          loading={isLoading}
        >
          Download All Plans
        </Button>
      )}
    </div>
  )
}

export default DomainReports
