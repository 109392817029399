import { Typography } from "@suraasa/placebo-ui"
import { theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import GradientButton from "features/ITO/components/GradientButton"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import React from "react"
import { createUseStyles } from "react-jss"
import { getPlatformURL } from "utils/helpers"

import CommunityPng from "./assets/community.png"
import CommunityMobPng from "./assets/community-mob.png"
import CommunityPeople from "./assets/community-people.png"

const useStyles = createUseStyles(theme => ({
  container: {
    backgroundColor: "rgb(255, 255, 255)",
    backgroundImage:
      "radial-gradient(at 100% 15%, rgba(84, 230, 144, 0.2) 0px, transparent 35%), radial-gradient(at 100% 15%, rgba(82, 149, 252, 0.5) 0px, transparent 35%)",
    backgroundSize: "100% 300%",
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "rgb(255, 255, 255)",
      backgroundImage:
        "radial-gradient(at 75% 0%, rgba(84, 230, 144, 0.1) 0px, transparent 35%), radial-gradient(at 75% 0%, rgba(82, 149, 252, 0.3) 0px, transparent 35%)",
      backgroundSize: "100% 300%",
    },
  },
}))
const CommunityCard = ({ className }: { className?: string }) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles()
  return (
    <div
      className={clsx(
        className,
        classes.container,
        "grid grid-cols-[auto] grid-rows-[auto_auto] items-center shadow-[1px_1px_5px_0px_rgba(0,0,0,0.10)] sm:grid-cols-[50%_50%] sm:grid-rows-[auto] sm:rounded-2xl"
      )}
    >
      <div className="px-2 py-3 sm:p-4">
        <SwitzerTypography
          className="mb-1 font-bold !text-ito-midnight"
          style={{
            fontFamily: "Switzer Bold",
          }}
          variant="title2"
        >
          Your Exclusive Access to ITO Community
        </SwitzerTypography>
        <SwitzerTypography
          className="mb-2 block w-full !text-ito-midnight sm:max-w-[349px]"
          variant="largeBody"
          style={{
            fontFamily: "Switzer Regular",
          }}
        >
          Join the International Teachers’ Chat Group to connect with Olympiad
          teacher registrants from different countries, and share your
          excitement for ITO 2023.
        </SwitzerTypography>

        <div className="my-2 flex flex-wrap items-center gap-1">
          <div className="relative mr-4">
            <img src={CommunityPeople} alt="people" className="max-h-6" />
            <div className="absolute right-[-30px] top-0 flex size-6 items-center justify-center rounded-full border-2  border-white bg-ito-iris-100">
              <Typography
                variant="preTitle"
                style={{
                  fontSize: 9,
                }}
              >
                21000+
              </Typography>
            </div>
          </div>
          <Typography variant="preTitle">TEACHERS ALREADY CHATTING</Typography>
        </div>

        <GradientButton
          label="Join Community"
          size="lg"
          component="a"
          target="_blank"
          href={getPlatformURL("suraasa", "/get-app")}
        />
        <Typography variant="smallBody" className="mt-1 text-onSurface-500">
          Access community on the Suraasa mobile app!
        </Typography>
      </div>
      <div className="relative row-[1] h-full bg-white sm:row-auto sm:bg-transparent">
        <img
          alt="linkedin-post"
          className="inset-x-0 bottom-0 mx-auto object-contain px-2 pt-0 sm:absolute sm:h-full sm:pt-3.5"
          src={isXsDown ? CommunityMobPng : CommunityPng}
        />
      </div>
    </div>
  )
}

export default CommunityCard
