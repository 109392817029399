import { Button } from "@suraasa/placebo-ui-legacy"
import { SkillEvidence } from "api/resources/profile/types"
import { useState } from "react"

import EvidenceSetting from "../EvidenceSetting"
import EvidenceList from "./EvidenceList"

const Evidences = ({
  skillEvidences,
  isSelf,
  courseSlug,
}: {
  courseSlug: string
  isSelf: boolean
  skillEvidences: SkillEvidence[]
}) => {
  const [editMode, setEditMode] = useState(false)
  const course = skillEvidences.find(item => item.slug === courseSlug)

  if (editMode) {
    return (
      <EvidenceSetting
        courseSlug={courseSlug}
        skillEvidences={skillEvidences}
        onClose={() => {
          setEditMode(false)
        }}
      />
    )
  }

  return (
    <>
      <EvidenceList
        canView
        data={
          (isSelf
            ? course?.evidences.filter(x => x.visibility === true)
            : course?.evidences) ?? []
        }
      />

      {isSelf && (
        <>
          <EvidenceList
            canView={isSelf}
            data={course?.evidences.filter(x => x.visibility === false) ?? []}
            isHiddenEvidence
          />

          <Button
            className="mx-auto"
            style={{
              display: "block",
            }}
            variant="text"
            onClick={() => {
              setEditMode(true)
            }}
          >
            Edit Visibility
          </Button>
        </>
      )}
    </>
  )
}

export default Evidences
