import { Typography } from "@suraasa/placebo-ui"
import { Container } from "@suraasa/placebo-ui-legacy"
import React from "react"
import { formatDate } from "utils/helpers"

type Props = {
  resultDate: Date
}

const GoodJob = ({ resultDate }: Props) => {
  return (
    <div className="border border-success-300 bg-ito-mint-700 ">
      <Container>
        <div className="flex flex-col items-center justify-between gap-2 rounded-xl py-2.5 sm:flex-row sm:gap-0 sm:py-1.5">
          <div className="w-full">
            <Typography variant="title2" className="text-surface-500">
              Good Job!
            </Typography>
            <Typography variant="largeBody" className="text-surface-500">
              You have successfully attempted the Olympiad.
            </Typography>
          </div>

          <div className="flex w-full flex-col items-start sm:items-end">
            <Typography variant="smallBody" className="text-surface-500">
              Olympiad result arriving on
            </Typography>
            <Typography variant="title4" className="text-surface-500">
              {formatDate(resultDate.toISOString(), "d MMMM, yyyy")}
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default GoodJob
