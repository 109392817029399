import { toast } from "@suraasa/placebo-ui"
import { CircularProgress } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import { getToolName } from "features/AItools/helper"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import Heading from "../../Heading"
import TogglePro from "../../TogglePro"
import { AIToolsContext } from "../context"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import QuizOutput from "../GenerationOutput/QuizOutput"
import BasicForm from "./BasicForm"
import ProForm from "./ProForm"

const QuizGenerator = ({
  id,
  onGeneration,
}: {
  id?: string
  onGeneration: (data: ListPromptHistory) => void
}) => {
  const navigate = useNavigate()

  const [enableProMode, setEnableProMode] = useState(false)

  const { currentResponseId, overviewData, promptDataId, setPromptDataId } =
    useContext(AIToolsContext)
  // const [data, setData] = useState<QuizResponse | null>(null)

  const content = useQuery({
    enabled: Boolean(currentResponseId),
    queryKey: queries.aiTools.retrieveHistory(ToolType.quiz, currentResponseId)
      .queryKey,
    queryFn: async () => {
      const res = await api.aiTools.retrieveContent<ToolType.quiz>({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { type: ToolType.quiz, id: currentResponseId! },
      })

      // validateQuizOutput(res.output)
      return res
    },
  })

  useEffect(() => {
    if (content.error instanceof APIError) {
      toast.error(content.error.message || "Something went wrong")
    }
  }, [content.error])

  if (id && content.isLoading) {
    return (
      <div className="flex flex-col items-center justify-center p-5">
        <CircularProgress />
      </div>
    )
  }

  if (content.data && promptDataId) {
    return (
      <>
        <ContentActions
          onBack={() => {
            navigate(routes.aiTools.quiz, { replace: true })
            setPromptDataId(null)
          }}
          currentResponseId={currentResponseId}
          toolType={ToolType.quiz}
          title={overviewData?.title}
        />
        <QuizOutput {...content.data} />
      </>
    )
  }

  return (
    <>
      <Heading
        title={`${getToolName(ToolType.quiz)} Generator`}
        subtitle="Engage and challenge your students with customised quizzes."
      />
      <TogglePro
        isPro={enableProMode}
        onChange={state => setEnableProMode(state)}
        className="mb-2"
      />
      {enableProMode ? (
        <ProForm
          onGeneration={res => {
            onGeneration({
              dateCreated: new Date().toString(),
              promptDataId: res.id,
              title: res.title,
              toolType: ToolType.quizPro,
            })
          }}
        />
      ) : (
        <BasicForm
          onGeneration={res => {
            onGeneration({
              dateCreated: new Date().toString(),
              promptDataId: res.id,
              title: res.title,
              toolType: ToolType.quizPro,
            })
          }}
        />
      )}
    </>
  )
}

export default QuizGenerator
