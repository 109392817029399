import { Typography } from "@suraasa/placebo-ui"
import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { Assignment } from "api/resources/assignments/types"
import SlidingSheet from "components/SlidingSheet"
import { Xmark } from "iconoir-react"
import { useTheme } from "react-jss"

type Props = {
  learningOutcomes: Assignment["assignmentAssessmentCriteria"]
  open: boolean
  onClose: () => void
}

const LearningOutcomesSheet = ({ learningOutcomes, open, onClose }: Props) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <SlidingSheet
      open={open}
      from="end"
      onClose={onClose}
      className="sm:w-[550px]"
      fullScreen={isXs}
    >
      <div className="p-2 sm:p-3">
        <Button
          variant="text"
          onClick={onClose}
          startAdornment={<Xmark />}
          color="black"
          size="sm"
          nudge="left"
        >
          Close
        </Button>
        <Typography variant="title3" className="mt-3">
          Assessment Criteria (AC) and Learning Outcomes (LO)
        </Typography>
        {learningOutcomes.map((data, index) => (
          <div
            key={index}
            style={{ boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)" }}
            className="mt-2 rounded-lg border border-surface-200 bg-common-white-500 p-2"
          >
            <div className="mb-1 flex items-start">
              <Typography variant="strong" className="me-1 shrink-0">
                AC
              </Typography>
              <Typography>{data.name}</Typography>
            </div>
            <div className="flex items-start">
              <Typography variant="strong" className="me-1 shrink-0">
                LO
              </Typography>
              <Typography>{data.learningOutcome.name}</Typography>
            </div>
          </div>
        ))}
      </div>
    </SlidingSheet>
  )
}

export default LearningOutcomesSheet
