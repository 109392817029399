import { toast } from "@suraasa/placebo-ui"
import {
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  TextField,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { Interest } from "api/resources/profile/types"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { handleErrors } from "utils/helpers"

const useStyles = createUseStyles(theme => ({
  dialogContent: {
    padding: theme.spacing(2.5, 3, 5.5, 3),
    "& > form": {
      display: "flex",
      gap: 24,
      flexDirection: "column",
    },
  },
}))

type Props = {
  onAdd: (data: Interest) => void
  handleBack?: () => void
}

const AddDialog = ({
  handleBack,
  onAdd,
}: Props & Pick<DialogProps, "open" | "onRequestClose">) => {
  const classes = useStyles()

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Interest>()

  useEffect(() => {
    reset()
  }, [reset])

  const onSubmit = handleSubmit(async ({ interest }) => {
    const apiData = {
      interest,
    }

    const res = await api.profile.interests.create({ data: apiData })
    if (res.isSuccessful) {
      onAdd(res.data)
      toast.success("Added successfully.")
      if (handleBack) handleBack()
    } else {
      handleErrors(res, { setter: setError })
    }
  })

  return (
    <>
      <DialogTitle onBack={handleBack}> Add New Interest & Hobby</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form onSubmit={onSubmit}>
          <div>
            <TextField
              error={Boolean(errors.interest)}
              helperText={errors.interest?.message}
              label="Interest & Hobby"
              placeholder="Ex: Reading"
              fullWidth
              charLimit={50}
              {...register("interest", {
                required: { value: true, message: "Required" },
              })}
            />
          </div>
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Save",
            variant: "filled",
            color: "primary",
            onClick: onSubmit,
            loading: isSubmitting,
          },
          secondary: {
            label: "Close",
          },
        }}
      />
    </>
  )
}

export default AddDialog
