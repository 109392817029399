import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getCelestialURL = getServiceURL(SERVICE_PREFIX.celestial)

export const urls = {
  list: (itemType: string | number, itemId: string | number) =>
    `/v1/${itemType}/${itemId}/assignments/`,
  retrieve: (
    assignmentUUID: string | number,
    userPlannerItemId: string | number
  ) =>
    `/v1/assignments/${assignmentUUID}/user-planner-items/${userPlannerItemId}/`,
  getRubrics: (assignmentId: string | number) =>
    `/assignments/marking-criteria/${assignmentId}/`,
  getReviewRubrics: (reviewId: string | number) =>
    `/assignments/review-rubrics/${reviewId}/`,
  getIQAReviewRubrics: (reviewId: string | number) =>
    `/assignments/iqa-review-rubrics/${reviewId}/`,
  listSubmissions: (
    assignmentUUID: string | number,
    userPlannerItemId: string | number
  ) =>
    `/assignments/${assignmentUUID}/user-planner-items/${userPlannerItemId}/submissions/`,
  createSubmission: (assignmentId: string | number) =>
    `/assignments/${assignmentId}/submissions/`,
  retrieveSubmission: (submissionId: string | number) =>
    `/assignments/submissions/${submissionId}/`,
  attachment: (attachmentId: string | number) =>
    `/assignments/submissions/attachments/${attachmentId}/`,

  listDueAssignments: () => `/assignments/users/due/`,
  requestDueDateExtension: () =>
    getCelestialURL(`/v1/assignments/extension-requests/`),

  retrieveUserPlannerItem: (
    learningItemType: string | number,
    learningItemSlug: string | number,
    learningContentType: string | number,
    learningContentId: string | number
  ) =>
    `/v1/${learningItemType}/${learningItemSlug}/users/planners/items/${learningContentType}/${learningContentId}/`,
}

export default urls
