import { Typography } from "@suraasa/placebo-ui"
import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
} from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import queries from "api/resources/learningItems/queries"
import HandHoldingPen from "assets/Fallback/hand-holding-pen.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import { NavArrowDown } from "iconoir-react"
import groupBy from "lodash/groupBy"
import React from "react"

import MilestoneItem from "./MilestoneItem"

type Props = {
  plannerId: number
  className?: string
}

const Curriculum = ({ className, plannerId }: Props) => {
  const {
    data: planner,
    isLoading,
    isError,
  } = useQuery({
    queryFn: () =>
      api.learningItems.listPlannerItems({
        urlParams: { plannerId },
      }),
    queryKey: queries.listPlannerItems(plannerId).queryKey,
  })

  if (isError || isLoading || planner.length === 0) {
    let title = "Something is not right"
    if (planner?.length === 0)
      title = "Your course curriculum is not defined yet."
    if (isError) title = "Something went wrong"

    return (
      <div className="mb-6">
        <Typography variant="title3" className="mb-2">
          Self-Paced Course
        </Typography>
        <Fallback
          data={{
            image: HandHoldingPen,
            title: title,
            description: "Please contact care@suraasa.com",
          }}
          hasError={isError}
          isLoading={isLoading}
          className="mb-3 mt-9 sm:mt-20 md:mt-18"
        />
      </div>
    )
  }

  const milestones = groupBy(planner, "milestone.id")

  const totalMilestones = Object.keys(milestones).length

  return (
    <div className={clsx(className)}>
      <Typography variant="title3" className="mb-2">
        Self-Paced Course
      </Typography>
      {Object.entries(milestones).map(([milestoneId, milestone], index) => {
        // Taking the milestone name from first item's milestone key
        const milestoneTitle = milestone[0].milestone.title

        return (
          <div
            key={milestoneId}
            className={clsx("bg-white px-0 py-2 sm:px-1", {
              "border-2 border-b-0 rounded-t-2xl border-surface-200 ":
                index === 0,
              "border-2 border-b-0  border-surface-200 ":
                index > 0 && index < totalMilestones - 1,
              "border-2 border-surface-200 rounded-b-2xl":
                index === totalMilestones - 1,
            })}
          >
            <Accordion>
              <AccordionHeader icon={<NavArrowDown color="black" />}>
                <Typography className="text-end" variant="strong">
                  Milestone {index + 1} - {milestoneTitle}
                </Typography>
              </AccordionHeader>
              <AccordionDetails className="mt-2">
                {milestone.map((item, index) => (
                  <div
                    key={index}
                    className={clsx({
                      "mb-2": index < milestone.length - 1,
                    })}
                  >
                    <MilestoneItem item={item} plannerId={plannerId} />
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        )
      })}
    </div>
  )
}

export default Curriculum
