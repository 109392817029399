import { toast } from "@suraasa/placebo-ui"
import {
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  Select,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { State } from "api/resources/global/types"
import { WorkLocationInterest } from "api/resources/profile/types"
// import { GlobalContext } from "components/GlobalState"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { handleErrors } from "utils/helpers"
import { useResource } from "utils/hooks/useResource"

const useStyles = createUseStyles(theme => ({
  addWorkLocationsContainer: {
    padding: theme.spacing(2.5, 3, 5.5, 3),
    "& > form": {
      display: "flex",
      gap: 24,
      flexDirection: "column",
    },
  },
}))

type Props = {
  onAdd: (data: WorkLocationInterest) => void
  onBack?: () => void
}

const AddDialog = ({
  onBack,
  onAdd,

  onRequestClose,
}: Props &
  Pick<DialogProps, "open" | "onRequestClose"> &
  Pick<DialogTitleProps, "onBack">) => {
  const classes = useStyles()

  // TODO(profile)
  // const { countries } = useContext(GlobalContext)
  const { countries } = useResource(["countries"])

  const [states, setStates] = useState<State[]>([])

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    setError,
  } = useForm<{ countryId: string; stateId: string }>()

  const [loading, setLoading] = useState(false)
  const [statesLoading, setStatesLoading] = useState(false)

  const fetchStates = async (countryId: string) => {
    setStatesLoading(true)
    setStates([])
    setValue("stateId", "")
    const res = await api.global.listStates({
      urlParams: { countryId },
      params: { page: "-1" },
    })

    if (res.isSuccessful) {
      setStates(res.data)
    }
    setStatesLoading(false)
  }

  useEffect(() => {
    reset()
  }, [reset])

  const onSubmit = handleSubmit(async data => {
    setLoading(true)

    const res = await api.profile.locations.create({ data })
    if (res.isSuccessful) {
      onAdd(res.data)
      setLoading(false)
      toast.success("Added successfully.")
      reset()
      if (onBack) onBack()
    } else {
      //TODO: yet to get reply from ayush hindi on this
      if (
        res.errors.fieldErrors?.preferredWorkLocations &&
        typeof res.errors.fieldErrors?.preferredWorkLocations === "string"
      ) {
        setError("countryId", {
          message: res.errors.fieldErrors?.preferredWorkLocations,
        })
        toast.error(res.errors.fieldErrors?.preferredWorkLocations)
      }
      handleErrors(res, { setter: setError })
      setLoading(false)
    }
  })

  return (
    <>
      <DialogTitle onBack={onBack}>Add New Work Location</DialogTitle>
      <DialogContent className={classes.addWorkLocationsContainer}>
        <form onSubmit={onSubmit}>
          <div>
            <Controller
              control={control}
              name="countryId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  error={Boolean(errors.countryId)}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.uuid}
                  helperText={errors.countryId?.message}
                  label="Country"
                  options={countries}
                  placeholder="Ex: UAE"
                  value={
                    value
                      ? countries.find(country => country.uuid === value)
                      : null
                  }
                  fullWidth
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => {
                    if (val) {
                      fetchStates(val.uuid)
                      onChange(val.uuid)
                    } else {
                      setStates([])
                    }
                  }}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="stateId"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  error={Boolean(errors.stateId)}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.uuid}
                  helperText={errors.stateId?.message}
                  isLoading={statesLoading}
                  label="State/Region/Province"
                  options={states}
                  placeholder="Ex: Dubai"
                  value={
                    value ? states.find(state => state.uuid === value) : null
                  }
                  fullWidth
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => {
                    onChange(val?.uuid)
                  }}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Save",
            variant: "filled",
            color: "primary",
            onClick: onSubmit,
            loading,
          },
          secondary: {
            label: "Close",
            onClick: onRequestClose,
          },
        }}
      />
    </>
  )
}

export default AddDialog
