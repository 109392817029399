import { Dialog, DialogBody, DialogContent } from "@suraasa/placebo-ui"
import { Sheet, SheetBody, SheetContent } from "@suraasa/placebo-ui"
import { theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { context } from "global/Context/context"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import routes from "routes"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { UserPreferenceManager } from "utils/userPreferenceManager"

import Introduction from "./Introduction"
import Tools from "./Tools"

const { suraasaAIToolsOnboardingCompleted } = BROWSER_STORAGE_KEYS

const Onboarding = () => {
  const isCompleted =
    UserPreferenceManager.get(suraasaAIToolsOnboardingCompleted) === "true"

  const featureToggle = useFeatureToggle()

  const isAIToolsEnabled = featureToggle.aiTools.isEnabled

  const { isAuthenticated } = useContext(context)

  const [slide, setSlider] = useState<"introduction" | "tools" | null>(null)

  useEffect(() => {
    if (slide === null && !isCompleted) {
      setSlider("introduction")
    }
  }, [isCompleted, isAuthenticated, slide])

  const closeOnboarding = () => {
    UserPreferenceManager.set(suraasaAIToolsOnboardingCompleted, "true")
    setSlider(null)
  }

  const navigate = useNavigate()

  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const { pathname } = useLocation()

  // Only want to show popup on specific pages
  const allowedRoutes = [routes.home, routes.learning, routes.profile.self]
  if (!allowedRoutes.includes(pathname)) return null

  if (!isAuthenticated || isCompleted || !isAIToolsEnabled) return null

  return (
    <div>
      <Sheet open={slide === "introduction"} onOpenChange={closeOnboarding}>
        <SheetContent side="bottom" className="bg-white p-0" height={90}>
          <SheetBody className="rounded-3xl p-0">
            <Introduction
              onClick={() => {
                if (window.location.pathname.includes("ai-tools")) return
                navigate(routes.aiTools.home)
                closeOnboarding()
              }}
              onClose={closeOnboarding}
            />
          </SheetBody>
        </SheetContent>
      </Sheet>

      <Dialog open={slide === "tools"} onOpenChange={closeOnboarding}>
        <DialogContent
          className="h-full rounded-none sm:h-auto sm:rounded-xl"
          fullScreen={isXs}
        >
          <DialogBody className="h-full p-0 sm:h-auto">
            <Tools closeOnboarding={closeOnboarding} />
          </DialogBody>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Onboarding
