import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "features/CourseFeedbackCard/assets/moods.png"
import React from "react"

import styles from "./Step1.module.css"

type Props = {
  onClickRateCourse: () => void
  onClickNotNow: () => void
  title: string
}

const Step1 = ({ onClickRateCourse, onClickNotNow, title }: Props) => {
  // title = title.split(":")[0]

  return (
    <div
      className={clsx(
        "relative flex flex-col justify-between overflow-hidden px-2 pb-1 pt-2",
        styles.container
      )}
    >
      <span
        className={clsx(
          "z-10 w-fit rounded-md bg-[#1E326E] px-0.5 py-0.25 text-sm text-white"
        )}
      >
        Feedback
      </span>
      <div className="absolute left-0 top-0 w-full">
        <div className="relative w-full">
          <img
            src={Image}
            alt=""
            className="mx-auto max-h-[200px] min-h-[170px] overflow-hidden object-cover"
          />
        </div>
      </div>

      <div>
        <Typography variant="title3" className="mb-2 text-white">
          Let us know about your trainer&apos;s performance during the live
          session{title ? ` - ${title}` : "."}
        </Typography>
        <Button
          fullWidth
          color="white"
          className="mb-1 !text-interactive-500"
          onClick={onClickRateCourse}
        >
          Give Feedback
        </Button>
        {/* <Button
          color="white"
          variant="text"
          className="mx-auto"
          fullWidth
          onClick={onClickNotNow}
        >
          Not Now
        </Button> */}
      </div>
    </div>
  )
}

export default Step1
