import { Typography } from "@suraasa/placebo-ui"
import { Button, CircularProgress } from "@suraasa/placebo-ui-legacy"
import { CheckCircle } from "iconoir-react"
import { useEffect, useState } from "react"
import {
  browserName,
  browserVersion,
  isChrome,
  isSafari,
  isSamsungBrowser,
  osName,
} from "react-device-detect"
import {
  BROWSER_STORAGE_KEYS,
  browserIsSupportedForITO,
  isAppleDevice,
} from "utils/constants"

import {
  getCameraPermission,
  releaseMediaControl,
  Status,
} from "../../Proctoring/hooks/useCamDetection"
import AllowCameraDialog from "../AllowCameraDialog"
import BrowserSpecificAsset from "../BrowserSpecificAsset"
// import { GA } from "shared/utils/googleAnalytics"
import styles from "./SystemRequirement.module.css"
import UnsupportedBrowserDialog from "./UnsupportedBrowserDialog"

const ID = "system-requirements-section"

type Props = {
  autoCheckCamera?: boolean
  className?: string
}

const SystemRequirements = ({ autoCheckCamera = false, className }: Props) => {
  const [cameraStatus, setCameraStatus] = useState<Status | null>(
    autoCheckCamera === false &&
      localStorage.getItem(
        BROWSER_STORAGE_KEYS.cameraPermissionsAllowedForITO
      ) === "true"
      ? "on"
      : null
  )

  const [dialog, setDialog] = useState<
    "cameraPermission" | "unsupportedBrowser" | null
  >(null)

  const triggerCameraPermissionRequest = async (showDialog = true) => {
    const camera = await getCameraPermission({ initiate: true })

    setCameraStatus(camera.status)
    if (camera.status === "on") {
      localStorage.setItem(
        BROWSER_STORAGE_KEYS.cameraPermissionsAllowedForITO,
        "true"
      )
      setTimeout(() => {
        releaseMediaControl(camera.stream)
      }, 1000)
    }

    if (camera.status !== "on") {
      localStorage.setItem(
        BROWSER_STORAGE_KEYS.cameraPermissionsAllowedForITO,
        "false"
      )
    }

    if (showDialog) setDialog("cameraPermission")
  }

  useEffect(() => {
    if (!autoCheckCamera) return

    if (!cameraStatus) {
      triggerCameraPermissionRequest(false)
    }
  }, [cameraStatus, autoCheckCamera])

  const points = [
    "Your browser is supported.",
    "Your camera is working.",
    "Your internet connection is stable.",
  ]

  const browserIsSupported = browserIsSupportedForITO === "supported"

  const systemIsSupported = browserIsSupported
    ? cameraStatus === null
      ? null
      : browserIsSupported && cameraStatus === "on"
    : false

  const getCameraPermissionSteps = () => (
    <div
      onClick={() => {
        triggerCameraPermissionRequest()
      }}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
      className="rounded-xl border border-critical-100 bg-critical-50 p-2 text-critical-800"
    >
      <Typography variant="title3" className="mb-1">
        Attention Needed!
      </Typography>

      <Typography>
        Please click &apos;Allow&apos; if you get prompted for camera
        permissions
      </Typography>

      <div className="mt-1">
        <BrowserSpecificAsset
          data={{
            MobileSafari: {
              IOS: "/assets/camera-permission-steps/ios-safari-prompt.png",
            },
            Safari: {
              MAC_OS:
                "/assets/camera-permission-steps/macos-safari-prompt.jpeg",
            },
            Firefox: {
              Windows:
                "/assets/camera-permission-steps/firefox-settings-prompt.png",
              MAC_OS:
                "/assets/camera-permission-steps/firefox-settings-prompt.png",
            },
            Chrome: {
              IOS: "/assets/camera-permission-steps/ios-chrome-prompt.png",
              Android:
                "/assets/camera-permission-steps/android-chrome-prompt.png",
              Windows:
                "/assets/camera-permission-steps/windows-chrome-prompt.png",
              MAC_OS:
                "/assets/camera-permission-steps/macos-chrome-prompt.jpeg",
            },
            SamsungBrowser: {
              Android:
                "/assets/camera-permission-steps/samsung-browser-prompt.png",
            },
          }}
          render={asset => (
            <img
              className={styles.imgBackground}
              src={asset}
              alt="camera-permission"
            />
          )}
        />
      </div>

      <Button fullWidth className="mt-2" color="critical">
        Allow
      </Button>
    </div>
  )

  const getUnsupportedBrowserInstructions = () => (
    <div
      onClick={() => {
        setDialog("unsupportedBrowser")
      }}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
      className="rounded-xl border border-critical-100 bg-critical-50 p-2 text-critical-800"
    >
      <Typography variant="title3" className="mb-1">
        Attention Needed!
      </Typography>
      <div className="mb-2">
        You are using an unsupported browser. To attempt the olympiad, you need
        to be using the latest version of{" "}
        {browserIsSupportedForITO === "unsupported_version" && (
          <span className="inline font-semibold">
            {isSafari ? (
              <span>
                <span>Safari Browser</span>
                <img
                  className="ms-0.5 inline"
                  height={24}
                  width={24}
                  src="/assets/logos/safari-logo-browser.png"
                  alt="Safari"
                />
              </span>
            ) : (
              <>
                {isChrome && (
                  <span>
                    <span>Google Chrome</span>
                    <img
                      className="ms-0.5 inline"
                      height={24}
                      width={24}
                      src="/assets/logos/google-chrome-logo.png"
                      alt="Google Chrome"
                    />
                  </span>
                )}
                {isSamsungBrowser && (
                  <span>
                    <span>Samsung Browser</span>
                    <img
                      className="ms-0.5 inline"
                      height={24}
                      width={24}
                      src="/assets/logos/samsung-browser-logo.jpg"
                      alt="Samsung Browser"
                    />
                  </span>
                )}
              </>
            )}
          </span>
        )}
        {browserIsSupportedForITO === "unsupported_vendor" && (
          <span className="inline font-semibold">
            {isAppleDevice ? (
              <span>
                <span>Safari Browser</span>
                <img
                  className="ms-0.5 inline"
                  height={24}
                  width={24}
                  src="/assets/logos/safari-logo-browser.png"
                  alt="Safari"
                />
              </span>
            ) : (
              <span>
                <span>Google Chrome</span>
                <img
                  className="ms-0.5 inline"
                  height={24}
                  width={24}
                  src="/assets/logos/google-chrome-logo.png"
                  alt="Google Chrome"
                />
              </span>
            )}
          </span>
        )}
        .
      </div>
      <Button className="mt-2" color="critical">
        Click here to see next steps
      </Button>
    </div>
  )

  return (
    <div id={ID} className={className}>
      <AllowCameraDialog open={dialog === "cameraPermission"} />
      <UnsupportedBrowserDialog open={dialog === "unsupportedBrowser"} />

      <Typography className="mb-1.5" variant="title2">
        System Requirements
      </Typography>

      {/* {portalEl &&
        systemIsSupported === false &&
        createPortal(
          <>
            {!browserIsSupported && getUnsupportedBrowserInstructions()}

            {browserIsSupported &&
              cameraStatus !== null &&
              cameraStatus !== "on" &&
              getCameraPermissionSteps()}
          </>,
          portalEl
        )} */}

      {browserIsSupported &&
        !systemIsSupported &&
        !autoCheckCamera &&
        cameraStatus === null && (
          <div className="rounded-xl border border-warning-100 bg-warning-50 p-2 text-warning-800">
            <Typography variant="title3" className="mb-1">
              We need to check if your system is eligible to attempt the
              Olympiad.
            </Typography>

            <Typography>
              You may be prompted to allow permission to this website for
              accessing your camera. Please click &apos;Allow&apos;.
            </Typography>

            <Button
              className="mt-2"
              color="warning"
              onClick={() => triggerCameraPermissionRequest()}
            >
              Check my system
            </Button>
          </div>
        )}

      {systemIsSupported && (
        <div className="rounded-xl border border-success-100 bg-success-50 p-2 text-success-800">
          <Typography variant="strongSmallBody" className="mb-1">
            Your device is supported. You can attempt the olympiad on this
            device.
          </Typography>

          <ul>
            {points.map((x, i) => (
              <li key={i} className="flex items-center gap-1">
                <span>
                  <CheckCircle />
                </span>
                <Typography variant="smallBody">{x}</Typography>
              </li>
            ))}
          </ul>
        </div>
      )}

      {!browserIsSupported && getUnsupportedBrowserInstructions()}

      {browserIsSupported &&
        cameraStatus !== null &&
        cameraStatus !== "on" &&
        getCameraPermissionSteps()}

      {browserIsSupported && cameraStatus === null && autoCheckCamera && (
        <div
          onClick={() => {
            triggerCameraPermissionRequest()
          }}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
          className="rounded-xl border border-primary-100 bg-primary-50 p-2 text-primary-800"
        >
          <div className="flex items-center gap-1">
            <Typography variant="title3">Checking your system!</Typography>
            <CircularProgress />
          </div>
        </div>
      )}
      <Typography
        variant="smallBody"
        className="text-onSurface-500"
        style={{ fontSize: 8 }}
      >
        Currently using: {browserName} {browserVersion} on {osName}
      </Typography>
    </div>
  )
}

export default SystemRequirements
