import { Avatar, Typography } from "@suraasa/placebo-ui"
import { Button, Tag } from "@suraasa/placebo-ui-legacy"
import { Comment as CommentType } from "api/resources/discussions/types"
import clsx from "clsx"
import { formatDistance } from "date-fns"
import React, { useState } from "react"
import { buildUserName, pluralize } from "utils/helpers"

import CommentField from "../CommentField"
import { highlightHashtags } from "../highlightHastags"
import styles from "./Comment.module.css"
import CommentMenu from "./CommentMenu"
import Replies from "./Replies"

const Comment = ({
  onShowMore,
  comment: comments,
  discussionId,
  additionalData,
}: {
  onShowMore?: () => void
  discussionId: string
  comment: CommentType
  additionalData?: {
    userPlannerItemId: number
    discussion: number
    course: string
  }
}) => {
  const [replyToComment, setReplyToComment] = useState("")
  const [nextReplies, setNextReplies] = useState(2)

  const { text, dateCreated, replies, user, id: commentId, isAuthor } = comments

  const datePublishStatus =
    dateCreated &&
    formatDistance(new Date(dateCreated), new Date(), {
      addSuffix: true,
    })
  const fullName = buildUserName({
    firstName: user.firstName,
    lastName: user.lastName,
  })
  return (
    <div className="flex">
      <div className="me-0.5 flex shrink-0 flex-col items-center sm:me-2">
        <div className="mb-0.5">
          <Avatar className="size-6" name={fullName} src={user.photo} />
        </div>
        {replies && replies.length > 0 && (
          <div className={styles.verticalDivider} />
        )}
      </div>
      <div className="grow">
        <div className="mb-2 flex">
          <div className="flex grow flex-col">
            <div className="mb-1 flex flex-wrap items-center">
              <Typography className="me-1" variant="strongSmallBody">
                {fullName}
              </Typography>
              {isAuthor && (
                <Tag label="Author" color="primary" className="me-1" />
              )}
              <Typography className="text-onSurface-500" variant="smallBody">
                {datePublishStatus}
              </Typography>
            </div>
            <div className="mb-0.5 rounded-2xl rounded-tl border border-onSurface-200 bg-onSurface-50 p-1.5">
              <Typography
                className="whitespace-pre-line text-onSurface-800"
                style={{ wordBreak: "break-word" }}
                variant="smallBody"
              >
                {highlightHashtags(text)}
              </Typography>
            </div>
            <div className="flex items-baseline">
              <Button
                variant="text"
                onClick={() => {
                  if (!replyToComment) {
                    setReplyToComment(user.firstName)
                  } else {
                    setReplyToComment("")
                  }
                }}
                className={clsx("mb-1.5", styles.removeLeftSpace)}
              >
                <Typography variant="strongSmallBody">Reply</Typography>
              </Button>
              {replies && replies.length > 0 && (
                <>
                  <span className="me-1 text-onSurface-500">&#x2022;</span>
                  <Typography
                    className="text-onSurface-500"
                    variant="smallBody"
                  >
                    {pluralize("Reply", replies.length, { plural: "Replies" })}
                  </Typography>
                </>
              )}
            </div>
            {replyToComment === user.firstName && (
              <CommentField
                onAdd={() => {
                  setReplyToComment("")
                }}
                additionalData={additionalData}
                commentId={commentId}
                discussionId={discussionId}
                isReplyField
                fullWidth
                name={user.firstName}
              />
            )}
          </div>
          <CommentMenu
            isSelf={user.isSelf}
            commentId={commentId}
            discussionId={discussionId}
            firstName={user.firstName}
          />
        </div>
        {/* replies  */}
        {replies &&
          replies.slice(0, nextReplies).map((comment, index) => (
            <Replies
              commentId={commentId}
              discussionId={discussionId}
              key={index}
              data={comment}
              className={clsx({
                "mb-2": index > replies.length,
              })}
            />
          ))}

        {replies && nextReplies < replies.length && (
          <Button
            variant="text"
            onClick={() => {
              if (onShowMore) {
                onShowMore()
              } else {
                setNextReplies(prevState => prevState + 2)
              }
            }}
          >
            Show more replies
          </Button>
        )}
      </div>
    </div>
  )
}

export default Comment
