import { Typography } from "@suraasa/placebo-ui"
import { Divider, theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { Notification } from "api/resources/notifications/types"
import SuraasaLogo from "assets/suraasaLogo.jpg"
import clsx from "clsx"
import TruncatedText from "components/TruncatedText"
import { formatDistance } from "date-fns"
import { context } from "global/Context/context"
import { GraduationCap, Suitcase } from "iconoir-react"
import { useContext } from "react"
import { isSafari } from "react-device-detect"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

import { useNotificationAction } from "../hooks/useNotificationAction"
import { TabNames } from "../hooks/useNotifications"

const svgProps = {
  width: 14,
  height: 14,
}

const NotificationRowCard = ({
  notification,
  listLength,
  index,
  mini,
  isLastItem,
  loading,
  hasNextPage,
  actionFunc: fetchNextPage,
  activeTabName,
}: {
  notification: Notification
  listLength: number
  index: number
  mini?: boolean
  isLastItem: boolean
  loading: boolean
  hasNextPage?: boolean
  actionFunc: () => void
  activeTabName: string
}) => {
  const { ref } = usePaginationScroll({
    loading,
    hasNextPage,
    actionFunc: fetchNextPage,
  })
  const { handleNotificationClick, hasAction } =
    useNotificationAction(notification)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, toggleMenu] = useContext(context).notificationsPopupMenuState

  const { tags, text, dateRead, dateCreated, title } = notification
  const datePublishStatus = formatDistance(new Date(dateCreated), new Date(), {
    addSuffix: true,
  })

  const smUp = useMediaQuery(theme.breakpoints.up("sm"))
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const isMini = mini || isXs

  const getTag = () => {
    switch (activeTabName) {
      case TabNames.ALL:
        if (tags.includes("learning")) {
          return <GraduationCap {...svgProps} />
        }
        if (tags.includes("jobs")) {
          return <Suitcase {...svgProps} />
        }
        return null
      default:
        return null
    }
  }

  const tag = getTag()
  const notificationTag = () => {
    if (!tag) return null
    if (isMini)
      return (
        <div className="w-fit rounded-full bg-onSurface-900 p-0.75 text-surface-500">
          {tag}
        </div>
      )

    return (
      <div className="flex w-fit items-center gap-0.5 rounded-lg bg-onSurface-900 px-0.75 text-surface-500">
        {tag}
        <span>{TabNames.LEARN}</span>
      </div>
    )
  }

  return (
    <div
      className={clsx("flex", {
        "rounded-lg bg-highlight-50": !dateRead,
        "rounded-none bg-highlight-50": !dateRead && isMini,
      })}
      ref={isLastItem ? ref : null}
    >
      <button
        className={clsx("w-full", {
          "cursor-pointer": hasAction,
          "cursor-default": !hasAction,
        })}
        onClick={() => {
          handleNotificationClick()
          if (hasAction) {
            toggleMenu(false)
          }
        }}
      >
        <div className="flex items-center">
          {isMini ? (
            <div className="me-2 shrink-0 py-2 pl-2">
              <span className="relative">
                <img
                  alt="icon"
                  src={SuraasaLogo}
                  width="48px"
                  height="48px"
                  className="rounded-xl"
                />
                {/* For some reason this styling was breaking on Safari so had to resort to this check */}
                {isSafari ? (
                  <div className="absolute -bottom-1 left-3.5">
                    {notificationTag()}
                  </div>
                ) : (
                  <div className="absolute -right-1 bottom-[-5px]">
                    {notificationTag()}
                  </div>
                )}
              </span>
            </div>
          ) : (
            <div className="py-2 pl-2">
              <img
                alt="icon"
                src={SuraasaLogo}
                width="48px"
                height="48px"
                className="me-2.5 rounded-xl"
              />
            </div>
          )}

          <div className="flex w-full flex-col justify-between pr-2 pt-1.5 sm:pt-2">
            <div className="flex grow items-start justify-between">
              <div
                style={{ maxWidth: 640 }}
                className="me-2 flex flex-col pb-1.5 sm:pb-2"
              >
                {isMini ? (
                  <div>
                    {title && (
                      <TruncatedText
                        className="block text-start"
                        maxLength={165}
                        hideActionButton
                        variant="strongSmallBody"
                      >
                        {title}
                      </TruncatedText>
                    )}
                    <TruncatedText
                      maxLength={165}
                      hideActionButton
                      variant="smallBody"
                      className="mb-0.5 block text-start"
                    >
                      {text}
                    </TruncatedText>
                    <Typography
                      className="text-start text-onSurface-500"
                      variant="smallBody"
                    >
                      {datePublishStatus !== "less than a minute ago"
                        ? datePublishStatus.replace("about", "")
                        : "recently"}
                    </Typography>
                  </div>
                ) : (
                  <>
                    {title && (
                      <Typography
                        className="block text-start"
                        variant="strongSmallBody"
                      >
                        {title}
                      </Typography>
                    )}
                    <Typography
                      variant="smallBody"
                      className="mb-0.5 text-start"
                    >
                      {text}
                    </Typography>
                  </>
                )}
                {!isMini && notificationTag()}
              </div>
              {!isMini && (
                <Typography
                  className="shrink-0 text-onSurface-500"
                  variant="smallBody"
                >
                  {datePublishStatus !== "less than a minute ago"
                    ? datePublishStatus.replace("about", "")
                    : "recently"}
                </Typography>
              )}
            </div>
            {(smUp || index !== listLength - 1) && <Divider weight="light" />}
          </div>
        </div>
        {!smUp && index === listLength - 1 && <Divider weight="light" />}
      </button>
    </div>
  )
}

export default NotificationRowCard
