import { Typography } from "@suraasa/placebo-ui"
import { Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import OlympiadLogo from "features/ITO/Assessments/assets/olympiad-logo.svg"
import React from "react"
import { useTheme } from "react-jss"

import styles from "./Navbar.module.css"

export type NavbarProps = {
  className?: string
  gutterBottom?: boolean
  slotEnd?: React.ReactNode
  navBarTitle?: string
}

const Navbar = ({
  navBarTitle,
  gutterBottom = true,
  className,
  slotEnd,
}: NavbarProps) => {
  const theme = useTheme<Theme>()

  const isSm = useMediaQuery(theme.breakpoints.down("sm"))

  const getTitle = () => {
    if (isSm) {
      return <img alt="" src={OlympiadLogo} width={40} height={40} />
    }
    if (
      navBarTitle &&
      navBarTitle.includes("international-teachers-olympiad")
    ) {
      return (
        <img
          alt=""
          src="/src/features/ITO/Assessments/assets/olympiad-logo.svg"
          width="80%"
        />
      )
    }

    return <Typography variant="title3">{navBarTitle}</Typography>
  }

  return (
    <div
      className={clsx("fixed top-0 z-navbar w-full", {
        ["mb-4 md:mb-8"]: gutterBottom,
      })}
    >
      <nav
        className={clsx(
          "relative h-[62px] border-b border-b-onSurface-200 bg-onSurface-50",
          className,
          styles.root,
          "flex items-center py-1"
        )}
      >
        <div
          className={clsx(
            "flex w-full flex-wrap items-center justify-between p-2 sm:px-5 md:flex-nowrap",
            styles.container
          )}
        >
          <div className="flex grow items-center justify-start md:block md:grow-0">
            {getTitle()}
          </div>
          {slotEnd}
        </div>
      </nav>
    </div>
  )
}

export default React.memo(Navbar)
