import { Country, Currency } from "../global/types"
import { RefundStatus } from "../payment/types"

export enum ClientType {
  WEB = "web",
  IOS = "ios",
  ANDROID = "android",
  CRM = "crm",
}

export enum OrderStatus {
  NOT_INITIATED = 1,
  IN_PROGRESS = 2,
  SUCCESSFUL = 3,
  CANCELLED = 4,
  FAILED = 5,
}

export enum OrderItemType {
  COURSE = "course",
  QUALIFICATION = "qualification",
  CERTIFICATION = "certification",
  ASSESSMENT = "assessment",
  PACKAGE = "package",
  ITO = "ito",
  SELLABLE_FEATURE = "sellable_feature",
}
export enum OrderPaymentPlanStatus {
  NOT_INITIATED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
  CANCELLED = 4,
  OVERDUE = 5,
}
export type PaymentPlans = {
  id: string
  amount: number
  status: OrderPaymentPlanStatus
  due_date: string | null
  created_at: string
}

export type OrderItem = {
  id: string
  finalAmount: number
  productId: string
  itemName: string
  itemSlug: string
  productItems: {
    itemType: OrderItemType
    itemId: number
    sequence: number
    learningMode: string | null
    plannerId: null | number
    throughBatch: boolean
    image: string | null
    specialisationId: number | null
    slug: string | null
    name: string | null
    uuid: string | null
  }[]
}

export type Order = {
  id: string
  serialNumber: string
  client: ClientType
  canRetry: boolean
  finalAmount: number
  status: OrderStatus
  completedAt: string | null
  createdAt: string
  items: OrderItem[]
  currency: Currency
  refundStatus: RefundStatus | null
  itemsAllocated: boolean
  paymentPlans: PaymentPlans[]
}

export type Transaction = {
  id: string
  serialNumber: string
  status: TransactionStatus
  amount: number
  createdAt: string
  currency: Currency
}

export type OrderItemDetails = {
  id: string
  serialNumber: string
  taxAmount: number
  discountAmount: number
  canRetry: boolean
  itemTotal: number
  finalAmount: number
  status: OrderStatus
  refundStatus: RefundStatus
  completedAt: string
  createdAt: string
  client: ClientType
  deductions: []
  taxes: {
    name: string
    amount: number
    percentage: number
  }[]
  paymentPlans: {
    id: string
    amount: number
    status: number
    dueDate: null
    createdAt: string
  }[]

  itemsAllocated: boolean
  transactions: Transaction[]
  currency: Currency
  items: OrderItem[]
}

export enum TransactionStatus {
  SUCCESSFUL = 1,
  FAILED = 2,
  REFUND_INITIATED = 3,
  REFUNDED = 4,
}

export enum PaymentPlanStatus {
  PAID = 1,
  UNPAID = 2,
  DUE = 3,
}

export type InvoiceAttachment = {
  id: number
  invoicePdf: string
  invoiceName: string
  invoiceNumber: string
  transactionId: number
}

export type CheckoutOrder = {
  cartId: string
  userId: string
  currencyId: string
  countryId: string
  client: string
  taxAmount: number
  discountAmount: number
  itemTotal: number
  finalAmount: number
  status: number
  billingAddressId: string
  createdAt: string
  updatedAt: string
  id: string
  paymentPlans: {
    shortDescription: string
    amount: number
    receivedAmount: number
    startDate: string
    dueDate: null | string
    status: number
    type: number
    sequence: number
    isPrimary: boolean
    orderId: string
    createdAt: string
    updatedAt: string
    id: string
    pendingAmount: number
  }[]
  country: Country
  currency: Currency
  pendingAmount: null
}

export type CartlessOrder = {
  suraasaUserId: string
  currencyId: string
  country: {
    name: string
    isoCode: string
  }
  taxAmount: number
  discount_amount: number
  itemTotal: number
  finalAmount: number
  status: OrderStatus
  billingAddressId: string
  createdAt: string
  updatedAt: string
  id: string
  payment_plans: {
    shortDescription: string
    amount: number
    receivedAmount: number
    startDate: string
    dueDate: null | string
    status: OrderPaymentPlanStatus
    type: number
    sequence: number
    isPrimary: boolean
    orderId: string
    createdAt: string
    updatedAt: string
    id: string
    pendingAmount: number
  }[]

  currency: Currency
  pendingAmount: number | null
}
