import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Feedback } from "./types"
import { urls } from "./urls"

export default {
  create: async ({ data }: Config): Promise<APIResponse<NoContentType>> => {
    if (
      !(
        typeof data.comment === "object" &&
        !Array.isArray(data.comment) &&
        data.comment !== null
      )
    ) {
      throw new Error("'comment' must be a JSON serializable object")
    }

    try {
      const res = await axios.post(urls.create(), data)
      return formatSuccessResponse(res)
    } catch (error) {
      // @ts-expect-error this is okay to do
      if (error?.response?.status >= 500) {
        // This is done so that in case kepler fails, user is not annoyed by the feedback popup in their session
        sessionStorage.setItem("preventFeedbackPopups", "true")
      }

      return formatErrorResponse(error)
    }
  },
  retrieve: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Feedback>> => {
    try {
      const res = await axios.get(
        urls.retrieve(urlParams.itemType, urlParams.itemId)
      )
      return formatSuccessResponse(res)
    } catch (error) {
      return formatErrorResponse(error)
    }
  },
}
