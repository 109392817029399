import { Typography } from "@suraasa/placebo-ui"
import { Button, Container } from "@suraasa/placebo-ui-legacy"
import {
  LearningRestrictions,
  RestrictionType,
} from "api/resources/learningItems/types"
import clsx from "clsx"
import { context } from "global/Context/context"
import { ArrowRight } from "iconoir-react"
import { useContext } from "react"
import { Link, useLocation } from "react-router-dom"
import { getPlannerName } from "utils/getPlannerName"
import { generateModuleAccessRequestLink } from "utils/helpers"

const AccessRestrictedBanner = () => {
  const { pathname } = useLocation()

  const { learningRestrictions } = useContext(context)

  /**
   * Don't want to show banner while user is attempting assessment
   */
  const hide = pathname.includes("/assessments/attempt/")
  if (hide) return null

  if (!learningRestrictions || learningRestrictions.length === 0) return null
  const pausedList = learningRestrictions.filter(
    item => item.restrictionType === RestrictionType.PAUSED
  )
  const revokedList = learningRestrictions.filter(
    item => item.restrictionType === RestrictionType.REVOKED
  )
  const expiredList = learningRestrictions.filter(
    item => item.restrictionType === RestrictionType.EXPIRED
  )

  return (
    <>
      <Banner restrictionType={RestrictionType.PAUSED} data={pausedList} />
      <Banner restrictionType={RestrictionType.REVOKED} data={revokedList} />
      <Banner restrictionType={RestrictionType.EXPIRED} data={expiredList} />
    </>
  )
}

const Banner = ({
  restrictionType,
  data,
}: {
  restrictionType: RestrictionType
  data: LearningRestrictions[]
}) => {
  if (data.length === 0) {
    return null
  }

  const itemName = getPlannerName(data[0].learningItem)

  return (
    <div
      className={clsx({
        "bg-warning-200": restrictionType === RestrictionType.PAUSED,
        "bg-critical-200":
          restrictionType === RestrictionType.EXPIRED ||
          restrictionType === RestrictionType.REVOKED,
      })}
    >
      <Container className="!py-1.5">
        <div className="flex w-full flex-wrap items-center justify-between">
          {restrictionType === RestrictionType.PAUSED && (
            <Typography variant="smallBody" className="text-warning-900">
              {data.length > 1
                ? `Your access to ${data.length} modules has been paused.`
                : `Your access to ${itemName} has been paused.`}
            </Typography>
          )}
          {restrictionType === RestrictionType.REVOKED && (
            <Typography variant="smallBody" className="text-critical-900">
              {data.length > 1
                ? `Your access to ${data.length} modules has been revoked.`
                : `Your access to ${itemName} has been revoked.`}
            </Typography>
          )}
          {restrictionType === RestrictionType.EXPIRED && (
            <Typography variant="smallBody" className="text-critical-900">
              {data.length > 1
                ? `Your access to ${data.length} modules has expired.`
                : `Your access to ${itemName} has expired.`}
            </Typography>
          )}

          <Button
            variant="text"
            color="warning"
            className={clsx({
              "!text-warning-900": restrictionType === RestrictionType.PAUSED,
              "!text-critical-900":
                restrictionType === RestrictionType.EXPIRED ||
                restrictionType === RestrictionType.REVOKED,
            })}
            component={Link}
            to={generateModuleAccessRequestLink({
              modules: data.map(x => x.learningItem.name),
              type: restrictionType,
            })}
            endAdornment={<ArrowRight />}
          >
            Contact Us
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default AccessRestrictedBanner
