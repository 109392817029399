import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { OpenNewWindow } from "iconoir-react"
import Markdown from "markdown-to-jsx"
import React from "react"

import TypingAnimation from "./TypingAnimation"

export enum Status {
  TYPING = "TYPING",
  SENT = "SENT",
  SENDING = "SENDING",
  READ = "READ",
  // custom
  FAILED = "FAILED",
  UNREAD = "UNREAD",
  SENDING_MULTIPLE = "SENDING_MULTIPLE",
}

export enum Author {
  USER = "USER",
  AGENT = "AGENT",
}

export type Message = {
  text: string
  link?: string
  author: Author
  createdTime: Date | number
  status: Status
  id?: string
}

const createClassNames = ({
  isSelf,
  nextAuthorSame,
  prevAuthorSame,
  timeVisible,
  isNextDayDifferent,
  status,
}: {
  isSelf: boolean
  nextAuthorSame?: boolean
  prevAuthorSame?: boolean
  timeVisible?: boolean
  isNextDayDifferent?: boolean
  status: Status
}) => {
  const maxWidthClassName = "max-w-[380px]"
  const paddingClassName = "px-1.75 py-1"
  let marginClassName = "my-0.75"

  let className = "flex"
  if (isSelf) {
    className = "bg-primary-500 text-white justify-end" + className
  } else {
    className =
      "bg-surface-50 text-onSurface-800 border border-onSurface-100 w-fit " +
      className
  }

  let radiusClassName = " rounded-2xl "

  if (nextAuthorSame) {
    if (isSelf && !isNextDayDifferent) {
      radiusClassName = " rounded-br-sm " + radiusClassName
    } else if (!isNextDayDifferent) {
      radiusClassName = " rounded-bl-sm" + radiusClassName
    }

    marginClassName = "my-0.25"
  }
  if (prevAuthorSame) {
    if (isSelf && !timeVisible) {
      radiusClassName = " rounded-tr-sm " + radiusClassName
    } else if (!timeVisible) {
      radiusClassName = " rounded-tl-sm " + radiusClassName
    }

    marginClassName = "my-0.25"
  }
  let statusClassName = ""
  switch (status) {
    case Status.SENDING:
      statusClassName = " !bg-primary-200 "
      break

    default:
      break
  }

  return clsx(
    maxWidthClassName,
    paddingClassName,
    radiusClassName,
    marginClassName,
    statusClassName,
    className
  )
}
const MessageStatus = ({ status }: { status: Status }) => {
  switch (status) {
    case Status.SENDING:
      return (
        <Typography
          color="onSurface.400"
          style={{
            fontSize: "10px",
            fontWeight: 500,
          }}
        >
          Sending...
        </Typography>
      )

    default:
      return null
  }
}

type Props = {
  message: Message
  nextAuthorSame?: boolean
  prevAuthorSame?: boolean
  timeVisible?: boolean
  isNextDayDifferent?: boolean
  isSelf: boolean
}
const MessageRow = ({
  message,
  nextAuthorSame,
  prevAuthorSame,
  timeVisible,
  isNextDayDifferent,
  isSelf,
}: Props) => {
  return (
    <div className={clsx("flex flex-col", isSelf && "items-end justify-end")}>
      <div
        className={clsx(
          createClassNames({
            isSelf,
            nextAuthorSame: message.link ? true : nextAuthorSame,
            prevAuthorSame,
            timeVisible,
            isNextDayDifferent,
            status: message.status,
          })
        )}
      >
        {message.status === Status.TYPING ? (
          <TypingAnimation />
        ) : (
          <>
            <span>{parseText(message.text, isSelf)}</span>
          </>
        )}
      </div>
      {!isSelf && message.link && (
        <div
          className={clsx(
            createClassNames({
              isSelf: false,
              nextAuthorSame,
              prevAuthorSame: true,
              timeVisible,
              isNextDayDifferent,
              status: message.status,
            }),
            " flex w-full items-center justify-center"
          )}
        >
          <Button
            startAdornment={<OpenNewWindow className="text-primary-500" />}
            className="text-center"
            variant="link"
            component="a"
            target={"_blank"}
            href={message.link}
          >
            Schedule Call
          </Button>
        </div>
      )}
      {/* {isSelf && !nextAuthorSame && <MessageStatus status={message.status} />} */}
    </div>
  )
}

export default MessageRow

const parseText = (message: string, isSelf: boolean): React.ReactNode => {
  return (
    <Markdown
      options={{
        overrides: {
          ul: {
            props: {
              className: "list-inside list-disc",
            },
          },
          a: {
            props: {
              className: `${
                isSelf ? "!text-white" : "text-interactive-500"
              } underline`,
              target: "_blank",
            },
          },
        },
      }}
    >
      {message}
    </Markdown>
  )
}
