import { Avatar, Typography } from "@suraasa/placebo-ui"
import { IconButton } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { ConversationMessage } from "api/resources/sales/types"
import { APIResponse } from "api/types"
import SuraasaAppIcon from "assets/logos/suraasa-app-icon.png"
import clsx from "clsx"
import { Xmark } from "iconoir-react"
import { useEffect, useRef, useState } from "react"

import { storageKeys } from "."
import MessageContainer from "./Message"
import Input from "./Message/Input"
import { Author, Message, Status } from "./Message/MessageRow"

type Props = {
  onClose: () => void
  conversationId: string
  initMessageSent: boolean
  onInitMessageSent: (x: boolean) => void
}

const createMessage = ({
  author,
  status,
  text,
  id,
  link,
}: {
  text: string
  author: Author
  status: Status
  id?: string
  link?: string
}): Message => {
  return {
    link,
    author,
    createdTime: new Date(),
    status,
    text,
    id: id?.toString(),
  }
}

type Queue = {
  messageId: string
  message: string
}
const ChatBox = ({
  onClose,
  conversationId,
  onInitMessageSent,
  initMessageSent,
}: Props) => {
  const [agentName, setAgentName] = useState("")
  const [messages, setMessages] = useState<Message[]>([])

  const [queue, setQueue] = useState<Queue[]>([])
  const timeoutRef = useRef<any>(null)

  const updateMessage = (
    messageId: string | number,
    update: Pick<Message, "text" | "status" | "link">
  ) => {
    setMessages(m => {
      const messages = m.map(m => {
        if (m.id == messageId) {
          return {
            author: m.author,
            createdTime: m.createdTime,
            id: m.id,
            status: update.status,
            text: update.text,
          }
        }
        return m
      })
      return messages
    })
  }

  const sendMessage = async (
    message: string
  ): Promise<false | APIResponse<ConversationMessage>> => {
    try {
      const res = await api.sales.chatbot.createMessage({
        data: {
          message,
        },
        params: {
          id: conversationId,
        },
      })
      return res
    } catch (error) {
      throw Error()
    }
  }
  const onSend = (message: string) => {
    if (message.trim() !== "") {
      const messageId = Date.now().toString()
      setMessages([
        ...messages,
        createMessage({
          author: Author.USER,
          status: Status.SENT,
          text: message,
          id: messageId,
        }),
      ])
      setQueue(prevMessages => [...prevMessages, { message, messageId }])
    }
  }

  const removeMessage = (messageId: string) => {
    setMessages(m => m.filter(m => m.id !== messageId))
  }

  const sendMessagesToApi = async (queue: Queue[]) => {
    const messageId = Date.now().toString()

    setMessages([
      ...messages,
      createMessage({
        author: Author.AGENT,
        status: Status.TYPING,
        text: "",
        id: messageId,
      }),
    ])
    try {
      let combinedMessages = ""
      const messageIds: string[] = []

      for (const q of queue) {
        combinedMessages += `${q.message}\n`
        messageIds.push(q.messageId)
      }

      const res = await sendMessage(combinedMessages)
      if (res) {
        for (const q of queue) {
          updateMessage(q.messageId, { status: Status.SENT, text: q.message })
        }

        setMessages(m => [
          ...m,
          createMessage({
            author: Author.AGENT,
            status: Status.READ,
            text: res.message,
            link: res.link,
            id: "",
          }),
        ])
      } else {
        for (const q of queue) {
          updateMessage(q.messageId, { status: Status.FAILED, text: q.message })
        }
      }
      removeMessage(messageId)
    } catch (error) {
      removeMessage(messageId)
      setMessages(m => [
        ...m,
        createMessage({
          author: Author.AGENT,
          status: Status.FAILED,
          text: "I am having trouble processing that response. Can you try again?",
        }),
      ])
      console.error("Failed to send message:", error)
    }
  }
  useEffect(() => {
    if (queue.length === 0) return

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      sendMessagesToApi(queue)
      setQueue([])
    }, 2000)

    return () => clearTimeout(timeoutRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue])

  const sendInitMessage = async () => {
    const messageId = Date.now().toString()

    try {
      setMessages([
        ...messages,
        createMessage({
          author: Author.AGENT,
          status: Status.TYPING,
          text: "",
          id: messageId,
        }),
      ])
      const res = await api.sales.chatbot.createMessage({
        data: {},
        params: {
          id: conversationId,
        },
      })
      updateMessage(messageId, { status: Status.SENT, text: res.message })

      setAgentName(res.name || "")
      sessionStorage.setItem(storageKeys.agentName, res.name || "")
      onInitMessageSent(true)
    } catch (error) {
      removeMessage(messageId)
      setMessages(m => [
        ...m,
        createMessage({
          author: Author.AGENT,
          status: Status.FAILED,
          text: "I am having trouble processing that response. Can you try again?",
        }),
      ])
      console.error("Failed to init message:", error)
    }
  }

  const loadSessionData = () => {
    try {
      const messages = sessionStorage.getItem(storageKeys.messages)
      if (!messages) return false

      const parsed = JSON.parse(messages).messages.filter(
        (x: any) => x.status !== Status.TYPING
      )
      const agentName = sessionStorage.getItem(storageKeys.agentName)
      if (agentName) {
        setAgentName(agentName)
      } else {
        setAgentName("Suraasa")
      }

      if (parsed.length > 0) {
        setMessages(parsed)
        return true
      }
      return false
    } catch (error) {
      console.log("Error loading local messages")
      return false
    }
  }
  useEffect(() => {
    const hasLocalMessage = loadSessionData()

    if (!hasLocalMessage && !initMessageSent) {
      sendInitMessage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateStorage = (messages: Message[]) => {
    const json = {
      messages: messages,
    }
    sessionStorage.setItem(storageKeys.messages, JSON.stringify(json))
  }

  useEffect(() => {
    if (messages.length > 0) {
      updateStorage(messages)
    }

    return () => {}
  }, [messages])

  return (
    <div
      className={clsx(
        "flex h-screen flex-col bg-white shadow-md md:h-[615px] md:w-[500px] md:rounded-3xl [&>div]:p-1.5"
      )}
      style={{
        boxShadow: "0px 6px 15px 0px rgba(0, 0, 0, 0.05)",
      }}
    >
      <div
        className="flex items-center justify-between"
        style={{
          boxShadow: "0px 6px 15px 0px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div className="flex gap-1">
          <>
            <div>
              <Avatar
                className="size-6"
                src={SuraasaAppIcon}
                name={agentName || "Suraasa"}
              />
            </div>
            <div className="flex h-full flex-col gap-0.25">
              <Typography variant="strong" className="mt-0.25">
                {agentName || "Suraasa"}
              </Typography>
              <div className="flex items-center gap-1 justify-self-end">
                <div
                  style={{
                    width: "6px",
                    height: "6px",
                    borderRadius: "100%",
                    background: "#138D75",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                  }}
                />
                <Typography className="text-onSurface-500" variant="smallBody">
                  Active Now
                </Typography>
              </div>
            </div>
          </>
        </div>
        <IconButton onClick={onClose}>
          <Xmark />
        </IconButton>
      </div>
      <MessageContainer messages={messages} agentName={agentName} />
      <div className="flex h-auto max-h-[240px] justify-self-end">
        <Input
          onSend={onSend}
          disabled={messages.some(m => m.status === Status.TYPING)}
        />
      </div>
    </div>
  )
}

export default ChatBox
