import { CircularProgress } from "@suraasa/placebo-ui-legacy"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { getToolName } from "features/AItools/helper"
import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import Heading from "../../Heading"
import { AIToolsContext } from "../context"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import UnitPlanOutput from "../GenerationOutput/UnitPlanOutput"
import { getOutputMode } from "../GenerationOutput/UnitPlanOutput/utils"
import ProForm from "./ProForm"

const UnitPlanGenerator = ({
  onGeneration,
}: {
  id?: string
  onGeneration: (data: ListPromptHistory) => void
}) => {
  const navigate = useNavigate()

  const {
    promptDataId,
    overviewData,
    setCurrentResponseId,
    setPromptDataId,
    // currentResponseId,
    setResponseIds,
    // responseIds,
  } = useContext(AIToolsContext)

  const outputMode = overviewData ? getOutputMode(overviewData) : ""

  useEffect(() => {
    if (!overviewData) return

    const { unitPlanResponses, unitDivisionResponses } = overviewData

    if (outputMode === "unitPlanIntermediate") {
      setCurrentResponseId(unitDivisionResponses[0])
      setResponseIds(unitDivisionResponses)
      return
    }

    if (outputMode === "unitPlanTopic" && unitPlanResponses) {
      setCurrentResponseId(unitPlanResponses[0]?.responseIds[0])
      setResponseIds(unitPlanResponses[0]?.responseIds)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewData])

  if (promptDataId && !overviewData) {
    return (
      <div className="flex flex-col items-center justify-center p-5">
        <CircularProgress />
      </div>
    )
  }
  if (overviewData) {
    return (
      <>
        {promptDataId && (
          <ContentActions
            onBack={() => {
              navigate(routes.aiTools.unitPlanner, { replace: true })
              setPromptDataId(null)
            }}
            currentResponseId={promptDataId}
            toolType={ToolType.unitPlanner}
            title={overviewData?.title}
            // Don't show export button on intermediate output
            hideExportOutputButton={outputMode === "unitPlanIntermediate"}
          />
        )}
        {/* <pre>
          {JSON.stringify(
            {
              promptDataId,
              id,
              currentResponseId,
              responseIds,
            },
            null,
            2
          )}
        </pre> */}

        <UnitPlanOutput />
      </>
    )
  }

  return (
    <>
      <Heading
        title={`${getToolName(ToolType.unitPlanner)} Generator`}
        subtitle="Effortlessly design detailed unit plans that organize your curriculum and streamline your teaching process."
        isPro
      />
      <ProForm
        onGeneration={res =>
          onGeneration({
            dateCreated: new Date().toString(),
            promptDataId: res.id,
            title: res.title,
            toolType: ToolType.unitPlanner,
          })
        }
      />
    </>
  )
}

export default UnitPlanGenerator
