import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"
import { getFingerprint } from "utils/fingerprint"

import {
  Email,
  OTPResponse,
  SocialAccount,
  UpdateUserPreferenceResponse,
  UserPreference,
} from "./types"
import { urls } from "./urls"

export default {
  emails: {
    list: async (): Promise<APIResponse<Email[]>> => {
      try {
        const res = await axios.get(urls.email.list())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Email[]>> => {
      try {
        const res = await axios.delete(urls.email.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    create: async ({ data }: Config): Promise<APIResponse<OTPResponse>> => {
      try {
        const res = await axios.post(urls.email.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    verify: async ({ data }: Config): Promise<APIResponse<Email>> => {
      try {
        const res = await axios.post(urls.email.verify(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Email[]>> => {
      try {
        const res = await axios.put(urls.email.detail(urlParams.id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  changePassword: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.password.changePassword(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getUserPreferences: async (): Promise<APIResponse<UserPreference[]>> => {
    try {
      const res = await axios.get(urls.config.preferences(), {
        timeout: 2000,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  updateUserPreferences: async ({
    data,
  }: Config): Promise<APIResponse<UpdateUserPreferenceResponse[]>> => {
    try {
      const res = await axios.post(urls.config.preferences(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  addPhoneNumber: async ({
    data,
  }: Config): Promise<APIResponse<OTPResponse>> => {
    try {
      const res = await axios.post(urls.phoneNumber.add(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  verifyOtp: async ({
    data,
  }: Config): Promise<APIResponse<Pick<OTPResponse, "token">>> => {
    try {
      const res = await axios.post(urls.otp.verify(), data, {
        headers: {
          "device-id": await getFingerprint(),
        },
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  sendOTP: async ({ data }: Config): Promise<APIResponse<OTPResponse>> => {
    try {
      const res = await axios.post(urls.otp.send(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  socialAuth: {
    list: async (): Promise<APIResponse<SocialAccount[]>> => {
      try {
        const res = await axios.get(urls.socialAuth.list())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    link: async ({ data }: Config): Promise<APIResponse<SocialAccount>> => {
      try {
        const res = await axios.post(urls.socialAuth.link(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    unlink: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.socialAuth.unlink(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
