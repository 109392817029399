import { Typography } from "@suraasa/placebo-ui"
import React from "react"

const MissedOlympiadBanner = () => {
  return (
    <div className="bg-[rgba(235,72,63,0.35)] bg-[radial-gradient(circle_at_25%_33%,rgba(215,46,32,0.45)_0px,transparent_45%)] p-3 shadow-[0px_6px_15px_0px_rgba(0,0,0,0.05)] sm:rounded-lg">
      <Typography variant="title3" className="mb-0.5 text-surface-500">
        You missed the Olympiad!
      </Typography>
      <Typography className="text-surface-500">
        Seems like you couldn’t attempt the Olympiad
      </Typography>
    </div>
  )
}

export default MissedOlympiadBanner
