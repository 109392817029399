import { LessonPlanResponse } from "api/resources/aiTools/types"

import Paper from "../../../../Paper"
import MarkdownRenderer from "../../MarkdownRenderer"
import RegenerateOutput from "../../RegenerateOutput"

const LessonPlanContent = ({ output, ...props }: LessonPlanResponse) => {
  return (
    <Paper
      endSlot={
        <RegenerateOutput
          copyText={output}
          isPositiveResponse={props.isPositiveResponse}
        />
      }
    >
      <MarkdownRenderer>{output}</MarkdownRenderer>
    </Paper>
  )
}

export default LessonPlanContent
