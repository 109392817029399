import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  Typography,
} from "@suraasa/placebo-ui"
import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
  AccordionSummary,
  Button,
  Divider,
  IconButton,
} from "@suraasa/placebo-ui-legacy"
import {
  Award,
  Project,
  Publication,
  TestScore,
} from "api/resources/profile/types"
import clsx from "clsx"
import { format } from "date-fns"
import AwardDialog from "features/Profile/components/Achievements/AwardDialog"
import ProjectDialog from "features/Profile/components/Achievements/ProjectDialog"
import PublicationDialog from "features/Profile/components/Achievements/PublicationDialog"
import TestScoreDialog from "features/Profile/components/Achievements/TestScoreDialog"
import Section from "features/Profile/components/Section"
import SectionHeading from "features/Profile/components/Section/SectionHeading"
import SectionTitle from "features/Profile/components/Section/SectionTitle"
import { Edit, Plus } from "iconoir-react"
import React, { useContext, useMemo, useState } from "react"
import { createUseStyles } from "react-jss"
import { pluralize } from "utils/helpers"
import { PropsWithClassName } from "utils/types"

import ProfileContext from "../../context"
import AwardsItem from "./AwardsItem"

const useStyles = createUseStyles(theme => ({
  content: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),

    [theme.breakpoints.only("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    },
  },
  accordionSummary: {
    height: "max-content",
    // paddingRight: theme.spacing(3),
  },
  accordionDetails: {
    position: "relative",
    marginTop: theme.spacing(1),
  },
  details: {
    "& .edit-button": {
      position: "absolute",
      zIndex: "1",
      background: "white",

      [theme.breakpoints.down("sm")]: {
        right: "0px",
      },
      [theme.breakpoints.up("md")]: {
        pointerEvents: "none",
        right: "-30px",
        opacity: "0",
        transition: ".2s all",
      },
    },
    "&:hover .edit-button": {
      [theme.breakpoints.up("md")]: {
        opacity: "1",
        right: "4px",
        pointerEvents: "all",
      },
    },
  },

  headerPaddingReset: {
    paddingLeft: 0,
    paddingRight: theme.spacing(0.5),
  },

  containerMarginBottom: {
    // marginBottom: theme.spacing(28),
    // [theme.breakpoints.down("xl")]: {
    //   marginBottom: theme.spacing(20),
    // },
    // [theme.breakpoints.down("sm")]: {
    //   marginBottom: theme.spacing(2),
    // },
  },
}))

const DIALOG_TYPE = {
  AWARD: "AWARD",
  TEST_SCORE: "TEST_SCORE",
  PROJECT: "PROJECT",
  PUBLICATION: "PUBLICATION",
} as const

type DialogType = keyof typeof DIALOG_TYPE

type ToggleDialogConditional<T extends DialogType> = T extends "AWARD"
  ? Award
  : T extends "TEST_SCORE"
  ? TestScore
  : T extends "PROJECT"
  ? Project
  : Publication

type AccordionName = "publications" | "awards" | "testScores" | "projects"

const Achievements = ({ className }: PropsWithClassName) => {
  const classes = useStyles()
  const [accordionOpen, setAccordionOpen] = useState<AccordionName[]>([])
  const [dialogType, setDialogType] = useState<DialogType | null>(null)
  const [dialogData, setDialogData] = useState<
    Award | Publication | TestScore | Project
  >()

  const {
    achievements: {
      awards: { data: awards },
      projects: { data: projects },
      publications: { data: publications },
      testScores: { data: testScores },
    },
    isPublic,
  } = useContext(ProfileContext)

  const toggleDialog = <
    T extends DialogType,
    K extends ToggleDialogConditional<T>
  >(
    type: T,
    data?: K
  ) => {
    setDialogType(type)
    setDialogData(data)
  }

  const mountDialog = useMemo(() => {
    switch (dialogType) {
      case "AWARD":
        return (
          <AwardDialog
            setOpen={() => setDialogType(null)}
            id={dialogData?.id}
            open={dialogType === DIALOG_TYPE.AWARD}
          />
        )
      case "PROJECT":
        return (
          <ProjectDialog
            setOpen={() => setDialogType(null)}
            id={dialogData?.id}
            open={dialogType === DIALOG_TYPE.PROJECT}
          />
        )
      case "PUBLICATION":
        return (
          <PublicationDialog
            setOpen={() => setDialogType(null)}
            id={dialogData?.id}
            open={dialogType === DIALOG_TYPE.PUBLICATION}
          />
        )
      case "TEST_SCORE":
        return (
          <TestScoreDialog
            handleClose={() => setDialogType(null)}
            id={dialogData?.id}
            open={dialogType === DIALOG_TYPE.TEST_SCORE}
          />
        )
      default:
    }
  }, [dialogType, dialogData])

  const openAccordion = (str: AccordionName) => {
    setAccordionOpen(prevState => {
      if (prevState.includes(str)) {
        return prevState.filter(item => item !== str)
      } else {
        return [...prevState, str]
      }
    })
  }
  return (
    <Section className={clsx(classes.containerMarginBottom, className)}>
      <SectionHeading
        heading={
          <SectionTitle
            actionIcon={
              isPublic ? undefined : (
                <div>
                  <DropdownMenu>
                    <DropdownMenu>
                      <IconButton>
                        <Plus />
                      </IconButton>
                    </DropdownMenu>
                    <DropdownMenuContent>
                      <DropdownMenuItem
                        onClick={() => toggleDialog("PUBLICATION")}
                      >
                        Publication
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => toggleDialog("AWARD")}>
                        Award & Honour
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => toggleDialog("TEST_SCORE")}
                      >
                        Test Score
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => toggleDialog("PROJECT")}>
                        Project
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              )
            }
            title="Achievements"
          />
        }
        xPadding={2}
      />

      {publications.length > 0 && (
        <div className={classes.content}>
          <Accordion
            expanded={accordionOpen.includes("publications")}
            onChange={() => {
              openAccordion("publications")
            }}
            className="py-2"
          >
            <AccordionHeader className={classes.headerPaddingReset}>
              <SectionTitle
                itemCount={publications.length}
                title={pluralize("Publication", publications.length, {
                  skipCount: true,
                })}
                typographyProps={{ className: "text-onSurface-700" }}
              />
              <AccordionSummary
                className={clsx(
                  classes.accordionSummary,
                  "grid transition-all delay-100	ease-in-out",
                  {
                    "opacity-100 grid-rows-[1fr]":
                      !accordionOpen.includes("publications"),
                    "opacity-0 grid-rows-[0fr]":
                      accordionOpen.includes("publications"),
                  }
                )}
              >
                <div className="overflow-hidden text-start">
                  {publications.map((publication, index) => (
                    <Typography
                      className="inline"
                      key={index}
                      variant="smallBody"
                    >
                      {publication.title}
                      {index < publications.length - 1 && (
                        <span className="mx-0.5">•</span>
                      )}
                    </Typography>
                  ))}
                </div>
              </AccordionSummary>
            </AccordionHeader>

            <AccordionDetails
              className={clsx("pl-2 pr-0", classes.accordionDetails)}
            >
              {publications.map((publication, index) => (
                <React.Fragment key={index}>
                  <div className={clsx(classes.details, "pr-3")}>
                    {!isPublic && (
                      <IconButton
                        className={clsx("edit-button")}
                        color="black"
                        onClick={() => toggleDialog("PUBLICATION", publication)}
                      >
                        <Edit />
                      </IconButton>
                    )}

                    <Typography className="mb-0.5" variant="strongSmallBody">
                      {publication.title}
                    </Typography>

                    <Typography
                      className="mb-0.25 text-onSurface-600"
                      variant="smallBody"
                    >
                      {publication.publisher}
                    </Typography>

                    {publication.publishedOn && (
                      <Typography
                        className="mb-1 text-onSurface-600"
                        variant="smallBody"
                      >
                        {format(new Date(publication.publishedOn), "MMMM yyyy")}
                      </Typography>
                    )}

                    <Typography
                      className="mb-1"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                      variant="smallBody"
                    >
                      {publication.description}
                    </Typography>

                    {publication.url && (
                      <Button
                        component="a"
                        href={publication.url}
                        nudge="left"
                        target="_blank"
                        variant="link"
                      >
                        View Publication
                      </Button>
                    )}
                  </div>

                  {index < publications.length - 1 && (
                    <div className="my-2">
                      <Divider color="onSurface.200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {awards.length > 0 && (
        <div className={classes.content}>
          <Accordion
            expanded={accordionOpen.includes("awards")}
            onChange={() => {
              openAccordion("awards")
            }}
            className="py-2"
          >
            <AccordionHeader className={classes.headerPaddingReset}>
              <SectionTitle
                itemCount={awards.length}
                title={`${pluralize("Award", awards.length, {
                  skipCount: true,
                })} and ${pluralize("Honor", awards.length, {
                  skipCount: true,
                })}`}
                typographyProps={{ className: "text-onSurface-700" }}
              />

              <AccordionSummary
                className={clsx(
                  classes.accordionSummary,
                  "grid transition-all delay-100	ease-in-out",
                  {
                    "opacity-100 grid-rows-[1fr]":
                      !accordionOpen.includes("awards"),
                    "opacity-0 grid-rows-[0fr]":
                      accordionOpen.includes("awards"),
                  }
                )}
              >
                <div className="overflow-hidden text-start">
                  {awards.map((award, index) => (
                    <Typography
                      className="inline"
                      key={award.id}
                      variant="smallBody"
                    >
                      {award.title}
                      {index < awards.length - 1 && (
                        <span className="mx-0.5">•</span>
                      )}
                    </Typography>
                  ))}
                </div>
              </AccordionSummary>
            </AccordionHeader>

            <AccordionDetails
              className={clsx("pl-2 pr-0", classes.accordionDetails)}
            >
              {awards.map((award, index) => (
                <React.Fragment key={award.id}>
                  <AwardsItem
                    data={award}
                    isPublic={isPublic}
                    onEdit={() => toggleDialog("AWARD", award)}
                  />
                  {index < awards.length - 1 && (
                    <div className="my-2">
                      <Divider color="onSurface.200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {testScores.length > 0 && (
        <div className={classes.content}>
          <Accordion
            expanded={accordionOpen.includes("testScores")}
            onChange={() => {
              openAccordion("testScores")
            }}
            className="py-2"
          >
            <AccordionHeader className={classes.headerPaddingReset}>
              <SectionTitle
                itemCount={testScores.length}
                title={pluralize("Test Score", testScores.length, {
                  skipCount: true,
                })}
                typographyProps={{ className: "text-onSurface-700" }}
              />
              <AccordionSummary
                className={clsx(
                  classes.accordionSummary,
                  "grid transition-all delay-100	ease-in-out",
                  {
                    "opacity-100 grid-rows-[1fr]":
                      !accordionOpen.includes("testScores"),
                    "opacity-0 grid-rows-[0fr]":
                      accordionOpen.includes("testScores"),
                  }
                )}
              >
                <div className="overflow-hidden text-start">
                  {testScores.map((score, index) => (
                    <Typography
                      className="inline"
                      key={index}
                      variant="smallBody"
                    >
                      {score.name}
                      {index < testScores.length - 1 && (
                        <span className="mx-0.5">•</span>
                      )}
                    </Typography>
                  ))}
                </div>
              </AccordionSummary>
            </AccordionHeader>

            <AccordionDetails
              className={clsx("pl-2 pr-0", classes.accordionDetails)}
            >
              {testScores.map((score, index) => (
                <React.Fragment key={index}>
                  <div className={clsx(classes.details, "pr-3")}>
                    {!isPublic && (
                      <IconButton
                        className={clsx("edit-button")}
                        color="black"
                        onClick={() => toggleDialog("TEST_SCORE", score)}
                      >
                        <Edit />
                      </IconButton>
                    )}

                    <Typography variant="strongSmallBody">
                      {score.name}
                    </Typography>

                    {score.score && (
                      <Typography
                        className="mb-0.25 text-onSurface-600"
                        variant="smallBody"
                      >
                        Score: {score.score}
                      </Typography>
                    )}

                    {score.testDate && (
                      <Typography
                        className="mb-1 text-onSurface-600"
                        variant="smallBody"
                      >
                        {format(new Date(score.testDate), "MMMM d, yyyy")}
                      </Typography>
                    )}

                    <Typography
                      className="mb-1"
                      style={{ whiteSpace: "pre-wrap" }}
                      variant="smallBody"
                    >
                      {score.description}
                    </Typography>

                    {(score.evidenceUrl || score.evidenceDocument) && (
                      <Button
                        component="a"
                        href={
                          (score.evidenceUrl as string) ??
                          (score.evidenceDocument as string)
                        }
                        nudge="left"
                        target="_blank"
                        variant="link"
                      >
                        View Result
                      </Button>
                    )}
                  </div>

                  {index < testScores.length - 1 && (
                    <div className="my-2">
                      <Divider color="onSurface.200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {projects.length > 0 && (
        <div className={classes.content}>
          <Accordion
            expanded={accordionOpen.includes("projects")}
            onChange={() => {
              openAccordion("projects")
            }}
            className="py-2"
          >
            <AccordionHeader className={classes.headerPaddingReset}>
              <SectionTitle
                itemCount={projects.length}
                title={pluralize("Project", projects.length, {
                  skipCount: true,
                })}
                typographyProps={{ className: "text-onSurface-700" }}
              />

              <AccordionSummary
                className={clsx(
                  classes.accordionSummary,
                  "grid transition-all delay-100	ease-in-out",
                  {
                    "opacity-100 grid-rows-[1fr]":
                      !accordionOpen.includes("projects"),
                    "opacity-0 grid-rows-[0fr]":
                      accordionOpen.includes("projects"),
                  }
                )}
              >
                <div className="overflow-hidden text-start">
                  {projects.map((project, index) => (
                    <Typography
                      className="inline"
                      key={index}
                      variant="smallBody"
                    >
                      {project.title}
                      {index < projects.length - 1 && (
                        <span className="mx-0.5">•</span>
                      )}
                    </Typography>
                  ))}
                </div>
              </AccordionSummary>
            </AccordionHeader>

            <AccordionDetails
              className={clsx("pl-2 pr-0", classes.accordionDetails)}
            >
              {projects.map((project, index) => (
                <React.Fragment key={index}>
                  <div className={clsx(classes.details, "pr-3")}>
                    {!isPublic && (
                      <IconButton
                        className={clsx("edit-button")}
                        color="black"
                        onClick={() => toggleDialog("PROJECT", project)}
                      >
                        <Edit />
                      </IconButton>
                    )}

                    <Typography variant="strongSmallBody">
                      {project.title}
                    </Typography>

                    {project.startDate && (
                      <Typography
                        className="mb-1 text-onSurface-600"
                        variant="smallBody"
                      >
                        {format(new Date(project.startDate), "MMMM yyyy")} -{" "}
                        {project.endDate
                          ? format(new Date(project.endDate), "MMMM yyyy")
                          : "Ongoing"}
                      </Typography>
                    )}

                    <Typography
                      className="mb-1"
                      style={{ whiteSpace: "pre-wrap" }}
                      variant="smallBody"
                    >
                      {project.description}
                    </Typography>

                    {project.url && (
                      <Button
                        component="a"
                        href={project.url}
                        nudge="left"
                        target="_blank"
                        variant="link"
                      >
                        View Project
                      </Button>
                    )}
                  </div>

                  {index < projects.length - 1 && (
                    <div className="my-2">
                      <Divider color="onSurface.200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {publications.length === 0 &&
        awards.length === 0 &&
        testScores.length === 0 &&
        projects.length === 0 && (
          <Typography
            className="px-3 py-2 text-onSurface-400"
            variant="smallBody"
          >
            {isPublic
              ? "Looks like there's nothing here."
              : "You haven’t added any Achievements. Click on + icon to add one."}
          </Typography>
        )}

      {mountDialog}
    </Section>
  )
}

export default Achievements
