import { toast, Typography } from "@suraasa/placebo-ui"
import { Button, TextField } from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { SavedAddress as Address } from "api/resources/payment/types"
import { APIError } from "api/utils"
import Card from "components/Card"
import CollapseWrapper from "components/CollapseWrapper"
import GoogleLocationSelect, {
  AddressComponents,
  AddressTypeEnum,
} from "components/GoogleLocationSelect.tsx"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

export type NewAddress = {
  address: string | null
  country: string | null
  state: string | null
  location: AddressComponents | null
}

type Props = {
  onAdd: () => void
  onChangeClicked: () => void
}

const AddAddress = (props: Props) => {
  const [showForm, setShowForm] = useState(true)

  const {
    register,
    control,
    setError,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<NewAddress>()

  const setAddress = useMutation({
    mutationFn: (address: Address) =>
      api.payment.updateCart({
        data: {
          billingAddress: address.uuid,
        },
      }),
    onSuccess: () => {
      props.onAdd()
      toggleShowForm(false)
    },
    onError: err => {
      if (err instanceof APIError) {
        if (err.errors.message) handleErrors(err, { setter: setError })
      }
    },
  })

  const createAddress = useMutation({
    mutationFn: (data: NewAddress) => {
      const country = data.location?.find(item =>
        item.types.includes(AddressTypeEnum.COUNTRY)
      )
      const state = data.location?.find(item =>
        item.types.includes(AddressTypeEnum.STATE)
      )

      setValue("state", state?.longText || "")
      setValue("country", country?.longText || "")

      return api.payment.createAddress({
        data: {
          address: data.address ?? null,
          pincode: null,
          city: null,
          country: country
            ? { name: country.longText, isoCode: country.shortText }
            : null,
          state: state
            ? { name: state.longText, isoCode: state.shortText }
            : null,
        },
      })
    },
    onSuccess: res => {
      setAddress.mutate(res)
    },
    onError: err => {
      if (err instanceof APIError) {
        if (err.errors.message) toast.error(err.errors.message)
      }
    },
  })

  const onSubmit = handleSubmit(data => {
    createAddress.mutate(data)
  })

  const toggleShowForm = (state: boolean) => {
    setShowForm(state)
  }

  return (
    <Card padding={2} className="mt-2">
      <div>
        <CollapseWrapper open={!showForm}>
          <div className="flex w-full items-center justify-between">
            <Typography variant="strong" className="text-end">
              {getValues("state")}, {getValues("country")}
            </Typography>
            <Button
              variant="text"
              onClick={() => {
                props.onChangeClicked()
                toggleShowForm(true)
              }}
            >
              Change{" "}
              <span className="hidden sm:inline-block">&nbsp;Address</span>
            </Button>
          </div>
        </CollapseWrapper>
        <CollapseWrapper open={showForm}>
          <form onSubmit={onSubmit}>
            <Controller
              control={control}
              name="location"
              render={({ field: { onChange } }) => (
                <GoogleLocationSelect
                  fullWidth
                  label="City/District"
                  required
                  className="w-full"
                  placeHolder="Enter your location"
                  error={Boolean(errors.location)}
                  helperText={errors.location?.message}
                  onChange={val => {
                    onChange(val)
                  }}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />

            <Typography
              variant="smallBody"
              className="mt-0.5 text-onSurface-500"
            >
              Required to calculate appropriate government taxes
            </Typography>
            <div className="mb-0.75 mt-2">
              <Typography variant="strong">
                Billing Address{" "}
                <Typography
                  variant="body"
                  className="inline text-onSurface-500"
                >
                  (Optional)
                </Typography>
              </Typography>
            </div>
            {/* @ts-expect-error random-types-issue */}
            <TextField
              rows={3}
              error={Boolean(errors.address)}
              helperText={errors.address?.message}
              placeholder="Building number, street name"
              multiLine
              fullWidth
              {...register("address")}
            />
            <Button
              fullWidth
              type="submit"
              className="mt-3"
              loading={createAddress.isLoading || setAddress.isLoading}
            >
              Save
            </Button>
          </form>
        </CollapseWrapper>
      </div>
    </Card>
  )
}

export default AddAddress
