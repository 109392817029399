import { Typography } from "@suraasa/placebo-ui"
import { Chip, IconButton } from "@suraasa/placebo-ui-legacy"
import { EmploymentType, WorkExperienceType } from "api/resources/profile/types"
import TruncatedText from "components/TruncatedText"
import { format } from "date-fns"
import { Edit } from "iconoir-react"
import capitalize from "lodash/capitalize"

const getEmploymentTypeDisplay = (value: EmploymentType | null) => {
  if (!value) return null
  return capitalize(value.replace("_", " "))
}

const WorkExperienceItem = ({
  onEdit,
  data,
  isPublic,
}: {
  onEdit?: () => void
  data: WorkExperienceType
  isPublic?: boolean
}) => {
  return (
    <div className="group relative " key={data.id}>
      {!isPublic && onEdit && (
        <IconButton
          className="duration-[all] absolute right-0 transition-[0.2s] md:right-[-30px] md:opacity-0 group-hover:md:right-0 group-hover:md:opacity-100"
          color="black"
          onClick={() => onEdit()}
        >
          <Edit />
        </IconButton>
      )}
      <div>
        <span className="mb-0.5 flex items-center gap-1">
          <Typography variant="strongSmallBody">{data.title}</Typography>
          {data.curriculum && (
            <Chip
              color="secondary"
              label={data.curriculum.name}
              size="sm"
              variant="outlined"
            />
          )}
        </span>

        <Typography className="mb-0.25 text-onSurface-600" variant="smallBody">
          {[
            getEmploymentTypeDisplay(data.employmentType),
            data.teachingLevel?.name,
          ]
            .filter(Boolean)
            .join(", ")}
        </Typography>

        <Typography className="mb-0.25 text-onSurface-600" variant="smallBody">
          {[
            data.organisationName,
            [data.state?.name, data.country?.name].filter(Boolean).join(", "),
          ]
            .filter(Boolean)
            .join(" • ")}
        </Typography>

        {data.startDate && (
          <Typography className="text-onSurface-600" variant="smallBody">
            {format(new Date(data.startDate), "MMMM yyyy")} -{" "}
            {data.currentlyWorking
              ? "Present"
              : data.endDate && format(new Date(data.endDate), "MMMM yyyy")}
          </Typography>
        )}

        {data.description && (
          <TruncatedText
            className="mt-1"
            maxLength={300}
            style={{
              whiteSpace: "pre-wrap",
            }}
            variant="smallBody"
          >
            {data.description}
          </TruncatedText>
        )}
      </div>
    </div>
  )
}

export default WorkExperienceItem
