import * as Sentry from "@sentry/react"
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  Notification,
  NotificationActionName,
  NotificationResponse,
} from "api/resources/notifications/types"
import { PaginatedAPIResponse } from "api/types"
import { useNavigate } from "react-router-dom"
import routes from "routes"
import { getPlatformURL } from "utils/helpers"

import { TabNames } from "./useNotifications"

export const useNotificationAction = (notification: Notification) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const updateState = (
    state: InfiniteData<PaginatedAPIResponse<NotificationResponse>> | undefined
  ) => {
    if (state) {
      return {
        ...state,
        pages: state.pages.map(page => ({
          ...page,
          data: {
            ...page.data,
            notifications: page.data.notifications.map(x =>
              x.id === notification.id
                ? { ...x, dateOpened: new Date().toISOString() }
                : x
            ),
          },
        })),
      }
    }
    return state
  }

  const { mutate } = useMutation({
    mutationFn: () =>
      api.notifications.markAsOpened({
        params: {
          id: notification.id,
        },
      }),
    onSuccess: () => {
      queryClient.setQueryData(
        queries.notifications.notificationList(TabNames.ALL).queryKey,
        updateState
      )
      queryClient.setQueryData(
        queries.notifications.notificationList(TabNames.LEARN).queryKey,
        updateState
      )
      queryClient.setQueryData(
        queries.notifications.notificationList(TabNames.JOBS).queryKey,
        updateState
      )
    },
  })

  const navigationAction = (
    route: string,
    options?: {
      requiredKeys?: string[]
      data?: any
      isExternalNavigation?: boolean
    }
  ) => {
    mutate()

    const { action } = notification

    if (!action) {
      return null
    }
    if (!("name" in action)) return null
    if (!action.name) return null

    if (options?.requiredKeys) {
      for (const key of options.requiredKeys) {
        if (!options?.data[key]) {
          const message = `NotificationError: Malformed notification data for type ${action.name}. ("${key}" not found)`
          console.error(message, options.data)
          Sentry.captureException(new Error(message), scope => {
            scope.setExtras({
              notification: JSON.stringify(notification),
            })
            return scope
          })
          return
        }
      }
    }

    if (options?.isExternalNavigation) {
      window.location.href = route
      return
    }

    navigate(route)
  }

  const hasAction =
    notification.action &&
    "name" in notification.action &&
    Boolean(notification.action.name)

  const handleClick = () => {
    const { action } = notification
    if (!action) {
      return null
    }
    if (!("name" in action)) return null
    if (!action.name) return null

    switch (action.name) {
      case NotificationActionName.OPEN_JOB: {
        const route = action.data.jobPostSlug
          ? getPlatformURL(
              "jobs",
              `/school/${action.data.schoolSlug}?jobSlug=${action.data.jobPostSlug}`
            )
          : getPlatformURL("jobs", `/home?tab=Invitations`)

        return navigationAction(route, {
          requiredKeys: action.data.jobPostSlug
            ? ["schoolSlug", "jobPostSlug"]
            : [],
          data: action.data,
          isExternalNavigation: true,
        })
      }
      case NotificationActionName.OPEN_LEARNING_ITEM_OVERVIEW:
        return navigationAction(
          `/${action.data.learningItemType.toLowerCase()}/${action.data.slug}`,
          { requiredKeys: ["learningItemType", "slug"], data: action.data }
        )
      case NotificationActionName.REDIRECT_TO_DISCUSSION_DETAIL:
      case NotificationActionName.REDIRECT_TO_DISCUSSIONS_DETAIL:
        return navigationAction(
          `/discussions/${action.data.userDiscussionId}`,
          { requiredKeys: ["userDiscussionId"], data: action.data }
        )
      case NotificationActionName.REDIRECT_TO_ASSESSMENT_DETAIL: {
        return navigationAction(
          routes.attemptAssessment.replace(
            ":assessmentId",
            action.data.assessmentUuid
          ),
          { requiredKeys: ["assessmentUuid"], data: action.data }
        )
      }
      case NotificationActionName.REDIRECT_TO_ASSIGNMENT_PAGE_DETAIL: {
        if (action.data.submissionUuid) {
          return navigationAction(
            `/assignments/${action.data.assignmentUuid}/${action.data.submissionUuid}`,
            {
              requiredKeys: ["assignmentUuid", "submissionUuid"],
              data: action.data,
            }
          )
        }
        //TODO: setup link to assignment
        // navigate(
        //   routes.parentItemAssignment
        //     .replace(":slug", extras.slug!)
        //     .replace(":learningItemType", "course")
        //     .replace(":assignmentId", item.id.toString())
        // )
        // return navigationAction(
        //   routes.assignment.replace(
        //     ":assignmentId",
        //     action.data.assignmentUuid
        //   ),
        //   { requiredKeys: ["assignmentUuid"], data: action.data }
        // )
        return () => {}
      }
      // case NotificationActionName.REDIRECT_TO_CLASS_DETAIL: {
      // classId is not sufficient for this use case
      //   return navigationAction(`/course/${action.data.classId}/classes`, {
      //     requiredKeys: ["classId"],
      //     data: action.data,
      //   })
      // }
      case NotificationActionName.REDIRECT_TO_CENTRE_SCHEDULE_DETAIL: {
        return navigationAction(routes.mySchedule)
      }
      case NotificationActionName.REDIRECT_TO_COURSE_CERTIFICATE_DETAIL: {
        return navigationAction(routes.certificates)
      }

      case NotificationActionName.REDIRECT_TO_CERTIFICATION_CERTIFICATE_DETAIL: {
        return navigationAction(routes.certificates)
      }

      case NotificationActionName.REDIRECT_TO_QUALIFICATION_CERTIFICATE_DETAIL: {
        return navigationAction(routes.certificates)
      }

      case NotificationActionName.TRANSACTION_FAILED:
      case NotificationActionName.TRANSACTION_SUCCESSFUL:
      case NotificationActionName.REDIRECT_TO_ORDER_DETAIL: {
        return navigationAction(
          routes.orderDetailsPage.replace(":id", action.data.orderId),
          { requiredKeys: ["orderId"], data: action.data }
        )
      }

      case NotificationActionName.REDIRECT_TO_HOMEPAGE: {
        return navigationAction(routes.home)
      }

      case NotificationActionName.ITEMS_ALLOCATED:
      case NotificationActionName.REDIRECT_TO_MY_LIBRARY: {
        return navigationAction(routes.learning)
      }

      default: {
        const message = `NotificationError: Action type not handled for type ${action.name}`
        console.error(message, action)
        Sentry.captureException(new Error(message), scope => {
          scope.setExtras({
            notification: JSON.stringify(notification),
          })
          return scope
        })
      }
    }
  }

  return { handleNotificationClick: handleClick, hasAction }
}
