import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getOpheliaURL = getServiceURL(SERVICE_PREFIX.ophelia)

export const urls = validateUrls({
  submission: slug => getOpheliaURL(`/v1/submissions/events/${slug}/`),
  checkEligibility: slug => getOpheliaURL(`/v1/events/${slug}/eligibility/`),
})

export default urls
