import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@suraasa/placebo-ui"
import { Container, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import Monitor from "assets/Fallback/monitor.svg"
import Fallback from "components/Fallback"
import React, { useEffect, useState } from "react"
import { useTheme } from "react-jss"
import { useParams, useSearchParams } from "react-router-dom"
import { getAuthInfo } from "utils/helpers"

import EvidenceDetails from "./EvidenceDetails"
import Evidences from "./Evidences"
import Overview from "./Overview"
import SkillOverview from "./SkillOverview"

type SkillProfileControllerContextType = {
  courseId?: string
  submissionId?: string
  update: (data: Partial<SkillProfileControllerContextType>) => void
}

const defaultValue: SkillProfileControllerContextType = {
  update: () => {},
}

export const SkillProfileControllerContext =
  React.createContext<SkillProfileControllerContextType>(defaultValue)

const SkillProfile = () => {
  const [skillProfileController, setSkillProfileController] =
    useState<SkillProfileControllerContextType>(defaultValue)

  const [searchParams, setSearchParams] = useSearchParams()

  const { username } = useParams<{ username?: string }>()
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const loggedInUserName = getAuthInfo()?.user.username
  const isSelf = username ? loggedInUserName === username : true

  const {
    data: profile,
    isLoading,
    isError,
  } = useQuery({
    queryKey: queries.profile.retrieve().queryKey,
    queryFn: () =>
      api.profile.retrieve({
        urlParams: {
          username: username ?? "",
        },
      }),
  })

  const {
    data: skillEvidenceList,
    isLoading: isSkillLoading,
    isError: isSkillError,
  } = useQuery({
    queryKey: queries.profile.skillEvidenceList().queryKey,
    queryFn: () =>
      api.profile.skillEvidences.list({
        urlParams: {
          username: username ?? "",
        },
      }),
  })
  const updateSkillProfileController = (
    data: Partial<SkillProfileControllerContextType>
  ) => {
    setSkillProfileController(prev => ({ ...prev, ...data }))
  }

  useEffect(() => {
    const courseSlug = searchParams.get("course")
    if (courseSlug) {
      updateSkillProfileController({
        courseId: courseSlug,
      })
      searchParams.delete("course")
      setSearchParams(searchParams, { replace: true })
    }
  }, [searchParams])

  if (isError || isLoading) {
    return <Fallback isLoading={isLoading} hasError={isError} />
  }

  if (isSkillError || isSkillLoading) {
    return (
      <>
        <Overview data={profile} />
        <Container fullWidth={isXsDown} className="pb-8">
          <Fallback
            isLoading={isSkillLoading}
            className="mt-4"
            data={{ image: Monitor, title: "No Skill Evidences Available!" }}
          />
        </Container>
      </>
    )
  }

  return (
    <SkillProfileControllerContext.Provider
      value={{
        ...skillProfileController,
        update: updateSkillProfileController,
      }}
    >
      <Overview data={profile} />
      <Container className="pb-8">
        <SkillOverview
          skillEvidences={skillEvidenceList.skills}
          isLoading={isSkillLoading}
          isError={isSkillError}
          profile={profile}
        />

        <Sheet
          open={Boolean(skillProfileController.courseId)}
          onOpenChange={() => {
            updateSkillProfileController({ courseId: undefined })
          }}
        >
          <SheetContent
            side="right"
            className="w-full max-w-full sm:max-w-[60%]"
            height={90}
          >
            <SheetHeader className="p-1">
              <SheetTitle>Evidences</SheetTitle>
            </SheetHeader>

            <SheetBody>
              {skillProfileController.courseId && (
                <Evidences
                  courseSlug={skillProfileController.courseId}
                  isSelf={isSelf}
                  skillEvidences={skillEvidenceList.skills}
                />
              )}
            </SheetBody>
          </SheetContent>
        </Sheet>

        <Sheet
          open={Boolean(skillProfileController.submissionId)}
          onOpenChange={() => {
            updateSkillProfileController({ submissionId: undefined })
          }}
        >
          <SheetContent
            side="right"
            className="w-full max-w-full sm:max-w-[60%]"
            height={90}
          >
            <SheetHeader className="p-1">
              <SheetTitle>Skill Evidence</SheetTitle>
            </SheetHeader>
            <SheetBody className="h-full">
              {skillProfileController.submissionId && (
                <EvidenceDetails
                  submissionId={skillProfileController.submissionId}
                  isSelf={isSelf}
                  firstName={profile.user.firstName}
                />
              )}
            </SheetBody>
          </SheetContent>
        </Sheet>
      </Container>
    </SkillProfileControllerContext.Provider>
  )
}

export default SkillProfile
