import { toast, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  CircularProgress,
  Select,
  TextField,
} from "@suraasa/placebo-ui-legacy"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  ListPromptHistory,
  NarrationForm,
  ToolType,
} from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import {
  BLOOMS_TAXONOMY_OPTIONS,
  DURATION_MINUTES,
  getToolName,
} from "features/AItools/helper"
import { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import routes from "routes"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import AiToolsLoading from "../../AiToolsLoading"
import Heading from "../../Heading"
import Paper from "../../Paper"
import { AIToolsContext } from "../context"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import NarrationOutput from "../GenerationOutput/NarrationOutput"
import SelectLessonPlanSheet from "../helpers/SelectLessonPlanSheet"
import NoLessonPlannerDialog from "../SubjectiveAssessmentGenerator/NoLessonPlannerDialog"

const toolType = ToolType.narration

const NarrationGenerator = ({
  id,
  onGeneration,
}: {
  id?: string
  onGeneration: (data: ListPromptHistory) => void
}) => {
  const navigate = useNavigate()
  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<NarrationForm>()

  const { currentResponseId, overviewData, promptDataId, setPromptDataId } =
    useContext(AIToolsContext)
  const [lessonPlanSheetOpen, setLessonPlanSheetOpen] = useState(false)

  const [openDialog, setOpenDialog] = useState(false)
  const onClose = () => {
    setOpenDialog(false)
  }

  const onCreateLessonPlan = () => {
    navigate(routes.aiTools.lessonPlan)
  }

  const content = useQuery({
    enabled: Boolean(currentResponseId),
    queryKey: queries.aiTools.retrieveHistory(toolType, currentResponseId)
      .queryKey,
    queryFn: async () => {
      const res = await api.aiTools.retrieveContent<typeof toolType>({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { type: toolType, id: currentResponseId! },
      })
      return res
    },
  })

  useEffect(() => {
    if (content.error instanceof APIError) {
      toast.error(
        content.error.message || `Unable to fetch ${getToolName(toolType)}`
      )
    }
  }, [content.error])

  const { mutate, isLoading } = useMutation({
    mutationFn: async (
      data:
        | NarrationForm
        | { lessonPlan: number; generatedWithLessonPlan: boolean }
    ) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
      })
      return api.aiTools.generateNarration({ data })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
      })

      onGeneration({
        dateCreated: new Date().toString(),
        promptDataId: res.id,
        title: res.title,
        toolType: toolType,
      })
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) > 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const onSubmit = handleSubmit(async data => {
    mutate(data)
  })

  const lessonPlanList = useQuery({
    queryFn: x =>
      api.aiTools.listHistory({
        type: ToolType.lessonPlan,
        params: {
          page: x.pageParam || 1,
        },
      }),
    onSuccess: data => {
      if (data.total > 0) {
        setLessonPlanSheetOpen(true)
      } else {
        setOpenDialog(true)
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        console.log(err.statusCode)
        console.log(err.is500)
        console.log(err.__errors)
      }
    },

    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
    enabled: false,
  })

  if (id && content.isLoading) {
    return (
      <div className="flex flex-col items-center justify-center p-5">
        <CircularProgress />
      </div>
    )
  }

  if (content.data && promptDataId) {
    return (
      <>
        <ContentActions
          onBack={() => {
            navigate(routes.aiTools.narration, { replace: true })
            setPromptDataId(null)
          }}
          currentResponseId={currentResponseId}
          toolType={toolType}
          title={overviewData?.title}
        />
        <NarrationOutput {...content.data} />
      </>
    )
  }

  return (
    <>
      <SelectLessonPlanSheet
        open={lessonPlanSheetOpen}
        onOpenChange={setLessonPlanSheetOpen}
        onSelect={lp => {
          mutate({
            lessonPlan: lp.promptDataId,
            generatedWithLessonPlan: false,
          })
        }}
      />
      <NoLessonPlannerDialog
        open={openDialog}
        onClose={onClose}
        onCreateLessonPlan={onCreateLessonPlan}
        description="Looks like you haven’t generated any Lesson Plans. You can either generate a Lesson Plan to create a narration from or create a narration from scratch."
      />
      <Heading
        title={`${getToolName(toolType)} Generator`}
        slot={
          <Button
            onClick={() => {
              lessonPlanList.refetch()
            }}
            size="sm"
            loading={lessonPlanList.isFetching}
          >
            Select Lesson Plan
          </Button>
        }
        subtitle="A tool that helps you enhance delivery and make your sessions more engaging. It converts your lesson plan into a narrative format."
      />
      <Paper>
        <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            <TextField
              inputLabelProps={{ required: true }}
              label="Class/Grade"
              fullWidth
              placeholder="Ex: 12th Grade"
              error={Boolean(errors.grade)}
              helperText={errors.grade?.message}
              {...register("grade", {
                required: { value: true, message: "Required" },
              })}
            />

            <TextField
              {...register("curriculum", {
                required: { value: true, message: "Required" },
              })}
              inputLabelProps={{ required: true }}
              error={Boolean(errors.curriculum)}
              label="Curriculum"
              fullWidth
              placeholder="Ex: CBSE"
              helperText={errors.curriculum?.message}
            />
          </div>
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            <TextField
              inputLabelProps={{ required: true }}
              label="Subject"
              fullWidth
              placeholder="Ex: Arts and Craft"
              error={Boolean(errors.subject)}
              helperText={errors.subject?.message}
              {...register("subject", {
                required: { value: true, message: "Required" },
              })}
            />
            <Controller
              control={control}
              name="duration"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  inputLabelProps={{ required: true }}
                  label="Class Duration"
                  error={Boolean(errors.duration)}
                  helperText={errors.duration?.message}
                  options={DURATION_MINUTES}
                  placeholder="Ex: 60 Minutes"
                  value={DURATION_MINUTES.find(c => c.value === value)}
                  fullWidth
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => onChange(val?.value)}
                />
              )}
              rules={{ required: { value: true, message: "Required" } }}
            />
          </div>
          <TextField
            inputLabelProps={{ required: true }}
            fullWidth
            label="topic"
            placeholder="Ex: Rajasthani Paintings"
            error={Boolean(errors.topic)}
            helperText={errors.topic?.message}
            {...register("topic", {
              required: { value: true, message: "Required" },
            })}
          />
          {/* @ts-expect-error: placebo rows prop error */}
          <TextField
            multiLine
            inputLabelProps={{ required: true }}
            fullWidth
            rows={3}
            label="Objective/Description"
            placeholder="Ex: To understand the history of Rajasthani paintings and its significance in Indian art."
            error={Boolean(errors.lessonPlanObjectives)}
            helperText={errors.lessonPlanObjectives?.message}
            {...register("lessonPlanObjectives", {
              required: { value: true, message: "Required" },
            })}
          />
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            <TextField
              {...register("country", {
                required: { value: true, message: "Required" },
              })}
              inputLabelProps={{ required: true }}
              error={Boolean(errors.country)}
              label="Country"
              fullWidth
              placeholder="Ex: India"
              helperText={
                errors.country?.message ||
                `We use this to tailor the ${getToolName(
                  toolType
                )} to your region`
              }
            />
            <div className="flex flex-[50%] shrink-0 flex-col">
              <Controller
                control={control}
                name="bloomTaxonomyLevel"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    inputLabelProps={{ required: true }}
                    isMulti
                    closeMenuOnSelect={false}
                    label="Bloom's Taxonomy Level"
                    error={Boolean(errors.bloomTaxonomyLevel)}
                    helperText={errors.bloomTaxonomyLevel?.message}
                    options={BLOOMS_TAXONOMY_OPTIONS}
                    placeholder="Ex: Create"
                    value={
                      value &&
                      BLOOMS_TAXONOMY_OPTIONS.filter(c =>
                        value.includes(c.value)
                      )
                    }
                    fullWidth
                    mountOnBody
                    onBlur={onBlur}
                    onChange={val => onChange(val.map(x => x.value))}
                  />
                )}
                rules={{ required: { value: true, message: "Required" } }}
              />
              <Link
                to="https://www.suraasa.com/blog/learning-taxonomies"
                target="_blank"
              >
                <Typography
                  className="mt-0.5 text-interactive-500 hover:underline"
                  variant="smallBody"
                >
                  What is Bloom&apos;s Taxonomy?
                </Typography>
              </Link>
            </div>
          </div>
          <Button fullWidth type="submit" loading={isLoading}>
            Generate {getToolName(toolType)}
          </Button>
        </form>
      </Paper>
      <AiToolsLoading
        type={toolType}
        open={isLoading}

        // onClose={() => {
        //   setLoading(false)
        // }}
      />
    </>
  )
}

export default NarrationGenerator
