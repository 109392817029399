import {
  Dialog,
  DialogContent,
  IconButton,
  Theme,
  useMediaQuery,
  VideoPlayer,
} from "@suraasa/placebo-ui-legacy"
import { XmarkCircle } from "iconoir-react"
import React from "react"
import { useTheme } from "react-jss"

import styles from "./Classes.module.css"
type Props = {
  recordingURL: string | null
  onClose: () => void
}

const VideoPlayerDialog = ({ recordingURL, onClose }: Props) => {
  const theme = useTheme<Theme>()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  return (
    <Dialog open={Boolean(recordingURL)} width={isMdUp ? "800px" : "100%"}>
      <div className={styles.dialogContent}>
        <DialogContent>
          <IconButton
            onClick={onClose}
            className="absolute z-10 ml-1 mt-1 self-start sm:mr-1 sm:self-end"
          >
            <XmarkCircle />
          </IconButton>
          {recordingURL && (
            <VideoPlayer
              src={recordingURL}
              defaultLanguage="english"
              languages={[{ id: "english", name: "English" }]}
            />
          )}
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default VideoPlayerDialog
