import {
  CircularProgress,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  DropdownMenuTrigger,
  toast,
} from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { ToolType } from "api/resources/aiTools/types"
import { SocialAuthProvider } from "api/resources/settings/types"
import { APIError } from "api/utils"
import GoogleDocs from "assets/icons/google-docs.svg"
import MsWord from "assets/icons/ms-word.svg"
import AsyncBuilder from "components/AsyncBuilder"
import BackButton from "components/BackButton"
import { getToolName } from "features/AItools/helper"
import { useSuraasaProContext } from "features/AItools/PRO/context/SuraasaProContext"
import { googleAuthHandler } from "features/Settings/SocialAccounts/utils"
import { useGlobalContext } from "global/Context/context"
import { MultiplePages } from "iconoir-react"
import { useContext, useState } from "react"
import {
  capitalizeFirstLetter,
  handleErrors,
  saveBlobAsFile,
} from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../context"
import ConnectGoogleAccount from "./ConnectGoogleAccount"
import LinkReadyDialog from "./LinkReadyDialog"

const mimeType: Record<DocType, string> = {
  ms_word:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  pdf: "application/pdf",
  google_doc: "",
} as const

const docTypes = {
  msWord: "ms_word",
  pdf: "pdf",
  googleDoc: "google_doc",
} as const

type DocType = (typeof docTypes)[keyof typeof docTypes]

const exportOptions = [
  {
    label: "PDF",
    value: docTypes.pdf,
    icon: <MultiplePages />,
  },
  {
    label: "Google Docs",
    value: docTypes.googleDoc,
    icon: <img src={GoogleDocs} alt="google-docs-icon" className="w-3" />,
  },
  {
    label: "MS Word",
    value: docTypes.msWord,
    icon: <img src={MsWord} alt="ms-word-icon" className="w-3" />,
  },
] as const

const checkHasDriveScope = (scope: string | null | undefined) => {
  if (!scope) {
    return false
  }

  return (
    scope.includes("https://www.googleapis.com/auth/drive.file") ||
    scope.includes("https://www.googleapis.com/auth/drive.readonly")
  )
}

export const ExportOutputButton = ({
  currentResponseId,
  toolType,
  title,
  children,
  asSubMenu,
}: {
  currentResponseId: string | number
  toolType: ToolType
  title?: string
  children?: React.ReactNode
  asSubMenu?: boolean
}) => {
  const { isActive: isProActive, showGetProSheet } = useSuraasaProContext()
  const [showConnectDrive, setShowConnectDrive] = useState(false)
  const { socialAccounts, refetchSocialAccounts } = useGlobalContext()
  const [relinkGoogleAccount, setRelinkGoogleAccount] = useState(false)

  const [showLinkDialog, setShowLinkDialog] = useState({
    show: false,
    link: "",
  })

  const [menuOpen, setMenuOpen] = useState(false)

  const [callbackDownloading, setCallbackDownloading] = useState<{
    type: DocType
  } | null>(null)

  const googleConnection = socialAccounts.find(
    s => s.provider === SocialAuthProvider.GOOGLE
  )
  const driveConnected = checkHasDriveScope(googleConnection?.scope)

  const handleExport = async (docType: DocType, checkProAccess = true) => {
    if (checkProAccess && !isProActive && docType !== docTypes.pdf) {
      showGetProSheet({
        tool: toolType,
        claimCallback: async () => {
          setMenuOpen(true)
          setCallbackDownloading({
            type: docType,
          })
          await handleExport(docType, false)
          setCallbackDownloading(null)
          setMenuOpen(false)
        },
      })
      return
    }
    switch (docType) {
      case docTypes.googleDoc:
        await exportAsGoogleDoc()
        break
      case docTypes.pdf:
      case docTypes.msWord:
        await exportAsFile(docType)
    }
  }

  const exportAsFile = async (docType: DocType) => {
    try {
      const res = await api.aiTools.export({
        urlParams: {
          id: currentResponseId,
          type: toolType,
        },
        params: {
          doc_type: docType,
        },
        responseType: "blob",
      })

      if (res.__data) {
        saveBlobAsFile({
          data: res.__data?.data,
          type: mimeType[docType],
          name: title
            ? `${getToolName(toolType)} - ${capitalizeFirstLetter(title)}`
            : getToolName(toolType),
        })

        toast.success("File downloaded successfully!")

        trackingService.trackEvent("ai_tools_export_success", {
          tool_type: toolType,
          doc_type: docType,
        })
      }
    } catch (e: any) {
      console.error(e)
      toast.error("Unable to process your request")
      trackingService.trackEvent("ai_tools_export_failed", {
        doc_type: docType,
        tool_type: toolType,
        status_code: e?.statusCode,
        error: JSON.stringify(e),
      })
    }
  }

  const connectGoogleDrive = async (email?: string) => {
    const result = await googleAuthHandler({ withDriveScope: true, email })

    if (result === null || !result.accessToken) {
      toast.error("Error signing in.")

      return { success: false }
    }

    try {
      // @ts-expect-error _tokenResponse exist
      const scope = JSON.parse(result._tokenResponse.rawUserInfo)

      await api.settings.socialAuth.link({
        data: {
          accessToken: result.accessToken,
          provider: SocialAuthProvider.GOOGLE,
          scope: scope.granted_scopes,
        },
      })
      refetchSocialAccounts()
    } catch (err) {
      console.log(err)
      if (err instanceof APIError) {
        handleErrors(err)
      } else {
        toast.error("Something went wrong. Please try again.")
      }

      return { success: false }
    }

    return { success: true, email: result.user.email }
  }

  const exportAsGoogleDoc = async (force = false) => {
    if (!relinkGoogleAccount && !googleConnection && !force) {
      setShowConnectDrive(true)
      return
    }

    let hasDriveConnected = driveConnected

    if (!googleConnection || relinkGoogleAccount) {
      // Start google login with drive scope this will connect google auth and drive
      const result = await connectGoogleDrive("")
      if (!result.success) {
        toast.error("Failed connecting account")
        setShowConnectDrive(false)
        setRelinkGoogleAccount(false)
        return
      }
      hasDriveConnected = true
    }
    if (googleConnection) {
      // Check if google auth has drive scope
      hasDriveConnected = checkHasDriveScope(googleConnection?.scope)
    }

    if (!hasDriveConnected && googleConnection) {
      // This will add drive permissions
      const result = await connectGoogleDrive(googleConnection.email)
      if (result.success) {
        hasDriveConnected = true
      } else {
        toast.error("Failed connecting google drive")
        return
      }
    }

    if (!hasDriveConnected) {
      return
    }

    const docType = docTypes.googleDoc

    try {
      const res = await api.aiTools.export({
        urlParams: {
          id: currentResponseId,
          type: toolType,
        },
        params: {
          doc_type: docType,
        },
      })

      if ("url" in res && res.url) {
        setShowLinkDialog({
          show: true,
          link: res.url,
        })
        setMenuOpen(false)
        trackingService.trackEvent("ai_tools_export_success", {
          tool_type: toolType,
          doc_type: docType,
        })
      }
    } catch (e: any) {
      trackingService.trackEvent("ai_tools_export_failed", {
        doc_type: docType,
        tool_type: toolType,
        status_code: e?.statusCode,
        error: JSON.stringify(e),
      })

      if (e instanceof APIError) {
        const {
          errors: { fieldErrors },
        } = e

        if (fieldErrors && fieldErrors.googleAccessTokenExpired) {
          toast.info("Please sign in again.")
          setShowConnectDrive(true)
          setRelinkGoogleAccount(true)
          return
        }
      } else {
        toast.error("Unable to process your request")
      }
    }
    setShowConnectDrive(false)
    setRelinkGoogleAccount(false)
  }

  const options = exportOptions.map(({ label, value, icon }) => (
    <AsyncBuilder
      key={value}
      handler={() => handleExport(value)}
      render={({ loading, onClick }) => (
        <DropdownMenuItem
          onSelect={e => e.preventDefault()}
          onClick={e => {
            e.stopPropagation()
            onClick()
          }}
        >
          <div className="flex items-center gap-1">
            {icon}
            {label}{" "}
            {(loading ||
              (callbackDownloading && callbackDownloading.type === value)) && (
              <CircularProgress />
            )}
          </div>
        </DropdownMenuItem>
      )}
    />
  ))

  return (
    <>
      {showLinkDialog.show && (
        <LinkReadyDialog
          link={showLinkDialog.link}
          onClose={() => setShowLinkDialog({ link: "", show: false })}
        />
      )}
      <ConnectGoogleAccount
        open={showConnectDrive}
        onOpenChange={x => setShowConnectDrive(x)}
        onConnectClick={() => exportAsGoogleDoc(true)}
      />
      {asSubMenu ? (
        <>
          {children}
          <DropdownMenuPortal>
            <DropdownMenuSubContent>{options}</DropdownMenuSubContent>
          </DropdownMenuPortal>
        </>
      ) : (
        <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
          <DropdownMenuTrigger>
            {children ?? <Button>Download</Button>}
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">{options}</DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  )
}

const ContentActions = ({
  onBack,
  currentResponseId,
  toolType,
  title,
  hideExportOutputButton,
}: {
  onBack: VoidFunction
  toolType: ToolType
  currentResponseId: string | number | undefined
  title: string | undefined
  hideExportOutputButton?: boolean
}) => {
  const { setOpenHistorySideBar } = useContext(AIToolsContext)
  return (
    <div className="mb-3 mt-2 flex items-center justify-between">
      <BackButton onClick={onBack} label="Back" className="!block sm:!hidden" />
      <BackButton
        onClick={onBack}
        label="Generate Another"
        className="!hidden sm:!block"
      />
      <div className="flex flex-wrap items-center justify-end gap-2">
        <Button
          variant="text"
          className="lg:!hidden"
          onClick={() => {
            setOpenHistorySideBar(true)
          }}
        >
          View History
        </Button>
        {currentResponseId && !hideExportOutputButton && (
          <ExportOutputButton
            currentResponseId={currentResponseId}
            toolType={toolType}
            title={title}
          />
        )}
      </div>
    </div>
  )
}

export default ContentActions
