import { CircularProgress, Menu, MenuItem } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { UserPlannerItemData } from "api/resources/assignments/types"
import ViewPreviousSubmissionDialog from "features/AssignmentResult/ViewPreviousSubmissionDialog"
import { NavArrowDown } from "iconoir-react"
import React, { useState } from "react"

type Props = {
  currentSubmissionId?: string
  userPlannerItemData: UserPlannerItemData
}

const PreviousSubmissionsDropdown = ({
  currentSubmissionId,
  userPlannerItemData,
}: Props) => {
  const { data, isSuccess, isLoading, isError } = useQuery({
    queryFn: () =>
      api.assignments.previousSubmissionsList({
        urlParams: {
          assignmentUUID: userPlannerItemData.learningContent.uuid,
          userPlannerItemId: userPlannerItemData.id,
        },
      }),
    queryKey: queries.assignments.submissionsListByAssignmentId(
      userPlannerItemData.learningContent.id.toString()
    ).queryKey,
    select: data =>
      data?.sort((a, b) =>
        new Date(a.dateCreated) < new Date(b.dateCreated) ? 1 : -1
      ),
  })

  const [viewPreviousSubmissionIndex, setViewPreviousSubmissionIndex] =
    useState<string | null>(null)

  if (!data || data.length === 0) return null

  const getMenuItems = () => {
    if (!data) return null
    if (data.length === 0) {
      return <MenuItem>No submissions found</MenuItem>
    }

    return data.map((x, i) => {
      const isCurrent = currentSubmissionId === x.uuid
      return (
        <MenuItem
          disabled={isCurrent}
          key={x.uuid}
          onClick={() => setViewPreviousSubmissionIndex(x.uuid)}
        >
          Submission {data.length - i} {isCurrent && "(current)"}
        </MenuItem>
      )
    })
  }

  return (
    <>
      <ViewPreviousSubmissionDialog
        open={!!viewPreviousSubmissionIndex}
        onClose={() => setViewPreviousSubmissionIndex(null)}
        title={`Previous Submission`}
        submissionId={viewPreviousSubmissionIndex}
      />

      <Menu
        menuButton={
          <button>
            <p className="flex items-center text-sm font-semibold">
              <span className="me-0.25">View Previous Submissions</span>
              {isLoading ? (
                <span className="ms-1">
                  <CircularProgress size="xs" />
                </span>
              ) : (
                <NavArrowDown />
              )}
            </p>
          </button>
        }
      >
        {isError && <MenuItem>Error!</MenuItem>}
        {isSuccess && getMenuItems()}
      </Menu>
    </>
  )
}

export default PreviousSubmissionsDropdown
