import { Typography } from "@suraasa/placebo-ui"
import { Checkbox } from "@suraasa/placebo-ui-legacy"
import { Answer as AnswerType } from "api/resources/assessments/types"
import clsx from "clsx"
import React from "react"

import styles from "./MultipleCorrect.module.css"

type Props = {
  question: AnswerType
}

function MultipleCorrect({ question }: Props) {
  return (
    <>
      <div>
        <Typography variant="body" className="mb-2 mt-1">
          {question.question}
        </Typography>
      </div>
      <div className="mt-2">
        <div>
          {Object.entries(question.options).map(([optionId, optionText]) => {
            const id = parseInt(optionId)
            const markedOptionIndex =
              question.response && question.response.indexOf(id)

            const correctOptionIndex = question.correctAnswer.indexOf(id)

            const isCorrect =
              markedOptionIndex !== null &&
              markedOptionIndex !== -1 &&
              correctOptionIndex !== -1
            const isMarked = question.response && markedOptionIndex !== -1

            return (
              <div key={optionId} className={styles.checkbox}>
                <Checkbox
                  className={clsx("my-1 w-full rounded-[5px]", {
                    [styles.correctAnswer]: isCorrect,
                    [styles.markedAnswer]: !isCorrect && isMarked,
                  })}
                  color={isCorrect ? "success" : "critical"}
                  checked={isCorrect || Boolean(isMarked)}
                  readOnly
                  label={optionText}
                />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default MultipleCorrect
