import { Typography } from "@suraasa/placebo-ui"
import { Tag } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { format } from "date-fns"
import twitterVerifiedBadge from "features/Profile/assets/twitter-verified-badge.png"
import React from "react"

const ItemRow = ({
  title,
  isInProgress,
  className,
  startDate,
  endDate,
  type,
}: {
  className?: string
  title: string
  isInProgress?: boolean
  startDate?: string | null
  endDate?: string | null
  type: "Qualification" | "Certification"
}) => {
  return (
    <div className={clsx(className, "flex items-start gap-2")}>
      <div>
        <div className="flex flex-wrap items-center gap-1">
          <Typography variant="strongSmallBody" className="text-onSurface-800">
            {title}
          </Typography>

          <img alt="✅" height="24px" src={twitterVerifiedBadge} width="24px" />

          {isInProgress && <Tag color="onSurface" label="In Progress" />}
        </div>

        <Typography variant="smallBody" className="mb-0.25 text-onSurface-600">
          {type}
        </Typography>
        {startDate && type === "Qualification" && (
          <Typography variant="smallBody" className="text-onSurface-600">
            {[
              format(new Date(startDate), "MMMM yyyy"),
              endDate ? format(new Date(endDate), "MMMM yyyy") : "Present",
            ].join(" - ")}
          </Typography>
        )}
        {startDate && type === "Certification" && (
          <Typography variant="smallBody" className="text-onSurface-600">
            {format(new Date(startDate), "MMMM yyyy")}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default ItemRow
