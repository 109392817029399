import { Typography } from "@suraasa/placebo-ui"
import { Button, Dialog, DialogContent } from "@suraasa/placebo-ui-legacy"
import Hourglass from "features/ITO/Assessments/assets/hourglass.svg"

type Props = {
  open: boolean
  handleClose: () => void
  parentSelector?: () => void
}

const TimeUpDialog = ({ open, parentSelector, handleClose }: Props) => {
  return (
    <Dialog
      width="sm"
      parentSelector={parentSelector}
      open={open}
      onRequestClose={handleClose}
    >
      <DialogContent className="m-2 flex flex-col items-center justify-center">
        <img src={Hourglass} alt="hourglass" className="mb-1.5" />
        <Typography variant="title3" className="mb-1">
          Time&apos;s Up!
        </Typography>
        <Typography variant="body" className="mb-3 text-center">
          Your assessment has been <b>auto-submitted</b> as the time window for
          attempting it is over.
        </Typography>
        <Button fullWidth onClick={handleClose}>
          Okay
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default TimeUpDialog
