import { theme } from "@suraasa/placebo-ui-legacy"
import { ActivityUserResponse } from "api/resources/learningItems/types"
import clsx from "clsx"
import { Check, Xmark } from "iconoir-react"

import ActivityButton, { ActivityButtonState } from "../../ActivityButton"
import ActivityFormattedText from "./ActivityFormattedText"

const getButtonState = (option: ActivityUserResponse): ActivityButtonState => {
  if (option.validation === "incorrect") {
    return "error"
  }
  if (option.validation === "correct") {
    return "success"
  }
  if (option.markedAnswer !== null) {
    return "selected"
  }

  return "default"
}

const getMarkedAnswer = (
  option: ActivityUserResponse | null
): string | null => {
  if (!option) return null

  return option.markedAnswer && option.markedAnswer.length > 0
    ? option.options[option.markedAnswer[0]]
    : null
}

const ActivityItem = ({
  data,
  onSelect,
  className,
  showIcon = false,
}: {
  data: ActivityUserResponse
  onSelect?: (value: string) => void
  className?: string
  showIcon?: boolean
}) => {
  const paraWords = data.label.split(" ") || []

  return (
    <div className={clsx(className, "flex items-center gap-1")}>
      {showIcon && data.validation === "correct" && (
        <Check color={theme.colors.success[500]} />
      )}
      {showIcon && data.validation === "incorrect" && (
        <Xmark color={theme.colors.critical[500]} />
      )}
      <div>
        <div
          className={clsx("flex max-w-[473px] flex-wrap items-center gap-0.5", {
            "mb-5": onSelect,
          })}
        >
          {paraWords.map((word, index) => {
            if (word.includes("__blank__")) {
              const punctuation = word.split("__blank__")[1]
              return (
                <>
                  <ActivityButton
                    disabled
                    variant="outlined"
                    className={clsx("min-w-[52px]", {
                      "me-2.25": Boolean(punctuation),
                    })}
                    key={index}
                    endAdornment={punctuation}
                    state={getButtonState(data)}
                  >
                    {getMarkedAnswer(data) || <>&nbsp;</>}
                  </ActivityButton>
                </>
              )
            }
            return (
              <span className="text-body text-onSurface-900" key={index}>
                <ActivityFormattedText text={word} />
              </span>
            )
          })}
        </div>

        <div className="mx-auto flex max-w-[473px] flex-col space-y-3">
          {onSelect &&
            data.options.map((item, index) => {
              const isCorrect = data.validation === "correct"

              const state = data.markedAnswer?.includes(index)
                ? getButtonState(data)
                : "default"
              return (
                <ActivityButton
                  fullWidth
                  key={item}
                  state={state}
                  disabled={isCorrect}
                  onClick={() => {
                    if (onSelect) onSelect(item)
                  }}
                >
                  <ActivityFormattedText text={item} />
                </ActivityButton>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default ActivityItem
