import {
  Auth,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth"
import { app } from "firebaseInit"

export const getFirebaseAuth = (): Auth => {
  return getAuth(app)
}

type Options = {
  withDriveScope?: boolean
  email?: string
}

export const googleAuthHandler = async (options?: Options) => {
  const provider = new GoogleAuthProvider()
  provider.addScope("https://www.googleapis.com/auth/userinfo.email")
  provider.addScope("https://www.googleapis.com/auth/userinfo.profile")
  provider.addScope("openid")
  if (options?.withDriveScope) {
    provider.addScope("https://www.googleapis.com/auth/drive.file")
  }

  if (options?.email) {
    provider.setCustomParameters({ user_id: options.email })
  }

  const auth = getFirebaseAuth()

  try {
    const result = await signInWithPopup(auth, provider)
    const credential = GoogleAuthProvider.credentialFromResult(result)
    console.log("Google Authentication Response", result, credential)

    return { ...result, accessToken: credential?.accessToken }
  } catch (error) {
    return null
  }
}

export const facebookAuthHandler = async () => {
  const provider = new FacebookAuthProvider()
  provider.addScope("openid")
  provider.addScope("email")

  const auth = getFirebaseAuth()

  try {
    const result = await signInWithPopup(auth, provider)
    const credential = FacebookAuthProvider.credentialFromResult(result)

    console.log("Facebook Authentication Response", result, credential)

    return { ...result, accessToken: credential?.accessToken }
  } catch (error) {
    return null
  }
}
export const appleAuthHandler = async () => {
  const provider = new OAuthProvider("apple.com")
  provider.addScope("openid")
  provider.addScope("email")
  provider.addScope("name")

  const auth = getFirebaseAuth()

  try {
    const result = await signInWithPopup(auth, provider)
    const credential = OAuthProvider.credentialFromResult(result)
    console.log("Apple Authentication Response", result, credential)

    // @ts-expect-error this is safe to do, because apple response contains _tokenResponse
    return { ...result, accessToken: result._tokenResponse.oauthIdToken }
  } catch (error) {
    return null
  }
}
