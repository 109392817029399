import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getPolluxURL = getServiceURL(SERVICE_PREFIX.pollux)

export const urls = validateUrls({
  cart: {
    getCart: () => "/catalogue/cart/",
    addItem: () => `/catalogue/cart/item/`,
  },
  billingAddress: {
    list: () => "/catalogue/billing-address/",
    detail: (uuid: string | number) => `/catalogue/billing-address/${uuid}/`,
  },
  payment: {
    cancel: paymentPlanId =>
      getPolluxURL(`/v1/payment-plans/${paymentPlanId}/cancel/`),
    status: paymentPlanId =>
      getPolluxURL(`/v1/payment-plans/${paymentPlanId}/status/`),
    methods: paymentPlanId =>
      getPolluxURL(`/v1/payment-plans/${paymentPlanId}/methods/`),
    retry: paymentPlanId =>
      getPolluxURL(`/v1/payment-plans/${paymentPlanId}/retry/`),
  },
})
