import { CircularProgress, toast } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import ContentActions from "features/AItools/components/Generator/GenerationOutput/Export/ContentActions"
import Heading from "features/AItools/components/Heading"
import { getToolName } from "features/AItools/helper"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import TogglePro from "../../TogglePro"
import { AIToolsContext } from "../context"
import RubricOutput from "../GenerationOutput/RubricsOutput"
import BasicForm from "./BasicForm"
import ProForm from "./ProForm"

const RubricsGenerator = ({
  id,
  onGeneration,
}: {
  id?: string
  onGeneration: (data: ListPromptHistory) => void
}) => {
  const navigate = useNavigate()
  const [enableProMode, setEnableProMode] = useState(false)
  // const {
  //   register,
  //   control,
  //   setError,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm<RubricsForm>({})

  const { currentResponseId, overviewData, promptDataId, setPromptDataId } =
    useContext(AIToolsContext)

  const content = useQuery({
    enabled: Boolean(currentResponseId),
    queryKey: queries.aiTools.retrieveHistory(
      ToolType.rubrics,
      currentResponseId
    ).queryKey,
    queryFn: async () => {
      const res = await api.aiTools.retrieveContent<ToolType.rubrics>({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        urlParams: { type: ToolType.rubrics, id: currentResponseId! },
      })

      // validateRubricsOutput(res.output)
      return res
    },
  })

  useEffect(() => {
    if (content.error instanceof APIError) {
      toast.error(content.error.message || "Unable to fetch rubrics")
    }
  }, [content.error])

  if (id && content.isLoading) {
    return (
      <div className="flex flex-col items-center justify-center p-5">
        <CircularProgress />
      </div>
    )
  }

  if (content.data && promptDataId) {
    return (
      <>
        <ContentActions
          onBack={() => {
            navigate(routes.aiTools.rubrics, { replace: true })
            setPromptDataId(null)
          }}
          currentResponseId={currentResponseId}
          toolType={ToolType.rubrics}
          title={overviewData?.title}
        />
        <RubricOutput {...content.data} />
      </>
    )
  }
  // if (data) {
  //   return (
  //     <>
  //       <ContentActions
  //         onBack={() => {
  //           setData(null)
  //         }}
  //         onDownload={() => handleDownload(data.id, data.title)}
  //       />
  //       <RubricOutput {...data} />
  //     </>
  //   )
  // }

  return (
    <>
      <Heading
        title={`${getToolName(ToolType.rubrics)} Generator`}
        subtitle="Simplify assessment creation with the AI Rubrics Generator, enabling precise assignment evaluation."
      />
      <TogglePro
        isPro={enableProMode}
        onChange={state => setEnableProMode(state)}
        className="mb-2"
      />
      {enableProMode ? (
        <ProForm
          onGeneration={res =>
            onGeneration({
              dateCreated: new Date().toString(),
              promptDataId: res.id,
              title: res.title,
              toolType: ToolType.rubricsPro,
            })
          }
        />
      ) : (
        <BasicForm
          onGeneration={res =>
            onGeneration({
              dateCreated: new Date().toString(),
              promptDataId: res.id,
              title: res.title,
              toolType: ToolType.rubrics,
            })
          }
        />
      )}
    </>
  )
}

export default RubricsGenerator
