import { Typography } from "@suraasa/placebo-ui"
import { ToolType } from "api/resources/aiTools/types"
import MarkdownRenderer from "features/AItools/components/Generator/GenerationOutput/MarkdownRenderer"

import {
  assignmentMarkdownFormat,
  handoutMarkdownFormat,
  lessonPlannerMarkdownFormat,
  narrationMarkdownFormat,
  quizMarkdownFormat,
  rubricMarkdownFormat,
  subjectiveAssessmentMarkdownFormat,
} from "./script"
import data from "./tool_data.json"

const generateMarkdown = (item: any) => {
  try {
    switch (item.tool_type) {
      case ToolType.lessonPlan:
        return lessonPlannerMarkdownFormat({
          output: item.response_data_output,
          title: item.title,
        })
      case ToolType.assignment:
        return assignmentMarkdownFormat({
          output: item.response_data_output,
          overviewData: item.prompt_data,
        })
      case ToolType.handout:
        return handoutMarkdownFormat({
          output: item.response_data_output,
          title: item.title,
        })
      case ToolType.narration:
        return narrationMarkdownFormat({
          output: item.response_data_output,
          title: item.title,
        })
      case ToolType.quiz:
        return quizMarkdownFormat({
          output: item.response_data_output,
          title: item.title,
        })
      case ToolType.rubrics:
        return rubricMarkdownFormat({
          output: item.response_data_output,
          title: item.title,
        })
      case ToolType.subjectiveAssessment:
        return subjectiveAssessmentMarkdownFormat({
          output: item.response_data_output,
          overviewData: item.prompt_data,
          title: item.title,
        })
      default:
        return ""
    }
  } catch (error) {
    return ""
  }
}

const MarkdownGenerator = () => {
  const dataToRender = (data as any[]).filter(
    x => x.tool_type === ToolType.subjectiveAssessment
  )

  console.log(`Data to render: ${dataToRender.length}`)

  return (
    <div>
      {dataToRender.map((item, i) => {
        const markdown = generateMarkdown(item)
        const isProOutput =
          item.tool_type === "lesson_plan" && Array.isArray(markdown)

        return (
          <div
            className="m-2 grid h-[1000px] grid-cols-2 divide-x-4 overflow-scroll border-2 border-onSurface-600 p-2"
            key={i}
          >
            <div className="pe-1">
              <pre className="overflow-x-scroll text-xs">
                {/* <Typography variant="title2">
                  {item.tool_type}: {i}
                </Typography> */}

                {JSON.stringify(item.response_data_output, null, 2)}
                {/* {JSON.stringify(item, null, 2)} */}
              </pre>
            </div>
            <div className="ps-1">
              {isProOutput ? (
                markdown.map((md, i) => (
                  <>
                    <Typography variant="title1" className="mt-4">
                      Lesson {i + 1}
                    </Typography>
                    <MarkdownRenderer key={i}>{md}</MarkdownRenderer>
                  </>
                ))
              ) : (
                <MarkdownRenderer>{markdown}</MarkdownRenderer>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MarkdownGenerator
