import { Typography } from "@suraasa/placebo-ui"
import React from "react"

const TypingAnimation = () => {
  return (
    <div className="flex w-fit items-center">
      <Typography className="mr-0.5" variant="smallBody" color="onSurface.500">
        Typing
      </Typography>
      <div className="animate-dot m-0.25 inline-block size-0.5 animate-dot-animation rounded-full bg-surface-300"></div>
      <div className="animate-dot m-0.25 inline-block size-0.5 animate-dot-animation rounded-full bg-surface-300 delay-200"></div>
      <div className="animate-dot m-0.25 inline-block size-0.5 animate-dot-animation rounded-full bg-surface-300 delay-300"></div>
    </div>
  )
}

export default TypingAnimation
