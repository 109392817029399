// TODO: Remove this and use the generic remove dialog from shad
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import React from "react"

type Props = {
  handleClose: () => void
  onRemove: () => void
  title: string
  loading?: boolean
  children: React.ReactNode
  actionLabel?: string
  open: boolean
}

const RemoveDialog: React.FC<Props> = ({
  open,
  handleClose,
  title,
  children,
  actionLabel = "Remove",
  onRemove,
  loading,
}) => {
  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-w-[358px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogBody>{children}</DialogBody>
        <DialogFooter>
          <Button
            variant="outlined"
            onClick={handleClose}
            size="sm"
            color="black"
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={onRemove}
            size="sm"
            color="critical"
          >
            {actionLabel}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default RemoveDialog
