import routes from "routes"

type Options = {
  username?: string
  submissionId?: string
  courseId?: string
}
export const getSkillProfileRoute = (options?: Options) => {
  const route = options?.username
    ? routes.skillProfile.replace(":username?", options.username)
    : routes.skillProfile.replace(":username?", "")

  const searchParams = new URLSearchParams()
  if (options?.submissionId) searchParams.set("id", options.submissionId)
  if (options?.courseId) searchParams.set("course", options.courseId)

  if (searchParams.toString()) {
    return route + `?${searchParams.toString()}`
  }

  return route
}
