import { SheetBody } from "@suraasa/placebo-ui"
import {
  Button,
  Menu,
  MenuItem,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import VideoPlayer from "components/VideoPlayer"
import ProfileContext from "features/Profile/context"
import { useContext } from "react"
import { handleErrors } from "utils/helpers"

import Instructions from "../Instructions"
import TopBar from "../TopBar"

type Props = {
  videoURL?: string | null
  onDelete: () => void
  handleUpdate: () => void
  className?: string
}

const View = ({ onDelete, handleUpdate, videoURL }: Props) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const { isPublic } = useContext(ProfileContext)

  const { mutate: deleteVideo } = useMutation({
    mutationFn: () => api.profile.videoPortfolio.delete(),
    onSuccess: onDelete,
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const menu = (
    <div>
      <Menu menuButton={<Button variant="text">Edit Video Portfolio</Button>}>
        <MenuItem onClick={handleUpdate}>Update</MenuItem>
        <MenuItem color="critical" onClick={() => deleteVideo()}>
          Delete
        </MenuItem>
      </Menu>
    </div>
  )

  return (
    <>
      <TopBar>{!isPublic && menu}</TopBar>

      <SheetBody>
        <VideoPlayer
          src={videoURL}
          className="mb-1.5 h-full max-h-[190px]  overflow-hidden rounded-2xl sm:max-h-[322px] md:max-h-[423px]"
        />
        {isXsDown && !isPublic && menu}
        {!isPublic && <Instructions />}
      </SheetBody>
    </>
  )
}

export default View
