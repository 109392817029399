import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@suraasa/placebo-ui"
import { Button, IconButton } from "@suraasa/placebo-ui-legacy"
import { Xmark } from "iconoir-react"
import React, { createContext, useContext, useState } from "react"
import { createPortal } from "react-dom"

type Confirm = {
  message: React.ReactNode
  isOpen: boolean
  confirm: any
  cancel: any
  title?: string
  color: "primary" | "critical"
  actionLabel?: string
}

type Context = {
  state: Confirm
  setState: React.Dispatch<React.SetStateAction<Confirm>>
}
type Config = Pick<Confirm, "title" | "actionLabel" | "color">

const defaultConfig: Config = {
  color: "primary",
  actionLabel: "Confirm",
}

const ConfirmDialogContext = createContext<Context>({
  state: {
    cancel: null,
    isOpen: false,
    confirm: null,
    message: "",
    ...defaultConfig,
  },
  setState: () => {},
})
const useConfirmDialog = () => useContext(ConfirmDialogContext)

export const ConfirmDialogProvider = (props: { children: React.ReactNode }) => {
  const [confirm, setConfirm] = useState<Confirm>({
    message: "",
    isOpen: false,
    confirm: null,
    cancel: null,
    ...defaultConfig,
  })

  return (
    <ConfirmDialogContext.Provider
      value={{
        state: confirm,
        setState: setConfirm,
      }}
    >
      {props.children}
    </ConfirmDialogContext.Provider>
  )
}

export const useConfirmationDialog = () => {
  const { state, setState } = useConfirmDialog()
  // const [cleanupRequired, setCleanupRequired] = useState(false)

  const getConfirmation = (message: React.ReactNode, config?: Config) => {
    // setCleanupRequired(true)

    const promise = new Promise((resolve, reject) => {
      setState({
        message,
        title: config?.title,
        isOpen: true,
        confirm: resolve,
        actionLabel: config?.actionLabel || defaultConfig.actionLabel,
        cancel: reject,
        color: config?.color || defaultConfig.color,
      })
    })
    return promise.then(
      () => {
        setState({ ...state, isOpen: false })
        return true
      },
      () => {
        setState({ ...state, isOpen: false })
        return false
      }
    )
  }

  // useEffect(() => {
  //   return () => {
  //     if (state.cancel && cleanupRequired) {
  //       state.cancel()
  //     }
  //   }
  // }, [state, cleanupRequired])

  return {
    ...state,
    getConfirmation,
  }
}

const ConfirmDialog = () => {
  const { isOpen, cancel, title, message, confirm, color, actionLabel } =
    useConfirmationDialog()

  const Modal = () => {
    return (
      <Dialog open={isOpen} onOpenChange={undefined}>
        <DialogContent>
          {title && (
            <DialogHeader
              hideClose
              className="flex items-center justify-between"
            >
              <DialogTitle asChild={false}>{title}</DialogTitle>
              <IconButton onClick={cancel} color="black">
                <Xmark />
              </IconButton>
            </DialogHeader>
          )}

          <DialogBody>{message}</DialogBody>

          <DialogFooter>
            <Button size="sm" onClick={confirm} color={color}>
              {actionLabel}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }
  return <>{isOpen && createPortal(<Modal />, document.body)}</>
}
export default ConfirmDialog
