import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Theme,
} from "@suraasa/placebo-ui-legacy"
import { ASSESSMENT_ROOT_DIV_ID } from "features/ITO/Assessments/Attempt/constants"
import {
  getOffencePercentage,
  getWarningGradient,
} from "features/ITO/Assessments/helpers"
import OverlayWrapper from "features/ITO/Assessments/Proctoring/OverlayWrapper"
import React, { ReactElement } from "react"
import { createUseStyles } from "react-jss"

type Props = {
  title: string
  content: ReactElement
  isOpen: boolean
  totalWarnings: number
  maxWarningCount: number
  onClose?: () => void
}
const useStyles = createUseStyles((theme: Theme) => ({
  color: {
    color: theme.colors.onSurface[600],
  },
}))

const Alert = ({
  title,
  content,
  isOpen,
  totalWarnings,
  onClose,
  maxWarningCount,
}: Props) => {
  const classes = useStyles()

  const offensePercentage = getOffencePercentage(totalWarnings, maxWarningCount)

  return (
    <OverlayWrapper>
      <Dialog
        open={isOpen}
        width="md"
        parentSelector={() =>
          document.querySelector(`#${ASSESSMENT_ROOT_DIV_ID}`)
        }
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className="m-1 grid content-start items-center gap-2">
          <div className="grid gap-0.75">
            <Typography variant="preTitle" className={classes.color}>
              Violation count
            </Typography>
            <LinearProgress
              color="warning"
              value={offensePercentage}
              linearGradient={getWarningGradient(offensePercentage)}
            />
            <div className={classes.color}>
              <Typography variant="strongSmallBody">
                {totalWarnings} out of {maxWarningCount} times
              </Typography>
              <Typography variant="smallBody">triggered</Typography>
            </div>
          </div>
          <Typography>{content}</Typography>
          {onClose ? (
            <Button className="ml-auto mt-2" onClick={onClose}>
              Okay, I understand
            </Button>
          ) : (
            <Typography>
              This popup will automatically close when you&apos;re not violating
              the code of conduct.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </OverlayWrapper>
  )
}

export default Alert
