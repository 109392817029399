import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import UpcomingTasks from "components/UpcomingTasks"
import { Calendar } from "iconoir-react"
import React, { useState } from "react"

import styles from "./ScheduleBar.module.css"

const ScheduleBar = () => {
  const [showSchedule, setShowSchedule] = useState(false)
  return (
    <div>
      <UpcomingTasks
        slideIn={showSchedule}
        onClose={() => setShowSchedule(false)}
      />
      <button
        className={clsx(
          styles.root,
          "mt-4 flex w-full flex-wrap items-center justify-between rounded-2xl px-3 py-2"
        )}
        onClick={() => setShowSchedule(true)}
      >
        <Typography variant="body" className="me-1 text-left text-white">
          See what your next 7 days look like
        </Typography>
        <Calendar
          className={clsx("hidden shrink-0 p-1 sm:block", styles.icon)}
        />
      </button>
    </div>
  )
}

export default ScheduleBar
