import { IconButton, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { MasterclassCertificate } from "api/resources/profile/types"
import GreenRings from "assets/MyCertificates/GreenRings.svg"
import clsx from "clsx"
import { Download, ShareAndroid } from "iconoir-react"
import React from "react"
import { useTheme } from "react-jss"
import { downloadFile, formatDate, getPlatformURL } from "utils/helpers"

import styles from "./MasterclassCard.module.css"

type Props = {
  className?: string
  data: MasterclassCertificate
  setCertificateUrl: (value: string) => void
}

const MasterclassCard = ({ className, data, setCertificateUrl }: Props) => {
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const credentialIssuedOn = formatDate(data.issuedAt, "dd LLLL yyyy")

  const svgProps = { width: 20, height: 20 }

  const certificateUrl = getPlatformURL(
    "suraasa",
    `/masterclasses/certificate/${data.registrationId}`
  )

  const handleShare = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
    setCertificateUrl(certificateUrl)
  }

  const handleDownload = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
    downloadFile(data.certificate, data.masterclass.title)
  }

  return (
    <button
      style={{
        textAlign: "inherit",
      }}
      onClick={() => {
        window.open(
          getPlatformURL(
            "suraasa",
            `/masterclasses/certificate/${data.registrationId}`
          ),
          "_blank"
        )
      }}
      className={clsx(
        "relative flex h-[227px] w-full flex-col justify-between rounded-lg border-[5px] border-surface-500 bg-decorative-four-50 p-2 shadow-[0_8px_25px_0_rgba(0,0,0,0.15)]  sm:px-3 sm:py-4",
        className
      )}
    >
      <div className="z-10">
        <span
          className={clsx(
            "mb-0.5  block text-decorative-four-200",
            styles.dateText
          )}
        >
          {credentialIssuedOn}
        </span>
        <span className={clsx("text-onSurface-900", styles.title)}>
          {data.masterclass.title}
        </span>
      </div>
      {!isXsDown && (
        <div className="z-10 flex">
          <IconButton onClick={handleDownload} color="black" className="me-2">
            <Download {...svgProps} />
          </IconButton>
          <IconButton id="share" onClick={handleShare} color="black">
            <ShareAndroid {...svgProps} />
          </IconButton>
        </div>
      )}
      <img
        src={GreenRings}
        alt="rings"
        className="absolute bottom-0 right-0 h-full"
      />
    </button>
  )
}

export default MasterclassCard
