import { useParams } from "react-router-dom"
import { learningItemSlugs } from "utils/constants"

import { useQualificationDetails } from ".."
import AdmissionLetter from "./AdmissionLetter"
import EnrollmentNumber from "./EnrollmentNumber"
import Modules from "./Modules"
import NextUp from "./NextUp"
import StudentCatalog from "./StudentCatalog"

// const overviewDetails = {
//   professors: [
//     {
//       name: "Prof. David Antler",
//       qualification: "Msc. Physics, Delhi University",
//       photo: "https://picsum.photos/150",
//     },
//     {
//       name: "Prof. David Antler",
//       qualification: "Msc. Physics, Delhi University",
//       photo: "https://picsum.photos/150",
//     },
//   ],
//   description:
//     "Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms. Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms. Professional Certificate in Teaching and Learning (PgCTL) is a UK Level 6 teaching qualification designed to help teachers accelerate their professional growth and be successful in creating a high impact teaching and learning experience in their classrooms.",
// }

const QualificationOverview = () => {
  const { slug } = useParams() as { slug: string }
  const { qualification } = useQualificationDetails()

  const isMEd = qualification.slug === learningItemSlugs.med

  return (
    <div>
      {/* <Overview data={overviewDetails} /> */}
      {/* {isMEd && <MEdDegreeCard />} */}
      <EnrollmentNumber slug={slug} />
      {isMEd && (
        <div className="mb-3 flex flex-col gap-1 md:flex-row">
          <AdmissionLetter slug={slug} />
          <StudentCatalog />
        </div>
      )}
      <div className="mb-3">
        <NextUp
          learningItemId={qualification.id}
          learningItemType={qualification.type}
        />
      </div>
      <Modules slug={slug} />
    </div>
  )
}

export default QualificationOverview
