import { Button, ButtonComponentProps, Theme } from "@suraasa/placebo-ui-legacy"
import { NavArrowLeft, NavArrowRight } from "iconoir-react"
import React from "react"
import { useTheme } from "react-jss"
import { useNavigate } from "react-router-dom"

type BackButtonProps = {
  goBack?: () => void
} & ButtonComponentProps<any>

export default function BackButton({
  goBack,
  label = "Back",
  color = "primary",
  className: userClassName,
  ...otherProps
}: BackButtonProps) {
  const theme = useTheme<Theme>()
  const navigate = useNavigate()

  return (
    <Button
      className={userClassName}
      onClick={goBack ? goBack : () => navigate(-1)}
      aria-label="back"
      color={color}
      nudge="left"
      {...otherProps}
      variant="text"
    >
      {theme.direction === "rtl" ? <NavArrowRight /> : <NavArrowLeft />}
      {label}
    </Button>
  )
}
