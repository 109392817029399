import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import {
  Button,
  CircularProgress,
  IconButton,
} from "@suraasa/placebo-ui-legacy"
import { useInfiniteQuery, useMutation } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { CoverLetterHistory, ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import { MoreVert, Xmark } from "iconoir-react"
import { formatDate, handleErrors } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

import { ExportOutputButton } from "../GenerationOutput/Export/ContentActions"
import TitleBar from "./TitleBar"
import { getFileName } from "./utils/helper"

const HistoryRecords = ({
  onCreateCoverLetter,
  onSelectCoverLetter,
  onClose,
}: {
  onCreateCoverLetter: () => void
  onSelectCoverLetter: (id: number) => void
  onClose: () => void
}) => {
  const { isFetching, hasNextPage, fetchNextPage, data, isLoading, refetch } =
    useInfiniteQuery({
      queryKey: queries.aiTools.listHistory(ToolType.coverLetter).queryKey,

      queryFn: x =>
        api.aiTools.coverLetter.list({
          params: { page: x.pageParam || 1 },
        }),
      onError: err => {
        if (err instanceof APIError) {
          handleErrors(err)
          console.error(err.statusCode)
          console.error(err.is500)
          console.error(err.__errors)
        }
      },

      getNextPageParam: lastPage => {
        return lastPage.nextPage ?? undefined
      },
    })

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage,
    actionFunc: fetchNextPage,
  })
  const allData = data?.pages.flatMap(page => page.data)

  if (allData?.length === 0) {
    return (
      <div>
        <TitleBar onClick={onClose} startAdornment={<Xmark />} label="Close" />
        <div className="px-2">
          <div className="mb-3 size-full h-[250px] bg-white">
            <video
              src="https://assets.suraasa.com/lms/cover-letter-ai-tool-onboarding.mp4"
              muted
              autoPlay
              className="size-full"
              loop
              controls={false}
            />
          </div>
          <Typography variant="title3" className="mb-1 text-center">
            Create a cover letter in just 1 minute!
          </Typography>
          <Typography className="mb-3 text-center text-onSurface-500">
            Just enter the job details you are applying for, and we will take
            care of the rest, showcasing your skills and passion and making
            employers eager to know more.
          </Typography>
          <Button onClick={onCreateCoverLetter} fullWidth>
            Create Cover Letter
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="relative">
      <TitleBar
        className="sticky left-0 top-0 bg-white"
        onClick={onClose}
        startAdornment={<Xmark />}
        label="Close"
        endSlot={
          <Button onClick={onCreateCoverLetter} size="sm">
            Create Cover Letter
          </Button>
        }
      />

      <div className="flex flex-col px-2">
        {allData &&
          allData.map((item, i) => {
            const isLastItem = i === allData.length - 1

            return (
              <div key={item.id} ref={isLastItem ? ref : null}>
                <Record
                  {...item}
                  className={i === 0 ? "pb-2" : "py-2"}
                  onClick={onSelectCoverLetter}
                  onDelete={() => {
                    refetch()
                  }}
                />
              </div>
            )
          })}
        {isLoading && (
          <div className="flex items-center justify-center py-5">
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  )
}

const Record = (
  props: CoverLetterHistory & {
    onClick: (id: number) => void
    onDelete: () => void
    className?: string
  }
) => {
  const fileName = getFileName({
    schoolName: props.schoolName,
  })
  const deleteCoverLetter = useMutation({
    mutationFn: (id: number) =>
      api.aiTools.coverLetter.delete({ urlParams: { id } }),
    onSuccess: () => {
      props.onDelete()
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const createdOn = props.dateCreated
    ? formatDate(props.dateCreated, "do MMMM yyyy")
    : undefined

  return (
    <div
      className={clsx(
        props.className,
        "flex w-full items-start justify-between border-b border-solid border-onSurface-200"
      )}
    >
      <button
        className="grow"
        onClick={event => {
          event.stopPropagation()
          props.onClick(props.id)
        }}
      >
        <Typography variant="strong" className="mb-0.5 text-start">
          {props.position}, {props.subject}
        </Typography>
        <Typography
          variant="smallBody"
          className="text-start text-onSurface-400"
        >
          {[props.schoolName, createdOn].filter(Boolean).join(" • ")}
        </Typography>
      </button>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton color="secondary" size="sm">
            <MoreVert />
          </IconButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuSub>
            <ExportOutputButton
              title={fileName}
              asSubMenu
              currentResponseId={props.id}
              toolType={ToolType.coverLetter}
            >
              <DropdownMenuSubTrigger>Download</DropdownMenuSubTrigger>
            </ExportOutputButton>
          </DropdownMenuSub>

          <DropdownMenuItem
            disabled={deleteCoverLetter.isLoading}
            onClick={e => {
              e.preventDefault()
              deleteCoverLetter.mutate(props.id)
            }}
            className="flex items-center justify-between text-critical-500"
          >
            Delete
            {deleteCoverLetter.isLoading && <CircularProgress size="xs" />}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default HistoryRecords
