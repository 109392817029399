import { Typography } from "@suraasa/placebo-ui"
import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
} from "@suraasa/placebo-ui-legacy"
import { LessonPlanResponse, ToolType } from "api/resources/aiTools/types"
import { getToolName } from "features/AItools/helper"
import { NavArrowDown } from "iconoir-react"

import Paper from "../../../../Paper"
import MarkdownRenderer from "../../MarkdownRenderer"
import RegenerateOutput from "../../RegenerateOutput"

const LessonPlanContent = ({
  output,
  title,
  showRegenerateAction = true,
  ...props
}: Pick<LessonPlanResponse, "title" | "output" | "isPositiveResponse"> & {
  showRegenerateAction?: boolean
}) => {
  return (
    <Paper
      endSlot={
        showRegenerateAction ? (
          <RegenerateOutput
            copyText={output}
            isPositiveResponse={props.isPositiveResponse}
          />
        ) : null
      }
    >
      <MarkdownRenderer>{output}</MarkdownRenderer>
    </Paper>
  )
}

export const LessonPlanProContent = ({
  output,
  title,
  sequence,
}: Pick<LessonPlanResponse, "title" | "output"> & {
  sequence: number
}) => {
  return (
    <Paper>
      <Accordion expanded={sequence === 1}>
        <AccordionHeader
          icon={<NavArrowDown color="black" />}
          className="text-start [&>.AccordionHeader-content]:w-full"
        >
          <div className="flex items-center gap-1">
            <div>
              <Typography
                variant="title2"
                color="common.white.500"
                className="rounded-3xl bg-black px-3 py-0 text-white"
              >
                {sequence}
              </Typography>
            </div>
            <Typography variant="title2">
              {getToolName(ToolType.lessonPlanPro)}
            </Typography>
          </div>
        </AccordionHeader>

        <AccordionDetails>
          <MarkdownRenderer>{output}</MarkdownRenderer>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}

export default LessonPlanContent
