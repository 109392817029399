import { Typography } from "@suraasa/placebo-ui"
import { Gender, GenderMapping } from "api/resources/profile/types"
import clsx from "clsx"
import {
  Female,
  Mail,
  Male,
  MapPin,
  Minus,
  SmartphoneDevice,
  WhiteFlag,
} from "iconoir-react"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"

import ProfileContext from "../context"
import Section from "./Section"
import SectionContent from "./Section/SectionContent"
import SectionHeading from "./Section/SectionHeading"
import SectionTitle from "./Section/SectionTitle"

const useStyles = createUseStyles(theme => ({
  tile: { display: "flex", alignItems: "flex-start", gap: theme.spacing(1) },

  tileData: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4 / 8),
  },

  iconContainer: {
    width: theme.spacing(43 / 8),
    height: theme.spacing(43 / 8),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `${theme.spacing(1 / 8)} solid ${theme.colors.surface[200]}`,
    color: theme.colors.onSurface[500],
    flexShrink: 0,
    "&>svg": {
      width: theme.spacing(20 / 8),
      height: theme.spacing(20 / 8),
    },
  },

  tileValue: {
    wordBreak: "break-all",
  },
}))

const PersonalDetailTile = ({
  heading,
  value,
  icon,
}: {
  heading: string
  value?: number | string | null
  icon: React.ReactNode
}) => {
  const classes = useStyles()
  return (
    <div className={classes.tile}>
      <div className={classes.iconContainer}>{icon} </div>
      <div className={classes.tileData}>
        <Typography className="text-onSurface-500" variant="smallBody">
          {heading}
        </Typography>
        <Typography
          className={clsx(classes.tileValue, "text-onSurface-800")}
          variant="strong"
        >
          {value ?? "-"}
        </Typography>
      </div>
    </div>
  )
}

function getGenderIcon(gender: Gender | null): React.ReactNode {
  if (!gender) return <Minus />

  if (Gender.MALE === gender) {
    return <Male />
  }
  if (Gender.FEMALE === gender) {
    return <Female />
  }

  return <Minus />
}

const PersonalInfo = () => {
  const {
    profile: { country, gender, nationality, phoneNumber, user },
    isPublic,
  } = useContext(ProfileContext)

  return (
    <Section>
      <SectionHeading heading={<SectionTitle title="Additional Details" />} />
      <SectionContent
        className={clsx("gap-2", {
          "grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))]": isPublic,
          "flex flex-wrap flex-col": !isPublic,
        })}
        xPadding={2}
      >
        <PersonalDetailTile
          heading="Location"
          icon={<MapPin />}
          value={country?.name}
        />
        {!isPublic && (
          <PersonalDetailTile
            heading="E-Mail"
            icon={<Mail />}
            value={user.email}
          />
        )}
        {!isPublic && (
          <PersonalDetailTile
            heading="Phone"
            icon={<SmartphoneDevice />}
            value={
              phoneNumber ? `+${phoneNumber.code} ${phoneNumber.number}` : null
            }
          />
        )}
        <PersonalDetailTile
          heading="Gender"
          icon={getGenderIcon(gender)}
          value={gender ? GenderMapping[gender] : "-"}
        />
        <PersonalDetailTile
          heading="Nationality"
          icon={<WhiteFlag />}
          value={nationality?.name}
        />
      </SectionContent>
    </Section>
  )
}

export default PersonalInfo
