import { Button, CircularProgress, Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { APIError } from "api/utils"
import {
  buildUserName,
  getAuthInfo,
  handleErrors,
  pluralize,
} from "utils/helpers"

import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@suraasa/placebo-ui"

const getTitle = (type: "assessment" | "assignment") => {
  switch (type) {
    case "assessment":
      return "Assessment"
    case "assignment":
      return "Assignment"
  }
}
const TextDetails = ({
  fullName,
  email,
  mailToContent,
}: {
  fullName: string
  email: string
  mailToContent: string
}) => {
  return (
    <>
      <Typography variant="strong" className="inline">
        {fullName}{" "}
      </Typography>
      (
      <Typography variant="strong" className="inline">
        <a className="inline-block [word-wrap:break-word]" href={mailToContent}>
          {email}
        </a>
      </Typography>
      )
    </>
  )
}
const ReattemptDialog = ({
  open,
  onClose,
  title,
  type,
}: {
  open: boolean
  onClose: () => void
  title: string
  type: "assessment" | "assignment"
}) => {
  const auth = getAuthInfo()

  const { data, isLoading } = useQuery({
    queryFn: () => api.batch.listBatches(),
    queryKey: queries.batch.listBatches().queryKey,
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
    enabled: open,
  })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const fullName = buildUserName(auth!.user)

  const generateMailContent = (
    coordinatorName: string,
    coordinatorEmail: string
  ) => {
    const subject = `Subject: Request to Re-attempt ${getTitle(type)}`
    const encodedText = encodeURIComponent(
      `Respected ${coordinatorName},
      
I hope this message finds you well. I am writing to request an opportunity to re-attempt the ${type} “${title}”.
      
Your consideration of this request would be greatly appreciated.
      
Thank you for your understanding and support.
      
Best regards,
${fullName}`
    )
    const mailText = encodedText.replaceAll("\n", "%0D%0A")
    return `mailto:${coordinatorEmail}?subject=${subject}&body=${mailText}`
  }

  const lastElement = data && data.length > 1 ? data?.pop() : undefined
  return (
    <Dialog modal open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Typography variant="strong">Request Reattempt</Typography>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="py-2.5">
          {isLoading && (
            <div className="flex w-full items-center justify-center">
              <CircularProgress />
            </div>
          )}
          {data && (
            <Typography>
              Please contact{" "}
              {pluralize("your Program Co-ordinator", data.length, {
                plural: "one of your Program Co-ordinators",
                skipCount: true,
              })}{" "}
              {data.map((item, index) => {
                const mailToContent = generateMailContent(
                  item.coordinator.fullName,
                  item.coordinator.email
                )
                return (
                  <>
                    <TextDetails
                      fullName={item.coordinator.fullName}
                      email={item.coordinator.email}
                      mailToContent={mailToContent}
                    />
                    {index >= 0 && index < data.length - 1 && ", "}
                  </>
                )
              })}
              {lastElement && (
                <>
                  {` or `}
                  <TextDetails
                    fullName={lastElement.coordinator.fullName}
                    email={lastElement.coordinator.email}
                    mailToContent={generateMailContent(
                      lastElement.coordinator.fullName,
                      lastElement.coordinator.email
                    )}
                  />
                </>
              )}{" "}
              to request a reattempt for this {type}.
            </Typography>
          )}
        </DialogBody>

        <DialogFooter>
          <Button onClick={onClose} size="sm">
            Okay
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ReattemptDialog
