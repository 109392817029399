import { toast } from "@suraasa/placebo-ui"
/* eslint-disable lodash/prefer-get */
import {
  Button,
  Container,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { AccessStatus } from "api/resources/learningItems/types"
import clsx from "clsx"
import Fallback from "components/Fallback"
import PageNavigation from "components/PageNavigation"
import { context } from "global/Context/context"
import { useContext, useEffect, useState } from "react"
import { useTheme } from "react-jss"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import routes from "routes"
import useHideGlobalBottomNavigation from "utils/hooks/useGlobalHideBottomNavigation"
import useHideTopNavigation from "utils/hooks/useHideTopNavigation"

import BottomNavigation from "./BottomNavigation"
import { LearningModuleContext } from "./context"
import CourseItem from "./Course/CourseItem"
import CurriculumSidebar from "./Course/CurriculumSidebar"
import MilestoneCompletedSheet from "./MilestoneList/MilestoneCompletedSheet"
import NextMilestoneDialog from "./MilestoneList/NextMilestoneDialog"
import PlannerCompleted from "./PlannerCompleted"
import { usePlanner } from "./usePlanner"

enum Sheet {
  none,
  courseCompleted,
  milestoneCompleted,
  nextMilestone,
}

const LearningModule = () => {
  const navigate = useNavigate()
  const theme = useTheme<Theme>()
  const isSmAndUp = useMediaQuery(theme.breakpoints.up("sm"))

  useHideGlobalBottomNavigation()
  useHideTopNavigation({ query: theme.breakpoints.down("xs") })

  const {
    planner,
    loading,
    isEmpty,
    currentItem,
    switchItem,
    hydrateStatusUpdate,
    addVideoBookmarkInState,
    handleArticleBookmark,
    removeVideoBookmarkFromState,
    milestoneInstructions,
    handleDismissMilestoneInstructions,
    currentMilestoneNumber,
    totalMilestones,
    showPlannerCompletedSheet,
    setShowPlannerCompletedSheet,
    isFirstItem,
  } = usePlanner()

  const { slug } = useParams() as { slug: string }
  const [searchParams, setSearchParams] = useSearchParams()
  const { enrollments } = useContext(context)
  const courseName = enrollments?.find(x => x.slug === slug)?.name
  const courseSlug = enrollments?.find(x => x.slug === slug)?.slug

  const [drawerOpen, setDrawerOpen] = useState(false)

  const [sheet, setSheet] = useState({ type: Sheet.none })

  const handleSheetClose = () => setSheet({ type: Sheet.none })

  const [nextAllowed, setNextAllowed] = useState(false)

  useEffect(() => {
    if (currentItem) {
      searchParams.set("itemId", currentItem.id.toString())
      setSearchParams(searchParams, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem])

  useEffect(() => {
    if (currentItem) {
      if (currentItem.learningContentType === "video") {
        setNextAllowed(currentItem.status === "completed")
        return
      }

      setNextAllowed(true)
    }
  }, [currentItem])

  const [immersiveView, setImmersiveView] = useState(false)

  useEffect(() => {
    if (
      planner &&
      planner[0] &&
      planner[0].accessStatus === AccessStatus.COMPLETELY_RESTRICTED
    ) {
      toast.warning("You don't have access to this module.")
      navigate(`${routes.course.replace(":slug", slug)}`, { replace: true })
    }
  }, [planner])

  return (
    <LearningModuleContext.Provider
      value={{
        handleArticleBookmark,
        switchItem,
        hydrateStatusUpdate,
        addVideoBookmarkInState,
        removeVideoBookmarkFromState,
        enableVideoNextButton: () => setNextAllowed(true),
        immersiveView,
        setImmersiveView,
        plannerList: planner,
      }}
    >
      <MilestoneCompletedSheet
        milestoneNumber={currentMilestoneNumber}
        progress={(currentMilestoneNumber / totalMilestones) * 100}
        open={sheet.type === Sheet.milestoneCompleted}
        handleClose={handleSheetClose}
        onSuccess={() => {
          setSheet({ type: Sheet.nextMilestone })
        }}
      />

      <NextMilestoneDialog
        milestoneNumber={currentMilestoneNumber}
        open={milestoneInstructions != null}
        handleClose={handleDismissMilestoneInstructions}
        text={milestoneInstructions?.content || ""}
      />
      <div className="max-w-screen flex min-h-screen overflow-hidden">
        <div
          className="flex flex-1 flex-col"
          style={{
            overflowY: "hidden",
          }}
        >
          <Container
            className={clsx(
              "w-full transition-all [&>div]:relative [&>div]:flex [&>div]:flex-col [&>div]:justify-between"
            )}
          >
            <div className="grow">
              {immersiveView ? (
                <div className="sm:h-4" />
              ) : (
                <PageNavigation
                  className="my-2"
                  color="dark"
                  skipLastItem={false}
                  endSlot={
                    <Button
                      onClick={() => setDrawerOpen(!drawerOpen)}
                      className="md:!hidden"
                      variant="outlined"
                    >
                      Show Curriculum
                    </Button>
                  }
                />
              )}
              {isEmpty.item && !currentItem ? (
                <div className="flex h-[70vh] items-center justify-center">
                  <Fallback hasError />
                </div>
              ) : (
                <CourseItem loading={loading} item={currentItem} />
              )}
            </div>
            {isSmAndUp && !immersiveView && (
              <BottomNavigation
                onPrev={() => switchItem({ mode: "previous" })}
                onNext={() => switchItem({ mode: "next" })}
                disabledNext={!nextAllowed || loading}
                disabledPrev={loading || isFirstItem}
              />
            )}
          </Container>
          {!isSmAndUp && !immersiveView && (
            <BottomNavigation
              onPrev={() => switchItem({ mode: "previous" })}
              onNext={() => switchItem({ mode: "next" })}
              disabledNext={!nextAllowed || loading}
              disabledPrev={loading || isFirstItem}
            />
          )}
        </div>

        {!immersiveView && (
          <CurriculumSidebar
            isEmpty={isEmpty.list}
            currentItem={currentItem}
            drawerProps={{
              open: drawerOpen,
              toggle: () => setDrawerOpen(p => !p),
            }}
            items={planner}
          />
        )}
      </div>

      <PlannerCompleted
        open={showPlannerCompletedSheet}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        courseName={courseName!}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        courseSlug={courseSlug!}
        onClose={() => setShowPlannerCompletedSheet(false)}
      />
    </LearningModuleContext.Provider>
  )
}

export default LearningModule
