import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("settings", {
  emailList: () => ({
    queryKey: ["emails"],
  }),
  preferencesList: () => ({
    queryKey: ["preferences"],
  }),
  socialAccountsList: () => ({
    queryKey: ["socialAccountsList"],
  }),
})
