import { CircularProgress } from "@suraasa/placebo-ui-legacy"
import api from "api"
import TopNavigation from "components/Navigation/TopNavigation"
import { processAuthCode } from "features/Authentication/processAuthCode"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { importantSearchParams } from "utils/constants"
import {
  clearOtherPlatformAuthInfo,
  getOtherPlatformAuthInfo,
  setOtherPlatformAuthInfo,
} from "utils/helpers"

import Profile from ".."
import { PlatformsWhichCanViewLearnerProfile } from "../utils"

type Props = {
  platform: PlatformsWhichCanViewLearnerProfile
}

/**
 * This components lets other roles view the user profile.
 * This feature helps us not duplicate the profile code in every repository where profile access is needed
 * This component has its own auth. It does not use suraasaAuth.
 */
const ProfileAsDifferentPlatform = ({ platform }: Props) => {
  const [loading, setLoading] = useState(true)

  const [searchParams, setSearchParams] = useSearchParams()
  const code = searchParams.get("code")

  const logout = () => {
    clearOtherPlatformAuthInfo(platform)
    window.location.reload()
  }

  useEffect(() => {
    const run = async () => {
      const platformAuth = getOtherPlatformAuthInfo(platform)
      if (platformAuth) {
        try {
          const res = await api.users.verifyAccessToken({
            data: {
              token: platformAuth.accessToken,
            },
            headers: {
              Authorization: null,
            },
          })

          if (res.verified) {
            setLoading(false)
            searchParams.set(importantSearchParams.platformOverride, platform)
            setSearchParams(searchParams, { replace: true })
            return
          } else {
            logout()
            return
          }
        } catch (e) {
          logout()
          return
        }
      }

      if (code) {
        processAuthCode(code, platform).then(auth => {
          setOtherPlatformAuthInfo(auth, platform)
          searchParams.delete("code")
          setSearchParams(searchParams)
        })
      }

      if (!code && !platformAuth) {
        const url = new URL(import.meta.env.VITE_SSO_URL)
        url.searchParams.append("platform", platform)
        url.searchParams.append("redirect-url", window.location.href)
        window.location.href = url.toString()
      }
    }

    run()
  }, [code])

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <CircularProgress />
      </div>
    )
  }

  return (
    <div>
      <TopNavigation showSignInButton={false} />
      <Profile />
    </div>
  )
}

export default ProfileAsDifferentPlatform
