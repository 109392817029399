import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { InfoCircle } from "iconoir-react"

type Props = { className?: string; title?: string | null }

const ErrorMessage = ({ className, title }: Props) => {
  return (
    <div className={clsx(className, "flex items-center text-critical-500")}>
      <InfoCircle className="me-1 size-2.25 shrink-0" />
      <Typography variant="strongSmallBody">{title}</Typography>
    </div>
  )
}

export default ErrorMessage
