export const highlightHashtags = (text: string) => {
  const words = text.split(" ")
  return words.map((word, index) =>
    word.startsWith("#") && word !== "#" ? (
      <span key={index} className="font-semibold text-interactive-500">
        {word}{" "}
      </span>
    ) : (
      word + " "
    )
  )
}
