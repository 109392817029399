import { Typography } from "@suraasa/placebo-ui"
import { AISparkleFilled } from "assets/icons/system/AISparkle"
import clsx from "clsx"
import React from "react"
import { Link } from "react-router-dom"

import styles from "./Card.module.css"

const Card = ({
  title,
  subtitle,
  to,
  gradient,
  description,
  isPro,
  isProSubscribed,
  isFree,
  isProAvailable,
}: {
  title: string
  subtitle: string
  to: string
  gradient: string
  description: string
  isPro?: boolean
  isProSubscribed?: boolean
  isFree?: boolean
  isProAvailable?: boolean
}) => {
  return (
    <Link
      to={to}
      className={clsx(
        styles.parentContainer,
        "group rounded-2xl border border-solid border-surface-300"
      )}
      style={
        {
          "--backgroundColor": gradient,
        } as React.CSSProperties
      }
    >
      <div className="flex h-[190px] w-full flex-col justify-between rounded-2xl p-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            <Typography variant="smallBody" className="mr-0.25">
              {title}
            </Typography>
            <AISparkleFilled fill={gradient} className={styles.svgColor} />
          </div>
          <div>
            {isPro && (
              <div className="flex rounded bg-[#0F172A1A] md:flex-col lg:flex-row">
                <div>
                  <div className="rounded bg-onSurface-900 px-0.5">
                    <Typography
                      className="bg-clip-text text-center text-transparent"
                      style={{
                        fontFamily: "'Clash Display', sans-serif",
                        fontSize: "15.5px",
                        fontWeight: 600,
                        backgroundImage:
                          "linear-gradient(85deg, rgba(70,193,108,1) 18%, rgba(168,168,54,1) 39%, rgba(222,119,67,1) 64%)",
                      }}
                    >
                      PRO
                    </Typography>
                  </div>
                </div>
                {!isProSubscribed && isProAvailable && (
                  <div className="rounded px-0.5">
                    <Typography
                      style={{
                        fontFamily: "'Clash Display', sans-serif",
                        fontSize: "15.5px",
                        fontWeight: 500,
                      }}
                      className="text-center text-onSurface-900 group-hover:text-inherit"
                    >
                      Available
                    </Typography>
                  </div>
                )}
              </div>
            )}
            {isFree && (
              <div className="rounded bg-[#0F172A1A] px-0.5">
                <Typography
                  style={{
                    fontFamily: "'Clash Display', sans-serif",
                    fontSize: "15.5px",
                    fontWeight: 500,
                  }}
                  className="text-onSurface-900 group-hover:text-inherit"
                >
                  Free
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className="w-[210px] lg:w-[250px]">
          <Typography variant="title3">{subtitle}</Typography>
          <Typography
            variant={"smallBody"}
            className="mt-1 text-onSurface-500 group-hover:text-inherit"
          >
            {description}
          </Typography>
        </div>
      </div>
    </Link>
  )
}

export default Card
