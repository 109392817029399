import { Typography } from "@suraasa/placebo-ui"
import { IconButton, LinearProgress } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { ArrowLeft } from "iconoir-react"
import { PropsWithChildren } from "react"

const ActivityContainer = ({
  children,
  onBack,
  title,
  className,
  endSlot,
  progress,
}: PropsWithChildren<{
  onBack?: () => void
  title: string
  className?: string
  endSlot?: JSX.Element
  progress?: number
}>) => {
  return (
    <div className={clsx("-mx-2 sm:mx-auto", className)}>
      <div className="flex items-center justify-between border-b border-onSurface-200 px-1 py-2 sm:px-2">
        <div className="flex items-center space-x-1">
          {onBack && (
            <IconButton onClick={onBack} color="black">
              <ArrowLeft width={20} height={20} />
            </IconButton>
          )}
          <Typography variant="title3">{title}</Typography>
        </div>
        <div>{endSlot}</div>
      </div>
      {progress !== undefined && (
        <LinearProgress
          value={progress}
          color="success"
          className="!rounded-none [&>.LinearProgress-colored-bar]:rounded-[0px_4px_4px_0px]"
          size="lg"
        />
      )}

      <div className="bg-white p-2">{children}</div>
    </div>
  )
}

export default ActivityContainer
