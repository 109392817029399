import { SubjectiveAssessmentResponse } from "api/resources/aiTools/types"

import SubjectiveAssessmentContent from "./SubjectiveAssessmentContent"

const SubjectiveAssessmentOutput = (props: SubjectiveAssessmentResponse) => {
  return (
    <div className="mb-4 space-y-4">
      <SubjectiveAssessmentContent {...props} />
    </div>
  )
}

export default SubjectiveAssessmentOutput
