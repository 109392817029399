import { Navigate } from "react-router-dom"

type Props = {
  to: string
}

const Redirect = ({ to }: Props) => {
  return (
    <Navigate
      to={{
        pathname: to,
        search: window.location.search,
      }}
      replace
    />
  )
}

export default Redirect
