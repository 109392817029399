import { Typography } from "@suraasa/placebo-ui"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@suraasa/placebo-ui-legacy"
import BrowserSpecificAsset from "features/ITO/Assessments/Instructions/BrowserSpecificAsset"
import { browserName, osName } from "react-device-detect"

type Props = {
  open: boolean
}
// TODO Update images for Chrome:IOS

const SamsungData = {
  Android: [
    {
      text: "Click on the 'Menu' button on the bottom right of the browser.",
      img: "/assets/microphone-permission-steps/samsung-browser-step-1.png",
    },
    {
      text: "Click on 'Settings'.",
      img: "/assets/microphone-permission-steps/samsung-browser-step-2.png",
    },
    {
      text: "Go to 'Sites and downloads'.",
      img: null,
    },
    {
      text: "Go to 'Site permissions'.",
      img: "/assets/microphone-permission-steps/samsung-browser-step-3.png",
    },
    {
      text: "Click on 'Microphone'.",
      img: "/assets/microphone-permission-steps/samsung-browser-step-4.png",
    },
    {
      text: "Find 'learn.suraasa.com' and turn on both switches as shown.",
      img: "/assets/microphone-permission-steps/samsung-browser-step-5.png",
    },
    {
      text: "Click on the 'Refresh Page' button below.",
      img: null,
    },
  ],
}

const AllowMicrophoneDialog = ({ open }: Props) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography variant="subtitle2">
          Allow microphone before proceeding ({browserName} on {osName})
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className="mb-3">
          Looks like access to your microphone is blocked. Follow these steps to
          allow microphone access:
        </Typography>

        <div className={"ml-2.5"}>
          <ol className="list-outside list-decimal">
            <BrowserSpecificAsset
              render={steps => (
                <>
                  {steps.map(({ text, img }, i) => (
                    <li key={i} className="">
                      <Typography>{text}</Typography>
                      {img && (
                        <img
                          width="100%"
                          src={img}
                          alt="microphone block icon"
                          className="mt-1"
                        />
                      )}
                    </li>
                  ))}
                </>
              )}
              data={{
                SamsungInternet: SamsungData,
                SamsungBrowser: SamsungData,
                MobileSafari: {
                  IOS: [
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                    {
                      text: "You will be presented with a prompt as displayed below. Press 'Allow'.",
                      img: "/assets/microphone-permission-steps/ios-safari-prompt.png",
                    },
                  ],
                },
                Safari: {
                  MAC_OS: [
                    {
                      text: `Click on 'Safari' at the top-left corner of your screen and then click on 'Settings for ${window.location.origin}'.`,
                      img: "/assets/microphone-permission-steps/macos-safari-menu.jpeg",
                    },
                    {
                      text: "Now change Microphone setting from 'Ask' to 'Allow'.",
                      img: "/assets/microphone-permission-steps/macos-safari-settings.jpeg",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                },
                Firefox: {
                  Windows: [
                    {
                      text: "Click on the microphone icon at the left end of the address bar of your browser.",
                      img: "/assets/microphone-permission-steps/firefox-address-bar.png",
                    },
                    {
                      text: "Click on the 'Blocked Temporarily' button to reset the permission.",
                      img: "/assets/microphone-permission-steps/firefox-settings-prompt.png",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                  MAC_OS: [
                    {
                      text: "Click on the microphone icon at the left end of the address bar of your browser.",
                      img: "/assets/microphone-permission-steps/firefox-address-bar.png",
                    },
                    {
                      text: "Click on the 'Blocked Temporarily' button to reset the permission.",
                      img: "/assets/microphone-permission-steps/firefox-settings-prompt.png",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                },
                Chrome: {
                  IOS: [
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                    {
                      text: "You will be presented with a prompt as displayed below. Press 'Allow'.",
                      img: "/assets/microphone-permission-steps/ios-safari-prompt.png",
                    },
                  ],
                  Android: [
                    {
                      text: "Click on the lock icon on the address bar.",
                      img: "/assets/microphone-permission-steps/mobile-chrome-step-1.png",
                    },
                    {
                      text: "Click on ‘Permissions’.",
                      img: "/assets/microphone-permission-steps/mobile-chrome-step-2.png",
                    },
                    {
                      text: "Change the Microphone setting from 'Blocked' to 'Allow'.",
                      img: "/assets/microphone-permission-steps/mobile-chrome-step-3.png",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                  Windows: [
                    {
                      text: "Click on the microphone icon at the right end of the address bar of your browser.",
                      img: "/assets/microphone-permission-steps/chrome-address-bar.png",
                    },
                    {
                      text: `Now select the 'Always allow ${window.location.origin} to access your microphone' button and then click 'Done'.`,
                      img: "/assets/microphone-permission-steps/chrome-settings-prompt.png",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                  MAC_OS: [
                    {
                      text: "Click on the microphone icon at the right end of the address bar of your browser.",
                      img: "/assets/microphone-permission-steps/chrome-address-bar.png",
                    },
                    {
                      text: "Now change microphone setting from 'Ask' to 'Allow'.",
                      img: "/assets/microphone-permission-steps/chrome-settings-prompt.png",
                    },
                    {
                      text: "Click on the 'Refresh Page' button below.",
                      img: null,
                    },
                  ],
                },
              }}
            />

            <li>
              <Typography>
                If your microphone still does not work after following the steps
                above, then please make sure your device has a microphone and it
                is not blocked by the operating system.{" "}
                <a
                  href={`https://www.google.com/search?q=how+to+turn+microphone+on+${osName}+${browserName}`}
                  target="_blank"
                  className="border-b border-interactive-500 text-interactive-500"
                  rel="noreferrer"
                >
                  Click here to learn more
                </a>{" "}
                or try using a different device.
              </Typography>
            </li>
          </ol>
        </div>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Refresh page",
            onClick: () => {
              window.location.reload()
            },
          },
          secondary: null,
        }}
      />
    </Dialog>
  )
}

export default AllowMicrophoneDialog
