import { Divider, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import React from "react"
import { createUseStyles, useTheme } from "react-jss"

const useStyles = createUseStyles(({ spacing }) => ({
  root: ({ xPadding }: Required<Pick<Props, "xPadding">>) => ({
    padding: spacing(2, xPadding),
  }),
}))

interface Props {
  heading?: React.ReactElement
  /**
   * This value will be overwritten to 16px on sm down.
   */
  xPadding?: 1.5 | 2 | 3
}

const SectionHeading = ({ heading, xPadding = 2 }: Props) => {
  const theme = useTheme<Theme>()

  if (useMediaQuery(theme.breakpoints.only("xs"))) {
    xPadding = 2
  }

  const classes = useStyles({ xPadding })

  return (
    <>
      <div className={clsx(classes.root)}>{heading && heading}</div>
      <Divider color="onSurface.200" weight="light" />
    </>
  )
}

export default SectionHeading
