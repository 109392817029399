import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ToolType } from "api/resources/aiTools/types"
import { context } from "global/Context/context"
import React, { createContext, useContext, useEffect, useState } from "react"

import { FREE_CLAIM_PRODUCT_SLUG } from "../constants"
import GetProSheet from "../GetProSheet"

type ShowGetProSheetArgs = {
  claimCallback?: () => Promise<void>
  tool?: ToolType
  show: boolean
}

type Context = {
  status: SuraasaProSubscriptionStatus | undefined
  mode: SuraasaProSubscriptionMode | undefined
  isActive: boolean | undefined
  showGetProSheet: (options?: Omit<ShowGetProSheetArgs, "show">) => void
  refreshState: () => void
}

const SuraasaProContext = createContext<Context>({
  isActive: undefined,
  status: undefined,
  mode: undefined,
  showGetProSheet: () => {},
  refreshState: () => {},
})

export const useSuraasaProContext = () => useContext(SuraasaProContext)

enum SuraasaProSubscriptionStatus {
  active,
  paused,
  expired,
  inActive,
}

enum SuraasaProSubscriptionMode {
  paid,
  freeTrial,
}

export const ProAccessProvider = (props: { children: React.ReactNode }) => {
  const [status, setStatus] = useState<SuraasaProSubscriptionStatus>()
  const [mode, setMode] = useState<SuraasaProSubscriptionMode>()

  const { isAuthenticated } = useContext(context)

  const [isGetProSheetOpen, setIsGetProSheetOpen] =
    useState<ShowGetProSheetArgs>({
      show: false,
    })

  const orders = useQuery({
    enabled: isAuthenticated,
    queryFn: () =>
      api.orders.listOrders({
        params: {
          product_slug: FREE_CLAIM_PRODUCT_SLUG,
        },
      }),
    queryKey: queries.orders.list({
      product_slug: FREE_CLAIM_PRODUCT_SLUG,
    }).queryKey,
  })

  useEffect(() => {
    if (orders.data && orders.data.data.length > 0) {
      setStatus(SuraasaProSubscriptionStatus.active)
      setMode(SuraasaProSubscriptionMode.freeTrial)
    } else {
      setStatus(SuraasaProSubscriptionStatus.inActive)
    }
  }, [orders.data])

  const isActive =
    status !== undefined
      ? status === SuraasaProSubscriptionStatus.active
      : undefined

  return (
    <SuraasaProContext.Provider
      value={{
        isActive,
        status,
        mode,
        showGetProSheet: (options?: Omit<ShowGetProSheetArgs, "show">) => {
          setIsGetProSheetOpen({ show: true, ...options })
        },
        refreshState: orders.refetch,
      }}
    >
      <GetProSheet
        open={isGetProSheetOpen.show}
        tool={isGetProSheetOpen.tool}
        claimCallback={isGetProSheetOpen.claimCallback}
        handleClose={() => {
          setIsGetProSheetOpen({ show: false })
        }}
      />
      {props.children}
    </SuraasaProContext.Provider>
  )
}
