import { toast, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  IconButton,
} from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import clsx from "clsx"
import { ArrowSeparateVertical, Plus } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { handleErrors } from "utils/helpers"

import ProfileContext from "../../context"
import RemoveDialog from "../RemoveDialog"
import DraggableItem from "../ReOrderingFeatureOnboarding/DraggableItem"
import useDraggable from "../ReOrderingFeatureOnboarding/DraggableItem/useDraggable"
import AddDialog from "./AddDialog"

const useStyles = createUseStyles(theme => ({
  dialogContent: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  addSkills: {
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },
}))

const SkillsDialog = ({
  open,
  handleDialogClose,
}: { handleDialogClose: () => void } & Pick<DialogProps, "open">) => {
  const classes = useStyles()

  const {
    skills: { data, add, remove, set },
  } = useContext(ProfileContext)

  const hasNoItems = data.length === 0
  const [changePriority, setChangePriority] = useState(false)

  const skillList = useDraggable({ data })

  const [openAddSkillDialog, setOpenAddSkill] = useState(hasNoItems)
  const [skillToDelete, setSkillToDelete] = useState<string | null>(null)

  const [removeDialogLoading, setRemoveDialogLoading] = useState(false)

  const handleRemove = async () => {
    if (!skillToDelete) return

    setRemoveDialogLoading(true)

    try {
      await api.profile.skills.delete({
        urlParams: { id: skillToDelete },
      })
      remove(skillToDelete)
      toast.success("Removed successfully.")
      setSkillToDelete(null)
    } catch (e) {
      toast.error("Unable to process your request")
      console.error(e)
    }
    setRemoveDialogLoading(false)
  }

  const { mutate: handleUpdateSequence, isLoading: loadingSequence } =
    useMutation({
      mutationFn: (data: { id: number; sequence: number }[]) =>
        api.profile.skills.updateSequence({
          data: { skills: data },
        }),
      onSuccess: () => {
        toast.success("Successfully Updated")
        setChangePriority(false)
        const newData = skillList.processData(false)
        if (newData && set) {
          set(newData)
        }
      },
      onError: err => {
        if (err instanceof APIError) {
          handleErrors(err)
        }
      },
    })

  useEffect(() => {
    if (open) setOpenAddSkill(hasNoItems)
  }, [open, hasNoItems])

  return (
    <Dialog
      open={open}
      width={518}
      onAfterClose={() => {
        setOpenAddSkill(false)
        setChangePriority(false)
        skillList.reset()
      }}
      onRequestClose={() => handleDialogClose()}
    >
      {openAddSkillDialog ? (
        <AddDialog
          handleBack={hasNoItems ? undefined : () => setOpenAddSkill(false)}
          open={openAddSkillDialog}
          onAdd={add}
          onRequestClose={() => handleDialogClose()}
        />
      ) : (
        <>
          <RemoveDialog
            handleClose={() => setSkillToDelete(null)}
            loading={removeDialogLoading}
            open={Boolean(skillToDelete)}
            title="Remove Skill"
            keepScrollLocked
            onRemove={handleRemove}
          >
            <Typography variant="smallBody">
              Are you sure you want to remove{" "}
              <b>{data.find(item => item.id === skillToDelete)?.skillName}</b>{" "}
              from your profile?
            </Typography>
          </RemoveDialog>
          <DialogTitle>Skills</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div
              className={
                " flex items-center justify-between border-b border-b-onSurface-200 px-1.5 py-2"
              }
            >
              <Button
                startAdornment={<Plus />}
                variant="text"
                onClick={() => setOpenAddSkill(true)}
              >
                Add New Skill
              </Button>
              <IconButton
                onClick={() => {}}
                variant={changePriority ? "filled" : "plain"}
                size="xs"
                className="!grid sm:!hidden [&>svg]:size-2.5"
              >
                <ArrowSeparateVertical />
              </IconButton>

              <Button
                startAdornment={<ArrowSeparateVertical />}
                variant="text"
                className="!hidden sm:!grid"
                onClick={() => setChangePriority(true)}
              >
                Change Priority
              </Button>
            </div>

            <div>
              {skillList.data.length > 0 &&
                skillList.data.map((item, i) => (
                  <DraggableItem
                    showTags={false}
                    onDelete={() => {
                      setSkillToDelete(item.id)
                    }}
                    className={clsx(
                      "[&>div]:mx-1.5 [&>div]:py-1.5 sm:[&>div]:mx-3",
                      {
                        "[&>div]:border-b [&>div]:border-onSurface-200":
                          skillList.data.length - 1 !== i,
                      }
                    )}
                    isDraggable={changePriority}
                    key={item.id}
                    index={i}
                    onDragStart={skillList.onDragStart}
                    onDrop={skillList.onDrop}
                    onDragOver={skillList.onDragOver}
                  >
                    <Typography
                      className="mr-2 text-onSurface-800"
                      variant="strong"
                    >
                      {item.skillName}
                    </Typography>
                  </DraggableItem>
                ))}
            </div>
          </DialogContent>
          <DialogFooter>
            {changePriority && (
              <div className="flex justify-end">
                <Button
                  variant="outlined"
                  color="critical"
                  size="sm"
                  onClick={() => {
                    skillList.reset()
                    setChangePriority(false)
                  }}
                  className="mr-1"
                >
                  Discard
                </Button>
                <Button
                  loading={loadingSequence}
                  onClick={() => {
                    const newData = skillList.processData(true)
                    handleUpdateSequence(newData)
                  }}
                  size="sm"
                >
                  Save
                </Button>
              </div>
            )}
            {!changePriority && (
              <Button
                onClick={handleDialogClose}
                className="ml-auto !block"
                size="sm"
                variant="outlined"
                color="secondary"
              >
                Close
              </Button>
            )}
          </DialogFooter>
        </>
      )}
    </Dialog>
  )
}

export default SkillsDialog
