import React from "react"
import { PropsWithClassName } from "utils/types"

const LikeSvg = ({ className }: PropsWithClassName) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7815 1.53516L6.32006 5.99663C6.02876 6.28793 5.84478 6.70189 5.84478 7.13117V15.1956C5.84478 16.0848 6.56537 16.8054 7.4546 16.8054H14.7064C15.3503 16.8054 15.9329 16.4221 16.1782 15.8242L18.7999 9.69153C19.4899 8.09705 18.3093 6.31859 16.5769 6.31859H12.0234L12.79 2.6237C12.8666 2.22507 12.744 1.81112 12.4527 1.51982C12.0081 1.05988 11.2415 1.05988 10.7815 1.53516ZM2.60981 16.8207C3.49904 16.8207 4.21962 16.1001 4.21962 15.2109V8.75631C4.21962 7.86708 3.49904 7.1465 2.60981 7.1465C1.72058 7.1465 1 7.86708 1 8.75631V15.2109C1 16.0848 1.73591 16.8207 2.60981 16.8207Z"
        fill="inherit"
        stroke="#1E293B"
      />
    </svg>
  )
}

export default LikeSvg
