import { Typography } from "@suraasa/placebo-ui"
import { CircularProgress } from "@suraasa/placebo-ui-legacy"
import api from "api"
import {
  AssignmentAttachment,
  SubmissionAttachment,
} from "api/resources/assignments/types"
import clsx from "clsx"
import { Attachment as AttachmentIcon, XmarkCircle } from "iconoir-react"
import { useState } from "react"

import styles from "./Attachment.module.css"

const downloadFile = (name: string, url: string) => {
  const link = document.createElement("a")
  link.download = name
  link.href = url
  link.target = "_blank"
  link.click()
}

const getFileURL = async (id: number) => {
  try {
    return await api.assignments.getAttachment({
      urlParams: {
        attachmentId: id,
      },
    })
  } catch {
    return null
  }
}

export type AttachmentType = AssignmentAttachment | SubmissionAttachment | File

type Props = {
  file: AttachmentType
  onDelete?: (file: AttachmentType) => Promise<void>
}

const Attachment = ({ file, onDelete }: Props) => {
  const name = file.name

  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    if (loading) return

    if (onDelete) {
      setLoading(true)
      await onDelete(file)
      setLoading(false)
      return
    }

    // type 'File' means that the user has just uploaded it. It will be deleted upon click, which is handled above.
    if (file instanceof File) {
      throw new Error(
        "Trying to click on a locally uploaded file. Pass 'onDelete' handler."
      )
    }

    if ("url" in file) {
      return downloadFile(name, file.url)
    }

    setLoading(true)
    const data = await getFileURL(file.id)
    if (data?.url) downloadFile(name, data.url)

    setLoading(false)
  }

  return (
    <button
      className={clsx("me-3 flex cursor-pointer items-center", {
        [styles.hideIcons]: onDelete,
      })}
      onClick={handleClick}
    >
      <div className="me-1 flex items-center justify-center rounded-lg border border-surface-200 bg-common-white-500 p-1.75">
        {loading ? (
          <CircularProgress size="sm" />
        ) : (
          <>
            {onDelete && (
              <XmarkCircle className="deleteIcon text-critical-500" />
            )}
            <AttachmentIcon className="attachmentIcon" />
          </>
        )}
      </div>
      <div>
        <Typography variant="strongSmallBody">{name}</Typography>
      </div>
    </button>
  )
}

export default Attachment
