import { Typography } from "@suraasa/placebo-ui"
import React from "react"

const Card = ({
  img,
  link,
  title,
}: {
  img: string
  link: string
  title: string
}) => {
  return (
    <a
      href={link}
      target="_blank"
      className="w-[250px] shrink-0 overflow-hidden rounded-xl border border-surface-100 bg-white"
      rel="noreferrer"
    >
      <img src={img} className="h-[142px] w-full object-cover" alt="cover" />
      <div className="p-2 text-start">
        <Typography variant="title4">{title}</Typography>
      </div>
    </a>
  )
}

export default Card
