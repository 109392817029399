import { Typography } from "@suraasa/placebo-ui"
import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import LessonPlanThumbnail from "assets/AITools/lesson-plan.webp"
import QuizThumbnail from "assets/AITools/quiz.webp"
import RubricsThumbnail from "assets/AITools/rubrics.webp"
import clsx from "clsx"
import { ArrowRight } from "iconoir-react"
import { useState } from "react"
import { useTheme } from "react-jss"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import styles from "../Onboarding.module.css"

enum Steps {
  Step1,
  Step2,
  Step3,
}

const allSteps = [Steps.Step1, Steps.Step2, Steps.Step3]

type Props = {
  closeOnboarding: () => void
}

const Tools = ({ closeOnboarding }: Props) => {
  const [step, setStep] = useState<Steps>(Steps.Step1)
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const displayContent = (function () {
    switch (step) {
      case Steps.Step1:
        return {
          title: "Lesson Plan Generator",
          subTitle:
            "Craft comprehensive lesson plans effortlessly, using just a few keywords.",
          image: LessonPlanThumbnail,
        }
      case Steps.Step2:
        return {
          title: "Rubrics Generator",
          subTitle:
            "Simplify assessment creation with the AI Rubrics Generator, enabling precise assignment evaluation.",
          image: RubricsThumbnail,
        }
      case Steps.Step3:
        return {
          title: "Quiz Generator",
          subTitle:
            "Engage and challenge your students with customised quizzes.",
          image: QuizThumbnail,
        }
    }
  })()

  const navigate = useNavigate()

  const isLastStep = step === allSteps[allSteps.length - 1]

  const onClick = () => {
    if (isLastStep) {
      closeOnboarding()
      if (window.location.pathname.includes("ai-tools")) return
      navigate(routes.aiTools.home)
      return
    }
    setStep(step + 1)
  }

  return (
    <div className="flex h-full flex-col justify-between">
      <div>
        <div className="relative overflow-hidden bg-[#F5F5F5] sm:rounded-xl">
          <img
            className={clsx(
              "hidden size-full max-h-[20vw] min-h-[224px] object-cover",
              {
                "!block": step === Steps.Step1,
              }
            )}
            style={{
              background:
                "radial-gradient(circle, rgba(98,58,0,1) 0%, rgba(40,24,0,1) 100%)",
            }}
            src={LessonPlanThumbnail}
            alt="img"
          />
          <img
            className={clsx(
              "hidden size-full max-h-[20vw] min-h-[224px] object-cover",
              {
                "!block": step === Steps.Step2,
              }
            )}
            style={{
              background:
                "radial-gradient(circle, rgba(0,87,103,1) 0%, rgba(1,37,43,1) 100%)",
            }}
            src={RubricsThumbnail}
            alt="img"
          />
          <img
            className={clsx(
              "hidden size-full max-h-[20vw] min-h-[224px] object-cover",
              {
                "!block": step === Steps.Step3,
              }
            )}
            style={{
              background:
                "radial-gradient(circle, rgba(50,85,0,1) 0%, rgba(21,33,1,1) 100%)",
            }}
            src={QuizThumbnail}
            alt="img"
          />
          <div className="absolute left-2 top-2">
            <div className="rounded-[4px] bg-white px-1.25 py-0.5">
              <Typography variant="preTitle">NEW</Typography>
            </div>
          </div>
          {!isXsDown && (
            <Typography
              className="absolute right-2.5 top-2.5 text-onSurface-400"
              variant="largeBody"
            >
              {step + 1}/{allSteps.length}
            </Typography>
          )}
        </div>
        <div className="z-10 flex flex-col justify-start p-3">
          <div className="mb-5 md:mb-0">
            <Typography
              className={clsx({
                "text-start": isXsDown,
                "text-center": !isXsDown,
              })}
              variant="title1"
            >
              {displayContent.title}
            </Typography>
            <Typography
              variant={isXsDown ? "body" : "largeBody"}
              className={clsx(
                "mb-0 block text-onSurface-500 sm:mx-auto sm:mb-2.75",
                {
                  "max-w-[500px] min-h-[70px] sm:!min-h-[auto]": true,
                  "text-start": isXsDown,
                  "text-center": !isXsDown,
                }
              )}
            >
              {displayContent.subTitle}
            </Typography>
          </div>
        </div>
      </div>
      <div className="p-3">
        {isXsDown ? (
          <div className="flex items-center justify-between">
            {!isLastStep && (
              <div className="flex gap-0.5">
                {allSteps.map(item => (
                  <span
                    key={item}
                    className={clsx(styles.dot, {
                      [styles.expandDot]: item === step,
                    })}
                  />
                ))}
              </div>
            )}
            <Button
              color="black"
              variant="filled"
              size="lg"
              onClick={onClick}
              rounded
              fullWidth={isLastStep}
              endAdornment={<ArrowRight />}
            >
              {isLastStep ? (
                <Typography variant="button">
                  Experience the AI Magic
                </Typography>
              ) : (
                "Next"
              )}
            </Button>
          </div>
        ) : (
          <Button
            size="lg"
            color="black"
            className={clsx(styles.nextButton, "mx-auto")}
            onClick={onClick}
            endAdornment={<ArrowRight />}
          >
            {isLastStep ? (
              <Typography variant="button" className="me-4">
                Experience the AI Magic
              </Typography>
            ) : (
              "Next"
            )}
          </Button>
        )}
      </div>
    </div>
  )
}

export default Tools
