import { Typography } from "@suraasa/placebo-ui"
import { Timer } from "iconoir-react"
import React from "react"

export type CardProps = {
  img: string
  duration?: string
  title: string
  content: string
  link: string
}

const Card = ({ img, duration, title, content, link }: CardProps) => {
  return (
    <a
      href={link}
      target="_blank"
      className="relative mr-3 overflow-hidden rounded-xl bg-white"
      rel="noreferrer"
    >
      {duration && (
        <div className="absolute left-2 top-2 flex space-x-0.5 rounded bg-white/50 p-0.5">
          <Timer width={20} height={20} color="#1E293B" />
          <Typography variant="smallBody" className="text-onSurface-800">
            {duration}
          </Typography>
        </div>
      )}
      <img
        src={img}
        alt="resource-img"
        className="h-[141px] w-full object-cover"
      />
      <div className="p-2">
        <Typography variant="title4" className="mb-1 text-onSurface-800">
          {title}
        </Typography>
        <Typography variant="smallBody" className="text-onSurface-800">
          {content}
        </Typography>
      </div>
    </a>
  )
}

export default Card
