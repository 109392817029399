import { Typography } from "@suraasa/placebo-ui"
import { Button, IconButton } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { Xmark } from "iconoir-react"

type Option = string

type Props = {
  onClose: () => void
  options: Option[]
  selectedOption: Option | null
  onSelect: (value: Option) => void
  openFor: string | null
}
const Options = ({
  options,
  selectedOption,
  onSelect,
  onClose,
  openFor,
}: Props) => {
  return (
    <div className="min-h-[30vh] w-full rounded-[16px_16px_0px_0px] bg-white py-2 shadow-[0px_-8px_25px_0px_rgba(0,0,0,0.10)]">
      <div className="relative">
        <IconButton
          size="xs"
          color="black"
          variant="plain"
          className="absolute right-1 top-0"
          onClick={() => {
            onClose()
          }}
        >
          <Xmark />
        </IconButton>

        <div className="pl-1 pr-5">
          {!openFor && (
            <Typography className="text-center">
              Identify the correct part of speech
            </Typography>
          )}
          {openFor && (
            <Typography
              className={clsx("text-center", {
                "mb-4": true,
              })}
            >
              Identify the correct part of speech for{" "}
              <strong>“{openFor}”</strong>
            </Typography>
          )}
        </div>
        {openFor && (
          <div className="flex flex-wrap items-end justify-center gap-2 px-1">
            {options.map(option => {
              return (
                <Button
                  onClick={() => {
                    onSelect(option)
                    onClose()
                  }}
                  variant={selectedOption === option ? "filled" : "outlined"}
                  size="sm"
                  key={option}
                >
                  {option}
                </Button>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Options
