import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Theme,
} from "@suraasa/placebo-ui-legacy"
import { ASSESSMENT_ROOT_DIV_ID } from "features/ITO/Assessments/Attempt/constants"
import {
  getOffencePercentage,
  getWarningGradient,
} from "features/ITO/Assessments/helpers"
import OverlayWrapper from "features/ITO/Assessments/Proctoring/OverlayWrapper"
import { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import Webcam from "react-webcam"

type Props = {
  title: string
  isOpen: boolean
  totalWarnings: number
  maxWarningCount: number
  onClose?: () => void
}
const useStyles = createUseStyles((theme: Theme) => ({
  color: {
    color: theme.colors.onSurface[600],
  },
}))

const NoFaceDetectedAlert = ({
  title,
  isOpen,
  totalWarnings,
  onClose,
  maxWarningCount,
}: Props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const offensePercentage = getOffencePercentage(totalWarnings, maxWarningCount)

  useEffect(() => {
    if (isOpen) setOpen(true)
  }, [isOpen])

  const handleClose = () => {
    setOpen(false)
    if (onClose) onClose()
  }

  return (
    <OverlayWrapper disabled={!open}>
      <Dialog
        open={open}
        width="md"
        parentSelector={() =>
          document.querySelector(`#${ASSESSMENT_ROOT_DIV_ID}`)
        }
      >
        <DialogTitle>{title}</DialogTitle>
        {open && (
          <DialogContent className="m-1 grid content-start items-center gap-2">
            <div className="flex items-center justify-between">
              <div className="grid grow gap-0.75">
                <Typography variant="preTitle" className={classes.color}>
                  Violation count
                </Typography>
                <LinearProgress
                  color="warning"
                  value={offensePercentage}
                  linearGradient={getWarningGradient(offensePercentage)}
                />
                <div className={classes.color}>
                  <Typography variant="strongSmallBody">
                    {totalWarnings} out of {maxWarningCount} times
                  </Typography>
                  <Typography variant="smallBody">triggered</Typography>
                </div>
              </div>
              <div className="ms-2">
                <Webcam
                  mirrored
                  playsInline
                  className="max-w-[150px] rounded-xl"
                  videoConstraints={{
                    facingMode: "user",
                  }}
                />
              </div>
            </div>
            <Typography>
              No face detected. Ensure your face is in view and stay still.
            </Typography>
            <Typography variant="strong" className="-mt-1 text-critical-500">
              If you commit 10 camera violations, your attempt will be
              disqualified!
            </Typography>
            <div className="flex justify-end">
              <div className="flex items-center">
                <Button className="ms-2" onClick={handleClose}>
                  Okay, I understand
                </Button>
              </div>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </OverlayWrapper>
  )
}

export default NoFaceDetectedAlert
