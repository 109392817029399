import { Typography } from "@suraasa/placebo-ui"
import { IconButton } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { Cloud, GoogleDocs, Link, Xmark } from "iconoir-react"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  preview: {
    borderRadius: "4px",
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },

  previewText: {
    position: "relative",
    borderRadius: "4px",
    background: theme.colors.onSurface[200],
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
    "& p": {
      padding: theme.spacing(0, 1),
      width: "100%",
      textAlign: "center",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },

  box: {
    position: "relative",
    width: 180,
    height: 120,
    background: theme.colors.onSurface[50],
  },

  cancelButton: {
    borderRadius: "50%",
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translate(50%,-50%)",
    height: 20,
    width: 20,
    zIndex: 1,
  },

  linkPreviewText: {
    position: "absolute",
    bottom: 0,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    width: "100%",
    background: theme.colors.onSurface[50],
    "& p": {
      width: "calc(100% - 8px - 24px)",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  height80: {
    height: "80%",
  },
}))

type Props = {
  onCancel?: () => void
  data: File | string
  name?: string
}

const PreviewFile = ({ data, onCancel, name, ...props }: Props) => {
  const classes = useStyles()

  const [imageError, toggleImageError] = useState(false)

  if (typeof data !== "string") {
    if (["image/jpeg", "image/png"].includes(data.type)) {
      return (
        <div className={clsx(classes.box, "PreviewFile-box")} {...props}>
          {onCancel && (
            <IconButton
              className={classes.cancelButton}
              color="critical"
              size="xs"
              variant="filled"
              onClick={onCancel}
            >
              <Xmark height="16" width="16" />
            </IconButton>
          )}
          {imageError ? (
            <div
              className={clsx(
                "flex items-center justify-center",
                classes.height80
              )}
            >
              <Cloud height="24" width="24" />
            </div>
          ) : (
            <img
              alt="certificate"
              className={classes.preview}
              src={URL.createObjectURL(data)}
              onError={() => toggleImageError(true)}
            />
          )}
        </div>
      )
    }
    return (
      <div className={clsx(classes.box, "PreviewFile-box")} {...props}>
        {onCancel && (
          <IconButton
            className={classes.cancelButton}
            color="critical"
            size="xs"
            variant="filled"
            onClick={onCancel}
          >
            <Xmark height="16" width="16" />
          </IconButton>
        )}
        <div className={classes.previewText}>
          <GoogleDocs height="24" width="24" />
          <Typography variant="strongSmallBody">{data.name}</Typography>
        </div>
      </div>
    )
  }

  return (
    <div className={clsx(classes.box, "PreviewFile-box")} {...props}>
      {onCancel && (
        <IconButton
          className={classes.cancelButton}
          color="critical"
          size="xs"
          variant="filled"
          onClick={onCancel}
        >
          <Xmark height="16" width="16" />
        </IconButton>
      )}
      {imageError ? (
        <div
          className={clsx("flex items-center justify-center", classes.height80)}
        >
          <Cloud height="24" width="24" />
        </div>
      ) : (
        <img
          alt="link"
          className={classes.preview}
          src={data}
          onError={() => toggleImageError(true)}
        />
      )}
      <div className={classes.linkPreviewText}>
        <Link height="24" width="24" />
        <Typography className="block" variant="strongSmallBody">
          <a href={data} rel="noreferrer" target="_blank">
            {name || data}
          </a>
        </Typography>
      </div>
    </div>
  )
}

export default PreviewFile
