import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { isToday } from "date-fns"

import data from "./data.json"

const TeacherResource = ({ className }: { className?: string }) => {
  const todaysBlog = data.find(item => isToday(new Date(item.date)))

  if (!todaysBlog) {
    return null
  }

  const { image, title, description, link, type } = todaysBlog

  return (
    <div className={className}>
      <Typography variant="title2" className="mb-2">
        Today’s Teacher Resource
      </Typography>

      <div className="flex flex-col rounded-2xl bg-surface-100 md:h-[350px] md:flex-row">
        <img
          src={image}
          alt="cover-img"
          className="h-full max-h-[300px] shrink-0 rounded-t-2xl md:max-h-none md:max-w-[490px]  md:rounded-l-2xl md:!rounded-tr-none"
        />
        <div className="flex flex-col justify-between space-y-2 rounded-b-2xl p-3 md:rounded-r-2xl md:!rounded-bl-none">
          <div>
            <Typography variant="preTitle" className="text-onSurface-800">
              {type}
            </Typography>
            <Typography variant="title2" className="mb-2 text-onSurface-800">
              {title}
            </Typography>
            <Typography variant="largeBody" className="text-onSurface-800">
              {description}
            </Typography>
          </div>
          <Button
            component="a"
            href={link}
            rounded
            className="!block sm:!hidden"
            fullWidth
          >
            View
          </Button>
          <Button
            component="a"
            href={link}
            rounded
            className="!hidden sm:!block"
          >
            View
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TeacherResource
