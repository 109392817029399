import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { useScrollShiftOffset } from "utils/hooks/useScrollShiftOffset"

import styles from "./DialogBox.module.css"

type Props = { open: boolean; containerClassName?: string; className?: string }

const DialogBox = ({
  open,
  children,
  containerClassName,
  className,
}: PropsWithChildren<Props>) => {
  useScrollShiftOffset(open)
  if (!open) return null
  return (
    <div className={clsx(containerClassName, styles.container)}>
      <div className={clsx(className, "sm:rounded-2xl", styles.content)}>
        {children}
      </div>
    </div>
  )
}

export default DialogBox
