import SuraasaAppIcon from "assets/logos/suraasa-app-icon-large.png"
import satori from "satori"

import PlayIcon from "./assets/playIcon.png"

type Options = {
  courseName?: string
}
export const getCourseVideoPoster = async (
  title: string,
  options?: Options
) => {
  const fontData = await fetch(
    new URL("/assets/fonts/Inter-Regular.ttf", window.location.origin)
  ).then(res => res.arrayBuffer())

  const fontDataBold = await fetch(
    new URL("/assets/fonts/Inter-Bold.ttf", window.location.origin)
  ).then(res => res.arrayBuffer())

  const width =
    document.querySelector("video")?.getBoundingClientRect().width ?? 0
  const height = width ? (width * 9) / 16 : 0

  const multiplier = (number: number) => {
    if (window.innerWidth < 400) {
      return `${number * 1.2}px`
    }
    if (window.innerWidth < 600) {
      return `${number * 1.5}px`
    }
    if (window.innerWidth < 1080) {
      return `${number * 2}px`
    }
    if (window.innerWidth < 1280) {
      return `${number * 3}px`
    }
    return `${number * 4}px`
  }

  const svg = await satori(
    <div
      style={{
        backgroundColor: "#F5F5F5",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        borderRadius: 20,
        border: "1px solid #E0E0E0",
        padding: multiplier(12),
      }}
    >
      <img src={SuraasaAppIcon} alt="suraasa-icon" width={width / 6} />
      <p
        style={{
          marginTop: multiplier(14),
          fontFamily: "Inter",
          fontSize: multiplier(14),
          maxWidth: multiplier(250),
          lineHeight: 1,
          letterSpacing: multiplier(-0.5),
          fontWeight: 700,
        }}
      >
        {title}
      </p>

      <img
        src={PlayIcon}
        alt="play"
        width={96}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-48px, -24px)",
        }}
      />

      {options?.courseName && (
        <p
          style={{
            marginTop: multiplier(8),
            fontFamily: "Inter",
            fontSize: multiplier(4.5),
            color: "#82868F",
            letterSpacing: multiplier(0.5),
            fontWeight: 500,
          }}
        >
          {options.courseName.toUpperCase()}
        </p>
      )}
    </div>,
    {
      width,
      height,
      fonts: [
        {
          name: "Inter",
          data: fontData,
          weight: 400,
          style: "normal",
        },
        {
          name: "Inter",
          data: fontDataBold,
          weight: 700,
          style: "normal",
        },
      ],
    }
  )

  return `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`
}
