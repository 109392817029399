import { SocialAuthProvider } from "api/resources/settings/types"
import {
  getAnalytics,
  isSupported as analyticsIsSupported,
} from "firebase/analytics"
import { initializeApp } from "firebase/app"
import {
  collection as getCollection,
  getDocs,
  getFirestore,
} from "firebase/firestore"
import { getToken } from "firebase/messaging"
import { ValueOfEnum } from "utils/types"

const config = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}
enum CollectionName {
  featureToggles = "featureToggles",
  userFeedbackQueue = "userFeedbackQueue",
}

const app = initializeApp(config)

const enableAnalytics = async () => {
  const result = await analyticsIsSupported()
  if (result) {
    getAnalytics()
  }
}

enableAnalytics()

const messaging: any | undefined | null = undefined

// const setupMessaging = async () => {
//   messaging = (await messagingIsSupported()) ? getMessaging() : null

//   if (messaging) {
//     onMessage(messaging, payload => {
//       // eslint-disable-next-line no-console
//       console.log("> In-App notification recieved", payload)
//       if (payload.notification && payload.notification.body)
//         toast.info(payload.notification.body)
//     })
//   }
// }

// setupMessaging()

// navigator.serviceWorker
//   .register("./firebase-messaging-sw.js")
//   .then(registration => {
//     messaging.useServiceWorker(registration)

//     // Request permission and get token.....
//   })

// messaging
//   .requestPermission()
//   .then(function () {
//     console.log("Got Perms")
//   })
//   .catch(function (err) {
//     console.log("Perms Denied", err)
//   })

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app)

export const requestFirebaseNotificationPermission = () => {
  if (!("Notification" in window)) {
    return new Promise((resolve, reject) =>
      reject("Firebase is not supported on this browser")
    )
  }

  return Promise.resolve(Notification.requestPermission()).then(permission => {
    if (permission === "granted") {
      console.log("Notification permission granted.")
      // TODO(developer): Retrieve a registration token for use with FCM.
      // In many cases once an app has been granted notification permission,
      // it should update its UI reflecting this.
      return getFirebaseToken()
    } else {
      console.log("Unable to get permission to notify.")
    }

    // return new Promise((resolve, reject) => {
    //   if (messaging) {
    //     messaging
    //       .requestPermission()
    //       .then(getFirebaseToken)
    //       .then(firebaseToken => {
    //         resolve(firebaseToken)
    //       })
    //       .catch(err => {
    //         reject(err)
    //       })
    //   } else {
    //     reject("Firebase is not supported in this browser")
    //   }
    // })
  })
}

export function getFirebaseToken() {
  if (messaging)
    return getToken(messaging, {
      vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
    })
  return new Promise((resolve, reject) => {
    // reject("Firebase is not supported in this browser")
    reject(null)
  })
}

export const getFeatureToggleConfig = async () => {
  const collection = getCollection(db, CollectionName.featureToggles)
  const docs = await getDocs(collection)

  const data: any = {}
  docs.forEach(doc => {
    data[doc.id] = doc.data()
  })
  return data as FeatureToggleConfig
}

export type UserFeedbackQueue = {
  itemId: number | string
  itemName: string
  itemType: "course" | "class"
  // createdAt?: string
}

export const getUserFeedbackQueue = () => {
  const queue: UserFeedbackQueue[] =
    JSON.parse(localStorage.getItem("userFeedbackQueue") || "null") || []

  return queue

  // Firebase implementation
  // const authInfo = getAuthInfo()
  // if (!authInfo) return
  // const collection = getCollection(
  //   db,
  //   `${CollectionName.userFeedbackQueue}/${authInfo.user.email}/queue/`
  // )
  // const docs = await getDocs(collection)

  // const data = docs.docs.map(doc => doc.data()) as UserFeedbackQueue[]
  // console.log(data, docs)
  // return data
}

export const pushIntoUserFeedbackQueue = (data: UserFeedbackQueue) => {
  const queue: UserFeedbackQueue[] =
    JSON.parse(localStorage.getItem("userFeedbackQueue") || "null") || []
  const newQueue = [data, ...queue]

  const alreadyExists = Boolean(
    queue.find(x => getUniqueId(x) === getUniqueId(data))
  )
  if (alreadyExists) {
    console.log("Already exists")
    return
  }

  localStorage.setItem("userFeedbackQueue", JSON.stringify(newQueue))

  // Firebase implementation
  // const authInfo = getAuthInfo()
  // if (!authInfo) return
  // const docRef = doc(
  //   db,
  //   CollectionName.userFeedbackQueue,
  //   `${authInfo.user.email}/queue/${data.itemType} - ${data.itemName}`
  // )
  // const docSnap = await getDoc(docRef)
  // if (docSnap.exists()) {
  //   console.info("Entry already exists in UserFeedbackQueue", data)
  //   return
  // }
  // console.info("Adding to UserFeedbackQueue", data)
  // setDoc(docRef, data)
  // return null
}

const getUniqueId = (f: Pick<UserFeedbackQueue, "itemName" | "itemType">) =>
  `${f.itemType} - ${f.itemName}`

export const removeFromUserFeedbackQueue = (
  data: Pick<UserFeedbackQueue, "itemName" | "itemType">
) => {
  const queue: UserFeedbackQueue[] =
    JSON.parse(localStorage.getItem("userFeedbackQueue") || "null") || []
  const newQueue = queue.filter(x => getUniqueId(x) !== getUniqueId(data))

  console.log(queue, newQueue)

  localStorage.setItem("userFeedbackQueue", JSON.stringify(newQueue))

  // Firebase implementation
  // const authInfo = getAuthInfo()
  // if (!authInfo) return
  // const docRef = doc(
  //   db,
  //   CollectionName.userFeedbackQueue,
  //   `${authInfo.user.email}/queue/${data.itemType} - ${data.itemName}`
  // )
  // const docSnap = await getDoc(docRef)
  // if (docSnap.exists()) {
  //   console.info("Entry already exists in UserFeedbackQueue", data)
  //   return
  // }
  // console.info("Adding to UserFeedbackQueue", data)
  // setDoc(docRef, data)
  // return null
}

export const SOCIAL_AUTH_FIREBASE_KEY = "socialAccountAuthentication"
export const AI_TOOLS_FIREBASE_KEY = "aiTools"

type FeatureToggleConfig = {
  [SOCIAL_AUTH_FIREBASE_KEY]: {
    enabled: {
      web: boolean
      app: boolean
    }
    providers: ValueOfEnum<SocialAuthProvider>[]
  }
  [AI_TOOLS_FIREBASE_KEY]: {
    enabled: {
      web: boolean
      app: boolean
    }
  }
}

export { app }
