import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Container,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import Fallback from "assets/Fallback/course-bg-1.png"
import ItuLogo from "assets/logos/itu-logo-stacked.svg"
import clsx from "clsx"
import PageNavigation from "components/PageNavigation"
import { getRestrictionIcon } from "features/AccessManagement/CheckRestrictions"
import { LearningItem } from "features/LearningItems/Nav"
import { context } from "global/Context/context"
import { Message } from "iconoir-react"
import { useContext } from "react"
import { Link, NavLink, useLocation, useParams } from "react-router-dom"
import { learningItemSlugs } from "utils/constants"

import ImageBackgroundCard from "../ImageBackgroundCard"
import styles from "./Header.module.css"
type Props = {
  className?: string
  // To show Discussions button, defaults to false
  showDiscussionsButton?: boolean
  data: LearningItem[]
  itemSlug: string
  image: string | null
  itemName: string
}

const LearningItemHeader = ({
  image,
  className,
  showDiscussionsButton = false,
  data,
  itemSlug,
  itemName,
}: Props) => {
  const params = useParams()
  const { state } = useLocation()
  const slug: string =
    state?.parentSlug || params.parentSlug || params.slug || state?.slug

  const { learningRestrictions } = useContext(context)

  const restrictionType = learningRestrictions?.find(
    i => i.learningItem.slug === slug
  )?.restrictionType

  const mdUp = useMediaQuery(theme.breakpoints.up("md"))
  const smUp = useMediaQuery(theme.breakpoints.up("sm"))

  const titleLogos: Record<string, string> = {
    [learningItemSlugs.med]: ItuLogo,
  }

  const logo = titleLogos[slug]
  return (
    <ImageBackgroundCard
      disabled={!!restrictionType}
      background={image ?? Fallback}
      className={className}
    >
      <Container>
        <div className="pb-5 pt-2 text-common-white-500">
          <PageNavigation color="light" />
          <div className="flex items-center justify-between">
            <div className="flex flex-wrap items-center gap-1.5">
              <Typography variant="title2">{itemName}</Typography>
              {logo && (
                <>
                  <div className="h-3.5 min-h-full border-l border-white"></div>
                  <img className="w-12" src={logo} alt="" />
                </>
              )}
            </div>
            {showDiscussionsButton && !restrictionType && (
              <Button
                component={Link}
                to={`/discussions/?course=${itemSlug}`}
                startAdornment={<Message />}
                variant="text"
                color="white"
              >
                Discussions
              </Button>
            )}
            {restrictionType && (
              <div className="flex size-5 items-center justify-center rounded-lg bg-white/10">
                {getRestrictionIcon(restrictionType)}
              </div>
            )}
          </div>
        </div>
      </Container>
      <Container fullWidth={!smUp}>
        {!mdUp && (
          <div className={clsx(styles.customNav)}>
            {data.map((item, index) => (
              <NavLink
                key={index}
                to={item.link}
                className={({ isActive }) =>
                  clsx(
                    "relative me-3 w-fit shrink-0 pb-1.25 text-white sm:me-5",
                    {
                      "ms-2": !smUp && index === 0,
                      [styles.customBorder]: isActive,
                    }
                  )
                }
              >
                <Typography variant="subtitle2">{item.name}</Typography>
              </NavLink>
            ))}
          </div>
        )}
      </Container>
    </ImageBackgroundCard>
  )
}

export default LearningItemHeader
