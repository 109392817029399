import axios from "api/axios"
import { APIResponse, Config } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Conversation, ConversationMessage } from "./types"
import { urls } from "./urls"

export default {
  chatbot: {
    createConversation: async ({
      data,
    }: Config): Promise<APIResponse<Conversation>> => {
      try {
        const res = await axios.post(urls.chatbot.conversation(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    createMessage: async ({
      data,
      params,
    }: Config): Promise<APIResponse<ConversationMessage>> => {
      try {
        const res = await axios.post(
          urls.chatbot.conversationMessage(params.id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
