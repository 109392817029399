import { Typography } from "@suraasa/placebo-ui"
import { Button, theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import React from "react"

const UploadResumeBanner = ({
  toggleAddResume,
}: {
  toggleAddResume: () => void
}) => {
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <div className="flex flex-col justify-between gap-2 border border-solid border-interactive-100 bg-interactive-50 p-2 sm:rounded-lg md:flex-row md:items-center">
      <div>
        <Typography variant="title4" className="mb-0.5 text-onSurface-800">
          Upload Resume
        </Typography>
        <Typography
          className="text-onSurface-800 md:max-w-[370px]"
          variant="smallBody"
        >
          Boost your chances and stand out to potential opportunities by
          uploading your resume!
        </Typography>
      </div>
      <Button
        fullWidth={isSmDown}
        rounded
        variant="outlined"
        onClick={toggleAddResume}
        className="!bg-white"
      >
        Upload Resume
      </Button>
    </div>
  )
}

export default UploadResumeBanner
