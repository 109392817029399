import {
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  Typography,
} from "@suraasa/placebo-ui"
import { IconButton } from "@suraasa/placebo-ui-legacy"
import { Xmark } from "iconoir-react"
import { PropsWithChildren } from "react"

const TopBar = ({ children }: PropsWithChildren) => {
  return (
    <SheetHeader hideClose className="flex items-center justify-between">
      <div className="flex items-center gap-1">
        <SheetTrigger>
          <IconButton color="black">
            <Xmark />
          </IconButton>
        </SheetTrigger>
        <SheetTitle>
          <Typography variant="title3">Video Portfolio</Typography>
        </SheetTitle>
      </div>
      {children}
    </SheetHeader>
  )
}

export default TopBar
