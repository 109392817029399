import { IconButton, Menu, MenuItem } from "@suraasa/placebo-ui-legacy"
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Discussion } from "api/resources/discussions/types"
import { PaginatedAPIResponse } from "api/types"
import { APIError } from "api/utils"
import { MoreVert } from "iconoir-react"
import React, { useState } from "react"
import { handleErrors } from "utils/helpers"

import ReportDialog from "../../ReportDialog"

type Props = {
  isSelf: boolean
  commentId: string
  replyId?: string
  discussionId: string
  firstName: string
}

const CommentMenu = ({
  isSelf,
  commentId,
  discussionId,
  replyId,
  firstName,
}: Props) => {
  const queryClient = useQueryClient()
  const [openReportDialog, setOpenReportDialog] = useState(false)

  const deleteAPI = useMutation({
    mutationFn: () =>
      replyId
        ? api.discussions.deleteReply({
            params: { id: replyId },
          })
        : api.discussions.deleteComment({
            params: { id: commentId },
          }),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queries.discussions.commentList(discussionId).queryKey,
      })

      queryClient.invalidateQueries({
        queryKey: queries.discussions.post(discussionId).queryKey,
      })

      // TODO: Also need to update discussionList here

      queryClient.setQueryData(
        queries.discussions.list().queryKey,
        (oldQueryData?: InfiniteData<PaginatedAPIResponse<Discussion[]>>) => {
          if (oldQueryData) {
            const oldDataCopy: InfiniteData<
              PaginatedAPIResponse<Discussion[]>
            > = JSON.parse(JSON.stringify(oldQueryData))

            const pageIndex = oldDataCopy.pages.findIndex(item =>
              item.data.map(discussion => discussion.id === discussionId)
            )

            const discussionDataIndex = oldDataCopy.pages[
              pageIndex
            ].data.findIndex(item => item.id === discussionId)

            oldDataCopy.pages[pageIndex].data[discussionDataIndex]
              .totalComments--

            return oldDataCopy
          } else {
            return oldQueryData
          }
        }
      )

      queryClient.setQueryData<Discussion>(
        queries.discussions.post(discussionId).queryKey,
        oldQueryData => {
          if (!oldQueryData) return

          return {
            ...oldQueryData,
            totalComments: oldQueryData.totalComments - 1,
          }
        }
      )
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  return (
    <>
      <div>
        <Menu
          menuButton={
            <IconButton>
              <MoreVert />
            </IconButton>
          }
        >
          {isSelf ? (
            <MenuItem
              disabled={deleteAPI.isLoading}
              onClick={() => deleteAPI.mutate()}
              color="critical"
            >
              {deleteAPI.isLoading ? "Deleting..." : "Delete"}
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => setOpenReportDialog(true)}
              color="critical"
            >
              Report
            </MenuItem>
          )}
        </Menu>
      </div>

      <ReportDialog
        open={openReportDialog}
        handleClose={() => setOpenReportDialog(false)}
        id={replyId || commentId}
        type={replyId ? "reply" : "comment"}
        firstName={firstName}
      />
    </>
  )
}
export default CommentMenu
