import {
  Certification,
  LanguageProficiency,
  Qualification,
  WorkExperienceType,
} from "../profile/types"

export enum ApplicationStatus {
  NOT_STARTED = 1,
  IN_PROGRESS,
  SUBMITTED,
  UNDER_REVIEW,
  ACCEPTED,
  REJECTED,
}

export enum ReviewStatus {
  NOT_STARTED = 1,
  REVIEW_STARTED,
  CHANGES_REQUESTED,
  CHANGES_STARTED,
  CHANGES_DONE,
  ACCEPTED,
  REJECTED,
  INTERVIEW_REQUESTED,
}

export enum InterviewStatus {
  SCHEDULED = 1,
  NO_SHOW,
  CANCELLED,
  ACCEPTED,
  REJECTED,
}

export type ApplicationFormOverview = {
  id: number
  productId: number
  productSlug: string
  centreId: number
  questionnaireSlug: string
  evidenceUploaded: boolean
  status: ApplicationStatus
  review: {
    id: number
    status: ReviewStatus
  } | null
  interview: {
    id: number
    status: InterviewStatus
    meetingUrl: string // Interview joining url
    scheduledDate: string | null
  } | null
}

export type Profiles = {
  languageProficiencies: LanguageProficiency[]
  qualifications: (Certification & {
    instituteName: string
    certificates: Evidence[]
  })[]
  certifications: (Qualification & {
    instituteName: string
    certificates: Evidence[]
  })[]
  workExperiences: WorkExperienceType[]
}

export enum AttachmentType {
  TRANSCRIPT = 1, // Also called MARKSHEET
  DEGREE,
}

export type Evidence = {
  id: number | undefined
  attachment: File | null
  attachmentName?: string | null
  attachmentType?: AttachmentType | null
  url?: string | null
}

export type QuestionnaireProduct = {
  id: number
  productId: number
  productSlug: string
  centreId: number
  questionnaire: number
}
