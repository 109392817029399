export const getFileName = ({
  fullName,
  schoolName,
}: {
  fullName?: string | null
  schoolName?: string | null
}) => {
  if (fullName && schoolName) {
    return `${fullName} [${schoolName}]`
  }
  if (fullName) {
    return `${fullName}`
  }
  if (schoolName) {
    return `${schoolName}`
  }
  return ""
}
