import { toast, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Divider,
  Select,
  TextField,
  Toggle,
} from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  DifficultyLevel,
  QuizProForm,
  RetrievePrompt,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors, pluralize } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../context"

const LEVEL_OF_DIFFICULTY: { value: DifficultyLevel; label: string }[] = [
  { value: "easy", label: "Beginner" },
  { value: "medium", label: "Intermediate" },
  { value: "difficult", label: "Advanced" },
  { value: "expert", label: "Expert" },
]
const safeParseInt = (value?: string | number) => {
  if (typeof value === "number") return value
  return parseInt(value || "0")
}

const toolType = ToolType.quizPro

type Form = QuizProForm

type Props = {
  onGeneration: (data: APIResponse<RetrievePrompt<typeof toolType>>) => void
}

const ProForm = (props: Props) => {
  const [enableComprehension, setEnableComprehension] = useState(false)
  const {
    register,
    control,
    handleSubmit,
    setError,
    watch,
    setValue,

    formState: { errors },
  } = useForm<Form>({
    // defaultValues: {
    //   grade: "10",
    //   curriculum: "CBSE",
    //   difficultyLevel: "easy",
    //   topic: "Magnets",
    //   country: "India",
    //   subject: "Physics",
    //   numberOfIndividualQuestions: 5,
    // },
  })

  const numberOfComprehensions = safeParseInt(watch("numberOfComprehensions"))
  const numberOfComprehensionQuestions = safeParseInt(
    watch("numberOfComprehensionQuestions")
  )
  const numberOfIndividualQuestions = safeParseInt(
    watch("numberOfIndividualQuestions")
  )

  const totalComprehensionPassageQuestions =
    numberOfComprehensions * numberOfComprehensionQuestions

  const totalQuestions =
    numberOfIndividualQuestions + totalComprehensionPassageQuestions

  const { setPromptDataId } = useContext(AIToolsContext)

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: QuizProForm) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
        model: "pro",
      })

      return api.aiTools.generateQuizPro({
        data,
      })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
        model: "pro",
      })
      props.onGeneration(res)
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        model: "pro",
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const onSubmit = handleSubmit(async data => {
    let hasError = false
    if (enableComprehension) {
      if (!data.numberOfComprehensions) {
        setError("numberOfComprehensions", { message: "Required" })
        hasError = true
      }
      if (!data.numberOfComprehensionQuestions) {
        setError("numberOfComprehensionQuestions", { message: "Required" })
        hasError = true
      }
    }

    if (hasError) return

    if (!data.numberOfComprehensions) {
      delete data.numberOfComprehensions
    } else {
      data.numberOfComprehensions = safeParseInt(data.numberOfComprehensions)
    }

    if (!data.numberOfComprehensionQuestions) {
      delete data.numberOfComprehensionQuestions
    } else {
      data.numberOfComprehensionQuestions = safeParseInt(
        data.numberOfComprehensionQuestions
      )
    }

    data.numberOfIndividualQuestions = safeParseInt(
      data.numberOfIndividualQuestions
    )

    mutate(data)
  })

  useEffect(() => {
    setValue("numberOfComprehensions", undefined)
    setValue("numberOfComprehensionQuestions", undefined)
  }, [enableComprehension])

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex flex-col gap-2">
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <TextField
            {...register("grade", {
              required: { value: true, message: "Required" },
            })}
            inputLabelProps={{ required: true }}
            error={Boolean(errors.grade)}
            label="Grade"
            fullWidth
            placeholder="Ex: 11th Grade"
            helperText={errors.grade?.message}
          />
          <TextField
            {...register("curriculum", {
              required: { value: true, message: "Required" },
            })}
            inputLabelProps={{ required: true }}
            error={Boolean(errors.curriculum)}
            label="Curriculum"
            fullWidth
            placeholder="Ex: UAE National Curriculum"
            helperText={errors.curriculum?.message}
          />

          <Controller
            control={control}
            name="difficultyLevel"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                inputLabelProps={{ required: true }}
                label="Level of Difficulty"
                error={Boolean(errors.difficultyLevel)}
                helperText={errors.difficultyLevel?.message}
                options={LEVEL_OF_DIFFICULTY}
                placeholder="Ex: Expert"
                value={LEVEL_OF_DIFFICULTY.find(c => c.value === value)}
                fullWidth
                mountOnBody
                onBlur={onBlur}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{ required: { value: true, message: "Required" } }}
          />
          <TextField
            {...register("country", {
              required: { value: true, message: "Required" },
            })}
            inputLabelProps={{ required: true }}
            error={Boolean(errors.country)}
            label="Country"
            fullWidth
            placeholder="Ex: United Arab Emirates"
            helperText={errors.country?.message}
          />
        </div>
        <TextField
          {...register("subject", {
            required: { value: true, message: "Required" },
          })}
          inputLabelProps={{ required: true }}
          error={Boolean(errors.subject)}
          label="Subject"
          fullWidth
          placeholder="Ex: Physics"
          helperText={errors.subject?.message}
        />
        {/* @ts-expect-error: placebo rows prop error */}
        <TextField
          multiLine
          rows={3}
          inputLabelProps={{ required: true }}
          {...register("topic", {
            required: { value: true, message: "Required" },
          })}
          error={Boolean(errors.topic)}
          label="Topic"
          fullWidth
          placeholder="Ex: Circular Motion"
          helperText={errors.topic?.message}
        />
        <Divider />

        <div className="mb-1 mt-0.5">
          <div className="flex items-center gap-3">
            <Typography variant="strong">Comprehension Questions</Typography>
            <Toggle
              checked={enableComprehension}
              onChange={() => {
                setEnableComprehension(x => !x)
              }}
            />
          </div>
          <Typography className="mt-0.5 !text-onSurface-500">
            Add comprehension passages to your quiz.
          </Typography>
        </div>

        <TextField
          {...register("numberOfIndividualQuestions", {
            required: {
              value: true,
              message: "Required",
            },
          })}
          inputLabelProps={{ required: true }}
          error={Boolean(errors.numberOfIndividualQuestions)}
          label="Number of Individual Questions"
          inputMode="numeric"
          pattern="[0-9]*"
          fullWidth
          type="number"
          placeholder="Ex: 10"
          helperText={errors.numberOfIndividualQuestions?.message}
        />

        {enableComprehension && (
          <>
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
              <TextField
                {...register("numberOfComprehensions")}
                inputLabelProps={{ required: true }}
                error={Boolean(errors.numberOfComprehensions)}
                label="No. of Comprehension Passages"
                fullWidth
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="Ex: 2"
                helperText={errors.numberOfComprehensions?.message}
              />
              <TextField
                {...register("numberOfComprehensionQuestions")}
                inputLabelProps={{ required: true }}
                error={Boolean(errors.numberOfComprehensionQuestions)}
                label="Number of Questions (in each passage)"
                fullWidth
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="Ex: 4"
                helperText={errors.numberOfComprehensionQuestions?.message}
              />
            </div>
            {Boolean(
              numberOfIndividualQuestions &&
                numberOfComprehensions &&
                numberOfComprehensionQuestions
            ) && (
              <div className="rounded-lg border-2 border-highlight-100 bg-highlight-50 p-2">
                <Typography
                  variant="smallBody"
                  className="mb-0.25 !text-onSurface-500"
                >
                  Quiz Structure
                </Typography>
                <Typography variant="strong" className="!text-onSurface-700">
                  {`${pluralize("Total Question", totalQuestions)} (${pluralize(
                    "individual question",
                    numberOfIndividualQuestions
                  )}, ${pluralize(
                    "passage",
                    safeParseInt(numberOfComprehensions)
                  )} with ${pluralize(
                    "question",
                    safeParseInt(numberOfComprehensionQuestions)
                  )} each)`}
                </Typography>
              </div>
            )}
          </>
        )}
        <Button fullWidth type="submit" loading={isLoading}>
          Generate {getToolName(toolType)}
        </Button>
      </form>

      <AiToolsLoading
        type={toolType}
        open={isLoading}

        // onClose={() => {
        //   setLoading(false)
        // }}
      />
    </Paper>
  )
}

export default ProForm
