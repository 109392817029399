import { Sheet, SheetBody, SheetContent, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  CircularProgress,
  Select,
  SplitTextField,
  TextField,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { OTPResponse, PhoneNumber } from "api/resources/settings/types"
import { APIError } from "api/utils"
import SuraasaAppIcon from "assets/logos/suraasa-app-icon.png"
import VerifyPhoneNumberDialog from "features/Settings/ContactDetails/VerifyPhoneNumberDialog"
import { context } from "global/Context/context"
import { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import routes from "routes"
import countryCodes from "utils/countryCodes"
import { handleErrors, sleep } from "utils/helpers"
import { useGRecaptcha } from "utils/hooks/useGRecaptcha"

import { ryanGroupIdentifier, ryanSchoolsList } from "./utils"

const schoolsList = ryanSchoolsList.map(({ name, board }) => ({
  label: name,
  value: name,
  board,
}))

const countryCodeOptions = countryCodes.map(({ dialCode }) => ({
  value: dialCode,
  label: dialCode,
}))

type Form = {
  schoolName: string
  ryanMcbCode: string
  phoneNumber: {
    code: string
    number: string
  }
}
const RyanSignUpOnboarding = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const isRyanTeacher =
    searchParams.get("signup_source") === ryanGroupIdentifier

  const [open, setOpen] = useState(false)

  const { isAuthenticated } = useContext(context)

  const [showPhoneNumberField, setShowPhoneNumberField] = useState(false)

  const [showSuccessInformation, setShowSuccessInformation] = useState(false)

  useEffect(() => {
    if (!isAuthenticated || !isRyanTeacher) return
    ;(async () => {
      try {
        const res = await api.users.getUserAttributes()
        const isCompleted =
          res.hasPhoneNumber && res.ryanMcbCode && res.schoolName

        if (!isCompleted) {
          setOpen(true)

          setShowPhoneNumberField(!res.hasPhoneNumber)
        }
      } catch (e) {
        console.error(e)
      }
    })()
  }, [isRyanTeacher, isAuthenticated])

  const {
    register,
    handleSubmit,
    setError,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      phoneNumber: {
        code: "+91",
        number: "",
      },
    },
  })

  const updateUserPhoneNumber = async ({
    token,
    phoneNumber,
  }: {
    token: string
    phoneNumber: PhoneNumber | null
  }) => {
    if (!phoneNumber) return
    try {
      await api.users.updateUser({
        data: {
          phoneNumber: `${phoneNumber.code}${phoneNumber.number}`,
          smsOtpToken: token,
        },
      })
      setShowPhoneNumberField(false)
      saveUserAttributes()
    } catch (e) {
      if (e instanceof APIError) {
        handleErrors(e, { setter: setError })
        setLoading(false)
      }
    }
  }

  const saveUserAttributes = async () => {
    setLoading(true)
    const data = {
      ryanMcbCode: getValues("ryanMcbCode"),
      schoolName: getValues("schoolName"),
    }

    try {
      await api.users.updateUserAttributes({
        data: data,
      })

      await sleep(2000)

      searchParams.delete("signup_source")
      searchParams.delete("utm_source")
      setSearchParams(searchParams, { replace: true })

      setShowSuccessInformation(true)
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err, { setter: setError })
      }
    }
    setLoading(false)
  }

  const [otpData, setOtpData] = useState<OTPResponse | null>(null)

  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState<{
    phoneNumber: PhoneNumber | null
    verify: boolean
  }>({
    phoneNumber: null,
    verify: false,
  })

  const { submitWithCaptcha } = useGRecaptcha({
    action: "auth",
    checkboxContainer: "#ryan-signup-captcha",
  })

  const onSubmit = handleSubmit(async data => {
    if (!showPhoneNumberField) {
      saveUserAttributes()
      return
    }

    await submitWithCaptcha(async captcha => {
      if (!data.phoneNumber.code || !data.phoneNumber.number) return

      if (!captcha) {
        toast.error("Please verify the captcha")
        return
      }

      clearErrors()
      setLoading(true)

      try {
        const res = await api.settings.addPhoneNumber({
          data: {
            captcha,
            phoneNumber: `${data.phoneNumber.code}${data.phoneNumber.number}`,
          },
        })
        setOtpData(res)
        setVerifyPhoneNumber({
          phoneNumber: {
            number: data.phoneNumber.number,
            code: data.phoneNumber.code,
          },
          verify: true,
        })
      } catch (err) {
        if (err instanceof APIError) {
          if (
            err.errors?.fieldErrors?.resendAt &&
            err.errors?.fieldErrors?.token
          ) {
            setOtpData({
              resendAt: err.errors.fieldErrors.resendAt as string,
              token: err.errors.fieldErrors.token as string,
            })

            setVerifyPhoneNumber({
              phoneNumber: {
                number: data.phoneNumber.number,
                code: data.phoneNumber.code,
              },
              verify: true,
            })
          } else if (err.errors.message) {
            toast.error(err.errors.message)
          } else {
            handleErrors(err, { setter: setError })
          }
        }
      }
      setLoading(false)
    })()
  })

  return (
    <div>
      <Sheet open={open}>
        <SheetContent
          side="bottom"
          height={90}
          className="max-w-[1000px] p-3 md:p-6"
        >
          {!showSuccessInformation && (
            <SheetBody className="flex grow flex-col">
              <div className="flex items-center gap-2">
                <Typography variant="largeTitle">Welcome to Suraasa</Typography>
                <img
                  src={SuraasaAppIcon}
                  alt="suraasa"
                  className="max-w-[60px] lg:max-w-10"
                />
              </div>
              <Typography
                variant="title2"
                className="mt-1 !font-medium text-onSurface-500 md:mt-0"
              >
                One last step before you start using Suraasa.
              </Typography>

              <form onSubmit={onSubmit} className="mt-5 flex grow flex-col">
                <Typography variant="title2" className="mb-2">
                  In which branch do you work?
                </Typography>

                <Controller
                  control={control}
                  name="schoolName"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      label="Please select your school branch of Ryan Group of Schools"
                      ref={ref}
                      className="mb-4"
                      fullWidth
                      error={Boolean(errors.schoolName)}
                      getOptionLabel={item => item.label}
                      getOptionValue={item => item.value.toString()}
                      helperText={
                        errors.schoolName?.message ||
                        "You can also search your branch name by typing"
                      }
                      inputLabelProps={{ required: true }}
                      options={schoolsList}
                      placeholder="Select Branch"
                      value={
                        value
                          ? schoolsList.find(item => item.value === value)
                          : null
                      }
                      onBlur={onBlur}
                      onChange={val => {
                        onChange(val?.value)
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />

                <Typography variant="title2" className="mb-2">
                  What&apos;s your MCB Staff Code?
                </Typography>

                <TextField
                  label="MCB Staff Code"
                  error={Boolean(errors.ryanMcbCode)}
                  helperText={errors.ryanMcbCode?.message}
                  {...register("ryanMcbCode", {
                    required: { value: true, message: "Required" },
                    maxLength: {
                      value: 12,
                      message: "Length cannot be greater than 12",
                    },
                    minLength: {
                      value: 7,
                      message: "Length cannot be less than 7",
                    },
                  })}
                  placeholder="Type here..."
                  type="ryanMcbCode"
                  fullWidth
                  className="mb-4"
                />

                {showPhoneNumberField && (
                  <div>
                    <Typography variant="title2" className="mb-2">
                      What&apos;s your Phone Number?
                    </Typography>

                    <Controller
                      control={control}
                      name="phoneNumber"
                      render={({ field }) => (
                        <SplitTextField
                          error={Boolean(errors.phoneNumber)}
                          helperText={errors.phoneNumber?.message}
                          selectProps={{
                            placeholder: "+91",
                            options: countryCodeOptions,
                            value: countryCodeOptions.find(
                              c => c.value === field.value.code
                            ),
                            onChange: val => {
                              clearErrors()
                              if (val) setValue("phoneNumber.code", val.value)
                            },
                          }}
                          textFieldProps={{
                            type: "number",
                            placeholder: "Enter phone number",
                            value: field.value.number ?? undefined,
                            onChange: val => {
                              clearErrors()
                              setValue("phoneNumber.number", val.target.value)
                            },
                          }}
                          fullWidth
                        />
                      )}
                      rules={{
                        validate: v => {
                          if (!v) return "Required"
                          if (!v.code) return "Required"
                          if (!v.number) return "Required"
                        },
                      }}
                    />
                  </div>
                )}

                <div className="grow"></div>

                <Button loading={loading} rounded fullWidth type="submit">
                  Submit
                </Button>
              </form>
            </SheetBody>
          )}

          {showSuccessInformation && (
            <SheetBody className="flex grow flex-col">
              <div className="flex items-center gap-2">
                <Typography variant="largeTitle">Welcome to Suraasa</Typography>
                <img
                  src={SuraasaAppIcon}
                  alt="suraasa"
                  className="max-w-[60px] lg:max-w-10"
                />
              </div>

              <div className="flex grow flex-col items-center justify-center">
                <Typography
                  variant="title2"
                  className="mb-1 !font-medium text-onSurface-500"
                >
                  We&apos;re allocating the course to you. This might take a few
                  minutes.
                </Typography>
                <CircularProgress />
                {/* <Typography
                  variant="title3"
                  className="mt-1 !font-medium text-onSurface-500"
                  
                >
                  Feel free to explore the Suraasa platform.
                </Typography> */}
              </div>

              <Button
                rounded
                fullWidth
                onClick={() => {
                  navigate(routes.learning)
                  setOpen(false)
                }}
              >
                Continue
              </Button>
            </SheetBody>
          )}
        </SheetContent>
      </Sheet>

      {otpData && (
        <VerifyPhoneNumberDialog
          onBack={() => {
            // to allow the user to at least click the save button after returning from VerifyDialog
            clearErrors()
            setVerifyPhoneNumber({
              ...verifyPhoneNumber,
              phoneNumber: null,
              verify: false,
            })
          }}
          close={() => {
            // to allow the user to at least click the save button after returning from VerifyDialog
            clearErrors()
            setVerifyPhoneNumber({
              ...verifyPhoneNumber,
              phoneNumber: null,
              verify: false,
            })
            close()
          }}
          otpData={otpData}
          phoneNumber={verifyPhoneNumber.phoneNumber}
          open={verifyPhoneNumber.verify}
          onVerify={(token: string) => {
            updateUserPhoneNumber({
              token,
              phoneNumber: verifyPhoneNumber.phoneNumber,
            })
            setVerifyPhoneNumber({ phoneNumber: null, verify: false })
          }}
        />
      )}
    </div>
  )
}

export default RyanSignUpOnboarding
