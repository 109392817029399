export default {
  signIn: {
    title: "Sign in",
  },
  emailVerify: {
    title: "Verify Email",
  },
  assignments: {
    title: "Skill Evidences",
  },
  assessments: {
    title: "Assessments",
  },
  attemptAssessment: {
    title: "Attempt Assessment",
  },
  assessmentResult: {
    title: "Assessment Result",
  },
  orders: {
    title: "Order History",
  },
  payment: {
    title: "Payment",
  },
  settings: {
    title: "Settings",
  },
  orderDetails: {
    title: "Order Details",
  },
  home: {
    title: "Dashboard",
  },
  learning: {
    title: "My Learning",
  },
  viewCourse: {
    title: "Course",
  },
  viewAssignment: {
    title: "Skill Evidence",
  },
  course: {
    title: "Course",
  },
  qualification: {
    title: "Qualification",
  },
  certification: {
    title: "Certification",
  },
  learningModule: {
    title: "Course",
  },
  profile: {
    title: "Profile",
  },
  discussions: {
    title: "Discussions",
  },
  viewDiscussion: {
    title: "Discussion",
  },
  notifications: {
    title: "Notifications",
  },
  schedule: {
    title: "My Schedule",
  },
  certificates: {
    title: "My Certificates",
  },
  teacherImpactAwards: {
    title: "Teacher Impact Awards",
  },
  ito: {
    title: "ITO Dashboard",
  },
  tia: {
    title: "Teacher Impact Awards 2024",
  },
  submission: {
    title: "Submission | Skill Evidence",
  },
  pageNotFound: {
    title: "Page not found",
  },
  scheduleInterview: {
    title: "Schedule Interview",
  },
  components: {
    title: "Components",
  },
  instructions: {
    title: "Test Instructions",
  },
  itoInstructions: {
    title: "Olympiad Instructions",
  },
  itoAssessment: {
    title: "Attempt Olympiad",
  },
  proctoringDemo: {
    title: "Assessment Proctoring Demo",
  },
  aiTools: {
    title: "AI Tools",
  },
  aiLessonPlanGenerator: {
    title: "Lesson Plan Generator | AI Tools",
  },
  aiRubricsGenerator: {
    title: "Rubrics Generator | AI Tools",
  },
  aiQuizGenerator: {
    title: "Quiz Generator | AI Tools",
  },
  aiHandoutGenerator: {
    title: "Handout Generator | AI Tools",
  },
  aiNarrationGenerator: {
    title: "Narration Generator | AI Tools",
  },
  aiSubjectiveAssessmentGenerator: {
    title: "Subjective Assessment Generator | AI Tools",
  },
  aiAssignmentGenerator: {
    title: "Assignment Generator | AI Tools",
  },
  aiActivityPlannerGenerator: {
    title: "Activity Planner | AI Tools",
  },
  aiVideoSummaryGenerator: {
    title: "Video Summary Generator | AI Tools",
  },
  aiUnitPlanGenerator: {
    title: "Unit Planner | AI Tools",
  },
  applicationFormUploadDocuments: {
    title: "Upload Documents",
  },
  learnerHandbook: {
    title: "Learner Handbook | Qualifications",
  },
}
