import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  IconButton,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import Stars from "assets/AITools/stars-gradient.json"
import clsx from "clsx"
import styles from "features/Onboarding/Welcome/Welcome.module.css"
import { ArrowRight, Xmark } from "iconoir-react"
import { useTheme } from "react-jss"
import Lottie from "react-lottie"

const lottieStyle = {
  default: {
    width: "120px",
    height: "120px",
    marginBottom: "16px",
    borderRadius: "14px",
  },
  lgUp: {
    width: "220px",
    height: "220px",
    marginBottom: "16px",
    borderRadius: "14px",
  },
}

const Introduction = ({
  onClick,
  onClose,
}: {
  onClick: () => void
  onClose: () => void
}) => {
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const islgUp = useMediaQuery(theme.breakpoints.up("lg"))

  return (
    <div>
      <div className="absolute left-2 top-2">
        <div className="rounded-[4px] bg-black px-1.25 py-0.5 text-white">
          <Typography variant="preTitle">NEW</Typography>
        </div>
      </div>
      <div className="absolute right-2 top-2">
        <IconButton onClick={onClose} color="black">
          <Xmark />
        </IconButton>
      </div>
      <div className="bg-[#F5F5F5] p-3">
        {/* TODO: Disable tabbing. tabIndex={-1} */}
        <Lottie
          speed={1.75}
          isClickToPauseDisabled
          style={{
            userSelect: "none",
            cursor: "default",
            ...lottieStyle.default,
            ...(islgUp ? lottieStyle.lgUp : {}),
          }}
          options={{
            loop: true,
            autoplay: true,
            animationData: Stars,
          }}
        />
      </div>
      <div className="mb-4 p-3 pb-10 shadow-[0_-10px_20px_-15px_rgba(0,0,0,0.3)]">
        <Typography className="text-onSurface-700" variant="preTitle">
          Introducing
        </Typography>

        <Typography variant="title1">Suraasa AI Tools</Typography>

        <Typography
          variant="subtitle1"
          className="mb-3 leading-tight text-onSurface-700"
        >
          Suraasa is thrilled to unveil cutting-edge AI tools designed to
          revolutionise your teaching journey.
        </Typography>

        <Typography className="text-onSurface-700" variant="largeBody">
          These innovative features are tailored to enhance your teaching
          experience, saving your valuable time while ensuring enriched
          classroom engagement. Embrace the future of education with
          Suraasa&apos;s intuitive, user-friendly tools. Explore these exciting
          features today and elevate your teaching to new heights!
        </Typography>
      </div>

      <div
        className={clsx(
          styles.viewButton,
          "absolute bottom-2 flex w-full justify-center"
        )}
      >
        <Button
          onClick={onClick}
          color="black"
          endAdornment={<ArrowRight />}
          rounded
          className="mx-auto max-w-[90%] sm:mb-8 sm:!px-6 sm:!py-2"
          fullWidth={isXsDown}
        >
          Experience the AI Magic
        </Button>
      </div>
    </div>
  )
}

export default Introduction
