import axios from "api/axios"
import { APIResponse, Config } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"
import { TIA_EVENT_SLUG } from "features/TIA/shared/constants"

import { TIAEligibility, TIASubmission } from "./types"
import { urls } from "./urls"

export default {
  getSubmission: async (): Promise<APIResponse<TIASubmission>> => {
    try {
      const res = await axios.get(urls.submission(TIA_EVENT_SLUG))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  createSubmission: async ({
    data,
    headers,
  }: Config): Promise<APIResponse<TIASubmission>> => {
    try {
      const res = await axios.post(
        urls.submission(TIA_EVENT_SLUG),
        data,
        headers
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  checkEligibility: async (): Promise<APIResponse<TIAEligibility>> => {
    try {
      const res = await axios.get(urls.checkEligibility(TIA_EVENT_SLUG))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
