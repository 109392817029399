import SuraasaLogo from "assets/suraasaLogo.jpg"

type Props = {
  data?: {
    error: Error
    componentStack: string
    eventId: string
    resetError(): void
  }
}
export default function ErrorPage(props?: Props) {
  if (props?.data) console.error(`> Error Captured: ${props.data.error}`)

  return (
    <>
      <div className="flex h-screen flex-col items-center justify-center p-2">
        <img
          src={SuraasaLogo}
          alt="logo"
          width={70}
          height={70}
          className="mb-2"
        />

        <p>Look like an unexpected error has occurred.</p>
        <p>
          Please try refreshing the page. If the issue persists, write to{" "}
          <a
            className="text-highlight-500 hover:underline"
            href="mailto:care@suraasa.com"
          >
            care@suraasa.com
          </a>
          .
        </p>
        {import.meta.env.MODE === "development" && props?.data && (
          <pre
            className="mt-4 p-2 text-critical-600"
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            {`${props.data.error}`}
          </pre>
        )}
      </div>
    </>
  )
}
