import { Typography } from "@suraasa/placebo-ui"
import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { LearningItem } from "api/resources/learningItems/types"
import StartDiscussionDialog from "features/Discussions/StartDiscussion"
import { useUpdateItemStatus } from "features/LearningModule/hooks/useUpdateItemStatus"
import { context } from "global/Context/context"
import React, { useContext, useState } from "react"
import { useTheme } from "react-jss"
import { useParams } from "react-router-dom"

import TitleCard from "../TitleCard"
import DiscussionSlidingSheet from "./DiscussionSlidingSheet"

type Props = {
  item: LearningItem
}

const Discussions = ({ item }: Props) => {
  const { updateItemStatus } = useUpdateItemStatus(item)

  if (item.learningContentType !== "discussion")
    throw new Error("Invalid content type supplied to Discussion component")

  const { slug } = useParams<{ slug: string }>()
  const { enrollments } = useContext(context)
  const courseId = enrollments?.find(
    enrollment => enrollment.slug === slug
  )?.uuid

  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const {
    instructions,
    discussionType,
    id,
    learningContentId,
    defaultHashtag,
  } = item

  const isPostDiscussion = discussionType === "post_discussion"

  const [openSheet, setOpenSheet] = useState(false)
  const [openStartDialogue, setOpenStartDialogue] = useState(false)

  const handleClose = () => {
    if (isPostDiscussion) {
      setOpenSheet(false)
    } else {
      setOpenStartDialogue(false)
    }
  }
  const handleOnClick = () => {
    updateItemStatus()
    if (isPostDiscussion) {
      setOpenSheet(true)
    } else {
      setOpenStartDialogue(true)
    }
  }

  return (
    <>
      <div>
        <TitleCard
          title={
            isPostDiscussion
              ? "Start a new discussion"
              : "Reply to a discussion"
          }
          action={
            <Button fullWidth={isXsDown} onClick={handleOnClick}>
              {isPostDiscussion
                ? "Start a discussion"
                : "Reply to a discussion"}
            </Button>
          }
        />

        <Typography variant="title4" className="my-2 text-onSurface-800">
          Instructions
        </Typography>
        <Typography className="mb-1 text-onSurface-800">
          {instructions}
        </Typography>
        <Typography className="text-interactive-500" variant="strong">
          {defaultHashtag}
        </Typography>
      </div>
      {isPostDiscussion && courseId && (
        <StartDiscussionDialog
          open={openSheet}
          handleClose={handleClose}
          params={{
            course: courseId,
            userPlannerItemId: id,
            discussion: learningContentId,
          }}
        />
      )}
      {!isPostDiscussion && courseId && (
        <DiscussionSlidingSheet
          open={openStartDialogue}
          handleClose={handleClose}
          params={{
            course: courseId,
            userPlannerItemId: id,
            discussion: learningContentId,
          }}
        />
      )}
    </>
  )
}

export default Discussions
