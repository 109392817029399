import { Typography } from "@suraasa/placebo-ui"
import { Button, ButtonComponentProps } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import React from "react"

const TitleBar = ({
  endSlot,
  variant = "text",
  className,
  ...props
}: { endSlot?: React.ReactNode } & ButtonComponentProps<any>) => {
  return (
    <div className={clsx("mb-2 px-2 pt-1.5", className)}>
      <Button className="-ml-1 mb-1.75" variant={variant} {...props} />
      <div className="flex flex-wrap items-center justify-between gap-0.5 border-b border-solid border-onSurface-200 pb-2">
        <Typography variant="title3">Cover Letter</Typography>
        {endSlot}
      </div>
    </div>
  )
}

export default TitleBar
