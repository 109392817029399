import { Typography } from "@suraasa/placebo-ui"
import { Divider, IconButton, Tag } from "@suraasa/placebo-ui-legacy"
import { Qualification, UserAcademicStatus } from "api/resources/profile/types"
import clsx from "clsx"
import { format } from "date-fns"
import twitterVerifiedBadge from "features/Profile/assets/twitter-verified-badge.png"
import { Edit } from "iconoir-react"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"

import ProfileContext from "../../../context"
import PreviewEvidenceList from "../EvidenceList"

const useStyles = createUseStyles(theme => ({
  details: {
    position: "relative",

    "& .edit-button": {
      position: "absolute",
      zIndex: "1",
      background: "white",

      [theme.breakpoints.down("sm")]: {
        right: "0px",
      },
      [theme.breakpoints.up("md")]: {
        pointerEvents: "none",
        right: "-30px",
        opacity: "0",
        transition: ".2s all",
      },
    },
    "&:hover .edit-button": {
      [theme.breakpoints.up("md")]: {
        opacity: "1",
        right: "0px",
        pointerEvents: "all",
      },
    },
  },
}))

const Qualifications = ({
  onEdit,
}: {
  onEdit: (id: Qualification["id"]) => void
}) => {
  const classes = useStyles()

  const {
    academics: {
      qualifications: { data },
    },
    isPublic,
  } = useContext(ProfileContext)

  return (
    <>
      {data.map((qualification, index) => (
        <div className="ml-2 pt-2" key={qualification.id}>
          <div className={clsx(classes.details, "pb-2")}>
            {!isPublic && !qualification.isVerified && (
              <IconButton
                className={clsx("edit-button")}
                color="black"
                onClick={() => onEdit(qualification.id)}
              >
                <Edit />
              </IconButton>
            )}

            <div className="mb-0.5 flex flex-wrap items-center gap-1">
              <Typography variant="strongSmallBody">
                {qualification.name}
              </Typography>
              {qualification.isVerified && (
                <img
                  alt="✅"
                  height="24px"
                  src={twitterVerifiedBadge}
                  width="24px"
                />
              )}
              {qualification.status === UserAcademicStatus.IN_PROGRESS && (
                <Tag color="onSurface" label="In Progress" />
              )}
            </div>

            {qualification.qualificationLevel && (
              <Typography className="mb-0.25" variant="smallBody">
                {qualification.qualificationLevel.name}
              </Typography>
            )}

            <Typography className="mb-0.25" variant="smallBody">
              {qualification.organisationName}
            </Typography>

            {qualification.startDate && (
              <Typography className="mb-0.5" variant="smallBody">
                {[
                  format(new Date(qualification.startDate), "MMMM yyyy"),
                  qualification.endDate
                    ? format(new Date(qualification.endDate), "MMMM yyyy")
                    : "Present",
                ].join(" - ")}
              </Typography>
            )}

            {qualification.evidences && qualification.evidences.length > 0 && (
              <PreviewEvidenceList data={qualification.evidences} />
            )}
          </div>
          {index < data.length - 1 && (
            <Divider color="onSurface.200" weight="light" />
          )}
        </div>
      ))}
    </>
  )
}

export default Qualifications
