import { Typography } from "@suraasa/placebo-ui"
import { CircularProgress, Divider } from "@suraasa/placebo-ui-legacy"
import {
  InfiniteData,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Discussion } from "api/resources/discussions/types"
import { PaginatedAPIResponse } from "api/types"
import { APIError } from "api/utils"
import GradientCircle1 from "assets/Discussion/gradient-circle-one.svg"
import GradientCircle2 from "assets/Discussion/gradient-circle-two.svg"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

import Comment from "../Comment"
import CommentField from "../CommentField"
import LikesAndComments from "../Post/LikesAndComments"
import PostDetails from "../Post/PostDetails"

const DiscussionItem = ({
  discussionId: id,
  additionalData,
  courseId,
}: {
  courseId?: string | undefined
  discussionId: string
  additionalData?: {
    userPlannerItemId: number
    discussion: number
    course: string
  }
}) => {
  const queryClient = useQueryClient()

  const { isLoading, data: post } = useQuery({
    queryKey: queries.discussions.post(id).queryKey,
    queryFn: () => api.discussions.retrieve({ params: { id: id } }),
    initialData: () => {
      // Pre-hydrate the page with data from discussion list
      const queryData = queryClient.getQueryData<
        InfiniteData<PaginatedAPIResponse<Discussion[]>>
      >(queries.discussions.list(courseId).queryKey)

      let data
      queryData?.pages.find(page =>
        page.data.find((discussion: Discussion) => {
          if (discussion.id === id) {
            data = discussion
            return true
          }
          return false
        })
      )

      return data
    },
  })

  const onAdd = () => {
    // queryClient.setQueryData(
    //   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    //   queries.discussions.post(id!).queryKey,
    //   (oldQueryData?: Discussion) =>
    //     oldQueryData
    //       ? {
    //           ...oldQueryData,
    //           totalComments: oldQueryData.totalComments + 1,
    //         }
    //       : oldQueryData
    // )
    queryClient.invalidateQueries({
      queryKey: queries.discussions.post(id).queryKey,
    })
  }

  const {
    isFetching: isFetchingComments,
    isLoading: isLoadingComments,
    hasNextPage: hasNextPageComments,
    fetchNextPage,
    status: commentListStatus,
    data: commentList,
  } = useInfiniteQuery({
    queryKey: queries.discussions.commentList(id).queryKey,
    queryFn: x => {
      return api.discussions.comments({
        params: {
          id,
          page: x.pageParam || 1,
          fields: "replies",
        },
      })
    },

    onError: err => {
      if (err instanceof APIError) {
        console.log(err.statusCode)
        console.log(err.is500)
        console.log(err.__errors)
      }
    },

    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
  })

  const { ref } = usePaginationScroll({
    loading: isLoadingComments,
    hasNextPage: hasNextPageComments,
    actionFunc: fetchNextPage,
  })

  if (isLoading || !post)
    return (
      <div className="pb-4">
        <div className="pt-4 text-center">
          <CircularProgress />
        </div>
      </div>
    )

  return (
    <div className="pb-10">
      <div className="mb-3 overflow-hidden rounded-none border border-onSurface-200 bg-white sm:rounded-2xl">
        <div className="p-2 pb-0">
          <PostDetails
            discussionId={post.id}
            user={post.user}
            text={post?.text}
            totalComments={post.totalComments || 0}
            datePublished={post.dateCreated}
            onReadMore={() => {}}
          />
        </div>
        <div className="bg-onSurface-100 py-1">
          <LikesAndComments
            discussionId={post.id}
            hasVoted={post.voted}
            noOfLikes={post?.upvotes}
            courseId={courseId}
            noOfComments={post.totalComments || 0}
            className="ms-2 sm:ms-10"
          />
        </div>
      </div>
      <div className="px-2 sm:px-0">
        <Typography variant="title3" className="mb-2">
          Replies{" "}
          {post.totalComments > 0 && <span>({post.totalComments})</span>}
        </Typography>

        <CommentField
          additionalData={additionalData}
          discussionId={id}
          multiLine
          onAdd={onAdd}
          fullWidth
          showProgressOnInside
          rows={4}
          name={post?.user.firstName}
          className="mb-3"
        />
        {commentListStatus === "loading" && (
          <div className="text-center">
            <CircularProgress />
          </div>
        )}

        {commentList &&
          commentList.pages.map(item =>
            item.data.map((comment, index) => (
              // TODO: Update this to only be on the last element
              <div key={index} ref={hasNextPageComments ? ref : null}>
                <Comment
                  discussionId={id}
                  comment={comment}
                  additionalData={additionalData}
                />
                {index < item.data.length - 1 && (
                  <Divider
                    weight="medium"
                    color="onSurface.200"
                    className="my-4 ms-[52px] sm:my-2 sm:ms-8"
                  />
                )}
              </div>
            ))
          )}

        {!post.totalComments && !isFetchingComments && (
          <>
            <div className="relative mx-auto mb-7 h-18 w-[284px] sm:w-[450px]">
              <div className="flex w-[240px] rounded-xl bg-white p-1.5 shadow-[0_5px_17px_0_rgba(0,0,0,0.15)] sm:w-[400px]">
                <div className="mr-1 shrink-0">
                  <img src={GradientCircle1} alt="gradient-circle-one" />
                </div>
                <div className="grow">
                  <div className="mb-0.5 h-1.5 w-14 rounded-sm bg-onSurface-100" />
                  <div className="mb-0.5 h-5 w-full rounded-2xl rounded-tl bg-onSurface-100 sm:h-8 " />
                </div>
              </div>
              <div className="absolute  bottom-1.5 right-0 flex w-[240px] rounded-xl bg-white p-1.5 shadow-[0_5px_17px_0_rgba(0,0,0,0.15)] sm:-bottom-3 sm:w-[400px]">
                <div className="mr-1 shrink-0">
                  <img src={GradientCircle2} alt="gradient-circle-two" />
                </div>
                <div className="grow">
                  <div className="mb-0.5 h-1.5 w-14 rounded-sm bg-onSurface-100" />
                  <div className="mb-0.5 h-5 w-full rounded-2xl rounded-tl bg-onSurface-100 sm:h-8 " />
                </div>
              </div>
            </div>
            <Typography className="text-center" variant="title2">
              No replies yet
            </Typography>
          </>
        )}
      </div>
    </div>
  )
}

export default DiscussionItem
