import { Typography } from "@suraasa/placebo-ui"
import { Button, Container, LinearProgress } from "@suraasa/placebo-ui-legacy"
import SlidingSheet from "components/SlidingSheet"
import { Check } from "iconoir-react"
import { useEffect, useState } from "react"

interface Props {
  open: boolean
  handleClose: () => void
  onSuccess: () => void
  progress: number
  milestoneNumber: number
}

const MilestoneCompletedSheet = ({
  open,
  handleClose,
  milestoneNumber,
  onSuccess,
  ...props
}: Props) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProgress(props.progress)
    }, 600)

    return () => {
      clearTimeout(timeout)
    }
  }, [props.progress])

  return (
    <SlidingSheet open={open} onClose={handleClose} className="!bg-success-50">
      <Container className="flex flex-col items-center px-2 py-3 sm:p-10 ">
        <div className="mb-5 flex w-full items-center justify-between">
          <div className="flex gap-1">
            <Check
              className="rounded-full bg-success-500 p-1 text-white"
              height={60}
              width={60}
              strokeWidth={3}
            />
            <div>
              <Typography variant="title2">Congratulations! 🎉</Typography>
              <Typography>
                You just completed milestone {milestoneNumber}
              </Typography>
            </div>
          </div>
          <Button
            variant="filled"
            className="!hidden sm:!inline-block"
            color="success"
            onClick={onSuccess}
          >
            Continue Learning
          </Button>
        </div>
        <div>
          <LinearProgress value={progress} color="success" size="lg" />
          <div className="mt-1 flex justify-between">
            <Typography variant="subtitle2">Course Progress</Typography>
            <Typography variant="subtitle2">{progress}%</Typography>
          </div>
        </div>
        <Button
          variant="filled"
          fullWidth
          className="mt-2 !inline-block sm:!hidden"
          color="success"
          onClick={onSuccess}
        >
          Continue Learning
        </Button>
      </Container>
    </SlidingSheet>
  )
}

export default MilestoneCompletedSheet
