import { Button } from "@suraasa/placebo-ui-legacy"
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Discussion } from "api/resources/discussions/types"
import { APIResponse, PaginatedAPIResponse } from "api/types"
import { APIError } from "api/utils"
import { ReactComponent as LikeSvg } from "assets/Discussion/like.svg"
import { ReactComponent as LikedSvg } from "assets/Discussion/liked.svg"
import clsx from "clsx"
import { Message } from "iconoir-react"
import React, { useState } from "react"
import { handleErrors, pluralize } from "utils/helpers"

import styles from "../Post.module.css"

type Props = {
  noOfLikes?: number
  noOfComments?: number
  className?: string
  hasVoted: boolean
  discussionId: string
  handleCommentsClick?: () => void
  courseId?: string
}

const LikesAndComments = ({
  discussionId,
  className,
  handleCommentsClick,
  courseId,
  ...props
}: Props) => {
  const [hasVoted, setHasVoted] = useState(props.hasVoted)
  const [noOfLikes, setNoOfLikes] = useState(props.noOfLikes || 0)
  const noOfComments = props.noOfComments || 0

  const queryClient = useQueryClient()

  const { mutate: upvoteMutation } = useMutation({
    mutationFn: ({ id }: { id: string }) =>
      api.discussions.upvote({
        params: { id: id },
      }),
    onMutate: () => {
      // Optimistic Hydration
      setNoOfLikes(p => p + (hasVoted ? -1 : 1))
      setHasVoted(p => !p)
    },
    onSuccess: res => {
      // Sync with real data when API succeeds
      setHasVoted(res.isUpvoted)
      setNoOfLikes(res.upvotes)

      queryClient.setQueryData(
        queries.discussions.list(courseId).queryKey,
        (oldQueryData?: InfiniteData<PaginatedAPIResponse<Discussion[]>>) => {
          if (oldQueryData) {
            const oldDataCopy: InfiniteData<
              PaginatedAPIResponse<Discussion[]>
            > = JSON.parse(JSON.stringify(oldQueryData))

            const pageIndex = oldDataCopy.pages.findIndex(page =>
              page.data.some(discussion => discussion.id === discussionId)
            )

            const discussionIndex = oldDataCopy.pages[pageIndex].data.findIndex(
              discussion => discussion.id === discussionId
            )
            oldDataCopy.pages[pageIndex].data[discussionIndex].upvotes =
              res.upvotes
            oldDataCopy.pages[pageIndex].data[discussionIndex].voted = Boolean(
              res.isUpvoted
            )

            return oldDataCopy
          }
          return oldQueryData
        }
      )
      queryClient.setQueryData(
        queries.discussions.post(discussionId).queryKey,
        (oldQueryData?: APIResponse<Discussion>) => {
          if (oldQueryData) {
            const oldDataCopy: APIResponse<Discussion> = JSON.parse(
              JSON.stringify(oldQueryData)
            )
            oldDataCopy.upvotes = res.upvotes
            oldDataCopy.voted = Boolean(res.isUpvoted)

            return oldDataCopy
          }
          return oldQueryData
        }
      )
    },
    onError: err => {
      // Revert Optimistic Hydration if API fails
      setNoOfLikes(p => p + (hasVoted ? 1 : -1))
      setHasVoted(p => !p)

      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  return (
    <div className={clsx(className)}>
      <Button
        className={clsx({
          [styles.activeButton]: hasVoted,
          [styles.actionButton]: !hasVoted,
        })}
        onClick={() => {
          upvoteMutation({ id: discussionId })
        }}
        startAdornment={hasVoted ? <LikedSvg /> : <LikeSvg />}
        variant="text"
      >
        {pluralize("Like", noOfLikes)}
      </Button>
      {handleCommentsClick && (
        <Button
          className={styles.actionButton}
          startAdornment={<Message />}
          variant="text"
          onClick={handleCommentsClick}
        >
          {pluralize("Reply", noOfComments, { plural: "Replies" })}
        </Button>
      )}
    </div>
  )
}

export default LikesAndComments
