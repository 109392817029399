import { toast, Typography } from "@suraasa/placebo-ui"
import { Button, CircularProgress } from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  ActivityCategoryEnum,
  ActivityUserResponsePronunciation as ActivityUserResponse,
  PronunciationActivity,
} from "api/resources/learningItems/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import AsyncBuilder from "components/AsyncBuilder"
import { LearningModuleContext } from "features/LearningModule/context"
import { RefreshDouble } from "iconoir-react"
import { useCallback, useContext, useEffect, useState } from "react"

import ActivityContainer from "../ActivityContainer"
import ActivityResultBanner from "../ActivityResultBanner"
import PronunciationComponent from "./PronunciationComponent"
import TextPreview from "./TextPreview"
import WaveForm from "./WaveForm"

const buildResponses = (
  data: PronunciationActivity["activity"]["activityItems"],
  responses: PronunciationActivity["attemptItemResponses"]
): ActivityUserResponse[] => {
  let file: ActivityUserResponse["responseFile"]
  return data.map(item => {
    const response = responses.find(r => r.activityItem === item.id)

    file = response?.responseFile || null

    const obj: ActivityUserResponse = {
      type: item.itemDetails.sentenceType,
      additionalDescription: item.itemDetails.additionalDescription,
      id: item.id,
      label: item.description,
      value: item.description,
      options: item.options,
      preRecordedFile: item.file,
      responseFile: response?.isCorrect === false ? null : file,
      wrongIndices: response?.responseDetails?.wrongIndices || [],
      validation:
        response?.isCorrect == null
          ? null
          : response.isCorrect
          ? "correct"
          : "incorrect",
    }

    return obj
  })
}

const calculatePercentage = (options: ActivityUserResponse[]) => {
  const total = options.length
  if (options.length === 0) {
    return 1
  }

  const answered = options.reduce((acc, curr) => {
    if (curr.responseFile !== null) {
      return acc + 1
    }

    return acc
  }, 0)

  const percentage = (answered / total) * 100
  return Math.max(percentage, 1)
}

const getInitialQuestionIndex = (responses: ActivityUserResponse[]) => {
  return Math.max(
    responses.findIndex(item => item.responseFile === null),
    0
  )
}

/**
 * Alternate UX:
 * Instead of making the activity timed, we can make it so that the user has to listen to the audio once, mandatory.
 * Then he can proceed to record his own audio.
 */

const Pronunciation = ({
  data,
  reset,
  onBack,
  category,
}: {
  onBack: () => void
  reset: () => Promise<void>
  data: PronunciationActivity
  category: ActivityCategoryEnum
}) => {
  const [responses, setResponses] = useState(
    buildResponses(data.activity.activityItems, data.attemptItemResponses)
  )

  const { switchItem } = useContext(LearningModuleContext)

  const responsesToAttempt = responses.filter(x => x.validation !== "correct")

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    getInitialQuestionIndex(responsesToAttempt)
  )

  const [changingQuestion, setChangingQuestion] = useState(false)

  const [mode, setMode] = useState<"failed" | "success" | "attempt">("attempt")

  const [audioBlobUrl, setAudioBlobUrl] = useState<{
    mpeg: string
    wav: string
  } | null>()

  useEffect(() => {
    setAudioBlobUrl(null)
  }, [currentQuestionIndex])

  const markAnswer = async (activityItemId: number, responseFile: File) => {
    const files = new FormData()

    files.append("activity_item_id", activityItemId.toString())
    files.append("response_file", responseFile)

    return api.learningItems.activity.markAnswer({
      data: files,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      urlParams: {
        attemptId: data.id,
      },
    })
  }

  const submitActivity = useMutation({
    mutationFn: () =>
      api.learningItems.activity.submit({
        urlParams: {
          attemptId: data.id,
        },
      }),
    onSuccess: raw => {
      setCurrentQuestionIndex(0)

      const data = raw as PronunciationActivity
      setResponses(
        buildResponses(data.activity.activityItems, data.attemptItemResponses)
      )

      if (category === ActivityCategoryEnum.PRE_COURSE) {
        setMode("success")
        return
      }
      if (
        data.attemptItemResponses.every(answer => answer.isCorrect === true)
      ) {
        setMode("success")
      }

      if (data.attemptItemResponses.some(answer => answer.isCorrect !== true)) {
        setMode("failed")
      }
    },
    onError: err => {
      if (err instanceof APIError) {
        if (err.is500) {
          toast.error("Something went wrong")
        }
        if (err.errors.message) toast.error(err.errors.message)
      }
    },
  })

  const handleReset = async () => {
    await reset()
    setMode("attempt")
  }

  const item: ActivityUserResponse | undefined = responsesToAttempt[
    currentQuestionIndex
  ] as ActivityUserResponse | undefined

  const moveToNextQuestion = () => {
    if (currentQuestionIndex === responsesToAttempt.length - 1) {
      return
    }

    setChangingQuestion(true)
    setTimeout(() => {
      setCurrentQuestionIndex(prev => prev + 1)
      setChangingQuestion(false)
    }, 250)
  }

  const moveToPreviousQuestion = () => {
    if (currentQuestionIndex <= 0) {
      return 0
    }

    setChangingQuestion(true)
    setTimeout(() => {
      setCurrentQuestionIndex(prev => prev - 1)

      setChangingQuestion(false)
    }, 250)
  }

  const onSubmit = async (shouldSubmitActivity: boolean) => {
    if (!item) return

    if (!audioBlobUrl) {
      toast.error("Please record an audio first before submitting")
      return
    }

    const fileContent: false | Blob = await fetch(audioBlobUrl.wav)
      .then(rs => rs.blob())
      .catch(() => false)
    if (!fileContent) {
      toast.error(
        "Cannot retrieve recorded audio. Please reset the recording and try again."
      )
      return
    }
    const file = new File([fileContent], "audio.wav")

    try {
      await markAnswer(item.id, file)

      if (shouldSubmitActivity) {
        await submitActivity.mutateAsync()
      } else {
        moveToNextQuestion()
      }
    } catch (err) {
      if (err instanceof APIError) {
        if (err.errors.message) toast.error(err.errors.message)
      }
    }
  }

  const percentage = calculatePercentage(responses)

  const saveAnswer = useCallback(
    (blobUrl: { mpeg: string; wav: string }) => {
      if (!item) return

      setResponses(prev =>
        prev.map(x => {
          if (x.id === item.id) {
            return {
              ...x,
              responseFile: blobUrl.mpeg,
            }
          }

          return x
        })
      )
      setAudioBlobUrl(blobUrl)
    },
    [item]
  )

  if (changingQuestion)
    return (
      <ActivityContainer
        onBack={onBack}
        title={data.activity.title}
        progress={mode === "attempt" ? percentage : 100}
        endSlot={
          <Button disabled onClick={() => submitActivity.mutate()}>
            Submit
          </Button>
        }
      >
        <div className="flex items-center justify-center p-5">
          <CircularProgress />
        </div>
      </ActivityContainer>
    )

  const isLastQuestion = currentQuestionIndex === responsesToAttempt.length - 1

  return (
    <ActivityContainer
      onBack={onBack}
      title={data.activity.title}
      progress={mode === "attempt" ? percentage : 100}
      endSlot={
        mode === "attempt" ? (
          <Button
            disabled={percentage < 100}
            onClick={() => submitActivity.mutate()}
            loading={submitActivity.isLoading}
          >
            Submit
          </Button>
        ) : undefined
      }
    >
      {import.meta.env.MODE === "development" && mode === "attempt" && (
        <div className="flex items-center justify-between rounded-lg border border-onSurface-500 p-0.25">
          <Button size="sm" variant="text" onClick={moveToPreviousQuestion}>
            Previous
          </Button>
          <Typography variant="preTitle">
            This is for developers only
          </Typography>
          <Button size="sm" variant="text" onClick={moveToNextQuestion}>
            Next
          </Button>
        </div>
      )}
      {mode === "success" && (
        <ActivityResultBanner mode="success" action={async () => onBack()} />
      )}
      {mode === "failed" && (
        <ActivityResultBanner
          mode="warn"
          action={handleReset}
          handleNext={() => {
            switchItem({ mode: "next" })
          }}
        />
      )}

      {mode === "attempt" && item && (
        <div className="flex w-full flex-col items-stretch">
          <div className="flex flex-col">
            {category === ActivityCategoryEnum.IN_COURSE && (
              <>
                <Typography variant="largeBody" className="my-1">
                  Press the &apos;Record Now&apos; button, then recite the{" "}
                  {item.type}:
                </Typography>
                <Typography
                  variant="body"
                  className="mt-1 !leading-6 text-onSurface-500"
                >
                  <span className="mb-0.5">Please make sure that-</span>
                  <li className="ms-1">You speak clearly.</li>
                  <li className="ms-1">
                    You are in a quiet environment while recording.
                  </li>
                </Typography>
              </>
            )}
            {category === ActivityCategoryEnum.PRE_COURSE && (
              <>
                <Typography variant="largeBody" className="my-1">
                  Press the &apos;Record Now&apos; button, then recite the
                  passage:
                </Typography>
                <Typography
                  variant="body"
                  className="mt-1 !leading-6 text-onSurface-500"
                >
                  <span className="mb-0.5">Please make sure that-</span>
                  <li className="ms-1">You speak clearly.</li>
                  <li className="ms-1">
                    You are in a quiet environment when recording.
                  </li>
                </Typography>
              </>
            )}
            {category === ActivityCategoryEnum.POST_COURSE && (
              <>
                <Typography variant="largeBody" className="my-1">
                  Let’s see how your grasp on English has improved since you
                  started this course. Press the &apos;Record Now&apos; button,
                  then recite the paragraph:
                </Typography>
                <Typography
                  variant="body"
                  className="mt-1 !leading-6 text-onSurface-500"
                >
                  <span className="mb-0.5">Please make sure that-</span>
                  <li className="ms-1">You speak clearly.</li>
                  <li className="ms-1">
                    You are in a quiet environment when recording.
                  </li>
                </Typography>
              </>
            )}
          </div>
          <div className={clsx("mt-4 flex flex-col items-center")}>
            {category === ActivityCategoryEnum.POST_COURSE ? (
              <>
                {audioBlobUrl ? (
                  <PostCourse
                    postBlobURL={audioBlobUrl.mpeg}
                    onRetry={() => {
                      setAudioBlobUrl(null)
                    }}
                  />
                ) : (
                  <PronunciationComponent
                    additionalDescription={item.additionalDescription}
                    text={item.label}
                    type={item.type}
                    preRecordedFile={item.preRecordedFile}
                    onRecordSuccess={saveAnswer}
                    hideWaveForm
                  />
                )}
              </>
            ) : (
              <>
                {mode === "attempt" && (
                  <PronunciationComponent
                    additionalDescription={item.additionalDescription}
                    text={item.label}
                    type={item.type}
                    preRecordedFile={item.preRecordedFile}
                    onRecordSuccess={saveAnswer}
                  />
                )}
                {audioBlobUrl && (
                  <AsyncBuilder
                    handler={async () => {
                      await onSubmit(isLastQuestion)
                    }}
                    render={({ loading, onClick }) => (
                      <Button
                        disabled={mode !== "attempt"}
                        onClick={onClick}
                        loading={loading}
                        className="mt-2 !w-full sm:!w-max"
                        color="primary"
                      >
                        Continue
                      </Button>
                    )}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}

      {mode === "failed" && (
        <div className="flex flex-col gap-2">
          <Typography variant="largeBody">What does this mean?</Typography>
          <ul>
            <li>
              The words highlighted in red, are the parts which you might have
              spoken incorrectly.
            </li>
          </ul>
          {responses.map(item => (
            <TextPreview
              type={item.type}
              key={item.label}
              highlightIndex={item.wrongIndices}
              text={item.label}
              variant="error"
            />
          ))}
        </div>
      )}

      {mode === "success" && (
        <div className="flex flex-col gap-2">
          {responses.map(item => (
            <TextPreview
              type={item.type}
              key={item.label}
              highlightIndex={[]}
              text={item.label}
              variant="success"
            />
          ))}
        </div>
      )}
    </ActivityContainer>
  )
}

export default Pronunciation

const convertResponseToBlobURL = async (blob: Blob) => {
  try {
    const url = URL.createObjectURL(blob)
    return url
  } catch (error) {
    console.error(error)
  }
}

const PostCourse = ({
  postBlobURL,
  onRetry,
}: {
  postBlobURL: string
  onRetry: () => void
}) => {
  const { plannerList } = useContext(LearningModuleContext)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const preCourse = plannerList?.find(
    item =>
      "activityCategory" in item &&
      item.activityCategory === ActivityCategoryEnum.PRE_COURSE
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [blobUrl, setBlobUrl] = useState<string | null>(null)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const exec = async (activityId: number) => {
      try {
        const preActivity = await api.learningItems.activity.start({
          data: {
            activityId: activityId,
            retry: false,
          },
        })
        const preActivityResponseId = preActivity.attemptItemResponses[0].id

        const binary = await api.learningItems.activity.getItemResponse({
          urlParams: {
            itemResponseId: preActivityResponseId,
          },
        })

        console.log({ binary })

        if (binary) {
          const blobUrl = await convertResponseToBlobURL(binary)
          console.log({ blobUrl })

          if (blobUrl) setBlobUrl(blobUrl)
        }
      } catch (e) {
        console.error(e)
      }
    }

    // if (preCourse) {
    //   exec(preCourse.learningContentId)
    // }

    // // Clean up the Blob URL when the component unmounts
    // return () => {
    //   if (blobUrl) {
    //     URL.revokeObjectURL(blobUrl)
    //   }
    // }
  }, [])

  return (
    <>
      {/* <Typography variant="title4" className="mb-2">
        Before you started the course
      </Typography>
      <WaveForm
        stream={blobUrl || undefined}
        height={40}
        width="100%"
        progressColor="#64748B"
        waveColor="#64748B"
        className="mb-3"
      /> */}

      <Typography variant="title4" className="mb-2">
        After you finished the course
      </Typography>
      <div className="flex w-full flex-wrap justify-end gap-1">
        <WaveForm
          stream={postBlobURL}
          height={40}
          width="100%"
          progressColor="#138D75"
          waveColor="#138D75"
          className="!bg-success-50"
        />
        <Button
          onClick={onRetry}
          className="!rounded-full !bg-black !px-1"
          size="sm"
        >
          <div className="flex items-center space-x-0.5 text-xs text-white">
            <RefreshDouble height={15} />
            <span>Retry</span>
          </div>
        </Button>
      </div>
    </>
  )
}
