import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ToolType } from "api/resources/aiTools/types"
import clsx from "clsx"
import useTabs, { Tabs } from "components/TabBar/hooks/useTabs"
import { getToolNamePlural } from "features/AItools/helper"

import TabContent from "./TabContent"

const tabs: Tabs = [
  { name: "All", content: TabContent },
  {
    name: getToolNamePlural(ToolType.lessonPlan),
    content: () => <TabContent type={ToolType.lessonPlan} />,
  },
  {
    name: getToolNamePlural(ToolType.rubrics),
    content: () => <TabContent type={ToolType.rubrics} />,
  },
  {
    name: getToolNamePlural(ToolType.quiz),
    content: () => <TabContent type={ToolType.quiz} />,
  },
  {
    name: getToolNamePlural(ToolType.handout),
    content: () => <TabContent type={ToolType.handout} />,
  },
  {
    name: getToolNamePlural(ToolType.narration),
    content: () => <TabContent type={ToolType.narration} />,
  },
  {
    name: getToolNamePlural(ToolType.subjectiveAssessment),
    content: () => <TabContent type={ToolType.subjectiveAssessment} />,
  },
  {
    name: getToolNamePlural(ToolType.assignment),
    content: () => <TabContent type={ToolType.assignment} />,
  },
  {
    name: getToolNamePlural(ToolType.activityPlanner),
    content: () => <TabContent type={ToolType.activityPlanner} />,
  },
  {
    name: getToolNamePlural(ToolType.videoSummary),
    content: () => <TabContent type={ToolType.videoSummary} />,
  },
  {
    name: getToolNamePlural(ToolType.unitPlanner),
    content: () => <TabContent type={ToolType.unitPlanner} />,
  },
]

const History = () => {
  const [activeTab, setActiveTab] = useTabs({ tabs })

  const historyQuery = useInfiniteQuery({
    queryKey: queries.aiTools.listHistory().queryKey,
    queryFn: () =>
      api.aiTools.listHistory({
        type: null,
        params: { page: 1 },
      }),
  })

  const history = historyQuery.data?.pages.flatMap(i => i.data)

  if (historyQuery.isLoading) return null

  if (historyQuery.isSuccess && history && history.length === 0) {
    return (
      <div className="mb-2">
        <Typography variant="title3" className="mb-1">
          History
        </Typography>
        <Typography variant="body">
          Your past searches will show up here. Click on the cards above and
          start generating!
        </Typography>
      </div>
    )
  }

  return (
    <div>
      <div className="mb-2 flex justify-between">
        <Typography variant="title3">History</Typography>
      </div>
      <div className="flex flex-wrap gap-1">
        {tabs.map(i => (
          <Button
            key={i.name}
            className={clsx({
              "!bg-onSurface-100": i.name !== activeTab.name,
            })}
            size="sm"
            onClick={() => setActiveTab(i.name)}
            color={i.name === activeTab.name ? "primary" : "white"}
            rounded
          >
            {i.name}
          </Button>
        ))}
      </div>

      <activeTab.content />
    </div>
  )
}

export default History
