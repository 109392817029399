import { Typography } from "@suraasa/placebo-ui"
import { Divider, LinearProgress, Theme } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import RecordIcon from "features/ITO/Assessments/assets/record-icon.svg"
import {
  getOffencePercentage,
  getWarningGradient,
} from "features/ITO/Assessments/helpers"
import React from "react"
import { createUseStyles } from "react-jss"
import Webcam from "react-webcam"

import shepherdClass from "../shepherdClasses"

type Props = {
  videoRef: React.RefObject<Webcam>
  totalWarnings: number
  maxWarningCount: number
}

const useStyles = createUseStyles((theme: Theme) => ({
  videoFrame: {
    transform: "rotateY(180deg)",
    webkitTransform: "rotateY(180deg)",
    mozTransform: "rotateY(180deg)",
    borderRadius: "8px",
    width: "100%",
  },
  divider: {
    "& hr": {
      backgroundColor: theme.colors.onSurface[200],
    },
  },
  color: {
    color: theme.colors.onSurface[600],
  },
  videoBox: {
    position: "relative",
  },
  box: {
    backgroundColor: "#EFEFF7",
    padding: "10px",
    background: "red",
    resize: "horizontal",
    maxWidth: "100%",
    height: "100%",
    aspectRatio: "4 / 3",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  recordIconContainer: {
    position: "absolute",
    top: "12px",
    right: "16px",
    animation: "blink 2s linear infinite",
    [theme.breakpoints.down("xs")]: {
      top: "8px",
      right: "8px",
    },
  },
}))

const CameraPanel = ({ videoRef, totalWarnings, maxWarningCount }: Props) => {
  const classes = useStyles()

  const offensePercentage = getOffencePercentage(totalWarnings, maxWarningCount)

  return (
    <div className="grid gap-3 p-1 pt-0 xl:pl-4">
      <div className={classes.videoBox}>
        <Webcam
          className={classes.videoFrame}
          ref={videoRef}
          videoConstraints={{
            facingMode: "user",
          }}
        />
        <img
          src={RecordIcon}
          alt="recordIcon"
          className={classes.recordIconContainer}
        />
      </div>

      <div className={classes.divider}>
        <Divider />
      </div>
      <div className={clsx("grid gap-0.75", shepherdClass["offenseCount"])}>
        <Typography variant="preTitle" className={classes.color}>
          Offence count
        </Typography>
        <LinearProgress
          color="warning"
          value={offensePercentage}
          linearGradient={getWarningGradient(offensePercentage)}
        />
        <div className={classes.color}>
          <Typography variant="strongSmallBody">
            {totalWarnings} out of {maxWarningCount} times
          </Typography>
          <Typography variant="smallBody">triggered</Typography>
        </div>
      </div>
      <div className={classes.divider}>
        <Divider />
      </div>
    </div>
  )
}

export default React.memo(CameraPanel)
