import {
  Button,
  Container,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import React from "react"
import { useTheme } from "react-jss"

type Props = {
  onPrev?: () => void
  onNext?: () => void
  disabledNext: boolean
  disabledPrev: boolean
}

const BottomNavigation = ({
  onNext,
  onPrev,
  disabledNext,
  disabledPrev,
}: Props) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <div className="fixed bottom-0 left-0 z-[6] w-full">
      <Container fullWidth={isSmDown}>
        <div className="flex justify-between rounded-t-2xl bg-onSurface-900 px-3 py-2">
          <Button
            onClick={onPrev}
            disabled={!onPrev || disabledPrev}
            size={isXs ? "sm" : "md"}
          >
            Previous
          </Button>
          <Button
            onClick={onNext}
            disabled={!onNext || disabledNext}
            size={isXs ? "sm" : "md"}
          >
            Next
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default BottomNavigation
