import React, { useRef } from "react"

type Props = {
  status: "10%" | "1%" | "30%" | "100"
  onEnded: () => void
  videoEnded: boolean
}

const StatusMap = {
  "1%": "assets/ito/2023/AfterResultPhase/Percentiles/top1.mp4",
  "10%": "assets/ito/2023/AfterResultPhase/Percentiles/top10.mp4",
  "30%": "assets/ito/2023/AfterResultPhase/Percentiles/top30.mp4",
  "100": "assets/ito/2023/AfterResultPhase/Percentiles/top100.mp4",
}

const PercentileAnimation = ({
  onEnded = () => {},
  videoEnded,
  status,
}: Props) => {
  const video = useRef<HTMLVideoElement | null>(null)

  const onVideoEnd = () => {
    onEnded()
  }

  if (videoEnded) {
    return null
  }
  return (
    <div className="fixed top-0 z-50 h-screen w-screen bg-black">
      <video
        ref={video}
        className="h-screen w-screen"
        autoPlay={true}
        controlsList="nodownload"
        muted
        playsInline
        id="video"
        src={StatusMap[status]}
        onEnded={onVideoEnd}
      />
    </div>
  )
}

export default PercentileAnimation
