import { Avatar, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Container,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { CoverStyle, Profile } from "api/resources/profile/types"
import clsx from "clsx"
import BackButton from "components/BackButton"
import ShareSheet from "components/ShareSheet"
import Section from "features/Profile/components/Section"
import { BadgeCheck, ShareIos } from "iconoir-react"
import { useState } from "react"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router-dom"
import routes from "routes"
import { buildUserName, getAuthInfo } from "utils/helpers"

import { getSkillProfileRoute } from "../utils"

const AVATAR_SIZE = { lg: 142, sm: 91 }

const useStyles = createUseStyles(theme => ({
  shareButton: {
    borderColor: theme.colors.onSurface[400],
    color: theme.colors.onSurface[800],
  },

  displayPictureAndFilter: {
    transform: "translateY(-60%)",
    height: AVATAR_SIZE.lg,
    width: AVATAR_SIZE.lg,
    left: theme.spacing(3),
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      transform: "translateY(calc(-60% - 40px))",
      height: AVATAR_SIZE.sm,
      width: AVATAR_SIZE.sm,
      left: theme.spacing(2),
    },
  },

  displayPicture: {
    background: "white",
    objectFit: "cover",
    borderRadius: "50%",
    border: `3px solid ${theme.colors.common.white[500]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
  },

  verifiedBadge: {
    display: "inline-flex",
    color: theme.colors.primary[500],
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(1 / 2),
    "& svg": {
      fill: theme.colors.primary[500],
    },
    [theme.breakpoints.down("xs")]: {
      background: "transparent",
      "& span": {
        display: "none",
      },
    },
  },

  title: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      /** Edit profile button */
      "& button": {
        position: "absolute",
        right: theme.spacing(12 / 8),
        top: theme.spacing(12 / 8),
      },
    },
  },

  position: {
    marginBottom: theme.spacing(1 / 2),
  },
  locations: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(6 / 8),
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(1),
    },
    marginTop: theme.spacing(1),
  },

  cover: {
    height: 184,
    minWidth: "100%",
    backgroundColor: theme.colors.onSurface[300],
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: theme.spacing(1, 1, 0, 0),

    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    paddingLeft: `calc(${AVATAR_SIZE.lg}px + 24px + 16px)`,
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(3),

    [theme.breakpoints.down("sm")]: {
      borderRadius: theme.spacing(0),
    },

    [theme.breakpoints.down("xs")]: {
      height: 108,
      paddingLeft: 0,
      paddingRight: theme.spacing(2),
      /** Hide Video Portfolio button from cover */
      "& button:nth-child(1),button:nth-child(2)": {
        display: "none",
      },
    },
  },

  content: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),
    paddingLeft: `calc(${AVATAR_SIZE.lg}px + 24px + 16px)`,
    minHeight: "calc(284.1px - 175px)",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(5),
    },
  },
}))

const getImageUrl = (imageName?: CoverStyle, isSmDown?: boolean) => {
  if (!imageName) {
    return undefined
  }

  let baseUrl = "https://assets.suraasa.com/lms/profile-assets/cover-images/"

  if (isSmDown) {
    baseUrl += "mobile/"
  }

  return `${baseUrl}${imageName}.webp`
}

const Overview = ({ data }: { data: Profile }) => {
  const classes = useStyles()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const [openShare, setOpenShare] = useState(false)
  const { user, isVerified, currentWorkingExperience, picture } = data
  const fullName = buildUserName(user)

  const auth = getAuthInfo()
  const isSelf = auth ? auth.user.username === user.username : false

  const url = `${window.location.origin}${getSkillProfileRoute({
    username: user.username,
  })}`

  const navigate = useNavigate()
  return (
    <>
      <ShareSheet
        data={{
          url,
          text: `Check out my Skill Profile on Suraasa, the career app for teachers: ${url}`,
        }}
        open={openShare}
        handleClose={() => setOpenShare(false)}
      />
      <Section>
        <header>
          <div
            style={{
              backgroundImage: `url(${getImageUrl(data.coverStyle, isXsDown)})`,
            }}
            className={clsx("relative h-16 bg-cover text-black")}
          >
            <Container>
              <BackButton
                goBack={() => {
                  if (isSelf) navigate(routes.profile.self, { replace: true })
                  else
                    navigate(
                      routes.profile.public.replace(":username", user.username),
                      { replace: true }
                    )
                }}
                className="mt-2 !bg-black/30"
                color="white"
              />
            </Container>
          </div>
          <Container>
            <div className={classes.content}>
              <Avatar
                className="!absolute left-2 size-15 translate-y-[calc(-40%_-_40px)] shadow-[0px_6px_15px_rgba(0,0,0,0.05)] sm:left-3 sm:translate-y-[-50%]"
                src={picture || undefined}
                name={fullName}
              />

              <div
                className="mt-6 flex flex-wrap justify-between gap-1 sm:mt-0"
                style={{ width: "100%" }}
              >
                <div>
                  <div className={classes.title}>
                    <div className="flex items-center">
                      <Typography className="inline" variant="title3">
                        {buildUserName(user)}
                      </Typography>
                      {isVerified && (
                        <BadgeCheck
                          color="white"
                          height="26"
                          width="26"
                          className="ms-[1px] fill-primary-500"
                        />
                      )}
                    </div>
                  </div>

                  {currentWorkingExperience && (
                    <>
                      <Typography
                        className={clsx(classes.position, "text-onSurface-500")}
                        variant="smallBody"
                      >
                        {currentWorkingExperience.title}
                      </Typography>

                      <Typography
                        className="text-onSurface-500"
                        variant="smallBody"
                      >
                        {currentWorkingExperience.organisationName}
                      </Typography>
                    </>
                  )}
                </div>
                <div>
                  <Button
                    onClick={() => setOpenShare(true)}
                    variant="text"
                    endAdornment={<ShareIos />}
                    label="Share"
                  />
                </div>
              </div>
            </div>
          </Container>
        </header>
      </Section>
    </>
  )
}

export default Overview
