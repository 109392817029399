import { createQueryKeys } from "@lukemorales/query-key-factory"

import { ToolType } from "./types"

export default createQueryKeys("aiTools", {
  listHistory: (type?: ToolType) => ({
    queryKey: ["listHistory", type || "all"],
  }),
  listHistoryWithOutput: (type: ToolType, id: number | string) => ({
    queryKey: ["listHistoryWithOutput", type, id],
  }),
  retrieveHistory: (type: ToolType, id) => ({
    queryKey: ["attemptList", type, id],
  }),
  promptData: (type: ToolType, id) => ({
    queryKey: ["promptData", id, type],
  }),
  retrieveResumeFiller: () => ({
    queryKey: ["retrieveResumeFiller"],
  }),
  retrieveUnitDivisionResponse: id => ({
    queryKey: ["retrieveUnitDivisionResponse", id],
  }),
  unitPlanDivisionResponse: currentResponseId => ({
    queryKey: ["unitPlanDivisionResponse", currentResponseId],
  }),
  unitPlanResponse: currentResponseId => ({
    queryKey: ["unitPlanResponse", currentResponseId],
  }),
  generateUnitPlan: (unitIndex, termIndex, currentResponseId) => ({
    queryKey: [
      "generateUnitPlan",
      "academic_year",
      unitIndex,
      termIndex,
      currentResponseId,
    ],
  }),
  retrieveUnitPlanResponse: currentResponseId => ({
    queryKey: ["retrieveUnitPlanResponse", "academic_year", currentResponseId],
  }),
})
