import { Typography } from "@suraasa/placebo-ui"
import { CircularProgress, Toggle } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { SETTINGS } from "api/resources/settings/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import React from "react"
import { handleErrors } from "utils/helpers"
import { PropsWithClassName, ValueOf } from "utils/types"

import { useSettings } from ".."
import MobileHeadBar from "../MobileHeadbar"

enum BooleanValues {
  TRUE = "1",
  FALSE = "0",
}

const Card = ({
  className,
  slug,
  title,
  description,
  value,
}: PropsWithClassName<{
  slug: ValueOf<typeof SETTINGS>
  title: string
  description: string
  value: string
}>) => {
  const { updateUserPreference } = useSettings()

  async function updateNotificationPreference(slug: any) {
    try {
      const updateData = {
        slug,
        value:
          value === BooleanValues.TRUE
            ? BooleanValues.FALSE
            : BooleanValues.TRUE,
      }
      await api.settings.updateUserPreferences({
        data: {
          settings: [updateData],
        },
      })
      updateUserPreference(slug, updateData.value)
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    }
  }

  return (
    <div
      className={clsx(
        className,
        "flex items-start justify-between md:items-center"
      )}
    >
      <div className="flex w-full flex-col md:w-[90%]">
        <Typography variant="strong">{title}</Typography>
        <Typography variant="smallBody" className="mt-1 text-onSurface-500">
          {description}
        </Typography>
      </div>
      <Toggle
        checked={value === BooleanValues.TRUE}
        onChange={() => updateNotificationPreference(slug)}
      />
    </div>
  )
}

const NotificationSettings = () => {
  const { userPreferences } = useSettings()

  const pushNotifications = userPreferences?.find(
    e => e.setting.slug === SETTINGS.pushNotifications
  )
  const eventBasedEmail = userPreferences?.find(
    e => e.setting.slug === SETTINGS.eventBasedEmails
  )
  const promotionalEmail = userPreferences?.find(
    e => e.setting.slug === SETTINGS.promotionalEmails
  )

  if (!pushNotifications || !eventBasedEmail || !promotionalEmail)
    return (
      <div className="flex items-center justify-center pt-5">
        <CircularProgress />
      </div>
    )

  return (
    <div>
      <MobileHeadBar title="Notification Settings" />

      {/* Push Notifications */}
      <Typography variant="title3" className="mb-2">
        Push Notifications
      </Typography>
      <Card
        slug={SETTINGS.pushNotifications}
        className="mb-4"
        title="Push Notifications"
        description="Push notifications are important updates and alerts that are sent to your device. By enabling push notifications, you can stay up-to-date on important information and events, even when you are not actively using it."
        value={pushNotifications?.value}
      />

      {/* Email Notifications */}
      <Typography variant="title3" className="mb-2">
        Email Notifications
      </Typography>
      <Card
        slug={SETTINGS.promotionalEmails}
        className="mb-2"
        title="Promotional Emails"
        description="Promotional Emails include newsletter, coupon codes, and many their promotional stuff you might like"
        value={promotionalEmail?.value}
      />
      <Card
        slug={SETTINGS.eventBasedEmails}
        title="Event Emails"
        className="mb-4"
        description="These notifications include, events like new skill evidences (assignments) assigned to you."
        value={eventBasedEmail?.value}
      />
    </div>
  )
}

export default NotificationSettings
