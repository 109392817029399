import * as Sentry from "@sentry/react"
import { ErrorBoundary } from "@sentry/react"
import { useMenuState } from "@szhsin/react-menu"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Enrollment } from "api/resources/learningItems/types"
import { GenderMapping } from "api/resources/profile/types"
import { Email, UserPreference } from "api/resources/settings/types"
import ErrorPage from "components/ErrorPage"
import { useITOAccess } from "features/ITO/useITOAccess"
import FeatureToggleManagerProvider from "global/FeatureToggleProvider"
import { useSetPlatformLanguage } from "global/useSetPlatformLanguage"
import { posthog } from "posthog-js"
import { useEffect, useState } from "react"
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom"
import {
  BROWSER_STORAGE_KEYS,
  learningItemSlugs,
  PLATFORM_SETTINGS_SLUGS,
} from "utils/constants"
import { clearAuthInfo, getAuthInfo, saveAuthInfo } from "utils/helpers"
import { trackingService } from "utils/tracking"
import { UserPreferenceManager } from "utils/userPreferenceManager"

import { ValueOf } from "../../utils/types"
import { context, defaultContextValue, ResumeFillerStageType } from "./context"

const GlobalContext = () => {
  const notificationsPopupMenuState = useMenuState({
    transition: true,
  })
  const [authInfo, setAuthInfo] = useState(getAuthInfo())
  const [reMountingApp, setRemountingApp] = useState(false)
  // const [resumeFillerStage, setResumeFillerStage] =
  //   useState<ResumeFillerStageType>(null)

  const location = useLocation()

  const isAuthenticated = !!authInfo
  const itoParticipationDetails = useITOAccess({ isAuthenticated })

  useSetPlatformLanguage({ isAuthenticated })

  const [emails, setEmails] = useState<Email[]>(defaultContextValue.emails)
  const [enrollments, setEnrollments] = useState<Enrollment[] | null>(
    defaultContextValue.enrollments
  )
  // const updateResumeFillerStage = (type: ResumeFillerStageType) => {
  //   setResumeFillerStage(type)
  //   UserPreferenceManager.set(BROWSER_STORAGE_KEYS.resumeFillerStage, type)
  // }

  const learningRestrictions = useQuery({
    enabled: isAuthenticated,
    queryKey: queries.learningItems.learningRestrictions().queryKey,
    queryFn: () => api.learningItems.listRestrictions(),
  })

  // const retrieveResumeFiller = useQuery({
  //   enabled: isAuthenticated && Boolean(resumeFillerStage),
  //   queryKey: queries.aiTools.retrieveResumeFiller().queryKey,
  //   queryFn: () => api.aiTools.resumeFiller.retrieve(),
  //   refetchInterval: resumeFillerStage === "done" ? undefined : 5 * 60 * 100,
  //   onSuccess: res => {
  //     if (res.status === "Completed") {
  //       updateResumeFillerStage("done")
  //     }
  //   },
  // })

  // const dueAssignmentList = useQuery({
  //   enabled: isAuthenticated,
  //   queryKey: queries.assignments.dueAssignmentList().queryKey,
  //   queryFn: () => api.assignments.listDueAssignments({ params: {} }),
  // })

  const { data: userPreference } = useQuery({
    enabled: isAuthenticated,
    queryKey: [isAuthenticated],
    queryFn: () => api.settings.getUserPreferences(),
  })

  const platformSettings = userPreference
    ? (() => {
        try {
          if (!userPreference || userPreference?.length == 0) {
            return null
          }
          const preferences = userPreference.filter(x =>
            Object.values(PLATFORM_SETTINGS_SLUGS).includes(
              x.setting.slug as unknown as ValueOf<
                typeof PLATFORM_SETTINGS_SLUGS
              >
            )
          )
          const settings: { [x: string]: UserPreference } = {}
          preferences.forEach(v => {
            settings[v.setting.slug as string] = v
          })
          return settings
        } catch (error) {
          return null
        }
      })()
    : null

  const profileAPI = useQuery({
    enabled: isAuthenticated,
    queryKey: queries.profile.profileData().queryKey,
    queryFn: () =>
      api.profile.retrieve({
        urlParams: {},
      }),
    initialData: defaultContextValue.profile,
  })

  const emailsAPI = useQuery({
    enabled: isAuthenticated,
    queryKey: queries.settings.emailList().queryKey,
    queryFn: () => api.settings.emails.list(),
    onSuccess: data => setEmails(data),
  })

  const enrollmentsAPI = useQuery({
    enabled: isAuthenticated,
    queryKey: queries.learningItems.enrollmentList().queryKey,
    queryFn: () => api.learningItems.listEnrollments(),
    onSuccess: data => {
      posthog.setPersonProperties(undefined, {
        has_pgctl: Boolean(data.find(x => x.slug === learningItemSlugs.pgctl)),
        has_med: Boolean(data.find(x => x.slug === learningItemSlugs.pgctl)),
        // is_paying_customer: data.length > 0,
      })
      setEnrollments(data)
    },
  })
  const socialAccounts = useQuery({
    enabled: isAuthenticated,
    queryFn: () => api.settings.socialAuth.list(),
    queryKey: queries.settings.socialAccountsList().queryKey,
  })

  // useEffect(() => {
  //   const currentStage: ResumeFillerStageType = UserPreferenceManager.get(
  //     BROWSER_STORAGE_KEYS.resumeFillerStage
  //   )
  //   if (currentStage) {
  //     setResumeFillerStage(currentStage)
  //   } else {
  //     setResumeFillerStage(null)
  //   }
  // }, [])

  useEffect(() => {
    if (reMountingApp) {
      profileAPI.refetch()
      emailsAPI.refetch()
      enrollmentsAPI.refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reMountingApp])

  useEffect(() => {
    const profile = profileAPI.data
    if (profile.id) {
      if (profile.phoneNumber) {
        trackingService.setUserAttribute(
          "mobile",
          `+${profile.phoneNumber.code}${profile.phoneNumber.number}`
        )
      }
      if (profile.country) {
        trackingService.setUserAttribute("COUNTRY", profile.country.name)
      }
      if (profile.state) {
        trackingService.setUserAttribute("STATE", profile.state.name)
      }
      if (profile.gender) {
        trackingService.setUserAttribute(
          "GENDER",
          GenderMapping[profile.gender]
        )
      }
      trackingService.setUserAttribute(
        "looking_for_jobs",
        profile.lookingForJobs
      )
      trackingService.setUserAttribute("is_verified", profile.isVerified)
    }
  }, [profileAPI.data])

  useEffect(() => {
    if (itoParticipationDetails.registrationStatus === "completed") {
      trackingService.setUserAttribute("ito_2023_registrant", true)
    }
  }, [itoParticipationDetails.registrationStatus])

  const isPGCTLUser = enrollments
    ? enrollments.length > 0
      ? enrollments.some(item => item.slug === learningItemSlugs.pgctl)
      : false
    : undefined

  useEffect(() => {
    if (isPGCTLUser) {
      trackingService.setUserAttribute("is_pgctl_user", isPGCTLUser)
    }
  }, [isPGCTLUser])

  useEffect(() => {
    if (authInfo) {
      trackingService.initUser(authInfo)
    }
    if (authInfo)
      Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
  }, [authInfo])

  useEffect(() => {
    trackingService.trackEvent("PAGE_VIEW", { url: window.location.href }, [
      "WebEngage",
    ])
  }, [location, isAuthenticated])

  const remountApp = () => {
    if (reMountingApp) return

    setRemountingApp(true)

    setTimeout(() => {
      setRemountingApp(false)
    }, 150)
  }

  return (
    <ErrorBoundary fallback={e => <ErrorPage data={e} />} showDialog>
      <context.Provider
        value={{
          notificationsPopupMenuState,
          itoParticipationDetails,
          authInfo,
          setAuthInfo: newAuth => {
            if (
              authInfo &&
              newAuth &&
              authInfo.user.uuid !== newAuth.user.uuid
            ) {
              remountApp()
            }

            saveAuthInfo(newAuth)
            setAuthInfo(newAuth)
          },
          logout: () => {
            clearAuthInfo()
            setAuthInfo(null)
          },
          isAuthenticated,
          emails,
          enrollments,
          updateEmails: emails => setEmails(emails),
          profile: profileAPI.data,
          fetchProfile: profileAPI.refetch,
          learningRestrictions: learningRestrictions.data,
          platformSettings,
          // retrieveResumeFiller: {
          //   data: retrieveResumeFiller.data?.output,
          //   isLoading: retrieveResumeFiller.isLoading,
          //   id: retrieveResumeFiller.data?.id,
          // },
          // updateResumeFillerStage,
          // resumeFillerStage,
          // dueAssignmentList: dueAssignmentList.data,
          socialAccounts: socialAccounts.data || [],
          refetchSocialAccounts: socialAccounts.refetch,
        }}
      >
        <ScrollRestoration />
        <FeatureToggleManagerProvider>
          {!reMountingApp && <Outlet />}
        </FeatureToggleManagerProvider>
      </context.Provider>
    </ErrorBoundary>
  )
}

export default GlobalContext
