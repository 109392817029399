import { IconButton } from "@suraasa/placebo-ui-legacy"
import { Evidence, EvidenceType } from "api/resources/profile/types"
import clsx from "clsx"
import { OpenNewWindow } from "iconoir-react"
import React from "react"

const PreviewEvidenceList = ({ data }: { data: Evidence[] }) => {
  if (data && data.length === 0) {
    return null
  }

  return (
    <div className="flex flex-wrap gap-2">
      {data.map((item, index) => (
        <Preview item={item} key={index} />
      ))}
    </div>
  )
}

const Preview = ({ item }: { item: Evidence }) => {
  const [imageError, toggleImageError] = React.useState(false)
  const element = (
    <>
      {imageError ? (
        <div className="h-full bg-slate-200 pl-1 pt-1 text-base font-bold not-italic leading-[130%] tracking-[-0.32px] text-black">
          Certificate <br />
          <span className="text-sm">(click to view)</span>
        </div>
      ) : (
        <img
          alt="link"
          className="size-full bg-white object-cover"
          src={item.evidenceType === EvidenceType.URL ? item.url : item.file}
          onError={() => toggleImageError(true)}
        />
      )}
    </>
  )

  // if (item.evidenceType === 2) {
  //   const url = new URL(item.file)
  //   const isPDF = url.pathname.endsWith(".pdf")
  //   const isDOC =
  //     url.pathname.endsWith(".doc") || url.pathname.endsWith(".docx")

  //   if (isPDF) {
  //     element = (
  //       <embed
  //         src={item.file}
  //         width="166"
  //         height="110"
  //         className="overflow-hidden"
  //       />
  //     )
  //   }

  //   if (isDOC) {
  //     const embedUrl = encodeURIComponent(item.file)
  //     element = (
  //       <iframe
  //         className="overflow-hidden"
  //         // Alternate:
  //         // src={`https://docs.google.com/gview?url=${embedUrl}&embedded=true`}
  //         src={`https://view.officeapps.live.com/op/embed.aspx?src=${embedUrl}`}
  //         width="166"
  //         height="110"
  //         title="Embedded Word Document"
  //       />
  //     )
  //   }
  // }

  return (
    <div
      className={clsx(
        "relative h-[110px] w-[166px] overflow-hidden rounded-lg border-[5px] border-solid bg-decorative-two-200",
        {
          "border-decorative-two-50": item.evidenceType === EvidenceType.File,
          "border-surface-50 ": item.evidenceType === EvidenceType.URL,
        }
      )}
    >
      <a
        href={item.evidenceType === EvidenceType.URL ? item.url : item.file}
        rel="noreferrer"
        target="_blank"
      >
        {element}
      </a>
      <div className="absolute bottom-1 right-1 shadow-[0px_0px_5px_0px_rgba(0,0,0,0.5)]">
        <IconButton
          component="a"
          href={item.evidenceType === EvidenceType.URL ? item.url : item.file}
          rel="noreferrer"
          target="_blank"
          color="white"
          variant="filled"
        >
          <OpenNewWindow width={20} height={20} />
        </IconButton>
      </div>
    </div>
  )
}

export default PreviewEvidenceList
