import { Typography } from "@suraasa/placebo-ui"
import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  AssignmentList,
  SUBMISSION_STATUS,
} from "api/resources/assignments/types"
import HandHoldingPen from "assets/Fallback/hand-holding-pen.svg"
import clsx from "clsx"
import Fallback from "components/Fallback"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import TruncatedText from "components/TruncatedText"
import CheckRestrictions, {
  getRestriction,
} from "features/AccessManagement/CheckRestrictions"
import ReattemptDialog from "features/AssignmentResult/ReattemptDialog"
import { getActionConfig } from "features/Assignments/utils"
import { buildAssignmentTitle } from "features/Assignments/utils"
import { Lock } from "iconoir-react"
import { useState } from "react"
import { useTheme } from "react-jss"
import { Link, useParams } from "react-router-dom"
import routes from "routes"
import { formatDate } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import { PropsWithClassName } from "utils/types"

import { useCourseDetails } from ".."
import styles from "./Assignments.module.css"
import TutorialVideo from "./TutorialVideo"

const getDueDate = (item: AssignmentList) => {
  if (!item.dueDate) return null

  const date = (
    <Typography variant="smallBody" className="text-onSurface-500">
      Due by {formatDate(item.dueDate, "d LLL y")}
    </Typography>
  )

  // Show due date only in case of due, overdue, saved_for_later and rejected
  switch (item.submissionStatus) {
    case null:
    case undefined:
    case SUBMISSION_STATUS.rejected:
    case SUBMISSION_STATUS.plagiarised:
    case SUBMISSION_STATUS.savedForLater:
      return date
  }

  return null
}

const getButtonAction = (
  item: AssignmentList,
  extras: {
    slug: string
    parentSlug: string
    canAttempt: boolean
    isDraft: boolean | undefined
  }
) => {
  const state = {
    slug: extras.slug,
    parentSlug: extras.parentSlug,
  }

  if (extras.canAttempt) {
    const path = routes.assignment
      .replace(":slug", extras.slug)
      .replace(":learningItemType", "course")
      .replace(":assignmentId", item.id.toString())

    const url = new URL(path, window.location.origin)
    if (extras.isDraft && item.submissionUuid)
      url.searchParams.set("submissionId", item.submissionUuid)

    return (
      <Button
        variant="text"
        component={Link}
        to={url.pathname + url.search}
        state={state}
      >
        View
      </Button>
    )
  }

  if (item.submissionUuid)
    return (
      <Button
        variant="text"
        component={Link}
        to={routes.submission
          .replace(":slug", extras.slug)
          .replace(":learningItemType", "course")
          .replace(":assignmentId", item.id.toString())
          .replace(":submissionId", item.submissionUuid)}
        state={state}
      >
        View
      </Button>
    )

  return (
    <Button
      component={Link}
      to={routes.assignment
        .replace(":slug", extras.slug)
        .replace(":learningItemType", "course")
        .replace(":assignmentId", item.id.toString())}
      state={state}
    >
      View
    </Button>
  )
}

const CourseAssignments = ({ className }: PropsWithClassName) => {
  const [openReattemptDialog, setOpenReattemptDialog] = useState<string | null>(
    null
  )
  const { course } = useCourseDetails()

  const { slug, parentSlug } = useParams() as {
    slug: string
    parentSlug: string
  }

  const { isFetching, hasNextPage, fetchNextPage, data, isLoading, isError } =
    useInfiniteQuery({
      queryKey: queries.assignments.list("course", course.id).queryKey,
      queryFn: x =>
        api.assignments.list({
          params: { page: x.pageParam || 1 },
          urlParams: {
            learningItemType: "course",
            learningItemId: course.id,
          },
        }),
      getNextPageParam: lastPage => {
        return lastPage.nextPage ?? undefined
      },
    })

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage,
    actionFunc: fetchNextPage,
  })

  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const allData = data?.pages.map(page => page.data).flat() || []

  const isRestrictedInAnyWay = allData.some(x => getRestriction(x))

  const isDebugMode = localStorage.getItem("assignmentListDebug") === "true"

  return (
    <>
      <ReattemptDialog
        open={Boolean(openReattemptDialog)}
        title={openReattemptDialog || ""}
        type="assignment"
        onClose={() => {
          setOpenReattemptDialog(null)
        }}
      />
      <div className={clsx(className, "pb-2")}>
        <Typography className="mb-2" variant="title3">
          Skill Evidences (Assignments)
        </Typography>

        {!isRestrictedInAnyWay && (
          <TutorialVideo courseSlug={course.slug} className="mb-2" />
        )}

        {(isError || isLoading) && (
          <Fallback
            data={{
              image: HandHoldingPen,
              title: "Something went wrong!",
              description: "Please contact care@suraasa.com",
            }}
            className="my-9 sm:my-20 md:my-18"
            hasError={isError}
            isLoading={isLoading}
          />
        )}

        {allData.length > 0 ? (
          <ItemList>
            {allData.map(assignment => {
              const isLocked = assignment.dueDate === null
              const config = getActionConfig(assignment)

              return (
                <ItemRow
                  key={assignment.uuid}
                  className={clsx("relative", {
                    "bg-[linear-gradient(270deg,#FBF0F0_0%,rgba(251,240,240,0)_39.36%)]":
                      config.isOverdue,
                    [styles.draft]: config.isDraft,
                  })}
                  infoSlot={
                    <div className="grow" ref={hasNextPage ? ref : null}>
                      {isDebugMode && (
                        <pre>{JSON.stringify(assignment, null, 2)}</pre>
                      )}
                      {config.isOverdue && (
                        <div
                          className={clsx(
                            "absolute left-0 top-0 h-full w-0.5 bg-critical-500"
                          )}
                        />
                      )}
                      {config.isDraft && (
                        <Typography
                          variant="smallBody"
                          style={{ fontSize: 12 }}
                          className="mb-0.5 text-onSurface-500"
                        >
                          Continue Writing
                        </Typography>
                      )}
                      <TruncatedText
                        maxLength={60}
                        variant="strong"
                        className="mb-0.5"
                        hideActionButton
                      >
                        {buildAssignmentTitle(assignment)}
                      </TruncatedText>

                      <div className="flex items-center gap-1">
                        {config.tag}
                        {getDueDate(assignment)}
                      </div>
                    </div>
                  }
                  actionSlot={
                    <CheckRestrictions
                      accessStatus={assignment.accessStatus}
                      render={({ isRestricted }) => {
                        if (isLocked || isRestricted) return <Lock />

                        return (
                          <span className={clsx({ "self-end": isXs })}>
                            {getButtonAction(assignment, {
                              slug,
                              parentSlug,
                              ...config,
                            })}
                          </span>
                        )
                      }}
                    />
                  }
                />
              )
            })}
          </ItemList>
        ) : (
          <Fallback
            data={{
              image: HandHoldingPen,
              title: "No skill evidences (assignments) found!",
              description:
                "Make progress in your courses to unlock skill evidences (assignments)!",
            }}
            className="my-9 sm:my-20 md:my-18"
          />
        )}
      </div>
    </>
  )
}

export default CourseAssignments
