import { Typography } from "@suraasa/placebo-ui"
import { theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { InfiniteData } from "@tanstack/react-query"
import { NotificationResponse } from "api/resources/notifications/types"
import { PaginatedAPIResponse } from "api/types"
import { isThisWeek, isToday, isYesterday } from "date-fns"
import React from "react"

import NotificationRowCard from "../NotificationRowCard"
import styles from "./NotificationsList.module.css"

const NotificationsList = ({
  data: notificationList,
  mini,
  loading,
  hasNextPage,
  actionFunc,
  activeTabName,
}: {
  data: InfiniteData<PaginatedAPIResponse<NotificationResponse>>
  mini?: boolean
  activeTabName: string
  loading: boolean
  hasNextPage?: boolean
  actionFunc: () => void
}) => {
  const today = notificationList?.pages
    .map(page =>
      page.data.notifications.filter(notification =>
        isToday(new Date(notification.dateCreated))
      )
    )
    .flat()
  const yesterday = notificationList?.pages
    .map(page =>
      page.data.notifications.filter(notification =>
        isYesterday(new Date(notification.dateCreated))
      )
    )
    .flat()
  const thisWeek = notificationList?.pages
    .map(page =>
      page.data.notifications.filter(
        notification =>
          isThisWeek(new Date(notification.dateCreated)) &&
          !isYesterday(new Date(notification.dateCreated)) &&
          !isToday(new Date(notification.dateCreated))
      )
    )
    .flat()
  const earlier = notificationList?.pages
    .map(page =>
      page.data.notifications.filter(
        notification =>
          !isToday(new Date(notification.dateCreated)) &&
          !isYesterday(new Date(notification.dateCreated)) &&
          !isThisWeek(new Date(notification.dateCreated))
      )
    )
    .flat()

  const smUp = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <>
      {today.length > 0 && (
        <div className="flex flex-col">
          <Typography
            variant="smallBody"
            className="px-2 pb-1 pt-2 text-onSurface-400"
          >
            Today
          </Typography>
          {today.map((data, index) => {
            let isLastItem = false
            if (today.length === 10 && today.length === index + 1) {
              isLastItem = true
            }
            return (
              <NotificationRowCard
                key={index}
                loading={loading}
                hasNextPage={hasNextPage}
                actionFunc={actionFunc}
                activeTabName={activeTabName}
                mini={mini}
                listLength={today.length}
                index={index}
                notification={data}
                isLastItem={isLastItem}
              />
            )
          })}
        </div>
      )}
      {yesterday.length > 0 && (
        <div className="flex flex-col">
          {!smUp && <div className={styles.customDivider} />}
          <Typography
            variant="smallBody"
            className="px-2 pb-1 pt-2 text-onSurface-400"
          >
            Yesterday
          </Typography>
          {yesterday.map((data, index) => {
            let isLastItem = false
            if (
              thisWeek.length === 0 &&
              earlier.length === 0 &&
              yesterday.length === index + 1
            ) {
              isLastItem = true
            }
            return (
              <NotificationRowCard
                key={index}
                loading={loading}
                hasNextPage={hasNextPage}
                actionFunc={actionFunc}
                activeTabName={activeTabName}
                mini={mini}
                listLength={yesterday.length}
                index={index}
                notification={data}
                isLastItem={isLastItem}
              />
            )
          })}
        </div>
      )}
      {thisWeek.length > 0 && (
        <div className="flex flex-col">
          {!smUp && <div className={styles.customDivider} />}
          <Typography
            variant="smallBody"
            className="px-2 pb-1 pt-2 text-onSurface-400"
          >
            This Week
          </Typography>
          {thisWeek.map((data, index) => {
            let isLastItem = false
            if (earlier.length === 0 && thisWeek.length === index + 1) {
              isLastItem = true
            }
            return (
              <NotificationRowCard
                key={index}
                loading={loading}
                hasNextPage={hasNextPage}
                actionFunc={actionFunc}
                activeTabName={activeTabName}
                mini={mini}
                listLength={thisWeek.length}
                index={index}
                notification={data}
                isLastItem={isLastItem}
              />
            )
          })}
        </div>
      )}
      {earlier.length > 0 && (
        <div className="flex flex-col">
          {!smUp && <div className={styles.customDivider} />}
          <Typography
            variant="smallBody"
            className="px-2 pb-1 pt-2 text-onSurface-400"
          >
            Earlier
          </Typography>
          {earlier.map((data, index) => {
            let isLastItem = false
            if (earlier.length === index + 1) {
              isLastItem = true
            }
            return (
              <NotificationRowCard
                key={index}
                loading={loading}
                hasNextPage={hasNextPage}
                actionFunc={actionFunc}
                activeTabName={activeTabName}
                mini={mini}
                listLength={earlier.length}
                index={index}
                notification={data}
                isLastItem={isLastItem}
              />
            )
          })}
        </div>
      )}
    </>
  )
}
export default NotificationsList
