import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { ArrowRight } from "iconoir-react"
import { createUseStyles } from "react-jss"
import { getAuthInfo } from "utils/helpers"

const useStyles = createUseStyles(() => ({
  container: {
    filter: "drop-shadow(0px 8px 25px rgba(0, 0, 0, 0.15))",
  },
  logoContainer: {
    borderRadius: "50%",
    width: 40,
    height: 40,
    boxShadow:
      "-10px 10px 20px rgba(203, 203, 203, 0.2), 10px -10px 20px rgba(203, 203, 203, 0.2), -10px -10px 20px rgba(255, 255, 255, 0.9), 10px 10px 25px rgba(203, 203, 203, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(203, 203, 203, 0.5)",
  },
  designLogo: {
    position: "absolute",
    right: 60,
    height: "100%",
    top: 0,
  },
  subContainer: {
    height: "100%",
    background:
      "linear-gradient(120deg, #0C0C0C 50%, rgba(0,0,0,0) 30%), linear-gradient(84.96deg, #262626 0%, #1E1E1E 97.46%)",
    borderRadius: "0px 0px 16px 16px",
    color: "white",
  },
}))

const GOOGLE_FORM_LINK = (email: string) =>
  `https://suraasa.co/top100-cash-prize?email=${email}&utm_source=ITO%20Dashboard`

const CashPrizeCard = ({ prize = "" }) => {
  const classes = useStyles()
  const authInfo = getAuthInfo()

  return (
    <div
      className={clsx(
        classes.container,
        "relative rounded-2xl bg-gradient-to-tl from-[#050124] to-[#F9CD5E]  px-2 py-3 !text-white backdrop-blur-xl md:p-4"
      )}
    >
      <Typography className="mb-0.5" variant="preTitle">
        CASH PRIZE
      </Typography>
      <Typography variant="title2">{prize}</Typography>
      <img
        src="/assets/ito/2023/AfterResultPhase/YourRewards/circle-beads.svg"
        alt="beads"
        className={clsx(classes.designLogo, "hidden md:block")}
      />
      <Typography className="mt-3 max-w-[523px]">
        For being in the Top 100 participants from ITO 2023, you have won the
        cash prize of {prize}.
      </Typography>
      <Button
        endAdornment={<ArrowRight />}
        rounded
        component="a"
        target="_blank"
        href={GOOGLE_FORM_LINK(authInfo?.user.email ?? "")}
        className="mt-3 !w-full md:!w-auto"
        style={{
          background: "#655D63",
        }}
      >
        Share your details in this form
      </Button>
    </div>
  )
}

export default CashPrizeCard
