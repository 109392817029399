import "glider-js/glider.min.css"

import { Typography } from "@suraasa/placebo-ui"
import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Masterclass } from "api/resources/masterclasses/types"
import MasterClassPlaceholder from "assets/Placeholder/default-masterclass-thumbnail.svg"
import clsx from "clsx"
import { ArrowLeft, ArrowRight } from "iconoir-react"
import { useEffect, useState } from "react"
import Glider from "react-glider"
import { useTheme } from "react-jss"
import { getPlatformURL } from "utils/helpers"

import DynamicContainer, { CustomContainer } from "../DynamicContainer"

const Card = ({ slug, thumbnail }: Masterclass) => {
  return (
    <a
      href={getPlatformURL("suraasa", `/masterclasses/${slug}`)}
      className="relative me-3 overflow-hidden rounded-2xl border border-onSurface-200"
    >
      <div className="absolute left-0 top-0 z-10 size-full" />
      <img
        src={thumbnail || MasterClassPlaceholder}
        alt="thumbnail"
        className="w-full rounded-2xl shadow-sm"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src = MasterClassPlaceholder
        }}
      />
    </a>
  )
}

const RecentMasterclasses = () => {
  const masterclasses = useQuery({
    queryFn: () =>
      api.masterclasses.list({
        params: {
          ordering: "-start_time",
        },
      }),
    queryKey: queries.masterclasses.masterclassesList().queryKey,
  })
  const [currentSlide, setCurrentSlide] = useState(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setForceRender] = useState(0)

  const theme = useTheme<Theme>()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const GliderContainer = isSmDown ? "div" : CustomContainer

  const initGliderListeners = () => {
    const gliderElement = document.getElementById("glider")
    if (!gliderElement) return
    const listener = (event: any) => {
      setCurrentSlide(event.detail.slide)
    }
    gliderElement.addEventListener("glider-slide-visible", listener)

    return () => {
      gliderElement.removeEventListener("glider-slide-visible", listener)
    }
  }

  useEffect(() => {
    const listener = () => {
      const anchor = document.querySelector("#anchor-ref")
      const sliderContainer = document.querySelector(
        "#slider-container"
      ) as HTMLDivElement

      if (!anchor || !sliderContainer) return

      sliderContainer.style.marginLeft = `${anchor.getBoundingClientRect().x}px`
    }

    listener()

    window.addEventListener("resize", listener)

    return () => {
      window.removeEventListener("resize", listener)
    }
  }, [masterclasses.isSuccess])

  if (!masterclasses.isSuccess) {
    return null
  }

  const isLeftArrowDisabled = currentSlide === 0
  const isRightArrowDisabled = currentSlide === masterclasses.data.length - 1

  return (
    <div>
      <DynamicContainer>
        <div
          id="anchor-ref"
          className="mb-[19px] flex flex-wrap items-center justify-between lg:pe-5"
        >
          <Typography variant="title3">Recent Masterclasses</Typography>
          <Button
            variant="text"
            component="a"
            href={getPlatformURL("suraasa", "/masterclasses/all")}
          >
            View More
          </Button>
        </div>
      </DynamicContainer>

      <GliderContainer
        id="slider-container"
        className="mb-4 overflow-x-hidden rounded-l-2xl sm:mb-0"
      >
        <Glider
          id="glider"
          hasArrows
          onLoad={() => {
            initGliderListeners()
            setForceRender(x => x + 1)
          }}
          draggable={true}
          slidesToShow={3.5}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 3000,
              settings: {
                slidesToShow: 5.1,
              },
            },
            {
              breakpoint: 2500,
              settings: {
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 4.1,
              },
            },
            {
              breakpoint: 1560,
              settings: {
                slidesToShow: 3.1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2.1,
              },
            },
            {
              breakpoint: 300,
              settings: {
                slidesToShow: 1.1,
              },
            },
          ]}
          arrows={{
            prev: "#buttonPrev",
            next: "#buttonNext",
          }}
        >
          {masterclasses.data.map((masterclass, i) => (
            <Card key={i} {...masterclass} />
          ))}
        </Glider>
      </GliderContainer>

      {isSmUp && (
        <DynamicContainer className="mb-8 mt-3">
          <div className="flex items-center justify-between lg:pe-5">
            <div className="relative h-0.75 w-[100px] rounded-lg border border-solid border-onSurface-200 bg-white">
              <div
                className="absolute left-0 h-0.5 w-6 rounded-lg bg-[#050124] transition-[left] duration-150 ease-linear"
                style={{
                  left: `${(currentSlide / masterclasses.data.length) * 52}%`,
                }}
              />
            </div>
            <div className="flex items-center">
              <button
                disabled={isLeftArrowDisabled}
                id="buttonPrev"
                className={clsx(
                  "me-1 flex size-4 items-center justify-center !rounded-full bg-onSurface-900 p-0.5 text-white lg:me-1",
                  { "bg-surface-200 !text-onSurface-400": isLeftArrowDisabled }
                )}
              >
                <ArrowLeft strokeWidth={2.5} />
              </button>
              <button
                id="buttonNext"
                disabled={isRightArrowDisabled}
                className={clsx(
                  "flex size-4 items-center justify-center !rounded-full bg-onSurface-900 p-0.5 text-white",
                  { "bg-surface-200 !text-onSurface-400": isRightArrowDisabled }
                )}
              >
                <ArrowRight strokeWidth={2.5} />
              </button>
            </div>
          </div>
        </DynamicContainer>
      )}
    </div>
  )
}

export default RecentMasterclasses
