import React from "react"
import { MBToBytes } from "utils/helpers"

type FileType = FileList | null | string

type Props = {
  /**
   * Example: [".pdf", ".xlsx", ".docx"]
   */
  allowedExtensions?: string[]
  /**
   * In Mega Bytes.
   * Default: 1 MB
   */
  maxSize?: number
  limit?: number
  disabled?: boolean
  onChange: (files: File[]) => void
  onError?: (errors: string[]) => void
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "onChange" | "className" | "type" | "onError"
>

const MIME_TYPES = {
  ".doc": "application/msword",
  ".docx":
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ".pdf": "application/pdf",
  ".png": "image/png",
  ".jpg": "image/jpeg",
  ".jpeg": "image/jpeg",
}

const buildAcceptAttribute = (extensions: string[]) => {
  const accept: string[] = []
  extensions.forEach(e => {
    if (e in MIME_TYPES) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore this is safe because there is a check above
      accept.push(MIME_TYPES[e] as string)
    } else {
      console.warn(`>> MIME_TYPE not mapped for ${e} extension`)
    }
  })

  return accept.join(",")
}

const FileInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      allowedExtensions = [],
      onError,
      limit = 1,
      maxSize = 1,
      disabled = false,
      onChange,
      ...props
    },
    ref
  ) => {
    const areFilesValid = (files: FileType): boolean => {
      if (!files || typeof files === "string") return false

      if (files.length === 0) return false

      const fileExt = files[0].name.split(".").pop()?.toLowerCase()
      const fileSize = files[0].size

      if (!fileExt) return false

      console.log({ fileExt, fileSize: fileSize / 1000000 })

      if (
        allowedExtensions.length > 0 &&
        !allowedExtensions.includes(`.${fileExt}`)
      ) {
        if (onError) onError(["Invalid file format"])
        return false
      }

      if (fileSize > MBToBytes(maxSize)) {
        if (onError) onError(["File size is too large"])
        return false
      }

      return true
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length !== 0) {
        if (areFilesValid(e.target.files)) {
          onChange(Array.from(e.target.files as FileList).slice(0, limit))
        }
      }
    }

    return (
      <input
        accept={buildAcceptAttribute(allowedExtensions)}
        disabled={disabled}
        multiple={limit > 1}
        {...props}
        className="hidden"
        ref={ref}
        type="file"
        onChange={handleChange}
      />
    )
  }
)

FileInput.displayName = "FileInput"

export default FileInput
