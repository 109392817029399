import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  ButtonComponentProps,
  Divider,
  IconButton,
  TextField,
} from "@suraasa/placebo-ui-legacy"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { RetrievePrompt, ToolType } from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import LikeSvg from "assets/AITools/LikeSvg"
import clsx from "clsx"
import ErrorMessage from "components/ErrorMessage"
import {
  Check,
  Copy,
  NavArrowLeft,
  NavArrowRight,
  Refresh,
} from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { Controller, useForm } from "react-hook-form"

import AiToolsLoading from "../../AiToolsLoading"
import { AIToolsContext } from "../context"
import GenerateButton from "./GenerateButton"

const Vote = {
  LIKED: true,
  DISLIKED: false,
  NEUTRAL: null,
}
enum RegenerationInstructionsEnum {
  MAKE_IT_SHORT = "make_it_short",
  MAKE_IT_LONG = "make_it_detailed",
  MAKE_IT_SIMPLE = "use_simpler_language",
  MAKE_THE_FORMAT_MORE_CASUAL = "make_it_more_casual",
  MAKE_THE_FORMAT_MORE_FORMAL = "make_it_more_professional",
  OTHERS = "others",
}

const regenerationInstructions = [
  { title: "Make It short", value: RegenerationInstructionsEnum.MAKE_IT_SHORT },
  {
    title: "Make it detailed",
    value: RegenerationInstructionsEnum.MAKE_IT_LONG,
  },
  {
    title: "Make it simple",
    value: RegenerationInstructionsEnum.MAKE_IT_SIMPLE,
  },
  {
    title: "Make the format more casual",
    value: RegenerationInstructionsEnum.MAKE_THE_FORMAT_MORE_CASUAL,
  },
  {
    title: "Make the format more formal",
    value: RegenerationInstructionsEnum.MAKE_THE_FORMAT_MORE_FORMAL,
  },
  { title: "Others", value: RegenerationInstructionsEnum.OTHERS },
]

export type RegenerateOutputProps = {
  responseIds?: number[]
  currentResponseId?: number
  onResponseIdChange?: (responseId: number) => void
  refetchOverviewData?: () => void
  type?: ToolType
  regenerate?: {
    regenerateResponse?: (reason: string) => void
    isLoading?: boolean
    isError?: boolean
    isSuccess?: boolean
  }
  userReaction?: {
    userReaction?: (reaction: boolean | null | undefined) => void
  }
}

const RegenerateOutput = ({
  copyText,
  isPositiveResponse,
  ...props
}: RegenerateOutputProps & {
  copyText?: string
  isPositiveResponse?: boolean | null
}) => {
  const context = useContext(AIToolsContext)

  const queryClient = useQueryClient()

  // queries.aiTools.retrieveHistory(
  //   ToolType.assignment,
  //   currentResponseId
  // ).queryKey
  const responseIds = props.responseIds || context.responseIds
  const currentResponseId = props.currentResponseId || context.currentResponseId
  const onResponseIdChange =
    props.onResponseIdChange || context.onResponseIdChange
  const refetchOverviewData =
    props.refetchOverviewData || context.refetchOverviewData
  const type = props.type || context.type

  const [userVote, setUserVote] = useState<boolean | null | undefined>()
  const [generateNew, setGenerateNew] = useState(false)

  const [copied, setCopied] = useState(false)

  const {
    reset,
    watch,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    instructions: RegenerationInstructionsEnum | null
    customInstruction: string
  }>({ defaultValues: { instructions: null, customInstruction: "" } })

  const instruction = watch("instructions")

  const {
    mutate: regenerateResponse,
    isLoading: regenIsLoading,
    isSuccess: regenIsSuccess,
    isError: regenIsError,
  } = useMutation({
    mutationFn: (data: string) =>
      api.aiTools.regenerateResponse({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: currentResponseId!,
          type: type,
        },
        data: {
          regenerateInstruction: data,
        },
      }),
    onSuccess: () => {
      refetchOverviewData()
    },
  })

  const userReaction = useMutation({
    mutationFn: (reaction: boolean | null | undefined) =>
      api.aiTools.updateUserReaction({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: currentResponseId!,
          type: type,
        },
        data: {
          isPositiveResponse: reaction,
        },
      }),
    onSuccess: () => {
      queryClient.setQueryData(
        queries.aiTools.retrieveHistory(type, currentResponseId).queryKey,
        (oldQueryData?: APIResponse<RetrievePrompt<typeof type>>) => {
          if (oldQueryData) {
            const oldDataCopy: APIResponse<RetrievePrompt<typeof type>> =
              JSON.parse(JSON.stringify(oldQueryData))

            if ("isPositiveResponse" in oldDataCopy)
              oldDataCopy.isPositiveResponse = userVote
            return oldDataCopy
          }
          return oldQueryData
        }
      )
    },
  })

  const regenerate = props.regenerate?.regenerateResponse || regenerateResponse
  const isLoading = props.regenerate?.isLoading || regenIsLoading
  const isSuccess = props.regenerate?.isSuccess || regenIsSuccess
  const isError = props.regenerate?.isError || regenIsError

  const mutateUserReaction =
    props.userReaction?.userReaction || userReaction.mutate

  useEffect(() => {
    setUserVote(isPositiveResponse)
  }, [isPositiveResponse])

  const clearState = () => {
    reset({ instructions: null, customInstruction: "" })
    setGenerateNew(false)
  }
  const upVote = () => {
    mutateUserReaction(Vote.LIKED)
    setUserVote(Vote.LIKED)
    clearState()
  }
  const downVote = () => {
    mutateUserReaction(Vote.DISLIKED)
    setUserVote(Vote.DISLIKED)
  }
  function copyToClipboard() {
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  const onSubmit = handleSubmit(data => {
    const finalInstruction =
      data.instructions &&
      data.instructions !== RegenerationInstructionsEnum.OTHERS
        ? data.instructions
        : data.customInstruction

    regenerate(finalInstruction)
  })

  return (
    <>
      <AiToolsLoading type={type} open={isLoading} />

      <div className=" bg-surface-50 px-3 py-2">
        <div className="flex flex-col items-center gap-1 sm:flex-row ">
          {responseIds && responseIds.length > 0 && (
            <div className="flex items-center gap-0.5">
              <IconButton
                disabled={
                  currentResponseId !== undefined
                    ? responseIds.indexOf(currentResponseId) ===
                      responseIds.length - 1
                    : false
                }
                size="xs"
                color="black"
                onClick={() => {
                  if (onResponseIdChange && currentResponseId !== undefined) {
                    const currentIndex = responseIds.indexOf(currentResponseId)

                    if (currentIndex < responseIds.length - 1) {
                      setUserVote(isPositiveResponse)
                      onResponseIdChange(responseIds[currentIndex + 1])
                    }
                  }
                }}
              >
                <NavArrowLeft width={20} height={20} />
              </IconButton>

              <span>
                {currentResponseId &&
                  responseIds.length - responseIds.indexOf(currentResponseId)}
                /{responseIds.length}
              </span>
              <IconButton
                size="xs"
                color="black"
                disabled={
                  currentResponseId !== undefined
                    ? responseIds[0] === currentResponseId
                    : false
                }
                onClick={() => {
                  if (onResponseIdChange && currentResponseId !== undefined) {
                    const currentIndex = responseIds.indexOf(currentResponseId)

                    if (currentIndex > 0) {
                      setUserVote(isPositiveResponse)
                      onResponseIdChange(responseIds[currentIndex - 1])
                    }
                  }
                }}
              >
                <NavArrowRight width={20} height={20} />
              </IconButton>
            </div>
          )}
          <span className="text-left text-xs font-normal leading-[14.52px]">
            How was this response?
          </span>
          <div className="flex items-center gap-1">
            <IconButton onClick={upVote}>
              <LikeSvg
                className={clsx({
                  "fill-onSurface-900": userVote === Vote.LIKED,
                })}
              />
            </IconButton>
            <IconButton onClick={downVote}>
              <LikeSvg
                className={clsx("-scale-100", {
                  "fill-onSurface-900": userVote === Vote.DISLIKED,
                })}
              />
            </IconButton>
            <IconButton
              color="black"
              onClick={() => {
                setGenerateNew(true)
              }}
            >
              <Refresh width={20} height={20} className="rotate-90" />
            </IconButton>
            {copyText && (
              <CopyToClipboard
                text={copyText || ""}
                onCopy={() => copyToClipboard()}
              >
                <IconButton disabled={!copyText} color="black">
                  {copied ? (
                    <Check
                      width={20}
                      height={20}
                      className="text-success-600"
                    />
                  ) : (
                    <Copy width={20} height={20} className="rotate-90" />
                  )}
                </IconButton>
              </CopyToClipboard>
            )}
          </div>
        </div>
        {generateNew && (
          <form>
            <Divider className="my-1.5" weight="light" />
            <div className="flex flex-col">
              <Typography variant="smallBody" className="mb-1">
                Give additional instructions for regeneration
              </Typography>
              <Controller
                control={control}
                name="instructions"
                render={({ field: { value, onChange, onBlur } }) => (
                  <div className="flex flex-wrap items-center gap-1">
                    {regenerationInstructions.map(item => (
                      <ChipButton
                        key={item.value}
                        onBlur={onBlur}
                        isActive={value === item.value}
                        onClick={(e: { preventDefault: () => void }) => {
                          e.preventDefault()
                          onChange(item.value)
                        }}
                      >
                        {item.title}
                      </ChipButton>
                    ))}
                  </div>
                )}
                rules={{
                  required: {
                    value: true,
                    message: "Please select a valid option.",
                  },
                }}
              />
              {errors.instructions && (
                <ErrorMessage
                  className="mt-0.5"
                  title={errors.instructions.message}
                />
              )}

              {instruction === RegenerationInstructionsEnum.OTHERS && (
                <form>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <TextField
                    multiLine
                    fullWidth
                    className="mt-2"
                    inputLabelProps={{ required: true }}
                    error={Boolean(errors.customInstruction)}
                    helperText={errors.customInstruction?.message}
                    {...register("customInstruction", {
                      required: { value: true, message: "Required" },
                    })}
                    rows={3}
                    min={0}
                    max={10}
                  />
                </form>
              )}
              <GenerateButton
                loading={isLoading}
                label="Regenerate"
                className="mt-2 w-fit"
                onClick={onSubmit}
              />
            </div>
          </form>
        )}

        {!generateNew && userVote === Vote.DISLIKED && (
          <>
            <Divider className="my-1.5" weight="light" />
            <div>
              <Typography className="inline" variant="smallBody">
                Want to regenerate the result?
              </Typography>
              <Button
                variant="text"
                onClick={() => {
                  setGenerateNew(true)
                }}
                className="!outline-0"
              >
                Click here
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

const ChipButton = ({
  className,
  isActive,
  ...props
}: ButtonComponentProps<any> & { isActive?: boolean }) => (
  <button
    {...props}
    className={clsx(
      className,
      "rounded-full border border-solid  px-1.5 py-0.5 text-left text-sm font-medium leading-[18.2px] text-onSurface-800",
      {
        "bg-onSurface-800 border-onSurface-800 text-white": isActive,
        "bg-transparent border-onSurface-400 text-onSurface-800": !isActive,
      }
    )}
  />
)

export default RegenerateOutput
