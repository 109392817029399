import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("tia", {
  eligibility: () => ({
    queryKey: ["eligibility"],
  }),
  submissionDetails: () => ({
    queryKey: ["submissionDetails"],
  }),
})
