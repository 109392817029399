import { Typography } from "@suraasa/placebo-ui"
import { Button, Tag, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import queries from "api/resources/learningItems/queries"
import {
  LearningItemType,
  ScheduleType,
} from "api/resources/learningItems/types"
import FullWidthButtonCard from "components/FullWidthButtonCard"
import { format } from "date-fns"
import CheckRestrictions from "features/AccessManagement/CheckRestrictions"
import {
  reloadOnAssessmentClose,
  startAssessment,
} from "features/Assessments/helpers"
import { Calendar, Clock } from "iconoir-react"
import { useTheme } from "react-jss"
import { formatDate } from "utils/helpers"

const Timeline = ({ startTime }: { startTime: string }) => (
  <div className="flex">
    <div className="me-2 flex items-center">
      <Calendar className="me-1" />
      <Typography variant="strongSmallBody">
        {formatDate(new Date(startTime).toISOString(), "LLL dd, Y")}
      </Typography>
    </div>
    <div className="flex items-center">
      <Clock className="me-1" />
      <Typography variant="strongSmallBody">
        {formatDate(new Date(startTime).toISOString(), "p")}
      </Typography>
    </div>
  </div>
)

const NextUp = ({
  learningItemType,
  learningItemId,
}: {
  learningItemType: LearningItemType
  learningItemId: number
}) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const { data, isSuccess } = useQuery({
    queryFn: () =>
      api.learningItems.getUpcomingPlannerItems({
        urlParams: { learningItemType, learningItemId },
      }),
    queryKey: queries.upcomingPlannerItem(learningItemType, learningItemId)
      .queryKey,
  })

  if (!isSuccess) return null

  if (data.length === 0) return null

  const upcomingAssessment = data.find(
    upNext => upNext.scheduleType === ScheduleType.ASSESSMENT
  )

  const upcomingClass = data.find(
    upNext => upNext.scheduleType === ScheduleType.CLASS
  )

  return (
    <div>
      <Typography variant="title3" className="mb-2">
        Next Up
      </Typography>
      {upcomingClass && upcomingClass.scheduleType === ScheduleType.CLASS && (
        <CheckRestrictions
          accessStatus={upcomingClass.accessStatus}
          render={({ isRestricted }) => (
            <FullWidthButtonCard
              className="mb-3"
              buttonProps={{
                label: upcomingClass.videoMeeting?.joiningUrl
                  ? "Join"
                  : `Scheduled for ${format(
                      new Date(upcomingClass.startTime),
                      "d MMMM yyyy, p"
                    )}`,
                component: "a",
                target: "_blank",
                disabled:
                  isRestricted || !upcomingClass.videoMeeting?.joiningUrl,
                href: upcomingClass.videoMeeting?.joiningUrl,
              }}
            >
              <div>
                <div className="mb-0.5 flex">
                  <Typography
                    variant="title4"
                    className="me-1 text-onSurface-800"
                  >
                    Class {upcomingClass.classId.name}
                  </Typography>
                </div>
                <div className="me-1 mt-1">
                  <Typography
                    variant="smallBody"
                    className="mb-0.5 text-onSurface-500"
                  >
                    {formatDate(upcomingClass.startTime, "do MMMM Y, p")}
                  </Typography>
                  <Typography
                    variant="smallBody"
                    className="text-onSurface-500"
                  >
                    Conducted by Prof. {upcomingClass.professor.fullName}
                  </Typography>
                </div>
                {upcomingClass.lectures.length > 0 && (
                  <>
                    <Typography className="my-1 me-2" variant="strong">
                      Topics Covered:
                    </Typography>
                    <div>
                      {upcomingClass.lectures.map((lecture, index) => {
                        const [tag, name] = lecture.name.split(":")
                        return (
                          <div key={index} className="mb-0.5 flex items-center">
                            <Tag
                              color="onSurface"
                              className="me-2 shrink-0 uppercase"
                              label={tag}
                            />
                            <p>{name}</p>
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}
              </div>
            </FullWidthButtonCard>
          )}
        />
      )}

      {upcomingAssessment &&
        upcomingAssessment.scheduleType === ScheduleType.ASSESSMENT && (
          <CheckRestrictions
            accessStatus={upcomingAssessment.accessStatus}
            render={({ isRestricted }) => (
              <div className="flex flex-col justify-between overflow-hidden rounded-2xl border border-surface-200 bg-surface-500 sm:flex-row sm:items-center sm:p-2">
                <div className="p-2 sm:p-0">
                  <Typography variant="title4" className="mb-1">
                    {upcomingAssessment.assessment.title}
                  </Typography>
                  <Timeline startTime={upcomingAssessment.startTime} />
                </div>
                <Button
                  fullWidth={isXs}
                  style={{
                    borderRadius: isXs ? 0 : 4,
                  }}
                  disabled={isRestricted}
                  onClick={() =>
                    startAssessment({
                      assessmentId: upcomingAssessment.assessment.uuid,
                      onClose: reloadOnAssessmentClose,
                    })
                  }
                >
                  Attempt Assessment
                </Button>
              </div>
            )}
          />
        )}
    </div>
  )
}

export default NextUp
