import { SheetBody, toast, Typography } from "@suraasa/placebo-ui"
import { Button, LinearProgress } from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import axios from "axios"
import clsx from "clsx"
import FileUpload from "components/FileUpload"
import VideoPlayer from "components/VideoPlayer"
import { useState } from "react"
import { generateObjectURL, handleErrors } from "utils/helpers"

import {
  BYTES_IN_1_MB,
  MAX_VIDEO_PORTFOLIO_DURATION,
  MAX_VIDEO_PORTFOLIO_SIZE,
  VIDEO_PORTFOLIO_FORMATS,
} from "../config"
import Instructions from "../Instructions"
import TopBar from "../TopBar"
import styles from "./Upload.module.css"

type Props = {
  onUpload: (localURL: string, serverURL: string) => void
  className?: string
}

const CancelToken = axios.CancelToken
let cancelAxiosRequest: () => void

const Upload = ({ className, onUpload }: Props) => {
  const [fileName, setFileName] = useState<string>("")
  const [fileURL, setFileURL] = useState<string>("")

  const [uploadPercentage, setUploadPercentage] = useState(0)

  const { mutate: uploadVideo } = useMutation({
    onSuccess: ({ videoPortfolio }) => {
      onUpload(fileURL, videoPortfolio)
    },

    mutationFn: async (file: File) => {
      const formData = new FormData()
      formData.append("video_portfolio", file)
      setFileName(file.name)

      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent: ProgressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          if (percent <= 100 && percent > 0) {
            setUploadPercentage(percent)
          }
        },
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelAxiosRequest = c
        }),
      }
      return api.profile.videoPortfolio.create({
        data: formData,
        headers: options,
      })
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const handleFileUpload = (file: File) => {
    const localURL = generateObjectURL(file)
    const video = document.createElement("video")

    video.setAttribute("src", localURL)
    setFileURL(localURL)

    video.onloadeddata = function (event: any) {
      const { duration } = event.target

      // duration can sometimes be Infinity for very short videos

      if (!isFinite(duration)) {
        console.error("File is corrupted")
        toast.error("File is corrupted")

        return
      }

      if (duration > MAX_VIDEO_PORTFOLIO_DURATION * 60) {
        console.error(
          `Video duration cannot be greater than ${MAX_VIDEO_PORTFOLIO_DURATION} minutes`
        )
        toast.error(
          `Video duration cannot be greater than ${MAX_VIDEO_PORTFOLIO_DURATION} minutes`
        )

        return
      }

      uploadVideo(file)
    }
  }

  const topBarAction = (className?: string) => {
    if (uploadPercentage > 0 && uploadPercentage <= 100) {
      return null
    }

    return (
      <FileUpload
        allowedExtensions={VIDEO_PORTFOLIO_FORMATS}
        onFileUpload={handleFileUpload}
        maxSize={MAX_VIDEO_PORTFOLIO_SIZE * BYTES_IN_1_MB}
        inputComponent={({ onClick, errors }) => (
          <div className={className}>
            <Button onClick={onClick}>Upload Video</Button>
            {errors.map((error, i) => (
              <div className={clsx(styles.errors, "mt-0.5")} key={i}>
                <Typography variant="smallBody">{error}</Typography>
              </div>
            ))}
          </div>
        )}
      />
    )
  }

  const getContent = () => {
    if (uploadPercentage > 0 && uploadPercentage <= 100) {
      return (
        <>
          <div className="relative">
            <div className="absolute size-full rounded-xl bg-black opacity-50" />
            <div className="absolute inset-x-0 bottom-[50%] mx-auto px-2">
              <Typography variant="smallBody" className="mb-2 text-surface-500">
                {fileName}
              </Typography>
              <div className="flex items-center">
                <LinearProgress
                  className={clsx(styles.customLinearProgress, "me-0.5 grow")}
                  value={uploadPercentage}
                />
                <Typography className="text-surface-500">
                  {uploadPercentage}%
                </Typography>
              </div>
            </div>

            <VideoPlayer disabled src={fileURL} thumbnail="" />
          </div>
          <Button
            variant="text"
            className="mx-auto my-2"
            style={{
              display: "block",
            }}
            color="critical"
            onClick={() => {
              if (cancelAxiosRequest) {
                cancelAxiosRequest()
                setUploadPercentage(0)
              }
            }}
          >
            Cancel Upload
          </Button>
        </>
      )
    }

    return (
      <>
        <Instructions />
        <Typography variant="title3" className="mb-1.5">
          Sample Video
        </Typography>
        <VideoPlayer
          src="https://assets.suraasa.com/video-portfolio/sample-video.mp4"
          className="mb-10 rounded-2xl"
        />
        {/* {isXsDown && topBarAction("sticky bottom-2")} */}
      </>
    )
  }

  return (
    <>
      <TopBar>{topBarAction()}</TopBar>
      <SheetBody>{getContent()}</SheetBody>
    </>
  )
}

export default Upload
