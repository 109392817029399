import { toast, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Checkbox,
  CircularProgress,
  Select,
  TextField,
} from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  LessonPlanForm,
  ListPromptHistory,
  RetrievePrompt,
  ToolType,
} from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import {
  BLOOMS_TAXONOMY_OPTIONS,
  DURATION_MINUTES,
  getToolName,
} from "features/AItools/helper"
import { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import routes from "routes"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import AiToolsLoading from "../../AiToolsLoading"
import Heading from "../../Heading"
import Paper from "../../Paper"
import TogglePro from "../../TogglePro"
import { AIToolsContext } from "../context"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import LessonPlanOutput from "../GenerationOutput/LessonPlanOutput"
import LessonPlanProOutput from "../GenerationOutput/LessonPlanProOutput"
import ProForm from "./ProForm"

const toolType = ToolType.lessonPlan

type Form = LessonPlanForm & {
  generateHandout: boolean
  generateNarration: boolean
}

const LessonGenerator = ({
  id,
  onGeneration,
}: {
  id?: string
  onGeneration: (data: ListPromptHistory) => void
}) => {
  const navigate = useNavigate()
  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<Form>()

  const [enableProMode, setEnableProMode] = useState(false)

  const { currentResponseId, overviewData, promptDataId, setPromptDataId } =
    useContext(AIToolsContext)

  const [content, setContent] = useState<
    | RetrievePrompt<typeof toolType>
    | RetrievePrompt<ToolType.lessonPlanPro>
    | null
  >()

  useEffect(() => {
    ;(async () => {
      if (!currentResponseId) return
      try {
        const res = await api.aiTools.retrieveContent<typeof toolType>({
          urlParams: { type: toolType, id: currentResponseId! },
        })
        setContent(res)
      } catch (e) {
        setContent(null)
        if (e instanceof APIError) {
          toast.error(
            e.errors.message || `Unable to fetch ${getToolName(toolType)}`
          )
        }
      }
    })()
  }, [currentResponseId])

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: LessonPlanForm) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
      })
      return api.aiTools.generateLessonPlan({ data })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
      })
      onGeneration({
        dateCreated: new Date().toString(),
        promptDataId: res.id,
        title: res.title,
        toolType: toolType,
      })
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) > 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const onSubmit = handleSubmit(async data => {
    mutate(data)
  })

  if (id && content === undefined) {
    return (
      <div className="flex flex-col items-center justify-center p-5">
        <CircularProgress />
      </div>
    )
  }

  if (content && promptDataId) {
    return (
      <>
        <ContentActions
          onBack={() => {
            navigate(routes.aiTools.lessonPlan, { replace: true })
            setPromptDataId(null)
          }}
          currentResponseId={currentResponseId}
          toolType={toolType}
          title={overviewData?.title}
        />
        {content.isPro ? (
          <LessonPlanProOutput
            {...(content as RetrievePrompt<ToolType.lessonPlanPro>)}
          />
        ) : (
          <LessonPlanOutput
            {...(content as RetrievePrompt<typeof toolType>)}
            autoGenerateHandout={getValues("generateHandout")}
            autoGenerateNarration={getValues("generateNarration")}
            resetForm={reset}
          />
        )}
      </>
    )
  }

  return (
    <>
      <Heading
        title={`${getToolName(toolType)} Generator`}
        subtitle="Craft comprehensive lesson plans effortlessly, using just a few keywords."
      />
      <TogglePro
        isPro={enableProMode}
        onChange={state => setEnableProMode(state)}
        className="mb-2"
      />
      {enableProMode ? (
        <ProForm
          onGeneration={res => {
            onGeneration({
              dateCreated: new Date().toString(),
              promptDataId: res.id,
              title: res.title,
              toolType: ToolType.lessonPlanPro,
            })
          }}
        />
      ) : (
        <Paper>
          <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
            <div className="flex flex-wrap gap-2 md:flex-nowrap">
              <TextField
                inputLabelProps={{ required: true }}
                label="Class/Grade"
                fullWidth
                placeholder="Ex: 10th Grade"
                error={Boolean(errors.grade)}
                helperText={errors.grade?.message}
                {...register("grade", {
                  required: { value: true, message: "Required" },
                })}
              />

              <TextField
                {...register("curriculum", {
                  required: { value: true, message: "Required" },
                })}
                inputLabelProps={{ required: true }}
                error={Boolean(errors.curriculum)}
                label="Curriculum"
                fullWidth
                placeholder="Ex: IB"
                helperText={errors.curriculum?.message}
              />
            </div>
            <div className="flex flex-wrap gap-2 md:flex-nowrap">
              <TextField
                inputLabelProps={{ required: true }}
                label="Subject"
                fullWidth
                placeholder="Ex: Chemistry"
                error={Boolean(errors.subject)}
                helperText={errors.subject?.message}
                {...register("subject", {
                  required: { value: true, message: "Required" },
                })}
              />
              <Controller
                control={control}
                name="duration"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    inputLabelProps={{ required: true }}
                    label="Class Duration"
                    error={Boolean(errors.duration)}
                    helperText={errors.duration?.message}
                    options={DURATION_MINUTES}
                    placeholder="60 minutes"
                    value={DURATION_MINUTES.find(c => c.value === value)}
                    fullWidth
                    mountOnBody
                    onBlur={onBlur}
                    onChange={val => onChange(val?.value)}
                  />
                )}
                rules={{ required: { value: true, message: "Required" } }}
              />
            </div>
            {/* @ts-expect-error: placebo rows prop error */}
            <TextField
              multiLine
              inputLabelProps={{ required: true }}
              fullWidth
              rows={3}
              label="topic"
              placeholder="Ex: Analyzing the Chemical Properties and Reactions of Acids and Bases, with a Focus on pH, Neutralization, and Their Applications in Everyday Life"
              error={Boolean(errors.topic)}
              helperText={errors.topic?.message}
              {...register("topic", {
                required: { value: true, message: "Required" },
              })}
            />
            <div className="flex flex-wrap gap-2 md:flex-nowrap">
              <TextField
                {...register("country", {
                  required: { value: true, message: "Required" },
                })}
                inputLabelProps={{ required: true }}
                error={Boolean(errors.country)}
                label="Country"
                fullWidth
                placeholder="Ex: United Arab Emirates"
                helperText={
                  errors.country?.message ||
                  "We use this to tailor the lesson plan to your region"
                }
              />
              <div className="flex flex-[50%] shrink-0 flex-col">
                <Controller
                  control={control}
                  name="bloomTaxonomyLevel"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      inputLabelProps={{ required: true }}
                      isMulti
                      closeMenuOnSelect={false}
                      label="Bloom's Taxonomy Level"
                      error={Boolean(errors.bloomTaxonomyLevel)}
                      helperText={errors.bloomTaxonomyLevel?.message}
                      options={BLOOMS_TAXONOMY_OPTIONS}
                      placeholder="Ex: Analyse, Apply"
                      value={
                        value &&
                        BLOOMS_TAXONOMY_OPTIONS.filter(c =>
                          value.includes(c.value)
                        )
                      }
                      fullWidth
                      mountOnBody
                      onBlur={onBlur}
                      onChange={val => onChange(val.map(x => x.value))}
                    />
                  )}
                  rules={{ required: { value: true, message: "Required" } }}
                />
                <Link
                  to="https://www.suraasa.com/blog/learning-taxonomies"
                  target="_blank"
                >
                  <Typography
                    className="mt-0.5 text-interactive-500 hover:underline"
                    variant="smallBody"
                  >
                    What is Bloom&apos;s Taxonomy?
                  </Typography>
                </Link>
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <Controller
                  control={control}
                  name="generateHandout"
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      className="items-end font-semibold"
                      label="Generate Handout"
                      checked={value}
                      onChange={e => onChange(e.target.checked)}
                    />
                  )}
                />
                <Typography
                  variant="smallBody"
                  className="ms-3.5 mt-0.5 text-onSurface-500"
                >
                  Handouts are supplementary materials that help students
                  understand the lesson better.
                </Typography>
              </div>
              <div className="mt-2 flex flex-col">
                <Controller
                  control={control}
                  name="generateNarration"
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      className="items-end font-semibold"
                      label="Generate Narration"
                      checked={value}
                      onChange={e => onChange(e.target.checked)}
                    />
                  )}
                />
                <Typography
                  variant="smallBody"
                  className="ms-3.5 mt-0.5 text-onSurface-500"
                >
                  Narration is a concise guide outlining how a teacher will
                  teach, including objectives, strategies, materials,
                  assessments, and closure.
                </Typography>
              </div>
            </div>
            <Button fullWidth type="submit" loading={isLoading}>
              Generate Lesson Plan
            </Button>
          </form>
          <AiToolsLoading
            type={toolType}
            open={isLoading}

            // onClose={() => {
            //   setLoading(false)
            // }}
          />
        </Paper>
      )}
    </>
  )
}

export default LessonGenerator
