import api from "api"
import { USER_TYPE } from "utils/constants"

export const processAuthCode = async (code: string, platform = USER_TYPE) => {
  return api.users.getUserToken({
    data: {
      code,
      platform,
    },
  })
}
