import { Avatar, Typography } from "@suraasa/placebo-ui"
import {
  Container,
  IconButton,
  MenuItem,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { MenuDivider } from "@szhsin/react-menu"
import AISparkle from "assets/icons/system/AISparkle"
import Bell from "assets/icons/system/Bell"
import BellRing from "assets/icons/system/BellRing"
import BookClosed from "assets/icons/system/BookClosed"
import BookOpenNew from "assets/icons/system/BookOpen"
import HomeLine from "assets/icons/system/HomeLine"
import HomeSmile from "assets/icons/system/HomeSmile"
import JobsSearch from "assets/icons/system/JobsSearch"
import User from "assets/icons/system/User"
import UserCircle from "assets/icons/system/UserCircle"
import ITOLogoGrey from "assets/logos/ito-mini-grey.svg"
import clsx from "clsx"
import { useExcludeShadow } from "components/Navigation/hooks/useExcludeShadow"
import Link from "components/Navigation/Link"
import Logout from "features/Authentication/Logout"
import { useNotifications } from "features/Notifications/hooks/useNotifications"
import NotificationsPopup from "features/Notifications/NotificationsPopup"
import { context } from "global/Context/context"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import {
  Book,
  Journal,
  MediaVideoList,
  Message,
  MicrophoneSpeaking,
  Shop,
  Suitcase,
  ViewGrid,
} from "iconoir-react"
import { useContext, useState } from "react"
import { useTheme } from "react-jss"
import { useNavigate } from "react-router"
import {
  Link as ReactRouterLink,
  matchPath,
  useLocation,
} from "react-router-dom"
import routes from "routes"
import { buildUserName, getPlatformURL } from "utils/helpers"
import { useEnrollments } from "utils/hooks/useEnrollments"
import { webengage } from "utils/webengage"

import { ReactComponent as ItoLogo } from "../assets/OlympiadLogo.svg"
import Sidebar from "./Sidebar"
import WithoutAuth from "./WithoutAuth"

const moreLinks = [
  {
    key: "ito",
    name: "ITO 2022",
    link: routes.ito2022,
    icon: ItoLogo,
    disabled: false,
  },
  {
    key: "learnerHandbook",
    name: "Learner Handbook",
    link: routes.learnerHandbook,
    icon: Book,
    disabled: false,
  },
  {
    key: "aiTools",
    name: "AI Tools",
    link: routes.aiTools.home,
    icon: AISparkle,
    disabled: false,
  },
  {
    name: "Store",
    link: getPlatformURL("suraasa", "/store"),
    icon: Shop,
    disabled: false,
  },
  {
    name: "Masterclasses",
    link: getPlatformURL("suraasa", "/masterclasses"),
    icon: MediaVideoList,
    disabled: false,
  },
  {
    name: "Blogs",
    link: getPlatformURL("suraasa", "/blog"),
    icon: Journal,
    disabled: false,
  },
  {
    name: "Podcasts",
    link: "https://podcasters.spotify.com/pod/show/suraasa",
    icon: MicrophoneSpeaking,
    disabled: false,
  },
  {
    key: "version",
    name: `v${APP_VERSION}`,
    disabled: true,
  },
  // {
  //   name: "Past Events (Coming Soon)",
  //   disabled: true,
  //   link: getPlatformURL("suraasa", "/"),
  //   icon: BoxIso,
  // },
]

const Divider = () => <div className="h-full border-l-2 border-onSurface-200" />

type Props = {
  showSignInButton?: boolean
}

const TopNavigation = ({ showSignInButton = true }: Props) => {
  const theme = useTheme<Theme>()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))
  const navigate = useNavigate()
  const location = useLocation()
  const { pageOverview } = useNotifications({
    useSearchParams: false,
    enableNotificationList: false,
  })
  const { data: notificationOverviewData } = pageOverview

  const featureToggle = useFeatureToggle()

  const isAIToolsEnabled = featureToggle.aiTools.isEnabled

  const [openSheet, setOpenSheet] = useState(false)
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const omitShadow = useExcludeShadow()
  const { isPGCTLUser } = useEnrollments()

  const { authInfo, itoParticipationDetails, profile } = useContext(context)

  const hasNotification =
    notificationOverviewData && notificationOverviewData.all > 0

  const userName = authInfo ? buildUserName(authInfo.user) : ""

  const matchPathResult = matchPath(
    {
      path: routes.learning + routes.course,
    },
    location.pathname
  )

  const toggleSheet = () => {
    setOpenSheet(!openSheet)
  }

  const handleLogout = () => {
    webengage?.user.logout()
    setIsLoggingOut(true)
  }

  if (!authInfo) {
    return <WithoutAuth showSignInButton={showSignInButton} />
  }

  if (!mdUp) {
    return (
      <>
        <div
          id="topNavbar"
          className="sticky top-0 z-navbar flex items-center justify-between bg-white px-2 py-1"
          style={{
            boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
            transition: "top 0.3s",
          }}
        >
          <div className="flex shrink-0 items-center">
            <button onClick={toggleSheet}>
              <Avatar
                className="size-5"
                src={profile.picture}
                name={userName}
              />
            </button>
          </div>
          <div>
            {location.pathname === routes.learning && (
              <IconButton
                variant="plain"
                color="black"
                component={ReactRouterLink}
                to="/discussions"
              >
                <Message />
              </IconButton>
            )}
            {matchPathResult && (
              <IconButton
                variant="plain"
                color="black"
                component={ReactRouterLink}
                to={`/discussions/?course=${matchPathResult.params.slug}`}
              >
                <Message />
              </IconButton>
            )}

            <Sidebar
              open={openSheet}
              handleClose={toggleSheet}
              handleLogout={handleLogout}
            />
          </div>
        </div>
        {isLoggingOut && <Logout />}
      </>
    )
  }

  const moreLinksToShow = moreLinks
    // Don't show Ai Tools route if disabled
    .filter(x => (isAIToolsEnabled ? true : x.key !== "aiTools")) // Don't show ITO links to non-participants
    .filter(x =>
      itoParticipationDetails.pastYears.isITO2022Participant
        ? true
        : x.key !== "ito"
    )
    .filter(x => (x.key === "learnerHandbook" ? isPGCTLUser : true))

  return (
    <nav
      id="topNavbar"
      className="sticky top-0 z-navbar border-b border-surface-200 bg-white"
      style={{
        boxShadow: omitShadow ? "initial" : "1px 1px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Container>
        <div className="flex items-center justify-between">
          <ReactRouterLink to={routes.home}>
            <img
              src="https://assets.suraasa.com/logos/suraasa-logo-black-full.svg"
              alt="suraasa logo"
              width={150}
            />
          </ReactRouterLink>
          {mdUp ? (
            <div className="flex gap-1">
              <Link
                href={routes.home}
                text="Home"
                icon={<HomeLine />}
                activeIcon={<HomeSmile />}
              />
              <Link
                href={routes.learning}
                text="Learning"
                icon={<BookClosed />}
                activeIcon={<BookOpenNew />}
              />
              <Link
                href={routes.aiTools.home}
                text="AI Tools"
                icon={
                  <AISparkle className="h-3" height="20" viewBox="0 0 20 20" />
                }
              />
              <Link
                href={getPlatformURL("jobs", "/")}
                text="Jobs"
                icon={<Suitcase />}
                activeIcon={<JobsSearch />}
              />
              {itoParticipationDetails.registrationStatus === "completed" && (
                <Link
                  href={routes.ito}
                  text="ITO"
                  icon={
                    <img
                      src={ITOLogoGrey}
                      height={22}
                      width={22}
                      alt="ito"
                      className="mb-[2.5px]"
                    />
                  }
                  activeIcon={
                    <img
                      src="https://assets.suraasa.com/ito/logos/ito-original-mini.svg"
                      height={22}
                      width={22}
                      alt="ito"
                      className="mb-[2.5px]"
                    />
                  }
                />
              )}
              <div className={clsx({ relative: hasNotification })}>
                <Link
                  isMenu
                  arrow={false}
                  isNotification
                  text="Notifications"
                  icon={<Bell />}
                  activeIcon={<BellRing />}
                  menuChildren={<NotificationsPopup />}
                />
                {hasNotification && (
                  <span className="absolute left-7 top-1 inline-flex justify-center rounded-full bg-critical-500 px-0.5 text-xs font-semibold text-common-white-500">
                    {notificationOverviewData.all}
                  </span>
                )}
              </div>
              <Link
                isMenu
                text="Me"
                icon={<User />}
                activeIcon={<UserCircle />}
                className="[&>.szh-menu-container>ul]:!w-[275px]"
                menuChildren={
                  <>
                    {authInfo && (
                      <MenuItem
                        className="flex"
                        onClick={() => {
                          navigate(routes.profile.self)
                        }}
                      >
                        <div className="me-1 shrink-0">
                          <Avatar
                            className="size-6"
                            src={profile.picture}
                            name={userName}
                          />
                        </div>
                        <div>
                          <Typography
                            variant="strong"
                            className="whitespace-pre-wrap"
                            style={{
                              wordBreak: "break-word",
                            }}
                          >
                            {userName}
                          </Typography>
                          <Typography
                            variant="smallBody"
                            className="text-onSurface-500"
                          >
                            View Profile
                          </Typography>
                        </div>
                      </MenuItem>
                    )}
                    {authInfo && <MenuDivider />}
                    <MenuItem
                      onClick={() => {
                        navigate(routes.certificates)
                      }}
                    >
                      My Certificates
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate(routes.mySchedule)
                      }}
                    >
                      My Schedule
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate(routes.orders)
                      }}
                    >
                      Orders and Payments
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate(routes.settings)
                      }}
                    >
                      Settings
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        window.open(getPlatformURL("sso", "/help"), "_blank")
                      }}
                    >
                      Help Center
                    </MenuItem>
                    <MenuItem color="critical" onClick={handleLogout}>
                      Logout
                    </MenuItem>
                  </>
                }
              />
              <div>
                <Divider />
              </div>
              <Link
                text="More"
                icon={<ViewGrid />}
                activeIcon={<ViewGrid />}
                isMenu
                menuChildren={
                  <>
                    {moreLinksToShow.map((x, i) =>
                      x.key === "version" ? (
                        <MenuItem
                          disabled={x.disabled}
                          key={i}
                          startAdornment={x.icon ? <x.icon /> : null}
                          href={x.link}
                        >
                          <Typography
                            variant="smallBody"
                            className="text-onSurface-500"
                          >
                            {x.name}
                          </Typography>
                        </MenuItem>
                      ) : (
                        <MenuItem
                          disabled={x.disabled}
                          key={i}
                          startAdornment={x.icon ? <x.icon /> : null}
                          href={x.link}
                        >
                          <Typography>{x.name}</Typography>
                        </MenuItem>
                      )
                    )}
                  </>
                }
              />
            </div>
          ) : (
            <Link
              href="/home"
              text="Home"
              icon={<HomeLine />}
              activeIcon={<HomeSmile />}
            />
          )}
        </div>
      </Container>

      {isLoggingOut && <Logout />}
    </nav>
  )
}
export default TopNavigation
