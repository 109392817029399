import { LessonPlanProResponse } from "api/resources/aiTools/types"
import { useContext } from "react"

import { AIToolsContext } from "../../context"
import InputDisplay from "../../helpers/InputDisplay"
import { LessonPlanProContent } from "./LessonPlanContent"

const LessonPlanProOutput = ({ output, ...props }: LessonPlanProResponse) => {
  const { overviewData } = useContext(AIToolsContext)

  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && <InputDisplay data={overviewData} />}

      {output.map((x, i) => (
        <LessonPlanProContent
          output={x}
          sequence={i + 1}
          title={i > 0 ? "" : props.title}
          key={i}
        />
      ))}
    </div>
  )
}

export default LessonPlanProOutput
