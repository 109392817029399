import { Typography } from "@suraasa/placebo-ui"
import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
  Divider,
} from "@suraasa/placebo-ui-legacy"
import { NavArrowDown } from "iconoir-react"
import React from "react"

import { makeId } from "./utils/helper"

type Props = {
  items: {
    category: string
    question: string
    answer: string
    whatsapp: string
    keywords: string
  }[]
}
const PerTopicFAQ = ({ items }: Props) => {
  return (
    <div
      className="my-8 grid scroll-mt-10 grid-cols-1 gap-2"
      id={makeId(items[0].category)}
    >
      <Typography variant="title2" className="mb-2">
        {items[0].category}
      </Typography>
      {/**
       * -mt-3 because first accordion has py-3
       * and we don't want padding top on first element,
       * instead of using js to achieve I used css
       */}
      <div className="-mx-2 -mt-3">
        {items.map(item => (
          <React.Fragment key={item.question}>
            <Accordion className="py-3">
              <AccordionHeader icon={<NavArrowDown color="#000" />}>
                <Typography variant="strong" className="text-start">
                  {item.question}
                </Typography>
              </AccordionHeader>
              <AccordionDetails>
                <Typography variant="body" className="mt-0.5">
                  <span
                    className="[&>a]:text-primary-500 [&>a]:hover:underline"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Divider color="onSurface.200" weight="light" />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default PerTopicFAQ
