import api from "api"
import {
  InterviewStatus,
  ReviewStatus,
} from "api/resources/applicationForm/types"
import LoadingOverlay from "components/LoadingOverlay"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { learningItemSlugs } from "utils/constants"
import { getAuthInfo } from "utils/helpers"

const getURL = (name: string, email: string) => {
  const url = new URL("https://go.oncehub.com/suraasa-admissions")

  url.searchParams.append("name", name)
  url.searchParams.append("email", email)
  url.searchParams.append("skip", "1")

  return url.toString()
}

const ScheduleInterview = () => {
  const auth = getAuthInfo()
  const name = auth ? `${auth.user.firstName} ${auth.user.lastName}` : ""
  const email = auth?.user.email || ""

  const url = getURL(name, email)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [errorPage, setErrorPage] = useState(false)

  const fetchApplicationOverview = async () => {
    try {
      const res = await api.applicationForm.overview()
      const pgctlOverview = res.find(
        o => o.productSlug === learningItemSlugs.pgctl
      )

      if (pgctlOverview) {
        const { interview, review } = pgctlOverview

        if (
          (interview &&
            interview?.status !== InterviewStatus.NO_SHOW &&
            interview?.status !== InterviewStatus.CANCELLED) ||
          review?.status !== ReviewStatus.INTERVIEW_REQUESTED
        ) {
          setErrorPage(true)
        }
      }
    } catch (e) {
      setErrorPage(true)
      console.error(e)
    }

    setLoading(false)
  }
  useEffect(() => {
    fetchApplicationOverview()
  }, [])

  if (loading) return <LoadingOverlay />
  if (errorPage)
    return (
      <div className="flex items-center justify-center p-5">
        <p>Something went wrong!</p>
      </div>
    )

  return (
    <iframe
      title="schedule-page"
      src={url}
      style={{ width: "100%", height: "100vh", border: "0px" }}
    />
  )
}

export default ScheduleInterview
