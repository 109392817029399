import clsx from "clsx"
import React from "react"
import { isDesktop } from "react-device-detect"
import { PropsWithClassName } from "utils/types"

import TutorialThumbnailTablet from "../assets/tutorial-thumbnail-tablet.webp"
import TutorialThumbnailWeb from "../assets/tutorial-thumbnail-web.webp"

const videosAvailableFor = [
  "assessment-and-evaluation-techniques",
  "child-development-learning-styles-and-learning-taxonomies",
  "ethics-and-values",
  "learning-theories-and-teaching-strategies",
  "lesson-planning",
  "management-of-class-dynamics",
  "providing-feedback",
  "questioning-skills-and-techniques",
  "reflective-teaching",
  "using-technology-for-teaching-and-learning",
]

const TutorialVideo = (props: PropsWithClassName<{ courseSlug: string }>) => {
  if (!videosAvailableFor.includes(props.courseSlug)) return null

  return (
    /* eslint-disable-next-line jsx-a11y/media-has-caption */
    <video
      controlsList="nodownload"
      controls
      className={clsx(
        "w-full rounded-xl shadow-[0px_6px_15px_0px_rgba(0,0,0,0.05)]",
        props.className
      )}
      preload="none"
      poster={isDesktop ? TutorialThumbnailWeb : TutorialThumbnailTablet}
      src={`https://assets.suraasa.com/lms/pgctl-skill-evidence-how-to-videos/${props.courseSlug}.mp4`}
    />
  )
}

export default TutorialVideo
