import { Typography } from "@suraasa/placebo-ui"
import { Divider } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  fullWidth: {
    width: "100%",
  },
})

type Props = { text?: string; children?: any }

const TextDivider = ({ text = "OR", children }: Props) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.fullWidth, "flex items-center")}>
      <Divider className={clsx(classes.fullWidth, "mr-1")} />
      {children ?? <Typography variant="button">{text}</Typography>}
      <Divider className={clsx(classes.fullWidth, "ml-1")} />
    </div>
  )
}

export default TextDivider
