import { Typography } from "@suraasa/placebo-ui"
import { Button, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { ToolType } from "api/resources/aiTools/types"
import AISparkle from "assets/AITools/ai-sparkle.webp"
import { AISparkleFilled } from "assets/icons/system/AISparkle"
import clsx from "clsx"
import { getToolName } from "features/AItools/helper"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import routes from "routes"

import styles from "./Card.module.css"

const cardData = [
  {
    title: `${getToolName(ToolType.lessonPlan)}\nGenerator`,
    color: "#E5A256",
  },
  {
    title: `${getToolName(ToolType.rubrics)}\nGenerator`,
    color: "#49B7CC",
  },
  {
    title: `${getToolName(ToolType.quiz)}\nGenerator`,
    color: "#97BC62",
  },
  {
    title: `${getToolName(ToolType.handout)}\nGenerator`,
    color: "#3B82F6",
  },
  {
    title: `${getToolName(ToolType.narration)}\nGenerator`,
    color: "#138D75",
  },
  {
    title: `${getToolName(ToolType.subjectiveAssessment)}\nGenerator`,
    color: "#9A7BBA",
  },
  {
    title: `${getToolName(ToolType.assignment)}\nGenerator`,
    color: "#EEC239",
  },
  {
    title: `${getToolName(ToolType.lessonPlan)}\nGenerator`,
    color: "#E5A256",
  },
]

const AIToolsOnboardingCard = () => {
  const isSmallScreen = useMediaQuery("@media (max-width: 440px)")

  const [diagonalDistance, setDiagonalDistance] = useState(1)

  const featureToggle = useFeatureToggle()

  const isAIToolsEnabled = featureToggle.aiTools.isEnabled

  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ref.current) {
      setDiagonalDistance(ref.current.clientHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  if (!isAIToolsEnabled) return null

  return (
    <>
      <Typography className="!flex" variant="title3">
        Suraasa AI Tools{" "}
        <span className="-translate-y-1">
          <img src={AISparkle} alt="" width="25" />
        </span>
      </Typography>
      <div
        className="relative my-4 mt-2 flex max-h-[210px] min-h-[180px] items-center overflow-hidden rounded-2xl border border-slate-200 bg-gradient-to-bl from-white to-gray-200 sm:gap-5"
        ref={ref}
        style={
          {
            "--diagonalDistance": diagonalDistance,
          } as React.CSSProperties
        }
      >
        <div className="relative hidden size-full max-w-[30%] sm:block">
          <div className="absolute  size-full bg-white" />
          <div className={clsx(styles.sliderContainer, "flex w-full")}>
            <div className={clsx("grow", styles.shift1)}>
              {cardData.map((item, index) => (
                <div
                  key={index}
                  style={{
                    background: item.color,
                  }}
                  className={clsx(
                    styles.imgContainer,
                    "relative flex w-full flex-col justify-between overflow-hidden p-2 text-white"
                  )}
                >
                  <div className="flex items-center">
                    <Typography variant="smallBody" className="mr-1">
                      Suraasa AI
                    </Typography>
                    <AISparkleFilled fill="white" />
                  </div>
                  <Typography
                    variant="title2"
                    className="!whitespace-pre-wrap leading-tight"
                  >
                    {item.title}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="size-full grow">
          <div
            className={clsx(
              "flex flex-col justify-between gap-2 pl-2 sm:pl-0",
              {
                "mr-[25%]": !isSmallScreen,
                "mr-0 pr-2": isSmallScreen,
              }
            )}
          >
            <div>
              <Typography
                className={clsx("mb-1", {
                  "max-w-[182px] sm:max-w-[314px]": !isSmallScreen,
                })}
                variant="largeBody"
              >
                Cutting-edge AI tools designed to revolutionise your teaching
                journey.
              </Typography>
            </div>
            <Button color="black" component={Link} to={routes.aiTools.home}>
              View Tools
            </Button>
          </div>
        </div>

        {!isSmallScreen && (
          <div className="relative block size-full max-w-[40%] sm:hidden">
            <div className="absolute -left-2 size-full bg-white" />
            <div className={clsx(styles.sliderContainer, "flex w-full")}>
              <div className={clsx("grow", styles.shift1)}>
                {cardData.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      background: item.color,
                    }}
                    className={clsx(
                      styles.imgContainer,
                      "relative flex w-full flex-col justify-between overflow-hidden p-2 text-white"
                    )}
                  >
                    <div className="flex items-center">
                      <Typography variant="smallBody" className="mr-1">
                        Suraasa AI
                      </Typography>
                    </div>
                    <Typography
                      variant="title3"
                      className="!whitespace-pre-wrap"
                    >
                      {item.title}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default AIToolsOnboardingCard
