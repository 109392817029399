import { Button, Theme } from "@suraasa/placebo-ui-legacy"
import { ITOCertificate } from "api/resources/profile/types"
import clsx from "clsx"
import { createUseStyles } from "react-jss"
import { generateAuthCode, getPlatformURL } from "utils/helpers"
import { trackingService } from "utils/tracking"

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    background: `url("/assets/ito/2023/AfterResultPhase/WallOfRecognition/the-wall.webp")`,
    color: "8D796D",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0% 75%",
    [theme.breakpoints.up("lg")]: {
      backgroundPosition: "0% 90%",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "12% 75%",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "50% 75%",
    },
  },
  frame: {
    filter: "drop-shadow(-3px 6px 6px rgba(0, 0, 0, 0.25))",
    display: "inline-table",
    width: 239.08,
    padding: "24.8px 26.21px",
    textAlign: "center",
    "&:before": {
      content: `""`,
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0px",
      left: "0px",
      zIndex: -1,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      background: `url("/assets/ito/2023/AfterResultPhase/WallOfRecognition/frame.webp")`,
    },
  },
  excellenceCertificateContainer: {
    height: 267.96,
    width: 189.47,
    left: "25% !important",
  },

  verticalFrame: {
    height: 239.08,
    width: 320.38,

    "&:before": {
      left: -38,
      transform: "scaleX(-1) rotate(-90deg)",
    },
  },
  verticalFrameContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },

  certificateContainer: {
    height: 189.47,
    width: 267.96,
  },

  certificateInnerShadow: {
    position: "absolute",
    boxShadow:
      "inset 0.172249px -0.172249px 0.688995px rgba(0, 0, 0, 0.15), inset -0.516746px 0.516746px 0.688995px rgba(0, 0, 0, 0.25)",
    top: 0,
    left: 0,
  },
  viewButton: {
    borderRadius: 50,
    zIndex: 1,
  },
  imgWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}))

type Props = {
  data?: ITOCertificate[]
}

const WallOfRecognition = ({ data }: Props) => {
  const classes = useStyles()

  if (!data) return null
  const handleViewCertificate = async (
    certificateNumber: string,
    type: string
  ) => {
    trackingService.trackEvent(
      "element_clicked",
      {
        meta: {
          type: type,
        },
        id: "view_ito_certificate",
        text: "View",
        intent: "view_ito_certificate",
        page_url: window.location.href,
      },
      ["WebEngage"]
    )

    const code = await generateAuthCode()
    window.open(
      getPlatformURL(
        "suraasa",
        code
          ? `/international-teachers-olympiad/verify/${certificateNumber}?authCode=${code}`
          : `/international-teachers-olympiad/verify/${certificateNumber}`
      ),
      "_blank"
    )
  }

  return (
    <div className={clsx("pb-20 pt-10", classes.container)}>
      <img
        src="/assets/ito/2023/AfterResultPhase/WallOfRecognition/title.webp"
        alt=""
        className={clsx("mx-auto mb-6 md:mb-7", classes.imgWidth)}
        style={{ display: "block" }}
      />
      <div
        className={clsx("mx-auto flex flex-wrap justify-center", {
          "gap-6 items-baseline": data.length > 2,
          "md:gap-20 gap-6": data.length <= 2,
        })}
      >
        {data
          .filter(x => x.highResImage || x.lowResImage)
          .map((item, index) => {
            if (!item.highResImage || !item.lowResImage) return null

            const isExcellence =
              item.item.slug ===
              "international-teacher-olympiad-2023-excellence"
            return (
              <div
                key={index}
                className={clsx("relative flex flex-col items-center", {
                  [classes.verticalFrameContainer]: isExcellence,
                })}
              >
                <div
                  className={clsx(classes.frame, "relative", {
                    [classes.verticalFrame]: isExcellence,
                  })}
                >
                  <div
                    className="relative"
                    style={{
                      transform: isExcellence ? "translate(39px,2px)" : "none",
                    }}
                  >
                    <img
                      src={item.highResImage || item.lowResImage}
                      alt="certificate"
                      className={clsx({
                        [classes.excellenceCertificateContainer]: isExcellence,
                      })}
                    />

                    <div className={clsx(classes.certificateInnerShadow, {})} />
                  </div>
                </div>

                <Button
                  onClick={() =>
                    handleViewCertificate(item.serialNumber, item.item.slug)
                  }
                  className={clsx("mt-4", classes.viewButton)}
                >
                  View
                </Button>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default WallOfRecognition
