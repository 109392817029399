import { Typography } from "@suraasa/placebo-ui"
import { context } from "global/Context/context"
import React, { useContext } from "react"

const EnrollmentNumber = ({ slug }: { slug: string }) => {
  const { enrollments } = useContext(context)
  const currentEnrollment = enrollments?.find(item => item.slug === slug)

  if (!currentEnrollment || !currentEnrollment.enrollmentNumber) return null

  return (
    <div className="mb-1 flex h-full items-center justify-between gap-2 rounded-lg border border-solid border-highlight-200 bg-highlight-50 p-2">
      <Typography variant="title4">Enrollment Number</Typography>
      <span className="text-left text-base font-normal leading-[20.8px] text-onSurface-500">
        {currentEnrollment.enrollmentNumber}
      </span>
    </div>
  )
}

export default EnrollmentNumber
