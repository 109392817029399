import { QuizResponse } from "api/resources/aiTools/types"
import { useContext } from "react"

import Paper from "../../../Paper"
import { AIToolsContext } from "../../context"
import InputDisplay from "../../helpers/InputDisplay"
import MarkdownRenderer from "../MarkdownRenderer"
import RegenerateOutput from "../RegenerateOutput"

const QuizOutput = ({ output, ...props }: QuizResponse) => {
  const { overviewData } = useContext(AIToolsContext)

  const isPro =
    (overviewData?.numberOfIndividualQuestions || 0) > 0 ||
    (overviewData?.numberOfComprehensions || 0) > 0

  if (isPro && overviewData?.numberOfQuestions) {
    overviewData.numberOfQuestions = 0
  }

  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && <InputDisplay data={overviewData} />}
      <Paper
        className="overflow-x-auto !p-0"
        endSlot={
          <RegenerateOutput
            copyText={output}
            isPositiveResponse={props.isPositiveResponse}
          />
        }
      >
        <div className="mb-3 px-2 pb-2">
          <MarkdownRenderer>{output}</MarkdownRenderer>
        </div>
      </Paper>
    </div>
  )
}

export default QuizOutput
