import clsx from "clsx"
import React from "react"

type Props = {
  background: string
  className?: string
  disabled?: boolean
}

const ImageBackgroundCard: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  background,
  className,
  disabled,
}) => {
  return (
    <div className={clsx("relative overflow-hidden", className)}>
      <div
        className={clsx(
          "absolute left-0 top-0 -z-2 size-full scale-150 bg-auto bg-center bg-no-repeat blur",
          {
            grayscale: disabled,
          }
        )}
        style={{
          backgroundImage: `url(${background})`,
        }}
      />
      <div
        className="absolute left-0 top-0 -z-1 size-full"
        style={{ background: "rgba(0,0,0,0.3)" }}
      />
      {children}
    </div>
  )
}

export default ImageBackgroundCard
