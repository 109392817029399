import { Typography } from "@suraasa/placebo-ui"
import { Container, Theme } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { format } from "date-fns"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import BalanceCard from "features/ITO/Dashboard/ReferAndEarn/BalanceCard"
import { context } from "global/Context/context"
import { InfoCircle } from "iconoir-react"
import { useContext, useState } from "react"
import { createUseStyles } from "react-jss"

import CreditsHistorySidebar from "./CreditsHistorySidebar"
import EarningsCard from "./EarningsCard"
import InviteViaWhatsappAndShare from "./InviteViaWhatsappAndShare"
import ReferralHistory from "./ReferralHistory"
import RegisterDialog from "./RegisterDialog"
const useStyles = createUseStyles((theme: Theme) => ({
  backButton: {
    marginBottom: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 5),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5, 2),
    },
    justifyItems: "center",
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      columnGap: "24px",
    },
  },
  bannerHeading: {
    fontFamily: "Reckless TRIAL",
    fontSize: "40px",
    lineHeight: "48px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-start",
    },
  },
  shareBtn: {
    backgroundColor: "#743FF5",
    "&:hover": {
      backgroundColor: "#4909e7",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  cardContainer: {
    display: "flex",
    gap: "24px",
    flexGrow: "1",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "8px",
    },
  },
}))

export default function ReferAndEarn() {
  const classes = useStyles()
  const [openCreditsHistory, setOpenCreditsHistory] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(0)
  const [openRegisterFirst, setRegisterFirst] = useState(false)

  const overview = useQuery(["getReferralOverview", forceUpdate], () =>
    api.ito.referAndEarn.getReferralOverview({
      params: {
        type: "ito_referral_reward",
        source: "ito_referral_reward_2023",
      },
    })
  )

  const { itoParticipationDetails } = useContext(context)

  const handleWithdrawCallBack = () => {
    setForceUpdate(prevState => prevState + 1)
  }

  const referralCode =
    itoParticipationDetails.itoRegistrationDetails?.referralCode

  const handleOnCloseCallback = () => {
    setRegisterFirst(false)
  }

  return (
    <>
      {/* <DisableItoRoutes> */}
      <div className="h-3 w-full" />
      <RegisterDialog
        isVisible={openRegisterFirst}
        onClose={handleOnCloseCallback}
      />
      <Container>
        <div className={classes.cardContainer}>
          <EarningsCard
            earning={overview.data?.totalEarning}
            currency={overview.data?.currency}
          />
          <BalanceCard
            referralCode={referralCode}
            balance={overview.data?.balance}
            currency={overview.data?.currency}
            onCreditHistory={() => setOpenCreditsHistory(!openCreditsHistory)}
            onClaimSuccess={handleWithdrawCallBack}
          />
        </div>
        {overview.data?.expiryDate && (
          <Typography
            variant="strongSmallBody"
            className="mb-2 !flex flex-wrap items-center gap-1"
          >
            <InfoCircle height={20} width={20} className="hidden sm:block" />
            <span>
              You can withdraw these earnings anytime before{" "}
              {format(new Date(overview.data.expiryDate), "d MMMM yyyy")}.
            </span>
          </Typography>
        )}

        {referralCode && (
          <>
            <Typography variant="title3" className="mb-2">
              How it works?
            </Typography>
            <div className="mb-4 grid gap-3 md:grid-cols-2">
              <div className="relative flex items-start rounded-lg border border-onSurface-200 p-2 pb-3">
                <span className="absolute -bottom-2 left-2.5 h-0.25 w-3 rotate-90 bg-black md:-right-3 md:bottom-auto md:left-auto md:rotate-0" />
                <Typography
                  variant="title2"
                  className="mr-1 size-5 shrink-0 rounded-full bg-[#25D366] p-0.25 text-center !text-white"
                >
                  1
                </Typography>
                <div className="mt-1">
                  <SwitzerTypography
                    className="mb-1"
                    variant="title3"
                    style={{ fontFamily: "Switzer Bold" }}
                  >
                    Invite Teachers.
                  </SwitzerTypography>
                  <Typography
                    variant="body"
                    style={{ fontWeight: "500" }}
                    className="text-onSurface-800"
                  >
                    Invite Teachers in your network to International Teachers’
                    Olympiad
                  </Typography>
                  <InviteViaWhatsappAndShare className="mt-3" />
                </div>
              </div>
              <div className="flex items-start rounded-lg border border-onSurface-200 p-2">
                <Typography
                  variant="title2"
                  className="mr-1 size-5 shrink-0 rounded-full bg-[#25D366] p-0.25 text-center !text-white"
                >
                  2
                </Typography>
                <div className="mt-1">
                  <SwitzerTypography
                    className="mb-1"
                    variant="title3"
                    style={{ fontFamily: "Switzer Bold" }}
                  >
                    Earn Cash.
                  </SwitzerTypography>
                  <Typography
                    variant="body"
                    style={{ fontWeight: "500" }}
                    className="text-onSurface-800"
                  >
                    For each successful registration, you earn
                    <b className="font-bold">&nbsp;₹100</b>, and they also get
                    <b className="font-bold">&nbsp;₹50&nbsp;</b>
                    discount on registration.
                  </Typography>
                </div>
              </div>
            </div>
          </>
        )}

        <ReferralHistory
          hasWithdrawnAtLeastOnce={Boolean(overview.data?.totalEarning)}
        />
      </Container>
      <CreditsHistorySidebar
        forceUpdate={forceUpdate}
        drawerProps={{
          open: openCreditsHistory,
          toggle: () => setOpenCreditsHistory(!openCreditsHistory),
        }}
      />
      {/* </DisableItoRoutes> */}
    </>
  )
}
