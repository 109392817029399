import { Typography } from "@suraasa/placebo-ui"
import { Container } from "@suraasa/placebo-ui-legacy"
import ReportsDesktop from "assets/Ito/BeforeResult/PedagogyReport/reports-desktop.webp"
import { ArrowRight } from "iconoir-react"

const PedagogyReports = ({
  gotoAwardsAndPrize,
}: {
  resultDate: Date
  gotoAwardsAndPrize: () => void
}) => {
  // const { days, hours, minutes } = useTimer({
  //   expiryTimestamp: resultDate,
  //   autoStart: false,
  // })

  return (
    <div className="bg-ito-peach-900">
      <Container>
        <div className="flex flex-col items-start justify-between gap-2 pb-0 pt-3 sm:items-center sm:text-center md:flex-row md:py-2 md:text-start">
          <div>
            <Typography variant="title1" className="mb-3 !text-ito-peach-500">
              Your Pedagogy Reports are coming shortly!
            </Typography>
            <button
              onClick={gotoAwardsAndPrize}
              className="mx-0 flex rounded-full bg-black/25 px-3 py-1.5 sm:mx-auto md:mx-0"
            >
              <span className="text-[0.9375rem] font-medium leading-5 text-white">
                Look at all the rewards awaiting you
              </span>
              <ArrowRight
                className="ml-1 shrink-0 text-white"
                width={20}
                height={20}
              />
            </button>
          </div>
          <div className="relative h-[180px] w-full shrink-0 overflow-hidden sm:h-[224px] md:w-auto">
            <img
              src={ReportsDesktop}
              className="absolute -bottom-1/4 h-[220px] w-full object-contain md:static md:h-full"
              alt="reports"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PedagogyReports
