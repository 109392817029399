import { Typography } from "@suraasa/placebo-ui"
import { Button, Theme } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import AllowCameraDialog from "features/ITO/Assessments/Instructions/AllowCameraDialog"
import OverlayWrapper from "features/ITO/Assessments/Proctoring/OverlayWrapper"
import { VideoCameraOff } from "iconoir-react"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles((theme: Theme) => ({
  color: {
    color: "#FFFFFF",
  },
  box: {
    backgroundColor: "#EFEFF7",
    padding: theme.spacing(6, 13),
    borderRadius: "14px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 9),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 7),
    },
  },
  text: {
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

const CameraOverlay = () => {
  const classes = useStyles()
  const [showAllowCameraDialog, toggleAllowCameraDialog] = useState(false)

  return (
    <>
      <AllowCameraDialog open={showAllowCameraDialog} />
      <OverlayWrapper backgroundImg="url('/src/features/ITO/Assessments/Attempt/Proctoring/assets/attemptAssessment.png')">
        <div className="flex flex-col items-center justify-center gap-6 p-2 sm:p-4">
          <div>
            <div className={classes.box}>
              <VideoCameraOff height={75} width={75} />
            </div>
            <Typography
              className={clsx(classes.color, "mt-0.75")}
              variant="smallBody"
            >
              Unable to detect your camera
            </Typography>
          </div>
          <Typography
            variant="title1"
            className={clsx("text-center", classes.color, classes.text)}
          >
            Please switch on your camera.
          </Typography>
          <Button onClick={() => toggleAllowCameraDialog(true)}>
            Enable your camera
          </Button>
        </div>
      </OverlayWrapper>
    </>
  )
}

export default CameraOverlay
