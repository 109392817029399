import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { NotificationResponse } from "api/resources/notifications/types"
import { PaginatedAPIResponse } from "api/types"
import { APIError } from "api/utils"
import { useEffect, useState } from "react"
import { handleErrors } from "utils/helpers"

import useTabs, { Tabs } from "../../../components/TabBar/hooks/useTabs"

type Options = {
  useSearchParams?: boolean
  enableNotificationList?: boolean
  enableNotificationOverview?: boolean
  popup?: boolean
}

const defaultOptions: Options = {
  useSearchParams: true,
  enableNotificationList: true,
  enableNotificationOverview: true,
  popup: false,
}
export enum TabNames {
  ALL = "All",
  LEARN = "Learn",
  JOBS = "Jobs",
  COMMUNITY = "Community",
}

export const useNotifications = (options = defaultOptions) => {
  const queryClient = useQueryClient()
  const [tabs, setTabs] = useState<Tabs>([
    {
      name: TabNames.ALL,
      content: () => null,
      count: 0,
    },
    {
      name: TabNames.LEARN,
      content: () => null,
      count: 0,
    },
    {
      name: TabNames.JOBS,
      content: () => null,
      count: 0,
    },
  ])
  const [activeTab, setActiveTab] = useTabs({
    tabs,
    initialTab: tabs[0].name,
    useSearchParams: options.useSearchParams,
  })

  const {
    data: notificationOverview,
    isLoading: notificationOverviewLoading,
    isError: notificationOverviewLError,
  } = useQuery({
    queryKey: queries.notifications.notificationOverview().queryKey,
    queryFn: () => api.notifications.overview(),
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
    refetchOnMount: false,
    enabled: options.enableNotificationOverview,
  })
  const getTagName = () => {
    switch (activeTab.name) {
      case TabNames.LEARN:
        return "learning"
      case TabNames.JOBS:
        return "jobs"
      case TabNames.COMMUNITY:
        return "community"
      default:
        return null
    }
  }
  const notifications = useInfiniteQuery({
    queryKey: queries.notifications.notificationList(activeTab.name).queryKey,
    queryFn: x => {
      const tag = getTagName()
      return api.notifications.list({
        params: {
          page: x.pageParam || 1,
          ...(tag && { tag }),
          inapp_tray: options.popup,
        },
      })
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },

    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
    refetchOnMount: false,
    enabled: options.enableNotificationList,
  })

  const updateState = (
    state: InfiniteData<PaginatedAPIResponse<NotificationResponse>> | undefined
  ) => {
    if (state) {
      return {
        ...state,
        pages: state.pages.map(page => ({
          ...page,
          data: {
            ...page.data,
            notifications: page.data.notifications.map(notification => ({
              ...notification,
              dateRead:
                notification.dateRead === null
                  ? new Date().toISOString()
                  : notification.dateRead,
            })),
          },
        })),
      }
    }
    return state
  }

  const { mutate: markAsRead, ...markAllAsReadStatus } = useMutation({
    mutationFn: () => api.notifications.markAsRead(),
    onSuccess: () => {
      queryClient.setQueryData(
        queries.notifications.notificationList(TabNames.ALL).queryKey,
        updateState
      )
      queryClient.setQueryData(
        queries.notifications.notificationList(TabNames.LEARN).queryKey,
        updateState
      )
      queryClient.setQueryData(
        queries.notifications.notificationList(TabNames.JOBS).queryKey,
        updateState
      )
      queryClient.setQueryData(
        queries.notifications.notificationOverview().queryKey,
        () => ({ all: 0, learning: 0, jobs: 0 })
      )
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const markAllAsRead = async () => {
    markAsRead()
  }

  useEffect(() => {
    if (notificationOverview)
      setTabs(prevState => {
        return prevState.map(x => {
          if (x.name === TabNames.ALL) {
            x.count = notificationOverview["all"]
          }
          if (x.name === TabNames.LEARN) {
            x.count = notificationOverview["learning"]
          }
          if (x.name === TabNames.JOBS) {
            x.count = notificationOverview["jobs"]
          }
          if (x.name === TabNames.COMMUNITY) {
            x.count = notificationOverview["community"]
          }
          return x
        })
      })
  }, [notificationOverview])

  const getDescription = () => {
    if (activeTab.name === TabNames.JOBS) {
      return "Apply for jobs get notified."
    }
    return "Start a course or engage in discussions to get started "
  }

  return {
    tabs,
    markAllAsRead,
    changeTab: setActiveTab,
    activeTab,
    notifications,
    pageOverview: {
      isLoading: notificationOverviewLoading,
      isError: notificationOverviewLError,
      data: notificationOverview,
    },
    getDescription,
    markAllAsReadStatus,
  }
}
