import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Divider,
  LinearProgress,
  TextField,
} from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import clsx from "clsx"
import { add } from "date-fns"
import { useEffect, useState } from "react"
import { useTimer } from "react-timer-hook"
import { handleErrors } from "utils/helpers"
import useArray from "utils/hooks/useArray"

import styles from "./Step2.module.css"

const AUTO_DISMISS_TIME = 5 // in seconds

type Props = {
  onClose: (isSubmitted: boolean) => void
  itemId: number | string
}

const moods = {
  1: [
    "Frustrating",
    "Challenging",
    "Confusing",
    "Overwhelming",
    "Disappointing",
  ],
  2: ["Challenging", "Needs Clarification", "Boring", "Mediocre", "Inadequate"],
  3: ["Informative", "Straightforward", "Satisfactory", "Adequate", "Decent"],
  4: ["Useful", "Interesting", "Engaging", "Helpful", "Enjoyable"],
  5: [
    "Excellent",
    "Clear",
    "Insightful",
    "Well - Structured",
    "Highly Recommended",
  ],
}

const getMoodsList = (rating: number | null) => {
  if (!rating) return moods[5]
  return moods[rating as keyof typeof moods]
}

const ratingChoices = Array.from({ length: 5 }, (_, i) => i + 1)

const Step2 = ({ onClose, itemId }: Props) => {
  const [rating, setRating] = useState<null | number>(null)

  const experience = useArray<string>([])
  const [description, setDescription] = useState("")

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: () =>
      api.feedback.create({
        data: {
          rating: rating,
          comment: {
            experience: experience.array,
            description,
          },
          itemType: 1, // "course"
          itemId,
        },
      }),
    onSuccess: () => {
      setRating(null)
      experience.clear()
      setDescription("")
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const timer = useTimer({
    expiryTimestamp: add(new Date(), { seconds: AUTO_DISMISS_TIME }),
    autoStart: false,
    onExpire: () => {
      setTimeout(() => {
        onClose(true)
      }, 1000)
    },
  })

  useEffect(() => {
    if (isSuccess) {
      timer.restart(add(new Date(), { seconds: AUTO_DISMISS_TIME }), true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const onSubmit = () => {
    mutate()
  }

  useEffect(() => {
    experience.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating])

  return (
    <div
      className={clsx("overflow-hidden", styles.container, {
        [styles.isSuccess]: isSuccess,
      })}
    >
      <div
        className={clsx(
          "mb-2 flex items-center justify-between px-2 pt-2 sm:px-4 sm:pt-4"
        )}
      >
        <Typography variant="title3" className="text-onSurface-800">
          Feedback
        </Typography>
        {/* <IconButton
          onClick={() => {
            onClose(isSuccess)
          }}
        >
          <Xmark />
        </IconButton> */}
      </div>
      {isSuccess ? (
        <div>
          <Typography
            variant="strong"
            className="mb-4 px-2 text-onSurface-800 sm:px-4"
          >
            Thank you for your feedback.
          </Typography>

          <LinearProgress value={(timer.seconds / AUTO_DISMISS_TIME) * 100} />
        </div>
      ) : (
        <div className=" px-2 pb-2 sm:px-4 sm:pb-4">
          <Typography variant="strong" className="mb-1.5 text-onSurface-800">
            How would you rate this course?
          </Typography>
          <div className="mb-1 flex gap-1.5">
            {ratingChoices.map((item, index) => (
              <button
                onClick={() => {
                  setRating(item)
                }}
                className={clsx(styles.rateButton, {
                  [styles.selectedRateButton]: rating === item,
                })}
                key={index}
              >
                <Typography variant="strong">{item}</Typography>
              </button>
            ))}
          </div>
          <div className="flex items-center justify-between">
            <Typography variant="smallBody" className="text-onSurface-500">
              Not Happy
            </Typography>
            <Typography variant="smallBody" className="text-onSurface-500">
              Very Happy
            </Typography>
          </div>
          <div
            className={clsx(styles.bottomContainer, {
              [styles.bottomContainerActive]: rating,
            })}
          >
            <div className="overflow-hidden">
              <Divider className="my-3" />
              <Typography
                variant="strong"
                className="mb-1.5 text-onSurface-800"
              >
                How was your experience?
              </Typography>
              <div className="mb-3 flex flex-wrap gap-1">
                {getMoodsList(rating).map((item, index) => {
                  const isSelected = experience.array.includes(item)
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        if (isSelected) {
                          experience.remove(experience.array.indexOf(item))
                        } else {
                          experience.push(item)
                        }
                      }}
                      className={clsx("px-1.25 py-1", styles.experienceButton, {
                        [styles.selectedExperienceButton]: isSelected,
                      })}
                    >
                      {item}
                    </button>
                  )
                })}
              </div>

              <Typography variant="strong" className="mb-1 text-onSurface-800">
                Tell us more about your experience (optional)
              </Typography>
              <TextField
                className="mb-4"
                multiLine
                value={description}
                rows={5}
                fullWidth
                onChange={(event: { target: { value: string } }) => {
                  setDescription(event.target.value)
                }}
              />
              <Button onClick={onSubmit} loading={isLoading} fullWidth>
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Step2
