import { toast } from "@suraasa/placebo-ui"
import { Button, Select, TextField } from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  AssignmentProForm,
  RetrievePrompt,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import AiToolsLoading from "features/AItools/components/AiToolsLoading"
import Paper from "features/AItools/components/Paper"
import { getToolName, proPointScaleOptions } from "features/AItools/helper"
import { useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { AIToolsContext } from "../../context"

const toolType = ToolType.assignmentPro

type Props = {
  onGeneration: (data: APIResponse<RetrievePrompt<typeof toolType>>) => void
}

const ProForm = ({ onGeneration }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<AssignmentProForm>()
  const { setPromptDataId } = useContext(AIToolsContext)

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: AssignmentProForm) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
        model: "pro",
      })
      return api.aiTools.generateAssignmentPro({ data })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
        model: "pro",
      })

      onGeneration(res)
      setPromptDataId(res.id)
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        model: "pro",
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
        if (err.errors.fieldErrors) {
          handleErrors(err, { setter: setError })
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const onSubmit = handleSubmit(async data => {
    if (!data.book) {
      delete data.book
    }
    mutate(data)
  })
  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <TextField
            inputLabelProps={{ required: true }}
            label="Class/Grade"
            fullWidth
            placeholder="Ex: 5th Grade"
            error={Boolean(errors.grade)}
            helperText={errors.grade?.message}
            {...register("grade", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            {...register("curriculum", {
              required: { value: true, message: "Required" },
            })}
            inputLabelProps={{ required: true }}
            error={Boolean(errors.curriculum)}
            label="Curriculum"
            fullWidth
            placeholder="Ex: CBSE"
            helperText={errors.curriculum?.message}
          />
          <TextField
            inputLabelProps={{ required: true }}
            label="Subject"
            fullWidth
            placeholder="Ex: Enviornmental Studies"
            error={Boolean(errors.subject)}
            helperText={errors.subject?.message}
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
          />
          <Controller
            control={control}
            name="rubricScale"
            render={({ field: { value, onChange, onBlur } }) => (
              <Select
                inputLabelProps={{ required: true }}
                error={Boolean(errors.rubricScale)}
                helperText={errors.rubricScale?.message}
                label="Rubric Point Scale"
                options={proPointScaleOptions}
                placeholder="Ex: 7"
                fullWidth
                mountOnBody
                onBlur={onBlur}
                value={proPointScaleOptions.find(c => c.value === value)}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />{" "}
        </div>
        <TextField
          label="book"
          fullWidth
          placeholder="Ex: Environmental Studies Looking Around"
          error={Boolean(errors.book)}
          helperText={errors.book?.message}
          {...register("book")}
        />
        {/* @ts-expect-error: placebo rows prop error */}
        <TextField
          {...register("topic", {
            required: { value: true, message: "Required" },
          })}
          inputLabelProps={{ required: true }}
          error={Boolean(errors.topic)}
          label="Chapter/topic"
          fullWidth
          multiLine
          rows={3}
          placeholder="Ex: Environmental Impact Study"
          helperText={errors.topic?.message}
        />
        {/* @ts-expect-error: placebo rows prop error */}
        <TextField
          multiLine
          inputLabelProps={{ required: true }}
          fullWidth
          rows={3}
          label="Objective/Description"
          placeholder={`Ex: Analyze and present the effects of human activities on the local environment, focusing on how these activities impact plants, animals, and natural resources, and suggest ways to reduce negative impacts.`}
          error={Boolean(errors.assignmentObjectives)}
          helperText={errors.assignmentObjectives?.message}
          {...register("assignmentObjectives", {
            required: { value: true, message: "Required" },
          })}
        />
        <Button fullWidth type="submit" loading={isLoading}>
          Generate {getToolName(toolType)}
        </Button>
      </form>

      <AiToolsLoading
        type={toolType}
        open={isLoading}

        // onClose={() => {
        //   setLoading(false)
        // }}
      />
    </Paper>
  )
}

export default ProForm
