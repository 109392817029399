import { Typography } from "@suraasa/placebo-ui"

const StudentCatalog = () => {
  return (
    <div className="flex size-full items-center justify-between gap-2 rounded-lg border border-highlight-200 bg-highlight-50 p-1.5">
      <Typography variant="title4" className="text-onSurface-800 ">
        Student Catalog
      </Typography>
      <a
        href="https://assets.suraasa.com/itu/student-catalog.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <Typography className="text-primary-500 px-1 py-0.5">View</Typography>
      </a>
    </div>
  )
}

export default StudentCatalog
