import { toast, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Chip,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  InputLabel,
  Select,
} from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { Skill } from "api/resources/profile/types"
import { APIError } from "api/utils"
import ProfileContext from "features/Profile/context"
import { InfoCircle } from "iconoir-react"
import { useContext, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  dialogContent: {
    padding: theme.spacing(2.5, 3, 5.5, 3),
    "& > form": {
      display: "flex",
      gap: 24,
      flexDirection: "column",
    },
  },
}))

type Props = {
  onAdd: (data: Skill) => void
  handleBack?: () => void
}

const AddDialog = ({
  handleBack,
  onAdd,
}: Props & Pick<DialogProps, "open" | "onRequestClose">) => {
  const classes = useStyles()

  const {
    skills: { data: skills },
  } = useContext(ProfileContext)

  const {
    handleSubmit,
    reset,
    getValues,
    setError,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<{
    skills: { label: string; value: string; __isNew__: true }[]
  }>()

  const watchSkills = watch("skills")

  useEffect(() => {
    reset()
  }, [reset])

  const onSubmit = handleSubmit(async ({ skills }) => {
    if (skills.length >= 20) {
      toast.error("You can add a maximum of 20 skills.")
      return
    }

    for (const skill of skills) {
      const apiData = {
        skill_name: skill.value,
      }

      try {
        const res = await api.profile.skills.create({ data: apiData })
        onAdd(res)
      } catch (e) {
        if (e instanceof APIError) {
          if (typeof e.errors?.fieldErrors?.skill === "string") {
            setError("skills", {
              message: e.errors?.fieldErrors?.skill,
            })
          }
        }
      }
    }

    if (handleBack) handleBack()
  })

  const suggestions = useQuery({
    queryFn: () => api.profile.skills.listSuggestions(),
    queryKey: queries.profile.skillsSuggestions().queryKey,
    select: data =>
      // We don't want to show skills which are already in user's profile
      data.filter(
        suggestion => !skills.map(y => y.skillName).includes(suggestion)
      ),
  })

  const filteredSuggestions = suggestions.data?.filter(
    suggestion => !watchSkills?.map(x => x.value)?.includes(suggestion)
  )

  const handleSkillSuggestionClick = (skill: string) => {
    const values = getValues("skills")
    setValue("skills", [
      ...(values || []),
      { label: skill, value: skill, __isNew__: true },
    ])
  }
  const alreadyAddedSkills = skills?.length || 0

  const hasReachedMaxLimit =
    alreadyAddedSkills >= 20 ||
    (watchSkills?.length || 0) + alreadyAddedSkills > 20

  return (
    <>
      <DialogTitle onBack={handleBack}>Add New Skill</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {hasReachedMaxLimit && (
          <div className="mb-2 flex items-center gap-1 rounded-xl border border-critical-200 bg-critical-50 p-2 text-critical-800">
            <InfoCircle className="shrink-0" />
            <Typography variant="smallBody">
              You can only add up to 20 skills in your profile. Remove some
              existing skills from your profile to add more.
            </Typography>
          </div>
        )}
        <form onSubmit={onSubmit}>
          <div>
            <Controller
              control={control}
              name="skills"
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <div className="flex items-center justify-between ">
                    <InputLabel label="Skill" required />
                    {watchSkills?.length > 0 && (
                      <Button
                        variant="text"
                        onClick={() => {
                          setValue("skills", [])
                        }}
                      >
                        Clear
                      </Button>
                    )}
                  </div>
                  <Select
                    error={Boolean(errors.skills)}
                    helperText={errors.skills?.message}
                    isMulti
                    createable
                    options={[]}
                    placeholder="Ex: Rubric Creation"
                    value={value}
                    fullWidth
                    createableMessage="You can add skills by typing"
                    mountOnBody
                    isClearable={false}
                    noOptionsMessage={() => "Start typing..."}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                </>
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>

          {filteredSuggestions && filteredSuggestions.length > 0 && (
            <div className="relative rounded-xl border border-onSurface-200 bg-surface-50 p-2">
              <Typography variant="title4">
                Suggested skills based on your profile
              </Typography>
              <Typography variant="smallBody" className="mt-0.25">
                Select them to add them to your profile
              </Typography>

              <div className="mt-2 flex flex-wrap gap-1">
                {filteredSuggestions.map(skill => (
                  <Chip
                    type="button"
                    key={skill}
                    label={skill}
                    variant="outlined"
                    onClick={() => {
                      handleSkillSuggestionClick(skill)
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Save",
            variant: "filled",
            color: "primary",
            disabled: hasReachedMaxLimit,
            onClick: onSubmit,
            loading: isSubmitting,
          },
          secondary: {
            label: "Close",
          },
        }}
      />
    </>
  )
}

export default AddDialog
