import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { Button, TextField } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { APIError } from "api/utils"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"

type FormData = {
  careerAspiration: string
}

type Props = {
  onUpdate: (newData: string) => void
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  data: FormData
  open: boolean
}

const EditDialog = ({
  data: { careerAspiration },
  open,
  setOpen,
  onUpdate,
}: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      careerAspiration,
    },
  })

  useEffect(() => {
    reset({ careerAspiration })
  }, [careerAspiration, reset])

  const onSubmit = handleSubmit(async formData => {
    const apiData = {
      careerAspiration: formData.careerAspiration || null,
    }

    return api.profile
      .update({
        data: apiData,
      })
      .then(res => {
        onUpdate(formData.careerAspiration)
        toast.success("Successfully saved.")
        setOpen(false)
      })
      .catch(e => {
        if (e instanceof APIError) handleErrors(e, { setter: setError })
      })
  })

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Typography variant="strong">About</Typography>
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          <form onSubmit={onSubmit}>
            {/* @ts-expect-error random-types-issue */}
            <TextField
              charLimit={400}
              error={Boolean(errors.careerAspiration)}
              helperText={errors.careerAspiration?.message}
              maxRows={20}
              rows={8}
              autoFocus
              fullWidth
              placeholder="Write about yourself, your hobbies, your career aspiration etc."
              multiLine
              {...register("careerAspiration", {
                maxLength: {
                  value: 400,
                  message: "Try to keep it short",
                },
              })}
            />
          </form>
        </DialogBody>

        <DialogFooter>
          <Button onClick={onSubmit} loading={isSubmitting} size="sm">
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EditDialog
