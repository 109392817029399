import { Container } from "@suraasa/placebo-ui-legacy"
import { CoverStyle as CoverStyleEnum } from "api/resources/profile/types"
import ProfileContext from "features/Profile/context"
import { useContext, useState } from "react"

import Card from "./Card"
import CoverOverview from "./CoverOverview"

const coverStylesArray = Object.values(CoverStyleEnum).map(value => ({
  label: value,
  value: value,
}))

const CoverStyle = ({
  toggleChangeCoverStyle,
}: {
  toggleChangeCoverStyle: () => void
}) => {
  const { profile } = useContext(ProfileContext)
  const [currentType, setCurrentType] = useState<CoverStyleEnum>(
    profile.coverStyle || CoverStyleEnum.DUSK
  )

  return (
    <div>
      <CoverOverview
        coverStyleType={currentType}
        toggleChangeCoverStyle={toggleChangeCoverStyle}
      />
      <Container className="pb-4">
        <div className="flex flex-col flex-wrap gap-2 sm:flex-row">
          {coverStylesArray.map(item => (
            <Card
              key={item.label}
              title={item.label}
              type={item.value}
              coverStyleType={currentType}
              onClick={() => {
                setCurrentType(item.value)
              }}
            />
          ))}
        </div>
      </Container>
    </div>
  )
}

export default CoverStyle
