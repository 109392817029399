import { Avatar, toast, Typography } from "@suraasa/placebo-ui"
import { Button, Dialog, IconButton } from "@suraasa/placebo-ui-legacy"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { APIError } from "api/utils"
import clsx from "clsx"
import CustomCircularProgress from "components/CustomCircularProgress"
import { context } from "global/Context/context"
import { Check, Xmark } from "iconoir-react"
import debounce from "lodash/debounce"
import React, { useContext, useRef } from "react"
import { buildUserName, handleErrors } from "utils/helpers"
import { useWordCountText } from "utils/hooks/useWordCountText"

import styles from "./StartDiscussionDialog.module.css"

type Props = {
  open: boolean
  handleClose: () => void
  params?: { userPlannerItemId: number; discussion: number; course: string }
}

const MIN_WORD_COUNT = 30

const initialValue = ""

const StartDiscussionDialog = ({
  open,
  handleClose: handleCloseOnParent,
  params,
}: Props) => {
  const { authInfo, profile } = useContext(context)

  const queryClient = useQueryClient()

  const { value, setValue, wordCount } = useWordCountText({ initialValue })

  const name = authInfo ? buildUserName(authInfo.user) : ""

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  function onInput() {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = `182px`
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    }
  }

  const handleClose = () => {
    handleCloseOnParent()
  }

  const isAllowedToPost = wordCount >= MIN_WORD_COUNT

  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      api.discussions.postDiscussion({
        data: {
          text: value,
          ...params,
        },
        params: { fields: ["user", "text", "date_created"] },
      }),
    onSuccess: () => {
      /**
       * We cannot update our local list right now because it will cause the next page to have duplicates.
       */
      queryClient.invalidateQueries({
        queryKey: queries.discussions.list().queryKey,
      })
      // queryClient.setQueryData(
      //   queries.discussions.list().queryKey,
      //   (oldQueryData?: InfiniteData<PaginatedAPIResponse<Discussion[]>>) => {
      //     if (oldQueryData) {
      //       const oldDataCopy: InfiniteData<
      //         PaginatedAPIResponse<Discussion[]>
      //       > = JSON.parse(JSON.stringify(oldQueryData))

      //       oldDataCopy.pages[0].data.unshift({
      //         ...res,
      //         totalComments: 0,
      //         upvotes: 0,
      //         voted: false,
      //         comments: [],
      //       })

      //       return oldDataCopy
      //     } else {
      //       queryClient.invalidateQueries({
      //         queryKey: queries.discussions.list().queryKey,
      //       })
      //       return oldQueryData
      //     }
      //   }
      // )
      toast.success(
        "Discussion posted successfully. You can now proceed to the next item"
      )
      handleClose()
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const onChangeText = debounce(function (value: string) {
    setValue(value)
  }, 750)

  const getActionButton = () => {
    if (isAllowedToPost) {
      return (
        <div className="flex size-4 items-center justify-center rounded-full bg-success-500 text-white">
          <Check width={24} height={24} strokeWidth={2.5} />
        </div>
      )
    }
    return (
      <div className="flex items-center">
        <CustomCircularProgress
          className="me-1"
          totalValue={MIN_WORD_COUNT}
          currentValue={wordCount}
          hideValue
        />
        <Typography variant="smallBody" className="w-6">
          {wordCount}/{MIN_WORD_COUNT}
        </Typography>
      </div>
    )
  }

  return (
    <Dialog open={open} onRequestClose={handleClose}>
      <div className="flex w-full justify-end pr-1.5 pt-1.5">
        <IconButton color="black" onClick={handleClose}>
          <Xmark />
        </IconButton>
      </div>
      <div className="flex h-full min-h-[280px] grow px-2 py-1.5">
        <div className="me-1">
          <Avatar className="size-6" src={profile.picture} name={name} />
        </div>
        <div className="flex grow flex-col">
          <Typography className="mb-0.5" variant="strong">
            {name}
          </Typography>

          <textarea
            placeholder="Type here..."
            className={clsx("mb-1.5 w-full", styles.customTextArea)}
            ref={textAreaRef}
            onInput={onInput}
            autoFocus
            defaultValue={value}
            onChange={(event: { target: { value: string } }) => {
              onChangeText(event.target.value)
            }}
          />

          <div className="flex justify-end">
            <div className="me-2">{getActionButton()}</div>

            <Button
              onClick={() => {
                mutate()
              }}
              loading={isLoading}
              disabled={!isAllowedToPost}
              size="sm"
            >
              Post Discussion
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default StartDiscussionDialog
