import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Select,
  TextField,
} from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import React, { useEffect, useState } from "react"
import { handleErrors } from "utils/helpers"

type Props = {
  open: boolean
  handleClose: () => void
  isDiscussion?: boolean
  id: string
  type: "reply" | "comment" | "discussion"
  firstName: string
}

const reportTypeChoices = [
  { value: 1, name: "Spam" },
  { value: 2, name: "Phishing" },
  { value: 3, name: "Violence" },
  { value: 4, name: "Hate speech" },
  { value: 5, name: "Terrorist content" },
  { value: 6, name: "Harassment" },
  { value: 7, name: "Bullying" },
  { value: 8, name: "Threats" },
  { value: 9, name: "Sexually explicit material" },
  { value: 10, name: "Child exploitation" },
  { value: 11, name: "Impersonation" },
  { value: 12, name: "Personal and confidential information" },
  { value: 13, name: "Illegal activities" },
  { value: 14, name: "Public streaming" },
  { value: 15, name: "Copyright infringement" },
  { value: 16, name: "Content use and submission" },
  { value: 17, name: "Other" },
]

const ReportDialog = ({ open, handleClose, id, type, firstName }: Props) => {
  const [reportType, setReportType] = useState<number | undefined>()
  const [reason, setReason] = useState("")
  const [error, setError] = useState<{ reportType?: string; reason?: string }>({
    reportType: "",
    reason: "",
  })

  const apiList = () => {
    switch (type) {
      case "discussion":
        return api.discussions.reportDiscussion
      case "comment":
        return api.discussions.reportComment
      case "reply":
        return api.discussions.reportReply
    }
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: (data?: { reason: string; report_type: number }) => {
      const mutationApi = apiList()
      return mutationApi({
        params: { id },
        ...(data && { data }),
      })
    },
    onSuccess: () => {
      handleClose()
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  useEffect(() => {
    if (!open) {
      setReportType(undefined)
      setReason("")
      setError({ reportType: "", reason: "" })
    }
  }, [open])

  const handleSubmitReport = () => {
    setError({ reason: "", reportType: "" })

    if (!reportType) {
      setError(p => ({ ...p, reportType: "Required" }))
      return
    }
    if (!reason) {
      setError(p => ({ ...p, reason: "Required" }))
      return
    }

    if (reason && reportType) {
      mutate({ reason: reason, report_type: reportType })
    }
  }

  return (
    <Dialog open={open} onRequestClose={handleClose}>
      <DialogTitle>Report {firstName}</DialogTitle>

      <DialogContent>
        <Select
          mountOnBody
          className="mb-2"
          value={reportTypeChoices.find(option => option.value === reportType)}
          onChange={v => setReportType(v?.value)}
          name="report_type"
          placeholder="Please select appropriate reason"
          label="Reason for reporting"
          error={Boolean(error?.reportType)}
          helperText={error?.reportType || ""}
          fullWidth
          options={reportTypeChoices}
          getOptionLabel={option => option.name}
          getOptionValue={option => String(option.value)}
        />
        <TextField
          label="Tell us more"
          name="describe"
          placeholder="Type here..."
          className="mb-0.5"
          multiLine
          onChange={(e: { target: { value: string } }) => {
            setReason(e.target.value)
          }}
          error={Boolean(error?.reason)}
          helperText={error?.reason || ""}
          rows={4}
          maxLength={256}
          charLimit={256}
          fullWidth
        />
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Report",
            color: "critical",
            type: "submit",
            loading: isLoading,
            onClick: handleSubmitReport,
          },
        }}
      />
    </Dialog>
  )
}

export default ReportDialog
