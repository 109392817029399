import { Typography } from "@suraasa/placebo-ui"
import { Divider, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import BackButton from "components/BackButton"
import React from "react"
import { useTheme } from "react-jss"
import { useNavigate } from "react-router-dom"
import routes from "routes"

type Props = {
  title: string
}

const MobileHeadBar = ({ title }: Props) => {
  const navigate = useNavigate()
  const theme = useTheme<Theme>()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  if (isSmUp) return null

  return (
    <div>
      <BackButton goBack={() => navigate(routes.settings)} />
      <Typography variant="title2" className="mt-2 text-onSurface-800">
        {title}
      </Typography>
      <Divider weight="light" color="onSurface.200" className="my-3" />
    </div>
  )
}

export default MobileHeadBar
