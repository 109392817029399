import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { popupWindow } from "components/ShareSheet"
import ShareReferralSheet from "features/ITO/components/ShareReferralSheet"
import SwitzerTypography from "features/ITO/components/SwitzerTypography"
import { useOlympiadPrices } from "features/ITO/utils/hooks/useOlympiadPrices"
import { ShareAndroid } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"
import { getITOShareReferralText, getPlatformURL } from "utils/helpers"
import useCurrentCountry from "utils/hooks/useCurrentCountry"

import WhatsappIcon from "./assets/whatsapp-icon.svg"

type Props = {
  referralCode: string
}

const useStyles = createUseStyles(theme => ({
  root: {
    border: "1px solid #F8FAFC",
    background: "#FFF",
    boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.10)",
    backgroundImage:
      "linear-gradient(80deg, rgba(255,255,255,1) 65%, rgba(105,219,123,0.2) 100%)",
  },
  shareBtn: {
    color: "white",
    backgroundColor: `#25D366 !important`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textBtn: {
    border: `1px solid ${theme.colors.onSurface[200]}`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))
const ReferTeachersCard = ({ referralCode }: Props) => {
  const classes = useStyles()
  const country = useCurrentCountry()

  const isIndia = country?.isoCode == "IN"
  // const { itoDetails } = useContext(GlobalContext)
  const { getPrice } = useOlympiadPrices({ edition: "2023" })

  const shareURL = getPlatformURL("suraasa", `/ito/?referral=${referralCode}`)
  const WHATSAPP_URL = `https://api.whatsapp.com/send?text=${getITOShareReferralText(
    shareURL,
    { encode: true, discount: isIndia }
  )}`

  return (
    <>
      <div className={clsx(classes.root, "rounded-none p-3 sm:rounded-xl")}>
        <SwitzerTypography
          variant="title2"
          className="mb-1"
          style={{
            fontFamily: "Switzer Bold",
          }}
        >
          {isIndia ? "Invite Teachers. Earn Cash." : "Invite Teachers"}
        </SwitzerTypography>

        {isIndia ? (
          <Typography>
            Invite Teachers in your network to register through your unique
            referral link. For each successful registration, you earn{" "}
            {getPrice("referralReward")} cash.
          </Typography>
        ) : (
          <Typography>
            Share the news of you joining this revolution with your teacher
            friends and become the star of your group.
          </Typography>
        )}

        <div className="mt-3 flex flex-col items-center gap-2 sm:flex-row">
          <Button
            rounded
            startAdornment={<img src={WhatsappIcon} alt="whatsapp" />}
            className={classes.shareBtn}
            onClick={() => {
              popupWindow(WHATSAPP_URL, 650, 650)
            }}
          >
            <div className="flex items-center gap-1">
              {/* <img alt="icon" height={20} src={WhatsappIcon.src} width={20} /> */}
              Share on WhatsApp
            </div>
          </Button>

          <div className="w-full sm:w-auto">
            <ShareReferralSheet
              url={shareURL}
              render={handleOpen => {
                return (
                  <Button
                    rounded
                    startAdornment={<ShareAndroid />}
                    onClick={handleOpen}
                    variant="outlined"
                    color="black"
                    className={classes.textBtn}
                  >
                    Share
                  </Button>
                )
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ReferTeachersCard
