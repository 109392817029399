import { Typography } from "@suraasa/placebo-ui"
import React from "react"

const HowToParticipate = ({ showTitle = true }) => (
  <div>
    {showTitle && (
      <Typography
        style={{ fontFamily: "Space Grotesk Bold" }}
        className="mt-4 !text-2xl"
      >
        How to Participate?
      </Typography>
    )}
    <Typography className="mt-2 text-common-black-700">
      You are eligible to participate in this edition of Teacher Impact Awards
      if you are:
      <ol className="ml-1 mt-1">
        <li>A PgCTL Learner or Graduate</li>
        <li>Among the Top 100 performers from ITO 2023</li>
        <li>Among the Top 1 Percentile from ITO 2023</li>
      </ol>
      <br />
      To participate and get a chance to be recognised as the top impact
      creator, follow these steps:
    </Typography>

    <ol className="ml-1 mt-1">
      {[
        "Create a Short Video of yourself describing your classroom practice and your achievements as a teacher thus far in your career. What impact has your teaching had on others?",
        "Upload your video at the bottom of this page by 15th January 2024.",
        "Attend the live online event later (date to be announced) where education leaders will announce the winners.",
      ].map((item, i) => (
        <li key={i}>
          <Typography className="mt-0.5 inline text-common-black-700">
            {item}
          </Typography>
        </li>
      ))}
    </ol>
  </div>
)

export default HowToParticipate
