import api from "api"
import { differenceInMinutes } from "date-fns"
import ClassFeedbackCard from "features/ClassFeedbackCard"
import { UserFeedbackQueue } from "firebaseInit"
import { context } from "global/Context/context"
import { useContext, useEffect, useState } from "react"

// const isAssignmentExpired = (date: string) => {
//   const dueDate = new Date(date)

//   const now = new Date()

//   return isBefore(dueDate, now)
// }

const GlobalCards = () => {
  const [feedbackToShow, setFeedbackToShow] = useState<UserFeedbackQueue>()
  const { isAuthenticated } = useContext(context)
  // const [shiftCardLeft, setShiftCardLeft] = useState(false)

  // const approachingDueDateList = dueAssignmentList?.filter(
  //   item => !isAssignmentExpired(item.dueDate)
  // )
  // const expiredList = dueAssignmentList?.filter(item =>
  //   isAssignmentExpired(item.dueDate)
  // )

  useEffect(() => {
    async function checkForClassFeedback() {
      try {
        const res = await api.learningItems.classes.getLastAttended()
        if (res.status === "present") {
          const minutesSince = differenceInMinutes(
            new Date(res.classSchedule.endTime),
            new Date()
          )
          const twoWeeksInMinutes = 20160

          if (Math.abs(minutesSince) < twoWeeksInMinutes) {
            const exists = await checkIfFeedbackExists({
              itemId: res.classSchedule.id,
              itemType: 4,
            })

            if (!exists) {
              setFeedbackToShow({
                itemId: res.classSchedule.id,
                itemType: "class",
                itemName: res.classSchedule.classId.name,
              })
            }
          }
        }
      } catch (e) {
        // console.log(e)
      }
    }

    if (!isAuthenticated) return

    checkForClassFeedback()
  }, [isAuthenticated])

  const checkIfFeedbackExists = async ({
    itemType,
    itemId,
  }: {
    itemType: 1 | 4
    itemId: number
  }) => {
    try {
      await api.feedback.retrieve({
        urlParams: {
          itemType,
          itemId,
        },
      })
      return true
    } catch (e) {
      return false
    }
  }

  // const toggleShiftCardLeft = (state: boolean) => {
  //   setShiftCardLeft(state)
  // }

  return (
    <div id="globalCards">
      {/* {feedbackToShow && feedbackToShow?.itemType === "course" && (
        <CourseFeedbackCard
          courseName={feedbackToShow.itemName}
          plannerId={feedbackToShow.itemId}
          onClose={() => {
            removeFromUserFeedbackQueue({
              itemType: feedbackToShow.itemType,
              itemName: feedbackToShow.itemName,
            })
          }}
        />
      )} */}
      {/* {approachingDueDateList && approachingDueDateList.length > 0 && (
        <AssignmentDueDateCard
          data={approachingDueDateList}
          toggleShiftCardLeft={toggleShiftCardLeft}
        />
      )}
      {expiredList && expiredList.length > 0 && (
        <AssignmentDueDateCard
          data={expiredList}
          isExpired
          shiftLeft={shiftCardLeft}
        />
      )} */}

      {feedbackToShow && feedbackToShow?.itemType === "class" && (
        <ClassFeedbackCard
          itemName={feedbackToShow.itemName}
          itemId={feedbackToShow.itemId}
        />
      )}
    </div>
  )
}

export default GlobalCards
