import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Mail } from "iconoir-react"
import React from "react"

import styles from "./CertificateContactUs.module.css"

const CertificateContactUs = () => {
  return (
    <a
      href="mailto:care@suraasa.com"
      className={clsx(
        styles.container,
        "my-4 flex items-center justify-between rounded-2xl border p-3"
      )}
    >
      <Typography>
        Found something wrong on certificates? Contact us at{" "}
        <span className="text-interactive-500">care@suraasa.com.</span>
      </Typography>
      <Mail className={clsx("size-5 shrink-0 p-1", styles.icon)} />
    </a>
  )
}

export default CertificateContactUs
