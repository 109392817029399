import { Typography } from "@suraasa/placebo-ui"
import { VideoSummaryResponse } from "api/resources/aiTools/types"
import Paper from "features/AItools/components/Paper"
import { useContext } from "react"

import { AIToolsContext } from "../../context"
import MarkdownRenderer from "../MarkdownRenderer"
import RegenerateOutput from "../RegenerateOutput"
// import styles from "./ActivityPlanner.module.css"

const VideoSummaryOutput = ({ output, ...props }: VideoSummaryResponse) => {
  const { overviewData } = useContext(AIToolsContext)
  return (
    <div className="mb-4 flex flex-col gap-2">
      <Paper
        className="mb-3 pt-0"
        endSlot={
          <RegenerateOutput
            copyText={output}
            isPositiveResponse={props.isPositiveResponse}
          />
        }
      >
        <Typography variant="title2" className="my-3">
          {overviewData?.title}
        </Typography>
        <Typography variant="title3" className="my-3">
          Summary
        </Typography>
        <MarkdownRenderer>{output}</MarkdownRenderer>
      </Paper>
    </div>
  )
}

export default VideoSummaryOutput
