import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React, { useEffect, useState } from "react"

import styles from "./TextSlider.module.css"

const TextSlider = ({
  textArray,
  interval = 5000,
  className,
}: {
  textArray: string[]
  interval?: number
  className?: string
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [animationClass, setAnimationClass] = useState(styles.slideIn)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnimationClass(styles.slideOut)
      setTimeout(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % textArray.length)
        setAnimationClass(styles.slideIn)
      }, 1000) // Adjust this delay based on your animation duration
    }, interval)

    return () => clearInterval(intervalId)
  }, [currentIndex, textArray.length, interval])

  return (
    <div className={clsx(className, styles.textSlider)}>
      <Typography variant="title3" className={animationClass}>
        {textArray[currentIndex]}
      </Typography>
    </div>
  )
}

export default TextSlider
