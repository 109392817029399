import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { Button, Theme } from "@suraasa/placebo-ui-legacy"
import api from "api"
import { OTPResponse, PhoneNumber } from "api/resources/settings/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import OTPInput from "react-otp-input"
import { handleErrors } from "utils/helpers"

const useStyles = createUseStyles((theme: Theme) => ({
  containerStyle: {
    justifyContent: "space-between",
  },

  inputStyle: {
    borderRadius: 4,
    width: `41px !important`,
    height: 50,
    border: `2px solid ${theme.colors.onSurface[500]}`,
    ...theme.typography.title2,

    "&:focus": {
      outline: `2px solid ${theme.colors.interactive[400]}`,
      outlineOffset: "1px",
    },
  },
  errorStyle: {
    border: `2px solid ${theme.colors.critical[500]}`,
    color: theme.colors.critical[500],
  },
}))

const VerifyPhoneNumberDialog = ({
  otpData,
  close,
  open,
  onVerify,
  phoneNumber,
}: {
  otpData: OTPResponse
  close: () => void
  onVerify: (token: string) => void
  onBack: () => void
  phoneNumber: PhoneNumber | null
  open: boolean
}) => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const [resendAt, setResendAt] = useState<string | null>(null)

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<{ otp: string }>()

  const onSubmit = handleSubmit(async ({ otp }) => {
    setLoading(true)
    try {
      const res = await api.settings.verifyOtp({
        data: {
          token: otpData.token,
          otp,
        },
      })
      onVerify(res.token)
    } catch (err) {
      if (err instanceof APIError) {
        handleErrors(err, { setter: setError })
      }
    }
    setLoading(false)
  })

  const resendOtp = async () => {
    try {
      const res = await api.settings.sendOTP({
        data: {
          token: otpData.token,
        },
      })
      toast.info("We've sent you a new OTP")
      setResendAt(res.resendAt)
    } catch (e) {
      if (e instanceof APIError) {
        handleErrors(e)
      }
    }
  }
  return (
    <Dialog open={open} onOpenChange={() => close()}>
      <DialogContent className="max-w-[400px]">
        <form onSubmit={onSubmit}>
          <DialogHeader>
            <DialogTitle>Verify Your Phone Number</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <div className="p-1">
              <Typography className="mb-2">
                OTP sent to{" "}
                <b>
                  {phoneNumber?.code} {phoneNumber?.number}
                </b>
              </Typography>

              <Controller
                control={control}
                name="otp"
                render={({ field }) => (
                  <OTPInput
                    numInputs={6}
                    {...field}
                    containerStyle={classes.containerStyle}
                    inputStyle={clsx(classes.inputStyle, {
                      [classes.errorStyle]: Boolean(errors.otp),
                    })}
                    inputType="number"
                    renderInput={props => <input {...props} />}
                    shouldAutoFocus
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
              {Boolean(errors.otp) && (
                <Typography
                  className="mt-1 text-critical-500"
                  variant="smallBody"
                >
                  {errors.otp?.message}
                </Typography>
              )}

              {/* <div className="flex flex-wrap items-center mt-3">
              <Typography className="me-1 shrink-0">
                Didn’t receive OTP?
              </Typography>
              <ResendOTPButton
                resendAt={resendAt ?? otpData.resendAt}
                text="Send Again"
                onClick={() => resendOtp()}
              />
            </div> */}
            </div>
          </DialogBody>

          <DialogFooter>
            <Button size="sm" type="submit">
              Verify
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default VerifyPhoneNumberDialog
