import { Button, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { PlannerListItem } from "api/resources/learningItems/types"
import CourseFallback from "assets/Placeholder/course_placeholder.svg"
import clsx from "clsx"
import Card from "components/Card"
import ImageBackgroundCard from "features/LearningItems/ImageBackgroundCard"
import { context } from "global/Context/context"
import { usePostHog } from "posthog-js/react"
import { useContext } from "react"
import { useTheme } from "react-jss"
import { Link } from "react-router-dom"
import routes from "routes"
import { PropsWithClassName } from "utils/types"

import CourseInfoAndProgress from "../CourseInfoAndProgress"
import styles from "./ResumeCourseCard.module.css"

type Props = {
  item: PlannerListItem
  className?: string
  header?: JSX.Element
}

const ResumeCourseCard = ({
  item,
  className,
  header,
}: PropsWithClassName<Props>) => {
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const posthog = usePostHog()

  const { enrollments, learningRestrictions } = useContext(context)

  const getLink = () => {
    const defaultLink = routes.parentItem
      .replace(":learningItemType", item.learningItemType)
      .replace(":slug", item.slug)

    if (!enrollments) return defaultLink
    const currentItem = enrollments.find(x => x.slug === item.slug)

    if (currentItem?.parents.length) {
      const parent = currentItem.parents[0]

      return routes.parentItemCourse
        .replace(":learningItemType", parent.type)
        .replace(":parentSlug", parent.slug)
        .replace(":slug", item.slug)
    }

    return defaultLink
  }

  const parent = enrollments?.find(x => x.slug === item.slug)?.parents || []

  // If the parent was restricted or the individual course was restricted
  const isRestricted =
    learningRestrictions?.find(i => i.learningItem.slug === item.slug) ||
    learningRestrictions?.some(x => x.learningItem.slug === parent[0]?.slug)

  const completedItems = item.progress?.completedUserPlannerItems ?? 0
  const totalItems = item.progress?.totalUserPlannerItems ?? 0

  if (isRestricted) return null

  return (
    <>
      {header && header}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        to={getLink()}
        className={clsx("block", className)}
        onClick={() => {
          posthog.capture("resume_course_card_clicked", {
            slug: item.slug,
          })
        }}
      >
        <ImageBackgroundCard background={item.image} className="rounded-2xl">
          <Card padding={2}>
            <div className="flex flex-col justify-between sm:flex-row sm:items-center">
              <div className="flex grow flex-col sm:flex-row sm:items-center">
                <img
                  alt="course"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = CourseFallback
                  }}
                  src={item.image ?? CourseFallback}
                  className="mb-2 h-[153px] shrink-0 rounded-xl border border-surface-100 object-cover sm:mb-0 sm:me-2 sm:h-[86px] md:w-18"
                />

                <CourseInfoAndProgress
                  courseCode={item.courseCode}
                  currentItemNumber={completedItems}
                  numberOfItems={totalItems}
                  title={item.name}
                  showCount={item.learningItemType === "course"}
                  highlightCourse
                />
              </div>
              <Button
                className={clsx("mt-2 sm:mt-0", styles.cardButtonBg)}
                fullWidth={isXs}
              >
                {completedItems === 0 ? "Start Learning" : "Continue Learning"}
              </Button>
            </div>
          </Card>
        </ImageBackgroundCard>
      </Link>
    </>
  )
}

export default ResumeCourseCard
