import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@suraasa/placebo-ui-legacy"
import AsyncBuilder from "components/AsyncBuilder"
import copy from "copy-to-clipboard"
import { useEffect, useState } from "react"
import {
  browserName,
  browserVersion,
  isAndroid,
  isMacOs,
  isSafari,
  isSamsungBrowser,
  osName,
} from "react-device-detect"
import { browserIsSupportedForITO, isAppleDevice } from "utils/constants"
import { generateAuthCode } from "utils/helpers"

type Props = {
  open: boolean
}

const getBrowserDownloadLink = () => {
  if (isAppleDevice) {
    if (isMacOs) {
      // window.alert(
      //   "Looks like you don't have 'Safari Browser' installed. Please install Google Chrome to attempt the Olympiad."
      // )
      return "https://www.google.com/intl/en_in/chrome/"
    }
    return "https://apps.apple.com/in/app/safari/id1146562112"
  }

  if (isAndroid) {
    return "https://play.google.com/store/apps/details?id=com.android.chrome&hl=en_IN&gl=US"
  }

  return "https://www.google.com/intl/en_in/chrome/"
}

const getBrowserUpdateLink = () => {
  if (isSafari) {
    if (isMacOs) {
      return "https://support.apple.com/en-gb/HT201541"
    }
    return "https://apps.apple.com/in/app/safari/id1146562112"
  }

  if (isSamsungBrowser) {
    return "https://play.google.com/store/apps/details?id=com.sec.android.app.sbrowser&hl=en_IN&gl=US"
  }

  if (isAndroid) {
    return "https://play.google.com/store/apps/details?id=com.android.chrome&hl=en_IN&gl=US"
  }

  return "https://www.google.com/intl/en_in/chrome/update/"
}

const UnsupportedBrowserDialog = ({ open }: Props) => {
  const [authCode, setAuthCode] = useState<string | null>(null)
  const [copied, setCopied] = useState(false)

  const generateNewAuthCode = async () => {
    const authCode = await generateAuthCode()
    setAuthCode(authCode)
  }

  useEffect(() => {
    if (open) generateNewAuthCode()
  }, [open])

  const copyTestLink = async () => {
    const url = new URL(window.location.href)
    if (authCode) url.searchParams.append("code", authCode)

    setCopied(true)
    copy(url.toString())

    setTimeout(() => {
      setCopied(false)
      generateNewAuthCode()
    }, 3000)
  }

  const downloadLink = getBrowserDownloadLink()
  const updateLink = getBrowserUpdateLink()

  if (browserIsSupportedForITO === "unsupported_version")
    return (
      <Dialog open={open}>
        <DialogTitle>Your browser is needs to be updated!</DialogTitle>
        <DialogContent>
          <Typography className="mb-2">
            You are currently using {browserName} {browserVersion} on {osName}.
          </Typography>
          <div className="mb-2 font-semibold">
            Please use the latest version of this browser to attempt the
            Olympiad.
          </div>
          <div className="flex gap-2">
            <ol>
              <li>
                Click the button below to download the latest version of your
                browser.
              </li>
              <li>
                Once updated, you can open this page again and you should be
                able to attempt the Olympiad.
              </li>

              {isSafari && (
                <a
                  target="_blank"
                  href="https://www.google.com/intl/en_in/chrome/"
                  rel="noreferrer"
                >
                  <Typography
                    variant="smallBody"
                    className="mt-2 text-onSurface-500 hover:underline"
                  >
                    If you&apos;re unable to update your Safari Browser then
                    please click here to install Google Chrome.
                  </Typography>
                </a>
              )}
            </ol>
          </div>
        </DialogContent>
        <DialogFooter>
          <Button fullWidth component="a" target="_blank" href={updateLink}>
            Update my browser
          </Button>
        </DialogFooter>
      </Dialog>
    )

  if (browserIsSupportedForITO === "unsupported_vendor")
    return (
      <Dialog open={open}>
        <DialogTitle>Your browser is not supported!</DialogTitle>
        <DialogContent>
          <Typography className="mb-2">
            You are currently using {browserName} {browserVersion} on {osName}.
          </Typography>
          <div className="mb-2 font-semibold">
            Please use{" "}
            {isAppleDevice ? (
              <span>
                <span>Safari Browser</span>
                <img
                  className="ms-0.5 inline"
                  height={24}
                  width={24}
                  src="/assets/logos/safari-logo-browser.png"
                  alt="Safari"
                />
              </span>
            ) : (
              <span>
                <span>Google Chrome</span>
                <img
                  className="ms-0.5 inline"
                  height={24}
                  width={24}
                  src="/assets/logos/google-chrome-logo.png"
                  alt="Google Chrome"
                />
              </span>
            )}{" "}
            to attempt the Olympiad.
          </div>
          <div className="flex gap-2">
            <ol>
              <li>Copy the test link using the button below.</li>
              <li>
                <p className="inline">
                  Open {isAppleDevice ? "Safari" : "Google Chrome"}.{" "}
                </p>

                {downloadLink && (
                  <a
                    href={downloadLink}
                    target="_blank"
                    rel="noreferrer"
                    className="text-interactive-500 hover:underline"
                  >
                    If you do not have it installed then please click here to
                    install it.
                  </a>
                )}
              </li>
              <li>
                Paste the link {isAppleDevice ? "Safari" : "Google Chrome"}{" "}
                address bar and press <kbd>Enter</kbd>.
              </li>
            </ol>
          </div>
        </DialogContent>
        <DialogFooter>
          <AsyncBuilder
            render={options => (
              <Button fullWidth {...options}>
                {copied ? "Link copied!" : "Copy Test Link"}
              </Button>
            )}
            handler={copyTestLink}
          />
        </DialogFooter>
      </Dialog>
    )

  return null
}

export default UnsupportedBrowserDialog
