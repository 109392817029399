import { Typography } from "@suraasa/placebo-ui"
import { theme } from "@suraasa/placebo-ui-legacy"
import React from "react"

interface Props {
  title?: string
  additionalInfo?: JSX.Element
  subtitle?: string
  action?: JSX.Element
  backgroundColor?: string
}

const TitleCard = ({
  title,
  additionalInfo,
  subtitle,
  action,
  backgroundColor = theme.colors.highlight[50],
}: Props) => {
  return (
    <div
      className="-mx-2 flex flex-col rounded-none px-2 py-3 sm:mx-0 sm:rounded-lg"
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="flex flex-wrap items-center justify-between gap-2">
        <div className="flex flex-col">
          <Typography variant="title3">{title}</Typography>
          {subtitle && (
            <Typography variant="smallBody" className="mt-1 text-onSurface-500">
              {subtitle}
            </Typography>
          )}
        </div>

        {additionalInfo}

        {action}
      </div>
    </div>
  )
}

export default TitleCard
