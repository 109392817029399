import { Popover, PopoverContent, PopoverTrigger } from "@suraasa/placebo-ui"
import api from "api"
import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { useScrollShiftOffset } from "utils/hooks/useScrollShiftOffset"

import ChatBox from "./ChatBox"
import Launcher from "./Launcher"

export const storageKeys = {
  conversationId: "chatbot-conversationId",
  messages: "chatbot-messages",
  agentName: "chatbot-agentName",
}

export type AlertState =
  | { show: false; text?: never; message?: never }
  | {
      show: true
      text: string | number
      message?: string
    }

const ChatBot = () => {
  const [initMessageSent, setInitMessageSent] = useState(false)
  const [open, setOpen] = useState(false)
  const [conversationId, setConversationId] = useState(
    sessionStorage.getItem(storageKeys.conversationId) || ""
  )
  const [alert, setAlert] = useState<AlertState>({
    show: false,
  })

  const onOpenClick = (open: boolean) => {
    if (!open) {
      setAlert({
        show: false,
      })
    }

    setOpen(open)
  }
  const createConversation = async () => {
    try {
      const res = await api.sales.chatbot.createConversation({ data: {} })
      if (res.id) {
        setConversationId(res.id.toString())
        sessionStorage.setItem(storageKeys.conversationId, res.id.toString())
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (!conversationId) {
      createConversation()
    }
    return () => {}
  }, [conversationId])

  useScrollShiftOffset(open)

  return (
    <div
      // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
      className={clsx(
        open &&
          "fixed right-0 z-[21] h-screen w-screen bg-black bg-opacity-20 md:top-0"
      )}
    >
      <div className="fixed bottom-10 right-0 z-[22] flex">
        <Popover open={open} onOpenChange={o => onOpenClick(o)}>
          <PopoverTrigger className={clsx(open && "hidden md:block")}>
            <div className="flex items-end">
              <Launcher setOpen={() => {}} alert={alert} />
            </div>
          </PopoverTrigger>
          <PopoverContent
            align="end"
            side="left"
            className="-ml-0.5 w-screen max-w-[100vw] !border-none !p-0 !shadow-none md:w-auto"
            style={{
              backgroundColor: "unset !important",
            }}
          >
            {open && conversationId && (
              <div className="md:mr-1">
                <ChatBox
                  onClose={() => onOpenClick(false)}
                  conversationId={conversationId}
                  initMessageSent={initMessageSent}
                  onInitMessageSent={x => setInitMessageSent(x)}
                />
              </div>
            )}
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}

export default ChatBot
