export type Email = {
  /**
   * `id` can be `"-1"` when `signupEmail=true` but the email has not been verified yet
   */
  id: string
  email: string
  isPrimary: true | null
  signupEmail: true | null
  isVerified: boolean
}

export type PhoneNumber = {
  code: string
  number: string
}

export type ContactDetails = {
  phoneNumber: PhoneNumber | null
  emails: Email[]
}

export interface UserPreference {
  id: string
  value: string
  setting: {
    name: string
    description: string
    slug: string
    type: string
    subcategory: {
      name: string
      slug: string
      category: {
        name: string
        slug: string
      }
    }
  }
}

export interface UpdateUserPreferenceResponse {
  id: string
  value: string
}

export const SETTINGS = {
  preferredLanguage: "preferred-language",
  pushNotifications: "push-notifications",
  emailNotifications: "email-notifications",
  eventBasedEmails: "event-based-emails",
  promotionalEmails: "promotional-emails",
  timezone: "timezone",
} as const

export type OTPResponse = {
  token: string
  resendAt: string
}

export enum SocialAuthProvider {
  GOOGLE = "google",
  FACEBOOK = "facebook",
  APPLE = "apple",
}

export type SocialAccount = {
  id: string
  provider: SocialAuthProvider
  email: string
  scope: null | string
}
