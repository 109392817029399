import { Typography } from "@suraasa/placebo-ui"
import { IconButton } from "@suraasa/placebo-ui-legacy"
import {
  AssessmentQuestion,
  QuestionStatus,
  QuestionType,
} from "api/resources/assessments/types"
import { getQuestionStatus } from "features/Assessments/helpers"
import { Bookmark } from "iconoir-react"
import React from "react"

type Props = {
  question: AssessmentQuestion
  questionNumberOverride?: string
  toggleMarkForReview: (question: AssessmentQuestion) => void
}

const QuestionHeader = ({
  toggleMarkForReview,
  questionNumberOverride,
  question,
}: Props) => {
  return (
    <div className="flex items-center justify-between">
      <div>
        <Typography variant="preTitle" className="pb-0.5 text-onSurface-600">
          {question.questionType === QuestionType.SINGLE_CORRECT &&
            "Single Correct"}
          {question.questionType === QuestionType.MULTIPLE_CORRECT &&
            "Multiple Correct"}
          {question.questionType === QuestionType.FILL_IN_THE_BLANKS &&
            "Fill in the blanks"}
          {question.questionType === QuestionType.CASE_STUDY && "Case Study"}
        </Typography>
        <Typography variant="title3" className="pb-1.5">
          Question {questionNumberOverride ?? question.questionNumber}
        </Typography>
      </div>

      {!questionNumberOverride && (
        <IconButton color="black" onClick={() => toggleMarkForReview(question)}>
          {getQuestionStatus(question) === QuestionStatus.MARKED_FOR_REVIEW ? (
            <Bookmark fill="black" />
          ) : (
            <Bookmark />
          )}
        </IconButton>
      )}
    </div>
  )
}

export default QuestionHeader
