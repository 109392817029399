import { CircularProgress } from "@suraasa/placebo-ui-legacy"
import { useContext } from "react"

import { AIToolsContext } from "../../context"
import InputDisplay from "../../helpers/InputDisplay"
import UnitPlanDivisionOutput from "./UnitPlanDivisionOutput"
import UnitPlanIntermediateOutput from "./UnitPlanIntermediateOutput"
import UnitPlanTopicOutput from "./UnitPlanTopicOutput"
import { getOutputMode } from "./utils"

const UnitPlanOutput = () => {
  const { overviewData, refetchOverviewData } = useContext(AIToolsContext)

  if (!overviewData)
    return (
      <div className="flex flex-col items-center justify-center p-5">
        <CircularProgress />
      </div>
    )

  const { unitPlanResponses, unitDivisionResponses } = overviewData

  const getContent = () => {
    if (!unitPlanResponses || !unitDivisionResponses)
      throw new Error(
        "unitPlanResponses and unitDivisionResponses should not be undefined"
      )

    const outputMode = getOutputMode({
      unitPlanResponses,
      unitDivisionResponses,
    })

    if (outputMode === "unitPlanTopic") {
      return <UnitPlanTopicOutput refetchPromptData={refetchOverviewData} />
    }

    if (outputMode === "unitPlanIntermediate") {
      return (
        <UnitPlanIntermediateOutput
          unitDivisionResponses={unitDivisionResponses}
          refetchPromptData={refetchOverviewData}
        />
      )
    }

    if (outputMode === "unitPlanDivision") {
      return (
        <UnitPlanDivisionOutput
          unitPlanResponses={unitPlanResponses}
          refetchPromptData={refetchOverviewData}
        />
      )
    }

    return (
      <div className="flex flex-col items-center justify-center p-5">
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && <InputDisplay data={overviewData} />}
      {getContent()}
    </div>
  )
}

export default UnitPlanOutput
