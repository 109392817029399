import { Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"

const AdmissionLetter = ({ slug }: { slug: string }) => {
  const { data, isSuccess } = useQuery({
    queryKey: queries.credentials.admissionLetter(slug).queryKey,
    queryFn: () =>
      api.credentials.getAdmissionLetter({
        urlParams: { slug: slug },
      }),
  })

  if (isSuccess) {
    return (
      <div className="flex size-full items-center justify-between gap-2 rounded-lg border border-highlight-200 bg-highlight-50 p-1.5">
        <Typography variant="title4" className="text-onSurface-800">
          Admission Letter
        </Typography>
        <a href={data.pdfFile} target="_blank" rel="noreferrer">
          <Typography className="text-primary-500 px-1 py-0.5">View</Typography>
        </a>
      </div>
    )
  }

  return null
}

export default AdmissionLetter
