import { toast } from "@suraasa/placebo-ui"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  TextField,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { Email, OTPResponse } from "api/resources/settings/types"
import { APIError } from "api/utils"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import { useGRecaptcha } from "utils/hooks/useGRecaptcha"
import isEmail from "validator/lib/isEmail"

import VerifyEmail from "../VerifyEmail"

type FormData = { email: string }

export default function AddSecondaryEmail({
  close,
  open,
  onAdd,
}: {
  close: () => void
  open: boolean
  onAdd: (email: Email) => void
}) {
  const [data, setData] = useState<(OTPResponse & { email: string }) | null>()

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormData>()

  const { submitWithCaptcha } = useGRecaptcha({
    action: "auth",
    checkboxContainer: "#secondary-email-checkbox-captcha",
  })

  const onSubmit = handleSubmit(async data => {
    submitWithCaptcha(async captcha => {
      if (!captcha) {
        toast.error("Please verify the captcha")
        return
      }
      try {
        const res = await api.settings.emails.create({
          data: { email: data.email, captcha },
        })
        setData({ ...res, email: data.email })
      } catch (err) {
        if (err instanceof APIError) {
          if (err.errors.message) {
            toast.error(err.errors.message)
          }
          if (err.errors.fieldErrors?.email) {
            handleErrors(err, { setter: setError })
          }
        }
      }
    })()
  })

  return (
    <>
      {data && (
        <VerifyEmail
          dialogTitle="Verify Secondary Email"
          onBack={() => setData(null)}
          close={() => {
            setData(null)
            close()
          }}
          email={data.email}
          otpData={data}
          open={Boolean(data)}
          onVerify={updatedEmail => {
            onAdd(updatedEmail)
            toast.success("Email added successfully")
            setData(null)
            close()
          }}
        />
      )}

      <Dialog
        open={open && !data}
        width={477}
        onAfterClose={reset}
        onRequestClose={close}
      >
        <DialogTitle>Add Email Address</DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent className="my-2">
            <TextField
              autoFocus
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              label="Email Address"
              placeholder="Enter Email Address"
              type="email"
              fullWidth
              {...register("email", {
                required: { value: true, message: "Required" },
                validate: value => isEmail(value) || "Invalid email address",
              })}
            />
            <div id="secondary-email-checkbox-captcha" className="my-2" />
          </DialogContent>

          <DialogFooter
            actions={{
              primary: {
                label: "Submit",
                type: "submit",
                loading: isSubmitting,
              },
              secondary: {
                label: "Cancel",
              },
            }}
          />
        </form>
      </Dialog>
    </>
  )
}
