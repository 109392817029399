import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { ITOReport } from "api/resources/ito/reports/types"
import clsx from "clsx"
import { NavArrowRight } from "iconoir-react"
import React from "react"
import { downloadFile } from "utils/helpers"

import styles from "./OverallReports.module.css"

const HOW_TO_READ_REPORT_LINK = "https://suraasa.co/I_xJ"

const OverallReports = ({ data }: { data: ITOReport }) => {
  const handleDownload = (
    e: { stopPropagation: () => void },
    pdf?: string | null
  ) => {
    e.stopPropagation()
    if (pdf) downloadFile(pdf, "Report.pdf")
  }

  return (
    <div
      className={clsx(
        "flex flex-col items-start px-2 py-3 sm:flex-row sm:rounded-xl sm:px-4",
        styles.container
      )}
    >
      <img
        src={
          "assets/ito/2023/AfterResultPhase/OverallReport/overall-report.webp"
        }
        alt="overall-reports"
        className="z-1 mb-4 size-full max-h-[260px] max-w-[183.701px] rounded-xl shadow-[0px_6px_15px_0px_rgba(0,0,0,0.14)] sm:mb-0 sm:mr-4"
      />
      <div className="z-1">
        <Typography variant="preTitle">Teacher Performance Report</Typography>
        <Typography variant="title2" className={clsx("mb-2", styles.title)}>
          International Teachers’ Olympiad 2023
        </Typography>
        <Typography variant="largeBody" className="mb-2">
          Your report contains a detailed analysis of your ITO performance
          across six pedagogy domains.
        </Typography>
        <div className="flex flex-wrap items-center gap-1.5">
          <Button
            onClick={(e: { stopPropagation: () => void }) => {
              handleDownload(e, data.pdf)
            }}
            rounded
            className="!bg-ito-midnight"
          >
            Download Report
          </Button>
          <Button
            variant="text"
            component="a"
            target="_blank"
            href={HOW_TO_READ_REPORT_LINK}
            endAdornment={<NavArrowRight />}
          >
            How to read this report
          </Button>
        </div>
      </div>

      <div className="absolute left-0 top-0 size-full bg-gradient-to-t from-onSurface-100 from-30% to-white/100 to-100%" />
    </div>
  )
}

export default OverallReports
