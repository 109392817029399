import { Typography } from "@suraasa/placebo-ui"
import { Button, Theme } from "@suraasa/placebo-ui-legacy"
import { ITOCertificate } from "api/resources/profile/types"
import clsx from "clsx"
import ShareSheet from "components/ShareSheet"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"
import { getPlatformURL } from "utils/helpers"

import AchieveMore from "./AchieveMore"

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    border: "1px solid #E2E8F0",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    borderRadius: 12,
    display: "grid",
    gridAutoFlow: "column",
    [theme.breakpoints.down("sm")]: {
      gridAutoFlow: "row",
    },
  },
  imgContainer: {},
  img: {
    maxHeight: 400,
    transform: "translateY(40px)",
    [theme.breakpoints.down("xs")]: {
      transform: "translateY(22px)",
    },
  },
}))

const Steps = ({
  stepNumber,
  content,
}: {
  stepNumber: number
  content: string
}) => {
  return (
    <div className="mb-1 grid grid-flow-col items-center justify-start gap-1.5">
      <Typography
        className="!flex size-4 items-center justify-center rounded-full bg-black text-center !text-white"
        variant="strong"
      >
        {stepNumber}
      </Typography>
      <Typography dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  )
}

const LetEveryoneKnow = ({ certificate }: { certificate: ITOCertificate }) => {
  const classes = useStyles()
  const stepContent = [
    `<a href="${certificate.file}" style="text-decoration: none; color: #3B82F6; border-bottom: 1px solid #3B82F6;" download target="_blank">Click here</a> to download your certificate`,
    "Click on the share button below, then copy the caption or write your own ",
    "Click on the platforms on which you want to share",
    "Upload your certificate, paste your caption, and post!",
  ]
  const [shareDialogOpen, setShareDialogOpen] = useState(false)

  const openShareDialog = () => {
    setShareDialogOpen(!shareDialogOpen)
  }

  const getShareText = () => {
    if (
      certificate.item.slug === "international-teacher-olympiad-2023-excellence"
    ) {
      return "Super happy to share that I stand among the Top 100 teachers globally."
    }
    if (
      certificate.item.slug ===
      "international-teacher-olympiad-2023-merit-1-percentile"
    ) {
      return "Super happy to share that I stand among the Top 1% teachers globally."
    }
    if (
      certificate.item.slug ===
      "international-teacher-olympiad-2023-merit-10-percentile"
    ) {
      return "Super happy to share that I stand among the Top 10% teachers globally."
    }
    if (
      certificate.item.slug ===
      "international-teacher-olympiad-2023-merit-30-percentile"
    ) {
      return "Super happy to share that I stand among the Top 30% teachers globally."
    }
    return "Super happy to share that I am one of the highly motivated teachers globally."
  }
  const getImg = () => {
    if (
      certificate.item.slug === "international-teacher-olympiad-2023-excellence"
    ) {
      return "/assets/ito/2023/AfterResultPhase/LetEveryoneKnow/excellence.webp"
    }
    if (
      certificate.item.slug ===
      "international-teacher-olympiad-2023-merit-1-percentile"
    ) {
      return "/assets/ito/2023/AfterResultPhase/LetEveryoneKnow/merit-1.webp"
    }
    if (
      certificate.item.slug ===
      "international-teacher-olympiad-2023-merit-10-percentile"
    ) {
      return "/assets/ito/2023/AfterResultPhase/LetEveryoneKnow/merit-10.webp"
    }
    if (
      certificate.item.slug ===
      "international-teacher-olympiad-2023-merit-30-percentile"
    ) {
      return "/assets/ito/2023/AfterResultPhase/LetEveryoneKnow/merit-30.webp"
    }
    return "/assets/ito/2023/AfterResultPhase/LetEveryoneKnow/participation.webp"
  }
  const certificateURL = getPlatformURL(
    "suraasa",
    `/international-teachers-olympiad/verify/${certificate.serialNumber}`
  )
  const shareText = `${getShareText()} Special thanks to the International Teachers’ Olympiad by Suraasa for giving all teachers such a wonderful opportunity of assessing their skills and getting celebrated for it.

I am thrilled to proceed to the next level of growth in my teaching career and discover my #TrueTeachingPotential!

Check out my certificate: ${certificateURL}`

  return (
    <>
      <ShareSheet
        data={{
          url: certificateURL,
          text: shareText,
        }}
        open={shareDialogOpen}
        handleClose={() => setShareDialogOpen(false)}
      />
      <div className="mb-5 md:mb-6">
        <div
          className={clsx(
            "gap-2.5 px-2 py-3 sm:px-4 sm:py-5",
            classes.container
          )}
        >
          <div>
            <Typography className="mb-3 text-end" variant="title2">
              You’re So Good. Let’s Tell Everyone!
            </Typography>
            <Typography className="mb-2" variant="body">
              Now you know how good you are at what you do. {"It's"} time you
              showed the world what {"you've"} got. To get started, follow these
              steps:
            </Typography>
            <div className="mb-3">
              {stepContent.map((item, index) => (
                <Steps key={index} stepNumber={index + 1} content={item} />
              ))}
            </div>
            <Typography className="mb-3" variant="strong">
              Enjoy the recognition that follows!
            </Typography>
            <Button rounded onClick={openShareDialog}>
              Share Certificate
            </Button>
          </div>
          <div className="flex justify-center">
            <img src={getImg()} className={classes.img} alt="" />
          </div>
        </div>
      </div>
      <AchieveMore />
    </>
  )
}

export default LetEveryoneKnow
