import { toast, Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Divider,
  IconButton,
  Tag,
  TagColors,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { AttemptStatus } from "api/resources/assessments/types"
import { SUBMISSION_STATUS } from "api/resources/assignments/types"
import { ScheduleItem } from "api/resources/schedule/types"
import clsx from "clsx"
import FullPageLoading from "components/FullPageLoading"
import SlidingSheet from "components/SlidingSheet"
import { addDays, format, isToday } from "date-fns"
import CheckRestrictions from "features/AccessManagement/CheckRestrictions"
import {
  reloadOnAssessmentClose,
  startAssessment,
} from "features/Assessments/helpers"
import { buildAssignmentTitle } from "features/Assignments/utils"
import { OpenNewWindow, Xmark } from "iconoir-react"
import { Fragment } from "react"
import { useTheme } from "react-jss"
import { Link } from "react-router-dom"
import routes from "routes"
import { formatDate, pluralize } from "utils/helpers"

import styles from "./UpcomingTasks.module.css"

const taskTypeOrder = {
  assessment_schedule: 1,
  assignment: 2,
  class_schedule: 3,
}

const tagAdornment: Record<string, { color: TagColors; label: string }> = {
  class_schedule: { color: "primary", label: "Class" },
  assessment_schedule: { color: "one", label: "Assessment" },
  assignment: { color: "three", label: "Skill Evidence Due" },
}

const ShowDayDate = ({ dayIndex }: { dayIndex: number }) => (
  <div className="flex items-center">
    <Typography variant="title3" className="me-1">
      {format(new Date(addDays(new Date(), dayIndex)), "eeee")}
    </Typography>
    <Typography className="text-onSurface-500">
      {format(new Date(addDays(new Date(), dayIndex)), "d MMM")}
    </Typography>
  </div>
)

const getBasicDetails = (task: ScheduleItem) => {
  switch (task.learningContentType) {
    case "assessment_schedule":
      return {
        title: task.assessment.title,
        startTime: task.startTime,
        endTime: task.endTime,
      }
    case "assignment":
      return {
        title: buildAssignmentTitle({
          title: task.title,
          shortTitle: task.shortTitle,
        }),
        endTime: task.targetDate,
      }
    case "class_schedule":
      return {
        title: task.classId.name,
        startTime: task.startTime,
        endTime: task.endTime,
      }
  }
}

const handleOpenAssessment = (item: ScheduleItem) => {
  if (
    item.learningContentType === "assessment_schedule" &&
    item.attempt !== null
  ) {
    if (
      item.attempt.status === AttemptStatus.NOT_STARTED ||
      item.attempt.status === AttemptStatus.IN_PROGRESS
    ) {
      // Missed
      if (new Date(item.endTime) < new Date()) {
        toast.info("You have missed this assessment")
        return
      }
      // Upcoming
      startAssessment({
        assessmentId: item.assessment.uuid,
        onClose: reloadOnAssessmentClose,
      })
      return
    }
    if (item.attempt.status === AttemptStatus.COMPLETED) {
      window.location.href = routes.assessmentResult.replace(
        ":attemptId",
        item.attempt.uuid
      )
      return
    }
  }
}

const getCTA = (task: ScheduleItem) => {
  switch (task.learningContentType) {
    case "assessment_schedule":
      handleOpenAssessment(task)
      return
    case "class_schedule":
      task.videoMeeting?.joiningUrl
        ? window.open(task.videoMeeting?.joiningUrl, "_blank")
        : toast.info("Class has not started yet!")
      return
    case "assignment":
      window.location.href = routes.assignment
        .replace(":slug", task.planner.learningItem.slug!)
        .replace(":learningItemType", "course")
        .replace(":assignmentId", task.planner.learningItem.id.toString())

      return
  }
}

const ShowDayTasks = ({
  tasks,
  day,
}: {
  tasks: ScheduleItem[]
  day: number
}) => {
  const showTodayTag = day === 0 && isToday(new Date())

  if (!tasks.length) {
    return (
      <div className="px-2 py-2.25 sm:px-3">
        <div className="mb-1 flex items-center justify-between">
          <ShowDayDate dayIndex={day} />
          {showTodayTag && <Tag color="primary" label="Today" />}
        </div>
        <Typography className="mt-2 text-onSurface-500">
          Nothing this day.
        </Typography>
      </div>
    )
  }

  const orderedTasks = tasks
    .sort((a, b) =>
      taskTypeOrder[a.learningContentType] <
      taskTypeOrder[b.learningContentType]
        ? 1
        : -1
    )
    .sort((a, b) =>
      a.learningContentType === b.learningContentType &&
      new Date(a.targetDate) > new Date(b.targetDate)
        ? 1
        : -1
    )

  let currentType = tasks[0].learningContentType

  return (
    <div className="mt-2.25 px-2 sm:px-3">
      {/* Day Date */}
      <div className="mb-1 flex items-center justify-between">
        <ShowDayDate dayIndex={day} />
        {showTodayTag && <Tag color="primary" label="Today" />}
      </div>

      {orderedTasks.map((task, index) => {
        const showTypeTag = task.learningContentType !== currentType
        if (showTypeTag) currentType = task.learningContentType
        const details = getBasicDetails(task)

        const endsToday = details?.endTime
          ? isToday(new Date(details?.endTime))
          : undefined

        const timeDisplay = [
          details?.startTime
            ? format(new Date(details?.startTime), "h:mm a")
            : null,
          format(
            new Date(details!.endTime),
            endsToday ? "h:mm a" : "dd MMM, h:mm a"
          ),
        ]
          .filter(Boolean)
          .join(" - ")

        return (
          <Fragment key={index}>
            {(showTypeTag || index === 0) && task.learningContentType && (
              <Tag
                color={tagAdornment[task.learningContentType].color}
                label={tagAdornment[task.learningContentType].label}
                className="mt-2.5"
              />
            )}
            <CheckRestrictions
              accessStatus={task.accessStatus}
              render={({ isRestricted }) => (
                <button
                  disabled={isRestricted}
                  onClick={() => getCTA(task)}
                  className={clsx(styles.bar, "w-full text-start")}
                >
                  <div className="my-0.5 flex justify-between py-1">
                    <div className="flex flex-col items-start">
                      <Typography variant="strong">{details.title}</Typography>
                      <div className="mt-0.25 flex items-center">
                        <Typography
                          variant="smallBody"
                          className="text-onSurface-500"
                        >
                          {timeDisplay}
                        </Typography>
                        <div className="mx-0.5 size-[5px] rounded-full bg-onSurface-500" />
                        <Typography
                          variant="smallBody"
                          className="text-onSurface-500"
                        >
                          Learn
                        </Typography>
                      </div>
                    </div>
                    {showTodayTag && (
                      <IconButton disabled={isRestricted}>
                        <OpenNewWindow className="text-primary-500" />
                      </IconButton>
                    )}
                  </div>
                </button>
              )}
            />
          </Fragment>
        )
      })}
    </div>
  )
}

const ViewTasks = ({ data }: { data: ScheduleItem[] }) => {
  const upcomingTasks = data.sort((a, b) =>
    a.targetDate > b.targetDate ? 1 : -1
  )

  const getTasks = (days: number) => {
    return upcomingTasks.filter(
      task =>
        new Date(task.targetDate).getDate() ===
        addDays(new Date(), days).getDate()
    )
  }

  const daysTasks = new Array(DATE_RANGE).fill(0).map((x, i) => getTasks(i))
  const assignmentsDue = data.filter(
    data => data.learningContentType === "assignment"
  ).length

  return (
    <>
      {assignmentsDue > 0 ? (
        <div
          className="px-3 py-1"
          style={{
            background: "linear-gradient(90deg, #3B82F6 0%, #BFDBFE 100%)",
          }}
        >
          <Typography variant="strong" className="text-common-white-500">
            {pluralize("Skill Evidence (Assignment)", assignmentsDue, {
              plural: "Skill Evidences (Assignments)",
            })}{" "}
            due this week
          </Typography>
        </div>
      ) : (
        <Divider weight="light" className="text-onSurface-200" />
      )}

      {daysTasks.map((tasks, index) => (
        <Fragment key={index}>
          <ShowDayTasks tasks={tasks} day={index} />
          <Divider weight="light" className="text-onSurface-200" />
        </Fragment>
      ))}
    </>
  )
}

const DATE_RANGE = 7
const UpcomingTasks = ({
  slideIn = false,
  positioned,
  onClose,
}: {
  slideIn?: boolean
  positioned?: boolean
  onClose?: () => void
}) => {
  const theme = useTheme<Theme>()
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const date = new Date()

  const { data, isLoading, isError, isSuccess } = useQuery({
    // enabled: isMdUp || isSmDown,
    queryFn: () => {
      return api.schedule.getSchedule({
        params: {
          startDate: formatDate(date.toISOString(), "yyyy-MM-dd"),
          endDate: formatDate(
            addDays(date, DATE_RANGE - 1).toISOString(),
            "yyyy-MM-dd"
          ),
          loadPlannerItem: true,
          excludeAssignments: [
            SUBMISSION_STATUS.underReview,
            SUBMISSION_STATUS.notReviewed,
            SUBMISSION_STATUS.accepted,
          ],
        },
      })
    },
    queryKey: queries.schedule.upcoming(date.getDate()).queryKey,
  })

  return (
    <div className="h-full">
      <SlidingSheet
        open={slideIn}
        fullScreen={isSmDown}
        onClose={() => onClose && onClose()}
        from="end"
      >
        <div
          className={clsx(
            "h-full overflow-scroll border border-surface-200 bg-common-white-500 md:w-[416px]"
          )}
        >
          <div className="flex items-center justify-between px-2 py-[18.5px] sm:px-3">
            <div className="flex items-center">
              <IconButton
                onClick={() => {
                  onClose && onClose()
                }}
                className="me-1"
              >
                <Xmark />
              </IconButton>
              <Typography variant="title3">Next 7 Days</Typography>
            </div>
            <Button variant="text" component={Link} to={routes.mySchedule}>
              View Full Calendar
            </Button>
          </div>
          {isLoading && <FullPageLoading />}
          {isError && (
            <div className="p-2">
              Schedule not available. Please try again later.
            </div>
          )}
          {isSuccess && <ViewTasks data={data} />}
        </div>
      </SlidingSheet>
      {isMdUp && (
        <div
          className={clsx(
            "pointer-events-none absolute left-0 top-0 z-10 size-full bg-none transition-colors duration-100",
            { "!inline-block bg-[#00000099]": slideIn }
          )}
        />
      )}
      {positioned && (
        <div
          className={clsx(
            "h-full w-[420px] overflow-auto border border-surface-200 bg-common-white-500"
          )}
        >
          {/* Title */}
          <div className="flex items-center justify-between px-3 py-[18.5px]">
            <Typography variant="title3">Next 7 Days</Typography>
            <Button variant="text" component={Link} to={routes.mySchedule}>
              View Full Calendar
            </Button>
          </div>
          {isLoading && <FullPageLoading />}
          {isError && (
            <div className="p-2">
              Schedule not available. Please try again later.
            </div>
          )}

          {isSuccess && data && <ViewTasks data={data} />}
        </div>
      )}
    </div>
  )
}

export default UpcomingTasks
