import { Typography } from "@suraasa/placebo-ui"
import { Button, Theme } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { ArrowLeftCircle, ArrowRightCircle } from "iconoir-react"
import Glider from "react-glider"
import { createUseStyles } from "react-jss"

import PodcastIcon from "./assets/podcast.png"
import SuraasaIcon from "./assets/suraasa.png"
import YoutubeIcon from "./assets/youtube.png"
import {
  ResourcePlatform,
  ResourceProps,
  RESOURCES_DATA,
  ResourceType,
} from "./data"

const useStyles = createUseStyles((theme: Theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    textDecoration: "none",
    color: "inherit",
    textAlign: "start",
    overflow: "hidden",
    border: `1px solid ${theme.colors.onSurface[200]}`,
    borderRadius: theme.spacing(3),
    backgroundColor: theme.colors.common.white[500],
    marginInlineEnd: 16,
  },
  imgContainer: {
    position: "relative",
    width: "100%",
  },
  socialLogo: {
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
    position: "absolute",
    bottom: theme.spacing(2),
    left: theme.spacing(3),
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      bottom: theme.spacing(1),
      left: theme.spacing(2),
    },
  },
  resourceDetails: {
    flexGrow: 1,
    whiteSpace: "break-spaces",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}))

const getPlatformIcon = (platform: ResourcePlatform): { logo: string } => {
  switch (platform) {
    case ResourcePlatform.SURAASA:
      return { logo: SuraasaIcon }
    case ResourcePlatform.YOUTUBE:
      return { logo: YoutubeIcon }
    case ResourcePlatform.SPOTIFY:
      return { logo: PodcastIcon }
    default:
      return { logo: SuraasaIcon }
  }
}
const getResourceAction = (type: ResourceType) => {
  switch (type) {
    case ResourceType.BLOG:
      return "Read Blog"
    case ResourceType.MASTERCLASS:
      return "Watch Masterclass"
    case ResourceType.VIDEO:
      return "Watch Video"
    case ResourceType.PODCAST:
      return "Listen Podcast"
    default:
      return ""
  }
}

const ResourceCard = ({
  thumbnail,
  title,
  platform,
  type,
  actionUrl,
}: ResourceProps) => {
  const classes = useStyles()

  return (
    <a className={classes.cardContainer} href={actionUrl}>
      <div className={classes.imgContainer}>
        <img alt="thumbnail" src={thumbnail} width="100%" />
        <div className={classes.socialLogo}>
          <img
            alt="logo"
            height="100%"
            src={getPlatformIcon(platform).logo}
            width="100%"
          />
        </div>
      </div>
      <div className={clsx(classes.resourceDetails, "p-2 sm:p-3")}>
        <div>
          <Typography className="mb-0.75 text-onSurface-500" variant="preTitle">
            {type}
          </Typography>
          <Typography className="mb-2" variant="title3">
            {title}
          </Typography>
        </div>
        <Button nudge="left" variant="text">
          {getResourceAction(type)}
        </Button>
      </div>
    </a>
  )
}

const Resources = () => {
  return (
    <>
      <div className="mb-3">
        <Typography className="mb-0.5" variant="title2">
          Resources
        </Typography>
        <Typography variant="subtitle2">
          Explore these valuable resources while you wait for the Olympiad
        </Typography>
      </div>

      <div>
        <Glider
          id="glider"
          hasArrows
          draggable={true}
          slidesToShow={3.5}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 3000,
              settings: {
                slidesToShow: 3000 / 1000 + 1.5,
              },
            },
            {
              breakpoint: 2500,
              settings: {
                slidesToShow: 2500 / 1000 + 1.5,
              },
            },
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 4.1,
              },
            },
            {
              breakpoint: 1560,
              settings: {
                slidesToShow: 3.1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2.1,
              },
            },
            {
              breakpoint: 300,
              settings: {
                slidesToShow: 1.1,
              },
            },
          ]}
          arrows={{
            prev: "#buttonPrev",
            next: "#buttonNext",
          }}
        >
          {RESOURCES_DATA.map((resource, i) => (
            <ResourceCard key={i} {...resource} />
          ))}
        </Glider>

        <div className="mt-2 hidden items-center md:flex">
          <button
            // disabled={isLeftArrowDisabled}
            id="buttonPrev"
            className={clsx(
              "flex items-center justify-center !rounded-full p-0.5"
              // { "bg-surface-200 !text-onSurface-400": isLeftArrowDisabled }
            )}
          >
            <ArrowLeftCircle strokeWidth={2.5} fontSize={20} />
          </button>
          <button
            id="buttonNext"
            // disabled={isRightArrowDisabled}
            className={clsx(
              "flex items-center justify-center !rounded-full p-0.5"
              // { "bg-surface-200 !text-onSurface-400": isRightArrowDisabled }
            )}
          >
            <ArrowRightCircle strokeWidth={2.5} fontSize={20} />
          </button>
        </div>
      </div>
    </>
  )
}
export default Resources
