import { Typography } from "@suraasa/placebo-ui"
import { Button, Container } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { ArrowLeft, Plus } from "iconoir-react"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import StartDiscussionDialog from "../StartDiscussion"

type Props = {
  backButton?: boolean
}

const TitleBar = ({ backButton }: Props) => {
  const [openStartDiscussionDialog, setOpenStartDiscussionDialog] =
    useState(false)

  const navigate = useNavigate()
  return (
    <div className="mb-3.5 border-b-2 border-onSurface-200 bg-white py-2 shadow-[0_6px_15px_0_rgba(0,0,0,0.05)]">
      <Container>
        <div
          className={clsx("flex flex-wrap", {
            "justify-between": !backButton,
          })}
        >
          {backButton && (
            <Button
              variant="text"
              className="me-1.25"
              startAdornment={<ArrowLeft />}
              onClick={() => {
                navigate(-1)
              }}
            >
              Back
            </Button>
          )}
          <Typography variant="title2">Discussions</Typography>
          {!backButton && (
            <Button
              variant="text"
              onClick={() => setOpenStartDiscussionDialog(true)}
              startAdornment={<Plus />}
            >
              Start New Discussion
            </Button>
          )}
        </div>
      </Container>
      <StartDiscussionDialog
        open={openStartDiscussionDialog}
        handleClose={() => setOpenStartDiscussionDialog(false)}
      />
    </div>
  )
}

export default TitleBar
