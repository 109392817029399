import { cn, Typography } from "@suraasa/placebo-ui"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import clsx from "clsx"
import TruncatedText from "components/TruncatedText"
import { getToolName } from "features/AItools/helper"
import {
  BookStack,
  EditPencil,
  Keyframes,
  ListSelect,
  Notes,
  Page,
  Palette,
  SoundLow,
  ViewGrid,
} from "iconoir-react"
import React, { useContext } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import routes from "routes"
import { capitalizeFirstLetter, formatDate } from "utils/helpers"

import { AIToolsContext } from "../../Generator/context"

export type PromptCardType = ListPromptHistory & {
  toolType: ToolType
  showType?: boolean
  isGenerator?: boolean
} & {
  useReplaceWhileNavigating?: boolean
} & Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "id"
  >

const YoutubeSvg = ({ className }: { className?: string }) => (
  <svg
    width="24px"
    height="24px"
    strokeWidth="1.5"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="currentColor"
    className={className}
  >
    <path
      d="M2 12.7075V11.2924C2 8.39705 2 6.94939 2.90549 6.01792C3.81099 5.08645 5.23656 5.04613 8.08769 4.96549C9.43873 4.92728 10.8188 4.8999 12 4.8999C13.1812 4.8999 14.5613 4.92728 15.9123 4.96549C18.7634 5.04613 20.189 5.08645 21.0945 6.01792C22 6.94939 22 8.39705 22 11.2924V12.7075C22 15.6028 22 17.0505 21.0945 17.9819C20.189 18.9134 18.7635 18.9537 15.9124 19.0344C14.5613 19.0726 13.1812 19.1 12 19.1C10.8188 19.1 9.43867 19.0726 8.0876 19.0344C5.23651 18.9537 3.81097 18.9134 2.90548 17.9819C2 17.0505 2 15.6028 2 12.7075Z"
      stroke="currentColor"
      strokeWidth="1.5"
    ></path>
    <path
      d="M14 12L10.5 14V10L14 12Z"
      fill="white"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
)

const getRoute = (type: ToolType): string => {
  switch (type) {
    case ToolType.lessonPlanPro:
    case ToolType.lessonPlan:
      return routes.aiTools.lessonPlan
    case ToolType.rubricsPro:
    case ToolType.rubrics:
      return routes.aiTools.rubrics
    case ToolType.quizPro:
    case ToolType.quiz:
      return routes.aiTools.quiz
    case ToolType.handout:
      return routes.aiTools.handout
    case ToolType.narration:
      return routes.aiTools.narration
    case ToolType.subjectiveAssessmentPro:
    case ToolType.subjectiveAssessment:
      return routes.aiTools.subjectiveAssessment
    case ToolType.assignmentPro:
    case ToolType.assignment:
      return routes.aiTools.assignment
    case ToolType.activityPlanner:
      return routes.aiTools.activityPlanner
    case ToolType.videoSummary:
      return routes.aiTools.videoSummary
    case ToolType.unitPlanner:
      return routes.aiTools.unitPlanner
    case ToolType.coverLetter:
      throw new Error(
        "This function should not be called with type=cover_letter"
      )
  }
}

const getIcon = (
  type: ToolType
): typeof Page | ((props: any) => JSX.Element) => {
  switch (type) {
    case ToolType.lessonPlan:
    case ToolType.lessonPlanPro:
      return Page
    case ToolType.rubrics:
    case ToolType.rubricsPro:
      return Keyframes
    case ToolType.quiz:
    case ToolType.quizPro:
      return ViewGrid
    case ToolType.handout:
      return Notes
    case ToolType.narration:
      return SoundLow
    case ToolType.subjectiveAssessment:
    case ToolType.subjectiveAssessmentPro:
      return ListSelect
    case ToolType.activityPlanner:
      return Palette
    case ToolType.assignmentPro:
    case ToolType.assignment:
      return EditPencil
    case ToolType.videoSummary:
      return YoutubeSvg
    case ToolType.unitPlanner:
      return BookStack
    case ToolType.coverLetter:
      throw new Error(
        "This function should not be called with type=cover_letter"
      )
  }
}

const getColorClassName = (
  type: ToolType
): { primary: string; selected: string; parent: string } => {
  switch (type) {
    case ToolType.lessonPlan:
    case ToolType.lessonPlanPro:
      return {
        primary: "bg-decorative-one-50 text-decorative-one-100",
        selected: "text-decorative-one-50 bg-decorative-one-100",
        parent: "bg-decorative-one-50",
      }
    case ToolType.rubrics:
    case ToolType.rubricsPro:
      return {
        primary: "bg-decorative-three-50 text-decorative-three-100",
        parent: "bg-decorative-three-50",
        selected: "text-decorative-three-50 bg-decorative-three-100",
      }
    case ToolType.quiz:
    case ToolType.quizPro:
      return {
        primary: "bg-decorative-four-50 text-decorative-four-100",
        parent: "bg-decorative-four-50",
        selected: "text-decorative-four-50 bg-decorative-four-100",
      }
    case ToolType.handout:
      return {
        primary: "bg-interactive-50 text-interactive-500",
        parent: "bg-interactive-50",
        selected: "text-interactive-50 bg-interactive-500",
      }
    case ToolType.narration:
      return {
        primary: "bg-success-50 text-success-500",
        parent: "bg-success-50",
        selected: "text-success-50 bg-success-500",
      }
    case ToolType.subjectiveAssessment:
    case ToolType.subjectiveAssessmentPro:
      return {
        primary: "bg-decorative-two-50 text-decorative-two-100",
        parent: "bg-decorative-two-50",
        selected: "text-decorative-two-50 bg-decorative-two-100",
      }
    case ToolType.activityPlanner:
      return {
        primary: "bg-[#FFF1EB] text-[#FEAE8D]",
        parent: "bg-[#FFF1EB]",
        selected: "text-[#FFF1EB] bg-[#FEAE8D]",
      }
    case ToolType.unitPlanner:
      return {
        primary: "bg-[#FFF1F9] text-[#E478B5]",
        parent: "bg-[#FFF1F9]",
        selected: "text-[#FFF1F9] bg-[#E478B5]",
      }
    case ToolType.videoSummary:
      return {
        primary: "bg-critical-50 [&>svg]:fill-critical-500 text-critical-500",
        parent: "bg-critical-50",
        selected:
          "bg-critical-500 [&>svg]:fill-critical-50 text-critical-50 [&>svg>path:nth-of-type(2)]:stroke-critical-500 [&>svg>path:nth-of-type(2)]:fill-critical-500",
      }

    case ToolType.assignment:
    case ToolType.assignmentPro:
      return {
        primary: "bg-warning-50 text-warning-500",
        parent: "bg-warning-50",
        selected: "text-warning-50 bg-warning-500",
      }
    case ToolType.coverLetter:
      throw new Error(
        "This function should not be called with type=cover_letter"
      )
  }
}

const HistoryItem = ({
  title,
  toolType,
  className,
  onClick,
  dateCreated,
  useReplaceWhileNavigating = false,
  showType,
  promptDataId,
  isGenerator,
}: PromptCardType) => {
  const navigate = useNavigate()
  const {
    promptDataId: contextPromptDataId,
    setOpenHistorySideBar,
    openHistorySideBar,
  } = useContext(AIToolsContext)

  const [searchParams] = useSearchParams()
  const currentPromptDataId = searchParams.get("id") || "-1"
  const isSelected = contextPromptDataId
    ? promptDataId === contextPromptDataId
    : promptDataId === parseInt(currentPromptDataId)

  const Icon = getIcon(toolType)
  const colorClassName = getColorClassName(toolType)

  return (
    <button
      id={promptDataId ? promptDataId.toString() : undefined}
      className={cn(
        "my-2 flex w-full items-center gap-2 rounded-2xl text-start transition-colors duration-100 ease-linear",

        className,
        {
          "p-1 my-1": isGenerator,
          [colorClassName.parent]: isSelected,
        }
      )}
      onClick={e => {
        if (openHistorySideBar) setOpenHistorySideBar(false)
        navigate(
          {
            pathname: getRoute(toolType),
            search: `?id=${promptDataId}`,
          },
          { replace: useReplaceWhileNavigating }
        )
        if (onClick) onClick(e)
      }}
    >
      <div
        className={clsx(
          "grid size-8 shrink-0 place-items-center rounded-2xl transition-colors duration-100 ease-linear",
          {
            [colorClassName.primary]: !isSelected,
            [colorClassName.selected]: isSelected,
          }
        )}
      >
        {Icon && <Icon />}
      </div>
      <div className="w-full">
        <TruncatedText maxLength={74} hideActionButton variant="subtitle2">
          {capitalizeFirstLetter(title.replaceAll("*", ""))}
        </TruncatedText>
        <Typography variant="smallBody" className="text-onSurface-500">
          {[
            showType ? getToolName(toolType) : "",
            formatDate(dateCreated, "MMM d, yyyy"),
          ]
            .filter(Boolean)
            .join(" • ")}
        </Typography>
      </div>
    </button>
  )
}

export default HistoryItem
