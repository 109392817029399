import axios from "api/axios"
import { APIResponse, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { AdmissionLetter } from "./types"
import urls from "./urls"

export default {
  getAdmissionLetter: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<AdmissionLetter>> => {
    try {
      const res = await axios.get(urls.getAdmissionLetter(urlParams.slug))
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
