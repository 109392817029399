import { toast, Typography } from "@suraasa/placebo-ui"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  TextField,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import { Evidence, EvidenceType, TestScore } from "api/resources/profile/types"
import LoadingOverlay from "components/LoadingOverlay"
import RemoveDialog from "features/Profile/components/RemoveDialog"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { handleErrors } from "utils/helpers"
import useArray from "utils/hooks/useArray"

import ProfileContext from "../../context"
import UploadEvidenceSection from "../Academics/UploadEvidenceSection"

type Props = {
  handleClose: () => void
  id?: string
} & Omit<DialogProps, "data" | "id">

const TestScoreDialog = ({ id, open, handleClose }: Props) => {
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const [data, setData] = useState<TestScore>()
  const [removeDialogOpen, toggleRemoveDialog] = useState(false)
  const [removeDialogLoading, setRemoveDialogLoading] = useState(false)

  /**
   * These evidences are uploaded by the user while they are using the application
   * They get converted into `evidenceFiles` when user hits submit.
   */
  const newEvidences = useArray<File | string>([])
  /**
   * These evidences come from the API
   */
  const evidencesFiles = useArray<Evidence>([])

  const evidencesFilesToBeDeleted = useArray<Evidence["id"]>([])

  const {
    achievements: {
      testScores: { remove, add, update },
    },
  } = useContext(ProfileContext)

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<TestScore>()

  const resetForm = () => {
    newEvidences.clear()
    evidencesFiles.clear()
    evidencesFilesToBeDeleted.clear()
    reset({})
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return

      const res = await api.profile.testScores.retrieve({ urlParams: { id } })
      if (res.isSuccessful) {
        setData(res.data)
        evidencesFiles.set(
          res.data.evidenceDocument
            ? [
                {
                  id: "1",
                  evidenceType: EvidenceType.File,
                  file: res.data.evidenceDocument,
                  fileName: res.data.evidenceDocumentName,
                },
              ]
            : res.data.evidenceUrl
            ? [
                {
                  id: "1",
                  evidenceType: EvidenceType.URL,
                  url: res.data.evidenceUrl,
                },
              ]
            : []
        )

        reset(res.data)
      }
    }
    if (id) fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reset])

  const onSubmit = handleSubmit(
    async ({ name, score, testDate, description }) => {
      const apiData = new FormData()
      apiData.append("name", name)
      apiData.append("score", score)
      apiData.append("testDate", testDate ?? "")
      apiData.append("description", description ?? "")
      if (
        newEvidences.array.length === 0 &&
        evidencesFilesToBeDeleted.array.length !== 0
      ) {
        apiData.append("evidenceUrl", "")
        apiData.append("evidenceDocument", "")
      }

      newEvidences.array.forEach(item => {
        if (typeof item === "string") {
          apiData.append("evidenceUrl", item)
          apiData.append("evidenceDocument", "")
        } else {
          apiData.append("evidenceUrl", "")
          apiData.append("evidenceDocument", item)
        }
      })

      if (id) {
        const res = await api.profile.testScores.update({
          urlParams: { id },
          data: apiData,
        })
        if (res.isSuccessful) {
          update(id, res.data)
          toast.success("Successfully saved.")
          handleClose()
        } else {
          handleErrors(res, { setter: setError })
        }
      } else {
        const res = await api.profile.testScores.create({ data: apiData })
        if (res.isSuccessful) {
          add(res.data)
          toast.success("Successfully saved.")
          handleClose()
        } else {
          handleErrors(res, { setter: setError })
        }
      }
    }
  )

  const handleRemove = async () => {
    setRemoveDialogLoading(true)
    if (id) {
      const res = await api.profile.testScores.delete({ urlParams: { id } })
      if (res.isSuccessful) {
        remove(id)
        toast.success("Removed successfully.")
        toggleRemoveDialog(false)
        handleClose()
      }
    }
  }

  return (
    <>
      <Dialog
        fullScreen={isXs}
        open={open}
        width="md"
        onAfterClose={resetForm}
        onRequestClose={handleClose}
      >
        {id && !data && <LoadingOverlay />}

        <DialogTitle>{id ? "Edit" : "Add New"} Test Score</DialogTitle>

        <DialogContent>
          <form onSubmit={onSubmit}>
            <TextField
              className="mb-3"
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              inputLabelProps={{ required: true }}
              label="Name"
              placeholder="Ex: CTET Paper 1"
              fullWidth
              {...register("name", {
                required: { value: true, message: "Required" },
              })}
            />

            <TextField
              className="mb-3"
              error={Boolean(errors.score)}
              helperText={errors.score?.message}
              inputLabelProps={{ required: true }}
              label="Score"
              placeholder="Ex: 140/150"
              fullWidth
              {...register("score", {
                required: { value: true, message: "Required" },
              })}
            />

            <TextField
              className="mb-3"
              error={Boolean(errors.testDate)}
              helperText={errors.testDate?.message}
              label="Test Date"
              placeholder="Ex: 21 Jan 2021"
              type="date"
              fullWidth
              {...register("testDate")}
            />
            {/* @ts-expect-error random-types-issue */}
            <TextField
              className="mb-3"
              error={Boolean(errors.description)}
              helperText={errors.description?.message}
              label="Description"
              maxRows={5}
              placeholder="Talk about your role and experience etc..."
              rows={5}
              fullWidth
              charLimit={300}
              multiLine
              {...register("description", {
                maxLength: {
                  value: 300,
                  message: "Try to keep it short",
                },
              })}
            />

            <UploadEvidenceSection
              buttonLabel="Add Score Result"
              evidenceFiles={evidencesFiles}
              handleEvidenceFilesToBeDeleted={evidencesFilesToBeDeleted.push}
              inputLabel="Evidence"
              limit={1}
              newEvidences={newEvidences}
            />
          </form>
        </DialogContent>

        <DialogFooter
          actions={{
            primary: {
              label: "Save",
              loading: isSubmitting,
              color: "primary",
              onClick: onSubmit,
            },
            tertiary: data && {
              label: "Remove",
              variant: "text",
              type: "button",
              color: "critical",
              onClick: () => toggleRemoveDialog(true),
            },
          }}
        />
      </Dialog>

      {data && (
        <RemoveDialog
          handleClose={() => toggleRemoveDialog(false)}
          loading={removeDialogLoading}
          open={removeDialogOpen}
          title="Remove Test Score"
          onRemove={handleRemove}
        >
          <Typography variant="smallBody">
            Are you sure you want to remove&nbsp;
            <Typography className="inline" variant="strongSmallBody">
              <strong>{data.name}</strong>
            </Typography>
            &nbsp;from your profile?
          </Typography>
        </RemoveDialog>
      )}
    </>
  )
}

export default TestScoreDialog
