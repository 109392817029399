import { Typography, TypographyProps } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import React from "react"

type Props = {
  maxLength: number
  className?: string
  hideActionButton?: boolean
  children?: string
} & TypographyProps

const TruncatedText = ({
  maxLength,
  hideActionButton = false,
  children,
  className,
  ...props
}: Props) => {
  const [seeAll, setSeeAll] = React.useState(false)

  //   const classes = useStyles()

  return (
    <div className={className}>
      <Typography
        className={clsx("inline whitespace-pre-line")}
        style={{ wordBreak: "break-word" }}
        {...props}
      >
        {children &&
          (!seeAll && children.length > maxLength
            ? `${children.slice(0, maxLength)}...`
            : children)}
      </Typography>

      {!hideActionButton &&
        children &&
        children.toString().length > maxLength && (
          <Button
            className="ms-0.5"
            variant="link"
            onClick={() => setSeeAll(!seeAll)}
          >
            {seeAll ? "Read Less" : "Read More"}
          </Button>
        )}
    </div>
  )
}

export default TruncatedText
