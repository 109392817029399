import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  CircularProgress,
  Container,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { LeaderBoardDetailsType } from "api/resources/ito/registrations/types"
import clsx from "clsx"
import { useState } from "react"
import { createUseStyles, useTheme } from "react-jss"

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    background: "#1A1A1A",
    color: "white",
  },
  profileImg: {
    borderRadius: "50%",
    flexShrink: 0,
  },
  rowContainer: {
    borderBottom: "1px solid #1e293b",
  },
  gradientShadows: {
    background:
      "linear-gradient(180deg, rgba(26, 26, 26, 0) 82.02%, #1A1A1A 100.39%)",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
  },
  userNameDetailsContainer: {
    position: "relative",
  },
  whiteLine: {
    width: 8,
    height: "56px",
    background: "white",
    borderRadius: "50px",
    [theme.breakpoints.down("xs")]: {
      height: "72px",
    },
  },
  pointerContainer: {
    position: "absolute",
    height: "100%",
    top: 0,
    left: "calc(0px - 124px)",
    [theme.breakpoints.down("sm")]: {
      left: "-32px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "-13px",
    },
  },
  textColor: {
    color: "rgba(255, 255, 255, 0.75)",
  },
}))

const MobileTableRow = ({ row }: { row: LeaderBoardDetailsType }) => {
  const classes = useStyles()

  return (
    <div className={clsx("px-2 py-1.5 sm:px-1", classes.rowContainer)}>
      <div className="grid grid-flow-col items-center">
        <div
          className={clsx(
            "flex items-center gap-2",
            classes.userNameDetailsContainer
          )}
        >
          {/* <Avatar src={row.photo} name={row.fullName} /> */}
          <div>
            <div className="mb-0.5 grid grid-flow-col gap-1">
              <Typography variant="strong">
                {row.fullName} {row.isSelf && <strong>(YOU 🎉)</strong>}
              </Typography>
            </div>
            <div className="flex items-baseline">
              {/* <Typography variant="smallBody">
                {[row.state, row.country].filter(Boolean).join(", ")}
              </Typography> */}
            </div>
          </div>
          {row.isSelf && (
            <div
              className={clsx(
                "flex items-center gap-3",
                classes.pointerContainer
              )}
            >
              <div className={classes.whiteLine} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
const TableRow = ({ row }: { row: LeaderBoardDetailsType }) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()

  const isMd = useMediaQuery(theme.breakpoints.down("md"))
  const isSm = useMediaQuery(theme.breakpoints.down("sm"))
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  if (isXs) return <MobileTableRow row={row} />

  return (
    <div className={clsx("px-1 py-1.5", classes.rowContainer)}>
      <Container>
        <div
          className={clsx(
            "flex items-center gap-2",
            classes.userNameDetailsContainer
          )}
        >
          {/* <Avatar src={row.photo} name={row.fullName} /> */}
          <Typography variant="strong">
            {row.fullName} {row.isSelf && isMd && <strong>(YOU 🎉)</strong>}
          </Typography>

          {row.isSelf && (
            <div
              className={clsx(
                "flex items-center gap-3",
                classes.pointerContainer
              )}
            >
              {!isSm && (
                <Typography variant="strongSmallBody">YOU 🎉</Typography>
              )}
              <div className={classes.whiteLine} />
            </div>
          )}
          {/* <div className="flex items-baseline justify-end ml-auto">
            {row.state && (
              <Typography variant="smallBody">{row.state}</Typography>
            )}
            {row.country && row.state && <span>,&nbsp;</span>}
            {row.country && (
              <Typography variant="strongSmallBody">{row.country}</Typography>
            )}
          </div> */}
        </div>
      </Container>
    </div>
  )
}

type Props = {
  list: LeaderBoardDetailsType[] | undefined
}

const HallOfFame = ({ list }: Props) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()

  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const [showAll, setShowAll] = useState(false)

  if (list && list.length === 0) return null

  return (
    <div className={clsx("py-8", classes.container)}>
      <Container className="flex flex-col justify-between md:flex-row">
        <div id="the-hall-of-fame">
          <div className="mb-1 flex items-center justify-between">
            <Typography
              variant="title1"
              style={{
                backgroundImage:
                  "radial-gradient(100% 97% at 100% 50%, #F9E3B0 13.03%, #FFBAAB 41.67%, #B4BAEC 87.06%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              The Hall of Fame!
            </Typography>
            <Typography variant="title4">
              This list is in alphabetical order
            </Typography>
          </div>

          <Typography
            className="mb-1 max-w-[900px] md:mb-6"
            variant="subtitle2"
          >
            Read and remember every one of these names. These are the Top 100
            Teachers Globally. They personify all of our wills to change the
            world. If you are on this list, congratulations! The world awaits
            your magic.
          </Typography>
        </div>
      </Container>

      {list === undefined ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          {!isXs && (
            <div className={clsx("p-1", classes.rowContainer)}>
              <Container className="grid grid-flow-col">
                <Typography variant="strong" className="text-onSurface-300">
                  Name
                </Typography>
                {/* <Typography
                  variant="strong"
                  textAlign="right"
                  className="text-onSurface-300"
                >
                  Location
                </Typography> */}
              </Container>
            </div>
          )}
          <div style={{ position: "relative" }}>
            {(showAll ? list : list.slice(0, 9)).map((item, index) => (
              <TableRow key={index} row={item} />
            ))}
            {!showAll && <div className={classes.gradientShadows} />}
          </div>

          {!showAll && (
            <Button
              style={{ display: "block" }}
              color="white"
              className="mx-auto mt-5"
              size="sm"
              onClick={() => {
                setShowAll(true)
              }}
            >
              Show All
            </Button>
          )}
        </>
      )}
    </div>
  )
}

export default HallOfFame
