import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  Typography,
} from "@suraasa/placebo-ui"
import { Button, IconButton } from "@suraasa/placebo-ui-legacy"
import { CheckCircleSolid, Xmark } from "iconoir-react"
import { Link } from "react-router-dom"

const LinkReadyDialog = ({
  link,
  onClose,
}: {
  link: string
  onClose: () => void
}) => {
  return (
    <Dialog defaultOpen onOpenChange={() => onClose()}>
      <DialogContent className="min-w-[320px]">
        <DialogBody className="relative md:p-4">
          <DialogClose onClick={onClose} className="absolute right-1 top-1">
            <IconButton autoFocus={false}>
              <Xmark className="text-onSurface-800" />
            </IconButton>
          </DialogClose>
          <div className="flex flex-col items-center gap-2 ">
            <div className="border-success-300 text-success-500">
              <CheckCircleSolid className="size-12" />
            </div>
            <Typography variant="title2">Your file is ready!</Typography>

            <Button component={Link} to={link} target="_blank" variant="text">
              Click here to view.
            </Button>
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default LinkReadyDialog
