import { CircularProgress, Theme } from "@suraasa/placebo-ui-legacy"
import React, { PropsWithChildren } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles((theme: Theme) => ({
  overlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    top: 0,
    zIndex: theme.zIndex.dialog + 2,
    borderRadius: theme.spacing(0.5),
    color: "white !important",
  },
}))

type Props = {
  enabled?: boolean
  message?: JSX.Element | string
  size?: "sm" | "md" | "lg"
  opacity?: number
}

const DarkOverlayLoading = ({
  children,
  message,
  enabled = true,
  size = "md",
  opacity = 0.7,
}: PropsWithChildren<Props>) => {
  const classes = useStyles()

  if (!enabled) return <>{children}</>

  return (
    <>
      {children}
      <div
        className={classes.overlay}
        style={{
          background: `rgba(0, 0, 0, ${opacity})`,
        }}
      >
        <CircularProgress size={size} />
        <div className="mt-2">{message}</div>
      </div>
    </>
  )
}

export default DarkOverlayLoading
