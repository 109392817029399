import clsx from "clsx"
import React from "react"
import { PropsWithClassName } from "utils/types"

const Paper = ({
  children,
  className,
}: PropsWithClassName<React.PropsWithChildren>) => {
  return (
    <div
      className={clsx(
        "-mx-2 overflow-x-auto rounded-none border-x-0 border-surface-200 bg-common-white-500 p-2 sm:mx-0 sm:rounded-xl sm:border",
        className
      )}
    >
      {children}
    </div>
  )
}

export default Paper
