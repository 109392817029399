import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  CircularProgress,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ToolType, UnitPlanGenerateResponse } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import { NavArrowDown } from "iconoir-react"
import { useContext, useState } from "react"

import { AIToolsContext } from "../../../context"
import MarkdownRenderer from "../../MarkdownRenderer"
import RegenerateOutput from "../../RegenerateOutput"

const AccordionOutput = ({
  sequence,
  responseIds,
  refetchPromptData,
  unitPlanGenerateResponse,
  generateUnitPlan,
  isLoading,
  isError,
  retryFunc,
}: {
  sequence: number
  responseIds: number[]
  refetchPromptData: () => void
  unitPlanGenerateResponse?: UnitPlanGenerateResponse
  isError?: boolean
  isLoading?: boolean
  generateUnitPlan?: boolean
  retryFunc?: () => void
}) => {
  const { currentResponseId, setCurrentResponseId, setResponseIds } =
    useContext(AIToolsContext)
  const [expandValue, setExpandValue] = useState<string | null>(null)

  const retrieveUnitPlanResponse = useQuery({
    queryKey: queries.aiTools.retrieveUnitPlanResponse(
      currentResponseId && responseIds.includes(currentResponseId)
        ? currentResponseId
        : responseIds[0]
    ).queryKey,
    queryFn: () =>
      api.aiTools.retrieveUnitPlanResponse({
        urlParams: {
          id:
            currentResponseId && responseIds.includes(currentResponseId)
              ? currentResponseId
              : responseIds[0],
        },
      }),
    onError: (err: any) => {
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
    enabled: !generateUnitPlan,
  })

  const { mutate: regenerateResponse, isLoading: regenIsLoading } = useMutation(
    {
      mutationFn: (data: string) => {
        setExpandValue(null)
        return api.aiTools.regenerateResponse({
          urlParams: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: currentResponseId!,
            type: ToolType.unitPlanner,
          },
          data: {
            regenerateInstruction: data,
          },
        })
      },
      onSuccess: () => {
        refetchPromptData()
      },
    }
  )

  return (
    <Accordion
      type="single"
      disabled={
        retrieveUnitPlanResponse.isFetching ||
        regenIsLoading ||
        retrieveUnitPlanResponse.isError ||
        isLoading ||
        isError
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value={expandValue}
      onValueChange={value => {
        setExpandValue(value)
        if (value) {
          setCurrentResponseId(responseIds[0])
          setResponseIds(responseIds)
        } else {
          setResponseIds(undefined)
        }
      }}
    >
      <AccordionItem
        className="overflow-hidden rounded-2xl border-2 border-solid border-surface-100 bg-white pt-0"
        value={`item-${sequence}`}
      >
        <AccordionTrigger
          className="p-3"
          icon={
            retrieveUnitPlanResponse.isFetching ||
            regenIsLoading ||
            isLoading ? (
              <CircularProgress />
            ) : retrieveUnitPlanResponse.isError || isError ? (
              <Button
                variant="text"
                className="text-critical-500"
                onClick={() => {
                  if (retryFunc) retryFunc()
                  retrieveUnitPlanResponse.refetch()
                }}
              >
                Retry
              </Button>
            ) : (
              <NavArrowDown color="black" />
            )
          }
        >
          <div className="flex items-center gap-1">
            <div>
              <Typography
                variant="title2"
                color="common.white.500"
                className="rounded-3xl bg-black px-3 py-0 text-white"
              >
                {sequence}
              </Typography>
            </div>
            <Typography variant="title2">Unit Plan</Typography>
          </div>
        </AccordionTrigger>
        <AccordionContent className="mt-3 p-0">
          <div className="px-4">
            <MarkdownRenderer showTitle>
              {unitPlanGenerateResponse?.output ||
                retrieveUnitPlanResponse.data?.output}
            </MarkdownRenderer>
          </div>
          <RegenerateOutput
            refetchOverviewData={refetchPromptData}
            copyText={retrieveUnitPlanResponse.data?.output || ""}
            isPositiveResponse={
              retrieveUnitPlanResponse.data?.isPositiveResponse
            }
            regenerate={{
              regenerateResponse: regenerateResponse,
            }}
          />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

export default AccordionOutput
