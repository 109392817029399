import AccordionOutput from "../AccordionOutput"

const UnitPlanDivisionOutput = ({
  unitPlanResponses,
  refetchPromptData,
}: {
  unitPlanResponses: {
    id: number
    responseIds: number[]
  }[]
  refetchPromptData: () => void
}) => {
  return (
    <div className="mb-4 flex flex-col gap-2">
      {unitPlanResponses.map((responseId, index) => {
        return (
          <AccordionOutput
            refetchPromptData={refetchPromptData}
            key={responseId.id}
            sequence={index + 1}
            responseIds={responseId.responseIds}
          />
        )
      })}
    </div>
  )
}

export default UnitPlanDivisionOutput
