import { Button, Typography } from "@suraasa/placebo-ui"
import { Tag, TextField } from "@suraasa/placebo-ui-legacy"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { UnitPlanTerms } from "api/resources/aiTools/types"
import Paper from "features/AItools/components/Paper"
import { useContext, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"

import { AIToolsContext } from "../../../context"
import RegenerateOutput from "../../RegenerateOutput"
import Output from "./Output"

const UnitPlanIntermediateOutput = ({
  unitDivisionResponses,
  refetchPromptData,
}: {
  unitDivisionResponses: number[]
  refetchPromptData: () => void
}) => {
  const sequenceRef = useRef(1)
  const [successfulCallsRef, setSuccessfulCallsRef] = useState(1)
  const [isDataDirty, setIsDataDirty] = useState(false)
  const [generateDivisionData, setGenerateDivisionData] =
    useState<UnitPlanTerms | null>(null)

  const { overviewData, currentResponseId, setResponseIds } =
    useContext(AIToolsContext)

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
    register,
  } = useForm<
    {
      [key: string]: string
    }[]
  >()

  const { data, isFetching } = useQuery({
    queryKey:
      queries.aiTools.unitPlanDivisionResponse(currentResponseId).queryKey,
    queryFn: () =>
      api.aiTools.retrieveUnitDivisionResponse({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: currentResponseId!,
        },
      }),
    enabled: Boolean(currentResponseId) && !generateDivisionData,
  })

  const incrementSuccessfulCalls = () => {
    setSuccessfulCallsRef(prev => prev + 1)
  }

  useEffect(() => {
    if (data && !generateDivisionData) {
      reset()
      data.output.terms.map((term, termIndex) =>
        term.units.map((unit, unitIndex) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setValue(`${termIndex}_${unitIndex}`, unit.details)
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching])

  const updateUnitDivisionData = useMutation({
    mutationFn: (formData: UnitPlanTerms) =>
      api.aiTools.updateUnitDivisionData({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: currentResponseId!,
        },
        data: {
          editedOutput: formData,
        },
      }),
    onSuccess: () => {
      setIsDataDirty(false)
    },
  })

  const regenerateResponse = useMutation({
    mutationFn: async ({
      reason,
      output,
    }: {
      reason: string
      output?: UnitPlanTerms
    }) => {
      if (isDataDirty && output) {
        const updatedData = { ...output }

        output.terms.map((term, termIndex) =>
          term.units.map((unit, unitIndex) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            updatedData.terms[termIndex].units[unitIndex].details = watch(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              `${termIndex}_${unitIndex}`
            )
          })
        )

        await updateUnitDivisionData.mutateAsync(updatedData)
      }
      return api.aiTools.regenerateUnitPlanDivision({
        urlParams: {
          id: currentResponseId || unitDivisionResponses[0],
        },
        data: {
          regenerateInstruction: reason,
        },
      })
    },
    onSuccess: () => {
      refetchPromptData()
    },
  })

  const { mutate: updateReactionUnitPlanDivision } = useMutation({
    mutationFn: (reaction: boolean | null | undefined) =>
      api.aiTools.updateReactionUnitPlanDivision({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: currentResponseId!,
        },
        data: {
          isPositiveResponse: reaction,
        },
      }),
  })

  const regenerateDivision = async (reason: string) => {
    if (isDataDirty) {
      if (!data) return
      regenerateResponse.mutate({ reason, output: data.output })
    } else {
      regenerateResponse.mutate({ reason })
    }
  }

  const onSubmit = handleSubmit(async formData => {
    if (isDataDirty) {
      if (!data) return

      const updatedData = { ...data.output }
      Object.keys(formData).forEach(function (key) {
        const [termIndex, unitIndex] = key.split("_")
        updatedData.terms[termIndex].units[unitIndex].details = formData[key]
      })

      await updateUnitDivisionData.mutateAsync(updatedData)
      setGenerateDivisionData(updatedData)
      setResponseIds([])
    } else {
      setGenerateDivisionData(data!.output)
      setResponseIds([])
    }
  })

  useEffect(() => {
    if (
      sequenceRef.current === successfulCallsRef &&
      sequenceRef.current !== 1
    ) {
      refetchPromptData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequenceRef.current, successfulCallsRef])

  if (generateDivisionData) {
    sequenceRef.current = 1
    return (
      <div className="mb-4 flex flex-col gap-2">
        {generateDivisionData.terms.map((term, termIndex) =>
          term.units.map((unit, unitIndex) => {
            return (
              <Output
                key={`${termIndex}_${unitIndex}`}
                termIndex={termIndex}
                unitIndex={unitIndex}
                sequence={sequenceRef.current++}
                refetchPromptData={refetchPromptData}
                onSuccessAPi={incrementSuccessfulCalls}
              />
            )
          })
        )}
      </div>
    )
  }

  return (
    <>
      <Paper
        className="mb-3"
        endSlot={
          <RegenerateOutput
            isPositiveResponse={data?.isPositiveResponse}
            copyText=""
            refetchOverviewData={refetchPromptData}
            regenerate={{
              regenerateResponse: regenerateDivision,
              isError: regenerateResponse.isError,
              isSuccess: regenerateResponse.isSuccess,
              isLoading: regenerateResponse.isLoading,
            }}
            userReaction={{
              userReaction: updateReactionUnitPlanDivision,
            }}
          />
        }
      >
        {overviewData && (
          <Typography variant="title2" className="mb-3">
            {overviewData.title}
          </Typography>
        )}
        <Typography className="mb-3">{data?.output.description}</Typography>
        <form
          className="flex flex-col gap-2"
          onSubmit={onSubmit}
          id="format-form"
        >
          {data?.output.terms.map((term, termIndex) => (
            <div key={termIndex}>
              <Typography variant="title2" className="mb-2">
                {term.term}
              </Typography>
              <div className="flex flex-col gap-2">
                {term.units.map((unit, unitIndex) => {
                  return (
                    <div
                      key={unit.unitNumber}
                      className="rounded-lg border border-solid border-surface-200 p-3"
                    >
                      <div className="mb-2 flex flex-wrap items-center justify-between gap-2">
                        <Typography variant="title3">
                          Unit {unit.unitNumber}:{" "}
                          <span className="text-xl font-medium leading-[24.2px] tracking-[-0.02em]">
                            {unit.unitTitle}
                          </span>
                        </Typography>
                        <Tag
                          color="onSurface"
                          label={`Est. Time- ${unit.numberOfWeeks}`}
                        />
                      </div>
                      <Typography variant="strong" className="mb-0.75">
                        Details
                      </Typography>

                      {/* @ts-expect-error: placebo rows prop error */}
                      <TextField
                        multiLine
                        helperText={
                          errors[`${termIndex}_${unitIndex}`]
                            ? "Required"
                            : undefined
                        }
                        rows={5}
                        fullWidth
                        error={Boolean(errors[`${termIndex}_${unitIndex}`])}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        {...register(`${termIndex}_${unitIndex}`, {
                          required: { value: true, message: "Required" },
                          onChange: e => {
                            if (!isDataDirty) {
                              setIsDataDirty(true)
                            }
                            e.target.dispatchEvent(
                              new Event("input", { bubbles: true })
                            )
                          },
                        })}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          ))}
        </form>
      </Paper>
      <div className="sticky bottom-8 left-0 flex flex-wrap items-center justify-between gap-2 rounded-lg bg-onSurface-800 px-3 py-2 text-white md:bottom-0">
        <Typography variant="largeBody">
          Ready to generate a unit plan?
        </Typography>
        <Button type="submit" form="format-form">
          Generate All Unit Plans
        </Button>
      </div>
    </>
  )
}

export default UnitPlanIntermediateOutput
