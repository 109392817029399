import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getAxionURL = getServiceURL(SERVICE_PREFIX.axion)

export const urls = validateUrls({
  chatbot: {
    conversationMessage: id =>
      getAxionURL(`/v1/chatbot/conversations/${id}/messages/`),
    conversation: () => getAxionURL(`/v1/chatbot/conversations/`),
  },
})
