import React from "react"

const CreateFromScratch = ({
  currentColor = "#9A7BBA",
}: {
  currentColor?: string
}) => {
  return (
    <svg
      width="135"
      height="108"
      viewBox="0 0 135 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.499967"
        width="65.9513"
        height="86.3632"
        rx="3.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 76.7728 31.0112)"
        fill="white"
        stroke={currentColor}
      />
      <rect
        y="0.499967"
        width="65.9513"
        height="86.3632"
        rx="3.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 76.7728 24.8589)"
        fill="white"
        stroke={currentColor}
      />
      <rect
        y="0.499967"
        width="65.9513"
        height="86.3632"
        rx="3.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 76.7728 18.7065)"
        fill="white"
        stroke={currentColor}
      />
      <rect
        y="0.499967"
        width="65.9513"
        height="86.3632"
        rx="3.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 76.7728 12.5544)"
        fill="white"
        stroke={currentColor}
      />
      <rect
        y="0.499967"
        width="65.9513"
        height="86.3632"
        rx="3.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 76.7728 6.40234)"
        fill="white"
        stroke={currentColor}
      />
      <rect
        y="0.499967"
        width="65.9513"
        height="86.3632"
        rx="3.5"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 76.7728 0.25)"
        fill="white"
        stroke={currentColor}
      />
      <rect
        width="51.8354"
        height="4.48"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 64.9102 14)"
        fill={currentColor}
      />
      <rect
        width="51.8354"
        height="4.48"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 58.0078 17.9841)"
        fill={currentColor}
      />
      <rect
        width="39.4179"
        height="4.48"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 51.1055 21.9685)"
        fill={currentColor}
      />
      <rect
        width="24.1732"
        height="4.48"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 44.207 25.9526)"
        fill={currentColor}
      />
      <rect
        width="24.1732"
        height="4.48"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 68.1641 39.7827)"
        fill={currentColor}
      />
      <rect
        width="33.8924"
        height="4.48"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 37.3047 29.9368)"
        fill={currentColor}
      />
      <rect
        width="51.8354"
        height="4.48"
        transform="matrix(0.866044 0.499967 -0.866044 0.499967 30.4023 33.9209)"
        fill={currentColor}
      />
    </svg>
  )
}

export default CreateFromScratch
