import { Container, Theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import React from "react"
import { useTheme } from "react-jss"
import { useParams } from "react-router-dom"

import DiscussionItem from "../DiscussionItem"
import TitleBar from "../TitleBar"

const DiscussionDetailPage = () => {
  const { id } = useParams() as { id: string }
  const theme = useTheme<Theme>()
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <div className="pb-10">
      <TitleBar backButton />

      <Container fullWidth={xsDown}>
        <div className="col-span-12 grid grid-cols-12 sm:col-span-10 sm:col-start-1">
          <div className="col-span-12 col-start-1 md:col-span-10 md:col-start-2">
            <DiscussionItem discussionId={id} />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default DiscussionDetailPage
