import { Typography } from "@suraasa/placebo-ui"
import { Container, theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { CustomContainer } from "features/Home/DynamicContainer"
import GradientButton from "features/ITO/components/GradientButton"
import { NavArrowLeft, NavArrowRight } from "iconoir-react"
import React, { useEffect, useState } from "react"
import Glider from "react-glider"
import { getPlatformURL } from "utils/helpers"

import Card, { CardProps } from "./Card"
const list: CardProps[] = [
  {
    img: "/assets/ito/2023/BeforeResult/Recommendations/rec-1.webp",
    title: "High Expectations Affect Performance!",
    content:
      "In this video, discover how setting high expectations in the classroom can impact student performance. ",
    link: "https://www.youtube.com/watch?v=ej0E6axf8e0",
  },
  {
    img: "/assets/ito/2023/BeforeResult/Recommendations/rec-2.webp",
    title: "Assessment Standards - Raise the Bar",
    content:
      "Worry about your students’ assessment results? No need to worry any more. Learn about Assessment Standards to make the most of assessments.",
    link: "https://www.suraasa.com/blog/assessment-standards-raise-the-bar?utm_source=ITO Dashboard - Recommended Resources&utm_medium=ITO Dashboard&utm_campaign=ITO Dashboard - PreResult",
  },
  {
    img: "/assets/ito/2023/BeforeResult/Recommendations/rec-3.webp",
    title: "Why are Pedagogical Skills Important?",
    content:
      "Pedagogical skills can elevate the quality of teaching. Explore the value of these skills and discover how they can help you enhance your abilities.",
    link: "https://www.suraasa.com/blog/significance-of-pedagogical-skills-for-teachers?utm_source=ITO Dashboard - Recommended Resources&utm_medium=ITO Dashboard&utm_campaign=ITO Dashboard - PreResult",
  },
  {
    img: "/assets/ito/2023/BeforeResult/Recommendations/rec-4.webp",
    title: "Relieve Stress for Better Mental Health",
    content:
      "A stressful teacher exudes stress while a happy teacher exudes positivity. Discover stress management activities that all teachers should follow. ",
    link: "https://www.suraasa.com/blog/stress-management-activities?utm_source=ITO Dashboard - Recommended Resources&utm_medium=ITO Dashboard&utm_campaign=ITO Dashboard - PreResult",
  },
  {
    img: "/assets/ito/2023/BeforeResult/Recommendations/rec-5.webp",
    title: "5 Essential Skills For a Modern Teacher",
    content:
      "Teachers have to develop diverse skill sets so that their students become citizens who thrive in a fast-paced world. Explore these essential skills.",
    link: "https://www.thehindu.com/education/five-essential-skills-for-the-modern-teacher/article66936885.ece",
  },
  {
    img: "/assets/ito/2023/BeforeResult/Recommendations/rec-6.webp",
    title: "How to Become a Successful Teacher",
    content:
      "With over 20 years of rich experience in the ‘Education Industry’, Ms Varsha Agarwal shares her valuable insights and experience in this blog.",
    link: "https://www.suraasa.com/blog/teacher-upskilling?utm_source=ITO Dashboard - Recommended Resources&utm_medium=ITO Dashboard&utm_campaign=ITO Dashboard - PreResult",
  },
]

const RecommendedResources = ({ className }: { className?: string }) => {
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  const [currentSlide, setCurrentSlide] = useState(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setForceRender] = useState(0)

  const initGliderListeners = () => {
    const gliderElement = document.getElementById("glider")
    if (!gliderElement) return
    const listener = (event: any) => {
      setCurrentSlide(event.detail.slide)
    }
    gliderElement.addEventListener("glider-slide-visible", listener)

    return () => {
      gliderElement.removeEventListener("glider-slide-visible", listener)
    }
  }

  useEffect(() => {
    const listener = () => {
      const anchor = document.querySelector("#anchor-ref")
      const sliderContainer = document.querySelector(
        "#slider-container"
      ) as HTMLDivElement

      if (!anchor || !sliderContainer) return

      sliderContainer.style.marginLeft = `${anchor.getBoundingClientRect().x}px`
    }

    listener()

    window.addEventListener("resize", listener)

    return () => {
      window.removeEventListener("resize", listener)
    }
  }, [])

  const isLeftArrowDisabled = currentSlide === 0
  const isRightArrowDisabled = currentSlide === list.length - 1

  return (
    <div className={clsx("bg-surface-50", className)}>
      <CustomContainer className="pb-4"></CustomContainer>
      <CustomContainer
        removeMarginRight
        id="slider-container"
        className="relative mb-0 overflow-x-hidden sm:mb-4"
      >
        <Typography variant="strong">Check out our Resources</Typography>
        <Typography variant="title1" className="mb-4">
          Recommended Resources for You
        </Typography>
        {isSmUp && (
          <>
            <button
              id="buttonPrev"
              className={clsx(
                "absolute left-5 top-[60%] z-1 flex translate-y-[-60%] items-center justify-between rounded-full bg-onSurface-800 text-white",
                {
                  "!hidden": isLeftArrowDisabled,
                  "!block": !isLeftArrowDisabled,
                }
              )}
            >
              <NavArrowLeft width={54} height={54} />
            </button>
            <button
              id="buttonNext"
              className={clsx(
                "absolute right-5 top-[60%] z-1 flex translate-y-[-60%] items-center justify-between rounded-full bg-onSurface-800 text-white",
                {
                  hidden: isRightArrowDisabled,
                }
              )}
            >
              <NavArrowRight width={54} height={54} />
            </button>
          </>
        )}

        <Glider
          id="glider"
          hasArrows
          onLoad={() => {
            initGliderListeners()
            setForceRender(x => x + 1)
          }}
          className="relative"
          draggable={true}
          slidesToShow={3.5}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 3000,
              settings: {
                slidesToShow: 5.1,
              },
            },
            {
              breakpoint: 2500,
              settings: {
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 2000,
              settings: {
                slidesToShow: 4.1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3.5,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2.5,
              },
            },
            {
              breakpoint: 300,
              settings: {
                slidesToShow: 1.1,
              },
            },
          ]}
          arrows={{
            prev: "#buttonPrev",
            next: "#buttonNext",
          }}
        >
          {list.map((item, i) => (
            <Card key={i} {...item} />
          ))}
        </Glider>
      </CustomContainer>

      <Container>
        <GradientButton
          className="mt-3"
          fullWidth={!isSmUp}
          component="a"
          href={getPlatformURL("suraasa", "/resources")}
          target="_blank"
        >
          View More Resources
        </GradientButton>
      </Container>
    </div>
  )
}

export default RecommendedResources
