import {
  IconButton,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { Enrollment } from "api/resources/learningItems/types"
import { LearningCertificate } from "api/resources/profile/types"
import Athe from "assets/MyCertificates/Athe.svg"
import CurlLines from "assets/MyCertificates/CurlLines.svg"
import Rings from "assets/MyCertificates/Rings.svg"
import SuraasaLogo from "assets/MyCertificates/SuraasaLogo.svg"
import clsx from "clsx"
import { Download, Lock, ShareAndroid } from "iconoir-react"
import React from "react"
import { useTheme } from "react-jss"
import { downloadFile, formatDate, getPlatformURL } from "utils/helpers"

import styles from "./MyLearningCard.module.css"

type CardProps = {
  inCompletedEnrollment?: Enrollment
  completedEnrollment?: LearningCertificate
  className?: string
  setCertificateUrl: (value: string) => void
}

const getBackgroundImg = (item: boolean) => {
  if (item) return Rings
  return CurlLines
}

const Card = ({
  isDisabled,
  onClick,
  isDarkCard,
  className,
  topBar,
  titleBar,
  bottomBar,
  certificateType,
}: {
  isDisabled?: boolean
  onClick?: () => void
  isDarkCard: boolean
  className?: string
  topBar: JSX.Element | null
  titleBar: JSX.Element | null
  bottomBar: JSX.Element | null
  certificateType: "course" | "certification" | "qualification"
}) => {
  return (
    <Tooltip
      maxWidth="231px"
      title={`This certificate will get unlocked
    when you finish your ${certificateType}.`}
      disabled={!isDisabled}
    >
      <div>
        <button
          onClick={onClick}
          className={clsx(
            "relative flex h-[240px] w-full flex-col justify-between rounded-lg border-[5px] border-surface-500 p-2 shadow-[0_8px_25px_0_rgba(0,0,0,0.15)] sm:h-[278px] sm:px-3 sm:py-4  md:h-[292px]",
            className,
            styles.container,
            {
              [styles.pgctlCertificate]: isDarkCard,

              "bg-onSurface-100": isDisabled,
              "bg-decorative-three-50": !isDarkCard,
            }
          )}
        >
          <div className="z-10">
            {topBar}
            {titleBar}
          </div>
          {bottomBar}
          <img
            src={getBackgroundImg(isDarkCard)}
            alt="rings"
            className={clsx("absolute bottom-0 right-0 h-full", {
              [styles.logo]: isDarkCard,
            })}
          />
        </button>
      </div>
    </Tooltip>
  )
}

const logos = [
  {
    img: SuraasaLogo,
    alt: "SuraasaLogo",
    slug: "suraasa",
  },
  {
    img: Athe,
    alt: "Athe",
    slug: "athe",
  },
]

const svgProps = { width: 20, height: 20 }

const getCertificateType = (
  itemType: string
): "qualification" | "certification" | "course" => {
  if (itemType === "qualification") {
    return "qualification"
  }
  if (itemType === "certification") {
    return "certification"
  }
  return "course"
}

const MyLearningCard = ({
  className,
  inCompletedEnrollment,
  setCertificateUrl,
  completedEnrollment,
}: CardProps) => {
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  if (completedEnrollment) {
    const isDarkCard =
      completedEnrollment.item.itemType === "qualification" ||
      completedEnrollment.item.itemType === "certification"

    const credentialIssuedOn = formatDate(
      completedEnrollment.issuedAt,
      "dd LLLL yyyy"
    )

    const orgSlug = completedEnrollment.organization.slug as "suraasa" | "athe"

    const certificateUrl = getPlatformURL(
      "suraasa",
      `/verify/${completedEnrollment.serialNumber}`
    )

    const handleShare = (e: { stopPropagation: () => void }) => {
      e.stopPropagation()
      setCertificateUrl(certificateUrl)
    }

    const handleDownload = (e: { stopPropagation: () => void }) => {
      e.stopPropagation()
      downloadFile(completedEnrollment.file, completedEnrollment.item.name)
    }

    const getBottomBar = () => {
      if (isDarkCard) {
        return (
          <div className="z-10 flex w-full items-center justify-between">
            {!isXsDown && (
              <div className="flex">
                <IconButton
                  onClick={handleDownload}
                  color="white"
                  className="me-2"
                >
                  <Download {...svgProps} />
                </IconButton>
                <IconButton onClick={handleShare} color="white">
                  <ShareAndroid {...svgProps} />
                </IconButton>
              </div>
            )}
            <span className={styles.dateText}>{credentialIssuedOn}</span>
          </div>
        )
      }
      if (!isXsDown)
        return (
          <div className="z-10 flex">
            <IconButton onClick={handleDownload} color="black" className="me-2">
              <Download {...svgProps} />
            </IconButton>
            <IconButton id="share" onClick={handleShare} color="black">
              <ShareAndroid {...svgProps} />
            </IconButton>
          </div>
        )
      return null
    }

    return (
      <Card
        onClick={() => {
          window.open(certificateUrl, "_blank")
        }}
        certificateType={getCertificateType(completedEnrollment.item.itemType)}
        className={className}
        isDarkCard={isDarkCard}
        topBar={
          <>
            {isDarkCard ? (
              <img
                className="mb-1"
                height="16px"
                width="83px"
                src={logos.find(item => item.slug === orgSlug)?.img}
                alt={logos.find(item => item.slug === orgSlug)?.alt}
              />
            ) : (
              <span
                className={clsx(
                  "mb-0.5  block text-onSurface-600",
                  styles.dateText
                )}
              >
                {credentialIssuedOn}
              </span>
            )}
          </>
        }
        bottomBar={getBottomBar()}
        titleBar={
          <span
            className={clsx(styles.title, "text-onSurface-900", {
              [styles.pgctlCardText]: isDarkCard,
            })}
          >
            {completedEnrollment.item.name}
          </span>
        }
      />
    )
  }

  if (!inCompletedEnrollment) return null

  const isDarkCard =
    inCompletedEnrollment.type === "qualification" ||
    inCompletedEnrollment.type === "certification"

  return (
    <Card
      isDarkCard={isDarkCard}
      isDisabled
      certificateType={getCertificateType(inCompletedEnrollment.type)}
      topBar={null}
      className={className}
      bottomBar={
        <div className="text-onSurface-500">
          <Lock {...svgProps} />
        </div>
      }
      titleBar={
        <span
          className={clsx(styles.title, "text-onSurface-900", {
            [styles.pgctlCardText]: isDarkCard,
          })}
        >
          {inCompletedEnrollment.name}
        </span>
      }
    />
  )
}

export default MyLearningCard
