import { toast } from "@suraasa/placebo-ui"
import { CircularProgress } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import { getToolName } from "features/AItools/helper"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import Heading from "../../Heading"
import { AIToolsContext } from "../context"
import ActivityPlannerOutput from "../GenerationOutput/ActivityPlannerOutput"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import ProForm from "./ProForm"

const ActivityPlannerGenerator = ({
  id,
  onGeneration,
}: {
  id?: string
  onGeneration: (data: ListPromptHistory) => void
}) => {
  const navigate = useNavigate()

  const { currentResponseId, overviewData, setPromptDataId, promptDataId } =
    useContext(AIToolsContext)

  const content = useQuery({
    enabled: currentResponseId !== undefined,
    queryKey: queries.aiTools.retrieveHistory(
      ToolType.activityPlanner,
      currentResponseId
    ).queryKey,
    queryFn: async () =>
      api.aiTools.retrieveContent<ToolType.activityPlanner>({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: currentResponseId!,
          type: ToolType.activityPlanner,
        },
      }),
    onError: err => {
      if (err instanceof APIError) {
        toast.error(err.message || "Something went wrong")
      }
    },
  })

  if (id && content.isLoading) {
    return (
      <div className="flex flex-col items-center justify-center p-5">
        <CircularProgress />
      </div>
    )
  }

  if (content.data && promptDataId) {
    return (
      <>
        <ContentActions
          onBack={() => {
            navigate(routes.aiTools.activityPlanner, { replace: true })
            setPromptDataId(null)
          }}
          currentResponseId={currentResponseId}
          toolType={ToolType.activityPlanner}
          title={overviewData?.title}
        />

        <ActivityPlannerOutput {...content.data} />
      </>
    )
  }
  return (
    <>
      <Heading
        title={`${getToolName(ToolType.activityPlanner)} Generator`}
        subtitle="Elevate your teaching approach with the Activity Plan Generator, fostering interactive and impactful learning experiences."
        isPro
      />
      <ProForm
        onGeneration={res =>
          onGeneration({
            dateCreated: new Date().toString(),
            promptDataId: res.id,
            title: res.title,
            toolType: ToolType.activityPlanner,
          })
        }
      />
    </>
  )
}

export default ActivityPlannerGenerator
