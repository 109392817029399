import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import Monitor from "assets/Fallback/monitor.svg"
import Fallback from "components/Fallback"
import ItemList from "components/ItemList"
import ItemRow from "components/ItemRow"
import TruncatedText from "components/TruncatedText"
import { SkillProfileControllerContext } from "features/SkillProfile"
import { useContext } from "react"

type Props = {
  data: {
    submission: string
    title: string
    uuid: string
    visibility?: boolean | undefined
  }[]
  isHiddenEvidence?: boolean
  canView: boolean
}

const EvidenceList = ({ data, isHiddenEvidence, canView }: Props) => {
  const controller = useContext(SkillProfileControllerContext)

  if (data.length === 0) {
    if (isHiddenEvidence) return null

    return (
      <Fallback
        className="my-4"
        data={{ image: Monitor, title: "There are no evidences available" }}
      />
    )
  }

  return (
    <>
      {isHiddenEvidence && (
        <Typography className="mb-2 text-onSurface-800" variant="title3">
          Hidden Evidences
        </Typography>
      )}
      <ItemList className="mb-2">
        {data.map((item, index) => (
          <ItemRow
            onClick={
              canView
                ? () => {
                    controller.update({ submissionId: item.submission })
                  }
                : undefined
            }
            key={index}
            infoSlot={
              <div className="py-1">
                <TruncatedText
                  maxLength={70}
                  color={isHiddenEvidence ? "onSurface.500" : undefined}
                  hideActionButton
                  variant="strong"
                >
                  {item.title}
                </TruncatedText>
              </div>
            }
            actionSlot={
              canView
                ? () => {
                    return <Button variant="text">View</Button>
                  }
                : undefined
            }
          />
        ))}
      </ItemList>
    </>
  )
}

export default EvidenceList
