import api from "api"
import { ApplicationFormOverview } from "api/resources/applicationForm/types"
import React, { useEffect, useState } from "react"
import { learningItemSlugs } from "utils/constants"

import MEdApplicationFormStatusCard from "./med"
import PgCTLApplicationFormStatusCard from "./Pgctl"

const ApplicationFormStatusBanners = () => {
  const [overview, setOverview] = useState<ApplicationFormOverview[]>([])

  const fetchApplicationOverview = async () => {
    try {
      const res = await api.applicationForm.overview()
      setOverview(res)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchApplicationOverview()
  }, [])

  const pgctlOverview = overview.find(
    o => o.productSlug === learningItemSlugs.pgctl
  )
  const medOverview = overview.find(
    o => o.productSlug === learningItemSlugs.med
  )

  return (
    <>
      {pgctlOverview && (
        <PgCTLApplicationFormStatusCard
          refresh={fetchApplicationOverview}
          data={pgctlOverview}
        />
      )}
      {medOverview && (
        <MEdApplicationFormStatusCard
          refresh={fetchApplicationOverview}
          data={medOverview}
        />
      )}
    </>
  )
}

export default ApplicationFormStatusBanners
