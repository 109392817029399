import AccessRestrictedBanner from "features/AccessManagement/AccessRestrictedBanner"
import UnverifiedEmailBanner from "features/Settings/UnverifiedEmailBanner"

const GlobalBanners = () => {
  return (
    <div id="globalTopBars">
      <UnverifiedEmailBanner />
      <AccessRestrictedBanner />
    </div>
  )
}

export default GlobalBanners
