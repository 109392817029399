import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { Button, IconButton } from "@suraasa/placebo-ui-legacy"
import api from "api"
import clsx from "clsx"
import { Plus, Trash } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { handleErrors } from "utils/helpers"

import ProfileContext from "../../context"
import AddDialog from "./AddDialog"
import RemoveDialog from "./RemoveDialog"
import { getProficiencyLabel } from "./utils"

const useStyles = createUseStyles(theme => ({
  dialogContent: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  addLanguages: {
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },

  languageContainer: {
    display: "flex",
    padding: theme.spacing(1.5, 3, 1.5, 0),
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.colors.critical[500],
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    "&:last-child": {
      border: "none",
    },
  },
  addLanguageContainer: {
    padding: theme.spacing(2.5, 3, 5.5, 3),
    display: "flex",
    gap: 24,
    flexDirection: "column",
  },
  removeLanguageContent: {
    padding: theme.spacing(2),
  },
}))

const LanguageDialog = ({
  open,
  onRequestClose,
}: {
  onBack?: () => void
  onRequestClose: () => void
  open: boolean
}) => {
  const classes = useStyles()

  const {
    languages: { data, add, remove },
  } = useContext(ProfileContext)

  const hasNoItems = data.length === 0

  const [openAddLanguageDialog, setOpenAddLanguageDialog] = useState(false)
  const [languageToDelete, setLanguageToDelete] = useState<number | null>(null)

  const [loading, setLoading] = useState(false)

  const handleRemove = async () => {
    if (!languageToDelete) return

    setLoading(true)

    const res = await api.profile.languages.delete({
      urlParams: { id: languageToDelete },
    })
    if (res.isSuccessful) {
      setLoading(false)
      remove(languageToDelete)
      toast.success("Removed successfully.")
      setLanguageToDelete(null)
    } else {
      handleErrors(res)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) setOpenAddLanguageDialog(hasNoItems)
  }, [open, hasNoItems])

  return (
    <Dialog open={open} onOpenChange={onRequestClose}>
      <DialogContent>
        {openAddLanguageDialog || hasNoItems ? (
          <AddDialog
            open={openAddLanguageDialog}
            onAdd={add}
            onBack={
              hasNoItems ? undefined : () => setOpenAddLanguageDialog(false)
            }
            onRequestClose={onRequestClose}
          />
        ) : (
          <>
            <RemoveDialog
              handleClose={() => setLanguageToDelete(null)}
              loading={loading}
              open={Boolean(languageToDelete)}
              title="Remove Language"
              onRemove={handleRemove}
            >
              <Typography variant="smallBody">
                Are you sure you want to remove{" "}
                <b>
                  {
                    data.find(item => item.id === languageToDelete)?.language
                      .name
                  }
                </b>{" "}
                language from your profile?
              </Typography>
            </RemoveDialog>
            <DialogHeader>
              <DialogTitle>Languages</DialogTitle>
            </DialogHeader>

            <DialogBody className="flex flex-col p-0">
              <div className={clsx(classes.addLanguages, "px-1.5 py-2")}>
                <Button
                  startAdornment={<Plus />}
                  variant="text"
                  onClick={() => setOpenAddLanguageDialog(true)}
                >
                  Add New Language
                </Button>
              </div>
              <div className="flex flex-col gap-1.5 py-1.5 pl-3 pr-0">
                {data.map(item => (
                  <div className={classes.languageContainer} key={item.id}>
                    <div>
                      <Typography
                        className="pb-0.5 text-onSurface-800"
                        variant="strong"
                      >
                        {item.language.name}
                      </Typography>
                      <Typography
                        className="text-onSurface-500"
                        variant="smallBody"
                      >
                        {getProficiencyLabel(item.proficiency)}
                      </Typography>
                    </div>
                    <IconButton
                      color="critical"
                      onClick={() => {
                        setLanguageToDelete(item.id)
                      }}
                    >
                      <Trash />
                    </IconButton>
                  </div>
                ))}
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                size="sm"
                onClick={onRequestClose}
                variant="outlined"
                color="black"
              >
                Close
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default LanguageDialog
