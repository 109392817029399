import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("learningItems", {
  overview: (itemType: string, itemId: number) => ({
    queryKey: ["overview", itemType, itemId],
  }),
  enrollmentList: () => ({
    queryKey: ["enrollments"],
  }),
  listPlannerItems: (id: number) => ({
    queryKey: ["plannerItems", id],
  }),
  listPlanners: (id?: number) => ({
    queryKey: ["planners", id],
  }),
  upcomingPlannerItem: (type: string, id: number) => ({
    queryKey: ["upcomingPlannerItem", type, id],
  }),
  details: (type, slug) => ({
    queryKey: ["learningItemDetails", type, slug],
  }),
  lastAccessed: itemId => ({
    queryKey: ["lastAccessedItem", itemId],
  }),
  listClasses: (itemType, itemId) => ({
    queryKey: ["listClasses", itemType, itemId],
  }),
  listResources: (itemType, itemId) => ({
    queryKey: ["listResources", itemType, itemId],
  }),
  learningRestrictions: () => ({
    queryKey: ["learningRestrictions"],
  }),
  retrieveGradeBookScore: qualificationId => ({
    queryKey: ["retrieveGradeBookScore", qualificationId],
  }),
})
