import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Shop } from "iconoir-react"
import React from "react"
import { getPlatformURL } from "utils/helpers"
import { PropsWithClassName } from "utils/types"

import styles from "./ExploreCoursesOnStoreBar.module.css"

const ExploreCoursesOnStoreBar = ({ className }: PropsWithClassName) => {
  return (
    <a
      href={getPlatformURL("suraasa", "/store")}
      className={clsx(
        styles.root,
        "flex items-center justify-between rounded-2xl px-3 py-2 sm:py-3",
        className
      )}
    >
      <Typography variant="body" className="me-1 text-white">
        Want to see what courses we offer? Check out our store!
      </Typography>
      <Shop className={clsx("hidden shrink-0 p-1 sm:block", styles.icon)} />
    </a>
  )
}

export default ExploreCoursesOnStoreBar
